
import { useComProfile, useCommunityActions } from '../../../../hooks/community-hook';
import { fetchEditAvatar, fetchEditUserName, fetchGetComProfile } from '../../../../api/community/api-profile';
import { Avatar, AvatarFallback, AvatarImage } from '../../../../components/ui/avatar';
import { Button } from '../../../../components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../../components/ui/card';
import React, { useState, useEffect } from 'react';
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { Input } from '../../../../components/ui/input';

interface ProfilePicture {
    src: string;
    alt: string;
}

const profilePics: ProfilePicture[] = ([
    { src: `${process.env.REACT_APP_BACKEND}/user/male_1.png`, alt: 'Avatar 1' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_1.png`, alt: 'Avatar 2' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_2.png`, alt: 'Avatar 3' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_2.png`, alt: 'Avatar 4' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_3.png`, alt: 'Avatar 5' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_3.png`, alt: 'Avatar 6' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_4.png`, alt: 'Avatar 7' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_4.png`, alt: 'Avatar 8' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_5.png`, alt: 'Avatar 9' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_5.png`, alt: 'Avatar 10' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_6.png`, alt: 'Avatar 11' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_6.png`, alt: 'Avatar 12' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_7.png`, alt: 'Avatar 13' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_7.png`, alt: 'Avatar 14' },
    { src: `${process.env.REACT_APP_BACKEND}/user/male_8.png`, alt: 'Avatar 15' },
    { src: `${process.env.REACT_APP_BACKEND}/user/female_8.png`, alt: 'Avatar 16' },
    { src: `${process.env.REACT_APP_BACKEND}/user/non_binary_1.png`, alt: 'Avatar 17' },
    { src: `${process.env.REACT_APP_BACKEND}/user/non_binary_2.png`, alt: 'Avatar 18' },
]);

const ProfileCommunity: React.FC = () => {
    const { t } = useTranslation();
    const comProfile = useComProfile();
    const { setComProfile } = useCommunityActions();
    const [selectedPic, setSelectedPic] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [newUsername, setNewUsername] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            const response = await fetchGetComProfile();
            if (response) {
                setComProfile(response);
                setSelectedPic(`${process.env.REACT_APP_BACKEND}/user/${response.avatar}`);
                setUsername(response.username);
                setNewUsername(response.username);
            }
        };

        fetchProfile();
    }, []);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUsername(e.target.value);
    };

    const updateUsername = async () => {
        if (!newUsername.trim()) {
            toast.error('Username cannot be empty.', {
                className: 'text-warning_blue'
            });
            setError('Username cannot be empty.');
            return;
        }

        if (newUsername === comProfile?.username) {
            toast.error('You already have this username.', {
                className: 'text-warning_blue'
            });
            setError('You already have this username.');
            return;
        }

        try {
            const response = await fetchEditUserName(newUsername);
            if (response === 'username already taken') {
                toast.error('Username already taken', {
                    className: 'text-warning_red'
                });
                setError('Username already taken');
                return;
            }
            setUsername(newUsername);
            setNewUsername('');
            if (comProfile)
                setComProfile({ ...comProfile, username: newUsername });
            toast.success(`${t("profile.community.username.toast")} ${newUsername}!`);
        } catch (error) {
            setError('Failed to update username');
        }
    };

    const selectProfilePic = async (picSrc: string) => {
        const filename = picSrc.split('/').pop();
        if (filename) {
            await fetchEditAvatar(filename);
            setSelectedPic(picSrc);
            if (comProfile)
                setComProfile({ ...comProfile, avatar: filename });
            toast.success(`${t("profile.community.picture.toast")}`);
        }
    };


    return (
        <div className="profile-community p-5">
            <Card
                className="flex flex-col p-4 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mb-4 max-w-full justify-center"
            >
                <CardHeader>
                    <CardTitle className="text-lg md:text-2xl font-bold text-[#133867] dark:text-[#D1E7FF]">{t("profile.community.title")}</CardTitle>
                    <CardDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">{t("profile.community.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    {/* username */}
                    <div className="text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4">
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.general.username.title")}</h2>
                        <div className='flex items-center space-x-4 flex-col md:flex-row'>
                            <Input
                                type="text"
                                className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                                value={newUsername}
                                onChange={handleUsernameChange}
                                placeholder={username}
                                id="username"
                            />
                            <div className="pt-3 md:pt-0"/>
                            <Button
                                className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold px-4 rounded focus:outline-none focus:shadow-outline" 
                                onClick={updateUsername}
                            >
                                {t("profile.community.username.button")}
                            </Button>
                            {error && <p className="error">{error}</p>}
                        </div>
                    </div>
                    {/* profile picture */}
                    <div className="text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4">
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.community.picture.title")}</h2>
                        <div className="grid grid-cols-3 sm:grid-cols-6 gap-4">
                            {profilePics.map((pic, index) => (
                                <div 
                                    key={index} 
                                    className={`cursor-pointer rounded-full p-1 ${selectedPic === pic.src ? 'ring-4 ring-[#B53F9D] dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                    onClick={() => selectProfilePic(pic.src)}
                                    style={{ width: '4rem', height: '4rem' }}  // Default small size
                                    //style={{ width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  // Ensures container size
                                >
                                    <Avatar
                                        key={index}
                                        className="rounded-full w-full h-full object-cover"
                                    >
                                        <AvatarImage src={pic.src}/>
                                        <AvatarFallback>{pic.alt} </AvatarFallback>
                                    </Avatar>
                                </div>
                            ))}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default ProfileCommunity;
