import axiosInstance from "../api";
import { GlbProfile, Password } from "../../interfaces/profile/profile";


export const fetchGetGlbProfile = async (): Promise<GlbProfile | null> => {
    const response = await axiosInstance.get<GlbProfile | null>('/users/profile/get');
    return response.data;
}

export const fetchEditMail  = async (newMail: string): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/mail/update/${newMail}`);
    return response.data;
}

export const fetchEditFirstName  = async (firstName: string): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/firstName/update/${firstName}`);
    return response.data;
}

export const fetchEditLastName  = async (lastName: string): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/lastName/update/${lastName}`);
    return response.data;
}

export const fetchEditLanguage  = async (language: string): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/language/update/${language}`);
    return response.data;
}

export const fetchEditTheme  = async (theme: string): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/theme/update/${theme}`);
    return response.data;
}

export const fetchEditPassword  = async (newPassword: Password): Promise<string> => {
    const response = await axiosInstance.patch<string>(`/users/password/update`, newPassword);
    return response.data;
}