//import { auth } from "@clerk/nextjs";
import { InputType, ReturnType } from "./types";
//import { db } from "@/lib/db";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { CreateCard } from "./schema";
//import { last } from "lodash";

import axiosInstance from "../../api";
import { Card } from "../../../interfaces/tasks/int-card";

import { ActionState } from "../../../lib/create-safe-actions";

const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting create card API call");
    try {
        //console.log('data create card API call :', data);
        const response = await axiosInstance.post<Card>(`/cards/addCard`, data);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const fetchCreateCard = createSafeAction(CreateCard, handler);
