import axiosInstance from "../api";

export interface ServerData {
    id: string;
    name: string;
    image_url: string;
}


export const findServers = async (): Promise<ServerData[]> => {

    const response = await axiosInstance.get<ServerData[]>('/com-server/all');

    return response.data;
};

