import { useEffect, useState } from "react";
import { SelectedCategoryState } from "../profile-techwatch"
import { Topic } from "../../../../../interfaces/techwatch/int-techwatch";
import { fetchDeleteCategoryFromUser, fetchTCCategoriesSelectedUser, fetchTopics, fetchaddCategoryToUser, fetchInitialTagCounts } from "../../../../../api/techwatch/api-tec-profile";

import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group"
import { Button } from "../../../../../components/ui/button";

import { ArrowRight } from "lucide-react";

import { toast } from "sonner";
import { useTranslation } from "react-i18next";

interface CategoryListProps {
    selectedCategory: SelectedCategoryState;
    setSelectedCategory: (category: SelectedCategoryState) => void;
    setSelectedCategories(categories: string[]): void;
}

const CategoryLists = ({
    selectedCategory,
    setSelectedCategory,
    setSelectedCategories,
}: CategoryListProps) => {
    const { t } = useTranslation();
    //console.log('category list component');
    // download the list of tags for the selected category
    const [categoryList, setCategoryList] = useState<Topic[]>([]);
    const [selectedCategoriesUser, setSelectedCategoriesUser] = useState<string[]>([]);

    useEffect(() => {
        const getCategoryList = async () => {
            const response = await fetchTopics();
            setCategoryList(response);
        };
        getCategoryList();
    }, []);

    // get already selected tags for the category
    useEffect(() => {
        const getSelectedLists = async () => {
            const response = await fetchTCCategoriesSelectedUser();
            //console.log('category selected list:', response);
            setSelectedCategoriesUser(response);
            setSelectedCategories(response); // to display the button go to feeds
        };
        getSelectedLists();
    }, []);

    const [selectedTagsCount, setSelectedTagsCount] = useState(new Map<string, number>());

    useEffect(() => {
        //console.log("useEffect categories count");
        const loadInitialTagCounts = async () => {
            try {
                const counts = await fetchInitialTagCounts(); // This needs to be defined in your API layer
                const countsMap = new Map(counts.map(item => [item.topic_id, item.count]));
                setSelectedTagsCount(countsMap);
            } catch (error) {
                //console.error("Error fetching initial tag counts:", error);
            }
        };
        loadInitialTagCounts();
    }, [selectedCategory, selectedCategoriesUser]);

    const handleCategoryChange = async (currentSelected: string[]) => {
        const previousSelected = selectedCategoriesUser;

        // Determine topics added
        const addedTopics = currentSelected.filter(topic => !previousSelected.includes(topic));

        if (addedTopics.length > 0) {
            
            for (let i = 0; i < addedTopics.length; i++) {
                const response = await fetchaddCategoryToUser(addedTopics[i])
                if (response) {
                    toast.success(`Category added`);
                }
            }
            // Update state
            setSelectedCategoriesUser(currentSelected);
            setSelectedCategories(currentSelected); // to display the button go to feeds
            const retrievedCategory = categoryList.find(topic => topic.id === addedTopics[0]);
            // Delay to ensure the category is added before setting it as selected => see how we make it evolve after the UX review
            setTimeout(() => {
                retrievedCategory && setSelectedCategory(retrievedCategory);
              }, 100);
        }

        // Determine topics removed
        const removedTopics = previousSelected.filter(topic => !currentSelected.includes(topic));

        if (removedTopics.length > 0) {
            let response = [''];     
            for (let i = 0; i < removedTopics.length; i++) {
                response = await fetchDeleteCategoryFromUser(removedTopics[i])
                toast.success(`Category removed`);
            }
            //console.log('response api delete = ', response);
            // Update state
            setSelectedCategoriesUser(response);
            setSelectedCategories(response); // to display the button go to feeds
        }
    };

    const handleGoToTags = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, topic: Topic) => {
        event.stopPropagation();  // This prevents the click event from propagating to the parent toggle item
        setSelectedCategory(topic);  // Navigate to the tags of the topic
    };
    return (
        <div>
            <p className="text-sm block text-[#2764B2] dark:text-[#87BAFF] font-bold mb-2 text-muted-foreground">{t("profile.techwatch.descriptionTopics")}</p>
            <div id='list-of-tags'>
                <ToggleGroup value={selectedCategoriesUser} onValueChange={((value: string[]) => handleCategoryChange(value))} type="multiple">
                    <div className="flex flex-wrap pt-2">
                        {categoryList && categoryList.map((topic: Topic, index: number) => (
                            <div key={topic.id} className="flex items-center m-1">
                                <ToggleGroupItem 
                                    key={topic.id} 
                                    value={topic.id}
                                    variant="default"
                                    className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-[#BBD9FF] hover:text-neutral_grey-500 data-[state=on]:bg-techwatch_primary data-[state=on]:text-neutral_white-200 dark:data-[state=on]:bg-[#3C72B9] bg-[#EBF1FA] dark:bg-[#5E5A5D]"
                                >
                                    {topic.topics_category}
                                    {selectedTagsCount.get(topic.id) ? ` (${selectedTagsCount.get(topic.id)})` : ""}
                                    {selectedCategoriesUser.includes(topic.id) && (
                                        <Button onClick={(event) => handleGoToTags(event, topic)} className="ml-2 text-sm h-full rounded-full bg-transparent hover:bg-techwatch_secondary p-0">
                                            <ArrowRight size={16} className="m-0 p-0"/>
                                        </Button>
                                    )}
                                </ToggleGroupItem>
                            </div>
                        ))}
                    </div>
                </ToggleGroup>
            </div>
        </div> 
     );
}
 
export default CategoryLists;