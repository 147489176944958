import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';

interface ContactHook {

    selectedTabContact: string | undefined;
    isLeftPanelVisible: boolean | undefined;
    selectedContact: string | undefined;
    newContact: string | undefined;
    initialSelectedContact: string | undefined;
    
    actions: {
        setSelectedTabContact: (selectedTabContact: string | undefined) => void;
        setIsLeftPanelVisible: (isLeftPanelVisible: boolean | undefined) => void;
        setSelectedContact: (selectedContact: string | undefined) => void;
        setNewContact: (newContact: string | undefined) => void;
        setInitialSelectedContact: (selectedContact: string | undefined) => void;
		// set tokens on the app start
		init: () => void;
		clear: () => void;
        clearContactHook: () => void;
	}
}

const contactHook = createStore<ContactHook>()(
    devtools(
        (set, get) => ({
            selectedTabContact: undefined,
            isLeftPanelVisible: false,
            selectedContact: undefined,
            newContact: undefined,
            initialSelectedContact: undefined,

            
            actions: {
                setSelectedTabContact: (selectedTabContact: string | undefined) => {
                    set({
                        selectedTabContact,
                    });
                },
                setIsLeftPanelVisible: (isLeftPanelVisible: boolean | undefined) => {
                    //console.log('isAthenticated hook', isLeftPanelVisible);
                    set({
                        isLeftPanelVisible,
                    });
                },
                setSelectedContact: (selectedContact: string | undefined) => {
                    set({
                        selectedContact,
                    });
                },
                setNewContact: (newContact: string | undefined) => {
                    set({
                        newContact,
                    });
                },
                setInitialSelectedContact: (contact: string | undefined) => {
                    set({
                        initialSelectedContact: contact,
                    });
                },
                init: () => {
                    const { setIsLeftPanelVisible } = get().actions;
                    setIsLeftPanelVisible(false);
                    //add init selected contact
                },
                clear: () => {
                    set({
                        isLeftPanelVisible: undefined,
                    });
                },
                clearContactHook: () => {
                    set({
                        selectedTabContact: undefined,
                        isLeftPanelVisible: false,
                        selectedContact: undefined,
                        newContact: undefined,
                        initialSelectedContact: undefined,
                    });
                }
            }
        }),
        {
            name: 'auth-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof contactHook, U>>;

// Selectors
const selectedTabContactSelector = (state: ExtractState<typeof contactHook>) => state.selectedTabContact;
const actionsSelector = (state: ExtractState<typeof contactHook>) => state.actions;
const isLeftPanelVisibleSelector = (state: ExtractState<typeof contactHook>) => state.isLeftPanelVisible;
const selectedContactSelector = (state: ExtractState<typeof contactHook>) => state.selectedContact;
const newContactSelector = (state: ExtractState<typeof contactHook>) => state.newContact;
const initialSelectedContactSelector = (state: ExtractState<typeof contactHook>) => state.initialSelectedContact;

// getters
export const getSelectedTabContact = () => selectedTabContactSelector(contactHook.getState());
export const getContactsHookActions = () => actionsSelector(contactHook.getState());

function useContactHookStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(contactHook, selector, equalityFn);
}

// Hooks
export const useSelectedTabContact = () => useContactHookStore(selectedTabContactSelector);
export const useContactHookActions = () => useContactHookStore(actionsSelector);
export const useIsLeftPanelVisible = () => useContactHookStore(isLeftPanelVisibleSelector);
export const useSelectedContact = () => useContactHookStore(selectedContactSelector);
export const useNewContact = () => useContactHookStore(newContactSelector);
export const useInitialSelectedContact = () => useContactHookStore(initialSelectedContactSelector);
