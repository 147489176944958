import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Skeleton } from "../../../../../components/ui/skeleton";

import { Textarea } from "../../../../../components/ui/textarea";


interface OppyDescriptionUnitProps {
    data: Opportunity;
}

export const OppyDescriptionUnit = ({
    data,
}: OppyDescriptionUnitProps) => {

    const { t } = useTranslation();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setDescription(data.description);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity");
        }
    });

    const [description, setDescription] = useState(data.description);

    // Adding a state to manage the editing state of the title (to avoid the onBlur event to be triggered when clicking on the title)
    const [isEditing, setIsEditing] = useState(false);

    const onBlur = () => {
        if (description !== data.description) {
            inputRef.current?.form?.requestSubmit();
        } else {
            setIsEditing(false);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const description = formData.get("description") as string;
        
        if (description === data.description) {
            setIsEditing(false);
            return;
        }

        if (data.id) {
            executeUpdateCard({
                id: data.id,
                description,
            });
        } 
    }
    
    
    useEffect(() => {
        if (isEditing && inputRef.current) {
            const adjustment = 2;
            inputRef.current.style.height = 'auto'; // Reset height to shrink back if text is deleted
            const idealHeight = inputRef.current.scrollHeight + adjustment;
            inputRef.current.style.height = `${idealHeight}px`;
        }

      }, [isEditing, description]);
    
    return (
        <div className="flex items-start gap-x-3 w-full text-xs md:text-sm">
            <form onSubmit={onSubmit} className="w-full">
                {isEditing ? (
                    <Textarea
                        placeholder={t("contacts.opportunities.description.placeholder")}
                        ref={inputRef}
                        onBlur={onBlur}
                        id="description"
                        name="description"
                        //defaultValue={title}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={1}
                        className="w-full items-center text-sm px-1 pt-1 bg-transparent dark:bg-transparent dark:text-[#FFDECD] border-transparent relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                        style={{ minHeight: `36px`, lineHeight: '1.5' }}
                        disabled={!data.id}
                    />
                ) : (
                    <div
                        //className="w-full font-semibold text-sm md:text-lg dark:bg-transparent dark:text-[#FFDECD] dark:hover:border-tasks_primary dark:hover:border-[1px] px-1 py-1 items-center cursor-pointer"
                        className="cursor-pointer"
                        style={{ whiteSpace: 'pre-wrap' }} // Ensures line breaks are preserved
                        onClick={() => setIsEditing(true)}
                    >
                        {description ? description : (
                            <span className="italic">
                                {t("contacts.opportunities.description.placeholder")}
                            </span>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
};

OppyDescriptionUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };
