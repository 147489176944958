import { z } from "zod";

const CreateAction = z.object({
    //id: z.string().optional(),
    //envent_id: z.optional(z.string()),
    type: z.optional(z.string().nullable()),
    title: z.string(),
    description: z.optional(z.string().nullable()),
    deadline_date: z.optional(z.string().nullable()),
    reminder_date: z.optional(z.string().nullable()),
  });

export const CreateEvent = z.object({
  //id: z.string().optional(),
  contact_id: z.string(),
  title: z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  }),
  description: z.optional(z.string().nullable()),
  date: z.optional(z.string().nullable()),
  type: z.optional(z.string().nullable()),
  location: z.optional(z.string().nullable()),
  actions: z.optional(z.array(CreateAction)),
});

