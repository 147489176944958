import { InputType, ReturnType } from "./types";
import { CreateBoard } from "./schema";
import { createSafeAction } from "../../../lib/create-safe-actions";

import axiosInstance from "../../api";
import { Board } from "../../../interfaces/tasks/int-board";
/*
export const fetchCreateBoard = async (data: InputType): Promise<ReturnType> => {
    const response = await axiosInstance.post<ReturnType>(`/boards/addBoard`, data);
    return response.data;
  };
*/
const handler = async (data: InputType): Promise<ReturnType> => {
    const response = await axiosInstance.post<Board>(`/boards/addBoard`, {
      title: data.title,
      template: data.template || 'blank'
    });
    return { data: response.data };
};

export const createBoard = createSafeAction(CreateBoard, handler);