import React, { useRef } from "react";

//import { ThemeProviderLanding } from "./_components/theme-provider";
import { About } from "./_components/About";
import { Hero } from "./_components/Hero";
import { HowItWorks } from "./_components/HowItWorks";

import "./_components/Landing.css";
import { Features } from "./_components/Features";
import { Pricing } from "./_components/Pricing";
import { Cta } from "./_components/Cta";
import { FAQ } from "./_components/FAQ";
import { Newsletter } from "./_components/Newsletter";
import { Services } from "./_components/Services";
import { ScrollToTop } from "./_components/ScrollToTop";

const Landing = () => {

    const landingContainerRef = useRef(null);

    return ( 

            <div 
                className="landing-container flex flex-col w-full h-full overflow-y-auto"
                ref={landingContainerRef}
            >
                <Hero />
                <About />
                <HowItWorks />
                <Features />
                <Services />
                <Pricing />

                <Newsletter />
                <Cta />
                <FAQ />

                <ScrollToTop scrollContainerRef={landingContainerRef} />

            </div>
        
     );
}
 
export default Landing;

/*
<ThemeProviderLanding>
</ThemeProviderLanding>
<Navbar />

    <Sponsors />
<About />
<HowItWorks />
<Features />
    <Services />
<Cta />
    <Testimonials />
    <Team />
<Pricing />
<Newsletter />
<FAQ />
<Footer />
<ScrollToTop />
*/