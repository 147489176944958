import { AlertCircle } from 'lucide-react';
import { Button } from './components/ui/button';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
    let navigate = useNavigate();

    return ( 
        <div className="flex flex-col items-center justify-center">
            <AlertCircle size={64} />
            <h3 className="text-xl font-bold text-career_primary dark:text-[#FF95A0]">Oups this page doesn't exist</h3>
            <Button onClick={() => navigate(`/`)} className="mt-4 bg-career_primary hover:bg-[#AE3F51] hover:text-[#FFBFC4] font-bold py-2 px-4 rounded-lg shadow-lg">
                Go to the main page
            </Button>
        </div>
);
}
 
export default Error404;