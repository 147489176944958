import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
 
import { Button } from "../../../components/ui/button"

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "../../../components/ui/form"
  import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
  } from "../../../components/ui/input-otp"
import { toast } from "../../../components/ui/use-toast"
import { fetchValidateEmail } from "../../../api/globals/api-signup"
import { useActions } from "../../../hooks/auth-store"
   
  const FormSchema = z.object({
    pin: z.string().min(6, {
      message: "Your code must be 6 characters.",
    }),
  })

const EmailValidation = () => {

    // hook pour passer à la page suivante
    const { setSignUpStep} = useActions();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
          pin: "",
        },
      })
    
    async function onSubmit(data: z.infer<typeof FormSchema>) {
        //console.log('data:', data)
        try {
            await fetchValidateEmail(data.pin);
            setSignUpStep('emailValidationDone');
        } catch (err:any) {
            //console.log('Error verifying email or signing up: ', err);
            toast({
                title: "le code renseigné n'est pas valide : ",
            });
        }
    }
    
    return ( 
        <div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-2/3 space-y-6">
                    <FormField
                    control={form.control}
                    name="pin"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>E-mail validation</FormLabel>
                            <FormControl>
                                <InputOTP maxLength={6} {...field}>
                                    <InputOTPGroup>
                                        <InputOTPSlot index={0} />
                                        <InputOTPSlot index={1} />
                                        <InputOTPSlot index={2} />
                                    </InputOTPGroup>
                                        <InputOTPSeparator />
                                    <InputOTPGroup>
                                        <InputOTPSlot index={3} />
                                        <InputOTPSlot index={4} />
                                        <InputOTPSlot index={5} />
                                    </InputOTPGroup>
                                </InputOTP>
                            </FormControl>
                            <FormDescription>
                                Please enter the code sent to your email.
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                    />
            
                    <Button type="submit">Submit</Button>
                </form>
            </Form>

        </div>
     );
}
 
export default EmailValidation;