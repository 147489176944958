import React from 'react';
import logo from '../../img/logo.png'; 
import { useTranslation } from "react-i18next";
import { ModalProvider } from '../../pages/SignUp/modals/modal-provider';
import { useModal } from '../../pages/SignUp/modals/use-modal-store';
import { useToast } from '../ui/use-toast';


const Footer: React.FC = () => {
    const { onOpen } = useModal();
    const { t } = useTranslation();
    const { toast } = useToast();

    const copyEmailToClipboard = async () => {
        try {
            await navigator.clipboard.writeText('contact@zangou.ai');
            toast({
                description: 'Email copied to clipboard!',
              });
        } catch (error) {
            toast({
                description: `Failed to copy email.`,
              });
        }
    };

    return (
        <footer id="footer" className="hidden md:flex bg-[#ECEBEB] text-neutral_black dark:bg-[#161616] dark:text-neutral_white-200 md:px-4 py-2">
            <div className="container mx-auto max-w-7xl flex justify-between items-center space-x-4 ">
                {/* Logo and description */}
                <div className="flex items-center space-x-2">
                    <img src={logo} alt="Logo" className="h-6" />
                    <div className='flex text-center'>
                        <h2 className="xs:text-xs md:text-md xs:font-semibold md:font-semibold">Zangou</h2>
                        <div className="text-xs ml-2">&copy;   Zangou, {new Date().getFullYear()}. All rights reserved.</div>
                    </div>
                </div>
                {/* Quick Links */}
                <div className="flex space-x-3 text-xs items-center">
                    <ModalProvider />
                    <label onClick={copyEmailToClipboard} className="text-neutral_black dark:text-neutral_white-200 hover:text-[#1F5DAA] dark:hover:text-[#87BAFF]">
                        {t("footer.contactUs")}
                    </label>
                    <label htmlFor="GTC" className="py-2 text-neutral_black dark:text-neutral_white-200 hover:text-[#1F5DAA] dark:hover:text-[#87BAFF]">
                        <span onClick={() => onOpen("cguModal")}>{t("footer.GTC")}</span>
                    </label>
                    <label htmlFor="LegalNotice" className="text-neutral_black dark:text-neutral_white-200 hover:text-[#1F5DAA] dark:hover:text-[#87BAFF]">
                        <span onClick={() => onOpen("legalNoticeModal")}>{t("footer.legalNotice")}</span>
                    </label>
                    <label htmlFor="LegalNotice" className="py-2 text-neutral_black dark:text-neutral_white-200 hover:text-[#1F5DAA] dark:hover:text-[#87BAFF]">
                        <span onClick={() => onOpen("privacyPolicyModal")}>{t("footer.privacyPolicy")}</span>
                    </label>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
