import { Button } from "../../../../components/ui/button";
import { Skeleton } from "../../../../components/ui/skeleton";
import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { Card } from "../../../../interfaces/tasks/int-card";
import { Copy, Trash } from "lucide-react";
import { useTranslation } from "react-i18next";

import { useAction } from "../../../../hooks/use-action";
import { copyCard } from "../../../../api/tasks/copy-card";
import { deleteCard } from "../../../../api/tasks/delete-card";
//import { useParams } from "next/navigation";
import { useCardModal } from "../../../../hooks/tasks-use-card-modal";
import { toast } from "sonner";
import { useSelectedBoard, useSelectedBoardElement, useTaskStoreActions } from "../../../../hooks/tasks-store";
import MoveTaskBoardList from "../_component_units/MoveTaskBoardList";

interface ActionsProps {
    data: CardWithList;
  };

export const Actions = ({
    data
}: ActionsProps) => {
    const { t } = useTranslation();

    const selectedBoard = useSelectedBoard();
    const selectedBoardElement = useSelectedBoardElement();
    const { createOrUpdateCard, deleteCardFromList, addTask, deleteTask } = useTaskStoreActions();

    const cardModal = useCardModal();

    const { 
        execute: executeCopyCard,
        isLoading: isLoadingCopy,
    } = useAction(copyCard, {
        onSuccess: (data) => {
            if (selectedBoardElement?.display_mode === "TABLE") {
                addTask(data);
            } else {
                createOrUpdateCard(data);
            }
            toast.success(`Card "${data.title}" copied`);
            cardModal.onClose();
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const { 
        execute: executeDeleteCard, 
        isLoading: isLoadingDelete,
    } = useAction(deleteCard, {
        onSuccess: (data) => {
            if (selectedBoardElement?.display_mode === "TABLE") {
                deleteTask(data.id);
            } else {
                deleteCardFromList(data.id);
            }
            toast.success(`Card "${data.title}" deleted`);
            cardModal.onClose();
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const onCopy = () => {
        const parent_elt = selectedBoard as string;

        executeCopyCard({
            id: data.id,
            parent_elt: data.parent_elt || parent_elt || '',
        });
    };

    const onDelete = () => {
        const parent_elt = selectedBoard as string;

        executeDeleteCard({
            id: data.id,
            parent_elt: data.parent_elt || parent_elt || '',
        });
    };

    return (
        <div className="space-y-2 mt-2">
            <p className="text-xs font-semibold text-neutral-700 dark:text-neutral-300">
                {t("tasks.card.edit.actions.title")}
            </p>
            <div className="flex md:flex-col items-start justify-start gap-1 w-full lg:w-2/3">
                <MoveTaskBoardList id={data.id} />
                <Button
                    onClick={onCopy}
                    disabled={isLoadingCopy || !data.id}
                    variant="task"
                    className="w-full justify-start"
                    //className="flex-grow flex justify-center items-center text-xs bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4 md:mb-2 sm:w-1/2"
                    size="sm"
                >
                    <Copy className="h-3 w-3" />
                    {t("tasks.card.edit.actions.copy")}
                </Button>
                <Button
                    onClick={onDelete}
                    disabled={isLoadingDelete || !data.id}
                    variant="task"
                    className="w-full justify-start"
                    //className="flex-grow flex justify-center items-center text-xs bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4 sm:w-1/2"
                    size="sm"
                >
                    <Trash className="h-3 w-3" />
                    {t("tasks.card.edit.actions.delete")}
                </Button>
            </div>
        </div>
    );
};



Actions.Skeleton = function ActionsSkeleton() {
    return (
      <div className="space-y-2 mt-2">
        <Skeleton className="w-20 h-4 bg-neutral-200" />
        <Skeleton className="w-full h-8 bg-neutral-200" />
        <Skeleton className="w-full h-8 bg-neutral-200" />
      </div>
    );
  };
