import axiosInstance from "../api";

import { Topic, Tag, Topics_Tags, Category_count, Proposed_Source } from "../../interfaces/techwatch/int-techwatch";

export const fetchTopics = async (): Promise<Topic[]> => {
    //console.log('hitting fetchTopics');
    const response = await axiosInstance.get<Topic[]>('/topics');
    return response.data;
};

export const fetchTCCategoriesSelectedUser = async (): Promise<string[]> => {
  //console.log('hitting fetchTCCategoriesSelectedUser');
  const response = await axiosInstance.get<string[]>(`/topics/findCategoriesSelectedUser/`);
  return response.data;
};

export const fetchTCCategoryTags = async (categoryId: string): Promise<Tag[]> => {
    const response = await axiosInstance.get<Tag[]>(`/topics/findCategoryTags/${categoryId}`);
    return response.data;
};

export const  fetchAllTags = async(): Promise<Topics_Tags[]> => {
  const response = await axiosInstance.get<Topics_Tags[]>('/topics/allTags');
  return response.data;
};

export const  fetchAllSelectedTags = async(): Promise<string[]> => {
  const response = await axiosInstance.get<string[]>('/topics/findAllSelectedTags');
  return response.data;
};

export const fetchaddCategoryToUser = async (topic: string): Promise<string> => {
    const response = await axiosInstance.post<string>(`/topics/addCategoryToUser`, {
      topicId: topic,
    });
    return response.data;
  };

export const fetchDeleteCategoryFromUser = async (topic: string): Promise<string[]> => {
  const response = await axiosInstance.delete<string[]>(`/topics/deleteCategoryFromUser/${topic}`);
  return response.data;
};

export const addUniqueTag = async (tagId: string): Promise<Tag[]> => {
  const response = await axiosInstance.post<Tag[]>(`/topics/saveUniqueTag/${tagId}`);
  return response.data;
};

export const deleteUniqueTag = async (tagId: string): Promise<string> => {
  const response = await axiosInstance.delete<string>(`/topics/deleteUniqueTag/${tagId}`);
  return response.data;
};

export const fetchTCCategorySelectedTags = async (categoryId: string): Promise<string[]> => {
  const response = await axiosInstance.get<string[]>(`/topics/findCategorySelectedTags/${categoryId}`);
  return response.data;
};

export const fetchaddMultipleTags = async (categoryId: string, tagsIds: string[]): Promise<Tag[]> => {
    //console.log('groupsIds', tagsIds);
    const response = await axiosInstance.post<Tag[]>(`/topics/saveCategoryTags/${categoryId}`, tagsIds);
    return response.data;
  };


export const fetchInitialTagCounts = async (): Promise<Category_count[]> => {
  const response = await axiosInstance.get<Category_count[]>('/topics/categoryCount');
  return response.data;
};

export const fetchAddProposedSource = async (newSource: Proposed_Source): Promise<Proposed_Source> => {
  const response = await axiosInstance.post<Proposed_Source>('/topics/proposedSource', newSource);
  return response.data;
};
