import { z } from "zod";

export const signInSchema = z.object({
    email: z
        .string()
        .min(1, { message: "Email is required" })
        .max(255, "Email can't be longer than 255 characters")
        .email({ message: "Invalid email address" }),
    password: z.string().min(1, "Password is required"),
});


export const emailSchema = z.object({
email: z.string()
    .min(1, { message: "Email is required" })
    .max(255, "Email can't be longer than 255 characters")
    .email({ message: "Invalid email address" })
});

export type SignInSchema = z.infer<typeof signInSchema>;