import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
  } from "./ui/dropdown-menu"
import { Bell, BellRing } from "lucide-react";
import { Button } from "./ui/button";
import NotifItem from "./notif-item";
import { useEffect, useState } from "react";
import { GetNotifications, fetchGetNotifications, fetchViewedNotification } from "../api/notifications/api-notifications";
import { ScrollArea } from "../components/ui/scroll-area";
import { useTranslation } from "react-i18next";
import { ActionTooltip } from "./action-tooltip";
import { getGeneralHookStoreActions, useUserHasUnviewedNotifications, useUserNotifications } from "../hooks/general-hook";

// Charger les notifications lors du premier rendu
export const loadNotifications = async () => {
    const { setUserNotifications } = getGeneralHookStoreActions();
    try {
        const response = await fetchGetNotifications();
        //const sortedResponse = response.sort((b, a) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        //setNotifications(sortedResponse);
        setUserNotifications(response);
        //const unviewedExist = sortedResponse.some((notification) => notification.viewed === false);
        //setHasUnviewedNotifications(unviewedExist);
    } catch (err) {
        console.error(err);
    }
};

export function Notifications ()  {
    const { t } = useTranslation();
    //const [notifications, setNotifications] = useState<GetNotifications[]>([]);
    //const [hasUnviewedNotifications, setHasUnviewedNotifications] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const userNotifications = useUserNotifications();
    const { setUserNotifications } = getGeneralHookStoreActions();
    const userHasUnnviewedNotifications = useUserHasUnviewedNotifications();

    const markNotificationsAsViewed = async () => {
        //console.log("markNotificationsAsViewed", userNotifications)
        if (userNotifications) {
            const unviewedNotifications = userNotifications.filter((notification) => !notification.viewed);
            //console.log("unviewedNotifications", unviewedNotifications)
            // Utilisez Promise.all pour traiter toutes les notifications non vues en parallèle
            await Promise.all(unviewedNotifications.map((notification) => 
                fetchViewedNotification(notification.id)
            ));
            // Mettre à jour l'état des notifications côté client
            const updatedNotifications = userNotifications.map((notification) => ({
                ...notification,
                viewed: true,  // Marquer comme vu côté client aussi
            }));
            //setNotifications(updatedNotifications);
            setUserNotifications(updatedNotifications);
            //setHasUnviewedNotifications(false);
        }
    };

    // Charger les notifications une seule fois à l'ouverture
    useEffect(() => {
        if (isOpen) {
            loadNotifications();
        }
    }, [isOpen]);


/*
    const refreshNotifications = async () => {
        // Assurez-vous que cette fonction charge à nouveau les notifications comme prévu
        // Par exemple :
        const updatedNotifications = await fetchGetNotifications();
        setNotifications(updatedNotifications);
        // Vous pouvez également vouloir vérifier à nouveau pour des notifications non vues
    };

    useEffect(() => {
        if (isOpen) {
            const loadNotifications = async() => {
                try {
                const response = await fetchGetNotifications();
                const sortedResponse = response.sort((b, a) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
                setNotifications(sortedResponse);
                const unviewedExist = sortedResponse.some(notification => notification.viewed === false);
                setHasUnviewedNotifications(unviewedExist);
                }
                catch(err) {
                    //console.log(err);
                }
            };
            loadNotifications();
        }
    }, [isOpen,notifications, hasUnviewedNotifications]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        markNotificationsAsViewed();
      };
*/
    //const unreadCount = userNotifications?.filter(notification => !notification.viewed).length;


    return (
        <DropdownMenu 
            onOpenChange = {(open) => {
                if (!open) {
                    markNotificationsAsViewed();
                      // Marquer les notifications comme vues à l'ouverture
                }
            }}
        >
                <DropdownMenuTrigger>
                    <div className="bg-transparent border-0 text-[#000021] dark:text-neutral_white-200">
                        <ActionTooltip label={t("header.notifications.hover")}>
                            <div className="flex">
                                <Bell className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
                                    {userHasUnnviewedNotifications && (
                                        <span className="top-0 right-0 inline-flex items-center justify-center h-4 w-4 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                                            {userHasUnnviewedNotifications}
                                        </span>
                                    )}
                                <span className="sr-only">Notifications</span>
                            </div>
                        </ActionTooltip>
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <ScrollArea className="flex-grow overflow-auto max-h-[20rem]">
                            {userNotifications && userNotifications.filter(x => x.toShow===true).length > 0 ? (
                            userNotifications.filter(x => x.toShow===true).map((notification) => (
                            <NotifItem
                                    key={notification.id}
                                    id={notification.id}
                                    content={notification.content}
                                    timestamp={notification.created_at}
                                    toShow={notification.toShow}
                                    viewed={notification.viewed}
                                    feature={notification.feature}
                                    accept={notification.accept}
                                    cancel={notification.cancel}
                                    done={notification.done}
                                    type={notification.type}
                                    channel_id={notification.channel_id}
                                    tas_elt_id={notification.tas_elt_id}
                                    car_elt_id={notification.car_elt_id}
                                    con_action_id={notification.con_action_id}
                                    onActionTaken={loadNotifications}
                            />
                            ))
                        ) : (
                        <div className="p-4 text-center text-sm">
                          {t("header.notifications.none")}
                        </div>
                    )}
                    </ScrollArea>
                </DropdownMenuContent>
        </DropdownMenu>
     );
}
