import React from 'react';
import img_techwatch from '../../../../img/landing/techwatch.svg';
import img_feed from '../../../../img/landing/techwatch/Feed-bro.svg';
import img_filter from '../../../../img/landing/techwatch/Filter-bro.svg';
import img_summary from '../../../../img/landing/techwatch/Search-bro.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

const HelpTechwatchModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Techwatch";

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-techwatch_primary dark:text-dark_techwatch bg-[#F8F5F5] dark:bg-[#1E1F22] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-3xl text-center font-bold">
            Techwatch
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.landingTechwatch.tagline")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
        <div className="bg-[#F8F5F5] dark:bg-[#1E1F22] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-2">
                <div className="container mx-auto px-4">
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_techwatch} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title1")}</h2>
                                <p className="mt-2 text-sm text-[#133867] dark:text-[#D1E7FF]">
                                    {t("landing.landingTechwatch.desc1")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#EBF1FA] dark:bg-[#0D1723] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_feed} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title2")}</h2>
                                <p className="mt-2 text-sm text-[#133867] dark:text-[#D1E7FF]">
                                    {t("landing.landingTechwatch.desc2")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_filter} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title3")}</h2>
                                <p className="mt-2 text-sm text-[#133867] dark:text-[#D1E7FF]">
                                    {t("landing.landingTechwatch.desc3")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-20 bg-[#EBF1FA] dark:bg-[#0D1723] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_summary} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title4")}</h2>
                                <p className="mt-2 text-sm text-[#133867] dark:text-[#D1E7FF]">
                                    {t("landing.landingTechwatch.desc4")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
        
    );
}

export default HelpTechwatchModal;
