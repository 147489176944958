import { ActionTooltip } from '../../../../components/action-tooltip';
import { Edit, Trash } from 'lucide-react';
import React, { useState } from 'react';
import Speedometer from 'react-d3-speedometer';
import { useModal } from '../modals/use-modal-store';
import { useCareerHookActions } from '../../../../hooks/career-hook';
import { CreationPlan, ExistingPlan } from '../../../../api/career/interface-career';
import { useTranslation } from 'react-i18next'; 

interface PlanComponentProps {
  id: string;
}

const PlanComponent = ({
  id,
}: PlanComponentProps) => {
    const { t } = useTranslation();
    const { onOpen } = useModal();

    const { setSelectedPath } = useCareerHookActions();

    const handlePathSelection = (path: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER" | undefined) => {
        setSelectedPath(path);
    }

    const { getPlanById } = useCareerHookActions();

    const metrics = ['resources', 'entertainment', 'confidence', 'coherence'] as const;

    const MAX_CHAR_COUNT = 200; // Maximum number of characters before truncating

    const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
    const [isContextExpanded, setContextExpanded] = useState(false);

    const toggleDescription = () => setDescriptionExpanded(!isDescriptionExpanded);
    const toggleContext = () => setContextExpanded(!isContextExpanded);

    const selectedPlan: CreationPlan | undefined = getPlanById(id);

    return selectedPlan ? (
        <div className="group border p-4 rounded-lg relative bg-[#F8F5F5] dark:bg-[#1A1213] shadow-sm hover:shadow-md transition-shadow">
            <h2 
                className="text-lg font-bold cursor-pointer text-career_primary dark:text-[#FF95A0]"
                onClick={() => handlePathSelection(selectedPlan?.path)}
            >
                {selectedPlan.title} ({selectedPlan.path})
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
                {isDescriptionExpanded || (selectedPlan.description && selectedPlan.description.length <= MAX_CHAR_COUNT )
                ? selectedPlan.description 
                : `${selectedPlan.description && selectedPlan.description.substring(0, MAX_CHAR_COUNT)}...`}
                {selectedPlan.description && selectedPlan.description.length > MAX_CHAR_COUNT && (
                <span className="cursor-pointer text-career_primary dark:text-[#FBD5D7]" onClick={toggleDescription}>
                    {isDescriptionExpanded ? ' See Less' : ' See More'}
                </span>
                )}
            </p>
            <p className="mt-4 text-md font-semibold text-career_primary dark:text-[#FBD5D7]">{t("career.modals.edit.context")}</p>
            <p className="text-gray-700 dark:text-gray-300">
                {isContextExpanded || (selectedPlan.context && selectedPlan.context.length <= MAX_CHAR_COUNT) 
                ? selectedPlan.context 
                : `${selectedPlan.context && selectedPlan.context.substring(0, MAX_CHAR_COUNT)}...`}
                {selectedPlan.context && selectedPlan.context.length > MAX_CHAR_COUNT && (
                <span className="cursor-pointer text-career_primary dark:text-[#FBD5D7]" onClick={toggleContext}>
                    {isContextExpanded ? ' See Less' : ' See More'}
                </span>
                )}
            </p>


            {selectedPlan.questions && selectedPlan.questions.length > 0 && (
                <div className="mt-4">
                    <h3 className="text-md font-semibold text-career_primary dark:text-[#FBD5D7]">{t("career.createPlan.step4.questions.title")}</h3>
                    <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
                        {selectedPlan.questions.map((question, index) => (
                            <li key={index}>{question.title}</li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="xs:grid xs:grid-cols-2 xs:gap-4 md:flex md:justify-around md:my-4 ">
                {metrics.map((metric) => {
                    const metricValue = selectedPlan[metric] ?? 0; // Safe default
                    return (
                    <div key={metric} className="mb-4">
                    <h3 className="md:hidden font-medium text-xs text-[#AE3F51] dark:text-[#FBD5D7] mt-2 ">{metric.toUpperCase()}</h3>
                    {/* Progress bar for mobile screens */}
                    <div className={`bg-gray-200 rounded-full dark:bg-gray-700 w-full ${selectedPlan[metric] ? 'block' : 'hidden'} md:hidden`}>
                        <div
                            className="bg-[#AE3F51] dark:bg-[#FF95A0] mt-1 text-xs font-medium text-neutral_white-200 text-center p-0.5 leading-none rounded-full"
                            style={{ width: `${metricValue * 10}%` }}
                        >
                            {metricValue}/10
                        </div>
                    </div>
                    {/* Speedometer for larger screens */}
                    <div className={`hidden md:block ${selectedPlan[metric] ? 'block items-center text-center' : 'hidden'}`}>
                         <Speedometer
                            value={selectedPlan[metric] as number}
                            minValue={0}
                            maxValue={10}
                            segments={10}
                            maxSegmentLabels={5}
                            width={140} // Adjusted for smaller screens
                            height={90} // Adjusted for smaller screens
                            //width={150}
                            //height={100}
                            needleColor="#ae3f51"
                            startColor="#ffbfc4"
                            endColor="#ae3f51"
                            textColor="#E4707F"
                            ringWidth={15} // Adjust ring width for smaller dials
                            //ringWidth={20}
                            currentValueText=" "
                        />
                        <span className='font-medium text-[#AE3F51] dark:text-[#FBD5D7]'>
                            {t(`career.createPlan.step4.estimations.${metric}.title`)}
                        </span>
                    </div>
                    </div>
                );
            })}
            </div>
            <div className="hidden group-hover:flex items-center gap-x-2 absolute p-1 top-2 right-5 bg-white dark:bg-zinc-800 border rounded-sm">
                        <ActionTooltip label="Edit">
                            <Edit
                            onClick={() => onOpen(
                                "editPlan", 
                                {
                                    //planId: id, 
                                    existingPlan: { 
                                        id: selectedPlan?.id, 
                                        title: selectedPlan?.title, 
                                        description: selectedPlan?.description, 
                                        context: selectedPlan?.context, 
                                        path: selectedPlan?.path, 
                                        resources: selectedPlan?.resources, 
                                        entertainment: selectedPlan?.entertainment, 
                                        confidence: selectedPlan?.confidence, 
                                        coherence: selectedPlan?.coherence, 
                                        questions: selectedPlan?.questions
                                    } 
                                }
                            )}
                            className="cursor-pointer ml-auto w-4 h-4 text-[#AE3F51] dark:text-[#FF95A0] dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                        <ActionTooltip label="Delete">
                            <Trash
                                onClick={() => onOpen("deletePlan", 
                                    { 
                                        planId: {id}, 
                                        existingPlan: { 
                                            id: selectedPlan?.id, 
                                            title: selectedPlan?.title, 
                                            description: selectedPlan?.description, 
                                            context: selectedPlan?.context, 
                                            path: selectedPlan?.path, 
                                            resources: selectedPlan?.resources, 
                                            entertainment: selectedPlan?.entertainment, 
                                            confidence: selectedPlan?.confidence, 
                                            coherence: selectedPlan?.coherence, 
                                            questions: selectedPlan?.questions
                                        } 
                                    })}
                                className="cursor-pointer ml-auto w-4 h-4 text-[#AE3F51] dark:text-[#FF95A0] dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                </div>
        </div>
        ) : (
            <div>
                Loading ...
            </div>
        )
    };

export default PlanComponent;
