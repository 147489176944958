import { z } from "zod";

export const CreatePlanElement = z.object({
  id: z.string(),
  plan_id: z.string(),
  parent_elt: z.string().optional(),
  title: z.string().min(3, "Title is too short").optional(),
  description: z.string().min(3, "Description is too short").optional(),
  previous_elt: z.optional(z.string()),
  next_elt: z.optional(z.string()),
  category: z.optional(z.enum(["MISSION", "GOCHAT", "GOLEARN", "TECHWATCH", "VISIBILITY", "PERSONAL", "MAIN", "NONE"])),
  type: z.optional(z.enum(["STEP", "PROJECT", "TASK", "CATEGORY"])),
  year: z.optional(z.number()),
  deadline_date: z.date().optional(),
  start_date: z.date().optional(),
  end_date: z.date().optional(),
  deleted: z.optional(z.boolean()),
  created_at: z.optional(z.date()),
  updated_at: z.optional(z.date()),
});

interface ContactTableElement { //to be adapted
    id: string;
    first_name: string;
    last_name: string;
    job_title?: string;
    company_name?: string;
    location?: string;
    email?: string;
    phone_number?: string;
};

export interface Contact {
  first_name: string;
  last_name: string;
  job_title?: string;
  company_name?: string;
  location?: string;
  phone_number?: string;
  email?: string;
  groups?: string; // Groups (comma-separated in the Excel file)
  interests?: string; // Interests (comma-separated in the Excel file)
  address?: string;
  linkedin?: string;
  meta?: string;
  other_rs?: string;
}
