// FooterBar.tsx
import { Home, Flame, Bookmark, Search, User } from "lucide-react";
import { useSelectedTab, useTechwatchStoreActions } from "../../../hooks/techwatch-store";

const BottomNavTechwatch = () => {
    const { setSelectedTab } = useTechwatchStoreActions();

    const handleSelectedTab = (tab: string) => {
        //console.log("changing Selected tab: ", tab);
        setSelectedTab(tab);
    };

    const selectedTab = useSelectedTab();

    const isSelected = (tab: string) => {
        return tab === selectedTab;
    };

    const availableTabs = ['General', 'Personalized', 'Trending-topics', 'Browse by category', 'Bookmarks', 'Profile'];

    // Create a function that returns conditional class names based on tab selection
    const getIconClasses = (tab: string) => 
      isSelected(tab) 
          ? "text-techwatch_primary fill-techwatch_primary dark:text-[#3C72B9] dark:fill-dark_techwatch_primary" // Apply a primary color for the selected tab [#3C72B9]
          : "text-gray-600 dark:text-[#3C72B9] hover:text-techwatch_secondary"; // Apply secondary hover color for non-selected tabs

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-around bg-[#F3F6FA] dark:bg-[#0D1723] shadow-md p-3 md:hidden z-50 border-t border-zinc-300 dark:border-zinc-700">
      <button onClick={() => handleSelectedTab("Personalized")} aria-label="Home">
        <Home size={24} className={getIconClasses("Personalized")}/>
      </button>
      <button onClick={() => handleSelectedTab("Browse by category")} aria-label="Search">
        <Search size={24} className={getIconClasses("Browse by category")}/>
      </button>
      <button onClick={() => handleSelectedTab("Bookmarks")} aria-label="Bookmarks">
        <Bookmark size={24} className={getIconClasses("Bookmarks")}/>
      </button>
      <button onClick={() => handleSelectedTab("Profile")} aria-label="Profile">
        <User size={24} className={getIconClasses("Profile")}/>
      </button>
    </div>
  );
};

export default BottomNavTechwatch;

/*
<button onClick={() => handleSelectedTab("Personalized")} aria-label="Trending">
  <Flame size={24} className={getIconClasses("Personalized")}/>
</button>
*/
