// Card Modal Component
// This component is responsible for displaying the modal that appears when a card is clicked.
// It displays the card's details :
// title, description, and actions.
// It also allows the user to edit the card's details.
// It allows the user to copy and delete the card.
// It uses the useCardModal hook to get the card's id and open/close the modal.
// It uses the useTaskStoreActions hook to get the card's details.
// It uses the Dialog component from the ui folder to display the modal.
// It uses the Header, Description, and Actions components to display the card's details.

import { toast } from "sonner";
import { useEffect, useState } from "react";

import { Dialog, DialogContent } from "../../../../components/ui/dialog";

import { useCardModal } from "../../../../hooks/tasks-use-card-modal";
import { useSelectedBoardElement, useTaskStoreActions } from "../../../../hooks/tasks-store";

import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { Board, DisplayMode } from "../../../../interfaces/tasks/int-board";
import { List } from "../../../../interfaces/tasks/int-list";
import { Card } from "../../../../interfaces/tasks/int-card";

import { Header } from "./header";
import { Description } from "./description";
import { Actions } from "./actions";
import { use } from "i18next";
import { cn } from "../../../../lib/utils";


export const CardModal = () => {
    
    const id = useCardModal((state) => state.id);
    const isOpen = useCardModal((state) => state.isOpen);
    const onClose = useCardModal((state) => state.onClose);

    const { getCardById, getTasksById, getBoards, getSelectedBoardElement } = useTaskStoreActions();

    // get board display mode
    const selectedBoardElement = useSelectedBoardElement();
    
    const [ selectedCard, setSelectedCard ] = useState<CardWithList | undefined>(undefined);

    useEffect(() => {
        const retrieveCardOrTask = async () => {
            if (isOpen) {
                if (!id) {
                    // Initialize a new card for "create" mode
                    const boards = getBoards();
                    const defaultList: List = {
                        id: 'default-list-id',
                        title: 'Default List',
                        board_id: boards?.filter((board: Board) => board.title === "Overview")[0].id || 'noBoardId',
                        order: 100,
                        // Add any other required properties for a List object
                      };
                    
                    const newCard: CardWithList = {
                        id: '', // Temporary ID, to be generated when the card is saved
                        title: '',
                        description: '',
                        parent_elt: selectedBoardElement?.id || '', //selectedBoardElement?.title !== "Overview" ? (selectedBoardElement?.id || '') : '', 
                        list: selectedBoardElement?.lists ? selectedBoardElement.lists[0] : defaultList, 
                        duration: undefined,
                        energy: 'MEDIUM',
                        due_date: undefined,
                        type: 'TASK',
                        status: 'TO_DO',
                        list_id: selectedBoardElement?.lists ? ((selectedBoardElement.lists.length !== 0 && selectedBoardElement.lists[0].id) ? selectedBoardElement.lists[0].id : defaultList.id) : defaultList.id,
                        order: 100000,
                        order_list: 100000,
                    };

                    if (selectedBoardElement?.lists) {
                        newCard.list = selectedBoardElement.lists[0];
                    }

                    setSelectedCard(newCard);
                } else {
                    if (id && selectedBoardElement?.display_mode === 'LIST') {
                        setSelectedCard(getCardById(id))
                        //selectedCard = getCardById(id)
                    } else if (id && selectedBoardElement?.display_mode === 'TABLE') {
                        const taskWithList = await getTasksById(id);
                        setSelectedCard(taskWithList)
                        //selectedCard = taskWithList;
                        //selectedCard = getCardById(id)
                    } else {
                        toast.error('No task selected');
                        onClose();
                    }
                }

/*
                if (id) {
                    if (id && selectedBoardElement?.display_mode === 'LIST') {
                        setSelectedCard(getCardById(id))
                        //selectedCard = getCardById(id)
                    } else if (id && selectedBoardElement?.display_mode === 'TABLE') {
                        const taskWithList = await getTasksById(id);
                        setSelectedCard(taskWithList)
                        //selectedCard = taskWithList;
                        //selectedCard = getCardById(id)
                    } else {
                        toast.error('No task selected');
                        onClose();
                    }
                } else {
                    // Initialize a new card for "create" mode
                    const boards = getBoards();
                    const defaultList: List = {
                        id: 'default-list-id',
                        title: 'Default List',
                        board_id: boards?.filter((board: Board) => board.title === "Overview")[0].id || 'noBoardId',
                        order: 100,
                        // Add any other required properties for a List object
                      };
                    
                    const newCard: CardWithList = {
                        id: '', // Temporary ID, to be generated when the card is saved
                        title: '',
                        description: '',
                        parent_elt: selectedBoardElement?.id || '', //selectedBoardElement?.title !== "Overview" ? (selectedBoardElement?.id || '') : '', 
                        list: selectedBoardElement?.lists ? selectedBoardElement.lists[0] : defaultList, 
                        duration: undefined,
                        energy: 'MEDIUM',
                        due_date: undefined,
                        type: 'TASK',
                        status: 'TO_DO',
                        list_id: selectedBoardElement?.lists ? selectedBoardElement.lists[0].id : defaultList.id,
                        order: 100000,
                        order_list: 100000,
                    };

                    if (selectedBoardElement?.lists) {
                        newCard.list = selectedBoardElement.lists[0];
                    }

                    setSelectedCard(newCard);
                }
                */
            }
        };
    
        // retrieveCardOrTask
        retrieveCardOrTask();
    }, [selectedBoardElement, id, isOpen]);

    return (
        <div className="m-2 sm:m-4 md:m-0">
            <Dialog
                open={isOpen}
                onOpenChange={onClose}
            >
                <DialogContent
                    //className="bg-[#F7F5F4] dark:bg-background border-tasks_primary border-[1px] p-4 md:p-6 md:max-w-3xl md:w-full lg:max-w-4xl lg:w-full xl:max-w-5xl xl:w-full"
                    className={cn("bg-[#F7F5F4] dark:bg-background border-tasks_primary border-[1px] p-4 md:p-6 md:max-w-3xl md:w-full lg:max-w-4xl lg:w-full xl:max-w-5xl xl:w-full",
                        "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                    )}
                >
                    {!selectedCard
                        ? <Header.Skeleton />
                        : <Header data={selectedCard} />
                    }
                    <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">
                        <div className="col-span-4 md:col-span-3">
                            <div className="w-full space-y-6">
                            {!selectedCard
                                ? <Description.Skeleton />
                                : <Description data={selectedCard} />
                            }
                            </div>
                        </div>
                        <div className="flex flex-col flex-grow">
                        {!selectedCard
                            ? <Actions.Skeleton />
                            : <Actions data={selectedCard} />
                        }
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}