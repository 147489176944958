import img_current_path from '../../../../../img/career_plans/Moving forward-bro.svg'
import img_alternative_path from '../../../../../img/career_plans/Business mission-bro.svg';
import img_dream_path from '../../../../../img/career_plans/Restorer-bro.svg';
import img_other_path from '../../../../../img/career_plans/Design thinking-bro.svg';
import { Button } from '../../../../../components/ui/button';
import { useCareerHookActions, useCreationPlan, useSelectedPath } from '../../../../../hooks/career-hook';
import { useRef } from 'react';
import { useTranslation } from "react-i18next";

interface FeatureProps {
    path: string,
    title: string;
    description: string;
    image: string;
    cta: string;
    reverse: boolean;
    color: string;
    dark_color: string;
    onCtaClick?: () => void;
  }



const NoticePlan = () => {

  const { t } = useTranslation();

  const features: FeatureProps[] = [
      {
        path: 'CURRENT',
        title: t("career.overviewComponents.current.title"),
        description: t("career.overviewComponents.current.description"),
        cta: t("career.overviewComponents.current.cta"),
        image: img_current_path,
        reverse: false,
        color: 'text-career_primary',
        dark_color: 'text-career_primary',
      },
      {
        path: 'ALTERNATIVE',
        title: t("career.overviewComponents.alternative.title"),
        description: t("career.overviewComponents.alternative.description"),
        cta: t("career.overviewComponents.alternative.cta"),
        image: img_alternative_path,
        reverse: true,
        color: 'text-[#6E0021]',
        dark_color: 'text-[#FBD5D7]',
      },
      {
        path: 'DREAM',
        title: t("career.overviewComponents.dream.title"),
        description: t("career.overviewComponents.dream.description"),
        cta: t("career.overviewComponents.dream.cta"),
        image: img_dream_path,
        reverse: false,
        color: 'text-[#D76474]',
        dark_color: 'text-[#D76474]',
      },
      {
        path: 'OTHER',
        title: t("career.overviewComponents.other.title"),
        description: t("career.overviewComponents.other.description"),
        cta: t("career.overviewComponents.other.cta"),
        image: img_other_path,
        reverse: true,
        color: 'text-[#AE3F51]',
        dark_color: 'text-[#FF95A0]',
      },
    ];

    /*      
    const getPathTitle = (path: string): string | undefined => {
        switch (path) {
            case "CURRENT":
            return "Current Path";
            case "ALTERNATIVE":
            return "Alternative Path";
            case "DREAM":
            return "Dream Path";
            case "OTHER":
            return "Other Path";
            default:
            return undefined;
        }
        };
    */
    
    const selectedPath = useSelectedPath();

    const creationPlan = useCreationPlan();

    const {setCreatingPlan, setCreationPlan } = useCareerHookActions();

    //const selectedPathTitle = selectedPath ? getPathTitle(selectedPath) : undefined;

    //const feature = selectedPathTitle ? features.find(f => f.title === selectedPathTitle) : undefined;

    const feature = selectedPath ? features.find(f => f.path === selectedPath) : undefined;


    const Feature: React.FC<FeatureProps> = ({ path, title, description, image, cta, reverse, color, dark_color, onCtaClick }) => {
        return (
          <div className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center gap-4 md:gap-8 p-4 md:p-8`}>
            <div className="flex justify-center md:flex-1">
              <img src={image} alt={title} className="w-full md:max-w-md h-auto mx-auto" />
            </div>
            <div className="flex-1 flex flex-col justify-center items-start text-left space-y-2 md:space-y-4">
              <h2 className={`text-lg md:text-xl font-semibold ${color} dark:${dark_color}`}>{title}</h2>
              <p className="text-sm md:text-base text-justify text-[#AE3F51] dark:text-[#FBD5D7]">{description}</p>
              <div className="flex">
                <Button 
                    className={`bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4`}
                    onClick={onCtaClick}
                >
                  {cta}
                </Button>
              </div>
            </div>
          </div>
        );
      };

    const createPlanRef = useRef<HTMLDivElement>(null);

    const handleCreatePlanClick = () => {
        //console.log("setCreatingPlan(true)");
        setCreatingPlan(true);
        setCreationPlan({
            path: selectedPath,
            creating_plan: true,
            current_step: 1,
        })
    };


    return (
        <div className="flex flex-col">
            <div className="text-center p-8">
                <h1 className="text-lg md:text-xl font-bold dark:text-[#FF95A0] text-career_primary">
                    {t("career.overviewLanding.top.title")}
                </h1>
                <p className="text-sm md:text-base mt-2 text-[#AE3F51] dark:text-[#FBD5D7]">
                  {t("career.overviewLanding.top.description")}
                </p>
            </div>
            {feature ? (
            <Feature
                    path={feature.path}
                    title={feature.title}
                    description={feature.description}
                    image={feature.image}
                    cta={feature.cta}
                    reverse={feature.reverse}
                    color={feature.color}
                    dark_color={feature.dark_color}
                    onCtaClick={handleCreatePlanClick}
            />
            ) : (
            <p>Oups, something went wrong here</p>
            )}
        </div>
    );
}
 
export default NoticePlan;