import axiosInstance from "../api";
import { Interest } from "../../hooks/contact-store";
import { CreateInterest } from "./interface-contact";

export const fetchUserInterests = async (): Promise<Interest[]> => {
    const response = await axiosInstance.get<Interest[]>(`/contact-interests/`);
    return response.data;
  };

export const fetchAddInterestContact = async (contactId: string, interest: Interest): Promise<{ newInterest: Interest }> => {
      const response = await axiosInstance.post<{ newInterest: Interest }>(`/contact-interests/addInterest`, {
        contactId: contactId,
        interest: interest,
      });
      return response.data;
    };

export const fetchaddMultipleInterestsContact = async (contactId: string, interestsIds: string[]): Promise<{ newContactInterests: Interest[] }> => {
  const response = await axiosInstance.post<{ newContactInterests: Interest[] }>(`/contact-interests/addMultipleInterests`, {
    contactId: contactId,
    interestsIds: interestsIds,
  });
  return response.data;
};

export const fetchDeleteInterestContact = async (contactId: string, interestId: string): Promise<string> => {
    const response = await axiosInstance.delete<string>(`/contact-interests/removeInterestContact`, {
        data: {
            contact_id: contactId,
            interest_id: interestId,
        },
    });
    return response.data;
};

export const fetchAddGlobalInterest= async (newInterest: CreateInterest): Promise<Interest> => {
  const response = await axiosInstance.post<{ newInterest: Interest }>(`/contact-interests/addGlobalInterest`,
    {interest: newInterest}
  );
  return response.data.newInterest;
};

export const fetchUpdateGlobalInterest= async (newInterest: Interest): Promise<Interest> => {
  const response = await axiosInstance.post<{ newInterest: Interest }>(`/contact-interests/updateGlobalInterest`,
    {interest: newInterest}
  );
  return response.data.newInterest;
};

export const fetchDeleteGlobalInterest = async (interestId: string): Promise<string> => {
  const response = await axiosInstance.delete<string>(`/contact-interests/deleteInterest/${interestId}`);
  return response.data;
};

