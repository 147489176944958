import { useEffect, useState, useRef, ElementRef } from 'react';
import { Loader2 } from 'lucide-react';
import ChatItem from "./chat-item";
import { ChatWelcome } from "./chat-welcome";
import { fetchLimitedMessages } from "../../../../api/community/api-messages";
import { format } from "date-fns";
import { useWebSocket } from '../../../../hooks/socket-context';
import { useCommunityActions, useHasNextPage, useMessages, usePage, useSelectedChannel } from '../../../../hooks/community-hook';


const DATE_FORMAT = "d MMM yyyy, HH:mm";

interface ChatMessagesProps {
    name: string;
    channelId: string;
    type: "channel" | "conversation";
}

const ChatMessages = ({ name, channelId, type }: ChatMessagesProps) => {
    const { socket } = useWebSocket();

    // Pour gérer le chargement séquencé des messages
    const selectedChannel = useSelectedChannel();
    const { updateManyMessages, setPage } = useCommunityActions();
    const messages = useMessages();
    const page = usePage();
    const loader = useRef(null);
    const messagesRef = useRef<{ [key: string]: any }>({}).current; // To store refs for each message card
    const [isLoading, setIsLoading] = useState(false);
    const hasNextPage = useHasNextPage();

    //Socket Usage
    const { createMessage, updateMessage, deleteMessage } = useCommunityActions();

    //test ref
    const chatRef =  useRef<ElementRef<"div">>(null);
    const bottomRef =  useRef<ElementRef<"div">>(null);

    useEffect(() => {
        // Ne Charger qu'une partie des messages
        const loadLimitedMessages = async () => {
            setIsLoading(true);
            if(selectedChannel) {
                try {
                    const response = await fetchLimitedMessages(selectedChannel.id, page, 20);
                    const sortedMessages = response.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
                    //console.log("Sorted Messages:", response);
                    updateManyMessages(sortedMessages);
                    //setMessages(response);
                    }
                    catch(error) {
                        //console.log("error: ", error);
                    } finally {
                        setIsLoading(false);
                    }
            }
        };
        loadLimitedMessages();

        }, [selectedChannel, page]);

    //infinte scroll logic
    useEffect(() => {
        var options = {
          root: null, // using the viewport as root
          rootMargin: "20px",
          threshold: 1.0
        };
        // Callback for IntersectionObserver
        const handleObserver = (entities: IntersectionObserverEntry[]) => {
          const target = entities[0];
          if (target.isIntersecting && !isLoading) {
            //fetchNextPage();
            setPage(page+1);
          }
        };
        // Create an intersection observer instance
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current);
        }
        if (bottomRef.current && page===1) {            
            bottomRef.current?.scrollIntoView({
                behavior: "auto",
                block: "nearest"
            });

        }
        // Clean up
        return () => observer.disconnect();
      }, [messages]);


      //Socket Usage
      useEffect(() => {

        if (socket) {
            socket.on('messageCreated', createMessage);
            socket.on('messageUpdated', updateMessage);
            socket.on('messageDeleted', (deletedMessage) => deleteMessage(deletedMessage));

            return () => {
                socket.off('messageCreated', createMessage);
                socket.off('messageUpdated', updateMessage);
                socket.off('messageDeleted', (deletedMessage) => deleteMessage(deletedMessage));
            };
        }
    }, [socket, createMessage, updateMessage, deleteMessage]);

    return (
        <div 
            ref={chatRef} 
            className="flex-1 flex flex-col py-4 overflow-y-auto"
        >
            {!hasNextPage && (
                <ChatWelcome
                type={type}
                name={name}
                />
            )}
            {
                isLoading ? (
                    <Loader2 className="h-6 w-6 text-zinc-500 animate-spin my-4" />
                ) : (
                    messages && hasNextPage && (
                        <div ref={loader} className="flex justify-center">
                            <button
                                onClick={() => setPage(page+1)}
                                className="text-zinc-500 hover:text-zinc-600 dark:text-zinc-400 text-xs my-4 dark:hover:text-zinc-300 transition"
                            >
                                Load previous messages
                            </button>
                        </div>
                    )
                )
            }
            {messages && messages.length > 0 && messages.map((message) => (
                    <ChatItem
                        key={message.id}
                        ref={messagesRef[message.id]}
                        id={message.id}
                        content={message.content}
                        userName={message.user_name}
                        avatar={message.avatar}
                        memberId={message.member_id}
                        timestamp={format(new Date(message.created_at), DATE_FORMAT)}
                        isUpdated={message.updated_at !== message.created_at}
                        deleted={message.deleted}
                        memberRole = {message.member_role}
                    />
                ))}
            <div ref={bottomRef} />
        </div>
        );
    };

export default ChatMessages;
