import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage} from "usehooks-ts";

import { cn } from "../../lib/utils";

import { Separator } from "../../components/ui/separator";
import { Skeleton } from "../../components/ui/skeleton";
import { Accordion } from "../../components/ui/accordion";

import { NavItemTasks } from "../../pages/Tasks/_components/side-menu/nav-item";
import { NavItemTechWatch } from "../../pages/TechWatch/_components/sidebar/nav-items-techwatch";
import { NavItemOtherTools } from "./nav-items-other-tools";
import { NavItemContacts } from "../../pages/Contacts/_components/sidebar/nav-items-contacts";
import { NavItemCareer } from "../../pages/CareerPath/_components/side-menu/nav-items-career";
import { NavItemCommunity } from "../../pages/Community/_components/sidebar/nav-items-community";
import { NavItemProfile } from "../../pages/Profile/_components/sidebar/nav-item-profile";


// Define a mapping of membership to component
const membershipComponents: Record<string, React.ElementType> = {
    Tasks: NavItemTasks,
    Techwatch: NavItemTechWatch,
    Contacts: NavItemContacts,
    Career: NavItemCareer,
    Community: NavItemCommunity,
    Profile: NavItemProfile,
    // Add other memberships and their corresponding components
    DefaultNavItem: NavItemTasks, // To be replaced with a neutral component 
  };

interface SidebarProps {
    storageKey?: string;
};

export const SidebarGeneral = ({
    storageKey = "t-sidebar-state",
}: SidebarProps) => {

    const location = useLocation();
    //console.log('location')
    //console.log(location)
    const isActive = (path: string) => {
        return location.pathname === path
    }

    const [expanded, setExpanded] = useLocalStorage<Record<string, any>>(
        storageKey,
         {});
    const [isInitialized, setIsInitialized] = useState(false);
    //console.log('storage key', storageKey);
    //console.log('expanded', expanded);

    const userMemberships = ['Career', 'Techwatch', 'Contacts', 'Community', 'Tasks', 'Profile']; // 'Visibility',
    const isLoaded = true;

    const defaultAccordionValue: string[] = Object.keys(expanded).reduce((acc: string[], key: string) => {
        if (expanded[key]) {
            acc.push(key);
        }

        return acc;
    }, []);

    const onExpand = (id: string) => {
        setExpanded((curr) => ({
            ...curr,

            [id]: !expanded[id],
        }));
    };

    useEffect(() => {
        // Check if there is no local storage data
        if (Object.keys(expanded).length === 0) {
            // Set all items to be expanded by default
            const initialExpandedState = userMemberships.reduce((acc, membership) => {
                acc[membership] = true;
                return acc;
            }, { otherTools: true } as Record<string, any>); // Include otherTools as expanded
            setExpanded(initialExpandedState);
            setIsInitialized(true);    
        } else {
            setIsInitialized(true);
        }

      }, []); //expanded, userMemberships, setExpanded

    if (!isInitialized){ // isLoadedOrg || !isLoadedOrgList || userMemberships.isLoading){
        return (
            <>
                <div className="flex items-center justify-between mb-2">
                    <Skeleton className="h-10 w-[50%]"/>
                    <Skeleton className="h-10 w-10"/> 
                </div>
                <div className="space-y-2">
                    <NavItemTasks.Skeleton />
                    <NavItemTasks.Skeleton />
                    <NavItemTasks.Skeleton />
                </div>
            </>
        )
    }
    return (
        <div 
            //className={cn("w-1/8 h-screen sticky top-0 dark:bg-[#1E1F22] overflow-y-auto",)}
            className={cn("w-1/8 h-screen dark:bg-[#1E1F22]",
                //"hover:overflow-scroll scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                //"overflow-scroll scrollbar-hide scrollbar-thin",
                //"overflow-y-scroll scrollbar-thin scrollbar-w-0 hover:scrollbar-w-2 scrollbar-track-transparent scrollbar-thumb-transparent hover:scrollbar-thin hover:scrollbar-thumb-gray-500 hover:scrollbar-track-gray-600 focus:scrollbar-thumb-gray-500 focus:scrollbar-track-gray-200",
                "md:overflow-auto md:scrollbar-none md:hover:scrollbar-thin md:scrollbar-thumb-gray-400 md:scrollbar-track-transparent md:dark:scrollbar-thumb-gray-600 md:dark:scrollbar-track-gray-800",
                //"overflow-y-auto hover:scrollbar-thin  scrollbar-position-absolute", // active:scrollbar-thin",
                "p-0 m-0"
            )}
            /*
            className={cn("w-1/8 h-screen dark:bg-[#1E1F22]",
                "overflow-auto hover:scrollbar-thin scrollbar-none scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                " p-0 m-0"
            )}
            */
        >
            <Accordion
                type="multiple"
                defaultValue={defaultAccordionValue}
                className="space-y-2 p-0 m-0"
            >
                {userMemberships
                    .filter(membership => "/" + membership === location.pathname)
                    .map(membership => {
                    // Dynamically select the component based on the membership
                    const SpecificNavItem = membershipComponents[membership] || membershipComponents['DefaultNavItem'];
                    return (
                        <SpecificNavItem
                            key={membership}
                            isActive={"/" + membership === location.pathname}
                            isExpanded={expanded[membership]}
                            membership={membership}
                            userMemberships={userMemberships}
                            onExpand={onExpand}
                        />
                    );
                    })}
            </Accordion>
            
            <Separator className="bg-zinc-300 dark:bg-zinc-700 mb-2" />

            <Accordion
                type="multiple"
                defaultValue={defaultAccordionValue}
                className="space-y-2"
            >
                    <NavItemOtherTools
                        key="otherTools"
                        isActive={false}
                        isExpanded={expanded['otherTools']} 
                        //membership={otherTools}
                        userMemberships={userMemberships}//.filter((item) => "/" + item !== location.pathname)}
                        onExpand = {onExpand}
                        location={location.pathname}
                    />
            </Accordion>
        </div>
    );
};