import { fetchFeedsByCategory, fetchFeedsByUser, fetchGeneralFeeds, fetchSavedFeeds } from "../../../api/techwatch/api-getFeeds";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import FeedCard, { FeedCardSkeleton } from "./feedCard";
import { Home, Flame, Search, ChevronUp, User, UserCog } from "lucide-react";
import { useFeeds, usePage, useSelectedCategory, useSelectedTab, useTechwatchStoreActions } from "../../../hooks/techwatch-store";
import CategoryComponent from "./CategoryComponent";
import { Button } from "../../../components/ui/button";

interface FeedsTimelineProps {
    scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const FeedsTimeline: React.FC<FeedsTimelineProps> = ({ scrollContainerRef }) => {

    //infinte scroll initial state
    const selectedTab = useSelectedTab();
    const page = usePage();
    
    const feeds = useFeeds();

    // handle browsing by category
    const selectedCategory = useSelectedCategory();

    const loader = useRef(null);

    //update functions
    const { updateManyFeeds, setPage, setSelectedTab, resetFeeds } = useTechwatchStoreActions();

    const [selectedFeed, setSelectedFeed] = useState<string>('');
    const feedRefs = useRef<{ [key: string]: any }>({}).current; // To store refs for each feed card

    useEffect(() => {
        //console.log("TL selectedTab: ", selectedTab);
        //console.log("TL page: ", page);
        const getFeedsFromDB = async () => {
            try {
                if (selectedTab === "General" || (selectedTab === "Browse by category" && !selectedCategory)) {
                    const response = await fetchGeneralFeeds(page, 20);
                    updateManyFeeds(response);
                } else if (selectedTab === "Personalized") {
                    // personalized feeds
                    //console.log("personalized feeds");
                    const response = await fetchFeedsByUser(page, 20);
                    updateManyFeeds(response);
                    //console.log("personalized feeds: ", response);
                } else if (selectedTab === "Bookmarks") {
                    const response = await fetchSavedFeeds(page, 20);
                    updateManyFeeds(response);
                    //console.log("bookmarks: ", response);
                } else if (selectedTab === "Trending-topics") {
                    // trending topics
                    //console.log("trending topics");
                } else if (selectedTab === "Browse by category"  && selectedCategory) {
                    //if (selectedCategory === '') return;
                    const response = await fetchFeedsByCategory(page, 20, selectedCategory);
                    updateManyFeeds(response);        
                }
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        getFeedsFromDB();
    }, [selectedTab, page, selectedCategory]);


    //infinte scroll logic
    useEffect(() => {
        var options = {
          root: null, // using the viewport as root
          rootMargin: "20px",
          threshold: 1.0
        };
        //console.log("hitting inf scroll");
        // Callback for IntersectionObserver
        const handleObserver = (entities: IntersectionObserverEntry[]) => {
          const target = entities[0];
          if (target.isIntersecting) {
            //console.log("hitting inf scroll target");
            setPage(page + 1);
          }
        };
    
        // Create an intersection observer instance
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current);
        }
    
        // Clean up
        return () => observer.disconnect();
      }, [feeds]);
    /*
    useEffect(() => {
        return () => {
            //console.log("cleaning up");
            setSelectedTab('General');
        };
    }, []);
    */

    //Scroll to top logic
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    //const scrollRef =  useRef<HTMLDivElement>(null);

    useEffect(() => {
        //console.log("Ref object:", scrollContainerRef);
        //console.log("Ref current value:", scrollContainerRef.current);
        const element = scrollContainerRef.current;
        const handleScroll = () => {
            if (element && element.scrollTop > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        element?.addEventListener('scroll', handleScroll);
        return () => element?.removeEventListener('scroll', handleScroll);
    }, [scrollContainerRef]);

    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; 
        }
    };

    const handleRefresh = () => {
        resetFeeds();
        setSelectedTab('Profile');
    };

    return (
        <div className="m-0 p-0">
            {selectedTab === "Browse by category" && (
                <CategoryComponent />
            )}
            {selectedTab === "Personalized" && (
                <div className="flex justify-end mt-2 mb-0">
                    <Button
                        onClick={handleRefresh}
                        variant="default_simple"
                        //className="mx-1 bg-techwatch_complementary text-[#EBF1FA] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#925B28] dark:text-[#FFDEC3] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        className="mx-4 text-md font-medium text-[#133867] dark:text-[#D1E7FF] bg-[#DDEBFE] dark:bg-[#102642] gap-x-4"
                    >
                        <UserCog className="mr-1"></UserCog>
                        Settings
                    </Button>
            </div>
            )}
            {(feeds && feeds.length > 0) ? (
                <div className="flex flex-col">
                    <div>
                        {feeds.map((feed) => (
                            <FeedCard 
                                key={feed.id}
                                feed={feed}
                                selectedFeed={selectedFeed}
                                setSelectedFeed={setSelectedFeed}
                                feedRefs={feedRefs}
                            />  
                        ))}
                        <div 
                            ref={loader} //ref for the infinite scroll
                        >
                            <FeedCardSkeleton />
                        </div>
                    </div>
                </div>
            ) : (
                <FeedCardSkeleton />
            )}
            
            {showScrollToTop && (
                <button
                    className="fixed bottom-4 right-5 p-3 rounded-full bg-[#BBD9FF] text-[#2D5384] dark:bg-[#1A3F6F] dark:text-neutral_white-200 shadow-lg hover:bg-techwatch_secondary dark:hover:bg-[#3C72B9] transition-colors duration-300 ease-in-out z-50"
                    onClick={scrollToTop}
                    aria-label="Scroll to top"
                >
                    <ChevronUp size={24} />
                </button>
            )}
        </div>   
    );
};
 
export default FeedsTimeline;