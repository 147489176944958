import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { useModal } from "./use-modal-store";

const CGUModal = () => {
    const { isOpen, onClose, type } = useModal();
    const isModalOpen = isOpen && type === "cguModal";

    const handleClose = () => {
        onClose();
    }

    return (
    <div className="flex-col bg-[#F8F5F5]  dark:bg-[#1A1213] shadow-md rounded-lg overflow-auto">
        <Dialog key="cgu" open={isModalOpen} onOpenChange={handleClose}> 
            <DialogContent className="border-transparent text-left text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
                <ScrollArea className="flex-grow max-h-[80vh] w-full overflow-auto rounded-md">
                        <div>
                            <div id="header" className="pr-2">
                                <h1 className="text-xl font-bold py-4">Conditions générales d’utilisation de Zangou</h1>
                                <p className="text-md">
                                    Notre mission est d'accompagner les professionnels du monde entier dans la gestion et l'optimisation de leur carrière. Zangou est conçu pour offrir des outils avancés et des ressources qui favorisent le développement professionnel, la planification de carrière et le networking efficace. Nos services visent à transformer la façon dont nos membres explorent les possibilités de carrière, développent leurs compétences, et interagissent au sein d'une communauté engagée. En vous connectant à Zangou, ainsi qu'à des milliers d'autres professionnels, vous pourrez partager des idées, collaborer sur des projets, et accéder à des opportunités uniques, tout cela dans un environnement de confiance et d'innovation.
                                </p>
                            </div>
                            <div id="introduction" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">1.Introduction</h2>
                                <div>
                                    <h3 className="text-lg font-semibold">1.1 Conditions Générales d’utilisation</h3>
                                    <p className="text-md">
                                    Notre mission est d'accompagner les professionnels du monde entier dans la gestion et l'optimisation de leur carrière. Zangou est conçu pour offrir des outils avancés et des ressources qui favorisent le développement professionnel, la planification de carrière et le networking efficace. Nos services visent à transformer la façon dont nos membres explorent les possibilités de carrière, développent leurs compétences, et interagissent au sein d'une communauté engagée. En vous connectant à Zangou, ainsi qu'à des milliers d'autres professionnels, vous pourrez partager des idées, collaborer sur des projets, et accéder à des opportunités uniques, tout cela dans un environnement de confiance et d'innovation.
                                    </p>
                                    <p className="text-md pt-2">
                                    Vous reconnaissez qu’en cliquant sur “S’inscrire”, “Rejoindre Zangou” ou un élément similaire, en vous inscrivant à nos services, en y accédant ou en les utilisant, vous acceptez de conclure un accord qui vous engage légalement avec Zangou. Si vous souhaitez résilier cet accord, vous pouvez le faire à tout moment en fermant votre compte et en cessant toute utilisation de nos Services ou en contacter le service Support.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-md font-semibold pt-2">Services</h3>
                                    <p className="text-md pt-2">
                                    Les présentes CGU s’appliquent à Zangou.ai, aux applications de la marque Zangou et aux autres sites, applications, communications et autres services associés à Zangou qui sont déclarés être proposés en vertu de ces CGU (les “Services”), y compris la collecte hors site de données pour ces Services, notamment nos publicités. Les utilisateurs de nos Services inscrits sont des “Membres” et les utilisateurs non-inscrits sont des “Visiteurs”.
                                    </p>
                                    <h3 className="text-md font-semibold pt-2">Zangou</h3>
                                    <p className="text-md pt-2">
                                    Vous concluez le présent accord avec Zangou (également désigné par “nous”). Vous concluez le présent accord avec Zangou SAS et Zangou contrôlera les données personnelles que vous avez fournies à nos Services, ou que nous avons collectées par le biais de nos Services, ou que nous avons traitées en relation avec nos Services. Les présentes CGU s’appliquent aux Membres et aux Visiteurs. En tant que Visiteur ou Membre de nos Services, la collecte, l’utilisation et le partage de vos données personnelles sont soumis à la présente Politique de confidentialité (qui inclut notre Politique relative aux cookies ainsi que d’autres documents indiqués dans la Politique de confidentialité) et à ses versions mises à jour.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg pt-3 font-semibold">1.2 Membres et Visiteurs</h3>
                                    <p className="text-md">
                                    Lorsque vous vous inscrivez aux Services Zangou, vous en devenez Membre. Lors du processus d’inscription, le Membre s’engage à lire les CGU lorsqu’il matérialise son consentement à celles-ci en cochant la case correspondante apparaissant dans la Solution dans le cadre du processus d’inscription.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg pt-3 font-semibold">1.3 Modifications des CGU</h3>
                                    <p className="text-md">
                                    Dans un souci constant d'amélioration de ses services et en réponse aux évolutions rapides du secteur numérique, Zangou met régulièrement à jour les caractéristiques et fonctionnalités de ses services. Cela peut inclure l'ajout de nouvelles fonctionnalités, la suppression de celles devenues obsolètes, ou la modification de certaines existantes.
                                    </p>
                                    <p className="text-md">
                                    À cet effet, les changements apportés à la Solution sont implémentés automatiquement et de manière régulière. Nous nous engageons à informer les utilisateurs de toute modification significative des CGU en publiant un avis sur notre site web trois jours avant l'entrée en vigueur de ces changements.
                                    </p>
                                    <p className="text-md">
                                    Durant cette période de préavis, tout utilisateur qui ne souhaite pas accepter les nouvelles CGU peut résilier son adhésion par tout moyen approprié, tel qu'un courriel exprimant sa décision de ne plus utiliser les services de Zangou.
                                    </p>
                                </div>
                            </div>
                            <div id="obligations" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">2. Obligations</h2>
                                <div>
                                    <h3 className="text-lg font-semibold">2.1 Droit aux Services</h3>
                                    <p>Dans le cadre de ces CGU, vous nous garantissez les choses suivantes :</p>
                                    <ul className="list-disc pl-5">
                                        <li>Vous avez la capacité légale de conclure cet accord.</li>
                                        <li>Vous avez l’âge minimal requis pour utiliser nos Services.</li>
                                    </ul>
                                    <h3 className="text-md font-semibold pt-2">Conditions d'utilisation des Services :</h3>
                                    <p className="mb-2">
                                        <span className="font-semibold">Âge Minimum :</span> Vous devez avoir au moins 16 ans pour utiliser nos Services. Cependant, si les lois de votre pays exigent que vous soyez plus âgé pour que Zangou puisse légalement fournir les Services sans consentement parental (y compris le traitement de vos données personnelles), alors cet âge plus élevé s'applique comme âge minimum.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Unicité du compte :</span>  Vous devez posséder un seul compte sur Zangou
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Autorisation d'utilisation :</span> Zangou ne vous a pas précédemment interdit l'utilisation de ces services. La création d’un compte avec des informations fausses ou pour le compte d’autrui, ainsi que pour des personnes de moins de 16 ans, constitue une violation de nos conditions.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">2.2 Votre Compte</h3>
                                    <p className="text-md">
                                    Vous êtes responsable de la confidentialité et de la sécurité de votre compte. Afin de protéger votre compte et vos informations personnelles, vous vous engagez à respecter les obligations suivantes :
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Confidentialité du mot de passe :</span> Vous vous engagez à garder votre mot de passe secret et à utiliser un mot de passe fort. Il est recommandé d'utiliser des combinaisons de lettres, chiffres et symboles pour renforcer la sécurité.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Usage personnel :</span> Votre compte est personnel et non transférable. Vous ne partagerez pas votre compte avec une autre personne, ni ne permettrez à d'autres d'accéder à votre compte. Le partage de compte viole nos conditions et peut entraîner la suspension ou la résiliation du compte.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Respect des règles et des lois :</span> Vous vous engagez à respecter toutes les règles internes de Zangou ainsi que les lois applicables lors de l'utilisation de nos services. Cela inclut, sans limitation, les règles concernant la protection des données, le respect de la vie privée d'autrui et la non-utilisation des services de Zangou à des fins illégales ou non autorisées ainsi que le respect des règles énoncées en partie 6 : « A faire et ne pas faire ».
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Responsabilité :</span> Vous êtes entièrement responsable de toute activité réalisée sous votre compte, à moins que vous ne fermiez votre compte ou que vous ne signaliez à Zangou une utilisation frauduleuse, abusive ou illégale. En cas de suspicion de compromission de votre compte, il est impératif de nous en informer immédiatement pour que les mesures nécessaires puissent être prises pour protéger votre compte.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">2.3 Règlement</h3>
                                    <p className="text-md">
                                    Certains de nos services sont payants. En vous abonnant à l'un de nos Services payants (« Services Premium »), vous vous engagez à respecter les obligations financières suivantes :
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Obligations de Paiement :</span> Vous vous engagez à régler les frais et les taxes applicables liés à l'utilisation des Services Premium. 
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Frais Additionnels : </span> Selon le lieu de votre transaction, des frais de conversion de devises ou des tarifs différents, tels que des taux de change, peuvent s'appliquer à votre achat.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Facturation Récurrente :</span> Si vous souscrivez à un abonnement, vous serez automatiquement facturé au début de chaque période d'abonnement selon les frais et les taxes en vigueur pour cette période. Pour éviter tout frais futur, vous devez annuler votre abonnement avant la date de renouvellement. Les instructions pour annuler ou suspendre vos Services Premium sont disponibles sur notre plateforme.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Politique de Remboursement :</span> Tout remboursement sera traité conformément à notre politique de remboursement, qui est détaillée séparément.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Calcul des Taxes :</span> Les taxes applicables seront calculées en fonction des informations de facturation que vous nous fournissez au moment de votre achat.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">2.4 Notifications et Messages</h3>
                                    <p className="mb-2">
                                        <span className="font-semibold">Communication via Zangou :</span> Vous acceptez de recevoir des notifications et des messages de notre part par le biais de nos sites web, applications, et autres canaux de communication que vous avez spécifiés lors de votre inscription ou que vous avez ultérieurement mis à jour. Ces notifications peuvent concerner des mises à jour importantes du service, des modifications des conditions d'utilisation, ou des promotions et offres spéciales.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Moyens de Communication :</span> Vous consentez à ce que nous vous contactions par les moyens suivants :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li>Directement dans le Service : Notifications et messages intégrés dans l'interface utilisateur de Zangou.</li>
                                        <li>Coordonnées Fournies : Messages envoyés directement aux coordonnées que vous nous avez fournies, telles que votre adresse e-mail, numéro de téléphone mobile, ou adresse postale.</li>
                                    </ul>
                                    <p className="mb-2">
                                        <span className="font-semibold">Responsabilité de Mise à Jour :</span> Vous vous engagez à maintenir vos coordonnées à jour. Si vos informations ne sont pas actuelles, vous risquez de ne pas recevoir des notifications importantes, ce qui peut affecter votre utilisation des Services.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">2.5 Partage</h3>
                                    <p className="mb-2">
                                        <span className="font-semibold">Interactions dans l'Espace Communauté (Community) :</span> Lorsque vous partagez des informations ou du contenu dans l'espace "Communauté" de Zangou, via les messages directs ou les canaux dédiés, ces informations peuvent être vues, copiées et utilisées par d'autres membres de la communauté. Il est important de comprendre que tout contenu partagé peut être sujet à l'interaction d'autres utilisateurs.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Visibilité et Utilisation des Informations Partagées :</span> Nos services permettent de partager des informations de diverses manières exclusivement au sein de l'espace "Communauté". Vous pouvez participer à des discussions, partager des ressources, et collaborer avec d'autres membres. Les informations et le contenu que vous partagez peuvent être visibles par d'autres membres inscrits dans les canaux ou les messages directs sur lesquels vous publiez.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Préférences de Confidentialité :</span> Nous respectons vos préférences en matière de visibilité et de partage d'informations. Vous avez la possibilité de configurer la visibilité de vos contributions et interactions au sein de la communauté, ce qui vous permet de contrôler qui peut voir les informations que vous partagez.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Gestion du Contenu :</span> Nous nous réservons le droit de modérer le contenu partagé dans l'espace "Communauté" pour assurer le respect des directives de la communauté et de la qualité des discussions. Zangou peut retirer tout contenu jugé inapproprié, offensant ou en violation de nos politiques, avec ou sans préavis.
                                    </p>
                                    <p className="mb-2">
                                        <span className="font-semibold">Responsabilité des Informations Partagées :</span>Soyez conscient que toute information partagée dans l'espace "Communauté" peut être redistribuée par d'autres membres et qu'une fois partagée, elle peut échapper à votre contrôle. Il est donc important de réfléchir aux informations que vous décidez de partager.
                                    </p>
                                </div>
                            </div>
                            <div id="droits" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">3. Droits et restrictions</h2>
                                <div>
                                    <h3 className="text-lg font-semibold">3.1 Votre octroi de licence à Zangou</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Propriété de Votre Contenu :</span> Vous restez propriétaire de tout le contenu, des retours et des données personnelles que vous fournissez à nos services. En utilisant nos services, vous nous accordez une licence non exclusive pour utiliser ce contenu.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Utilisation de Votre Contenu :</span> En tant que titulaire de compte, lorsque vous envoyez ou publiez du contenu sur les services de Zangou, vous accordez à Zangou et à ses affiliés une autorisation mondiale, cessible et pouvant faire l'objet d'une sous-licence de :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li>Utiliser, copier, modifier, distribuer, publier, et traiter les informations et le contenu que vous fournissez par le biais de nos Services, sans nécessité de consentement, préavis, ni compensation supplémentaire à vous ou à des tiers.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Restrictions de cette Licence :</span>
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li><span className="font-semibold">Résiliation de Licence :</span> Vous pouvez révoquer cette licence pour un contenu spécifique en le supprimant des Services, ou en fermant votre compte, sauf si ce contenu a été partagé avec d'autres utilisateurs, auquel cas une demande spécifique au support Zangou est nécessaire.</li>
                                        <li><span className="font-semibold">Publicité :</span> Nous ne placerons pas votre contenu dans des publicités pour les produits et services de tiers sans votre consentement explicite. Cependant, nous pouvons afficher des publicités à côté de votre contenu sans compensation financière.</li>
                                        <li><span className="font-semibold">Modification de Contenu :</span> Bien que nous puissions modifier la mise en page ou le format de votre contenu (par exemple, en le traduisant, en modifiant sa taille, ou en supprimant des métadonnées), nous ne modifierons pas intentionnellement le sens de votre expression.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Utilisation des Données Personnelles et Conformité au RGPD :</span> Cette partie concerne les utilisateurs européens de la solution.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Engagement de Zangou pour la Protection des Données :</span> Zangou s'engage à protéger la confidentialité et la sécurité de vos données personnelles. Nous respectons toutes les législations pertinentes en matière de protection des données, y compris le Règlement Général sur la Protection des Données (RGPD), pour offrir un niveau de sécurité élevé pour le traitement, l'accès et l'utilisation des données personnelles.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Base Légale pour le Traitement :</span>
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li><span className="font-semibold">Consentement :</span> Nous traitons vos données personnelles sur la base de votre consentement explicite que vous fournissez lors de l'inscription et lors de modifications ultérieures de vos préférences de confidentialité.</li>
                                        <li><span className="font-semibold">Exécution d'un contrat :</span> Certaines de vos données sont nécessaires pour l'exécution du contrat de service entre vous et Zangou, par exemple, pour la création et la gestion de votre compte ou encore la fourniture des services proposés par Zangou.</li>
                                        <li><span className="font-semibold">Intérêts légitimes :</span> Nous utilisons également vos données pour poursuivre nos intérêts légitimes de manière équilibrée avec vos droits et libertés, notamment pour améliorer nos services, pour des communications marketing, et pour prévenir la fraude.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Droits des Utilisateurs sous le RGPD :</span> Vous pouvez consulter la politique de confidentialité pour plus de détails, en syntèse, vous avez plusieurs droits spécifiques en vertu du RGPD, y compris :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li><span className="font-semibold">Droit d'accès :</span> Vous avez le droit de demander des copies de vos données personnelles que nous détenons.</li>
                                        <li><span className="font-semibold">Droit de rectification :</span> Vous pouvez demander que nous corrigions toute information que vous croyez inexacte ou complétions des informations que vous croyez incomplètes.</li>
                                        <li><span className="font-semibold">Droit à l'effacement :</span> Vous pouvez demander l'effacement de vos données personnelles sous certaines conditions.</li>
                                        <li><span className="font-semibold">Droit de restreindre le traitement :</span> Vous avez le droit de demander que nous restreignions le traitement de vos données personnelles, sous certaines conditions.</li>
                                        <li><span className="font-semibold">Droit à la portabilité des données :</span> Vous pouvez demander que nous transférions les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions.</li>
                                        <li><span className="font-semibold">Droit d'opposition :</span> Vous avez le droit de vous opposer à notre traitement de vos données personnelles, sous certaines conditions.</li>
                                    </ul>
                                    <p className="mb-2">
                                    Pour toute demande de cet ordre, vous pouvez envoyer un courriel au support Zangou: contact@zangou.ai
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Transfert de Données :</span> Zangou ne transfère pas vos données personnelles en dehors de l'Espace Économique Européen (EEE) sauf si des mesures de protection adéquates ou des mécanismes appropriés, comme les clauses contractuelles types approuvées par la Commission européenne, sont en place pour protéger vos données.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Sécurité des Données :</span> Nous mettons en œuvre des mesures techniques et organisationnelles avancées pour protéger vos données personnelles contre l'accès non autorisé, l'altération, la divulgation ou la destruction. Nous limitons l'accès à vos données personnelles aux employés, agents et tiers qui ont besoin de connaître ces informations pour les traiter en notre nom.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Feedback :</span> En soumettant des suggestions ou des feedbacks sur nos Services, vous acceptez que nous puissions utiliser et partager ces feedbacks pour toute fin, sans obligation de compensation.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Intégrité des Informations :</span> Vous vous engagez à fournir uniquement des informations et du contenu que vous avez le droit de partager, et à maintenir la véracité de votre profil. Vous vous assurez que votre contenu ou vos données ne violent aucune loi ni droit de tiers, incluant les droits de propriété intellectuelle.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">3.2 Disponibilité des Services</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Modification ou Interruption des Services :</span> Zangou se réserve le droit de modifier, suspendre, ou mettre fin à tout service à tout moment, selon les nécessités opérationnelles ou stratégiques de l'entreprise. Nous nous engageons à vous informer de ces changements dans un délai raisonnable, en respectant les exigences légales en vigueur.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Ajustement des Prix :</span> Nous pouvons également revoir et ajuster nos tarifs pour certains services. Toute modification des prix sera communiquée à nos utilisateurs de manière claire et avec un préavis suffisant, afin de vous permettre de prendre des décisions éclairées concernant votre utilisation de nos services.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Non-garantie de Stockage Permanent :</span> Zangou n'est pas un service de stockage dédié. En conséquence, nous ne garantissons pas le stockage indéfini ou la conservation permanente des informations et du contenu que vous avez publiés. Vous reconnaissez que Zangou n'a aucune obligation de conserver, de maintenir ou de vous fournir une copie des informations ou du contenu que vous ou d'autres avez partagé sur notre plateforme, sauf dans la mesure requise par la loi applicable et comme décrit dans notre Politique de confidentialité.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">3.3 Autres sites, contenus et applications</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Utilisation de Contenus de Tiers :</span> Votre utilisation des contenus et informations provenant de tiers disponibles sur nos Services se fait à vos propres risques. Zangou n'est pas responsable de la véracité ou de la fiabilité des informations fournies par des tiers.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Commercialisation par des Tiers :</span> Des tiers peuvent offrir leurs propres produits et services à travers nos Services. Zangou n'est pas responsable des activités de ces tiers, ni des produits ou services qu'ils proposent.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Contenus Potentiellement Nocifs :</span> Durant votre utilisation de nos Services, vous pouvez rencontrer des contenus inexacts, incomplets, obsolètes, trompeurs, illégaux, offensants ou de toute autre manière préjudiciables. Bien que Zangou s'efforce de maintenir un environnement sûr et respectueux, nous ne révisons pas systématiquement tous les contenus publiés par nos membres ou par d'autres entités. Vous reconnaissez que nous ne sommes pas responsables du contenu publié par des tiers.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Associations Erronées :</span> Vous acceptez également le risque d'être associé à tort à du contenu concernant des tiers lorsque nous informons vos relations ou abonnés que vous avez été mentionné dans les actualités. Les membres peuvent contrôler cette fonctionnalité via leurs paramètres de compte.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Services Offerts par des Membres :</span> Zangou peut faciliter la connexion entre membres qui offrent leurs services (coaching, consulting, etc.). Zangou ne fournit ni n'emploie personne pour fournir ces services. Vous devez être majeur pour offrir ou solliciter ces services sur notre plateforme. Vous reconnaissez que Zangou ne supervise pas, ne dirige pas et ne contrôle pas les membres qui fournissent ces services et acceptez que (1) Zangou n'est pas responsable de l'offre ou de la prestation de ces services, (2) Zangou ne cautionne aucun membre pour les services qu'il offre, et (3) aucune relation d'emploi ou d'agence n'est créée entre Zangou et les membres offrant des services. Si vous offrez des services, vous garantissez posséder toutes les licences requises et vous engagez à respecter nos politiques communautaires lors de la fourniture de ces services.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Participation à des Événements :</span> Zangou peut vous aider à vous inscrire et à participer à des événements organisés par des membres. Vous acceptez que (1) Zangou n'est pas responsable de la conduite des membres ou d'autres participants à ces événements, (2) Zangou ne cautionne aucun des événements listés sur nos Services, (3) Zangou ne contrôle ni ne vérifie aucun de ces événements, et (4) vous vous conformerez aux conditions qui s'appliquent à ces événements.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">3.4 Restrictions</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Gestion des Interactions sur la Plateforme :</span> Zangou se réserve le droit de restreindre la manière dont vous vous connectez et interagissez sur nos Services. Cela inclut, mais n'est pas limité à, la limitation du nombre de vos connexions, votre capacité à contacter d'autres membres, et l'accès à certaines fonctionnalités.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Respect des Conditions d'Utilisation :</span> Nous nous réservons le droit de limiter votre utilisation des Services, de suspendre ou de clôturer votre compte si nous déterminons que vous enfreignez les termes de cet accord, la loi, ou si votre utilisation des services est jugée abusive.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Exemples de Comportements Abusifs :</span> Les comportements jugés abusifs comprennent, mais ne sont pas limités à, les actions qui violent les droits d'autrui, l'utilisation de langage inapproprié, le harcèlement d'autres utilisateurs, la diffusion de contenu inapproprié, frauduleux, trompeur, illégal ou autrement nuisible :
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Les contenus manifestement illicites :</span>
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li>Le contenu publié par l’utilisateur ne doit pas inciter à la haine, à la violence, à la fabrication et utilisation d'explosifs, au suicide, au racisme, à l'antisémitisme, à la xénophobie, à l'homophobie, faire l'apologie des crimes de guerre ou des crimes contre l'humanité ;</li>
                                        <li>Le contenu publié par le Contributeur ne doit présenter en aucun cas un caractère pédophile ou pédopornographique ;</li>
                                        <li>Le contenu publié par le Contributeur ne doit pas inciter à commettre un crime, un délit ou un acte de terrorisme ;</li>
                                        <li>Le contenu publié par le Contributeur ne doit pas inciter à la discrimination d'une personne ou d'un groupe de personne en raison de son appartenance à une ethnie, à une religion, à une race, ou du fait de son orientation sexuelle ou de son handicap.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Les contenus litigieux :</span> L’utilisateur s'engage à prendre connaissance avant toute publication des règles et limites relatives à la liberté d'expression. La liberté d'expression autorise la critique, le rapport d'informations vérifiées et prouvées, elle n'autorise pas le dénigrement et la diffamation. Tout dénigrement, diffamation, ou allégation d'informations inexactes ou volontairement tronquées pour en changer le sens peuvent entraîner des poursuites à l'encontre de leur auteur. Ainsi :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li>Le contenu publié par l’utilisateur ne doit pas porter atteinte ou être contraire à l'ordre public, aux bonnes mœurs ou pouvoir heurter la sensibilité des mineurs ;</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas présenter de caractère pornographique ;</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas porter atteinte aux droits à la réputation, à la vie privée de tiers et à l'image.</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas être, au strict sens de la loi, dénigrant ou diffamatoire</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas porter atteinte à l'image, à la réputation d'une marque ou d'une quelconque personne physique ou morale. La liberté d'expression autorise la critique dès lors qu'elle est objective, argumentée, et portant sur des faits réels.</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas porter atteinte à la sécurité ou à l'intégrité d'un État ou d'un territoire, quel qu'il soit.</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas permettre à des tiers de se procurer des logiciels piratés, des numéros de série de logiciels ou tout logiciel pouvant nuire ou porter atteinte, de quelque manière que ce soit, aux droits ou aux biens des tiers.</li>
                                        <li>Le contenu publié par l’utilisateur ne doit pas porter atteinte aux droits de propriété intellectuelle de quelque personne physique ou morale que ce soit.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Conséquences des Violations :</span> En cas de violation de ces restrictions, Zangou prendra les mesures nécessaires, qui peuvent inclure la suppression de contenu, la restriction de certaines fonctionnalités pour votre compte, ou la suspension ou la résiliation de votre accès aux Services, selon la gravité de l'infraction.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Droit de Recours :</span> Si votre compte est suspendu ou clôturé, vous aurez la possibilité de contester cette décision en contactant le support client, sauf dans les cas où les infractions sont jugées sévères ou répétées.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">3.5 Droits de propriété intellectuelle</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Notification des Droits :</span> Zangou reconnaît et respecte les droits de propriété intellectuelle et vous informe des droits suivants concernant nos Services.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Droits de Zangou sur les Services :</span> Zangou se réserve l’intégralité de ses droits de propriété intellectuelle liés aux Services. Cela inclut, mais n'est pas limité à, tous les droits d'auteur, brevets, marques commerciales, secrets commerciaux et autres droits de propriété intellectuelle associés à nos Services.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Marques Commerciales :</span> Les marques commerciales, logos et autres signes distinctifs utilisés en relation avec les Services, y compris le nom "Zangou", le logo de Zangou, et tout autre marque, marque de service, graphiques et logos sont des marques déposées ou non déposées appartenant à Zangou ou sont utilisés sous licence par Zangou. Les marques de commerce appartenant à des tiers, utilisées en relation avec les Services, appartiennent à leurs propriétaires respectifs.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Usage des Marques :</span> L'utilisation des marques commerciales, logos et autres signes distinctifs de Zangou en relation avec les Services n'accorde aucun droit ou licence de les utiliser sans le consentement écrit préalable de Zangou ou de leur propriétaire respectif, si applicable.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Protection et Respect des Droits :</span> Nous vous demandons de respecter nos droits de propriété intellectuelle et ceux des autres. L'utilisation non autorisée des marques, copyrights, ou d'autres éléments de propriété intellectuelle associés à nos Services peut entraîner des conséquences légales.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">3.6 Traitement automatisé</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Objectifs du Traitement Automatisé :</span>Dans le cadre de notre engagement à fournir une expérience personnalisée et améliorée, nous employons des technologies de traitement automatisé pour diverses finalités, incluant, sans s'y limiter :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li>Vous suggérer des contenus, des événements, et des opportunités de formation ou d’emploi qui correspondent à vos intérêts et besoins professionnels.</li>
                                        <li>Adapter le contenu du fil d’actualité (Techwatch) à vos préférences et autres informations vous concernant.</li>
                                        <li>Proposer des plans de carrières et des connexions à vos contacts en fonction des synergies possibles et de vos préférences.</li>
                                        <li>Améliorer les fonctionnalités disponibles sur la plateforme en analysant les tendances d'usage et les préférences des utilisateurs</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Utilisation des Données pour les Modèles de Langage à Grande Échelle (LLMs) :</span>Pour optimiser nos services de proposition de plans de carrière, Zangou collabore avec des fournisseurs de technologies LLM. Ces prestataires reçoivent certaines données personnelles que vous nous fournissez, nécessaires pour générer des recommandations de carrière hautement personnalisées. 
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Précision des Recommandations :</span>En maintenant les informations de votre profil à jour et en fournissant des données précises et complètes, vous nous aidez à affiner les algorithmes de recommandation pour qu'ils soient plus pertinents et utiles pour vous et pour d'autres utilisateurs de Zangou. 
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Transparence et Contrôle :</span>Vous avez le contrôle sur les données que vous fournissez et pouvez à tout moment ajuster vos préférences de confidentialité pour gérer la manière dont vos informations sont utilisées pour le traitement automatisé. Nous nous engageons à être transparents quant aux technologies utilisées et à l'impact de ces dernières sur vos données et votre expérience utilisateur.
                                    </p>
                                </div>
                            </div>
                            <div id="exoneration" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">4. Exonération et limitation de responsabilité</h2>
                                <div>
                                    <h3 className="text-lg font-semibold">4.1 Absence de garantie</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Déclaration de non-responsabilité :</span> Zangou et ses sociétés affiliées ne garantissent pas la qualité, la sécurité ou la fiabilité de nos services. Les services sont fournis "en l'état" et "selon les disponibilités", sans aucune garantie d'aucune sorte. Ceci inclut, mais n'est pas limité à, l'absence d'interruption ou l'absence d'erreur dans les opérations des services. Dans toute la mesure permise par la loi applicable, Zangou et ses affiliés rejettent toutes les garanties implicites ou légales, y compris, sans limitation, les garanties de propriété, d'exactitude des données, d'absence de contrefaçon, de qualité marchande et d'adéquation à un usage particulier.
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-lg font-semibold">4.2 Exclusion de responsabilité</h3>
                                    <p className="mb-2">
                                    <span className="font-semibold">Limitations de responsabilité :</span> Dans toute la mesure permise par la loi, et sauf disposition contraire dans un accord écrit qui prévaudrait sur celui-ci, Zangou, y compris ses sociétés affiliées, ne sera pas responsable des pertes de bénéfices ou d'opportunités commerciales, de dommages à la réputation (par exemple, déclarations diffamatoires), de pertes de données (y compris les interruptions, la perte de votre contenu, son utilisation ou toute modification de celui-ci), ou pour tout dommage indirect, fortuit, consécutif, spécial ou punitif.
                                    </p>
                                </div>
                            </div>
                            <div id="resiliation" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">5. Résiliation</h2>
                                <div>
                                    <p className="mb-2">
                                    <span className="font-semibold">Conditions de Résiliation :</span> Cet accord peut être résilié par vous ou par Zangou à tout moment, à condition de fournir un préavis à l'autre partie. Pour résilier cet accord, vous pouvez fermer votre compte via les paramètres de compte ou en contactant notre support par courriel.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Conséquences de la Résiliation :</span> À la résiliation de cet accord, vous perdrez immédiatement le droit d'accéder ou d'utiliser les services de Zangou. Il est important de noter que certaines informations peuvent rester disponibles pour les autres utilisateurs si elles ont été partagées avec d'autres ou reproduites.
                                    </p>
                                    <p className="mb-2">
                                    <span className="font-semibold">Survivance des Droits et Obligations :</span> Certains droits et obligations issus de cet accord continueront d'exister après la résiliation, y compris :
                                    </p>
                                    <ul className="list-disc pl-5">
                                        <li><span className="font-semibold">Feedback :</span> Nos droits relatifs à l'utilisation et à la divulgation de votre feedback.</li>
                                        <li><span className="font-semibold">Contenu Partagé :</span> Les droits des membres à continuer de repartager le contenu et les informations que vous avez partagés à travers les Services.</li>
                                        <li><span className="font-semibold">Dispositions Survivantes :</span> Les dispositions des articles relatifs à la propriété intellectuelle, la limitation de responsabilité et les obligations générales continueront de s'appliquer même après la résiliation.</li>
                                        <li><span className="font-semibold">Obligations Financières :</span> Toute somme due par vous ou par Zangou avant la résiliation restera due après la résiliation.</li>
                                    </ul>
                                    <p className="mb-2">
                                    <span className="font-semibold">Clôture de Compte :</span> Pour obtenir de l'aide sur la procédure de clôture de votre compte, veuillez contacter notre service clientèle, qui vous guidera à travers les étapes nécessaires et vous informera sur les implications de la clôture de votre compte.
                                    </p>
                                </div>
                            </div>
                            <div id="obligations" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">6. À faire et à ne pas faire sur Zangou</h2>
                                <div>
                                    <p className="mb-2">
                                        Zangou est une communauté de professionnels. Cette liste "À faire et à ne pas faire", ainsi que nos Politiques de la communauté professionnelle, délimitent ce que vous pouvez faire et ne pas faire sur nos Services.
                                    </p>
                                    <div>
                                        <h3 className="text-lg font-semibold">6.1 A faire</h3>
                                        <p>Vous vous engagez à :</p>
                                        <ul className="list-disc pl-5">
                                            <li>Respecter toutes les lois applicables, y compris, sans limitation, les lois en matière de protection de la vie privée, les lois relatives aux droits de propriété intellectuelle, les lois relatives aux courriels non sollicités, les lois relatives au contrôle des exportations, les lois fiscales, et les exigences réglementaires.</li>
                                            <li>Fournir des informations exactes et les mettre à jour régulièrement.</li>
                                            <li>Utiliser les Services de Zangou de manière professionnelle.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold">6.2 A ne pas faire</h3>
                                        <p>Vous vous engagez à ne pas :</p>
                                        <ul className="list-disc pl-5">
                                            <li>Créer une fausse identité sur Zangou, usurper l'identité de quelqu'un, créer un profil pour quelqu'un d'autre que vous-même, ou utiliser ou tenter d'utiliser le compte de quelqu'un d'autre.</li>
                                            <li>Développer, supporter ou utiliser des logiciels, des dispositifs, des scripts, des robots ou tout autre moyen ou processus (y compris des robots d'indexation, des extensions de navigateur et autres) pour scraper les Services ou autrement copier des profils et autres données des Services.</li>
                                            <li>Contourner ou briser toute mesure de sécurité ou contourner l'utilisation ou les limitations des Services.</li>
                                            <li>Copier, utiliser, divulguer ou distribuer toute information obtenue à partir des Services, que ce soit directement ou par l'intermédiaire de tiers (tels que des moteurs de recherche), sans le consentement de Zangou.</li>
                                            <li>Divulguer des informations que vous n'avez pas l'autorisation de communiquer (comme des données personnelles d'autres personnes, y compris de votre employeur).</li>
                                            <li>Violer les droits de propriété intellectuelle d'autrui, y compris les droits d'auteur, les brevets, les marques, les secrets commerciaux, ou tout autre droit de propriété.</li>
                                            <li>Faire de l'ingénierie inverse sur les services ou tenter d'obtenir le code source des services ou de toute technologie connexe qui n'est pas disponible en open source.</li>
                                            <li>Sous-entendre ou déclarer sans autorisation que vous êtes affilié ou sponsorisé par Zangou.</li>
                                            <li>Louer, prêter, vendre, ou monnayer l'accès aux Services de Zangou ou les données obtenues des Services sans l'accord de Zangou.</li>
                                            <li>Utiliser des bots ou d'autres méthodes automatisées pour accéder aux Services, ajouter ou télécharger des contacts, envoyer ou rediriger des messages.</li>
                                            <li>Perturber le fonctionnement des Services ou imposer une charge disproportionnée sur les Services.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div id="plaintes" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">7. Plaintes relatives au contenu</h2>
                                <p className="mb-2">
                                    Pour toute question générale relative à nos Services, vous pouvez contacter notre assistance clientèle en ligne. Nous nous efforçons de fournir des réponses rapides et précises pour assurer votre satisfaction.
                                </p>
                            </div>
                            <div id="contact" className="pr-2">
                                <h2 className="text-lg font-bold pt-4 py-2">8. Nous Contacter</h2>
                                <p className="mb-2">
                                    Pour les notifications légales ou les actes de procédure, vous pouvez nous écrire à l'adresse officielle de Zangou. Cette adresse est disponible sur notre site Web dans la section des mentions légales ou peut être obtenue directement auprès de notre assistance clientèle.
                                </p>
                            </div>
                        </div>
                    <ScrollBar orientation="vertical" className=" bg-opacity-0" />
                </ScrollArea>
            </DialogContent>
        </Dialog>
    </div>
    );
}
 
export default CGUModal;