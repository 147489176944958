import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { useModal } from "./use-modal-store";

const PrivacyPolicyModal = () => {
    const { isOpen, onClose, type } = useModal();
    const isModalOpen = isOpen && type === "privacyPolicyModal";

    const handleClose = () => {
        onClose();
    }

    return (
    <div className="flex-col bg-[#F8F5F5]  dark:bg-[#1A1213] shadow-md rounded-lg overflow-auto">
        <Dialog key="cgu" open={isModalOpen} onOpenChange={handleClose}> 
            <DialogContent className="border-transparent text-left text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
                <ScrollArea className="flex-grow max-h-[80vh] w-full overflow-auto rounded-md">
                    <div>
                        <div id="header" className="pr-2">
                            <h1 className="text-xl font-bold py-4">Politique de Confidentialité</h1>
                            <p className="text-md">
                                Lors de votre utilisation de la solution Zangou, nous sommes amenés à recueillir des données à caractère personnel vous concernant.
                            </p>
                            <p className="text-md">
                                Le but de cette politique est de vous informer sur les modalités selon lesquelles nous traitons ces données.
                            </p>
                        </div>
                        <div id="introduction" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">1. Responsable de traitement</h2>
                            <div>
                                <p className="text-md">
                                    Zangou SAS, Société par actions simplifiée
                                </p>
                                <p className="text-md">
                                    RCS Paris n° 930 170 469
                                </p>
                                <p className="text-md">
                                    Siège social : 67 rue des Poissonniers 75018 Paris
                                </p>
                                <p className="text-md">
                                    (Ci-après : « Nous »)
                                </p>
                            </div>
                        </div>
                        <div id="utilite" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">2. A quoi servent les données collectées ?</h2>
                            <p className="text-md">
                            Vos données sont traitées pour répondre à une ou plusieurs finalités. Chaque finalité est basée sur une justification légale, listée ci-dessous :
                            </p>
                            <p>a. Pour l'exécution des mesures précontractuelles prises à votre demande, nous utilisons vos données pour les finalités suivantes :</p>
                            <ul className="list-disc pl-5">
                                <li>Création et accès à votre compte.</li>
                                <li>Amélioration de votre expérience utilisateur, adaptée à vos besoins professionnels (par exemple, parcours de carrière personnalisé).</li>
                            </ul>
                            <p>b. Sur la base de notre intérêt légitime à développer et promouvoir notre activité, nous traitons vos données pour :</p>
                            <ul className="list-disc pl-5">
                                <li>Répondre à vos demandes d’information;</li>
                                <li>Constituer et gérer un fichier de membres et de clients;</li>
                                <li>Gérer les contentieux éventuels relatifs à nos services</li>
                            </ul>
                            <p>c. Dans le cadre du respect de nos obligations légales et réglementaires, nous traitons vos données pour :</p>
                            <ul className="list-disc pl-5">
                                <li>Assurer la conformité aux réglementations qui nous sont applicables;</li>
                                <li>Gérer les demandes d’exercice des droits des utilisateurs.</li>
                            </ul>
                        </div>
                        <div id="usage" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">3. Quelles données personnelles collectons-nous ?</h2>
                            <p className="text-md">
                                Nous collectons des informations qui permettent de vous identifier, tels que :
                            </p>
                            <ul className="list-disc pl-5">
                                <li>Données d'identification (nom, prénom, adresse électronique, adresse postale).</li>
                                <li>Informations professionnelles (métier exercé, etc.).</li>
                                <li>Données de situation personnelle.</li>
                                <li>Données de connexion (adresse IP, logs).</li>
                                <li>Informations économiques et financières (par exemple, coordonnées bancaires).</li>
                            </ul>
                            <p className="text-md">
                                Les données nécessaires à la fourniture de nos services sont indiquées comme obligatoires lors de leur collecte.
                            </p>
                        </div>
                        <div id="destinataire" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">4. Qui sont les destinataires des données ?</h2>
                            <p className="text-md">
                                Les données collectées sont accessibles à :
                            </p>
                            <ul className="list-disc pl-5">
                                <li>Notre personnel interne.</li>
                                <li>Nos sous-traitants, tels que les prestataires d’hébergement, les services de mesure d’audience, et nos gestionnaires de systèmes de facturation.</li>
                                <li>Les autorités réglementaires, les auxiliaires de justice et les organismes de recouvrement de créances, uniquement dans le cadre de nos obligations légales.</li>
                            </ul>
                        </div>
                        <div id="destinataire" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">4. Qui sont les destinataires des données ?</h2>
                            <p className="text-md">
                                Les données collectées sont accessibles à :
                            </p>
                            <ul className="list-disc pl-5">
                                <li>Notre personnel interne.</li>
                                <li>Nos sous-traitants, tels que les prestataires d’hébergement, les services de mesure d’audience, et nos gestionnaires de systèmes de facturation.</li>
                                <li>Les autorités réglementaires, les auxiliaires de justice et les organismes de recouvrement de créances, uniquement dans le cadre de nos obligations légales.</li>
                            </ul>
                        </div>
                        <div id="conservation" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">5. Quelle est la durée de conservation des données ?</h2>
                            <p className="text-md">
                                Les données sont conservées pour la durée nécessaire à l'accomplissement des finalités pour lesquelles elles ont été collectées :
                            </p>
                            <ul className="list-disc pl-5">
                                <li>Données utilisées pour la prestation de services : conservées pendant la durée de la prescription légale (généralement 5 ans), et les factures pour 10 ans.</li>
                                <li>Données collectées pour répondre à vos demandes d'information : supprimées après traitement de la demande.</li>
                                <li>Données utilisées pour l'envoi de newsletters et messages promotionnels : conservées 3 ans après leur collecte.</li>
                            </ul>
                        </div>
                        <div id="UE" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">6. Vos données sont-elles susceptibles d’être transférées hors de l’Union européenne ?</h2>
                            <p className="text-md">
                                Vos données sont stockées sur les serveurs de la société AWS. Vos données peuvent être stockées ou transférées hors de l'UE par des sous-traitants internationaux. Ces transferts sont sécurisés par des décisions de la Commission Européenne ou par des clauses contractuelles types approuvées par cette dernière.
                            </p>
                        </div>
                        <div id="droits" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">7. Quels sont vos droits sur vos données ?</h2>
                            <p className="text-md">
                                Vous disposez des droits suivants s’agissant de vos données à caractère personnel :
                            </p>
                            <ul className="list-disc pl-5">
                                <li>Droit à l’information : La raison de cette présente charte.</li>
                                <li>Droit d’accès : Vous avez le droit d’accéder à tout moment à l’ensemble de vos données à caractère personnel.</li>
                                <li>Droit de rectification : Vous avez le droit de rectifier à tout moment vos données à caractère personnel inexactes, incomplètes ou obsolètes.</li>
                                <li>Droit à la limitation : Vous avez le droit d’obtenir la limitation du traitement de vos données à caractère personnel dans certains cas définis à l’art.18 du RGPD.</li>
                                <li>Droit à l’oubli : Vous avez le droit d’exiger que vos données à caractère personnel soient effacées, et d’en interdire toute collecte future.</li>
                                <li>Droit à la portabilité : Vous avez le droit de recevoir les données à caractère personnel que vous nous avez fournies dans un format standard lisible par machine et d’exiger leur transfert au destinataire de votre choix.</li>
                                <li>Droit d’opposition : Vous avez le droit de vous opposer au traitement de vos données à caractère personnel. Notez toutefois que nous pourrons maintenir leur traitement malgré cette opposition, pour des motifs légitimes ou la défense de droits en justice</li>
                                <li>Droit d’introduire une réclamation auprès d’une autorité de contrôle compétente (en France, la CNIL), si vous considérez que le traitement de vos données à caractère personnel constitue une violation des textes applicables.</li>
                                <li>Droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données à caractère personnel après votre mort. Vous pouvez exercer ces droits en nous écrivant aux coordonnées ci-dessous.</li>
                            </ul>
                            <p className="text-md">
                                Nous pourrons vous demander à cette occasion de nous fournir des informations ou documents complémentaires pour justifier de votre identité.
                            </p>
                        </div>
                        <div id="UE" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">8. Point de contact en matière de données personnelles</h2>
                            <p className="text-md">
                                Courriel de contact : contact@zangou.ai
                            </p>
                            <p className="text-md">
                                Adresse de contact : Zangou SAS, 67 rue des Poissonniers 75018 Paris, France
                            </p>
                        </div>
                        <div id="UE" className="pr-2">
                            <h2 className="text-lg font-bold pt-4 py-2">9. Modifications</h2>
                            <p className="text-md">
                             Nous pouvons modifier à tout moment la présente charte. Ces modifications s’appliqueront à la date d’entrée en vigueur de la version modifiée. Vous êtes donc invité à consulter régulièrement la dernière version de cette charte.
                            </p>
                            <p className="text-md">
                                Entrée en vigueur : 10/07/2024
                            </p>
                        </div>
                    </div>
                    <ScrollBar orientation="vertical" className=" bg-opacity-0" />
                </ScrollArea>
            </DialogContent>
        </Dialog>
    </div>
    );
}
 
export default PrivacyPolicyModal;