//import { Group, Interest } from "../../../../api/contacts/interface-contact";
import { create } from "zustand";

export type ModalType = "Start" | "Career" | "Techwatch" | "Contacts" | "Tasks" | "Community"

interface ModalData {
    //group?: Group;
    //interest?: Interest;
    associatedContacts?: number;
}

interface ModalStore {
    type: ModalType | null;
    //data: ModalData;
    isOpen: boolean;
    onOpen: (type: ModalType, data?: ModalData) => void;
    onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
    type: null,
    data: {},
    isOpen: false,
    onOpen: (type, data={}) => set({ isOpen: true, type}),
    onClose: () => set({ type: null, isOpen: false })
}));