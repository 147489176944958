import { z } from "zod";

const passwordValidation = z.string()
  .min(6, "Password must be at least 6 characters long")
  .regex(/[a-z]/, "Password must contain at least one lowercase letter")
  .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
  .regex(/[0-9]/, "Password must contain at least one number")
  .regex(/[^a-zA-Z0-9]/, "Password must contain at least one special character");


export const userSchema = z.object({
    first_name: z.optional(
        z.string()
        //.min(1, { message: "First name is required" })
        .max(255, "Email can't be longer than 255 characters")),
    last_name: z.optional(z
        .string()
        //.min(1, { message: "Last name is required" })
        .max(255, "Email can't be longer than 255 characters")),
    email: z
        .string()
        .min(1, { message: "Email is required" })
        .max(255, "Email can't be longer than 255 characters")
        .email({ message: "Invalid email address" }),
    password: passwordValidation,
    confirm_password: passwordValidation,
    user_name: z.string().min(1, "Username is required").max(20, "Username can't be longer than 20 characters"),
    display_name: z.optional(z.string().nullable()),
    accept_gtc_pp: z.boolean().refine(val => val === true, { message: "You must accept the terms and conditions" })
}).refine(
    (values) => {
      return values.password === values.confirm_password;
    },
    {
      message: "Passwords must match!",
      path: ["confirmPassword"],
    }
  );


  export const emailSchema = z.object({
    email: z.string()
      .min(1, { message: "Email is required" })
      .max(255, "Email can't be longer than 255 characters")
      .email({ message: "Invalid email address" })
  });
  
  export const userNameSchema = z.object({
    user_name: z.string().min(1, "Username is required").max(20, "Username can't be longer than 20 characters")
  });

  export type UserSchema = z.infer<typeof userSchema>;