import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useModal } from "./use-modal-store";
import { Button } from "../../../../components/ui/button";
import { deleteChannel } from "../../../../api/community/api-delete-channel";
import { useTranslation } from 'react-i18next';
import { useCommunityActions, useSelectedChannel } from "../../../../hooks/community-hook";

export const DeleteChannelModal = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, type, data } = useModal();

  const isModalOpen = isOpen && type === "deleteChannel";
  const { channel } = data;

  const selectedChannel = useSelectedChannel();

  const { setModifiedChannel } = useCommunityActions();

  const [isLoading, setIsLoading] = useState(false);

  const [deletedChannel, setDeleteChannel] = useState<{message: string}>();

  const onClick = async () => {
    try {
      //console.log("DELETE CHANNEL MODAL");
      setIsLoading(true);
      if (selectedChannel) {
        //console.log("IF DELETE CHANNEL MODAL");
        const response = await deleteChannel(selectedChannel.id);
        setDeleteChannel(response);
        setModifiedChannel(false);
        onClose();
      }
    } catch (error) {
      //console.log("onClick: ", selectedChannel);
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    isModalOpen && setModifiedChannel(true);

    return () => {
      setModifiedChannel(false);
    };
}, [isModalOpen]);

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] text-black dark:text-white p-0 overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
              {t(`community.modals.deleteChannel.title`)}
          </DialogTitle>
          <DialogDescription className="text-center text-[#5D184F] dark:text-[#F8D3ED]">
          {t(`community.modals.deleteChannel.description`)}<br />
            <span className="text-community_primary font-semibold">{selectedChannel?.name} </span>{t(`community.modals.deleteChannel.warning`)}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="bg-[#F8F5F5] dark:bg-[#212121] px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="mt-4 text-[#A5328E] dark:text-[#A6308F] hover:bg-[#F4E2EE] dark:hover:bg-[#34172D] hover:text-[#5D184F] dark:hover:text-[#F98FDF] px-4 py-2"
              variant="ghost"
            >
              {t(`community.modals.deleteChannel.cancel`)}
            </Button>
            <Button
              className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]"
              disabled={isLoading}
              onClick={onClick}
            >
              {t(`community.modals.deleteChannel.confirm`)}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}