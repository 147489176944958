import { InputType, ReturnType } from "./types";
//import { db } from "@/lib/db";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { UpdateCardOrder } from "./schema";

import axiosInstance from "../../api";
import { Card } from "../../../interfaces/tasks/int-card";

const handler = async (data: InputType): Promise<ReturnType> => {
    //console.log("hitting update card order API call");
    //console.log('data update card order API call :', data);
    const response = await axiosInstance.patch<Card[]>(`/cards/updateCardOrder`, data).catch((error) => {
        //console.error('API call failed:', error);
        throw error; // Re-throw to ensure it's also caught by the outer try/catch
    });
    return { data: response.data };
};

export const updateCardOrder = createSafeAction(UpdateCardOrder, handler);
