import ChatHeader from "../chat/chat-header";
import ChatMessages from "../chat/chat-messages";
import ChatInput from "../chat/chat-input";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { cn } from "../../../../lib/utils";

interface ChannelIdPageProps {
  params: {
    serverId: string;
    serverName: string;
    channelId: string;
    channelName: string;
  }
}

export const MainChannel = ({
  params
}: ChannelIdPageProps) => {
  return (
    <div className="flex-grow flex flex-col h-full">
      <div className="hidden md:block flex-shrink-0 px-4">
        <ChatHeader 
          name={params.channelName}
          serverId={params.serverId}
          serverName={params.serverName}
          type="channel"
        />
      </div>
      <div 
        //className="flex-grow overflow-auto p-4"
        className={cn("flex-grow overflow-auto p-4",
          "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
      )}
      >
          <ChatMessages 
            name={params.channelName}
            channelId={params.channelId}
            type="channel"
          />
      </div>
      <div className="flex-shrink-0 p-4">
        <ChatInput
            name={params.channelName}
            type="channel"
          />
      </div>
      
    </div>
  );
}

export default MainChannel;