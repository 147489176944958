import React from 'react';
import { NavLink } from 'react-router-dom';
import img_tasks from '../../../img/landing/tasks.svg';
import img_custom from '../../../img/landing/tasks/Organizing projects-bro.svg';
import img_integration from '../../../img/landing/tasks/Software integration-bro.svg';
import img_notif from '../../../img/landing/tasks/Push notifications-bro.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';



const LandingTasks = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h1 className="text-4xl font-bold text-tasks_primary dark:text-[#FFA06A]">Tasks</h1>
                        <p className="mt-4 text-lg lg:text-xl text-[#BA4F00] dark:text-[#FFDECD]">
                            {t("landing.landingTasks.tagline")}
                        </p>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_tasks} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title1")}</h2>
                                <p className="mt-2 text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc1")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#BA4F00] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFD0B8] dark:hover:bg-[#542810]">
                                        {t("landing.landingTasks.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F8EEEA] dark:bg-[#1B130F] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_custom} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title2")}</h2>
                                <p className="mt-2 text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc2")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#BA4F00] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFD0B8] dark:hover:bg-[#542810]">
                                        {t("landing.landingTasks.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_integration} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title3")}</h2>
                                <p className="mt-2 text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc3")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#BA4F00] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFD0B8] dark:hover:bg-[#542810]">
                                        {t("landing.landingTasks.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F8EEEA] dark:bg-[#1B130F] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_notif} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title4")}</h2>
                                <p className="mt-2 text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc4")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#BA4F00] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFD0B8] dark:hover:bg-[#542810]">
                                        {t("landing.landingTasks.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#BA4F00] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFD0B8] dark:hover:bg-[#542810]">
                            {t("landing.landingTasks.cta2")}
                        </NavLink>
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
}

export default LandingTasks;
