import { z } from "zod";

export const UpdateCard = z.object({
  parent_elt: z.string(),
  description: z.optional(
    z.string({
      required_error: "Description is required",
      invalid_type_error: "Description is required",
    }).min(3, {
      message: "Description is too short",
    }),
  ),
  title: z.optional(
    z.string({
      required_error: "Title is required",
      invalid_type_error: "Title is required",
    }).min(3, {
      message: "Title is too short",
    })
  ),
  due_date: z.optional(z.date()),
  status: z.optional(z.enum(["TO_DO", "IN_PROGRESS", "DONE"])),
  energy: z.optional(z.enum(["LOW", "MEDIUM", "HIGH"])),
  duration: z.optional(z.enum(["V_MIN", "X_MIN", "XV_MIN", "XXX_MIN", "XLV_MIN", "I_H", "II_H", "III_H", "IV_H", "VI_H", "VIII_H", "I_DAY"])),
  id: z.string(),
});