import axiosInstance from "../api";
import { ComProfile } from "./interface-community";


export const fetchGetComProfile = async (): Promise<{username: string, avatar: string} | undefined> => {
    const response = await axiosInstance.get<{username: string, avatar: string} | undefined>('/com-profile/profile');
    return response.data;
}


export const fetchEditAvatar  = async (avatarUrl: string): Promise<ComProfile> => {
    const response = await axiosInstance.patch<ComProfile>(`/com-profile/avatar/${avatarUrl}`);
    return response.data;
}

export const fetchEditUserName  = async (username: string): Promise<ComProfile | string> => {
    const response = await axiosInstance.patch<ComProfile | string>(`/com-profile/username/${username}`);
    return response.data;
}
