import { Button } from '../../../../components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../../components/ui/card';
import React, { useState, useEffect, useRef } from 'react';
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { Input } from '../../../../components/ui/input';
import { CreateGroup, CreateInterest, Group, Interest } from '../../../../api/contacts/interface-contact';
import { useContactStoreActions, useContactTable, useGroups, useInterests } from '../../../../hooks/contact-store';
import { fetchAddGlobalInterest, fetchUpdateGlobalInterest, fetchUserInterests } from '../../../../api/contacts/api-contact-interest';
import { fetchAddGlobalGroup, fetchUpdateGlobalGroup } from '../../../../api/contacts/api-ContactGroup';
import { X } from 'lucide-react';
import { useModal } from '../modals/use-modal-store';
import { fetchUserGroups } from '../../../../api/contacts/api-getUserGroups';
import OpportunityStatusManager from './_comp_contacts/OpportunityStatusManager';

const ProfileContacts = () => {
    const { t } = useTranslation();
    const { onOpen } = useModal(); 
    
    // Fetching groups and interests from Zustand store
    const userGroups = useGroups();
    const userInterests = useInterests();
    const contactTable = useContactTable();  // Récupère la table des contacts

    // Fetching actions from the store
    const { updateGroups, updateInterests, deleteGroup, deleteInterest, initGroups, initInterests } = useContactStoreActions();

    const [loading, setLoading] = useState<boolean>(true);
    const [newGroupName, setNewGroupName] = useState<string>("");
    const [newInterestName, setNewInterestName] = useState<string>("");
    const [editGroupId, setEditGroupId] = useState<string | null>(null);  // State to track the group being edited
    const [editInterestId, setEditInterestId] = useState<string | null>(null);  // State to track the interest being edited

    const groupInputRef = useRef<HTMLInputElement>(null);  // Reference to Group input field
    const interestInputRef = useRef<HTMLInputElement>(null);  // Reference to Interest input field


    // Initialiser les groupes et intérêts via API au montage
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch API et initialiser les stores
                const [fetchedGroups, fetchedInterests] = await Promise.all([
                    fetchUserGroups(),
                    fetchUserInterests(),
                ]);
                
                // Initialisation dans Zustand
                initGroups(fetchedGroups);
                initInterests(fetchedInterests);
            } catch (error) {
                toast.error("Failed to load groups or interests");
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        // Appel de l'API seulement si le store n'est pas initialisé
        if (!userGroups || !userInterests) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [userGroups, userInterests, initGroups, initInterests]);

    // Auto-focus the input when a group or interest is clicked for editing
    useEffect(() => {
        if (editGroupId && groupInputRef.current) {
            groupInputRef.current.focus();
        }
    }, [editGroupId]);

    useEffect(() => {
        if (editInterestId && interestInputRef.current) {
            interestInputRef.current.focus();
        }
    }, [editInterestId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleEditGroup = (group: Group) => {
        setEditGroupId(group.id);
        setNewGroupName(group.name);
    };

    const handleEditInterest = (interest: Interest) => {
        setEditInterestId(interest.id);
        setNewInterestName(interest.name);
    };

    const handleClearGroupEdit = () => {
        setEditGroupId(null);
        setNewGroupName("");
    };

    const handleClearInterestEdit = () => {
        setEditInterestId(null);
        setNewInterestName("");
    };

    // Group management logic
    const handleAddOrUpdateGroup = async () => {
        if (!newGroupName.trim()) {
            toast.error("Group name cannot be empty");
            return;
        }

        if (editGroupId) {
            const group: Group = {id: editGroupId, name: newGroupName };
            // Update existing group
            try {
                await fetchUpdateGlobalGroup(group);
                updateGroups({ id: editGroupId, name: newGroupName });
                toast.success(`Group "${newGroupName}" updated successfully`);
            } catch (error) {
                toast.error("Failed to update group");
                //console.error(error);
            } finally {
                setEditGroupId(null);
                setNewGroupName("");
            }
        } else {
            // Add new group
            const group: CreateGroup = { name: newGroupName };
            try {
                const newGroup = await fetchAddGlobalGroup(group);
                updateGroups(newGroup);  // Updating groups in the store
                setNewGroupName("");  // Reset input field
                toast.success(`Group "${newGroupName}" added successfully`);
            } catch (error) {
                toast.error("Failed to add group");
                //console.error(error);
            }
        }
    };

    const handleRemoveGroup = async (groupId: string) => {

        // Calculer le nombre de contacts associés à ce groupe
        const associatedContacts = contactTable?.filter(contact =>
            contact.con_contacts_groups.some(cg => cg.con_groups.id === groupId)
        ).length || 0;

        // Récupérer le groupe correspondant
        const group = userGroups?.find(g => g.id === groupId);

        // Ouvrir le modal en passant les informations nécessaires
        if (group) {
            onOpen("deleteGroup", { group, associatedContacts });
        }
    };

    // Interest management logic
    const handleAddOrUpdateInterest = async () => {
        if (!newInterestName.trim()) {
            toast.error("Interest name cannot be empty");
            return;
        }

        if (editInterestId) {
            // Update existing interest
            const interest: Interest = { id: editInterestId, name: newInterestName };
            try {
                await fetchUpdateGlobalInterest(interest);
                updateInterests({ id: editInterestId, name: newInterestName });
                toast.success(`Interest "${newInterestName}" updated successfully`);
            } catch (error) {
                toast.error("Failed to update interest");
                console.error(error);
            } finally {
                setEditInterestId(null);
                setNewInterestName("");
            }
        } else {
            // Add new interest
            const interest: CreateInterest = { name: newInterestName };

            try {
                const newInterest = await fetchAddGlobalInterest(interest);
                updateInterests(newInterest);  // Mise à jour du store après ajout
                setNewInterestName("");  // Reset input field
                toast.success(`Interest "${newInterestName}" added successfully`);
            } catch (error) {
                toast.error("Failed to add interest");
                console.error(error);
            }
        }
    };

    const handleRemoveInterest = (interestId: string) => {
        // Calculer le nombre de contacts associés à cet intérêt
        const associatedContacts = contactTable?.filter(contact =>
            contact.con_contacts_interests.some(cg => cg.con_interests.id === interestId)
        ).length || 0;

        // Récupérer l'intérêt correspondant
        const interest = userInterests?.find(g => g.id === interestId);

        // Ouvrir le modal en passant les informations nécessaires
        if (interest) {
            onOpen("deleteInterest", { interest, associatedContacts });
        }
    };



    return ( 
        <div className="p-5">
            <Card
                className="flex flex-col p-4 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mb-4 max-w-full justify-center"
            >
                <CardHeader>
                    <CardTitle className="text-lg md:text-2xl font-bold text-[#133867] dark:text-[#D1E7FF]">{t("profile.contacts.title")}</CardTitle>
                    <CardDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">{t("profile.contacts.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    {/* Group Management */}
                    <div className="py-4">
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.contacts.descriptionGroups")}</h2>
                        <div className="flex flex-wrap">
                            {userGroups?.map(group => (
                                <div 
                                    key={group.id} 
                                    className="flex items-center m-1 px-2 py-1 bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
                                >
                                     <p className="flex cursor-pointer align-middle text-xs md:text-sm" onClick={() => handleEditGroup(group)}>{group.name}</p>
                                        <span 
                                            className="text-white cursor-pointer align-middle px-1" 
                                            onClick={() => handleRemoveGroup(group.id)}
                                        >
                                            <X size={16} />
                                        </span>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                            <Input
                                type="text"
                                ref={groupInputRef}
                                className="shadow border rounded max-w-sm py-2 px-3 bg-[#EEF1F0] text-[#1A3C34] dark:bg-[#172A26] dark:text-[#BCECDF] leading-tight focus:border-[#1A3C34] dark:focus:border-[#1A3C34] focus:outline-none focus:ring-0"
                                value={newGroupName}
                                onChange={(e) => setNewGroupName(e.target.value)}
                                placeholder="Add group name"
                            />
                            {editGroupId && <X className="cursor-pointer" onClick={handleClearGroupEdit} />}
                            <Button 
                                className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
                                onClick={handleAddOrUpdateGroup}
                            >
                                {editGroupId ? "Update Group" : "Add Group"}
                            </Button>
                        </div>
                    </div>
                    {/* Interest Management */}
                    <div className="py-4">
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.contacts.descriptionInterests")}</h2>
                        <div className="flex flex-wrap">
                            {userInterests?.map(interest => (
                                <div 
                                    key={interest.id} 
                                    className="flex items-center m-1 px-2 py-1 bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
                                >
                                    <p className="flex cursor-pointer align-middle text-xs md:text-sm" onClick={() => handleEditInterest(interest)}>{interest.name}</p>
                                    <span 
                                            className="text-white cursor-pointer align-middle px-1" 
                                            onClick={() => handleRemoveInterest(interest.id)}
                                        >
                                            <X size={16} />
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center gap-2 mt-4">
                            <Input
                                type="text"
                                ref={interestInputRef}
                                className="shadow border rounded max-w-sm py-2 px-3 bg-[#EEF1F0] text-[#1A3C34] dark:bg-[#172A26] dark:text-[#BCECDF] leading-tight focus:border-[#1A3C34] dark:focus:border-[#1A3C34] focus:outline-none focus:ring-0"
                                value={newInterestName}
                                onChange={(e) => setNewInterestName(e.target.value)}
                                placeholder="Add interest name"
                            />
                            {editInterestId && <X className="cursor-pointer" onClick={handleClearInterestEdit} />}
                            <Button 
                                className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
                                onClick={handleAddOrUpdateInterest}
                            >
                                {editInterestId ? "Update Interest" : "Add Interest"}
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card
                className="flex flex-col p-4 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mb-4 max-w-full justify-center"
            >
                <CardHeader>
                    <CardTitle className="text-lg md:text-2xl font-bold text-[#133867] dark:text-[#D1E7FF]">{t("profile.contacts.title")}</CardTitle>
                    <CardDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">{t("profile.contacts.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    <OpportunityStatusManager />
                </CardContent>
            </Card>
        </div>
    );
}
 
export default ProfileContacts;