import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate
  } from "react-router-dom";
import './App.css';

import NavigationService from './lib/navigateUtility';

//authentification
import { getAuthStoreActions, useIsAuthenticated } from './hooks/auth-store';

//components
import Header from './components/Header';
import Footer from './components/Footer';

//landing pages
import LandingPage from './pages/Landing';
import LandingContacts from './pages/Landing/landing-subpages/landing-contacts';
import LandingTechWatch from './pages/Landing/landing-subpages/landing-techwatch';
import LandingCommunity from './pages/Landing/landing-subpages/landing-community';
import LandingCareer from './pages/Landing/landing-subpages/landing-career';
import LandingTasks from './pages/Landing/landing-subpages/landing-tasks';
import LandingVisibility from './pages/Landing/landing-subpages/landing-visibility';
// new landing 
import Landing from './pages/Landing/Landing';

// Sign in and Sign up pages
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ResetPassword from './pages/SignIn/ResetPassword';
import VerifyEmail from './pages/SignUp/VerifyEmail';

//Translation
import "./components/multilingual/i18n";

//product pages
import Career from './pages/CareerPath';
import Contacts from './pages/Contacts';
import TechWatch from './pages/TechWatch';
import Commmunity from './pages/Community';
import Visibility from './pages/Visibility';
import Profile from './pages/Profile';
import Tasks from './pages/Tasks';

import { ThemeProvider } from './components/theme-provider/theme-provider';

//Other components
import { Toaster } from "./components/ui/toaster";
import { Toaster as Sonner } from "sonner"
import { Spinner } from './components/ui/spinner';

//import useWebSocketStore from './hooks/socket-store';


const MainRoutes = () => {
  // Authentification initialisation and management
  const { init } = getAuthStoreActions();
  //init();

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    init().then(() => setLoading(false));
  }, [init]);

  const isAuthenticated = useIsAuthenticated();

  //console.log("isAuthenticated: ", isAuthenticated);
  const navigate = useNavigate();
  // Set the navigate function at the start of your application
  useEffect(() => {
    NavigationService.setNavigate(navigate);
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full gap-3">
        <Spinner>Loading...</Spinner>
      </div>
    ); // Or some other @loading indicator
  };

  
  return (
    <Routes>
      {/* ... product routes ... */}
      <Route path="/" element={<Landing />} />
      <Route path="/Landing" element={<Landing />} />
      <Route path="/productContacts" element={<LandingContacts />} />
      <Route path="/productTechWatch" element={<LandingTechWatch />} />
      <Route path="/productCommunity" element={<LandingCommunity />} />
      <Route path="/productCareer" element={<LandingCareer />} />
      <Route path="/productVisibility" element={<LandingVisibility />} />
      <Route path="/productTasks" element={<LandingTasks />} />

      {/* ... auth routes ... */}
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signin/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      
      {/* ... logged in routes ... */}
      <Route path="/Career" element={isAuthenticated ? <Career /> : <Navigate to="/productCareer" replace={true} />} />
      <Route path="/Contacts" element={isAuthenticated ? <Contacts /> : <Navigate to="/productContacts" replace={true} />} />
      <Route path="/TechWatch" element={isAuthenticated ? <TechWatch /> : <Navigate to="/productTechWatch" replace={true} />} />
      <Route path="/Community" element={isAuthenticated ? <Commmunity /> : <Navigate to="/productCommunity" replace={true} />} />
      <Route path="/Tasks" element={isAuthenticated ? <Tasks /> : <Navigate to="/productTasks" replace={true} />} />
      <Route path="/Visibility" element={isAuthenticated ? <LandingVisibility /> : <Navigate to="/productVisibility" replace={true} />} />
      <Route path="/Profile" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" replace={true} />} />

      {/*
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/features" element={<CRMFeaturesPage />} />
       ... other routes ... 
      <Route exact path="/" element={<Home />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/signin" element={<SignIn />} />
      <Route exact path="/TechNews" element={<TechNews />} />
      <Route exact path="/CareerPath" element={<CareerPath />} />
      <Route exact path="/Visibility" element={<Visibility />} />
      <Route exact path="/test" element={<TestGPT />} />
      */}
      {/*<Navigate to={isAuthenticated ? "/crm" : "/features"} replace={true} />*/}
    </Routes>
  );
};


const App: React.FC = () => {

  /*
  const { initializeSocket } = useWebSocketStore();

  useEffect(() => {
    initializeSocket();
    // N'oubliez pas de gérer la déconnexion si nécessaire
  }, [initializeSocket]);
  */
  //const setAuthData = useStore((state: any) => state.setAuthData); // TO DO : Add types to this function AuthData?
  
  return (
    /*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */
    <Router>
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        enableSystem={false}
        storageKey="theme"
        >
        {/*<AuthProvider>*/}
        <div className="bg-white dark:bg-[#313338] flex flex-col h-screen overflow-hidden"
          //overflow-hidden
        >
          <div className="flex-shrink-0">
              <Header />
          </div>
          <div className="flex flex-grow overflow-hidden"// h-full w-fulloverflow-hidden"
           //flex-grow 
          >
            <MainRoutes />
          </div>
          <Toaster />
          <Sonner />
        </div>
        
        {/*</AuthProvider>*/}
      </ThemeProvider>
    </Router>

  );
};

export default App;
