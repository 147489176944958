import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { cn } from "../../../../../lib/utils";
import { format } from "date-fns"

import { Action, Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";
import { useBoards, useTaskStoreActions } from "../../../../../hooks/tasks-store";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"
import { fetchUserBoards } from "../../../../../api/tasks/api-get-elements";
import { createBoard } from "../../../../../api/tasks/create-board" ;

import { CalendarCheck, CalendarDays, CalendarIcon, CalendarRange, ChevronsLeftRightEllipsis, DatabaseZap, DollarSign, Landmark, NotebookText, PiggyBank, Shield, Square, SquareCheck, StickyNote } from "lucide-react";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../../components/ui/select";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../../components/ui/popover"

import { Button } from "../../../../../components/ui/button";
import { FormSubmit } from "../../../../../components/form/form-submit";
import { Badge } from "../../../../../components/ui/badge";
import { Calendar } from "../../../../../components/ui/calendar";
import { Textarea } from "../../../../../components/ui/textarea";

interface OppyActionsUnitNewProps {
    data: Opportunity;
    //isNewAction: string;
    //setIsNewAction: React.Dispatch<React.SetStateAction<boolean>>;
    isEditingAction: string | boolean;
    setIsEditingAction: React.Dispatch<React.SetStateAction<string | boolean>>;
}

const OppyNewActionsUnit = ({
    data,
    //isNewAction,
    //setIsNewAction,
    isEditingAction,
    setIsEditingAction,
}: OppyActionsUnitNewProps) => {

    const { t } = useTranslation();

    const newActionObject: Action = {
        id: undefined,
        board: data?.actions && data.actions[0]?.board ? data.actions[0].board : "",
        title: "",
        status: "",
        due_date: null,
    }

    const foundAction = data.actions?.find((action) => action.id === isEditingAction);
    const [editingAction, setEditingAction] = useState<Action>(foundAction ?? newActionObject);

    const [isEditing, setIsEditing] = useState(false);
    const [isChooseBoard, setChooseBoard] = useState(false);

    // board
    const [openBoardPopover, setopenBoardPopover] = useState(false);
    const boards = useBoards();
    const { initBoards } = useTaskStoreActions();
    type TemplateType = 'blank' | 'kanban' | 'calendar' | 'opportunities';


    useEffect(() => {
        const getBoards = async () => {
            const response = await fetchUserBoards();
            await initBoards(response);
        }

        if (!boards) {
            getBoards();
        }
    }, [])

    const { updateOneBoard, setSelectedBoard, setSelectedBoardElement } = useTaskStoreActions();

    const { execute: executeCreateBoard, fieldErrors } = useAction(createBoard, {
        onSuccess: (data) => {
            updateOneBoard(data);
            setEditingAction((prevAction) => ({
                ...prevAction,
                board: data.id,
              }));
            setopenBoardPopover(false);
            toast.success("Board created!");
        },
        onError: (error) => {
            //console.log({ error });
            toast.success(error);
        }
    });
    
    const handleBoardChange = (boardId: string) => {
        if (boardId === "newOpportunityBoard") {
            const template: TemplateType = 'kanban';
            executeCreateBoard({ title: 'Opportunities', template });
        } else if (boardId === data.title) {
            const template: TemplateType = 'kanban';
            const newBoardTitle: string = '[Oppy] ' + data.title;
            executeCreateBoard({ title: newBoardTitle, template });
        } else {
            setEditingAction((prevAction) => ({
                ...prevAction,
                board: boardId,
              }));
            setopenBoardPopover(false);
        }
    };

    // title
    const inputTitleRef = useRef<HTMLTextAreaElement>(null);

    const handleTitleChange = (title: string) => {
        setEditingAction((prevAction) => ({
            ...prevAction,
            title,
          }));
    }

    useEffect(() => {
        if (inputTitleRef.current) {
          const adjustment = 2;
          inputTitleRef.current.style.height = 'auto'; // Reset height to shrink if text is deleted
          const idealHeight = inputTitleRef.current.scrollHeight + adjustment;
          inputTitleRef.current.style.height = `${idealHeight}px`;
        }
      }, [isEditing, editingAction.title]);

    // Task due date
    // open / close due date Popover
    const [openDueDatePopover, setopenDueDatePopover] = useState(false);
    const handleDeadlineDateChange = (value: Date) => {
        setEditingAction((prevAction) => ({
            ...prevAction,
            due_date: value,
          }));
        setopenDueDatePopover(false);
    };

    // Save changes
    const { createUpdateOpportunity } = useOpportunityStoreActions();
    
    const { execute: executeUpdateOpportunity } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            //setDescription(data.description);
            setIsEditing(false);
            setIsEditingAction(false);
        },
        onError: (error) => {
            toast.error("An error occurred. Please try again.");
        }
    });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        if (!editingAction.board) {
            toast.error('Please select a board before submitting.');
            return;
          }

        if (data.id) {
            if (editingAction.id) {
                // if the action exist; we update it
                executeUpdateOpportunity({
                    id: data.id,
                    action_to_update: {
                        id: editingAction.id,
                        board: editingAction.board,
                        title: editingAction.title,
                        deadline_date: editingAction.due_date,
                    }
                });
            } else {
                // if the action doesn't exist; we create it
                executeUpdateOpportunity({
                    id: data.id,
                    action_to_update: {
                        board: editingAction.board,
                        title: editingAction.title,
                        status:'TO_DO',
                        deadline_date: editingAction.due_date,
                    }
                });
            }
        } 
    }

    const disableEditing = () => {
        //setIsNewAction(false);
        setIsEditingAction(false);
    }

    return ( 
        <div id="actions-new-editing-full" className="flex flex-col text-sm p-1 w-full h-full gap-y-1">
            <form onSubmit={onSubmit} className="w-full h-full gap-y-2">
                <label htmlFor="rate" className="flex w-full items-center gap-x-1 m-0 p-0 text-sm">
                    {t("contacts.opportunities.nextTasks.board")}
                </label>
                <Popover
                    open={openBoardPopover} 
                    onOpenChange={setopenBoardPopover}
                >
                    <PopoverTrigger asChild>
                        <button>
                            <Badge 
                                variant="contacts"
                                className={cn("text-xs md:text-sm cursor-pointer whitespace-nowrap",
                                    editingAction.board ? "bg-transparent border-none italic font-medium" : 
                                    ""
                                )}
                            >
                            {boards?.find((board) => board.id === editingAction.board) ? 
                                boards.find((board) => board.id === editingAction.board)?.title?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) :
                                t("contacts.opportunities.nextTasks.selectBoard")
                            }
                            </Badge>
                        </button>
                    </PopoverTrigger>
                    <PopoverContent className="p-2">
                        <div className="flex flex-col text-xs md:text-sm max-h-48 overflow-y-auto scrollbar-thin scrollbar-track-transparent">
                            {!boards?.find((board) => board.title === "Opportunities") && (
                                <div
                                    key=""
                                    onClick={() => handleBoardChange('newOpportunityBoard' as string)}
                                    className={cn(
                                        "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                    )}
                                >
                                    {t("contacts.opportunities.nextTasks.createOppyBoard")}
                                </div>
                            )}
                            {!boards?.find((board) => board.title === '[Oppy] ' + data.title) && (
                                <div
                                    key=""
                                    onClick={() => handleBoardChange(data.title as string)}
                                    className={cn(
                                        "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                    )}
                                >
                                    {t("contacts.opportunities.nextTasks.createOppyBoardSpecific")}
                                </div>
                            )}
                            {boards && boards.map((board) => (
                                <div
                                    key={board.id}
                                    onClick={() => handleBoardChange(board.id as string)}
                                    className={cn(
                                        "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                        board.id === editingAction.board && "font-bold"
                                    )}
                                >
                                    {board.title.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                </div>
                            ))}
                        </div>
                    </PopoverContent>
                </Popover>

                <label htmlFor="title" className="flex w-full items-center gap-x-1 m-0 mt-2 p-0 text-sm">
                    {t("contacts.opportunities.nextTasks.title")}
                </label>
                <Textarea
                        placeholder={t("tasks.card.placeholder")}
                        ref={inputTitleRef}
                        id="title"
                        name="title"
                        value={editingAction.title}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        rows={1}
                        className="w-full items-center text-sm px-1 pt-1 bg-[#F1F1F3] dark:bg-transparent dark:text-[#FFDECD] border-transparent overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                        //className="w-full items-center text-sm px-1 pt-1 bg-[#F1F1F3] dark:bg-transparent dark:text-[#FFDECD] border-transparent relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                        style={{ minWidth: `140px`, minHeight: `36px`, lineHeight: '1.5' }}
                        disabled={!data.id}
                    />

                
                    <label htmlFor="dueDate" className="flex w-full items-center gap-x-1 m-0 mt-2 p-0 text-sm">
                        {t("contacts.opportunities.nextTasks.deadline")}
                    </label>
                    <Popover
                        open={openDueDatePopover} 
                        onOpenChange={setopenDueDatePopover} 
                    >
                        <PopoverTrigger asChild >
                            <Button
                            variant={"outline"}
                            className={cn(
                                //"w-[280px] justify-start text-left font-normal",
                                "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                                !editingAction.due_date && "text-muted-foreground"
                            )}
                            >
                            {editingAction.due_date ? format(editingAction.due_date, "MMM do, yyyy") : <span className="hidden md:block">
                                {t("tasks.card.edit.deadline")}
                                </span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={editingAction.due_date ? editingAction.due_date : undefined}
                                onSelect={(value) => value && handleDeadlineDateChange(value)}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                
                <div className="flex items-center gap-x-2 mt-2">
                    <Button
                        type="submit"
                        size="sm"
                        variant="contacts_primary"
                        className="text-xs md:text-sm"
                    >
                        {t("tasks.card.edit.description.save")}
                    </Button>
                    <Button
                        type="button"
                        onClick={disableEditing}
                        size="sm"
                        variant="contacts"
                        className="text-xs md:text-sm"
                    >
                        {t("tasks.card.edit.description.cancel")}
                    </Button>
                </div>
            </form>
        </div>
     );
}
 
export default OppyNewActionsUnit;