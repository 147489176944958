import { ReactFlowProvider } from "reactflow";
import { CareerPathFlow } from "../CareerPathFlow";
import { useCareerHookActions, useCreationPlan, useSelectedPath, useSelectedPlan } from "../../../../hooks/career-hook";
import LandingPlan from "./landing/landing-plan";
import { useEffect } from "react";
import { fetchPlanElementsData } from "../../../../api/career/api-plan-elements";

const PlanPage = () => {

    const selectedPath = useSelectedPath();
    const selectedPlan = useSelectedPlan(); 
    const creationPlan = useCreationPlan();
    const { setSelectedPlan, getPlanByPath, setCreationPlan, setPlanElements } = useCareerHookActions();    
    
    useEffect(() => {
      if (selectedPath) {
        const currentPlan = selectedPath ? getPlanByPath(selectedPath): undefined;

        currentPlan && setSelectedPlan(currentPlan.id);
        
        setCreationPlan(currentPlan);

        if (currentPlan?.id && currentPlan?.current_step  ) {
          
          const getPlanElements = async () => {
            const retrievePlanElements = currentPlan?.id && await fetchPlanElementsData(currentPlan?.id);
            //save plan elements in the store to use data in other components than the flow
            if (retrievePlanElements) {
              setPlanElements(retrievePlanElements);
            } else {
              setPlanElements(undefined);
            }
    
            //console.log("planElements :", retrievePlanElements);
          }
          getPlanElements();
        }
      }
    }, [selectedPath]);
    
  
    return (
      <div className="flex overflow-x-auto">
        {selectedPlan && (creationPlan?.current_step && creationPlan?.current_step > 4) ? (
          <ReactFlowProvider>
            <CareerPathFlow />
          </ReactFlowProvider>
        ) : (
          <LandingPlan />
        ) 
        }
      </div>
    );
}
 
export default PlanPage;