import { Edge, Position, Node as ReactFlowNode } from "reactflow";

type NodeCategory = 'MAIN' | 'MISSION' | 'GOCHAT' | 'GOLEARN' | 'TECHWATCH' | 'VISIBILITY' | 'NONE';

const positionNodes = async (nodeOption: string, nodes: ReactFlowNode[], edges: Edge[]) => {

    //console.log("hitting positioning nodes : ", nodes);
    
    // set the margins for the nodes
    const marginX = 70;
    const marginY = 100;

    const nodeWidth = (nodeOption === "ClassicNode") ? 300 : 440;
    const nodeHeight = (nodeOption === "ClassicNode") ? 100 : 100;


    // Map the node types to their y position values => TO DO : change the values to be more dynamic
    const categoryValueMap: { [key in NodeCategory]: number } = {
        MAIN: 0,
        MISSION: 150,
        GOLEARN: 300,
        GOCHAT: 450,
        TECHWATCH: 600,
        VISIBILITY: 750,
        NONE: 900,
    };

    function isNodeCategory(value: any): value is NodeCategory {
        //return ['main', 'mission', 'goChat', 'goLearn', 'techwatch', 'visibility'].includes(value);
        return ['MAIN', 'MISSION', 'GOCHAT', 'GOLEARN', 'TECHWATCH', 'VISIBILITY', 'NONE'].includes(value);
    }

    // Old test where we tried to set the nodes in a vertical way -> to be tested again in the future
    let isVertical = false;

    // Enrich the nodes with new width, height, and target / source position values
    let updatedNodes = nodes.map(node => {
        // Check if the node is not a group node
        if (node.data.type === "STEP") {
            return {
                ...node,
                width: 440, //440
                height: 100,
                targetPosition: isVertical ? Position.Top : Position.Left,
                sourcePosition: isVertical ? Position.Bottom : Position.Right,
            };
        } else if (node.data.type === "CATEGORY") { // Category labels on the side of the graph
            return {
                ...node,
                width: nodeOption === "ClassicNode" ? 300 : 6,
                height: nodeOption === "ClassicNode" ? 100 : 6,
                //targetPosition: isVertical ? Position.Top : Position.Left,
                //sourcePosition: isVertical ? Position.Bottom : Position.Right,
            };
        } else { // Regular nodes
          // Update the node with new width and height based on the condition
          return {
            ...node,
            width: nodeOption === "ClassicNode" ? 300 : 6,
            height: nodeOption === "ClassicNode" ? 100 : 6,
            targetPosition: isVertical ? Position.Top : Position.Left,
            sourcePosition: isVertical ? Position.Bottom : Position.Right,
            
          };
        }
    });
    
    //console.log("enriching nodes : ", updatedNodes);

    const yearTypeCount = calculateMaxNodesPerYearPerType(updatedNodes);
    //console.log('calculate max nodes : ', yearTypeCount);
    

    let widthPerYear: { [year: string]: number } = {};
    for (let year in yearTypeCount) {
        widthPerYear[year] = Math.max(yearTypeCount[year].count * nodeWidth + (2 * yearTypeCount[year].count + 1) * marginX, 440);
    }
    //console.log("width per year : ", widthPerYear);

    // To calculate the cumulative width up to a certain year
    const calculateCumulativeWidth = (upToYear: string) => {
        let cumulativeWidth = 0;
        Object.keys(widthPerYear).forEach(year => {
            if (parseInt(year) < parseInt(upToYear)) {
                cumulativeWidth += widthPerYear[year];
            }
        });
        return cumulativeWidth;
    };

    //console.log("cumulative width 1 : ", calculateCumulativeWidth("1"));

    // Structure to keep track of node counts per year and type
    const nodeCounts: Record<string, number> = {};

    updatedNodes = updatedNodes.map(node => {
        // Check if the node is not a group node
        if (node.data.type === "STEP") {
          // Update the node with new width and height based on the condition
          return {
            ...node,
            position: { 
                x: calculateCumulativeWidth(node.data.year.toString()) + widthPerYear[node.data.year.toString()] / 2 - (node.width || 440) / 2, //- marginX, 
                y: 0 + marginY,
             },
            //width: widthPerYear[node.data.year.toString()] - 2 * marginX,
            //height: 100, //nodeHeight,
            targetPosition: isVertical ? Position.Top : Position.Left,
            sourcePosition: isVertical ? Position.Bottom : Position.Right,
          };
        }
        else if (node.data.type !== "STEP") {
            const nodeDataCategory = node.data.category;
            if (isNodeCategory(nodeDataCategory)) {
                
                return {
                    ...node,
                    position: {
                        x: calculateCumulativeWidth(node.data.year.toString()) + calculateXPosition(node, nodeCounts, nodeWidth, marginX) + marginX,
                        y: categoryValueMap[nodeDataCategory] + marginY,
                    }
                };

            } else {
                return node;
            }
        }
        return node;
    });
    
    //console.log("nodes after group positionning : ", updatedNodes);
    
    return { newNodes: updatedNodes, newEdges: edges };
}
 
export default positionNodes;

// Function to calculate the maximum number of nodes per year per data.type
const calculateMaxNodesPerYearPerType = (nodes: ReactFlowNode[]) => {
    const yearTypeCounts: { [year: string]: { [category: string]: number } } = {};
  
    // Counting nodes per year and type
    nodes.forEach(node => {
      const { year, category } = node.data;
      if (!yearTypeCounts[year]) yearTypeCounts[year] = {};
      if (!yearTypeCounts[year][category]) yearTypeCounts[year][category] = 0;
      yearTypeCounts[year][category]++;
    });
  
    // For each year, find the type with the maximum nodes
    const maxNodesPerYearPerType: { [year: string]: {category: string, count: number} } = {};
    Object.entries(yearTypeCounts).forEach(([year, categories]) => {
      const maxType = Object.entries(categories).reduce((max, [category, count]) => count > max.count ? {category, count} : max, {category: '', count: 0});
      maxNodesPerYearPerType[year] = maxType;
    });
  
    return maxNodesPerYearPerType;
  };

  // Function to incrementally calculate the x position based on previous nodes of the same year and type
const calculateXPosition = (node: ReactFlowNode, nodeCounts: Record<string, number>, nodeWidth: number, marginX: number): number => {
    const key = `${node.data.year}-${node.data.category}`;
    const count = nodeCounts[key] || 0;
    nodeCounts[key] = count + 1; // Increment the count for the next node of the same year and type
    //console.log("nodeCounts : ", nodeCounts);
    //return count * (nodeWidth + marginX);
     // Calculate the x position based on the count
    return count * nodeWidth + (count + 1) * marginX;
};
