import { useEffect, useState } from "react";

//import { fetchContactData } from "../../../../api/contacts/api-getContactData";

import { getContactsHookActions, useInitialSelectedContact, useNewContact, useSelectedContact } from "../../../../hooks/contact-hook";

import ContactCard from "./_components/ContactCard";
import ContactGroups from "./_components/ContactGroups";
import ContactInfoTable from "./_components/ContactInfoDetails";

//import { detailedContact } from "../../../../interfaces/contacts/int-contacts";
import ContactInterests from "./_components/ContactInterests";

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../../../../components/ui/accordion"
import { useToast } from "../../../../components/ui/use-toast";
import ContactTimeline from "./_components/ContactTimeline";

import { ContactTableElement, useContactStoreActions } from "../../../../hooks/contact-store";
import { ArrowBigLeft } from 'lucide-react';

export interface ContactDetailsProps {
    newContact: boolean;
    // You can add more props here if needed
}

//const ContactDetails: React.FC<ContactDetailsProps> = ({ newContact }) => {
const ContactDetails = () => {
    //initialize toast
    const { toast } = useToast();

    //Retrieve selected contact from the store
    const selectedContact = useSelectedContact();
    const initialSelectedContact = useInitialSelectedContact();

    //test
    //console.log('contact-details');
    //console.log('selectedContact :', selectedContact);
    //console.log('initialSelectedContact :', initialSelectedContact);

    // retrieve the step of the new contact
    const { setNewContact, setSelectedContact, setInitialSelectedContact } = getContactsHookActions(); // to be deleted if not used
    const newContact = useNewContact();

    //initialize initialSelectedContact Data (populated by the useEffect below)
    const [initialSelectedContactData, setInitialSelectedContactData] = useState<undefined | ContactTableElement>(undefined);
    const { getContactById } = useContactStoreActions();

    //initialize open panels
    const [openPanels, setOpenPanels] = useState<string[]>([]);

    const togglePanel = (panelId?: string[]) => {
        //console.log("hitting togglePanel");
        if (newContact && !selectedContact) {
            // If the new contact is not saved, close all panels and display a toast message to avoid showing groups, interests, etc
            if (panelId) {
                setOpenPanels([]);
                //toast.error("Please save the new contact before adding more details.");
                toast({
                    description: "Please save the new contact before adding more details.",
                  });
            } else {
                setOpenPanels([]);
            }
            //console.log("new contact and no selected contact");
        } else if (newContact && selectedContact) {
            if (newContact === "Groups") {
                setOpenPanels(["Groups"]);
                if (panelId) {
                    toast({
                        description: `Please save or skip the step ${newContact} before adding more details.`,
                      });
                }
            } else if (newContact === "Interests") {
                setOpenPanels(["Interests"]);
                if (panelId) {
                    toast({
                        description: `Please save or skip the step ${newContact} before adding more details.`,
                      });
                }
                
            } else if (newContact === "Timeline") {
                setOpenPanels(["Timeline"]);
                if (panelId) {
                    toast({
                        description: `Please save or skip the step ${newContact} before adding more details.`,
                      });
                }
            } else if (newContact === "Informations") {
                setOpenPanels(["Informations"]);
                if (panelId) {
                    toast({
                        description: `Please save or skip the step ${newContact} before adding more details.`,
                      });
                }
            }
            
            //console.log("new contact and selected contact");
        } else if (selectedContact) {
            //console.log("selected contact");
            if (panelId) {
                // Open or close the panels upon user request
                setOpenPanels(panelId);
               //console.log("panelId: does this work", panelId);
            }
            else {
                // initialize the panels to be open if there is a selected contact
                setOpenPanels(["Groups", "Interests", "Timeline", "Informations"]);
            }

        } else {
            setOpenPanels([]);
        }
    };

    useEffect(() => {
        togglePanel();
        if(initialSelectedContact) {
            const initialSelectedContactFromStore = getContactById(initialSelectedContact);
            if (initialSelectedContactFromStore) {
                setInitialSelectedContactData(initialSelectedContactFromStore.contact);
            }
        }
    }, [selectedContact, newContact]);

    if (!selectedContact && !newContact) {
        return null;
    }

    
    const handleFormerContactClick = () => {
        if (initialSelectedContact) {
                //setNewContact(undefined);
                setSelectedContact(initialSelectedContact);
                setInitialSelectedContact(undefined);
        } else {
            setSelectedContact(undefined);
            setInitialSelectedContact(undefined);
        }
    };
    

    return ( 
        <div className="flex flex-col h-screen">
            {(selectedContact || newContact) && (
                <div className="flex-grow overflow-auto rounded-md border">
                    {initialSelectedContact && (
                        <div 
                            className="inline-flex items-center rounded-sm bg-contacts_primary text-white m-1 px-3 py-2 cursor-pointer"
                            onClick={() => handleFormerContactClick()} 
                        >
                            <ArrowBigLeft className="h-5 w-5" /> Back to {initialSelectedContactData?.first_name} {initialSelectedContactData?.last_name}
                        </div>
                    )}
                    <div className="flex flex-col space-y-8">
                        
                        <div className="flex space-x-8">
                            <ContactCard />
                        </div>

                        <Accordion 
                            type="multiple" 
                            value={openPanels} 
                            onValueChange={(value) => togglePanel(value)}
                            className="px-4"
                        >

                            <AccordionItem value="Timeline">
                                <AccordionTrigger className= "text-[#006C5B] dark:text-[#BCECDF] xs:text-md md:text-lg">Timeline</AccordionTrigger>
                                    <AccordionContent className="z-10">
                                        <ContactTimeline />
                                    </AccordionContent>
                            </AccordionItem>
                            <AccordionItem value="Groups">
                                <AccordionTrigger className= "text-[#006C5B] dark:text-[#BCECDF] xs:text-md md:text-lg">Groups</AccordionTrigger>
                                    <AccordionContent className="z-10">
                                        <ContactGroups />
                                    </AccordionContent>
                            </AccordionItem>
                            <AccordionItem value="Interests">
                                <AccordionTrigger className= "text-[#006C5B] dark:text-[#BCECDF] xs:text-md md:text-lg">Interests</AccordionTrigger>
                                    <AccordionContent className="z-10">
                                        <ContactInterests />
                                    </AccordionContent>
                            </AccordionItem>
                            <AccordionItem value="Informations">
                                <AccordionTrigger className= "text-[#006C5B] dark:text-[#BCECDF] xs:text-md md:text-lg">Informations</AccordionTrigger>
                                    <AccordionContent className="z-10">
                                        <ContactInfoTable />
                                    </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            )}
        </div>
     );
}
 
export default ContactDetails;