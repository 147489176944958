import { InputType, ReturnType } from "./types";

import { createSafeAction } from "../../../lib/create-safe-actions";
import { DeletePlanElement } from "./schema";

import axiosInstance from "../../api";
import { PlanElement } from "../../../api/career/interface-career";


const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting update card API call");
    try {
        //console.log('data update card API call :', data);
        const response = await axiosInstance.delete<PlanElement[]>(`/car-plan-element/deletePlanElement/${data.id}`);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Plan Element object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const deletePlanElement = createSafeAction(DeletePlanElement, handler);
