import axiosInstance from "../api";

export interface GetNotifications {
    id: string;
    content: string,
    created_at: Date,
    toShow: boolean;
    viewed : boolean,
    feature : string,
    accept: boolean,
    cancel: boolean,
    done: boolean,
    type: number;
    channel_id : string | undefined,
    con_action_id : string | undefined,
    tas_elt_id: string | undefined;
    car_elt_id: string | undefined;
}

export interface InfoNotif {
    channel_id?: string,
    type?: string,
}

export const fetchGetNotifications = async (): Promise<GetNotifications[]> => {

    const response = await axiosInstance.get<GetNotifications[]>('/glb-notifications/getNotifications');

    return response.data;
};

export const fetchViewedNotification = async (notificationId: string): Promise<GetNotifications[]> => {

    const response = await axiosInstance.patch<GetNotifications[]>(`/glb-notifications/viewed/${notificationId}`);

    return response.data;
};

export const fetchDeletetNotification = async (notificationId: string, data: {action:string}): Promise<string> => {

    const response = await axiosInstance.delete<string>(`/glb-notifications/delete/${notificationId}`, {data: data});
    /*if (data.action === "accepted" && infos.channel_id && infos.type == "channel invitation"){
        setter le channe le serveur pour redirection automatique
    }
    */

    return response.data;
};