// navigateUtility.ts
class NavigationService {
    private navigate: ((path: string) => void) | null = null;
  
    setNavigate(fn: (path: string) => void): void {
      this.navigate = fn;
    }
  
    getNavigate(): (path: string) => void {
      if (!this.navigate) {
        throw new Error("Navigate function is not set. Please ensure it is set correctly.");
      }
      return this.navigate;
    }
  }
  
  export default new NavigationService();