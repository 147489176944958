import { useTranslation } from "react-i18next";
import fr_img from '../../../../img/languages/france.png'
import en_img from '../../../../img/languages/united-states.png'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../../components/ui/card';
import { Button } from '../../../../components/ui/button';
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { fetchEditUserName, fetchGetComProfile } from "../../../../api/community/api-profile";
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar";
import { Moon, Sun, SunMoon } from "lucide-react";
import { Input } from "../../../../components/ui/input";
import { fetchEditFirstName, fetchEditLanguage, fetchEditLastName, fetchEditMail, fetchEditPassword, fetchEditTheme, fetchGetGlbProfile } from "../../../../api/globals/api-profile";
import { useGlbProfile, useProfileActions } from "../../../../hooks/general-profile";
import i18n from "../../../../components/multilingual/i18n";
import { useTheme } from "../../../../components/theme-provider/theme-provider";

// Utility function to map response language to i18next supported languages
const mapLanguage = (lang: string | undefined): string => {
    switch (lang) {
      case 'french': return 'fr';
      case 'english': return 'en';
      default: return 'en'; // default to English if undefined or 'default'
    }
  };

const ProfileGeneral = () => {
    const { t } = useTranslation();
    const glbProfile = useGlbProfile();
    const { setGlbProfile } = useProfileActions();
    const [username, setUsername] = useState<string>('');
    const [newUsername, setNewUsername] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [newEmail, setNewEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string | undefined>('');
    const [newFirstName, setNewFirstName] = useState<string | undefined>('');
    const [lastName, setLastName] = useState<string | undefined>('');
    const [newLastName, setNewLasName] = useState<string | undefined>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [language, setLanguage] = useState<string>('');
    const [newLanguage, setNewLanguage] = useState<string>('');
    const [themeProfile, setThemeProfile] = useState<string>('');
    const { theme, setTheme } = useTheme();
    //const { themeToggle, setThemeToggle } = useTheme();


    useEffect(() => {
        const fetchProfile = async () => {
            const response = await fetchGetGlbProfile();
            if (response) {
                //console.log("response:", response);
                setGlbProfile(response);
                if (response.language && i18n.language !== mapLanguage(response.language)) {
                    i18n.changeLanguage(mapLanguage(response.language));
                  }
                setUsername(response.user_name);
                setNewUsername(response.user_name);
                setEmail(response.email);
                setNewEmail(response.email);
                setFirstName(response?.first_name);
                setNewFirstName(response?.first_name);
                setLastName(response?.last_name);
                setNewLasName(response?.last_name);
                setLanguage(response.language);
                setNewLanguage(response.language);
                setThemeProfile(response.theme);
            }
            /*
            else
                //console.log("error, no response");
            */
        };
        fetchProfile();
    }, []);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewUsername(e.target.value);
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewFirstName(e.target.value);
    };

    const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmail(e.target.value);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewLasName(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };
    /*
    const handleLanguageChange = (lng:string) => {
        setNewLanguage(lng);
    };
    */

    const updateUsername = async () => {
        if (!newUsername.trim()) {
            toast.error('Username cannot be empty.', {
                className: 'text-warning_blue'
            });
            setError('Username cannot be empty.');
            return;
        }

        if (newUsername === glbProfile?.user_name) {
            toast.error('You already have this username.', {
                className: 'text-warning_blue'
            });
            setError('You already have this username.');
            return;
        }

        try {
            const response = await fetchEditUserName(newUsername);
            if (response === 'username already taken') {
                toast.error('Username already taken', {
                    className: 'text-warning_red'
                });
                setError('Username already taken');
                return;
            }
            setUsername(newUsername);
            setNewUsername('');
            if (glbProfile)
                setGlbProfile({ ...glbProfile, user_name: newUsername });
            toast.success(`${t("profile.general.username.toast")} ${newUsername}!`);
        } catch (error) {
            setError('Failed to update username');
        }
    };

    const updateMail = async () => {
        if (!newEmail.trim()) {
            toast.error('email cannot be empty.', {
                className: 'text-warning_blue'
            });
            setError('Email cannot be empty.');
            return;
        }

        if (newEmail === glbProfile?.email) {
            toast.error('You already have this email.', {
                className: 'text-warning_blue'
            });
            setError('You already have this email.');
            return;
        }

        try {
            const response = await fetchEditMail(newEmail);
            if (response === 'mail already associated to an account') {
                toast.error('Email already associated to an account', {
                    className: 'text-warning_red'
                });
                setError('Email already taken');
                return;
            }
            setEmail(newEmail);
            setNewEmail('');
            if (glbProfile)
                setGlbProfile({ ...glbProfile, email: newEmail });
            toast.success(`new mail: ${newEmail}!`);
        } catch (error) {
            setError('Failed to update mail');
        }
    };

    const updatePassword = async () => {
        try {
            const response = await fetchEditPassword({password: newPassword});
            if (response === 'password updated') {
                toast.success(`password updated!`);
            }}
            catch (error) {
            setError('Failed to update password');
        }
    }

    const updateFirstName = async () => {
        if (newFirstName && !newFirstName.trim()) {
            toast.error('Firstname cannot be empty.', {
                className: 'text-warning_blue'
            });
            setError('Firstname cannot be empty.');
            return;
        }

        if (newFirstName === glbProfile?.first_name) {
            toast.error('You already have this Firstname.', {
                className: 'text-warning_blue'
            });
            setError('You already have this Firstname.');
            return;
        }

        try {
            const response = newFirstName && await fetchEditFirstName(newFirstName);
            if (response === 'firstName updated') {
                setFirstName(newFirstName);
                setNewFirstName('');
                if (glbProfile)
                    setGlbProfile({ ...glbProfile, first_name: newFirstName });
                toast.success(`${t("profile.general.other.toast1")} : ${newFirstName}!`);
            }  
        } catch (error) {
            setError('Failed to update firstName');
        }
    };

    const updateLastName = async () => {
        if (newLastName && !newLastName.trim()) {
            toast.error('Last name cannot be empty.', {
                className: 'text-warning_blue'
            });
            setError('Last name cannot be empty.');
            return;
        }

        if (newLastName === glbProfile?.last_name) {
            toast.error('You already have this Last name.', {
                className: 'text-warning_blue'
            });
            setError('You already have this Last name.');
            return;
        }

        try {
            const response = newLastName && await fetchEditLastName(newLastName);
            if (response === 'Last name updated') {
                setLastName(newLastName);
                setNewLasName('');
                if (glbProfile)
                    setGlbProfile({ ...glbProfile, last_name: newLastName });
                toast.success(`${t("profile.general.other.toast2")} : ${newLastName}!`);
            }  
        } catch (error) {
            setError('Failed to update Last name');
        }
    };

    const updateLanguage = async (lng: string) => {
        try{
            const response = await fetchEditLanguage(lng);
            if ((glbProfile) && (response === 'language updated')) {
                setLanguage(lng);
                setNewLanguage('');
                setGlbProfile({...glbProfile, language:lng});
                i18n.changeLanguage(mapLanguage(lng)); // Change language in i18next globally
                toast.success(`language updated`);
            };
        } catch(error){
            setError('Failed to update language preference');
        }
    };

    const updateTheme = async (theme: string) => {
        try{
            const response = await fetchEditTheme(theme);
            if ((glbProfile) && (response === 'theme updated')) {
                setThemeProfile(theme);
                //setNewLanguage('');
                setGlbProfile({...glbProfile, theme:theme});
                setTheme(theme)
                toast.success(`theme updated`);
            };
        } catch(error){
            setError('Failed to update theme preference');
        }
    };

    return ( 
        <div className="profile-general p-5">
            <Card
                className="flex flex-col p-4 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mb-4 max-w-full justify-center"
            >
                <CardHeader>
                    <CardTitle className="text-lg md:text-2xl font-bold text-[#133867] dark:text-[#D1E7FF]">{t("profile.general.title")}</CardTitle>
                    <CardDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">{t("profile.general.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    {/* username */}
                    <div className="text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4">
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.general.username.title")}</h2>
                        <div className='flex items-center space-x-4 flex-col md:flex-row'>
                            <Input
                                type="text"
                                className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                                value={newUsername}
                                onChange={handleUsernameChange}
                                placeholder={username}
                                id="username"
                            />
                            <div className="pt-3 md:pt-0"/>
                            <Button
                                className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold px-4 rounded focus:outline-none focus:shadow-outline" 
                                onClick={updateUsername}
                            >
                                {t("profile.general.username.button")}
                            </Button>
                            {/*{error && <p className="error">{error}</p>}*/}
                        </div>
                    </div>
                    {/* mail */}
                    <div className='text-sm md:text-lg mail text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4 pt-5'>
                        <h2 className="font-semibold mb-3">{t("profile.general.mail.title")}</h2>
                        <div className='flex items-center space-x-4 flex-col md:flex-row'>
                            <Input
                                className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                                type="text"
                                value={newEmail}
                                onChange={handleMailChange}
                                placeholder={email}
                                id="mail"
                            />
                            <div className="pt-3 md:pt-0"/>
                            <Button
                                className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                onClick={updateMail}
                            >
                                {t("profile.general.mail.button")}
                            </Button>
                            {/*{error && <p className="error">{error}</p>}*/}
                        </div>
                    </div>
                    {/* password */}
                    <div className='text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4 pt-5'>
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.general.password.title")}</h2>
                        <div className='flex items-center space-x-4 flex-col md:flex-row'>
                            <Input
                                className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                                type="password"
                                minLength={8}
                                value={newPassword}
                                onChange={handlePasswordChange}
                                placeholder="........"
                                id="password"
                            />
                            <div className="pt-3 md:pt-0"/>
                            <Button
                                className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                onClick={updatePassword}
                            >
                                {t("profile.general.password.button")}
                            </Button>
                            {/*{error && <p className="error">{error}</p>}*/}
                        </div>
                    </div>
                    {/* language */}
                    <div className='text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4 justify-center pt-5'>
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.general.language.title")}</h2>
                        <div className='flex items-center space-x-4'>
                            <div 
                                //key={index} 
                                className={`cursor-pointer rounded-full p-1 ${language === 'french' ? 'ring-4 ring-warning_blue dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                onClick={() => updateLanguage("french")}
                                //onChange={() => handleLanguageChange("french")}
                                style={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Avatar
                                    className="rounded-full w-full h-full object-cover"
                                >
                                    <AvatarImage src={fr_img}/>
                                    <AvatarFallback>FR</AvatarFallback>
                                </Avatar>
                            </div>
                            <div 
                                //key={index} 
                                className={`cursor-pointer rounded-full p-1 ${language !== 'french' ? 'ring-4 ring-warning_blue dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                onClick={() => updateLanguage("english")}
                                //onChange={() => handleLanguageChange("english")}
                                style={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Avatar
                                    className="rounded-full w-full h-full object-cover"
                                >
                                    <AvatarImage src={en_img}/>
                                    <AvatarFallback>EN</AvatarFallback>
                                </Avatar>
                            </div>
                        </div>
                    </div>
                    {/* theme */}
                    <div className='text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4 justify-center pt-5'>
                        <h2 className="text-sm md:text-lg font-semibold mb-3">{t("profile.general.theme.title")}</h2>
                        <div className='flex items-center space-x-4'>
                            <div 
                                //key={index} 
                                className={`cursor-pointer rounded-full p-1 ${themeProfile === 'light' ? 'ring-4 ring-warning_blue dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                onClick={() => updateTheme("light")}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Avatar
                                    //key={index}
                                    className="rounded-full w-full h-full object-cover"
                                >
                                    <Sun />
                                </Avatar>
                            </div>
                            <div 
                                //key={index} 
                                className={`cursor-pointer rounded-full p-1 ${themeProfile === 'dark' ? 'ring-4 ring-warning_blue dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                onClick={() => updateTheme("dark")}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Avatar
                                    //key={index}
                                    className="rounded-full w-full h-full object-cover"
                                >
                                    <Moon />
                                </Avatar>
                            </div>
                            <div 
                                //key={index}
                                className={`cursor-pointer rounded-full p-1 ${themeProfile === 'system' ? 'ring-4 ring-warning_blue dark:ring-[#A8C44E] ring-opacity-100' : ''}`}
                                onClick={() => updateTheme("system")}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Avatar
                                    //key={index}
                                    className="rounded-full w-full h-full object-cover"
                                >
                                    <SunMoon />
                                </Avatar>
                            </div>
                        </div>
                    </div>
                    {/* first and last name */}
                    <div className='other pt-5'>
                        <h2 className="text-sm md:text-lg font-semibold text-[#133867] dark:text-[#D1E7FF] space-x-2 py-4">{t("profile.general.other.title")}</h2>
                    </div>
                    <div className='flex items-center space-x-4 pt-5 flex-col md:flex-row'>
                        <Input
                            className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                            type="text"
                            value={newFirstName}
                            onChange={handleFirstNameChange}
                            placeholder={firstName}
                            id="firstName"
                        />
                        <div className="pt-3 md:pt-0"/>
                        <Button
                            className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                            onClick={updateFirstName}
                        >
                            {t("profile.general.other.button1")}
                        </Button>
                        {/*{error && <p className="error">{error}</p>}*/}
                    </div>
                    <div className='flex items-center  space-x-4 pt-5 flex-col md:flex-row'>
                        <Input
                            className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642] flex-grow"
                            type="text"
                            value={newLastName}
                            onChange={handleLastNameChange}
                            placeholder={lastName}
                            id="lastName"
                        />
                        <div className="pt-3 md:pt-0"/>
                        <Button
                            className="text-xs md:text-sm bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                            onClick={updateLastName}
                        >
                            {t("profile.general.other.button2")}
                        </Button>
                        {/*{error && <p className="error">{error}</p>}*/}
                    </div>
                </CardContent>
            </Card>
        </div>
     );
}
 
export default ProfileGeneral;