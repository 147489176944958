import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { ContactCompany } from '../api/contacts/interface-contact';

export interface ContactTableElement {
    id: string;
    first_name: string;
    last_name: string;
    job_title?: string;
    company_name?: string;
    location?: string;
    email?: string;
    phone_number?: string;
    company?: ContactCompany;
    con_contacts_groups: ContactGroup[];
    con_contacts_interests: ContactInterest[];
    con_interactions: Interaction[];
}

export interface ContactGroup {
    con_groups: Group;
}

export interface Group {
    id: string;
    name: string;
}

export interface ContactInterest {
    con_interests: Interest;
}

export interface Interest {
    id: string;
    name: string;
}

export interface Interaction {
    id: string;
    title: string;
    date: string; // or Date if you want to use Date objects
}

interface ContactStore {

    contactTable: ContactTableElement[] | undefined;
    groups: Group[] | undefined;
    interests: Interest[] | undefined;

    actions: {
        initContactTable: (initialContacts: ContactTableElement[]) => void;
        getContactById: (contactId: string) => { contact: ContactTableElement } | undefined;
        getContactGroupsById: (contactId: string) => Group[] | undefined;
        getContactInterestsById: (contactId: string) => Interest[] | undefined;
        updateContactTable: (contact: ContactTableElement) => void;
        updateContactTableForUpload: (contacts: ContactTableElement[]) => void;
        updateContactGroups: (contactId: string, group: Group, actionType: 'add' | 'remove') => void;
        updateContactInterests: (contactId: string, group: Interest, actionType: 'add' | 'remove') => void;
        deleteContact: (contactId: string) => void;
        initGroups: (initialGroups: Group[]) => void;
        updateGroups: (group: Group) => void;
        deleteGroup: (groupId: string) => void;
        initInterests: (initialInterests: Interest[]) => void;
        updateInterests: (interest: Interest) => void;
        deleteInterest: (interestId: string) => void;
        getContactsWithSameCompany: (contactId: string) => ContactTableElement[] | undefined;
        getContactsWithSameGroup: (contactId: string) => ContactTableElement[] | undefined;
        getContactsWithSameInterest: (contactId: string) => ContactTableElement[] | undefined;
		//init: () => void;
		clear: () => void;
        clearContactStore: () => void;
	}
}
  

const contactStore = createStore<ContactStore>()(
    devtools(
        (set, get) => ({
            contactTable: undefined,
            groups: undefined,
            interests: undefined,

            actions: {
                initContactTable: (initialContacts: ContactTableElement[]) => {
                    set({
                        contactTable: initialContacts,
                    });
                },
                getContactById: (contactId: string) => {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact) {
                        return undefined; // or some suitable default value
                    }
                    return {
                        contact,
                    };
                },
                getContactGroupsById: (contactId: string) => {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact) {
                        return undefined; // or some suitable default value
                    }

                    //console.log("contact.con_contacts_groups: ", contact.con_contacts_groups);
                    const groups = contact.con_contacts_groups.map(cg => cg.con_groups);
                    //console.log("groups from the store: ", groups);
                    //const groups = contact.con_contacts_groups;
                    return groups;
                },
                getContactInterestsById: (contactId: string) => {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact) {
                        return undefined; // or some suitable default value
                    }
                    const interests = contact.con_contacts_interests.map(cg => cg.con_interests);
                    return interests;
                },
                updateContactTable: (contact: ContactTableElement) => {
                    const currentContacts = get().contactTable || [];
                    const existingContactIndex = currentContacts.findIndex(c => c.id === contact.id);
                
                    let newContactTable;
                    if (existingContactIndex >= 0) {
                        // Contact exists, update it
                        newContactTable = currentContacts.map((c, index) =>
                            index === existingContactIndex ? contact : c
                        );
                    } else {
                        // Contact does not exist, add it
                        newContactTable = [...currentContacts, contact];
                    }
                
                    set({
                        contactTable: newContactTable,
                    });
                },
                updateContactTableForUpload: (contacts: ContactTableElement[]) => {
                    const currentContacts = get().contactTable || [];

                    // Use a new array to avoid mutating the original state
                    let newContactTable = [...currentContacts];

                    contacts.forEach((contact) => {
                        const existingContactIndex = newContactTable.findIndex(c => c.id === contact.id);

                        if (existingContactIndex >= 0) {
                        // If contact exists, update it
                        newContactTable[existingContactIndex] = contact;
                        } else {
                        // If contact does not exist, add it
                        newContactTable.push(contact);
                        }
                    });
                    
                    set({
                        contactTable: newContactTable,
                    });
                },
                updateContactInterests: (contactId: string, interest: Interest, actionType: 'add' | 'remove') => {
                    const currentContacts = get().contactTable || [];
                    const contactIndex = currentContacts.findIndex(c => c.id === contactId);
            
                    if (contactIndex === -1) {
                        return; // Contact not found, you might handle this case as well
                    }
            
                    let updatedInterests = [...currentContacts[contactIndex].con_contacts_interests];
            
                    if (actionType === 'add') {
                        // Add group if not already present
                        if (!updatedInterests.some(cg => cg.con_interests.id === interest.id)) {
                            updatedInterests.push({ con_interests: interest });
                        }
                    } else {
                        // Remove group
                        updatedInterests = updatedInterests.filter(cg => cg.con_interests.id !== interest.id);
                    }
            
                    let updatedContact = { ...currentContacts[contactIndex], con_contacts_interests: updatedInterests };
                    let newContactTable = [...currentContacts];
                    newContactTable[contactIndex] = updatedContact;
            
                    set({ contactTable: newContactTable });
                },
                updateContactGroups: (contactId: string, group: Group, actionType: 'add' | 'remove') => {
                    const currentContacts = get().contactTable || [];
                    const contactIndex = currentContacts.findIndex(c => c.id === contactId);
            
                    if (contactIndex === -1) {
                        return; // Contact not found, you might handle this case as well
                    }
            
                    let updatedGroups = [...currentContacts[contactIndex].con_contacts_groups];
            
                    if (actionType === 'add') {
                        // Add group if not already present
                        if (!updatedGroups.some(cg => cg.con_groups.id === group.id)) {
                            updatedGroups.push({ con_groups: group });
                        }
                    } else {
                        // Remove group
                        updatedGroups = updatedGroups.filter(cg => cg.con_groups.id !== group.id);
                    }
            
                    let updatedContact = { ...currentContacts[contactIndex], con_contacts_groups: updatedGroups };
                    let newContactTable = [...currentContacts];
                    newContactTable[contactIndex] = updatedContact;
            
                    set({ contactTable: newContactTable });
                },
                deleteContact: (contactId: string) => {
                    const currentContacts = get().contactTable || [];
                    const newContactTable = currentContacts.filter(contact => contact.id !== contactId);
                    set({
                        contactTable: newContactTable,
                    });
                },
                // groups actions
                initGroups: (initialGroups: Group[]) => {
                    set({
                        groups: initialGroups,
                    });
                },
                updateGroups: (group: Group) => {
                    const currentGroups = get().groups || [];
                    const existingGroupIndex = currentGroups.findIndex(c => c.id === group.id);
                
                    let newGroups;
                    if (existingGroupIndex >= 0) {
                        // Group exists, update it
                        newGroups = currentGroups.map((c, index) =>
                            index === existingGroupIndex ? group : c
                        );
                    } else {
                        // Group does not exist, add it
                        newGroups = [...currentGroups, group];
                    }

                    set({
                        groups: newGroups,
                    });
                },
                deleteGroup: (groupId: string) => {
                    const currentgroups = get().groups || [];
                    const newGroups = currentgroups.filter(group => group.id !== groupId);
                    set({
                        groups: newGroups,
                    });
                },
                // interests actions
                initInterests: (initialInterests: Interest[]) => {
                    set({
                        interests: initialInterests,
                    });
                },
                updateInterests: (interest: Interest) => {
                    const currentInterests = get().interests || [];
                    const existingInterestIndex = currentInterests.findIndex(c => c.id === interest.id);
                
                    let newInterests;
                    if (existingInterestIndex >= 0) {
                        // Interest exists, update it
                        newInterests = currentInterests.map((c, index) =>
                            index === existingInterestIndex ? interest : c
                        );
                    } else {
                        // Interest does not exist, add it
                        newInterests = [...currentInterests, interest];
                    }

                    set({
                        interests: newInterests,
                    });
                },
                deleteInterest: (interestId: string) => {
                    const currentInterests = get().interests || [];
                    const newInterests = currentInterests.filter(interest => interest.id !== interestId);
                    set({
                        interests: newInterests,
                    });
                },
                getContactsWithSameCompany(contactId: string) {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact || !contact.company_name) {
                        return []; // or some suitable default value
                    }
                    return currentContacts.filter(c => c.company_name === contact.company_name && c.id !== contactId);
                },
                getContactsWithSameGroup(contactId: string) {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact || contact.con_contacts_groups.length === 0) {
                        return []; // or some suitable default value
                    }
                    return currentContacts.filter(c => c.con_contacts_groups.some(cg => cg.con_groups.id === contact.con_contacts_groups[0].con_groups.id && c.id !== contactId));
                },
                getContactsWithSameInterest(contactId: string) {
                    const currentContacts = get().contactTable || [];
                    const contact = currentContacts.find(contact => contact.id === contactId);
                    if (!contact || contact.con_contacts_interests.length === 0) {
                        return []; // or some suitable default value
                    }
                    return currentContacts.filter(c => c.con_contacts_interests.some(cg => cg.con_interests.id === contact.con_contacts_interests[0].con_interests.id && c.id !== contactId));
                },
                /*init: () => {
                    const { initContactTable, initGroups, initInterests } = get().actions;
                    initContactTable([]);
                    initGroups([]);
                    initInterests([]);
                },*/
                clear: () => {
                    set({
                        contactTable: undefined,
                        groups: undefined,
                        interests: undefined,
                    });
                },
                clearContactStore: () => {
                    set({
                        contactTable: undefined,
                        groups: undefined,
                        interests: undefined,
                    });
                },
            }
        }),
        {
            name: 'auth-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof contactStore, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof contactStore>) => state.actions;
const contactTableSelector = (state: ExtractState<typeof contactStore>) => state.contactTable;
const groupsSelector = (state: ExtractState<typeof contactStore>) => state.groups;
const interestsSelector = (state: ExtractState<typeof contactStore>) => state.interests;

// getters
export const getContactStoreActions = () => actionsSelector(contactStore.getState());
export const getContactTable = () => contactTableSelector(contactStore.getState());
export const getGroups = () => groupsSelector(contactStore.getState());
export const getInterests = () => interestsSelector(contactStore.getState());

function useContactStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(contactStore, selector, equalityFn);
}

// Hooks
export const useContactStoreActions = () => useContactStore(actionsSelector);
export const useContactTable = () => useContactStore(contactTableSelector);
export const useGroups = () => useContactStore(groupsSelector);
export const useInterests = () => useContactStore(interestsSelector);