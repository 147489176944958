import * as React from "react"
import { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "../../../../components/ui/card";
import { X,  AlertCircle, CircleCheck, ArrowRight  } from "lucide-react";
import CategoryTags from "./_comp_techwatch/category-tags";
import CategoryLists from "./_comp_techwatch/categories-list";
import { Topic } from "../../../../interfaces/techwatch/int-techwatch";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/ui/button";
import { useSelectedTab, useTechwatchStoreActions } from "../../../../hooks/techwatch-store";
import { fetchAddProposedSource, fetchAllSelectedTags } from "../../../../api/techwatch/api-tec-profile";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../../components/ui/input";
import { toast } from "sonner";

export interface SelectedCategory {
    categoryId: string;
    category: string;
};

export type SelectedCategoryState = Topic | undefined;

const ProfileTechwatch = () => {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState<SelectedCategoryState>(undefined);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [filter, setFilter] = useState("");
    const selectedTab = useSelectedTab();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const { resetFeeds, setSelectedTab } = useTechwatchStoreActions();
    const navigate = useNavigate();

    // Variables d'état pour les champs du formulaire
    const [newSourceName, setNewSourceName] = useState<string>("");
    const [newSourceUrl, setNewSourceUrl] = useState<string>("");
    const [newSourceTopic, setNewSourceTopic] = useState<string>("");
    const [newSourceTags, setNewSourceTags] = useState<string>("");

    // Validation d'erreur
    const [errors, setErrors] = useState({
        sourceName: "",
        sourceUrl: ""
    });

    const handleClearFilter = () => {
        setFilter(""); // Clear the filter input
    };

    const handleRefresh = () => {
        resetFeeds();
        setSelectedTab('Personalized');
        navigate("/Techwatch");

    };
    /*
    useEffect(() => {
        const fetchTags = async () => {
            try {
                const tags = await fetchAllSelectedTags();
                setSelectedTags(tags);
            } catch (error) {
                //console.error("Failed to fetch tags:", error);
            }
        };
        fetchTags();
    }, [selectedCategories, selectedTab]);
    */

    // Fonction de validation des champs
    const validateForm = () => {
        const errors: any = {};

        if (!newSourceName.trim()) {
            errors.sourceName = "Source name is required";
        }

        if (!newSourceUrl.trim()) {
            errors.sourceUrl = "Source URL is required";
        }

        return errors;
    };

    // Fonction pour ajouter une nouvelle source
    const handleAddSource = async () => {
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const newSource = {
                source_name: newSourceName,
                source_url: newSourceUrl,
                topic: newSourceTopic,
                tags: newSourceTags,
            };

            const response = await fetchAddProposedSource(newSource);
            if (response) {
                toast.error(t("profile.techwatch.proposedSource.toast.success"));
                setNewSourceName(""); // Reset input fields
                setNewSourceUrl("");
                setNewSourceTopic("");
                setNewSourceTags("");
                setErrors({ sourceName: "", sourceUrl: "" }); // Clear errors
            }
        } catch (error) {
            console.error("Failed to add the source:", error);
            toast.error(t("profile.techwatch.proposedSource.toast.error"));
        }
    };

    return (
        <div>
            <Card
                className="flex flex-col p-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mt-1 mb-4 max-w-full justify-center text-center"
            >
                <CardHeader>
                    <div className="flex flex-row items-start justify-between">
                        <div className="flex flex-col justify-start">
                            <CardTitle className="text-lg md:text-2xl font-bold text-start text-[#133867] dark:text-[#D1E7FF]">{t("profile.techwatch.title")}</CardTitle>
                            <CardDescription
                                className="text-gray-600 dark:text-[#B3B3B3] text-xs text-start italic mt-1 font-medium"
                            >
                                {t("profile.techwatch.description")}
                            </CardDescription>
                        </div>
                        {selectedCategories.length > 0 && (
                            <div className="flex justify-center">
                                <Button
                                    onClick={handleRefresh}
                                    //className="mt-4 bg-techwatch_complementary text-[#EBF1FA] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#925B28] dark:text-[#FFDEC3] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                    variant="default_simple"
                                    className="px-2 gap-x-1 text-md font-medium text-neutral_white-200 dark:text-[#D1E7FF] bg-techwatch_primary dark:bg-[#102642]"
                                >
                                    <ArrowRight />
                                    {t("profile.techwatch.refreshButtonLabel")}
                                </Button>
                            </div>
                        )}
                    </div>
                </CardHeader>
                <CardContent>
                
                    <div className="flex justify-center text-center text-[#133867] dark:text-[#D1E7FF] space-x-2 pt-4 pb-4">
                        <input
                            type="text"
                            placeholder={t("profile.techwatch.filterPlaceholder")}
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                            className="input border-transparent bg-[#DDEBFE] dark:bg-[#102642]"
                        />
                        {filter && (
                            <button onClick={handleClearFilter} className="btn btn-square btn-outline">
                                <X size={16} />
                            </button>
                        )}
                    </div>


                    {filter ? (
                        // Show filtered tags across all categories when there is a filter
                        <CategoryTags selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} filter={filter}/>
                    ) : selectedCategory ? (
                        // Show tags for a specific category when one is selected and no filter is applied
                        <CategoryTags selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} filter="" />
                    ) : (
                        // Show list of categories when no category is selected and no filter is applied
                        <CategoryLists selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} setSelectedCategories={setSelectedCategories} />
                    )}

                    <div className="flex justify-center mt-6">
                        {selectedCategories.length > 0 && (
                            <div className="flex justify-center">
                                <Button
                                    onClick={handleRefresh}
                                    //className="mt-4 bg-techwatch_complementary text-[#EBF1FA] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#925B28] dark:text-[#FFDEC3] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                    variant="default_simple"
                                    //className="px-2 gap-x-1 text-md font-medium text-[#133867] dark:text-[#D1E7FF] bg-[#DDEBFE] dark:bg-[#102642]"
                                    className="px-2 gap-x-1 text-md font-medium text-neutral_white-200 dark:text-[#D1E7FF] bg-techwatch_primary dark:bg-[#102642]"
                                >
                                    <ArrowRight />
                                    {t("profile.techwatch.refreshButtonLabel")}
                                </Button>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
            <Card className="flex flex-col p-4 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mb-4 max-w-full justify-center text-left">
                <CardHeader>
                    <CardTitle className="text-lg md:text-2xl font-bold text-[#133867] dark:text-[#D1E7FF]">{t("profile.techwatch.proposedSource.title")}</CardTitle>
                    <CardDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                    {t("profile.techwatch.proposedSource.description")}
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4"> {/* Ajoute des espaces entre les inputs */}
                    <div className="relative">
                        <Input
                            type="text"
                            className={`shadow border rounded max-w-sm py-2 px-3 bg-[#DDEBFE] dark:bg-[#102642] text-[#133867] dark:text-[#D1E7FF] focus:border-techwatch_primary dark:focus:border-dark_techwatch focus:outline-none focus:ring-0 ${errors.sourceName ? 'border-red-500' : ''}`}
                            value={newSourceName}
                            onChange={(e) => setNewSourceName(e.target.value)}
                            placeholder={t("profile.techwatch.proposedSource.input.sourceNamePlaceholder")}
                        />
                        {errors.sourceName && (
                            <div className="flex items-center space-x-1 mt-1">
                                <AlertCircle className="w-5 h-5 text-red-500" />
                                <p className="text-red-500 text-sm">{t("profile.techwatch.proposedSource.errors.sourceNameRequired")}</p>
                            </div>
                        )}
                    </div>
                    <div className="relative">
                        <Input
                            type="text"
                            className={`shadow border rounded max-w-sm py-2 px-3 bg-[#DDEBFE] dark:bg-[#102642] text-[#133867] dark:text-[#D1E7FF] focus:border-techwatch_primary dark:focus:border-dark_techwatch focus:outline-none focus:ring-0 ${errors.sourceUrl ? 'border-red-500' : ''}`}
                            value={newSourceUrl}
                            onChange={(e) => setNewSourceUrl(e.target.value)}
                            placeholder={t("profile.techwatch.proposedSource.input.sourceUrlPlaceholder")}
                        />
                        {errors.sourceUrl && (
                            <div className="flex items-center space-x-1 mt-1">
                                <AlertCircle className="w-5 h-5 text-red-500" />
                                <p className="text-red-500 text-sm">{t("profile.techwatch.proposedSource.errors.sourceUrlRequired")}</p>
                            </div>
                        )}
                    </div>
                    <Input
                        type="text"
                        className="shadow border rounded max-w-sm py-2 px-3 bg-[#DDEBFE] dark:bg-[#102642] text-[#133867] dark:text-[#D1E7FF] focus:border-techwatch_primary dark:focus:border-dark_techwatch focus:outline-none focus:ring-0"
                        value={newSourceTopic}
                        onChange={(e) => setNewSourceTopic(e.target.value)}
                        placeholder={t("profile.techwatch.proposedSource.input.sourceTopicPlaceholder")}
                    />
                    <Input
                        type="text"
                        className="shadow border rounded max-w-sm py-2 px-3 bg-[#DDEBFE] dark:bg-[#102642] text-[#133867] dark:text-[#D1E7FF] focus:border-techwatch_primary dark:focus:border-dark_techwatch focus:outline-none focus:ring-0"
                        value={newSourceTags}
                        onChange={(e) => setNewSourceTags(e.target.value)}
                        placeholder={t("profile.techwatch.proposedSource.input.sourceTagsPlaceholder")}
                    />
                    <Button
                        className="bg-[#DDEBFE] dark:bg-[#102642] text-[#133867] dark:text-[#D1E7FF] rounded-full group hover:bg-techwatch_primary hover:text-neutral_white-200 dark:hover:bg-dark_techwatch dark:hover:text-techwatch_primary"
                        onClick={handleAddSource}
                    >
                        {t("profile.techwatch.proposedSource.button")}
                    </Button>
                </CardContent>
            </Card>
        </div>
     );
}
 
export default ProfileTechwatch;