import { X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Group, getContactStoreActions, useGroups } from "../../../../../hooks/contact-store";
import { getContactsHookActions, useNewContact, useSelectedContact } from "../../../../../hooks/contact-hook";
import { fetchUserGroups, fetchAddGroupContact, fetchDeleteGroupContact, fetchaddMultipleGroupsContact } from "../../../../../api/contacts/api-ContactGroup";

import { useToast } from "../../../../../components/ui/use-toast";
import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group"
import { Button } from "../../../../../components/ui/button";
import ComboboxContacts from "../../../../../components/Portal/ComboboxContacts";

interface ContactsGroupsProps {
    incomingPage?: string; // Not in use as of now, to be used in the contact table to display "+" button instead of "Add group"
  }

//const ContactGroups: React.FC<ContactDetailsProps> = ({ newContact }) => {
const ContactGroups = () => {
    // initialize toast
    const { toast } = useToast();

//load data from the store
    //Retrieve selected contact from the store
    const selectedContact = useSelectedContact();
    //console.log("selected contact: ", selectedContact);

    // retrieve the step of the new contact if one
    const { setNewContact } = getContactsHookActions(); // to be deleted if not used
    const newContact = useNewContact();

    //load user groups
    const userGroups = useGroups();
    //load necessary functions for the groups from the store
    const { initGroups, updateGroups } = getContactStoreActions();
    //console.log("userGroups: ", userGroups);
    //load user groups from the database if they are not in the store
    if (!userGroups) {
        const getUserGroupsFromDB = async () => {
            try {
                const response = await fetchUserGroups();
                initGroups(response);
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        getUserGroupsFromDB();
    }
    
    //load contact groups
    const [contactGroups, setContactGroups] = useState<Group[]>([]);
    //load necessary functions for the contact groups from the store
    const { getContactGroupsById, updateContactGroups } = getContactStoreActions();

    // handle toggle for adding groups to a new contact
    // Initialize state
    const [selectedGroupsnewContact, setSelectedGroupsnewContact] = useState<string[]>([]);
    
    useEffect(() => {
        if (selectedContact) {
            const groups = getContactGroupsById(selectedContact);
            setContactGroups(groups ?? []);

            // Set the group IDs to the state for toggling groups in UI
            const groupIds = groups?.map(group => group.id) || [];
            setSelectedGroupsnewContact(groupIds);
        }
        // Suppressing the ESLint warning about missing dependency since filterSuggestedGroups doesn’t rely on any state or props that change over time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContact]);
    

    // load state variables for the UX
    //const [showInput, setShowInput] = useState(false); //To be deleted
    //const [inputValue, setInputValue] = useState(''); //To be deleted
    //const [suggestedGroups, setSuggestedGroups] = useState<Group[]>([]); //To be deleted
    const [filteredGroups, setFilteredGroups] = useState<Group[]>([]); // in use for combobox
    const containerRef = useRef<HTMLDivElement>(null); //TODO : remember what it does


// suggest groups logic
    // function to filter suggested groups
    const filterSuggestedGroups = () => {
        if (!userGroups || !contactGroups) {
            return [];
        }
    
        // Filter out groups that are already in contactGroups
        return userGroups.filter(userGroup => 
            !contactGroups.some(contactGroup => 
                contactGroup.id === userGroup.id
            )
        );
    };

    //update suggested groups list
    useEffect(() => {
        const newSuggestedGroups = filterSuggestedGroups();
        //setSuggestedGroups(newSuggestedGroups);
        setFilteredGroups(newSuggestedGroups);
        // Suppressing the ESLint warning about missing dependency since filterSuggestedGroups doesn’t rely on any state or props that change over time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroups, contactGroups]);

    //Group - input auto-complete
    /*
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if(e.target.value) {
            setFilteredGroups(
                suggestedGroups?.filter(group => group.name.toLowerCase().includes(e.target.value.toLowerCase())
                ) ?? []
            );
        } else {
            setFilteredGroups([]);
        }
    };
    */

    // Group - Checking for outside click
    /*
    useEffect(() => {
        // Checking for outside click
        function handleOutsideClick(event: MouseEvent) {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowInput(false);
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    */

//Groups adding / deleting logic
    
    //handlers
    // function addGroup()
    function addGroup(group: Group, contactId: string) {
        //add group to the contact
        const addGroupContactToDB = async () => {
            try {
                const response = await fetchAddGroupContact(contactId, group);
                //console.log("response: ", response);
                updateGroups(response.newGroup);
                updateContactGroups(contactId, response.newGroup, 'add');
                setContactGroups(getContactGroupsById(contactId) || []);
                if (newContact) {
                    setSelectedGroupsnewContact([...selectedGroupsnewContact, response.newGroup.id]);
                }
                //setShowInput(false);
                //setInputValue("");
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        addGroupContactToDB();


        return true;
    }
    // function removeGroup()
    function removeGroup(group: Group, contactId: string) {
        //remove group from the contact
        const deleteGroupContactToDB = async () => {
            try {
                const response = await fetchDeleteGroupContact(contactId, group.id);
                //console.log("response: ", response);
                updateGroups(group);
                updateContactGroups(contactId, group, 'remove');
                setContactGroups(getContactGroupsById(contactId) || []);

            } catch (err) {
                //console.log("error: ", err);
            }
        };
        deleteGroupContactToDB();
    }

    // function addGroups() - for contact creation
    function addMultipleGroups(groups: string[], contactId: string, type: string) {
        //add group to the contact
        const addMultipleGroupContactToDB = async () => {
            try {
                //console.log("groups to be sent: ", groups);
                const response = await fetchaddMultipleGroupsContact(contactId, groups);
                //console.log("response: ", response);

                if (response.newContactGroups) {
                    response.newContactGroups.map(group => {
                        updateContactGroups(contactId, group, 'add');
                        setContactGroups(getContactGroupsById(contactId) || []);
                        
                        if (type === "next") {
                            setNewContact("Interests");
                        } else if (type === "previous") {
                            setNewContact("Timeline");
                        };
                        toast({
                            description: `Groups added to the contact`,
                          });
                    });
                }
            } catch (err) {
                toast({
                    description: `Groups could not be added to the contact`,
                  });
            }
        };

        if (groups.length === 0) {
            if (type === "next") {
                setNewContact("Interests");
            } else if (type === "previous") {
                setNewContact("Timeline");
            };
            return true;
        } else {
            addMultipleGroupContactToDB();
            return true;
        }
    }

    return ( 
        <div className="px-4 xs:text-xs md:text-md">
                <div className="py-2">
                    {newContact ? (
                        <div>
                            <p>Select the groups</p>
                            
                            <ToggleGroup 
                                value={selectedGroupsnewContact} 
                                onValueChange={(value) => {setSelectedGroupsnewContact(value)}}
                                type="multiple"
                            >
                                    <div className="flex flex-wrap pt-2">
                                        {userGroups && userGroups.map((group: Group, index: number) => (
                                            <ToggleGroupItem 
                                                key={group.id} 
                                                value={group.id}
                                                variant="default"
                                                //className="flex items-center m-1 px-2 py-1 bg-contacts_secondary rounded-full group hover:bg-contacts_complementary hover:text-white checked:bg-contacts_primary"
                                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white data-[state=on]:bg-contacts_primary bg-contacts_secondary bg-opacity-50"
                                            >
                                                {group.name}
                                            </ToggleGroupItem>
                                        ))
                                        }
                                    </div>
                            </ToggleGroup>

                            <ComboboxContacts 
                                //showInput={showInput} 
                                nature="group" 
                                selectedContact={selectedContact} 
                                filteredValues={userGroups || []} 
                                addGroup={addGroup}
                                incomingPage="newContact"
                            />

                            <div className="flex justify-end gap-2 mt-2">
                                <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white" onClick={() => addMultipleGroups(selectedGroupsnewContact, selectedContact || "", "previous")}>Previous</Button>
                                <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white" onClick={() => addMultipleGroups(selectedGroupsnewContact, selectedContact || "", "next")}>Next</Button>
                            </div>
                        </div>

                    ) : (

                        <div>
                            <div className="flex flex-wrap"> 
                                {contactGroups && contactGroups.map((group: Group, index: number) => (
                                    <div key={group.id} className="flex items-center m-1 px-2 py-1 bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]">
                                        <p className="flex align-middle text-xs md:text-sm">{group.name}</p>
                                        <span 
                                            className="text-white cursor-pointer align-middle px-1" 
                                            onClick={() => removeGroup(group, selectedContact || "")}
                                        >
                                            <X size={16} />
                                        </span>
                                    </div>
                                ))
                                }
                            </div>

                            <ComboboxContacts 
                                //showInput={showInput} 
                                nature="group" 
                                selectedContact={selectedContact} 
                                filteredValues={filteredGroups} 
                                addGroup={addGroup} />
                        </div>
                    )}
                </div>
        </div>
     );
};
 
export default ContactGroups;