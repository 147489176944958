import { updateList } from "../../../api/tasks/update-list";
import { FormInput } from "../../../components/form/form-input";
import { useAction } from "../../../hooks/use-action";
import { List } from "../../../interfaces/tasks/int-list";
import { useState, useRef, ElementRef } from "react";
import { toast } from "sonner";
import { useEventListener } from "usehooks-ts";
import { ListOptions } from "./list-options";
import { getSelectedBoardElement, useTaskStoreActions } from "../../../hooks/tasks-store";
import { ListWithCards } from "../../../interfaces/tasks/int-types";
import { useTranslation } from "react-i18next";
import { Board } from "../../../interfaces/tasks/int-board";


interface ListHeaderProps {
    data: List;
    onAddCard: () => void;
}

export const ListHeader = ({
    data,
    onAddCard,
}: ListHeaderProps) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
  
    const formRef = useRef<ElementRef<"form">>(null);
    const inputRef = useRef<ElementRef<"input">>(null);

    const enableEditing = () => {
        setIsEditing(true);
        setTimeout(() => { //to be replaced in a prod env for perf if any issues
          inputRef.current?.focus();
          inputRef.current?.select(); //make sure we really need both
        });
      };
    
      const disableEditing = () => {
        setIsEditing(false);
      };

      const { updateStoredList, updateOneBoard } = useTaskStoreActions();

      const { execute } = useAction(updateList, {
        onSuccess: (data) => {
            updateStoredList(data as ListWithCards);
            
            // Retrieve the selected board element containing this list
            const selectedBoardElement = getSelectedBoardElement();

            if (selectedBoardElement && selectedBoardElement.id) {
                // Ensure the board is valid and contains the updated list
                const updatedBoard: Board = {
                    ...selectedBoardElement,
                    lists: selectedBoardElement.lists?.map((list) =>
                        list.id === data.id ? { ...list, title: data.title } : list
                    ) || [],  // Update the list name in the board
                };

                // Use the updateOneBoard function to update the board in the store
                updateOneBoard(updatedBoard);

                toast.success(`Renamed to "${data.title}"`);
            } else {
                toast.error("Board not found or is invalid");
            }

            disableEditing();
        },
        onError: (error) => {
            toast.error(error);
        }
      });

      //const handleSubmit = (formData: FormData) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        const id = formData.get("id") as string;
        const board_id = formData.get("boardId") as string;

        if (title === data.title){
            return disableEditing();
        }

        execute({
            title,
            id,
            board_id,
        });

      }

      const onBlur = () => {
        formRef.current?.requestSubmit();
      }

      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          formRef.current?.requestSubmit();
        }
      };
    
    useEventListener("keydown", onKeyDown);

    return(
        <div className="pt-2 px-2 text-sm font-semibold flex justify-between items-start gap-x-2">
            {isEditing ? (
                <form 
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className="flex-1 px-[2px]"
                >
                    <input hidden id="id" name="id" value={data.id} />
                    <input hidden id="boardId" name="boardId" value={data.board_id} />
                    <FormInput 
                        ref={inputRef}
                        onBlur={onBlur}
                        id="title"
                        placeholder={t("tasks.list.placeholder")}
                        defaultValue={data.title}
                        className="text-sm px-[7px] py-1 h-7 font-medium border-transparent hover:border-input focus:border-input transition truncate bg-transparent focus:bg-white"
                    />
                    <button type="submit" hidden />
                </form>
            ) : (
                <div 
                    onClick={enableEditing}
                    className="w-full text-sm px-2.5 py-1 h-7 font-medium border-transparent"
                >
                    {data.title}
                </div>
            )}
            <ListOptions 
                onAddCard={onAddCard}
                data={data}
            />
        </div>
    );
};