import { useTranslation } from "react-i18next";

export const Statistics = () => {
    const { t } = useTranslation();

    interface statsProps {
      quantity: string;
      description: string;
    }
  
    const stats: statsProps[] = [
      {
        quantity:  t("landing.about.metric1_quantity"),
        description: t("landing.about.metric1_description"),
      },
      {
        quantity:  t("landing.about.metric2_quantity"),
        description: t("landing.about.metric2_description"),
      },
      {
        quantity:  t("landing.about.metric3_quantity"),
        description: t("landing.about.metric3_description"),
      },
      {
        quantity:  t("landing.about.metric4_quantity"),
        description: t("landing.about.metric4_description"),
      },
    ];
  
    return (
      <section id="statistics">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map(({ quantity, description }: statsProps) => (
            <div
              key={description}
              className="space-y-2 text-center"
            >
              <h2 className="text-xl sm:text-2xl font-bold ">{quantity}</h2>
              <p className="text-lg text-muted-foreground">{description}</p>
            </div>
          ))}
        </div>
      </section>
    );
  };
  