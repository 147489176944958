import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom"; // Import hooks to work with query params

import { useBoards, useLists, useSelectedBoard, useSelectedBoardElement, useTasks, useTaskStoreActions } from "../../hooks/tasks-store";

import { fetchBoardListsWithCards, fetchBoardOverviewListsWithCards, fetchBoardOverviewTasksAll, fetchUserBoards } from "../../api/tasks/api-get-elements";

import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area"

import { ModalProvider } from "./_components/card-modal/modal-provider";
import { ListContainer } from "./_components/list-container";
import { BoardNavbar } from "./_components/board-navbar";

import { useColumns } from "./_components/_components_table/tasks-table-columns";
import { DataTable } from "./_components/_components_table/tasks-table-data";
import SkeletonRow from "./_components/_components_table/SkeletonRow";

import { SidebarGeneral } from "../../components/Sidebar-general";
import { set } from "lodash";
import { CardWithList, ListWithCards } from "../../interfaces/tasks/int-types";
import { List } from "../../interfaces/tasks/int-list";
import { cn } from "../../lib/utils";
import Footer from "../../components/Footer";
                
export const transformListsToCardsWithList = (lists: ListWithCards[]): CardWithList[] => {
    const cardsWithList: CardWithList[] = [];

    lists.forEach((list) => {
        const listForCard: List = {
            id: list.id,
            title: list.title,
            description: list.description,
            order: list.order,
            board_id: list.board_id,
            created_at: list.created_at,
            updated_at: list.updated_at
        };

        // For each card in the list, create a CardWithList object
        list.cards.forEach((card) => {
            const cardWithList: CardWithList = {
                ...card,
                list: listForCard
            };
            cardsWithList.push(cardWithList);
        });
    });

    return cardsWithList;
};

const Tasks = () => {

    const { initBoards, setSelectedBoard, setLists, setSelectedBoardElement, getTasks, setTasks, getSelectedBoardElement, getSelectedBoard } = useTaskStoreActions();

    const selectedBoard = useSelectedBoard();
    const selectedBoardElement = useSelectedBoardElement();

    const [searchParams] = useSearchParams(); // For reading query params
    const navigate = useNavigate(); // For navigation and updating URL

    useEffect(() => {
        const getBoards = async () => {
            // fetch boards
            const response = await fetchUserBoards();

            if (response) {
                // Get board ID from query params
                const boardIdFromUrl = searchParams.get("board") || undefined;

                await initBoards(response, boardIdFromUrl);

                if (boardIdFromUrl !== selectedBoard) {
                    //navigate(`?board=${selectedBoard}`, { replace: true });
                    navigate('', { replace: true });
                }
            }
        };

        getBoards();
    }, []);

    // fetch lists and cards of the selected board
    useEffect(() => {
        if (!selectedBoardElement) { // && !selectedBoardElement) {
            return;
        }
        
        const retrieveData = async () => {
            if (selectedBoardElement.title === "Overview") {
                if (selectedBoardElement.display_mode === "TABLE") {
                    const response = await fetchBoardOverviewTasksAll();
                    setTasks(response);
                    setLists(undefined); // Clear lists when switching to table mode
                } else if (selectedBoardElement.display_mode === "LIST") {
                    const response = await fetchBoardOverviewListsWithCards(selectedBoardElement.id);
                    setLists(response);
                    setTasks(undefined); // Clear tasks when switching to list mode
                }
            } else {
                if (selectedBoardElement.display_mode === "TABLE") {
                    const response = await fetchBoardListsWithCards(selectedBoardElement.id);
                    const cardsWithLists = transformListsToCardsWithList(response);
                    setTasks(cardsWithLists);
                    setLists(undefined); // Clear lists when switching to table mode
                } else if (selectedBoardElement.display_mode === "LIST") {
                    const response = await fetchBoardListsWithCards(selectedBoardElement.id);
                    setLists(response);
                    setTasks(undefined); // Clear tasks when switching to list mode
                }
            }
        };
        
       retrieveData();
        
    }, [selectedBoard, selectedBoardElement, setTasks, setLists]);

    // get columns for the table
    const columns = useColumns();

    const lists = useLists();
    const tasks = useTasks();

    return ( 
        <div className="flex flex-grow w-full h-full bg-no-repeat bg-cover bg-center">
            <div 
                className="hidden md:flex flex-col w-2/12 h-full px-1 py-1 dark:bg-[#1E1F22]"
            >
                <SidebarGeneral /> 
            </div>
            <div className="flex-grow flex flex-col h-full w-10/12 max-h-screen">
            {selectedBoard && selectedBoardElement && (lists || tasks) && (
                <div className="flex-grow flex flex-col h-full w-full">
                    <div className="flex-shrink-0 w-full h-14">
                        <BoardNavbar data={selectedBoardElement} />
                    </div>
                    <div 
                        className={cn("flex-grow w-full h-full pt-2",
                            "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                            //"overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                        )}
                    >
                        {(tasks && selectedBoardElement.display_mode === "TABLE") ? (
                            <div className="container mx-auto py-1 dark:bg-[#1E1F24]">
                                {!tasks ? (
                                    <div className="mt-8">
                                        <SkeletonRow columnsCount={columns.length} />
                                        <SkeletonRow columnsCount={columns.length} />
                                        <SkeletonRow columnsCount={columns.length} />
                                    </div>
                                ) : (
                                    <div className="z-0">
                                        <DataTable columns={columns} data={tasks} />
                                    </div>
                                )}
                            </div>
                        ) : (lists && selectedBoardElement.display_mode === "LIST") ? (
                            <ListContainer 
                                boardId = {selectedBoard}
                                data = {lists}
                            />
                        ) : (
                            <div className="mt-8">
                                <SkeletonRow columnsCount={columns.length} />
                                <SkeletonRow columnsCount={columns.length} />
                                <SkeletonRow columnsCount={columns.length} />
                            </div>
                        )}
                        
                        {/* Footer */}
                        <Footer />
                    </div>
                </div>
                )}
            </div>
            <ModalProvider />
        </div>
     );
}
 
export default Tasks;