import React from 'react';
import img_tasks from '../../../../img/landing/tasks.svg';
import img_custom from '../../../../img/landing/tasks/Organizing projects-bro.svg';
import img_integration from '../../../../img/landing/tasks/Software integration-bro.svg';
import img_notif from '../../../../img/landing/tasks/Push notifications-bro.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

const HelpTasksModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Tasks";

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-tasks_primary dark:text-[#FFA06A] bg-[#F8F5F5] dark:bg-[#212121] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-3xl text-center font-bold">
            Tasks
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.landingTasks.tagline")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
        <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-2">
                <div className="container mx-auto px-4">
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_tasks} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title1")}</h2>
                                <p className="mt-2 text-sm text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc1")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F8EEEA] dark:bg-[#1B130F] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_custom} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title2")}</h2>
                                <p className="mt-2 text-sm text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc2")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_integration} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title3")}</h2>
                                <p className="mt-2 text-sm text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc3")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F8EEEA] dark:bg-[#1B130F] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_notif} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-[#DD691D] dark:text-dark_tasks">{t("landing.landingTasks.title4")}</h2>
                                <p className="mt-2 text-sm text-[#54301E] dark:text-[#FFDECD]">
                                    {t("landing.landingTasks.desc4")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
        
    );
}

export default HelpTasksModal;
