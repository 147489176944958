import { useEffect, useState } from "react";
import { useToast } from "../../../../../components/ui/use-toast";

import { Copy, Pencil, Save } from 'lucide-react';
import { fetchAddContact, fetchUpdateContactData } from "../../../../../api/contacts/api-getContactData";
import { fetchCompanies } from "../../../../../api/contacts/opportunities/api-get-opportunities";

import { useCompanies, useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { ContactGroup, ContactInterest, ContactTableElement, Interaction, getContactStoreActions } from "../../../../../hooks/contact-store";
import { getContactsHookActions, useNewContact, useSelectedContact } from "../../../../../hooks/contact-hook";

import { cn } from "../../../../../lib/utils";
import { Button } from "../../../../../components/ui/button";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../../components/ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../../../../../components/ui/command";
import { Badge } from "../../../../../components/ui/badge";

import debounce from 'lodash.debounce';

interface ContactCardProps {
    contactXsId?: string;
    handleOpenSheetFunction?: (newContactTrigger?: boolean, contactId?: string) => void;
}

const ContactCard: React.FC<ContactCardProps> = ({ contactXsId, handleOpenSheetFunction }) => {
//const ContactCard = () => {
    //initialize toast
    const { toast } = useToast();

    // State to manage edit mode
    const [editMode, setEditMode] = useState(false);
    const [contact, setContact] = useState<ContactTableElement>({} as ContactTableElement);
    const [editableContact, setEditableContact] = useState<Partial<ContactTableElement>>({} as Partial<ContactTableElement>);
    
    // get store actions
    const { getContactById, updateContactTable } = getContactStoreActions();
    //Retrieve selected contact from the store
    const selectedContact = useSelectedContact();
    // retrieve function to update selected contact
    const {setSelectedContact} = getContactsHookActions();
    //console.log("selected contact: ", selectedContact);

    // retrieve the step of the new contact if one
    const { setNewContact } = getContactsHookActions();
    const newContact = useNewContact();
    

    //Retrieve selected contact Data from the store
    useEffect(() => {
        const fetchContact = async (id: string) => {
            const contactDataFromStore = getContactById(id);
            if (contactDataFromStore) {
                setContact(contactDataFromStore.contact);
                setEditableContact({
                    id: contactDataFromStore.contact.id,
                    first_name: contactDataFromStore.contact.first_name,
                    last_name: contactDataFromStore.contact.last_name,
                    job_title: contactDataFromStore.contact.job_title,
                    company_name: contactDataFromStore.contact.company_name,
                    location: contactDataFromStore.contact.location,
                    email: contactDataFromStore.contact.email,
                    phone_number: contactDataFromStore.contact.phone_number,
                    company: contactDataFromStore.contact.company,
                });
            }
        };

        if (contactXsId) {
            fetchContact(contactXsId);
        } else if (selectedContact) {
            fetchContact(selectedContact);
        }
        else if (newContact === "contactCard") {
            setEditableContact({
                first_name: '',
                last_name: '',
                job_title: '',
                company_name: '',
                location: '',
                email: '',
                phone_number: '',
                company: {id: '', name: ''},
            });
            setEditMode(true);
        }
        
        // Suppressing the ESLint warning about missing dependency since filterSuggestedGroups doesn’t rely on any state or props that change over time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactXsId, selectedContact, newContact]);

    // ---------------------------------------------------
    // Company management
    // ---------------------------------------------------

    // Load companies
    const companies = useCompanies();
    const { initCompanies } = useOpportunityStoreActions();
    useEffect(() => {
        const getCompaniesList = async () => {
            try {
                const response = await fetchCompanies();
                if (response) {
                    initCompanies(response);
                }
            } catch (error) {
                //console.log("companies error : ", error);
            }
        };
        if (!companies || companies.length === 0) {
            getCompaniesList();
        }
    }, []);

    // Handle Company popover
    const [openCompanyPopover, setOpenCompanyPopover] = useState(false);
    // search input company
    // debouncer for the search input
    const [inputCompanyValue, setInputCompanyValue] = useState("")
    const handleCompanyInputChange = debounce((newInputValue) => {
        setInputCompanyValue(newInputValue);
      }, 300);
    
    const handleCompanyChange = (company_id: string) => {
        if (company_id === "newElement") {
            setEditableContact((prevContact) => ({
                ...prevContact,
                company: {
                    name: companies?.find((company) => company.id === company_id)?.name ?? inputCompanyValue,
                },
            }));
        } else {
            setEditableContact((prevContact) => ({
                ...prevContact,
                company: {
                    id: company_id,
                    name: companies?.find((company) => company.id === company_id)?.name ?? inputCompanyValue,
                }, 
            }));
        }
        
        setOpenCompanyPopover(false);
    };

    // for all the other fields 
    // Function to handle the input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditableContact({ ...editableContact, [e.target.name]: e.target.value });
    };

    // Function to handle saving the edited contact
    const handleSave = async () => {
        // Here you would send the updated contact information to your server
        //console.log('Saving contact:', editableContact);
        if (contactXsId || selectedContact) {
            setEditMode(false);
            try {
                const id = contactXsId || selectedContact;
                if (id) {
                    const response = await fetchUpdateContactData(id, editableContact);
                    const updatedContact = { ...contact, ...editableContact };
                    updateContactTable(updatedContact);
                    setContact(updatedContact);
                    //console.log("test response update contact : " + response)
                    toast({
                        description: `Contact updated`,
                    });
                }
                else {
                    throw new Error("Invalid contact ID");
                }
                
            } catch (err) {
                //console.log("Can not update contact : " + err)
                toast({
                    description: `Failed to update contact`,
                  });
            }
        } else if (newContact) {
            try {
                const response = await fetchAddContact(editableContact);
                //console.log("test response add contact : ", response.newContact);
                // add contact to the store
                if (response.newContact.id === undefined) {
                    throw new Error("Contact ID is undefined");
                }
                const updatedContact: ContactTableElement = { 
                    id: response.newContact.id as string,
                    first_name: response.newContact.first_name as string,
                    last_name: response.newContact.last_name as string,
                    job_title: response.newContact.job_title ?? '',  // Providing a default value if undefined
                    company_name: response.newContact.company_name ?? '',  // Providing a default value if undefined
                    location: response.newContact.location ?? '',  // Providing a default value if undefined
                    email: response.newContact.email ?? '',  // Providing a default value if undefined
                    phone_number: response.newContact.phone_number ?? '',  // Providing a default value if undefined
                    company: response.newContact.company ?? undefined,// {id: '', name: ''},
                    con_contacts_groups: [] as ContactGroup[],
                    con_contacts_interests: [] as ContactInterest[],
                    con_interactions: [] as Interaction[],
                 };
                //console.log("test updated contact : ", updatedContact);
                updateContactTable(updatedContact);
                setContact(updatedContact);
                setEditMode(false);
                setSelectedContact(updatedContact.id);
                setNewContact("Timeline");
                toast({
                    description: `Contact added`,
                  });
                } catch (err) {
                    //console.log("Can not add contact : " + err)
                    toast({
                        description: `Failed to add contact`,
                      });
                }
        }
    };

    const inputStyle = "text-base bg-transparent border-b-2 border-gray-300 p-1 m-1 rounded focus:border-blue-500 outline-none";

    //Function copyToClipBoard to store the email or phone number in clipboard
    
    async function copyTextToClipboard(event: React.MouseEvent, type: string, text?: string) {
        event.stopPropagation();

        if (!text) { 
            toast({
                description: `Can not copy to clipboard, ${type} is missing in the contact details`,
            });
        }
        else {
            if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
                toast({
                    description: `${type} copied to clipboard`,
                });
            } catch (err) {
                //console.error('Failed to copy: ', err);
            }
            } else {
            // Fallback for older browsers
                try {
                    const textarea = document.createElement('textarea');
                    textarea.value = text;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textarea);
                    toast({
                        description: `${type} copied to clipboard`,
                    });
                } catch (err) {
                    //console.error('Failed to copy: ', err);
                    toast({
                        description: `Fail to copy ${type} in the clipboard`,
                    });
                }
            }
        }
      };

      const handleDetailedCardXsMode = (contactXsIdToEdit?: string) => {
        if (contactXsId && handleOpenSheetFunction) {
            handleOpenSheetFunction(false, contactXsId);
        } else {
            //setEditMode(true);
        }
      };

      //console.log("ContactCard");

    return ( 
        <div 
            className={cn(
                "p-4",
                contactXsId ? "" : "mt-4",
            )}
        >
            <div className="flex items-center space-x-4">
                <div className="hidden sm:flex bg-[#D4E8E3] dark:bg-[#22453D] p-4 w-24 h-24 rounded-full items-center justify-center">
                    <p className="text-contacts_primary dark:text-[#78CDB9] text-4xl font-bold text-center">
                        {editableContact.first_name?.charAt(0)}
                        {editableContact.last_name?.charAt(0)}
                    </p>
                </div>
                <div className="flex-1 text-gray-600 xs:text-xs md:text-md">
                    {editMode ? (
                        <div>
                            <input
                                className={`${inputStyle} font-bold`}
                                value={editableContact.first_name}
                                name="first_name"
                                placeholder="First Name"
                                onChange={handleInputChange}
                            />
                            <input
                                className={`${inputStyle} font-bold`}
                                value={editableContact.last_name}
                                name="last_name"
                                placeholder="Last Name"
                                onChange={handleInputChange}
                            />
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.job_title}
                                name="job_title"
                                placeholder="Job Title"
                                onChange={handleInputChange}
                            /> @{" "}
                            <span
                                // company name
                                className="flex flex-row w-full justify-start items-center gap-x-1"
                            >
                                <Popover open={openCompanyPopover} onOpenChange={setOpenCompanyPopover} >
                                    <PopoverTrigger asChild>
                                        <button>
                                            <Badge
                                                variant="contacts"
                                                className={cn("text-xs md:text-sm cursor-pointer whitespace-nowrap",
                                                    editableContact.company?.name ? "bg-transparent border-none italic font-medium" : 
                                                    ""
                                                )}
                                            >
                                                {editableContact.company?.name ? editableContact.company?.name : 'choose a company'}
                                            </Badge>
                                        </button>
                                    </PopoverTrigger>
                                    <PopoverContent 
                                        className="p-2"
                                    >
                                        <Command>
                                            <CommandInput
                                                //placeholder={incomingPage === "newContact" ? `Type new ${nature}...` : "Search value..." }
                                                onValueChange={(input) => handleCompanyInputChange(input)}  // Capture the input value
                                                />
                                            <CommandList className="max-h-[150px] overflow-x-hidden overflow-y-auto" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                <CommandEmpty>
                                                    <div
                                                        key='new'
                                                        onClick={() => handleCompanyChange("newElement")}
                                                        className="z-60 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-sm px-2 py-1 text-sm cursor-pointer bg-accent-1 dark:bg-accent-8"  // Ensure it's clickable and has high z-index
                                                    >
                                                        Create company "{inputCompanyValue}"
                                                    </div>
                                                </CommandEmpty>
                                                <CommandGroup>
                                                    {companies?.map((elt) => (
                                                    <CommandItem
                                                        key={elt.id}
                                                        value={elt.id}
                                                        onSelect={(currentValue) => {
                                                            handleCompanyChange(currentValue)
                                                        }}
                                                        className=" flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none z-1000 hover:bg-gray-100 dark:hover:bg-gray-800"  // Ensure it's clickable and has high z-index
                                                        style={{ pointerEvents: 'auto', zIndex: 1000 }}  // Debugging inline style
                                                    >
                                                        {elt.name}
                                                    </CommandItem>
                                                    ))}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                            </span>
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.company_name}
                                name="company_name"
                                placeholder="Company Name"
                                onChange={handleInputChange}
                            />
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.location}
                                name="location"
                                placeholder="Location"
                                onChange={handleInputChange}
                            />
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.email}
                                name="email"
                                placeholder="Email"
                                onChange={handleInputChange}
                            />
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.phone_number}
                                name="phone_number"
                                placeholder="Phone Number"
                                onChange={handleInputChange}
                            />
                            <Button 
                                className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base mt-2 p-1 m-1 " 
                                onClick={handleSave}
                            >
                                {newContact ? "Create contact" : "Update contact"}
                            </Button>
                        </div>
                    ) : (
                        <div className="flex flex-col h-full w-full text-[#1A3C34] dark:text-[#BCECDF]" onClick={() => handleDetailedCardXsMode()}>
                            <div className="flex justify-start items-center"> 
                                <h1 className="font-bold text-base md:text-xl text-contacts_primary">
                                    {contact.first_name} {contact.last_name}
                                </h1>
                                {selectedContact && (
                                    <button className="bg-contacts_primary ml-4 hover:bg-contacts_secondary text-white font-bold py-1 px-2 rounded" onClick={() => setEditMode(true)}>
                                        <Pencil size={16}/>
                                    </button>
                                )}
                            </div>
                            <div className="flex flex-col w-full h-full text-sm md:text-base">
                                <div>{contact.job_title} {contact.company?.name && `@${contact.company?.name}`}</div>
                                <div>{contact.location}</div>
                                <div className="flex flex-col md:flex-row gap-x-1">
                                    <div className="mr-2 flex flex-row gap-x-1 cursor-pointer items-center" onClick={(event) => copyTextToClipboard(event, "email", contact.email)}>
                                        <span className="text-sm md:text-base">{contact.email}</span>
                                        {contact.email && (<Copy className="h-3 w-3 md:h-3 md:w-3" />)}
                                    </div>
                                    <div className="hidden md:flex">- </div>
                                    <div className="md:ml-2 flex flex-row gap-x-1 cursor-pointer items-center" onClick={(event) => copyTextToClipboard(event, "phone number", contact.phone_number)}>
                                        <span className="text-sm md:text-base">{contact.phone_number}</span>
                                        {contact.phone_number && (<Copy className="h-3 w-3 md:h-3 md:w-3" />)}    
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>  
        </div>
     );
};
 
export default ContactCard;