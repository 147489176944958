import { useCallback, useEffect, useMemo, useState } from "react";
import { getContactsHookActions, useSelectedContact } from "../../../../../hooks/contact-hook";
import { ContactTableElement, useContactStoreActions } from "../../../../../hooks/contact-store";

interface RelatedContactsProps {
    type?: string;
}

const RelatedContacts: React.FC<RelatedContactsProps> = ({ type }) => {

    const selectedContact = useSelectedContact();
    
    const { setSelectedContact, setInitialSelectedContact, setNewContact } = getContactsHookActions();
    const { getContactsWithSameCompany, getContactsWithSameGroup, getContactsWithSameInterest } = useContactStoreActions();

    //const [relatedContacts, setRelatedContacts] = useState<ContactTableElement[]>([]);
    
    const [companyContacts, setCompanyContacts] = useState<ContactTableElement[]>([]);
    const [groupContacts, setGroupContacts] = useState<ContactTableElement[]>([]);
    const [interestContacts, setInterestContacts] = useState<ContactTableElement[]>([]);
    
    const fetchCompanyContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameCompany(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameCompany]);

    const fetchGroupContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameGroup(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameGroup]);

    const fetchInterestContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameInterest(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameInterest]);

    useEffect(() => {
        if (selectedContact) {
            const companyRelated = fetchCompanyContacts();
            const groupRelated = fetchGroupContacts();
            const interestRelated = fetchInterestContacts();

            // Only update state when the data changes
            if (companyRelated && (JSON.stringify(companyRelated) !== JSON.stringify(companyContacts))) {
                setCompanyContacts(companyRelated);
            }

            if (groupRelated && (JSON.stringify(groupRelated) !== JSON.stringify(groupContacts))) {
                setGroupContacts(groupRelated);
            }

            if (interestRelated && (JSON.stringify(interestRelated) !== JSON.stringify(interestContacts))) {
                setInterestContacts(interestRelated);
            }
        }

        return () => {
            setCompanyContacts([]);
            setGroupContacts([]);
            setInterestContacts([]);
        };
    }, [selectedContact]); //, fetchCompanyContacts, fetchGroupContacts, fetchInterestContacts selectedContact

    const handleClick = (contactId: string) => {
        //console.log("Related contact clicked");
        //console.log("contactId: ", contactId);
        setInitialSelectedContact(selectedContact);
        setSelectedContact(contactId);
        setNewContact(undefined);
    };

    //console.log("companyContacts: ", companyContacts.length);
    //console.log("groupContacts: ", groupContacts.length);
    //console.log("interestContacts: ", interestContacts.length);

    return ( 
        <div className="p-2 mb-2">
            {companyContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same company</h2>
                    <div className="flex flex-wrap">
                        {companyContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            {groupContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same group</h2>
                    <div className="flex flex-wrap">
                        {groupContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {interestContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same interests</h2>
                    <div className="flex flex-wrap">
                        {interestContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
     );
}
 
export default RelatedContacts;

/*
<div className="p-2 mb-2">
    <h2 className="text-lg mb-2">{relatedContactLabel}</h2>
    <div className="flex flex-wrap">
        {relatedContacts.map((contact) => (
            <div 
                key={contact.id} 
                onClick={() => handleClick(contact.id)}
                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white data-[state=on]:bg-contacts_primary bg-contacts_secondary bg-opacity-50"
            >
                <p>{contact.first_name} {contact.last_name}</p>
            </div>
        ))}
    </div>
</div>
*/

/*
import { useCallback, useEffect, useMemo, useState } from "react";
import { getContactsHookActions, useSelectedContact } from "../../../../../hooks/contact-hook";
import { ContactTableElement, useContactStoreActions } from "../../../../../hooks/contact-store";

interface RelatedContactsProps {
    type?: string;
}

const RelatedContacts: React.FC<RelatedContactsProps> = ({ type }) => {

    const selectedContact = useSelectedContact();
    
    const { setSelectedContact, setInitialSelectedContact, setNewContact, setIsLeftPanelVisible } = getContactsHookActions();
    const { getContactsWithSameCompany, getContactsWithSameGroup, getContactsWithSameInterest } = useContactStoreActions();

    //const [relatedContacts, setRelatedContacts] = useState<ContactTableElement[]>([]);
    
    const [companyContacts, setCompanyContacts] = useState<ContactTableElement[]>([]);
    const [groupContacts, setGroupContacts] = useState<ContactTableElement[]>([]);
    const [interestContacts, setInterestContacts] = useState<ContactTableElement[]>([]);
    
    const fetchCompanyContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameCompany(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameCompany]);

    const fetchGroupContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameGroup(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameGroup]);

    const fetchInterestContacts = useCallback(() => {
        if (selectedContact) {
            return getContactsWithSameInterest(selectedContact);
        }
        return [];
    }, [selectedContact, getContactsWithSameInterest]);

    //const [relatedContactLabel, setRelatedContactLabel] = useState<string>("");
    /*
    useEffect(() => {
        if (selectedContact) {
            const companyRelated = getContactsWithSameCompany(selectedContact);
            const groupRelated = getContactsWithSameGroup(selectedContact);
            const interestRelated = getContactsWithSameInterest(selectedContact);

            setCompanyContacts(companyRelated || []);
            setGroupContacts(groupRelated || []);
            setInterestContacts(interestRelated || []);
        }
            /*
            //get the contact from the contact store where the company is the same as the selected contact
            //set the related Contacts
            let sameContacts: ContactTableElement[] | undefined = [];
            if (type === "company") {
                //get the company name from the selected contact
                //get the contacts from the store where the company name is the same as the selected contact
                sameContacts = getContactsWithSameCompany(selectedContact);
                if (sameContacts) {
                    setRelatedContacts(sameContacts);
                    setRelatedContactLabel("Related Contacts with the same company");
                }
            } else if (type === "group") {
                //get the group name from the selected contact
                //get the contacts from the store where the group name is the same as the selected contact
                sameContacts = getContactsWithSameGroup(selectedContact);
                if (sameContacts) {
                    setRelatedContacts(sameContacts);
                    setRelatedContactLabel("Related Contacts with the same groups");
                }
            } else if (type === "interest") {
                //get the interest name from the selected contact
                //get the contacts from the store where the interest name is the same as the selected contact
                
                sameContacts = getContactsWithSameInterest(selectedContact);
                if (sameContacts) {
                    setRelatedContacts(sameContacts);
                    setRelatedContactLabel("Related Contacts with the same interests");
                }
            }
        }
        */
    /*
        return () => {
            setCompanyContacts([]);
            setGroupContacts([]);
            setInterestContacts([]);
        };
    }, [selectedContact]);
    */
    /*
    useEffect(() => {
        if (selectedContact) {
            const companyRelated = fetchCompanyContacts();
            const groupRelated = fetchGroupContacts();
            const interestRelated = fetchInterestContacts();

            // Only update state when the data changes
            if (companyRelated && (JSON.stringify(companyRelated) !== JSON.stringify(companyContacts))) {
                setCompanyContacts(companyRelated);
            }

            if (groupRelated && (JSON.stringify(groupRelated) !== JSON.stringify(groupContacts))) {
                setGroupContacts(groupRelated);
            }

            if (interestRelated && (JSON.stringify(interestRelated) !== JSON.stringify(interestContacts))) {
                setInterestContacts(interestRelated);
            }
        }

        return () => {
            setCompanyContacts([]);
            setGroupContacts([]);
            setInterestContacts([]);
        };
    }, []); //, fetchCompanyContacts, fetchGroupContacts, fetchInterestContacts selectedContact

    const handleClick = (contactId: string) => {
        //console.log("Related contact clicked");
        //console.log("contactId: ", contactId);
        setInitialSelectedContact(selectedContact);
        setSelectedContact(contactId);
        setNewContact(undefined);
    };

    return ( 
        <div className="p-2 mb-2">
            {companyContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same company</h2>
                    <div className="flex flex-wrap">
                        {companyContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            {groupContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same group</h2>
                    <div className="flex flex-wrap">
                        {groupContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {interestContacts.length > 0 && (
                <div>
                    <h2 className="text-lg mb-2">Related Contacts with the same interests</h2>
                    <div className="flex flex-wrap">
                        {interestContacts.map((contact) => (
                            <div
                                key={contact.id}
                                onClick={() => handleClick(contact.id)}
                                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white bg-contacts_secondary bg-opacity-50"
                            >
                                <p>{contact.first_name} {contact.last_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
     );
}
 
export default RelatedContacts;

/*
<div className="p-2 mb-2">
    <h2 className="text-lg mb-2">{relatedContactLabel}</h2>
    <div className="flex flex-wrap">
        {relatedContacts.map((contact) => (
            <div 
                key={contact.id} 
                onClick={() => handleClick(contact.id)}
                className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white data-[state=on]:bg-contacts_primary bg-contacts_secondary bg-opacity-50"
            >
                <p>{contact.first_name} {contact.last_name}</p>
            </div>
        ))}
    </div>
</div>
*/