import { useTranslation } from "react-i18next";

import { useOpportunityStoreActions, useOpportunityTable } from "../../../../hooks/contact-oppy-store";
import { Badge } from "../../../../components/ui/badge";
import OppyCard from "./_components_oppy/OppyCard";
import { useEffect, useState } from "react";
import { fetchOpportunities } from "../../../../api/contacts/opportunities/api-get-opportunities";
import OppyCardSample from "./_components_oppy/OppyCardSample";
import { Opportunity, Status } from "../../../../interfaces/contacts/int-opportunity";
import { Button } from "../../../../components/ui/button";
import BottomNavContacts from "../BottomNavContacts";
import { Plus } from "lucide-react";
import { debounce } from "lodash";
import { Input } from "../../../../components/ui/input";

const newOpportunity: Opportunity = {
    title: '',
    description: '',
    priority: '',
    value: 0,
    rate: 0,
    status: { id: '', name: '', order: 10 } as Status,
}

const OpportunitiesComponent = () => {

    const { t } = useTranslation();

    const opportunitiesTable = useOpportunityTable();
    const { initOpportunityTable } = useOpportunityStoreActions();

    const [newOppy, setNewOppy] = useState<Boolean>(false);

    useEffect(() => {
        const getOpportunities = async () => {
            const response = await fetchOpportunities();
            if (response) {
                initOpportunityTable(response);
            }
        };

        getOpportunities();
    }, [])

    const handleNewOppy = () => {
        setNewOppy(true);
    };

    // Search management
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');

    const handleSearchQuery = debounce((query: string) => {
        setSearchQuery(query);
    }, 300);
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newInputValue = e.target.value;
        setInputValue(newInputValue);
        handleSearchQuery(newInputValue); // debounce only affects `searchQuery`
    };
    
    // Filter opportunities based on search term
    const filteredOpportunities = opportunitiesTable?.filter((oppy: Opportunity) => {
        const lowerSearch = searchQuery.toLowerCase();

        // Check if any company names or contacts match the search term
        const companyOrContactMatch = oppy.companies?.some((company) =>
            company.name?.toLowerCase().includes(lowerSearch) ||
            company.contacts?.some((contact) => {
                const fullName = `${contact.firstName ?? ''} ${contact.lastName ?? ''}`.toLowerCase();
                return (
                    fullName.includes(lowerSearch) ||
                    contact.firstName?.toLowerCase().includes(lowerSearch) || 
                    contact.lastName?.toLowerCase().includes(lowerSearch) ||
                    contact.email?.toLowerCase().includes(lowerSearch) ||
                    contact.phone_number?.toLowerCase().includes(lowerSearch)
            )}
            )
        );

        // Check if any actions match the search term
        const actionMatch = oppy.actions?.some((action) =>
            action.title.toLowerCase().includes(lowerSearch) ||
            action.status.toLowerCase().includes(lowerSearch) ||
            (action.due_date ? action.due_date.toString().includes(lowerSearch) : false)
        );
        
        return (
            oppy.title.toLowerCase().includes(lowerSearch) ||
            oppy.description?.toLowerCase().includes(lowerSearch) ||
            oppy.last_note?.text?.toLowerCase().includes(lowerSearch) ||
            oppy.priority?.toLowerCase().includes(lowerSearch) ||
            oppy.value?.toString().includes(lowerSearch) ||
            oppy.rate?.toString().includes(lowerSearch) ||
            oppy.status?.name.toLowerCase().includes(lowerSearch) ||
            companyOrContactMatch ||
            actionMatch
        );
    });

    return ( 
        <div className="container py-1 dark:bg-[#1E1F24] w-full h-full pb-16 min-h-screen">
            <div className="flex justify-start gap-x-2 items-end">
                <h2 id="opportunities" className="flex text-base sm:text-2xl font-bold m-0 p-0">
                    {t("contacts.opportunities.headerTitle.title")}
                </h2>
                <Badge variant="default" className="bg-contacts_primary text-white text-xs sm:text-sm px-3 py-0 mb-1">
                    {filteredOpportunities ? filteredOpportunities.length : 0}
                </Badge>
            </div>

            <div className="flex justify-between gap-x-2 items-end mt-2">
                <Input
                    type="text"
                    placeholder={t("contacts.opportunities.headerTitle.placeholder")}
                    value={inputValue}
                    onChange={handleInputChange}
                    className="flex flex-grow px-3 py-1 border rounded-lg w-full md:w-1/3 hover:border-contacts_primary focus:border-contacts_primary dark:bg-[#2A2E35] dark:border-gray-700"
                />
                <Button
                    onClick={() => handleNewOppy()}
                    className="hidden md:flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold px-3 py-1 rounded-lg"
                >
                    {t("contacts.opportunities.headerTitle.add")}
                </Button>
                <Button
                    onClick={() => handleNewOppy()}
                    className="flex md:hidden bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold px-3 py-1 rounded-lg"
                >
                    <Plus className="h-4 w-4" />
                </Button>
            </div>

            <div className="flex flex-col my-2 h-full w-full gap-y-2 pb-24">
                {newOppy && (
                    <OppyCard data={newOpportunity} setNewOppy={setNewOppy} />
                )}
                {(filteredOpportunities && filteredOpportunities.length > 0) ? filteredOpportunities?.map((oppy: Opportunity) => (
                    <OppyCard data={oppy} />
                )) : (
                    <OppyCardSample />
                )}
            </div>
        </div>
     );
}
 
export default OpportunitiesComponent;

/*
<input
    type="text"
    placeholder={t("contacts.opportunities.headerTitle.placeholder")}
    value={inputValue}
    onChange={handleInputChange}
    className="flex flex-grow px-3 py-1 border rounded-lg w-full md:w-1/3 hover:border-contacts_primary focus:border-contacts_primary dark:bg-[#2A2E35] dark:border-gray-700"
/>
<Button
    onClick={() => handleNewOppy()}
    className="hidden md:flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
>
    {t("contacts.opportunities.headerTitle.add")}
</Button>
<Button
    onClick={() => handleNewOppy()}
    className="flex md:hidden bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
>
    <Plus className="h-4 w-4" />
</Button>
*/