import { useCareerHookActions, useCreatingPlan, useCreationPlan, useSelectedPath, useStepCreation } from '../../../../../hooks/career-hook';
import CreatePlanProcess from '../../createPlan/create-plan-process';
import { useEffect, useRef, useState } from 'react';
import CreatePlanSteps from '../../createPlan/create-plan-steps';
import NoticePlan from './explanation-plan';
import { FirstPromptAPI } from '../../../../../api/career/interface-career';

const LandingPlan = () => {
    const createPlanRef = useRef<HTMLDivElement>(null);
    const selectedPath = useSelectedPath();
    const creatingPlan = useCreatingPlan();
    const currentStep = useStepCreation();
    const { setCreatingPlan, setCreationPlan, getPlanByPath } = useCareerHookActions();
    const creationPlan = useCreationPlan();

    useEffect(() => {
      if (selectedPath) {
        const plan = getPlanByPath(selectedPath);
        plan && setCreationPlan(plan);
      }
    }, [selectedPath]);

    
    useEffect(() => {
      if (creationPlan) {
        createPlanRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, [creationPlan]);
    /*
    useEffect(() => {
      if (creationPlan) {
        const scrollTarget = createPlanRef.current;
        const footerHeight = 50; // Assuming the footer height is known. Adjust accordingly.
    
        if (scrollTarget) {
          const elementPosition = scrollTarget.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - footerHeight;
    
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    }, [creationPlan]);
    */
    

    useEffect(() => {
      return () => { setCreatingPlan(false) };
    }, []);

    //const [planStepsData, setPlanStepsData] = useState<FirstPromptAPI | null>(null); 

    const handleNextStep = (data: FirstPromptAPI) => {
      setCreatingPlan(false);  // Reset creating plan state
      //setPlanStepsData(data);  // Update the planStepsData when moving to the next step

    };

    return (
      <div className="flex flex-col w-full text-[#000021] antialiased">
        {((!creationPlan) || (!creationPlan.current_step) || (creationPlan.current_step && creationPlan.current_step < 5)) && (
          <div>
            <NoticePlan />
            {creationPlan && creationPlan.path && (
              <div ref={createPlanRef} className='flex flex-col'>
                <CreatePlanProcess onNext={handleNextStep} />
              </div>
            )}
          </div>
        )}
      </div>
    );
}

export default LandingPlan;
