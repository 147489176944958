import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity, Status } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions, useOppyStatuses } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"
import { fetchOpportunitiesStatuses } from "../../../../../api/contacts/opportunities/api-get-opportunities";

import { Skeleton } from "../../../../../components/ui/skeleton";
import { Badge } from "../../../../../components/ui/badge";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover"
import { cn } from "../../../../../lib/utils";

interface OppyStatusUnitProps {
    data: Opportunity;
}

export const OppyStatusUnit = ({
    data,
}: OppyStatusUnitProps) => {

    const [open, setOpen] = useState(false);

    const [status, setStatus] = useState<Status | undefined>(data.status || { id: '', name: 'Add a status', order: 10 });

    const oppyStatuses = useOppyStatuses();

    const { t } = useTranslation();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { createUpdateOpportunity, initOppyStatuses } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setStatus(data.status);
            setOpen(false);
        },
        onError: (error) => {
            toast.error(error);
            setOpen(false);
        }
    });

    const handleChange = (value: Status) => {

        if (data.id) {
            if (value.id === status?.id) {
                setOpen(false);
                return;
            }
            
            executeUpdateCard({
                id: data.id,
                status_id: value.id,
            });
        }
    };

    useEffect(() => {
        const getUserStatuses = async () => {
            const response = await fetchOpportunitiesStatuses();
            if (response) {
                initOppyStatuses(response);
            }
        };
        if (!oppyStatuses || oppyStatuses?.length === 0) {
            getUserStatuses();
        }
    }, [])


    return (

        <Popover
            open={open} 
            onOpenChange={setOpen} 
        >
            <PopoverTrigger asChild disabled={!data.title}>
                <button>
                    <Badge className={cn("bg-contacts_primary text-xs md:text-sm cursor-pointer",
                        !data.title && "bg-contacts_primary/10 text-gray-500 border-none italic font-medium cursor-not-allowed"
                    )}>
                        {status?.name ? status?.name?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Add a status"}
                    </Badge>
                </button>
            </PopoverTrigger>
            <PopoverContent className="p-2">
                <div className="flex flex-col text-xs md:text-sm">
                    {oppyStatuses?.map((statusMapped) => (
                        <div
                            key={statusMapped.id}
                            onClick={() => handleChange(statusMapped as Status)}
                            className={cn(
                                "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                status?.id === statusMapped.id && "font-bold"
                            )}
                        >
                            {statusMapped.name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                        </div>
                    ))}
                </div>
            </PopoverContent>
        </Popover>
    );
};

OppyStatusUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };