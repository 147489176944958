import axiosInstance from "../../api";
import { Company, Opportunity, Status } from "../../../interfaces/contacts/int-opportunity";

export const fetchOpportunities = async (): Promise<Opportunity[]> => {
    const response = await axiosInstance.get<Opportunity[]>(`/contact-opportunity/`);
    return response.data;
  };

export const fetchOpportunitiesStatuses = async (): Promise<Status[]> => {
    const response = await axiosInstance.get<Status[]>(`/contact-opportunity/oppyStatuses`);
    return response.data;
};

export const fetchCompanies = async (): Promise<Company[]> => {
  const response = await axiosInstance.get<Company[]>(`/contact-opportunity/getCompanies`);
  return response.data;
}; 