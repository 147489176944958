import { useState, useEffect } from "react";
import HelpCareerModal from "./help-career";
import HelpCommunityModal from "./help-community";
import HelpContactsModal from "./help-contacts";
import HelpTasksModal from "./help-tasks";
import HelpTechwatchModal from "./help-techwatch";
import HelpStartModal from "./help-start";
//import { UploadContacts } from "./upload-download-modal";

export const ModalProvider = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <HelpCareerModal />
            <HelpCommunityModal />
            <HelpContactsModal />
            <HelpTasksModal />
            <HelpTechwatchModal />
            <HelpStartModal />
        </>
    )
}