import React from 'react';
import img_contacts from '../../../../img/landing/contacts.svg';
import img_timeline from '../../../../img/landing/contacts/Timeline-bro.svg';
import img_engagement from '../../../../img/landing/contacts/Contact us-bro.svg';
import img_opportunitiies from '../../../../img/landing/contacts/Efficiency-bro.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

const HelpContactsModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Contacts";

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-contacts_primary dark:text-dark_contacts bg-[#F8F5F5] dark:bg-[#1E1F24] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-3xl text-center font-bold">
            Contacts
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.landingContacts.tagline")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
            <div className="bg-[#F8F5F5] dark:bg-[#1E1F24] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-2">
                    <div className="container mx-auto px-4">
                        <div className="mb-16">
                            <div className="flex flex-col md:flex-row items-center gap-2">
                                <img src={img_contacts} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title1")}</h2>
                                    <p className="mt-2 text-sm text-[#1A3C34] dark:text-[#BCECDF]">
                                        {t("landing.landingContacts.desc1")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16 bg-[#ECF3F1] dark:bg-[#111917] p-2 rounded-lg">
                            <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                                <img src={img_timeline} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title2")}</h2>
                                    <p className="mt-2 text-sm text-[#1A3C34] dark:text-[#BCECDF]">
                                        {t("landing.landingContacts.desc2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16">
                            <div className="flex flex-col md:flex-row items-center gap-2">
                                <img src={img_engagement} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title3")}</h2>
                                    <p className="mt-2 text-sm text-[#1A3C34] dark:text-[#BCECDF]">
                                        {t("landing.landingContacts.desc3")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16 bg-[#ECF3F1] dark:bg-[#111917] p-2 rounded-lg">
                            <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                                <img src={img_opportunitiies} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title4")}</h2>
                                    <p className="mt-2 text-sm text-[#1A3C34] dark:text-[#BCECDF]">
                                        {t("landing.landingContacts.desc4")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
    );
}

export default HelpContactsModal;
