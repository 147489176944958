import { useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity, Priority, priorityValues } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"
import { fetchDeleteOpportunityCompany } from "../../../../../api/contacts/opportunities/delete-opportunity-company";

import { Skeleton } from "../../../../../components/ui/skeleton";
import { Badge } from "../../../../../components/ui/badge";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover"
import { cn } from "../../../../../lib/utils";
import { Building, Building2, ChevronsLeftRightEllipsis, DollarSign, FolderCode, Handshake, Landmark, Pencil, Plus, Shield, Trash, Trash2 } from "lucide-react";
import { ActionTooltip } from "../../../../../components/action-tooltip";
import { Button } from "../../../../../components/ui/button";
import OppyCompanyEditUnit from "./OppyCompanyEditUnit";

interface OppyCompanyUnitProps {
    data: Opportunity;
}

export const OppyCompanyUnit = ({
    data,
}: OppyCompanyUnitProps) => {
    const { t, i18n } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);

    const [isEditingCompany, setEditingCompany] = useState<string | boolean>(false);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeDeleteOpportunityCompany } = useAction(fetchDeleteOpportunityCompany, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
            setIsEditing(false);
        }
    });

    const toggleDeleteCompany = (companyId: string) => {
        if (data.id && companyId) {
            executeDeleteOpportunityCompany({
                opportunity_id: data.id,
                company_id: companyId,
            });   
        }
    };

    return (
        <div className="flex flex-col justify-start gap-x-1 gap-y-2 w-full">
            <div className="flex flex-row gap-x-1 justify-start items-center font-medium">
                <Landmark className="h-4 w-4" />
                <span>Client / Prospect</span>
                <Button 
                    variant="contacts"
                    className="flex h-5 w-5 m-0 p-0 text-sm"
                    onClick={() => {
                        setEditingCompany(true);
                    }}
                >
                    <Plus className="h-4 w-4" />
                </Button>
            </div>

            {isEditingCompany ? (
                <OppyCompanyEditUnit
                    data={data}
                    isEditingCompany={isEditingCompany}
                    setIsEditingCompany={setEditingCompany}
                />
            ) : (
                data.companies?.map((company: any) => (
                    <div className="flex flex-col justify-start pl-2">
                        <div className="flex flex-row justify-start items-center gap-x-1 pl-2">
                            {(company.role === "INTERMEDIARY") ? (
                                <span className="">
                                    <ActionTooltip label="Intermediary">
                                        <ChevronsLeftRightEllipsis className="h-4 w-4" />
                                    </ActionTooltip>
                                </span>
                            ) : (company.role === "PARTNER") ? (
                                <span>
                                    <ActionTooltip label="Partner">
                                        <Handshake className="h-4 w-4" />
                                    </ActionTooltip>
                                </span>
                            ) : (company.role === "PROVIDER") ? (
                                <span>
                                    <ActionTooltip label="Provider">
                                        <FolderCode className="h-4 w-4" />
                                    </ActionTooltip>
                                </span>
                            ) : (
                                <span>
                                    <ActionTooltip label="Client">
                                        <Building className="h-4 w-4 text-contacts_primary" />
                                    </ActionTooltip>
                                </span>
                            )}
                            <div className={cn("flex flex-row w-full justify-start gap-x-2 pl-1 font-semibold", company.role === "CLIENT" && "text-contacts_primary")}>
                                <div className="flex">
                                    {company.name}
                                </div>
                                <div className="flex flew-row gap-x-1">
                                    <button
                                        // Edit task
                                        onClick={() => setEditingCompany(company.id)}
                                        className="text-contacts_primary text-xs inline"
                                    >
                                        <Pencil className="h-4 w-4" />
                                    </button>
                                    <button
                                        // Delete task
                                        onClick={() => toggleDeleteCompany(company.id)}
                                        className="text-contacts_primary text-xs inline"
                                    >
                                        <Trash2 className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="pl-6">
                            {company.contacts.map((contact: any) => (
                                <div className="flex flex-col pl-2">
                                    <div className="text-sm">
                                        {contact.firstName} {contact.lastName}
                                    </div>
                                    <div className="text-xs italic">
                                        {contact.job_title}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

OppyCompanyUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };