import { FormSubmit } from "../../../../components/form/form-submit";
import { FormTextarea } from "../../../../components/form/form-textarea";
import { Button } from "../../../../components/ui/button";
import { Skeleton } from "../../../../components/ui/skeleton";
import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { Card } from "../../../../interfaces/tasks/int-card";
import { AlignLeft } from "lucide-react";
import { ElementRef, useEffect, useRef, useState } from "react";
import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { useAction } from "../../../../hooks/use-action";
import { updateCard } from "../../../../api/tasks/update-card";
import { toast } from "sonner";
import { useSelectedBoard, useSelectedBoardElement, useTaskStoreActions } from "../../../../hooks/tasks-store";
import { useTranslation } from "react-i18next";

interface DescriptionProps {
    data: CardWithList;
}

export const Description = ({
    data
}: DescriptionProps) => {

    const { t } = useTranslation();

    const selectedBoard = useSelectedBoard();
    const selectedBoardElement = useSelectedBoardElement();

    const { createOrUpdateCard, updateTasks } = useTaskStoreActions();
  
    const [isEditing, setIsEditing] = useState(false);

    const textarea = useRef<ElementRef<"textarea">>(null);
    
    const formRef = useRef<ElementRef<"form">>(null);
    const textareaRef = useRef<ElementRef<"textarea">>(null);

    const enableEditing = () => {

        if (!data.id) {
            toast.error('Please add a title first')
        } else {
            setIsEditing(true);
            setTimeout(() => {
                textareaRef.current?.focus();
            });
        }
      };

      const disableEditing = () => {
        setIsEditing(false);
      };
      
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          disableEditing();
        }
      };
    
      useEventListener("keydown", onKeyDown);
      //useOnClickOutside(formRef, disableEditing);

      const { execute, fieldErrors } = useAction(updateCard, {
        onSuccess: (data) => {
            if (selectedBoardElement?.display_mode === "TABLE") {
                updateTasks([data]);
            } else {
                createOrUpdateCard(data);
            }
            //createOrUpdateCard(data);
            toast.success(`Card "${data.title}" updated`);
            disableEditing();
            setDescription(data.description || '');
        },
        onError: (error) => {
            toast.error(error);
        }
      })

      const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        /*
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const description = formData.get("description") as string;
        const parent_elt = selectedBoard as string;
        */

        if (event) event.preventDefault();
        const description = textareaRef.current?.value || '';
        const parent_elt = selectedBoard as string;

        if (description !== data.description) {
            execute({
                id: data.id,
                description,
                parent_elt: data.parent_elt || selectedBoardElement?.id || ''
            })
        } else {
            disableEditing();
        }
      }

      // Submit the form and disable editing when clicking outside the form
        useOnClickOutside(formRef, () => {
            onSubmit();
        });

        const [description, setDescription] = useState('');

        useEffect(() => {
            setDescription(data?.description || '');
        }, [data]);


    return (
        <div className="flex items-start gap-x-3 w-full">
            <AlignLeft className="h-5 w-5 mt-0.5 text-neutral-700 dark:text-neutral-300" />
            <div className="w-full">
                <p className="font-semibold text-sm md:text-lg text-neutral-700 dark:text-neutral-300 mb-2">
                {t("tasks.card.edit.description.title")}
                </p>
                {isEditing ? (
                    <form
                        onSubmit={onSubmit}
                        ref={formRef}
                        className="space-y-2"
                    >
                        <FormTextarea 
                            id="description"
                            className="w-full text-sm text-[#BA4F00] bg-[#F1F1F3] focus:border-[#AFB1BC] dark:bg-[#161719] dark:text-[#FFDECD] mt-2"
                            placeholder={t("tasks.card.edit.description.placeholder")}
                            defaultValue={description}
                            errors={fieldErrors}
                            ref={textareaRef}
                        />
                        <div className="flex items-center gap-x-2">
                            <FormSubmit
                                className="text-xs md:text-sm bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A]"
                            >
                                {t("tasks.card.edit.description.save")}
                            </FormSubmit>
                            <Button
                                type="button"
                                onClick={disableEditing}
                                size="sm"
                                variant="ghost"
                                className="text-xs md:text-sm"
                            >
                                {t("tasks.card.edit.description.cancel")}
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div
                        onClick={enableEditing}
                        role="button"
                        className="min-h-[78px] text-[#54301E] bg-[#F1F1F3] dark:bg-transparent dark:text-[#FFDECD] dark:hover:border-tasks_primary dark:hover:border-[1px] text-sm font-medium py-3 px-3.5"
                        style={{ whiteSpace: 'pre-wrap' }} // Ensures line breaks are preserved
                    >
                        {description || t("tasks.card.edit.description.placeholder")}
                    </div>
                )}
            </div>
        </div>
    );
};

Description.Skeleton = function DescriptionSkeleton() {
    return (
        <div className="flex items-start gap-x-3 w-full">
        <Skeleton className="h-6 w-6 bg-neutral-200" />
        <div className="w-full">
            <Skeleton className="w-24 h-6 mb-2 bg-neutral-200" />
            <Skeleton className="w-full h-[78px] bg-neutral-200" />
        </div>
        </div>
    );
};