import { useEffect, useRef, useState } from "react";

import { useCareerStoreAll } from "../../../../hooks/career-store";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { useAction } from "../../../../hooks/use-action";

import { NodeStatus, NodeCategory } from "../../../../api/career/interface-career";
import { updatePlanElement } from "../../../../api/career/update-plan-element";
import { PlanElement } from "../../../../api/career/interface-career";

import { cn } from "../../../../lib/utils";

import { Layout } from "lucide-react";
import { toast } from "sonner";

import { Skeleton } from "../../../../components/ui/skeleton";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../components/ui/select";
import { Textarea } from "../../../../components/ui/textarea";
import { format } from "date-fns"
import { Button } from "../../../../components/ui/button"
import { Calendar as CalendarIcon } from "lucide-react"
import { Calendar } from "../../../../components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover"
import { useTranslation } from "react-i18next";


const nodecategories = ["MISSION", "GOCHAT", "GOLEARN", "TECHWATCH", "VISIBILITY"];
const nodestatus = ["TO_DO", "IN_PROGRESS", "DONE"];

interface HeaderProps {
    data: PlanElement;
}

export const Header = ({
    data,
}: HeaderProps) => {
    
    const { t } = useTranslation();

    //console.log('data in node : ', data);

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { updatePlanElementHook } = useCareerHookActions();
    const { updateNode } = useCareerStoreAll();
    
    const { execute } = useAction(updatePlanElement, {
        onSuccess: (data) => {
            //console.log('data update card API call :', data);
            updatePlanElementHook(data);
            updateNode(data);
            toast.success(`Card "${data.title}" updated`);
            setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const [title, setTitle] = useState(data?.title);

    // Adding a state to manage the editing state of the title (to avoid the onBlur event to be triggered when clicking on the title)
    const [isEditing, setIsEditing] = useState(data?.id ? false : true);

    const onBlur = () => {
        if (title !== data.title) {
            inputRef.current?.form?.requestSubmit();
        } else {
            setIsEditing(false);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //formData: FormData
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        //console.log('title:', title);
        if (title === data.title) {
            setIsEditing(false);
            return;
        }

        execute({
            title,
            id: data.id,
            plan_id: data.plan_id,
        })
    }
    
    useEffect(() => {
        if (!inputRef.current) return;
        const adjustment = 2;
        inputRef.current.style.height = 'auto'; // Reset height to shrink back if text is deleted
        const idealHeight = inputRef.current.scrollHeight + adjustment;
        inputRef.current.style.height = `${idealHeight}px`;
      }, [title, isEditing]);


    const changeCategory = (category: NodeCategory | undefined) => {
        execute({
            category,
            id: data.id,
            plan_id: data.plan_id,
        })
    }

    const changeStatus = (status: NodeStatus | undefined) => {
        execute({
            status,
            id: data.id,
            plan_id: data.plan_id,
        })
    }

    const [deadline, setDeadline] = useState<Date>(new Date(data?.deadline_date));

    const handleDeadlineChange = (date: Date) => {
        execute({
            deadline_date: date,
            id: data.id,
            plan_id: data.plan_id,
        });
        setDeadline(date);
    };

    return (
        <div className="flex items-start gap-x-3 w-full">
            <Layout className="h-5 w-5 mt-1.5 text-neutral-700 dark:text-neutral-500" />
            <div className="w-full">
                <form 
                    onSubmit={onSubmit}
                    //className="m-0 p-0"
                >
                    {isEditing ? (
                        <Textarea
                            placeholder={t("tasks.card.placeholder")}//"Add a title..."
                            ref={inputRef}
                            onBlur={onBlur}
                            id="title"
                            name="title"
                            //defaultValue={title}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            rows={1}
                            className="w-11/12 font-semibold text-xl px-1 py-0 text-career_primary bg-transparent border-transparent relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                            style={{ minHeight: `36px`, lineHeight: '1.5' }}
                        />
                    ) : (
                        <h2
                            className="w-11/12 font-semibold text-sm md:text-lg text-career_primary hover:bg-[#F1F1F3] bg-transparent dark:bg-background dark:text-white px-1 py-1 items-center cursor-pointer dark:hover:border-career_primary dark:hover:border-[1px]" //dark:text-[#FFDECD] dark:bg-[#161719]
                            onClick={() => setIsEditing(true)}
                        >
                            {title}
                        </h2>
                    )}
                </form>
                <div className="flex flex-row items-center gap-x-2 mt-1.5">
                    <span className="flex text-sm text-muted-foreground m-0 p-0">
                        <Select
                            onValueChange={(value: NodeCategory) => {changeCategory(value)}}
                        >
                            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                                in <SelectValue placeholder={data.category ? data.category.toLowerCase() : "choose a category"} />
                            </SelectTrigger>
                            <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Category</SelectLabel>
                                    {nodecategories.map((category) => (
                                        <SelectItem key={category} value={category}>
                                            {category.toLowerCase()}
                                        </SelectItem>
                                    ))}
                            </SelectGroup>
                            </SelectContent>
                        </Select>
                    </span>
                    <span className="flex text-sm text-muted-foreground m-0 p-0">
                        <Select
                            onValueChange={(value: NodeStatus) => {changeStatus(value)}}
                        >
                            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                                <SelectValue placeholder={data.status ? data.status.split('_').join(' ').toLowerCase() : "To do"} />
                            </SelectTrigger>
                            <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Status</SelectLabel>
                                    {nodestatus.map((status) => (
                                        <SelectItem key={status} value={status}>
                                            {status.split('_').join(' ').toLowerCase()}
                                        </SelectItem>
                                    ))}
                            </SelectGroup>
                            </SelectContent>
                        </Select>
                    </span>
                    <span 
                        // Deadline date picker
                        className="flex px-0"
                    >
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    //"w-[280px] justify-start text-left font-normal",
                                    "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                                    !deadline && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {deadline ? format(deadline, "PPP") : <span>add a deadline</span>}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                mode="single"
                                selected={deadline}
                                onSelect={(value) => value && handleDeadlineChange(value)}
                                initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </span>
                </div>
            </div>
        </div>
    );
};

Header.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };