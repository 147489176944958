import React, { type FC } from 'react';
import {
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
  type Edge,
  type EdgeProps,
} from 'reactflow';

// this is a little helper component to render the actual edge label

interface CustomEdgeCategoriesData {
    startLabel?: string;
    endLabel?: string;
  }

function EdgeLabel({ transform, label }: { transform: string; label: string }) {
  return (
    <div
      style={{
        position: 'absolute',
        background: '#E4707F',
        padding: '4px 8px',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 700,
        fontStyle: 'italic',
        whiteSpace: 'nowrap',  // Prevent text wrapping
        width: '150px',        // Set a fixed width for all labels
        height: '30px',        // Set a fixed height if needed
        textAlign: 'center',   // Center the text horizontally
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  // Center the text vertically
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

//const CustomEdgeCategories: FC<EdgeProps<Edge<CustomEdgeCategoriesData>>> = ({
const CustomEdgeCategories: FC<EdgeProps<CustomEdgeCategoriesData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  // Calculate the midpoint for the label
  const labelX = (sourceX + targetX) / 2;
  const labelY = (sourceY + targetY) / 2;

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {data?.startLabel && data?.startLabel !== "Start" && (
          <EdgeLabel
            transform={`translate(-50%, 0%) translate(${sourceX}px,${sourceY}px)`}
            label={data.startLabel}
          />
        )}
        {data?.endLabel && (
          /*
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${targetX}px,${targetY}px)`}
            label={data.endLabel}
          />
          */
          <EdgeLabel
            transform={`translate(-50%, -50%) translate(${targetX - 100}px,${targetY}px)`}
            label={data.endLabel}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdgeCategories;