import axiosInstance from "../api";
import { Group } from "../../hooks/contact-store";
import { CreateGroup } from "./interface-contact";

export const fetchUserGroups = async (): Promise<Group[]> => {
    const response = await axiosInstance.get<Group[]>(`/contact-groups/`);
    return response.data;
  };

export const fetchAddGroupContact = async (contactId: string, group: Group): Promise<{ newGroup: Group }> => {
      const response = await axiosInstance.post<{ newGroup: Group }>(`/contact-groups/addGroup`, {
        contactId: contactId,
        group: group,
      });
      return response.data;
    };

export const fetchaddMultipleGroupsContact = async (contactId: string, groupsIds: string[]): Promise<{ newContactGroups: Group[] }> => {
  //console.log('groupsIds', groupsIds);
  const response = await axiosInstance.post<{ newContactGroups: Group[] }>(`/contact-groups/addMultipleGroups`, {
    contactId: contactId,
    groupsIds: groupsIds,
  });
  return response.data;
};

export const fetchDeleteGroupContact = async (contactId: string, groupId: string): Promise<string> => {
    const response = await axiosInstance.delete<string>(`/contact-groups/removeGroupContact`, {
        data: {
            contact_id: contactId,
            group_id: groupId,
        },
    });
    return response.data;
};

export const fetchAddGlobalGroup = async (newGroup: CreateGroup): Promise<Group> => {
  const response = await axiosInstance.post<{ newGroup: Group }>(
    `/contact-groups/addGlobalGroup`,
    { group: newGroup }
  );
  return response.data.newGroup;
};

export const fetchUpdateGlobalGroup = async (newGroup: Group): Promise<Group> => {
  const response = await axiosInstance.post<{ newGroup: Group }>(
    `/contact-groups/updateGlobalGroup`,
    { group: newGroup }
  );
  return response.data.newGroup;
};

export const fetchDeleteGlobalGroup = async (groupId: string): Promise<string> => {
  const response = await axiosInstance.delete<string>(`/contact-groups/deleteGroup/${groupId}`);
  return response.data;
};