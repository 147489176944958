import img_general from '../../../../img/landing/landing_servers/general.svg';
import img_missions from '../../../../img/landing/landing_servers/missions.svg';
import img_coworking from '../../../../img/landing/landing_servers/coworking.svg';
import img_kickstart from '../../../../img/landing/landing_servers/kickstart.svg';
import img_projects from '../../../../img/landing/landing_servers/projects.svg';
import img_upskilling from '../../../../img/landing/landing_servers/upskilling.svg';
import img_accounting from '../../../../img/landing/landing_servers/accounting.svg';
import img_tips from '../../../../img/landing/landing_servers/tips.svg';
import img_FAQ from '../../../../img/landing/landing_servers/FAQ.svg';

import { Button } from '../../../../components/ui/button';
import { useCommunityActions } from '../../../../hooks/community-hook';
import { useEffect, useState } from 'react';
import { ServerData, findServers } from '../../../../api/community/api-display-server';
import { useTranslation } from 'react-i18next';



const MainServers = () => {
    const { t } = useTranslation();
    const [servers, setServers] = useState<ServerData[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const getServers = async () => {
            try {
                const response = await findServers();
                setServers(response)
                setIsLoading(false);
            } catch (error) {
                //console.log('erreur de get contact : ', error);
                setIsLoading(false);
            }
        };
        getServers();
    }, [])


    const { setSelectedServer } = useCommunityActions();

    const handleServerSelection = (id: string, name: string) => {
        setSelectedServer({id:id, name:name});
    }

    
    // Safety check to handle rendering only when servers are loaded
    if (isLoading) {
        return <div>Loading servers...</div>; // Show loading state or spinner
    }
        

    interface FeatureProps {
        id: string;
        name: string;
        description: string;
        image: string;
        cta: string;
        reverse: boolean;
        color: string;
        index: number;
    }
    
    const features = servers.map((server, index) => ({
        id: server.id,
        name: t(`community.landing.${server.name}.title`),
        description: t(`community.landing.${server.name}.description`),
        cta: t(`community.landing.${server.name}.cta`),
        image: [img_general, img_missions, img_coworking, img_kickstart, img_projects, img_upskilling, img_accounting, img_tips, img_FAQ][index],
        reverse: index % 2 === 1,
        color: 'text-community_' + (index % 2 === 0 ? 'primary' : 'complementary'),
        index,
    }));

    const Feature: React.FC<FeatureProps> = ({ id, name, description, image, cta, reverse, color, index }) => {
        return (
            <div className={`flex flex-col sm:flex-row ${reverse ? 'sm:flex-row-reverse' : ''} sm:items-stretch gap-4 p-4
                ${index % 2 === 0 ? 'bg-[#F4E2EE] dark:bg-[#1D101A]' : 'bg-[#F8F5F5] dark:bg-[#212121]'} sm:min-h-[400px]
            `}>
                <div className="flex-1 flex justify-center items-center sm:items-start">
                    <img src={image} alt={name} className="max-w-md h-auto mx-auto" />
                </div>
                <div className="flex-1 flex flex-col justify-center items-start text-left space-y-4">
                    <h2 className="text-2xl font-semibold text-community_primary dark:text-dark_community">{name}</h2>
                    <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">{description}</p>
                    <Button
                        className="text-xs md:text-sm mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]"
                        onClick={() => handleServerSelection(id, name)}
                    >
                        {cta}
                    </Button>
                </div>
            </div>
        );
    };
    


    return (
        <div className="flex flex-grow flex-col h-full bg-[#F8F5F5] dark:bg-[#212121] text-[#000021] antialiased">
            {/* Hero Section */}
            <div className="text-center p-8">
                <h1 className="text-2xl font-semibold text-community_primary dark:text-dark_community">
                    {t('community.landing.summary.title')}
                </h1>
                <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">
                {t('community.landing.summary.description')}
                </p>
            </div>

            
            {/* Features Section */}
            {features.map((feature, index) => (
                <Feature key={feature.id} {...feature} index={index} />
            ))}

        </div>
    );
}
 
export default MainServers;