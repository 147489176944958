import { createStore, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ExistingPlan, Plan, PlanElement } from "../api/career/interface-career";

interface ProfileHook {
    selectedProfilePage: string | undefined;
    selectedTab: string;
    
    actions: {
        setSelectedProfilePage: (selectedProfilePage:  string | undefined) => void;
        getSelectedTab: () => string;
        setSelectedTab: (tab: string) => void;
        clearProfileHook: () => void;
    }
}

const profileHook = createStore<ProfileHook>()(
    devtools(
        (set, get) => ({
            selectedProfilePage: undefined,
            selectedTab: 'General',
            
            actions: {
                setSelectedProfilePage: (selectedProfilePage :  string | undefined) => {
                    set({
                        selectedProfilePage,
                    });
                },
                getSelectedTab: () => {
                    return get().selectedTab;
                },
                setSelectedTab: (tab: string) => {
                    set({
                        selectedTab: tab,
                    });
                },
                clearProfileHook: () => {
                    set({
                        selectedProfilePage: undefined,
                        selectedTab: 'General',
                    });
                },
            },
        }),
        {
            name: 'career-hook',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
)

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof profileHook, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof profileHook>) => state.actions;
const selectedTabSelector = (state: ExtractState<typeof profileHook>) => state.selectedTab;
const selectedProfilePageSelector= (state: ExtractState<typeof profileHook>) => state.selectedProfilePage;

// getters
export const getContactStoreActions = () => actionsSelector(profileHook.getState());
export const getSelectedTabContact = () => selectedTabSelector(profileHook.getState());
export const getSelectedProfilePage = () => selectedProfilePageSelector(profileHook.getState());

function useProfileHook<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(profileHook, selector, equalityFn);
}

// Hooks
export const useProfileHookActions = () => useProfileHook(actionsSelector);
export const useSelectedTabProfile = () => useProfileHook(selectedTabSelector);
export const useSelectedProfilePage = () => useProfileHook(selectedProfilePageSelector);
