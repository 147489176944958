// BottomNavContacts.tsx
import { Home, BookUser, CircleArrowRight, SmilePlus, User, Rocket } from "lucide-react";
import { useSelectedTab, useTechwatchStoreActions } from "../../../hooks/techwatch-store";
import { useContactHookActions, useSelectedTabContact } from "../../../hooks/contact-hook";

interface BottomNavContactsProps {
    handleOpenSheetFunction: (newContact?: boolean) => void;
  }

const BottomNavContacts : React.FC<BottomNavContactsProps> = ({ handleOpenSheetFunction }) => {
    const { setSelectedTabContact } = useContactHookActions();

    const handleSelectedTab = (tab: string) => {
        setSelectedTabContact(tab);
    };

    const selectedTab = useSelectedTabContact();

    const isSelected = (tab: string) => {
        return tab === selectedTab;
    };

    const availableTabs = ['All_Contacts', 'Next_Best_Actions', 'Opportunities'];

    //console.log("selectedTab: ", selectedTab);

    // Create a function that returns conditional class names based on tab selection
    const getIconClasses = (tab: string) => 
      isSelected(tab) 
          ? "text-[#107362] dark:text-[#78CDB9]" // Apply a primary color for the selected tab
          : "text-[#84C2B3] dark:text-[#22453D] hover:text-contacts_secondary"; // Apply secondary hover color for non-selected tabs

    const handleContactCreation = () => {
        setSelectedTabContact("All_Contacts");
        handleOpenSheetFunction(true);
    };
         
    return (
        <div className="fixed bottom-0 left-0 right-0 flex justify-around bg-[#F3F6FA] dark:bg-[#0D1723] shadow-md p-3 md:hidden z-50 border-t border-zinc-300 dark:border-zinc-700">
            <button onClick={() => handleSelectedTab("All_Contacts")} aria-label="All_Contacts">
                <BookUser size={24} className={getIconClasses("All_Contacts")}/>
            </button>
            <button onClick={() => handleSelectedTab("Next_Best_Actions")} aria-label="Next_Best_Actions">
                <CircleArrowRight size={24} className={getIconClasses("Next_Best_Actions")}/>
            </button>
            <button onClick={() => handleSelectedTab("Opportunities")} aria-label="Opportunities">
                <Rocket size={24} className={getIconClasses("Opportunities")}/>
            </button>
            <button onClick={() => handleContactCreation()} aria-label="Add">
                <SmilePlus size={24} className={getIconClasses("Add")}/>
            </button>
        </div>
    );
};

export default BottomNavContacts;
