import { MoreHorizontal, Copy, Trash2, Mails, SquareUserRound } from "lucide-react";
import { useToast } from "../../../../../components/ui/use-toast";
import { Button } from "../../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu"

import { ContactTableElement, getContactStoreActions } from "../../../../../hooks/contact-store";
import { getContactsHookActions } from "../../../../../hooks/contact-hook";
import { fetchDeleteContact } from "../../../../../api/contacts/api-getContactData";

// Define the props interface
interface ContactTableActionCellProps {
    contact: ContactTableElement;
}

 //handle left panel visibility
 const { setIsLeftPanelVisible, setSelectedContact } = getContactsHookActions();
 
 const handleOpenSheet = (contactId: string) => {
       //console.log('contactId', contactId);
       setSelectedContact(contactId);
       setIsLeftPanelVisible(true);
   };


const ContactTableActionCell = ({ contact }: ContactTableActionCellProps) => {
    const { toast } = useToast();

    /*const handleCopyClipboard = (type: string) => {
        try {
            if (type === "email") {
                if (contact.email) 
                navigator.clipboard.writeText(contact.email);   
            }
            if (type === "phone") {
            navigator.clipboard.writeText(contact.phone_number);
            }
            toast({
                description: `${type} copied to clipboard`,
              });
        } catch (error) {
            toast({
                description: "Oups can not copy to clipboard",
              });
              //TODO : check if we need to treat legacy browser
        }
      };*/

    //Function copyToClipBoard to store the email or phone number in clipboard
    
    async function copyTextToClipboard(type: string, text?: string) {
      if (!text) { 
          toast({
              description: `Can not copy to clipboard, ${type} is missing in the contact details`,
          });
      }
      else {
          if ('clipboard' in navigator) {
          try {
              await navigator.clipboard.writeText(text);
              toast({
                  description: `${type} copied to clipboard`,
              });
          } catch (err) {
              //console.error('Failed to copy: ', err);
          }
          } else {
          // Fallback for older browsers
              try {
                  const textarea = document.createElement('textarea');
                  textarea.value = text;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textarea);
                  toast({
                      description: `${type} copied to clipboard`,
                  });
              } catch (err) {
                  //console.error('Failed to copy: ', err);
                  toast({
                      description: `Fail to copy ${type} in the clipboard`,
                  });
              }
          }
      }
    };

    const handleContactDeletion = (contactId: string) => {
        //console.log("delete contact");
        //console.log("contactId: ", contactId);
        const { deleteContact } = getContactStoreActions();
        const deleteContactToDB = async () => {
            try {
                const response = await fetchDeleteContact(contactId);
                //console.log("response: ", response);
                deleteContact(contactId);
                toast({
                    description: "Contact deleted",
                  });
            } catch (err) {
                //console.log("error: ", err);
                toast({
                    description: "Oups can not delete contact",
                  });
            }
        };
        deleteContactToDB();
    };

     return ( 
        <div onClick={(event) => event.stopPropagation()}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem
                    onClick={() => copyTextToClipboard("email", contact.email)}
                  >
                    <Mails size={16} />
                    <p className="ml-2">Copy email</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => copyTextToClipboard("phone", contact.phone_number)}
                  >
                    <Copy size={16} />
                    <p className="ml-2">Copy phone number</p>
                  </DropdownMenuItem>

                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => handleOpenSheet(contact.id)}
                  >
                    <SquareUserRound size={16} />
                    <p className="ml-2">View contact details</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleContactDeletion(contact.id)}
                  >
                    <Trash2 size={16} />
                    <p className="ml-2">Delete contact</p>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
     );
}
 
export default ContactTableActionCell;