import { InputType, ReturnType } from "./types";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { UpdateCard } from "./schema";

import axiosInstance from "../../api";
import { Card } from "../../../interfaces/tasks/int-card";


const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting update card API call");
    try {
        //console.log('data update card API call :', data);
        const response = await axiosInstance.patch<Card>(`/cards/updateCard`, data);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const updateCard = createSafeAction(UpdateCard, handler);

/*
const handler = async (data: InputType): Promise<ReturnType> => {

    const { userId, orgId } = auth();

    if (!userId || !orgId) {
        return {
            error: "Unauthorized",
        };
    }

    const { id, boardId, ...values } = data;

    let card;

    try {
        card = await db.card.update({
            where: {
                id,
                list: {
                    board: {
                        orgId,
                    },
                },
            },
            data: {
                ...values,
            }
        });
    } catch (error) {
        return {
            error: "Failed to update"
        }
    }

    revalidatePath(`/board/${boardId}`);
    return { data: card};
};

export const updateCard = createSafeAction(UpdateCard, handler);
*/