import { FeedExtended } from '../../../interfaces/techwatch/int-techwatch';
import { 
    LinkedinShareButton, 
    LinkedinIcon, 
    TwitterShareButton, 
    TwitterIcon, 
    RedditShareButton, 
    RedditIcon, 
    WhatsappShareButton, 
    WhatsappIcon,
 } from 'react-share';

 import { Mail } from 'lucide-react';
 import { SocialIcon } from 'react-social-icons'

// TypeScript interface for props
interface LinkedInShareButtonProps {
    feed: FeedExtended;
}

const SocialShareButton: React.FC<LinkedInShareButtonProps>  = ({feed}) => {

    // Handle gmail share button
    const shareUrl = feed.link;
    const subject = feed.title;
    const body = "Hello, I thought you might find this interesting: \n\n" + feed?.tldr + "\n\n" + shareUrl;
    
    const handleGmailShareClick = () => {
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(gmailUrl, '_blank');
    };

    return ( 
        <div className="items-center justify-center flex gap-x-3">
            <LinkedinShareButton
                url={feed.link}
                title={feed?.tldr}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            {/* Twitter Share Button */}
            <TwitterShareButton
                url={feed.link}
                title={feed?.tldr}
                className="rounded"
            >
                <SocialIcon url='"https://x.com' style={{ height: 32, width: 32 }}/>
                {/*<TwitterIcon size={32} round />*/}
            </TwitterShareButton>
            {/* Reddit Share Button */}
            <RedditShareButton
                url={feed.link}
                title={feed?.tldr}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
                <RedditIcon size={32} round />
            </RedditShareButton>
            {/* WhatsApp Share Button */}
            <WhatsappShareButton
                url={feed.link}
                title={feed?.tldr}
                separator=": "
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <button 
                onClick={handleGmailShareClick}
                className="bg-red-600 hover:bg-red-800 text-white font-bold px-2 py-1.5 rounded-full inline-flex justify-center items-center h-8 w-8"
            >
                <Mail size={20} />
             </button>
        </div> 
    );
}
 
export default SocialShareButton;