import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { useModal } from "./use-modal-store";
import { useTranslation } from 'react-i18next';
import { toast } from "sonner";
import { useContactStoreActions } from "../../../../hooks/contact-store";
import { fetchDeleteGlobalInterest } from "../../../../api/contacts/api-contact-interest";

export const DeleteInterestModal = () => {
  const { t } = useTranslation();

  const { isOpen, onClose, type, data } = useModal();

  const isModalOpen = isOpen && type === "deleteInterest";

  const [isLoading, setIsLoading] = useState(false);
  const { deleteInterest } = useContactStoreActions();  // Action pour supprimer un interet dans le store


  const onClick = async () => {
    if (data?.interest) {
        try {
          setIsLoading(true);
          // Appel API pour supprimer l'interet
          await fetchDeleteGlobalInterest(data.interest.id);
          deleteInterest(data.interest.id);  // Supprimer l'interet du store
          toast.success(`Interest "${data.interest.name}" deleted successfully`);
          onClose();
        } catch (error) {
          toast.error("Failed to delete the interest");
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#1E1F24] p-0 overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-contacts_primary ">
            {t("profile.contacts.deleteModal.titleInterest", { interestName: data?.interest?.name })}
          </DialogTitle>
          <DialogDescription className="text-center text-[#1A3C34] dark:text-[#BCECDF]">
            {t("profile.contacts.deleteModal.descriptionInterest", { count: data?.associatedContacts || 0 })}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="bg-[#F8F5F5] dark:bg-[#1E1F24] px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="mt-4 text-[#1A3C34] dark:text-[#BCECDF] hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]px-4 py-2"
              variant="ghost"
            >
              {t("profile.contacts.deleteModal.cancel")}
              </Button>
            <Button
              disabled={isLoading}
              onClick={onClick}
              className="mt-4 bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] font-bold px-4 py-2 rounded shadow hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
            >
              {t("profile.contacts.deleteModal.confirm")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}