import React, { useEffect, useMemo, useState } from 'react';

import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
  } from '@hello-pangea/dnd';

import { Status } from '../../../../../interfaces/contacts/int-opportunity';

import { useOpportunityStoreActions, useOppyStatuses } from '../../../../../hooks/contact-oppy-store';

import { Edit, Trash2, Save, X, GripVertical } from 'lucide-react';
import { fetchUpdateOpportunityStatusOrder } from '../../../../../api/contacts/opportunities/update-opportunity-status-order';
import { useAction } from '../../../../../hooks/use-action';
import { toast } from "sonner";
import { fetchOpportunityStatusDelete } from '../../../../../api/contacts/opportunities/delete-opportunity-status';
import { fetchCreateOpportunityStatus } from '../../../../../api/contacts/opportunities/create-opportunity-status';
import { fetchUpdateOpportunityStatus } from '../../../../../api/contacts/opportunities/update-opportunity-status';
import { fetchOpportunitiesStatuses } from '../../../../../api/contacts/opportunities/api-get-opportunities';
import { Input } from '../../../../../components/ui/input';
import { Button } from '../../../../../components/ui/button';

const OpportunityStatusManager = () => {

    //const statuses = useOppyStatuses() || [];    
    const { initOppyStatuses, createUpdateOppyStatuses, updateOppyStatusOrder, deleteOppyStatus } = useOpportunityStoreActions();

    const globalStatuses = useOppyStatuses() || [];
    
    // **Use local state for statuses**
    const [statuses, setStatuses] = useState<Status[]>([]);
    /*
    useEffect(() => {
        // Initialize local statuses from global store
        setStatuses(globalStatuses);
    }, [globalStatuses]);
    */
    //const [displayStatuses, setDisplayStatuses] = useState<Status[]>([]);

    // Initialize and synchronize statuses
    useEffect(() => {
        // Check for any additions, deletions, or name changes
        const hasChanges = globalStatuses.length !== statuses.length ||
            globalStatuses.some((status, index) => status.name !== statuses[index]?.name);

        // If there are changes, update statuses while preserving order
        if (hasChanges) {
            setStatuses(prevStatuses => {
                const updatedStatuses = globalStatuses.map(globalStatus => {
                    const existingStatus = prevStatuses.find(status => status.id === globalStatus.id);
                    return existingStatus ? { ...existingStatus, name: globalStatus.name } : globalStatus;
                });
                return updatedStatuses;
            });
        }
    }, [globalStatuses]);
    /*
    useEffect(() => {
        // Update orderedStatuses only if there are changes in content or length
        const hasChanges = globalStatuses.some((status, index) => 
            status.id !== statuses[index]?.id || status.name !== statuses[index]?.name
        );
    
        if (hasChanges || globalStatuses.length !== statuses.length) {
            setStatuses(globalStatuses);
        }
    }, [globalStatuses]);
    */
    const [newStatusName, setNewStatusName] = useState('');
    const [isEditing, setIsEditing] = useState<string | null>(null);
    const [editedName, setEditedName] = useState('');

    useEffect(() => {
        const getOppyStatusesFromDb = async () => {
            const response = await fetchOpportunitiesStatuses();
            if (response) {
                initOppyStatuses(response);
                setStatuses(response);
            }
        };
        if (!statuses || !statuses.length) {
            getOppyStatusesFromDb();
        };

    }, []);

    const { execute: executeOppyAddStatus } = useAction(fetchCreateOpportunityStatus, {
        onSuccess: (data: Status) => {
            
            createUpdateOppyStatuses(data);
            toast.success(`Opportunity status updated`);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const addStatus = () => {
        if (newStatusName.trim() === '') return;

        // Find the maximum order value in the current statuses
        const maxOrder = statuses.reduce((max, status) => Math.max(max, status.order), 0);
        
        const newStatus: Status = {
            //id: Date.now().toString(),
            id: '',
            name: newStatusName,
            order: maxOrder + 1,
        };

        //setStatuses([...statuses, newStatus]);
        setNewStatusName('');
        executeOppyAddStatus(newStatus);
    };

    const { execute: executeOppyModifyStatus } = useAction(fetchUpdateOpportunityStatus, {
        onSuccess: (data: Status) => {
            
            createUpdateOppyStatuses(data);
            toast.success(`Opportunity status updated`);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const editStatus = (id: string, newName: string, order: number) => {
        if (newName.trim() === '') return;

        const updatedStatus = {
            id,
            name: newName,
            order: order,
        };
        executeOppyModifyStatus(updatedStatus);
    };

    const { execute: executeOppyDeleteStatus } = useAction(fetchOpportunityStatusDelete, {
        onSuccess: (data: { id: string }) => {
            
            deleteOppyStatus(data.id);
            toast.success(`Opportunity status updated`);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const deleteStatus = (id: string) => {
        if (!id) return;
        executeOppyDeleteStatus({ id });
    };

    const { execute: executeChangeStatusOrder } = useAction(fetchUpdateOpportunityStatusOrder, {
        onSuccess: (data: Status[]) => {
            updateOppyStatusOrder(data);
            toast.success(`Opportunity status updated`);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity status order");
        }
    });

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (!destination) { //|| !statuses
        return;
        }

        const reorderedStatuses = Array.from(statuses);
        const [removed] = reorderedStatuses.splice(source.index, 1);
        reorderedStatuses.splice(destination.index, 0, removed);

        // Update the order property based on the new index
        const updatedStatuses = reorderedStatuses.map((status, index) => ({
            ...status,
            order: index + 1,
        }));

        // **Update local state immediately**
        setStatuses(updatedStatuses);
        // Send the update to the server
        setTimeout(() => {
            executeChangeStatusOrder(updatedStatuses);
        }, 0);
    };

    //const sortedStatuses = useMemo(() => {
    //    return [...statuses].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    //  }, [statuses]);

    return (
        <div className="max-w-md bg-transparent justify-start">
          <h2 className="text-sm md:text-lg font-semibold mb-3">Manage Opportunity Statuses</h2>
    
          {/* Status List */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="statuses">
              {(provided) => (
                <div
                  className="status-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {statuses && statuses.map((status, index) => (
                    <Draggable key={status.id} draggableId={status.id} index={index}>
                      {(provided) => (
                        <div
                          className="status-item flex items-center justify-between gap-x-2 p-2 text-sm bg-white dark:bg-gray-700 shadow mb-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="flex items-center flex-grow gap-x-1">
                            <span
                              {...provided.dragHandleProps}
                              className="mr-2 cursor-grab"
                            >
                              <GripVertical className="w-4 h-4 text-gray-500 dark:text-gray-300" />
                            </span>
                            {isEditing === status.id ? (
                              <Input
                                type="text"
                                value={editedName}
                                onChange={(e) => setEditedName(e.target.value)}
                                className="border p-1 flex-grow"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      editStatus(status.id || '', editedName, status.order);
                                      setIsEditing(null);
                                    } else if (e.key === 'Escape') {
                                        setIsEditing(null);
                                    }
                                  }}
                              />
                            ) : (
                              <span 
                                className="text-gray-800 dark:text-gray-200"
                                onClick={() => {
                                    setIsEditing(status.id);
                                    setEditedName(status.name);
                                  }}
                              >
                                    {status.name}
                              </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            {isEditing === status.id ? (
                              <>
                                <button
                                  onClick={() => {
                                    editStatus(status.id || '', editedName, status.order);
                                    setIsEditing(null);
                                  }}
                                  className="text-contacts_primary"
                                >
                                  <Save className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => setIsEditing(null)}
                                  className="text-gray-500"
                                >
                                  <X className="w-4 h-4" />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    setIsEditing(status.id ?? '');
                                    setEditedName(status.name);
                                  }}
                                  className="text-contacts_primary"
                                >
                                  <Edit className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => deleteStatus(status.id ?? '')}
                                  className="text-gray-500"
                                >
                                  <Trash2 className="w-4 h-4" />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* Add New Status */}
          <div className="flex mb-4 justify-between">
            <Input
              type="text"
              value={newStatusName}
              onChange={(e) => setNewStatusName(e.target.value)}
              placeholder="Add new status"
              className="shadow border rounded max-w-sm py-2 px-3 bg-[#EEF1F0] text-[#1A3C34] dark:bg-[#172A26] dark:text-[#BCECDF] leading-tight focus:border-[#1A3C34] dark:focus:border-[#1A3C34] focus:outline-none focus:ring-0"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addStatus();
                }
              }}
            />
            <Button
              onClick={addStatus}
              className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
            >
              Add
            </Button>
          </div>
        </div>
      );
    };
    
 
export default OpportunityStatusManager;