export interface Status {
    id: string;
    name: string;
    order: number;
  }
  
export interface Contact {
    id: string;
    firstName?: string;
    lastName?: string;
    job_title?: string;
    email?: string;
    phone_number?: string;
  }
  
export interface Company {
    id?: string;
    name?: string;
    role?: companyRole;
    contacts: Contact[];
  }
  
export interface Note {
    id?: string;
    text?: string | null;
  }
  
export interface Action {
    id?: string | undefined;
    board?: string;
    title: string;
    status: string;
    due_date: Date | null;
  }
  
export interface Opportunity {
    id?: string | undefined;
    title: string;
    description?: string;
    status?: Status;
    priority?: Priority | '';
    value?: number;
    rate?: number;
    start_date?: Date | null;
    end_date?: Date | null;
    exp_close_date?: Date | null;
    companies?: Company[];
    last_note?: Note;
    actions?: Action[];
  }

export type Priority = "LOW" | "MEDIUM" | "HIGH";
export const priorityValues = ["LOW", "MEDIUM", "HIGH"];


export type companyRole = "CLIENT" | "INTERMEDIARY" | "PARTNER" | "PROVIDER";
export const companyRoleValues = ["CLIENT", "INTERMEDIARY", "PARTNER", "PROVIDER"];