import { useCareerHookActions, useCreationPlan } from "../../../../hooks/career-hook";
import { fetchCreatePlanPromptNew, fetchEditPlan } from "../../../../api/career/api-create-plan";
import { useState } from "react";
import { FormTextarea } from "../../../../components/form/form-textarea";
import { useTranslation } from 'react-i18next';

interface CreatePlanContextProps {
    onNext: () => void;
}

const CreatePlanContext: React.FC<CreatePlanContextProps> = ({ onNext }) => {
    const creationPlan = useCreationPlan();
    const { setCreationPlan, setPlanElements } = useCareerHookActions();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (step: 'previous' | 'next', e: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true);
        e.preventDefault();
        const form = e.currentTarget.form;
        const formData = new FormData(form!);
        const context = formData.get("context") as string;
        const skills = formData.get("skills") as string;
        const soft_skills = formData.get("soft_skills") as string;
        const tools = formData.get("tools") as string;
        const newStep = creationPlan?.current_step && (step === 'previous' ? creationPlan?.current_step - 1 : creationPlan?.current_step + 1);

        const creationPlanUpdate = {
            ...creationPlan,
            context: context,
            skills: skills,
            soft_skills: soft_skills,
            tools: tools,
            current_step: newStep,
        }

        try {
            const response = creationPlan?.id && await fetchEditPlan(creationPlan?.id, creationPlanUpdate);
            
            if (response) {
                if (step === 'next') { 
                    // reset plan elements
                    setPlanElements(undefined);
                    const apiResponse = await fetchCreatePlanPromptNew(creationPlanUpdate);
                    setPlanElements(apiResponse);                
                    //onNext();
                }
                setCreationPlan(response);
            }
        } catch (error) {
            //console.error("Error while updating the plan:", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="max-w-xl md:max-w-2xl lg:max-w-3xl mx-auto p-6 bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold text-center text-career_primary dark:text-[#FBD5D7] mb-6">{t("career.createPlan.step2.top")}</h2>
            <form id="creationPlanForm">
                <div className="mb-4">
                    <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step2.experience.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step2.experience.description")}
                    </p>
                    <FormTextarea
                        id="context"
                        //name="context"
                        className="mt-2 p-4 block w-full h-48 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step2.experience.placeholder")}
                        defaultValue={creationPlan?.context}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold">{t("career.createPlan.step2.skills.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step2.skills.description")}
                    </p>
                    <FormTextarea
                        id="skills"
                        //name="skills"
                        className="mt-2 p-4 block w-full h-48 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step2.skills.placeholder")}
                        defaultValue={creationPlan?.skills}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold">{t("career.createPlan.step2.softSkills.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step2.softSkills.description")}
                    </p>
                    <FormTextarea
                        id="soft_skills"
                        //name="soft_skills"
                        className="mt-2 p-4 block w-full h-48 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step2.softSkills.placeholder")}
                        defaultValue={creationPlan?.soft_skills}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold">{t("career.createPlan.step2.tools.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step2.tools.description")}
                    </p>
                    <FormTextarea
                        id="tools"
                        //name="tools"
                        className="mt-2 p-4 block w-full h-48 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step2.tools.placeholder")}
                        defaultValue={creationPlan?.tools}
                    />
                </div>
                <div className="mt-6 flex justify-between">
                    <button
                        type="submit"
                        className="bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={(e) => handleSubmit('previous', e)}
                        disabled={isLoading}
                    >
                        {t("career.createPlan.step2.buttonPrevious")}
                    </button>
                    <button
                        type="submit"
                        className="bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={(e) => handleSubmit('next', e)}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : t("career.createPlan.step2.buttonNext")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreatePlanContext;
