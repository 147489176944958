import React from 'react';
import { ElementRef, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import { Tv2, Phone, Send, Mail, Coffee, Utensils, BriefcaseBusiness, GlassWater, Aperture, Plus, Pickaxe, LayoutDashboard, Pencil, Edit, Trash, CalendarIcon, SquareUserRound } from "lucide-react";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import { useSelectedContact } from "../../../../../hooks/contact-hook";

import { ConEvent, ConAction } from '../../../../../interfaces/contacts/int-contacts';

import { fetchCreateEvent } from '../../../../../api/contacts/create-event';
import { fetchUpdateEvent } from '../../../../../api/contacts/update-event';

import { cn } from '../../../../../lib/utils';

import ContactTimelineActions from './ContactTimelineActionsOptions';

import { enventTypeComponents } from './ContactTimeline';

import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { toast } from "sonner";

import { Button } from '../../../../../components/ui/button';
import { FormTextarea } from '../../../../../components/form/form-textarea';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../components/ui/popover';
import { Calendar } from '../../../../../components/ui/calendar';
import { format } from "date-fns"
import { useAction } from '../../../../../hooks/use-action';
import { FormInput } from '../../../../../components/form/form-input';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../../../../../components/ui/dropdown-menu';
import { Input } from '../../../../../components/ui/input';


// DebouncedInput component
/*
interface DebouncedInputProps {
    index: number;
    initialValue: string;
    onChange: (index: number, newValue: string) => void;
}

const DebouncedInput: React.FC<DebouncedInputProps> = ({ index, initialValue, onChange }) => {
    const [value, setValue] = useState<string>(initialValue);

    // Debounce the callback
    const debouncedOnChange = debounce(onChange, 500);

    useEffect(() => {
        // Update the internal state when the initial value changes
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setValue(newValue);
        debouncedOnChange(index, newValue);
    };

    return (
        <input
            type="text"
            value={value}
            onChange={handleChange}
            className="text-xs py-1 px-1 my-2 border-none text-muted-foreground"
            placeholder="Action title"
        />
    );
};
*/
/*
// A debounced input react component
function DebouncedInput({
    value: initialValue,
    index,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string;
    index: number;
    //onChange: (value: string | number) => void
    onChange: (index: number, newValue: string) => void;
    debounce?: number;
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue);
  
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(index, value)
      }, debounce)
  
      return () => clearTimeout(timeout)
    }, [index, value, debounce, onChange])
    
    //console.log('Debouncer set to:', value);

    return (
      <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
  }
*/
// Function to sort the actions array
function sortActions(actions: ConAction[]): ConAction[] {
    return actions.sort((a, b) => {
    if (a.deadline_date && b.deadline_date) {
        return new Date(a.deadline_date).getTime() - new Date(b.deadline_date).getTime();
    } else if (a.deadline_date && !b.deadline_date) {
        return -1;
    } else if (!a.deadline_date && b.deadline_date) {
        return 1;
    } else {
        return 0;
    }
    });
}

interface ContactTimelineElementProps {
    key: string;
    contactEvent: ConEvent;
    index: number;
    handleEventDeletion: (eventId: string) => void;
    disableEditing: () => void;
    disableNewEvent: () => void;
    updateContactEvents: (event: ConEvent) => void;
}

const ContactTimelineForm = ({key, contactEvent, index, disableEditing, disableNewEvent, handleEventDeletion, updateContactEvents}: ContactTimelineElementProps) => {
    const formRef = useRef<ElementRef<"form">>(null);

    const selectedContact = useSelectedContact();

    //console.log('contactEvent : ', contactEvent);

    const { execute: executeCreateEvent, fieldErrors: createEventFieldErrors } = useAction(fetchCreateEvent, {
            onSuccess: (data) => {
                //console.log('data - new card :', data);
                updateContactEvents(data);
                disableEditing();
                disableNewEvent();
                toast.success(`Event created`);
                formRef.current?.reset();
            },
            onError: (error) => {
                toast.error(error);
            },
        });

      const { execute: executeUpdateEvent, fieldErrors: updateEventFieldErrors } = useAction(fetchUpdateEvent, {
        onSuccess: (data) => {
            //console.log('data - update event :', data);
            updateContactEvents(data);
            disableEditing();            
            toast.success(`Event updated`);
            formRef.current?.reset();
        },
        onError: (error) => {
          toast.error(error);
        },
      });
    
      

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            disableEditing();
        }
      };
    
    //useOnClickOutside(formRef, disableEditing());
    useEventListener("keydown", onKeyDown); 

    const onTextareakeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          formRef.current?.requestSubmit();
        }
      };

      const onSubmit = (event: React.FormEvent) => { //(formData: FormData) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(formRef.current as HTMLFormElement);
        //console.log('form data : ', formData);
        const title = formData.get('title') as string;
        const description = formData.get('description') as string;
        const type = eventType ? eventType as string : "OTHER";
        const date = eventDate ? eventDate.toISOString() : "";

        //console.log('date : ', date);

        if (contactEvent.id) {
            executeUpdateEvent({
                id: contactEvent.id,
                title, 
                description, 
                type, 
                contact_id: selectedContact || "",
                date,
                location: eventLocation || "",
                actions: eventActions.filter((action) => action.title.trim() !== ''),
            });
        } else {
            executeCreateEvent({
                title, 
                description, 
                type, 
                contact_id: selectedContact || "",
                date,
                location: eventLocation || "",
                actions: eventActions.filter((action) => action.title.trim() !== ''),
            });
        }
        
        //console.log('Form in treatment...');
    };

    // Date management
    const [eventDate, setEventDate] = useState<Date | undefined>(contactEvent.date ? new Date(contactEvent?.date) : undefined);

    const handleDateChange = (date: Date) => {
        //console.log('date : ', date);
        setEventDate(date);
    };

    // Event type management
    const [eventType, setEventType] = useState<string | undefined>(contactEvent.type);

    const handleTypeChange = (type: string) => {
        //console.log('type : ', type);
        setEventType(type);
    };

    // Event location management
    const [eventLocation, setEventLocation] = useState<string | undefined>(contactEvent.location);

    const handleLocationChange = (e: any) => {
        //console.log('location : ', e.target.value);
        setEventLocation(e.target.value);
    };

    // Event actions management
    const [eventActions, setEventActions] = useState<ConAction[]>((contactEvent.actions && contactEvent.actions.length > 0) ? [...sortActions(contactEvent.actions), { title: '' }] : [{title: ''}]);
    // Ensure that dates are correctly handled when changes are made
    const handleActionDateChange = (index: number, date: Date) => {
        handleActionChange(index, { deadline_date: date.toISOString() });
    };

    // Function to update an action
    const handleActionChange = (index: number, changes: any) => {
        const updatedActions = eventActions.map((action, i) => {
            if (i === index) {
                // Correctly apply changes to the matched action
                return { ...action, ...changes} //, event_id: contactEvent.id};
            }
            return action;
        });
        
        // Automatically add a new question field if the last one is being edited
        // Check if the last action is filled before adding a new one
        if (updatedActions.length === 0 || updatedActions[updatedActions.length - 1].title.trim() !== '') {
            updatedActions.push({
                title: '', // Other fields with default empty or null values
            });
        }

        setEventActions(sortActions(updatedActions));
        
    };

    // Function to delete an action
    const handleActionDeletion = (index: number) => {
        //console.log('index : ', index);
        //console.log('eventActions : ', eventActions);
        const updatedActions = eventActions.filter((action, i) => i !== index);
        //console.log('updatedActions : ', updatedActions);
        setEventActions(sortActions(updatedActions));
    };

    return (
        <div className={`group flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} items-stretch align-top`}>
            <div 
                id="part-1" 
                className={`flex-1 px-2 py-2.5 ${index % 2 === 0 ? 'text-right' : 'text-left'}`}
            >
                <span 
                    // Deadline date picker
                    className="flex px-0"
                >
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                //"w-[280px] justify-start text-left font-normal",
                                "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                                !contactEvent.date && "text-muted-foreground"
                            )}
                            >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {eventDate ? format(eventDate, "PPP") : <span>add a date</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                selected={contactEvent.date ? contactEvent.date : undefined}
                                onSelect={(value) => value && handleDateChange(value)}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                </span>

                <div>
                    <Input 
                        id="eventLocation"
                        type='text'
                        onChange={handleLocationChange}
                        value={eventLocation}
                        placeholder='Add location...'
                        className="text-xs py-1 px-1 my-2 border-none text-muted-foreground"
                        //className="text-lg font-bold px-[7px] py-1 h-7 bg-transparent focus-visible:outline-none focus-visible:ring-transparent text-white border-none"
                    />
                </div>

            </div>
            <div id="part-2" className="flex flex-col items-center justify-center p-0 m-0 relative">
                <div className="w-10 h-10 bg-contacts_primary rounded-full flex items-center justify-center">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0 m-0">
                                <span className="sr-only">Open menu</span>
                                {contactEvent && (() => {
                                    const ContactTypeEventIcon = eventType ? enventTypeComponents[eventType] : enventTypeComponents['OTHER'];
                                    return <ContactTypeEventIcon className="flex w-5 h-5" />
                                })()}
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Choose type</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {enventTypeComponents && Object.keys(enventTypeComponents).map((type) => {
                                const ContactTypeEventIcon = type ? enventTypeComponents[type] : enventTypeComponents['OTHER'];
                                return (
                                    <DropdownMenuItem
                                    key={type}
                                    onClick={() => {
                                        handleTypeChange(type);
                                    }}
                                >
                                    <ContactTypeEventIcon className="flex w-5 h-5" />
                                    <p className="ml-2">{type.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}</p>
                                </DropdownMenuItem>
                                )
                            })}
                            <DropdownMenuItem
                                //onClick={() => handleOpenSheet(action.id)}
                            >
                                <SquareUserRound size={16} />
                                <p className="ml-2">View contact details</p>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                
                <div className="w-0.5 bg-gray-400 flex flex-grow"></div>
            </div>
            <div 
                id="part-3" 
                className={`flex-1 px-2 py-2 ${index % 2 === 0 ? 'text-left' : 'text-right'}`}
            >
                <form 
                    ref={formRef}
                    onSubmit={onSubmit}
                >
                    <div className="text-base">
                        <FormInput
                            id="title"
                            placeholder="Enter a title..."
                            defaultValue={contactEvent.title}
                            errors={createEventFieldErrors || updateEventFieldErrors}
                            className="text-base py-1 px-1 border-none text-muted-foreground"
                        />
                    </div>
                    <div className="text-sm">
                        <FormTextarea
                            id="description"
                            onKeyDown={onTextareakeyDown}
                            placeholder="Enter a description..."
                            defaultValue={contactEvent.description}
                            errors={createEventFieldErrors || updateEventFieldErrors}
                            className="text-xs py-1 px-1 my-2 border-none text-muted-foreground"
                        />
                    </div>
                    <div className="py-2">
                        <div className="text-base inline-flex gap-2 items-center"><DirectionsRunIcon className="w-4 h-4" /> Actions</div>
                        <div className="w-full p-0 m-0 flex flex-col">
                            {eventActions && eventActions.map((action: ConAction, index: number) => (
                                <div
                                    key={action.id} 
                                    className={`flex m-0 p-0 text-sm items-center ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
                                >
                                    <div key={index} className="m-0 p-0 my-2">
                                        {/*
                                            <DebouncedInput
                                            index={index}
                                            value={action.title}
                                            onChange={(index, newValue) => handleActionChange(index, { title: newValue })}
                                            className="text-xs py-1 px-1 border-none text-muted-foreground"
                                            placeholder="Action title"
                                        />
                                        */}
                                        <input
                                            type="text"
                                            value={action.title}
                                            onChange={(e) => handleActionChange(index, { title: e.target.value })}
                                            placeholder="Action title"
                                            className="text-xs py-1 px-1 my-2 border-none text-muted-foreground"
                                        />
                                    </div>
                                    <div 
                                        // Deadline date picker
                                        className="flex px-0"
                                    >
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button
                                                variant={"outline"}
                                                className={cn(
                                                    //"w-[280px] justify-start text-left font-normal",
                                                    "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                                                    !contactEvent.date && "text-muted-foreground"
                                                )}
                                                >
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {action.deadline_date ? format(action.deadline_date, "PPP") : <span>add a date</span>}
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar
                                                    mode="single"
                                                    selected={action.deadline_date ? new Date(action.deadline_date) : undefined}
                                                    onSelect={(value) => value && handleActionDateChange(index, value)}
                                                    initialFocus
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    </div>
                                    <div>
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            onClick={() => handleActionDeletion(index)}
                                        >
                                            <Trash className="w-4 h-4" />
                                        </Button>
                                    </div>
                                    {/*
                                        <div className="m-0 p-0 w-6 h-6 items-center flex"><ContactTimelineActions action={action} /></div>
                                    */}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        {contactEvent.id && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => handleEventDeletion(contactEvent.id ?? '')}
                            >
                                <Trash className="w-4 h-4" />
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={() => {
                                //console.log('Cancel button clicked');
                                disableNewEvent();
                                disableEditing();
                            }}
                        >
                            cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className="bg-contacts_primary"
                        >
                            save
                        </Button>
                    </div>
                </form>
            </div>
        </div>
     );
}
 
export default ContactTimelineForm;



/*  --> Archive Input component for actions without debouncing
    <input
        type="text"
        value={action.title}
        onChange={(e) => handleActionChange(index, { title: e.target.value })}
        placeholder="Action title"
        className="text-xs py-1 px-1 my-2 border-none text-muted-foreground"
    />
*/