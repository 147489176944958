import axiosInstance from "../api";

export interface ChannelData {
    id: string;
    name: string;
    image_url: string | null;
}

export const fetchChannels = async (serverId: string): Promise<ChannelData[]> => {
    const response = await axiosInstance.get<ChannelData[]>(`/com-channel/findChannelsByServerByUser/${serverId}`);
    return response.data;
  };

export const fetchGeneralChannel = async (serverId: string): Promise<ChannelData> => {
    const response = await axiosInstance.get<ChannelData>(`/com-channel/general/${serverId}`);
    return response.data;
};