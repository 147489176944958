import { toast } from "sonner";
import { fetchAddingMail, NewsletterDto } from "../../../api/globals/landing/api-newsletter";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const Newsletter = () => {
  

  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire
    const mailDto: NewsletterDto = { mail: email.trim() };
    //const mail = {mail: email};
    //const addingMail = await fetchAddingMail(mailDto);
    try {
      const addingMail = await fetchAddingMail(mailDto);
      if (addingMail) {
        toast.success("Thanks! Let's keep in touch!");
      }
    } catch (error) {
      toast.error("Something went wrong, sorry!");
    }
  };

  return (

    <section id="newsletter">
      <hr className="w-11/12 mx-auto" />

      <div className="container py-24 sm:py-32">
        <h3 className="text-center text-4xl md:text-5xl font-bold">
            {t("landing.newsletter.title")}{" "}
          <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
            Newsletter
          </span>
        </h3>
        <p className="text-xl text-muted-foreground text-center mt-4 mb-8">
            {t("landing.newsletter.description")}
        </p>

        <form
          className="flex flex-col w-full md:flex-row md:w-6/12 lg:w-4/12 mx-auto gap-4 md:gap-2"
          onSubmit={handleSubmit}
        >
          <Input
            placeholder="ludovic.cruchot@gmail.com"
            className="bg-muted/50 dark:bg-muted/80 "
            aria-label="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button 
            variant="landing_full">{t("landing.newsletter.cta")}</Button>
        </form>
      </div>

      <hr className="w-11/12 mx-auto" />
    </section>
  );
};
