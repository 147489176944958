import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../../../components/ui/accordion";

import { useTranslation } from "react-i18next";

  
  interface FAQProps {
    question: string;
    answer: string;
    value: string;
  }
  
  
  
  export const FAQ = () => {

    const { t } = useTranslation();

    const FAQList: FAQProps[] = [
        {
          question: t("landing.faq.question1"),
          answer: t("landing.faq.answer1"),
          value: "item-1",
        },
        {
            question: t("landing.faq.question2"),
            answer: t("landing.faq.answer2"),
            value: "item-2",
        },
        {
            question: t("landing.faq.question3"),
            answer: t("landing.faq.answer3"),
            value: "item-3",
        },
      ];

    return (
      <section
        id="faq"
        className="container pb-48 sm:py-32"
      >
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
            {t("landing.faq.title1")}{" "}
          <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
          {t("landing.faq.title2")}
          </span>
        </h2>
  
        <Accordion
          type="single"
          collapsible
          className="w-full AccordionRoot"
        >
          {FAQList.map(({ question, answer, value }: FAQProps) => (
            <AccordionItem
              key={value}
              value={value}
            >
              <AccordionTrigger className="text-left">
                {question}
              </AccordionTrigger>
  
              <AccordionContent>{answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
  
        <h3 className="font-medium mt-4">
            {t("landing.faq.cta")}{" "}
          <a
            rel="noreferrer noopener"
            href="#"
            className="text-primary transition-all border-primary hover:border-b-2"
          >
            {t("landing.faq.cta2")}{" "}
          </a>
        </h3>
      </section>
    );
  };
  