import { useNavigate } from 'react-router-dom';

import { AccordionContent, AccordionItem, AccordionTrigger } from "../../../../components/ui/accordion";

import { cn } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";

import { useSelectedTab, useTechwatchStoreActions } from "../../../../hooks/techwatch-store";
import { useGeneralHookActions } from '../../../../hooks/general-hook';


interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string; //Organization;
    userMemberships: string[];
    onExpand: (id: string) => void;
}

export const NavItemTechWatch = ({
    isExpanded,
    isActive,
    membership,
    userMemberships,
    onExpand,
}: NavItemProps) => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const { setSelectedTab } = useTechwatchStoreActions();

    // Close the hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();

    const handleSelectedTab = (tab: string) => {
        //console.log("changing Selected tab: ", tab);
        setSelectedTab(tab);
        closeHamburgerMenuSheet();
    };

    const selectedTab = useSelectedTab();

    const availableTabs = [
        //{ key: 'General', label: t("sidebar.techwatch.general") },
        { key: 'Personalized', label: t("sidebar.techwatch.personalized") },
        //{ key: 'Trending-topics', label: t("sidebar.techwatch.trending") },
        { key: 'Browse by category', label: t("sidebar.techwatch.browse") },
        { key: 'Bookmarks', label: t("sidebar.techwatch.bookmarks") },
        { key: 'Profile', label: t("sidebar.techwatch.profile") }
    ];

    return (
        <AccordionItem
            value={membership} //organization.id
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand(membership)} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-gray-700 dark:text-gray-200 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        {membership}
                    </span>
                </div>
            </AccordionTrigger>
            <AccordionContent className="pt-1 text-neutral-700">
                {availableTabs.map((tab) => (
                    <Button
                        key={tab.key}
                        size="sm"
                        //onClick={() => onClick(board.id as string)}
                        //onClick={() => setSelectedBoard(membership)}
                        onClick={() => handleSelectedTab(tab.key)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4",
                            selectedTab === tab.key && "bg-[#DDEBFE] dark:bg-[#11325B] text-techwatch_primary"
                            )}
                        variant="ghost"
                    >
                        <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-techwatch_primary dark:hover:text-techwatch_complementary">{tab.label} </span>
                    </Button>
                ))}
            </AccordionContent>
        </AccordionItem>
    );
};

NavItemTechWatch.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}