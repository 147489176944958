import { useState, useEffect, useRef } from 'react';
import { fetchEditPlan } from '../../../../api/career/api-create-plan';
import { useCareerHookActions, useCreationPlan, useSelectedPath } from '../../../../hooks/career-hook';
import { Trash, Rocket, ArrowLeft, ArrowRight } from 'lucide-react';
import { Input } from '../../../../components/ui/input';
import { ActionTooltip } from '../../../../components/action-tooltip';
import { Button } from '../../../../components/ui/button';
import { FormTextarea } from '../../../../components/form/form-textarea';
import { PlanElement } from '../../../../api/career/interface-career';
import icon_year_1 from "../../../../img/career_icons/year_1.png"
import icon_year_2 from "../../../../img/career_icons/year_2.png"
import icon_year_3 from "../../../../img/career_icons/year_3.png"
import icon_year_4 from "../../../../img/career_icons/year_4.png"
import icon_year_5 from "../../../../img/career_icons/year_5.png"
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next'; 

const CreatePlanSteps: React.FC<{ detailedPlanAPICall: (inputPlanElements: PlanElement[]) => void }> = ({ detailedPlanAPICall }) => {
    const { t } = useTranslation();
    const { getPlanElementsMainSteps, setCreationPlan } = useCareerHookActions();
    const [planMainSteps, setPlanMainSteps] = useState<PlanElement[]>([]);
    const creationPlan = useCreationPlan();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const selectedPath = useSelectedPath();
    const { setSelectedPlan, setPlanElements } = useCareerHookActions();
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef<Slider>(null);

    useEffect(() => {
        const fetchAndSortPlanSteps = () => {
            let fetchedPlanSteps = getPlanElementsMainSteps();
            fetchedPlanSteps = fetchedPlanSteps && fetchedPlanSteps.sort((a, b) => a.year - b.year);
            const preparedPlanSteps = fetchedPlanSteps && fetchedPlanSteps.map(step => ({
                ...step,
                key_results: step.key_results && step.key_results.length ? step.key_results : [{ id: `${step.id}-kr0`, title: '' }]
            }));
            preparedPlanSteps && setPlanMainSteps(preparedPlanSteps);
        };

        fetchAndSortPlanSteps();
    }, []);

    const handleKeyResultChange = (stepId: string, keyResultIndex: number, newValue: string) => {
        setPlanMainSteps(prevSteps =>
            prevSteps.map(step =>
                step.id === stepId ? {
                    ...step,
                    key_results: step.key_results && step.key_results.map((kr, index) =>
                        index === keyResultIndex ? { ...kr, title: newValue } : kr
                    ).concat(keyResultIndex === step.key_results.length - 1 && newValue.trim() !== '' ? [{ id: `new-kr-${step.id}-${step.key_results.length}`, title: '' }] : [])
                } : step
            )
        );
    };

    const handleRemoveKeyResult = (stepId: string, keyResultIndex: number) => {
        setPlanMainSteps(prevSteps =>
            prevSteps.map(step =>
                step.id === stepId ? {
                    ...step,
                    key_results: step.key_results && step.key_results.filter((_, index) => index !== keyResultIndex)
                } : step
            )
        );
    };

    const icons : Record<number, typeof icon_year_1>= {
        1: icon_year_1,
        2: icon_year_2,
        3: icon_year_3,
        4: icon_year_4,
        5: icon_year_5,
      };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setLoading(true);
        e.preventDefault();
        const form = e.currentTarget.form;
        const formData = new FormData(form!);
        //const formData = new FormData(e.currentTarget);
        const title = formData.get("title") as string;
        const description = formData.get("description") as string;
        const proposed_title = formData.get("proposed_title") as string;
        const newStep = creationPlan?.current_step && (creationPlan?.current_step +1);
    
        const creationPlanUpdate = {
            ...creationPlan,
            title: title,
            description: description,
            proposed_title: proposed_title,
            current_step: newStep,
        };
    
        try {
            const responsePlan = creationPlan?.id && await fetchEditPlan(creationPlan?.id, creationPlanUpdate);
            //console.log('responsePlan:', responsePlan);
    
            if (responsePlan) {
                setPlanElements(planMainSteps);
                setCreationPlan(responsePlan);
            }

            // Launch Detailed plan calculation
            detailedPlanAPICall(planMainSteps);

            //onNextStep(planMainSteps); // Trigger the next step here
        } catch (error) {
            //console.error('An error occurred while updating the plan:', error);
            setError('An error occurred while updating the plan.');
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    };

    const CustomPrevArrow = ({ onClick, currentSlide }: { onClick?: () => void, currentSlide?: number }) => (
        currentSlide !== 0 ? (
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer hidden sm:block" onClick={onClick}>
                <ArrowLeft className="w-8 h-8 text-[#AE3F51] dark:text-[#FF95A0]" />
            </div>
        ) : null
    );

    const CustomNextArrow = ({ onClick, slideCount, currentSlide }: { onClick?: () => void, slideCount?: number, currentSlide?: number }) => (
        currentSlide !== (slideCount! - 1) ? (
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer hidden sm:block" onClick={onClick}>
                <ArrowRight className="w-8 h-8 text-[#AE3F51] dark:text-[#FF95A0]" />
            </div>
        ) : null
    );

    const handleIconClick = (index: number) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index + 1); // +1 to account for the summary slide
        }
    };

    // Slider settings
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: false,
        //autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        beforeChange: (current: number, next: number) => setCurrentSlide(next),
    };

    return (
        <div className="flex flex-col flex-grow w-full space-y-4 overflow-auto">
            <div id="congrats" className='flex-col flex-grow w-full text-center'>
                <div className='flex flex-col items-center justify-center'>
                    <Rocket className="text-[#AE3F51] dark:text-[#FF95A0] w-8 h-8 md:w-12 md:h-12 mb-2" />
                    <h2 className="px-4 text-2xl font-semibold text-center text-[#AE3F51] dark:text-[#FF95A0] mb-2">
                        {t("career.createPlan.step3.top")}
                    </h2>
                </div>
                <p className="text-sm md:text-base text-[#AE3F51] dark:text-[#FF95A0]">
                    {t("career.createPlan.step3.topDescription")}
                </p>
            </div>
            <form id="creationPlanSteps">
                <div id='global_objectives' className='max-w-xl md:max-w-2xl lg:max-w-3xl mx-auto'>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step3.title.title")}</label>
                        <Input
                            type="text"
                            id="title"
                            name="title"
                            className="shadow border rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                            placeholder="Example: Transitioning to AI Specialist in Tech"
                            value={creationPlan?.title}
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="description" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step3.description.title")}</label>
                        <FormTextarea
                            id="description"
                            className="mt-2 p-4 block w-full h-24 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                            placeholder="Example: Advance from a software developer to an AI specialist by acquiring necessary skills through courses and real-world projects, aiming to lead AI initiatives in a tech firm."
                            defaultValue={creationPlan?.description}
                            //onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="relative">
                    <Slider ref={sliderRef}  {...sliderSettings}>
                        <div className='flex flex-col justify-center items-center space-y-4'>
                            <h3 className="text-lg text-center font-semibold justify-center text-[#AE3F51] dark:text-[#FF95A0]">{t("career.createPlan.step3.summary.title")}</h3>
                            <div className="grid grid-cols-2 sm:grid-cols-5 gap-4">
                                {planMainSteps.map((step, index) => (
                                    <div key={step.id} className='flex flex-col items-center cursor-pointer' onClick={() => handleIconClick(index)}>
                                        <Avatar className="rounded-full w-12 h-12 object-cover">
                                            <AvatarImage src={icons[step.year]} />
                                            <AvatarFallback>{`Year ${step.year}`}</AvatarFallback>
                                        </Avatar>
                                        <span className="mt-2 text-sm font-medium italic text-gray-700 dark:text-gray-300 text-center">{step.title}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {planMainSteps.map((step) => (
                            <div key={step.id} className='flex flex-col items-center space-y-4'>
                                <div className="flex items-center justify-center space-x-2 mb-4">
                                    <span className="text-lg font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{`YEAR`}</span>
                                    <div className="w-12 h-12">
                                        <Avatar className="rounded-full w-full h-full object-cover">
                                            <AvatarImage src={icons[step.year]} />
                                            <AvatarFallback>{`Year ${step.year}`}</AvatarFallback>
                                        </Avatar>
                                    </div>
                                    <span className="text-lg font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{step.year === 1 ? "ONE" : step.year === 2 ? "TWO": step.year === 3 ? "THREE" : step.year === 4 ? "FOUR" : "FIVE"}</span>
                                </div>
                                <div className="max-w-xl md:max-w-2xl lg:max-w-3xl mx-auto space-y-4">
                                    <div className="mb-4">
                                        <label htmlFor="step_title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step3.year.title")}</label>
                                        <FormTextarea 
                                            id={`step_title_${step.id}`}
                                            className="shadow border rounded h-18 w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                            defaultValue={step.title}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="step_description" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step3.year.description")}</label>
                                        <FormTextarea 
                                            id={`step_title_${step.id}`}
                                            className="shadow border rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                            defaultValue={step.description}
                                        />
                                    </div>
                                    <div className='space-y-2'>
                                        {step.key_results && step.key_results.map((keyResult, keyResultIndex) => (
                                            <div key={keyResult.id} className="relative w-full my-2">
                                                <Input 
                                                    id={`key_result${keyResult.id}`}
                                                    type="text"
                                                    className="shadow border rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                                    value={keyResult.title}
                                                    onChange={(e) =>  handleKeyResultChange(step.id, keyResultIndex, e.target.value)}
                                                    placeholder={t("career.createPlan.step3.year.keyResult")}
                                                />
                                                {keyResultIndex !== 0 && (
                                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                                        <ActionTooltip label="Remove">
                                                            <Trash
                                                                onClick={() => handleRemoveKeyResult(step.id, keyResultIndex)}
                                                                className="cursor-pointer w-4 h-4 text-[#AE3F51] dark:text-[#FF95A0] dark:hover:text-zinc-300 transition"
                                                            />
                                                        </ActionTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                {currentSlide !== 0 && (
                    <div id="finalizePlan" className='justify-items-center mt-6'>
                        <Button
                            type="submit"
                            className="justify-items-center w-full bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={(e) => handleSubmit(e)}
                            disabled={loading}
                        >
                            {t("career.createPlan.step3.buttonNext")}
                        </Button>
                    </div>
                )}
            </form>            
        </div>
    );
};

export default CreatePlanSteps;
