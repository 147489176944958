import {
    Cloud,
    CreditCard,
    Github,
    Keyboard,
    LifeBuoy,
    LogOut,
    Mail,
    MessageSquare,
    Plus,
    PlusCircle,
    Settings,
    User,
    UserPlus,
    Users,
  } from "lucide-react"
   
  import { Button } from "../../../../components/ui/button"
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
  } from "../../../../components/ui/dropdown-menu"
  
import { toast } from "sonner";

import { useBoards, useSelectedBoardElement, useTaskStoreActions } from "../../../../hooks/tasks-store";
import { useAction } from "../../../../hooks/use-action";

import { moveCard } from "../../../../api/tasks/move-card";


interface MoveTaskBoardListProps {
    id?: string;
  };

const MoveTaskBoardList = ({
    id
}: MoveTaskBoardListProps) => {

    const { updateTasks, createOrUpdateCard } = useTaskStoreActions();

    const boards = useBoards();

    const selectedBoardElement = useSelectedBoardElement();

    const { execute } = useAction(moveCard, {
        onSuccess: (data) => {
            //createOrUpdateCard(data); => TO DO : update cards in the list when refactor lists
            //updateTasks([data]);
            if (selectedBoardElement?.display_mode === "TABLE") {
                updateTasks([data]);
            } else {
                createOrUpdateCard(data);
            }
            toast.success(`Card "${data.title}" moved`);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const handleClick = (board_id: string, list_id: string) => () => {
        execute({
            id: id as string,
            parent_elt: board_id,
            list_id: list_id
        });
    };

    return ( 
        <div className="h-full w-full items-center justify-center">
            <DropdownMenu>
                <DropdownMenuTrigger 
                    asChild 
                    disabled={!id}
                    //className="flex-grow flex justify-center items-center text-xs bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4 md:mb-2 sm:w-1/2"
                >
                    <Button 
                        variant="task"
                        className="w-full justify-start"
                        //className="flex-grow flex justify-center items-center text-xs bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4 md:mb-2 sm:w-1/2"
                        size="sm"
                    >
                        <LogOut className="h-3 w-3" />
                        Move card
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 max-h-64 overflow-y-auto">
                    <DropdownMenuLabel>Move card</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                    
                        {boards && boards.filter((board) => board.title !== "Overview").map((board, index) => (
                            <DropdownMenuSub
                                key={index}
                            >
                                <DropdownMenuSubTrigger>
                                    <span>{board.title}</span>
                                </DropdownMenuSubTrigger>
                                <DropdownMenuPortal>
                                    <DropdownMenuSubContent className="max-h-48 overflow-y-auto" >
                                        {board.lists && board.lists.map((list, index) => (
                                            <DropdownMenuItem 
                                                key={index}
                                                onClick={handleClick(board.id, list.id)}
                                            >
                                                <LogOut className="mr-2 h-4 w-4" />
                                                <span>{list.title}</span>
                                            </DropdownMenuItem>
                                        ))}               
                                    </DropdownMenuSubContent>
                                </DropdownMenuPortal>
                            </DropdownMenuSub>
                        ))}
                        
                    </DropdownMenuGroup>
                    
                </DropdownMenuContent>
                </DropdownMenu>
        </div>
     );
}
 
export default MoveTaskBoardList;