import { InputType, ReturnType } from "./types";
//import { db } from "@/lib/db";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { UpdateListOrder } from "./schema";

import axiosInstance from "../../api";
import { ListWithCards } from "../../../interfaces/tasks/int-types";
import { List } from "../../../interfaces/tasks/int-list";

const handler = async (data: InputType): Promise<ReturnType> => {
    //console.log('data update list order API call :', data);
    const response = await axiosInstance.patch<List[]>(`/lists/updateListOrder`, data).catch((error) => {
        //console.error('API call failed:', error);
        throw error; // Re-throw to ensure it's also caught by the outer try/catch
    });
    return { data: response.data };
};

export const updateListOrder = createSafeAction(UpdateListOrder, handler);
