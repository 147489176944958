import { AlignLeft } from "lucide-react";
import { ElementRef, useRef, useState } from "react";
import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { toast } from "sonner";

import { FormSubmit } from "../../../../components/form/form-submit";
import { FormTextarea } from "../../../../components/form/form-textarea";
import { Button } from "../../../../components/ui/button";
import { Skeleton } from "../../../../components/ui/skeleton";

import { useAction } from "../../../../hooks/use-action";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { useCareerStoreAll } from "../../../../hooks/career-store";

import { updatePlanElement } from "../../../../api/career/update-plan-element";
import { PlanElement } from "../../../../api/career/interface-career";

interface DescriptionProps {
    data:  PlanElement;
}

export const Description = ({
    data
}: DescriptionProps) => {
  
    //console.log('data description in component :', data.description);
    
    const { getPlanElement } = useCareerHookActions();
    const selectedElement: PlanElement | undefined = data.id ? getPlanElement(data.id) : undefined;

    const [isEditing, setIsEditing] = useState(false);

    const textarea = useRef<ElementRef<"textarea">>(null);
    
    const formRef = useRef<ElementRef<"form">>(null);
    const textareaRef = useRef<ElementRef<"textarea">>(null);

    const enableEditing = () => {
        setIsEditing(true);
        setTimeout(() => {
          textareaRef.current?.focus();
        });
      };

      const disableEditing = () => {
        setIsEditing(false);
      };
      
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          disableEditing();
        }
      };
    
      useEventListener("keydown", onKeyDown);
      useOnClickOutside(formRef, disableEditing);

      const { updatePlanElementHook } = useCareerHookActions();
      const { updateNode } = useCareerStoreAll();

      const { execute, fieldErrors } = useAction(updatePlanElement, {
        onSuccess: (data) => {
            updatePlanElementHook(data);
            updateNode(data);
            toast.success(`Card "${data.title}" updated`);
            toast.success(`Renamed to "${data.description}"`);
            disableEditing();
        },
        onError: (error) => {
            toast.error(error);
        }
      })

      const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const description = formData.get("description") as string;

        //execute
        execute({
            description,
            id: data.id,
            plan_id: data.plan_id,
        })
      }


    return (
        
        <div className="flex flex-col items-start gap-x-3 w-full">
            <div className="flex flex-row gap-x-3 text-neutral-700 dark:text-neutral-500">
                <AlignLeft className="h-5 w-5 mt-0.5" />
                <p className="font-semibold mb-2">
                    Description
                </p>
            </div>
            
            {isEditing ? (
                <form
                    onSubmit={onSubmit}
                    ref={formRef}
                    className="space-y-2 w-5/6 ml-6 "
                >
                    <FormTextarea 
                        id="description"
                        className="w-full mt-2"
                        placeholder="Add a more detailed description"
                        defaultValue={selectedElement?.description || undefined}
                        errors={fieldErrors}
                        ref={textareaRef}
                    />
                    <div className="flex items-center gap-x-2">
                        <FormSubmit>
                            Save
                        </FormSubmit>
                        <Button
                            type="button"
                            onClick={disableEditing}
                            size="sm"
                            variant="ghost"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            ) : (
                <div
                    onClick={enableEditing}
                    role="button"
                    className="w-5/6 min-h-[78px] ml-6 bg-neutral-200 dark:bg-background dark:hover:border-career_primary dark:hover:border-[1px] text-sm font-medium py-3 px-3.5 rounded-md"
                >
                    {selectedElement?.description || "Add a more detailed description..."}
                </div>
            )}
        </div>
    );
};

Description.Skeleton = function DescriptionSkeleton() {
    return (
        <div className="flex items-start gap-x-3 w-full">
        <Skeleton className="h-6 w-6 bg-neutral-200" />
        <div className="w-full">
            <Skeleton className="w-24 h-6 mb-2 bg-neutral-200" />
            <Skeleton className="w-full h-[78px] bg-neutral-200" />
        </div>
        </div>
    );
};