import { useCommunityActions, useSelectedChannel, useSelectedServer } from "../../../../hooks/community-hook";
import MainChannel from "../main/main-channel";
import { useWebSocket } from "../../../../hooks/socket-context";
import { useEffect, useState } from "react";
import { GetMember, fetchGetCurrentMember } from "../../../../api/community/api-members";
import { SidebarGeneral } from "../../../../components/Sidebar-general";
import { cn } from "../../../../lib/utils";

const ComChannels = () => {

    const { joinRoom, leaveRoom } = useWebSocket();
    const selectedServer = useSelectedServer();
    const selectedChannel = useSelectedChannel();
    const { setSelectedCurrentMember, clearMessages } = useCommunityActions();
    const [currentMember, setCurrentMemmber] = useState<GetMember>();

    useEffect(() => {
        if (selectedChannel) {
          joinRoom(selectedChannel.id);
          return () => {
            leaveRoom(selectedChannel.id);
            clearMessages();
          };
        }
      }, [selectedChannel, joinRoom, leaveRoom]);

    useEffect(() => {
        const loadCurrentMember = async () => {
            if(selectedChannel) {
                const response = await fetchGetCurrentMember(selectedChannel.id);
                setCurrentMemmber(response);
                setSelectedCurrentMember({ id: response.memberId, role: response.memberRole });
            }
        };
        loadCurrentMember();

        return () => {
            setCurrentMemmber(undefined);
            setSelectedCurrentMember(undefined);
        };
        
    }, [selectedChannel]);
    

    return (
        <div className="flex flex-grow h-full w-full">
            <div className="hidden md:flex md:flex-col w-2/12 h-full px-1 py-1 dark:bg-[#1E1F22]">
                <SidebarGeneral />
            </div>
            <div 
                //className="bg-white dark:bg-[#313338] flex-grow flex flex-col p-2 w-10/12 overflow-auto"
                className={cn("bg-white dark:bg-[#313338] flex-grow flex flex-col p-2 w-10/12",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            > 
                {selectedChannel && selectedServer && (
                    <MainChannel
                        params={{
                            serverId: selectedServer.id,
                            channelId: selectedChannel.id,
                            channelName: selectedChannel.name,
                            serverName: selectedServer.name,
                        }}
                    />
                )}
            </div>
        </div>
    );
}
 
export default ComChannels;