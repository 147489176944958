import React, { useState } from 'react';
import StepTitleDescription from './create-plan-title';
import StepContext from './create-plan-context';
import StepQuestionsMotivations from './create-plan-questions';
import { FirstPromptAPI, InputSecondPromptAPI, PlanElement } from '../../../../api/career/interface-career';
import { Progress } from '../../../../components/ui/progress';
import { useCareerHookActions, useCreationPlan, useSelectedPath, useStepCreation } from '../../../../hooks/career-hook';
import CreatePlanSteps from './create-plan-steps';

import { fetchFinalizePlanSecondRequest } from '../../../../api/career/api-create-plan';



interface CreatePlanProcessProps {
    onNext: (data: FirstPromptAPI) => void; 
}
  
const CreatePlanProcess: React.FC<CreatePlanProcessProps> = ({ onNext }) => {
    const currentStep = useStepCreation();
    const { setStepCreation } = useCareerHookActions();
    const creationPlan = useCreationPlan();
    const [planElements, setPlanElements] = useState<PlanElement[]>([]);

    const nextStep = () => currentStep && setStepCreation(currentStep + 1);
    const prevStep = () => currentStep && setStepCreation(currentStep - 1);

    const handleNextStepAfterPlanSteps = (elements: PlanElement[]) => {
        //console.log("handleNextStepAfterPlanSteps BEFORE", planElements);
        setPlanElements(elements);
        //console.log("handleNextStepAfterPlanSteps AFTER", planElements);
        nextStep();
    };

    const [ detailedPlanCallIsLoading, setDetailedPlanCallIsLoading ] = useState<boolean>(false);
    const selectedPath = useSelectedPath();
    const { updatePlan } = useCareerHookActions();

    const detailedPlanAPICall = (inputPlanElements: PlanElement[]) => {
        setDetailedPlanCallIsLoading(true);

        const inputData: InputSecondPromptAPI = {
            global_title: creationPlan?.proposed_title ? creationPlan?.proposed_title : creationPlan?.title || "",
            year_1: { objective: '', description: '', keyResults: [] },
            year_2: { objective: '', description: '', keyResults: [] },
            year_3: { objective: '', description: '', keyResults: [] },
            year_4: { objective: '', description: '', keyResults: [] },
            year_5: { objective: '', description: '', keyResults: [] },
        };

        // Populate each year's data from planMainSteps
        inputPlanElements.forEach(step => {
            if (step.year >= 1 && step.year <= 5) {
                const yearKey = `year_${step.year}` as keyof Omit<InputSecondPromptAPI, 'global_title'>;
                inputData[yearKey].objective = step.title;
                inputData[yearKey].description = step.description || "";
                inputData[yearKey].keyResults = step.key_results?.map(kr => kr.title) || [];
            }
        });

        const fetchPlanDetails = async () => {
            try {
                const responseElements = await fetchFinalizePlanSecondRequest(selectedPath as "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER", inputData);
                //console.log('responseElements:', responseElements);
                if (responseElements) {
                    updatePlan(responseElements);
                }
            } catch (error) {
                //console.error('An error occurred while fetching plan details:', error);
            } finally {
                setDetailedPlanCallIsLoading(false);
            }
        };

        fetchPlanDetails();
    };

    return (
        <div className='flex flex-col overflow-x-auto items-center justify-center'>
            {creationPlan?.current_step === 1 && <StepTitleDescription />}
            {creationPlan?.current_step === 2 && <StepContext onNext={nextStep} />}
            {creationPlan?.current_step === 3 && <CreatePlanSteps detailedPlanAPICall={detailedPlanAPICall} />}
            {creationPlan?.current_step === 4 && <StepQuestionsMotivations planElements={planElements} onNext={nextStep} detailedPlanCallIsLoading={detailedPlanCallIsLoading} />}
        </div>
    );
};

export default CreatePlanProcess;
