import { useEffect, useState } from "react";

import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import signInImg from '../../../img/Sign-in.svg'

import { toast } from "../../../components/ui/use-toast"
import { useActions, useIsAuthenticated } from "../../../hooks/auth-store";

import { userResetPWDSchema, UserSchema } from "../../../api/globals/reset-password/schema";
import { fetchRPCheckToken } from "../../../api/globals/reset-password/api-reset-password";
import { fetchResetPassword } from "../../../api/globals/reset-password";
import Error404 from "../../../Error";
import { AlertCircle } from "lucide-react";
import { Button } from "../../../components/ui/button";

interface MyData {
    // Définissez votre structure ici
  }

const ResetPassword = () => {

    const { register, handleSubmit, formState: { errors }, watch } = useForm<UserSchema>({
        resolver: zodResolver(userResetPWDSchema),
        defaultValues: {
          token: '',
          password: '',
          confirm_password: '',
        }
      });
    
    // get token from URL
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [validToken, setValidToken] = useState<Boolean | undefined>(undefined);

    useEffect(() => {
        
        const verifyToken = async () => {
            try {
                if(token) {
                    const returnData = await fetchRPCheckToken(token);
                    //console.log("returnData: ", returnData);
                    //console.log('type returnData: ', typeof returnData);
                    //console.log("returnData.message: ", returnData.message);
                    if (returnData.message === "Token is valid") {
                        setValidToken(true);
                    } else {
                        setValidToken(false);
                    }
                }
            } catch (err) {
                console.error("Invalid or expired token: ", err);
            }
        };

        const token = searchParams.get('token') || '';
        //console.log('token: ', token);
        
        verifyToken();

    }, []);

    const token = searchParams.get('token');

    // handle submit state variables
    const [data, setData] = useState<MyData>({} as MyData);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Check if user is authenticated and redirection
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();

    if (isAuthenticated) {
        return <Navigate to="/techwatch" />;
    }

    if (!token || validToken === false) {
        return (
            <div className="flex justify-center items-start w-full p-4">
                <div className="flex flex-col items-center justify-center">
                    <AlertCircle size={64} />
                    <h3 className="text-xl font-bold text-career_primary dark:text-[#FF95A0]">Your reset token is expired, please ask for a new link again</h3>
                    <Button onClick={() => navigate(`/signin`)} className="mt-4 bg-career_primary hover:bg-[#AE3F51] hover:text-[#FFBFC4] font-bold py-2 px-4 rounded-lg shadow-lg">
                        Go to the sign in page
                    </Button>
                </div>
            </div>
        );
    }

    const onSubmit = async (data: UserSchema) => {

        setLoading(true);
        
        const resetPasswordData = {
            ...data,
            token: token || '',
        };

        //console.log('resetPasswordData : ', resetPasswordData);

        try {
            const response = await fetchResetPassword(resetPasswordData);
            setLoading(false);
            toast({
                title: "Votre mot de passe a été réinitialisé avec succès",
            });
            navigate('/signin');
        } catch (err:any) {
            //console.log('Error verifying password or expired / wrong token: ', err);
            setError(err.message);
            setLoading(false);
            toast({
                title: "Nous n'avons pas pu réinitialiser votre mot de passe",
            });
            setValidToken(false);
        }
    };


    return (
        <div className="flex flex-col md:flex-row min-h-screen flex-grow bg-[#F8F5F5] dark:bg-[#1E1F24]">
        {/* Top bar for small screens */}
        <div className="md:hidden bg-gradient-to-r from-[#F8F5F5] to-[#55AA98] dark:from-[#1E1F24] dark:to-[#78CDB9] p-4 text-white flex justify-between items-center">
            <h3 className="text-md font-bold text-neutral_grey-500">Join Us Today</h3>
            <button onClick={() => navigate(`/signup`)} className="bg-neutral_white-200 text-contacts_primary dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded">
                Sign Up
            </button>
        </div>
        {/* Image Panel */}
        <div className="hidden md:flex w-full md:w-1/4 items-center justify-center bg-[#F8F5F5] dark:bg-[#1E1F24]">
            <img src={signInImg} alt='SignIn' className="w-full h-auto object-cover"/>
        </div>

        <div className="w-full md:w-1/2 flex-col items-center justify-center p-6 bg-[#F8F5F5] dark:bg-[#1E1F24]">
            <h1 className="text-center text-2xl font-extrabold text-contacts_primary dark:text-[#78CDB9]">Welcome back !</h1>
            <h2 className="text-center text-xl font-semibold justify-between items-center mb-4 text-[#107362] dark:text-[#BCECDF]">Sign into your account</h2>
    
            <div className="flex flex-grow h-full w-full">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-1 sm:mx-auto sm:w-full sm:max-w-md pb-3">
                        <div className="pb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Password</label>
                            <input
                                type="password"
                                id="password"
                                {...register('password')}
                                placeholder="type your password"
                                className="mt-1 rounded block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 sm:text-sm"
                            />
                            {errors.password && <p className="text-warning_red text-sm">{errors.password.message}</p>}
                        </div>
                        <div className="pb-4">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                {...register('confirm_password')}
                                placeholder="confirm your password"
                                className="mt-1 rounded block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 sm:text-sm"
                            />
                            {errors.confirm_password && <p className="text-warning_red text-sm">{errors.confirm_password.message}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-[#C9E9E0] text-[#107362] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:bg-[#2D544B] dark:text-[#78CDB9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1A3C34]"
                        >
                        Sign Up
                        </button>
                    </form>
                )}
            </div>
        </div>


        {/* Right Panel for Background and Information */}
        <div className="hidden md:flex w-full md:w-1/4 items-center justify-center bg-gradient-to-r from-[#F8F5F5] to-[#55AA98] dark:from-[#1E1F24] dark:to-[#78CDB9] p-10 text-white">
            <div className="max-w-md mx-auto">
                <h2 className="text-xl font-bold mb-3">Join Us Today</h2>
                <p className="mb-5 text-md">Create an account to get started with our services.</p>
                <button onClick={() => navigate(`/signup`)} className="mt-4 bg-neutral_white-200 text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] font-bold py-2 px-4 rounded-lg shadow-lg">
                    Sign Up
                </button>
            </div>
        </div>
        </div>
    );
}
 
export default ResetPassword;