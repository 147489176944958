import { 
    ShieldAlert, 
    ShieldCheck,
  } from "lucide-react";
import { useEffect, useState } from "react";
  
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
  } from "../../../../components/ui/dialog";
import { useModal } from "./use-modal-store";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { Channel } from "../../../../api/community/interface-community";
import { useSelectedChannel } from "../../../../hooks/community-hook";
import { GetMembers, fetchGetMembersFromChannel } from "../../../../api/community/api-members";
import { UserAvatar } from "../user-avatar";
import { useTranslation } from 'react-i18next';
import icon_fr from "../../../../img/channels/france.png"
  
  const roleIconMap = {
    "GUEST": null,
    "MODERATOR": <ShieldCheck className="h-4 w-4 ml-2 text-community_complementary" />,
    "ADMIN": <ShieldAlert className="h-4 w-4 text-community_primary" />
  }
  
export const ViewMembersModal = () => {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose, type, data } = useModal();
  const selectedChannel = useSelectedChannel();

  const isModalOpen = isOpen && type === "viewMembers";
  const { channel } = data as { channel : Channel }; 

  const [membersFromChannel, setGetMembers] = useState<GetMembers[]>([]);
  
  useEffect(() => {
    const fetchMembers = async () => {
      if (selectedChannel?.id) {
        try {
          const response = await fetchGetMembersFromChannel(selectedChannel.id);
          setGetMembers(response);
        } catch (error) {
          //console.log("error", error);
        }
      }
    };
  
    fetchMembers();
  }, [selectedChannel?.id]); // Exécute cet effet chaque fois que l'ID du canal sélectionné change
    
  
  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
            {t(`community.modals.viewMembers.title`)}
          </DialogTitle>
          <DialogDescription 
            className="text-center text-[#5D184F] dark:text-[#F8D3ED]"
          >
            {membersFromChannel?.length} {t(`community.modals.viewMembers.description`)}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
            {membersFromChannel?.map((member) => (
            <div key={member.id} className="flex items-center gap-x-2 mb-6">
                <UserAvatar src={`${process.env.REACT_APP_BACKEND}/user/${member.image_url}`}/>
                <div className="flex flex-col gap-y-1">
                <div className="text-sm font-semibold flex items-center gap-x-1">
                    {member.user_name}
                    {roleIconMap[member.role]}
                </div>
                </div>
            </div>
            ))}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}