import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card" //"../../components/ui/card";
import { MedalIcon, MapIcon, PlaneIcon, GiftIcon, ListCheckIcon } from "./Icons";
import { useTranslation } from "react-i18next";

import image_listCheck from "../../../img/landing/main_landing/list-check.svg"; 

interface FeatureProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

export const HowItWorks = () => {

  const { t } = useTranslation();

  const features: FeatureProps[] = [
    {
      icon: <MedalIcon />,
      title: t("landing.how.item1_title"),
      description:
        t("landing.how.item1_description"),
    },
    {
      icon: <MapIcon />,
      title: t("landing.how.item2_title"),
      description:
        t("landing.how.item2_description"),
    },
    {
      icon: <PlaneIcon />,
      title: t("landing.how.item3_title"),
      description:
        t("landing.how.item3_description"),
    },
    {
      icon: <ListCheckIcon />,// <img src={image_listCheck} alt="About feature" className="w-[200px] lg:w-[300px] mx-auto" />,//<ListCheckIcon />,
      title: t("landing.how.item4_title"),
      description:
        t("landing.how.item4_description"),
    },
  ];

  return (
    <section
      id="howItWorks"
      className="container text-center py-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold ">
      {t("landing.how.title1")}
      {" "}
        <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
        {t("landing.how.title2")}
        {" "}
        </span>
        {t("landing.how.title3")}
      </h2>
      <p className="md:w-3/4 mx-auto mt-4 mb-8 text-xl text-muted-foreground">
        {t("landing.how.description")}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {features.map(({ icon, title, description }: FeatureProps) => (
          <Card
            key={title}
            className="bg-muted/50"
          >
            <CardHeader>
              <CardTitle className="grid gap-4 place-items-center">
                {icon}
                {title}
              </CardTitle>
            </CardHeader>
            <CardContent>{description}</CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};
