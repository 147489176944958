import { useNavigate } from "react-router-dom";
import { fetchDeletetNotification } from "../api/notifications/api-notifications";
import { ActionTooltip } from "./action-tooltip";
import { Trash } from "lucide-react";
import { getGeneralHookStoreActions } from "../hooks/general-hook";
import { toast } from "sonner";

interface NotifItemProps {
    id: string;
    content: string;
    timestamp: Date;
    toShow: boolean;
    viewed: boolean;
    feature: string;
    accept: boolean;
    cancel: boolean;
    done: boolean;
    type: number;
    channel_id: string | undefined;
    con_action_id : string | undefined,
    tas_elt_id: string | undefined;
    car_elt_id: string | undefined;
    onActionTaken: () => void;
}

const NotifItem = ({
    id,
    content,
    timestamp,
    toShow,
    viewed,
    feature,
    accept,
    cancel,
    done,
    type,
    channel_id,
    tas_elt_id,
    car_elt_id,
    onActionTaken
}: NotifItemProps) => {

    const navigate = useNavigate();
    // Event handlers
    const handleAction = async (actionType: string) => {
        const newAction = await fetchDeletetNotification(id, { action: actionType });
        const { deleteNotification } = getGeneralHookStoreActions();
        deleteNotification(id);
        //if (actionType === "toDelete") {} //On peut décider de garder les notifs si on accepte mais il faut un peu de rework, aujorud'hui cela supprime la notif
        onActionTaken();
        toast(newAction);
    };

    const contentClass = viewed ? "" : "font-semibold";

    const featureClasses: { [key: string]: string } = {
        career: "text-career_primary dark:text-dark_career",
        techwatch: "text-techwatch_primary dark:text-dark_techwatch",
        visibility: "text-visibility_primary dark:text-dark_visibility",
        community: "text-community_primary dark:text-dark_community",
        tasks: "text-tasks_primary dark:text-dark_tasks",
        contacts: "text-contacts_primary dark:text-dark_contacts",
        global: "", // Aucune classe pour global
      };

       // Déterminer la classe CSS à appliquer
       const featureClass = featureClasses[feature] || "";

       // Fonction pour rediriger en fonction de la `feature`
       const handleNavigation = () => {
            if (feature !== "global") {
                const path = feature.charAt(0).toUpperCase() + feature.slice(1).toLowerCase();
                navigate(`/${path}`);
            }
        };

    return (
        <div 
        className={`relative group flex items-center hover:bg-gray-100 dark:hover:bg-gray-700 p-4 transition w-full max-w-md`}
        >
            <div className="flex flex-col items-start cursor-pointer" onClick={handleNavigation}>
                <span className={`${contentClass} ${featureClass} text-sm`}>
                    {content}
                </span>
                <div className="flex mt-2 space-x-2">
                    {cancel && (
                        <button
                            onClick={() => handleAction('cancel')}
                            type="button"
                            className="bg-warning_red hover:bg-red-600 text-white rounded px-3 py-1 text-xs transition"
                        >
                            Cancel
                        </button>
                    )}
                    {accept && (
                        <button
                            onClick={() => handleAction('accept')}
                            type="button"
                            className="bg-warning_green hover:bg-green-600 text-white rounded px-3 py-1 text-xs transition"
                        >
                            Accept
                        </button>
                    )}
                </div>
            </div>
            <div className="hidden group-hover:flex items-center gap-x-2 absolute p-1 top-2 right-2 bg-white dark:bg-gray-800 border rounded shadow">
                <ActionTooltip label="Dismiss">
                    <Trash
                        onClick={() => handleAction('toDelete')}
                        className="cursor-pointer w-4 h-4 text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 transition"
                    />
                </ActionTooltip>
            </div>
        </div>
    );
}

export default NotifItem;
