import { Button } from "../../../components/ui/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "react-calendly"; // Utiliser PopupButton au lieu de PopupWidget


export const Cta = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    // Assurer que rootElement ne soit pas null

  return (
    <section
      id="cta"
      className="bg-muted/50 pt-6 pb-32 my-24 sm:my-32"
    >
      <div className="container lg:grid lg:grid-cols-2 place-items-center">
        <div className="lg:col-start-1">
          <h2 className="text-3xl md:text-4xl font-bold ">
            {t("landing.demo.title1")}
          <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
              {" "}
              {t("landing.demo.title2")}{" "}
            </span>
            {t("landing.demo.title3")}
          </h2>
          <p className="text-muted-foreground text-xl mt-4 mb-8 lg:mb-0">
            {t("landing.demo.description")}
          </p>
        </div>
        <div className="space-y-4 lg:col-start-2 lg:space-y-0 lg:flex lg:gap-4">
            <PopupButton
              url="https://calendly.com/contact-zangou" // Remplace par ton lien Calendly
              rootElement={document.getElementById("root") as HTMLElement}
              text={t("landing.demo.cta1")}
              className="w-full md:w-auto px-4 py-2 rounded-md text-sm font-medium  border border-neutral_blue text-white bg-neutral_blue hover:bg-white hover:text-neutral_blue"
            />
            <Button
                variant="landing_outline"
                className="w-full md:w-auto px-4 py-2"
                onClick={() => {navigate('/signup')}}
            >
                {t("landing.demo.cta2")}
            </Button>
        </div>
      </div>
    </section>
  );
};
