import { 
    DropdownMenu, 
    DropdownMenuTrigger,
    DropdownMenuContent, 
    DropdownMenuItem,
    DropdownMenuSeparator
} from "../../../../components/ui/dropdown-menu";
import { ChevronDown, LogOut, Settings, Trash, UserPlus, Users } from "lucide-react";
import { useModal } from "../modals/use-modal-store";
import { useCommunityActions, useSelectedChannel, useSelectedCurrentMember } from "../../../../hooks/community-hook";
import { useTranslation } from 'react-i18next';

interface ChannelSettingsProps {
    id:string;
    name:string;
};

export const ChannelSettings = ({
    id,
    name
}: ChannelSettingsProps) => {
    const { onOpen } = useModal();
    const { t } = useTranslation();
    const currentChannel = useSelectedChannel();
    const currentMember = useSelectedCurrentMember();
    const currentMemberRole = currentMember?.role;
    const currentMemberId = currentMember?.id;
    const isAdmin = currentMemberRole === "ADMIN";
    const isModerator = isAdmin || currentMemberRole === "MODERATOR";

    const { setSelectedChannel } = useCommunityActions();
    const handleChannelSelection = () => {
        setSelectedChannel({id:id, name:name});
    }

    
    if (!currentChannel || !currentMember || (currentChannel.name === "general" && !isAdmin)) {
        return null;
    }
    


    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                className="focus:outline-none"
                asChild
            >
                <button
                    onClick={handleChannelSelection}
                    className="w-full text-md font-semibold px-3 flex items-center h-12 border-neutral-200 dark:border-neutral-800 transition"
                >

                    <ChevronDown className="ml-auto text-gray-400 hover:text-gray-500 transition duration-150 ease-in-out cursor-pointer" />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className="w-full text-xs font-medium text-black dark:text-neutral-400 space-y-[2px]"
            >
                {isModerator && (
                    <DropdownMenuItem
                        onClick={()=> onOpen("invite")}
                        className= "text-community_complementary dark:text-community_complementary px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.invite`)}
                        <UserPlus className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
                {isAdmin && (
                    <DropdownMenuItem
                        onClick={() => onOpen("editChannel", )}
                        className= "px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.channelSettings`)}
                        <Settings className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
                {isAdmin && (
                    <DropdownMenuItem
                        onClick= {() => onOpen("members", )}
                        className= "px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.manageMembers`)}
                        <Users className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
                {!isAdmin && (
                    <DropdownMenuItem
                        onClick= {() => onOpen("viewMembers", )}
                        className= "px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.viewMembers`)}
                        <Users className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
                {isModerator && (
                    <DropdownMenuSeparator />
                )}
                {isAdmin && (
                    <DropdownMenuItem
                        onClick={() => onOpen("deleteChannel", )}
                        className= "text-warning_red px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.deleteChannel`)}
                        <Trash className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
                {!isAdmin && (
                    <DropdownMenuItem
                        onClick={() => onOpen("leaveChannel", )}
                        className= "text-rose-500 px-3 py-2 text-sm cursor-pointer"
                    >
                        {t(`community.Settings.leaveChannel`)}
                        <LogOut className="h-4 w-4 ml-auto"/>
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}