import { GetNotifications } from "../api/notifications/api-notifications";
import { createStore, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";

interface GeneralHook {
    isHamburgerMenuOpen: boolean;
    userNotifications: GetNotifications[] | undefined;
    userHasUnviewedNotifications: number | undefined;
    
    actions: {
        toggleHamburgerMenuSheet: (isHamburgerMenuOpenState: boolean) => void;
        closeHamburgerMenuSheet: () => void;

        setUserNotifications: (notifications: GetNotifications[]) => void;
        deleteNotification: (notificationId: string) => void;
        clearGeneralHook: () => void;

    }
}

const generalHook = createStore<GeneralHook>()(
    devtools(
        (set, get) => ({
            isHamburgerMenuOpen: false,
            userNotifications: undefined,
            userHasUnviewedNotifications: undefined,
            
            actions: {
                toggleHamburgerMenuSheet: (isHamburgerMenuOpenState: boolean) => {
                    //console.log('isHamburgerMenuOpenState hook : ', isHamburgerMenuOpenState);
                    set({
                        isHamburgerMenuOpen: !isHamburgerMenuOpenState,
                    });
                    //console.log('isHamburgerMenuOpenState hook : ', get().isHamburgerMenuOpen);
                },
                closeHamburgerMenuSheet: () => {
                    set({
                        isHamburgerMenuOpen: false,
                    });
                },
                setUserNotifications: (notifications: GetNotifications[]) => {

                    const newNotifications = get().userNotifications || [];

                    notifications.forEach((notification) => {
                        const index = newNotifications?.findIndex((n) => n.id === notification.id);
                        if (index !== -1) {
                            newNotifications[index] = notification;
                        } else {
                            newNotifications.push(notification);
                        }
                    });

                    const sortedResponse = newNotifications.sort((b, a) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

                    const unviewedNotifications = sortedResponse.filter(notification => !notification.viewed).length;
                    set({
                        userNotifications: newNotifications,
                        userHasUnviewedNotifications: unviewedNotifications > 0 ? unviewedNotifications : undefined,
                    });
                },
                deleteNotification: (notificationId: string) => {
                    const currentNotifications = get().userNotifications || [];
                    const index = currentNotifications.findIndex((n) => n.id === notificationId);

                    if (index !== -1) {
                        const newNotifications = currentNotifications.filter((n) => n.id !== notificationId);
                        set({
                            userNotifications: newNotifications,
                        });
                    }
                },
                clearGeneralHook: () => {
                    set({
                        isHamburgerMenuOpen: false,
                        userNotifications: undefined,
                        userHasUnviewedNotifications: undefined,
                    });
                },
            },
        }),
        {
            name: 'general-hook',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
)

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof generalHook, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof generalHook>) => state.actions;
const selectedTabSelector = (state: ExtractState<typeof generalHook>) => state.isHamburgerMenuOpen;
const userNotificationsSelector = (state: ExtractState<typeof generalHook>) => state.userNotifications;
const userHasUnviewedNotificationsSelector = (state: ExtractState<typeof generalHook>) => state.userHasUnviewedNotifications;

// getters
export const getGeneralHookStoreActions = () => actionsSelector(generalHook.getState());
export const getSelectedTabContact = () => selectedTabSelector(generalHook.getState());
export const getUserNotifications = () => userNotificationsSelector(generalHook.getState());
export const getUserHasUnviewedNotifications = () => userHasUnviewedNotificationsSelector(generalHook.getState());

function useGeneralHook<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(generalHook, selector, equalityFn);
}

// Hooks
export const useGeneralHookActions = () => useGeneralHook(actionsSelector);
export const useIsHamburgerMenuOpen = () => useGeneralHook(selectedTabSelector);
export const useUserNotifications = () => useGeneralHook(userNotificationsSelector);
export const useUserHasUnviewedNotifications = () => useGeneralHook(userHasUnviewedNotificationsSelector);
