import { z } from "zod";

export const CreateBoard = z.object({
  title: z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short."
  })
  .refine((val) => val !== "Overview", {
    message: "Title cannot be 'Overview'",
  }),
  template: z.enum(['blank', 'kanban', 'calendar', 'opportunities']).optional()
});