import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { startTransition } from 'react';

interface AuthStore {
    isAuthenticated: boolean;
    signUpStep: string;
    actions: {
        setIsAuthenticated: (isAuthenticated: boolean) => Promise<void>;
        setSignUpStep: (signUpStep: string) => void;
		init: () => Promise<void>;
		clearTokens: () => void;
        clearAuthStore: () => void;
	}
}


const encoder = new TextEncoder();
const decoder = new TextDecoder();

async function generateKeyFromPassphrase(passphrase: string): Promise<CryptoKey> {
    const hash = await crypto.subtle.digest('SHA-256', encoder.encode(passphrase));
    return crypto.subtle.importKey(
        'raw',
        hash.slice(0, 32), // Ensure the key is exactly 32 bytes (256 bits)
        { name: 'AES-GCM' },
        true,
        ['encrypt', 'decrypt']
    );
}

let key: CryptoKey | undefined; // Store the key globally or manage via state

(async () => {
    const passphrase = process.env.REACT_APP_SESSION as string;
    key = await generateKeyFromPassphrase(passphrase);
})();

const authStore = createStore<AuthStore>()(
    devtools(
        (set, get) => ({
            isAuthenticated: false,
            signUpStep: 'signUpForm', // emailValidation or signUpForm

            actions: {
                setIsAuthenticated: async (isAuthenticated: boolean) => {
                    if (key) {
                        const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
                        const encoded = encoder.encode(JSON.stringify(isAuthenticated));
                        const encrypted = await crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, encoded);
                        sessionStorage.setItem('isAuthenticated', JSON.stringify({ iv: Array.from(iv), encrypted: Array.from(new Uint8Array(encrypted)) }));
                    } else {
                        sessionStorage.removeItem('isAuthenticated');
                    }
                    set({ isAuthenticated });
                },
                setSignUpStep: (signUpStep: string) => set({ signUpStep }),
                init: async () => {
                    const data = sessionStorage.getItem('isAuthenticated');
                    if (data && key) {
                        const { iv, encrypted } = JSON.parse(data);
                        try {
                            const decryptedData = await crypto.subtle.decrypt({ name: "AES-GCM", iv: new Uint8Array(iv) }, key, new Uint8Array(encrypted));
                            set({ isAuthenticated: JSON.parse(decoder.decode(decryptedData)) });
                        } catch (e) {
                            console.error('Decryption failed:', e);
                            set({ isAuthenticated: false }); // Handle decryption failure by setting to false
                        }
                    }
                },
                clearTokens: () => {
                    sessionStorage.removeItem('isAuthenticated');
                    set({ isAuthenticated: false }); // Ensure isAuthenticated is reset to false when cleared
                },
                clearAuthStore: () => {
                    sessionStorage.removeItem('isAuthenticated');
                    startTransition(() => {
                        set({
                            isAuthenticated: false,
                            signUpStep: 'signUpForm', // Ensure this is properly set instead of undefined
                        });
                    });
                },
            }
        }),
        {
            name: 'auth-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
);

export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof authStore, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof authStore>) => state.actions;
const isAuthenticatedSelector = (state: ExtractState<typeof authStore>) => state.isAuthenticated;
const signUpStepSelector = (state: ExtractState<typeof authStore>) => state.signUpStep;

// getters
export const getAuthStoreActions = () => actionsSelector(authStore.getState());
export const getIsAuthenticated = () => isAuthenticatedSelector(authStore.getState());
export const getSignUpStep = () => signUpStepSelector(authStore.getState());

function useAuthStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(authStore, selector, equalityFn);
}

// Hooks
export const useActions = () => useAuthStore(actionsSelector);
export const useIsAuthenticated = () => useAuthStore(isAuthenticatedSelector);
export const useSignUpStep = () => useAuthStore(signUpStepSelector);
