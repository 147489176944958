import { X } from "lucide-react";

import {
    Popover,
    PopoverClose,
    PopoverContent,
    PopoverTrigger
} from "../ui/popover";

import { RadioGroup, RadioGroupItem } from "../ui/radio-group";

import { useAction } from "../../hooks/use-action";
//import { fetchCreateBoard } from "../../api/tasks/create-board";
import { createBoard } from "../../api/tasks/create-board";
import { FormInput } from "./form-input";
import { FormSubmit } from "./form-submit";
import { Button } from "../../components/ui/button";
import { toast } from "sonner";
//import { FormPicker } from "./form-picker";
import { ElementRef, useRef } from "react";
import { BrowserRouter, Router } from "react-router-dom";
import { useTaskStoreActions } from "../../hooks/tasks-store";
import { useTranslation } from "react-i18next";
import { Label } from "../ui/label";

//import { useRouter } from "next/navigation"; //to do change 


interface FormPopoverProps {
    children: React.ReactNode;
    side?: "left" | "right" | "top" | "bottom";
    align?: "start" | "center" | "end";
    sideOffset?: number;
};

export const FormPopover = ({
    children,
    side = "right",
    align,
    sideOffset = 0,
}: FormPopoverProps) => {
    //const router = BrowserRouter;
    const { t } = useTranslation();
    
    const closeRef = useRef<ElementRef<"button">>(null);

    const inputRef = useRef<ElementRef<"input">>(null);

    const { updateOneBoard, setSelectedBoard, setSelectedBoardElement } = useTaskStoreActions();

    const { execute, fieldErrors } = useAction(createBoard, {
        onSuccess: (data) => {
            //console.log('data create board API call :', data);
            updateOneBoard(data);
            toast.success("Board created!");
            closeRef.current?.click();
            setSelectedBoard(data.id);
            //setSelectedBoardElement(data.id); => added in selectBoard
            //TO DO : go to created board
            //router.push(`/board/${data.id}`);
            
        },
        onError: (error) => {
            //console.log({ error });
            toast.success(error);
        }
    });
/*
    const onBlur = () => {
        inputRef.current?.form?.requestSubmit();
    };
*/
    // Define the template types
    type TemplateType = 'blank' | 'kanban' | 'calendar' | 'opportunities';

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        const template = formData.get("template") as TemplateType;
        execute({ title, template });
    };


    return (
        <Popover
            //modal={false}
            
        >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent
                align={align}
                className="w-80 pt-3 z-[9999] bg-white dark:bg-black"
                side={side}
                sideOffset={sideOffset}
                avoidCollisions={true}
            >
                <div className="text-sm font-medium text-center text-gray-700 dark:text-gray-200 pb-4">
                    {t("tasks.board.create.board")}
                </div>
                <PopoverClose ref={closeRef} asChild>
                    <Button 
                        className="h-auto w-auto p-2 absolute top-2 right-2 text-gray-700 dark:text-gray-200"
                        variant="ghost"
                    >
                        <X className="h-4 w-4"/>
                    </Button>
                </PopoverClose>
                <form onSubmit={onSubmit} className="space-y-4 text-gray-700 dark:text-gray-200">
                    <div className="space-y-4">
                        <FormInput
                            id="title"
                            label={t("tasks.board.create.title")}
                            type="text"
                            errors={fieldErrors}
                            //onBlur={onBlur}
                            ref={inputRef}
                            className="text-gray-700 dark:text-gray-200 "
                        />
                    </div>
                    <RadioGroup defaultValue="blank" name="template">
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="blank" id="option-one"/>
                            <Label htmlFor="blank">Blank</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="kanban" id="option-two" />
                            <Label htmlFor="kanban">Kanban Template</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="calendar" id="option-three" />
                            <Label htmlFor="calendar">Months Template</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="opportunities" id="option-four" />
                            <Label htmlFor="opportunities">Opportunities Template</Label>
                        </div>
                    </RadioGroup>
                    <FormSubmit className="w-full text-neutral_white-200 bg-tasks_primary hover:text-[#DD691D] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA]">
                        {t("tasks.board.create.create")}
                    </FormSubmit>
                </form>
            </PopoverContent>
        </Popover>
    );
};
