import * as React from "react"
import { useTranslation } from "react-i18next";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

import { Table } from "@tanstack/react-table";

//import { Button } from "@/registry/new-york/ui/button";
import { Button } from "../../../../components/ui/button";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select"

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../../components/ui/pagination"

interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {

  //handle situation where > 10 pages
  const currentPage = table.getState().pagination.pageIndex + 1;
  const pageSize = table.getState().pagination.pageSize;
  const totalRows = table.getFilteredRowModel().rows.length;

  const { t } = useTranslation();
  
  const pages = [];
  if (currentPage < 4 ) {
    const endPage = Math.min(5, table.getPageCount()-1);
    for (let i = 2; i <= endPage; i++) {
      pages.push(i);
    }
  } else {
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(currentPage + 2, table.getPageCount()-1);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  }

  return (
    <div className="flex items-center justify-between p-4">
      <div 
        className="hidden text-sm text-muted-foreground" //md:flex -> Hidden for now since no bulk actions are available
      >
          {table.getFilteredSelectedRowModel().rows.length} of{" "} {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="hidden md:block text-sm font-medium">
            {t("contacts.contactsTable.footer.pages")}
          </p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        

        {(totalRows > pageSize) && (
          <div className="flex items-center space-x-2">
            <Pagination>
              <PaginationContent>
                {currentPage > 1 && (
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => table.previousPage()}
                      className="text-contacts_primary cursor-pointer"
                    />
                  </PaginationItem>
                )}
                
                <PaginationItem>
                  <PaginationLink 
                    onClick={() => table.setPageIndex(0)}
                    className={currentPage === 1 ? "bg-contacts_primary text-white" : "text-contacts_primary"}
                  >
                    1
                  </PaginationLink>
                </PaginationItem>
              
                  
                {pages[0] > 2 && (
                  <PaginationItem>
                    <span className="mx-1 text-contacts_primary">...</span>
                  </PaginationItem>
                  )}

                {pages.map((i) =>
                  <PaginationItem key={i}>
                    <PaginationLink
                      onClick={() => table.setPageIndex(i-1)}
                      className={currentPage === i ? "bg-contacts_primary text-white" : "text-contacts_primary"}
                    >
                      {i}
                    </PaginationLink>
                  </PaginationItem>
                )}

                {pages[pages.length - 1] < table.getPageCount() - 1 && (
                  <PaginationItem>
                    <span className="mx-1 text-contacts_primary">...</span>
                  </PaginationItem>
                )}
                  
                
                {table.getPageCount() !== 1 && (
                <PaginationItem>
                  <PaginationLink 
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    className={currentPage === table.getPageCount() ? "bg-contacts_primary text-white" : "text-contacts_primary"}
                  >
                    {table.getPageCount()}
                  </PaginationLink>
                </PaginationItem>
                )}
                
                {currentPage < table.getPageCount() && (
                  <PaginationItem>
                    <PaginationNext 
                      onClick={() => table.nextPage()}
                      className="text-contacts_primary cursor-pointer"
                    />
                  </PaginationItem>
                )}
                
              </PaginationContent>
            </Pagination>
          </div>
        )}

        {/*
          <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
                </div>
          */}
      </div>
    </div>
  )
}

/*
24/08 : Pagination Ellipsis retiré car cause une seconde scrollbar -> remplacé par "..."
<PaginationEllipsis />
*/