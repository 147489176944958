import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo_light from '../../img/logo.png';
import logo_dark from '../../img/logo_dark.svg';
import { useIsAuthenticated } from "../../hooks/auth-store";
import Logout from "../../pages/SignIn/_components/logout";
import { ModeToggle } from "../mode-toggle";
import { useTheme } from "../theme-provider/theme-provider";
import { loadNotifications, Notifications } from "../notifications";
import { User, Globe, CircleHelp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { useTranslation } from "react-i18next";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from "../ui/dropdown-menu"
// Hamburger menu
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "../../components/ui/sheet"

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuTrigger,
} from "../../components/ui/navigation-menu";

import { buttonVariants } from "../../components/ui/button";

import { SidebarGeneral } from "../Sidebar-general";
import { useGeneralHookActions, useIsHamburgerMenuOpen } from "../../hooks/general-hook";
import { ActionTooltip } from "../action-tooltip";
import i18n from "../multilingual/i18n";
import { toast } from "sonner";
import { useEffect } from "react";
import { LogoIcon } from "../../pages/Landing/_components/Icons";
import { Menu } from "lucide-react";
import { cn } from "../../lib/utils";
import { ModalType, useModal } from "./_components/modals/use-modal-store";
import { ModalProvider } from "./_components/modals/modal-provider";

//Landing
interface RouteProps {
    href: string;
    label: string;
  }

interface ModalProps {
    label: string;
  }

const routeList: RouteProps[] = [
    {
      href: "/#features",
      label: "Features",
    },
    {
        href: "/#about",
        label: "About",
    },
/*    
    {
      href: "#testimonials",
      label: "Testimonials",
    },
*/
    {
      href: "/#pricing",
      label: "Pricing",
    },
    {
      href: "/#faq",
      label: "FAQ",
    },
];

const featuresList: RouteProps[] = [
    {
      href: "/productCareer",
      label: "Career",
    },
    {
      href: "/productTechwatch",
      label: "Techwatch",
    },
    {
      href: "/productContacts",
      label: "Contacts",
    },
    {
      href: "/productCommunity",
      label: "Community",
    },
    {
      href: "/productVisibility",
      label: "Visibility",
    },
    {
      href: "/productTasks",
      label: "Tasks",
    },
];

const helpList: ModalProps[] = [
    {
      label: "Start",
    },
    {
      label: "Career",
    },
    {
      label: "Techwatch",
    },
    {
      label: "Contacts",
    },
    {
      label: "Community",
    },
    {
      label: "Tasks",
    },
];

const Header = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;
    const isAuthenticated = useIsAuthenticated();
    const { theme } = useTheme();
    const logo = theme === 'dark' ? logo_dark : logo_light;
    let navigate = useNavigate();
    const { onOpen } = useModal();
    // Hamburger menu
    const isHamburgerMenuOpen = useIsHamburgerMenuOpen();
    const { toggleHamburgerMenuSheet } = useGeneralHookActions();

    // Function to handle navigation in normal mode
    const handleNavigation = (href: string) => {
        if (href.startsWith("#")) {
            // Internal navigation (same page)
            const element = document.querySelector(href);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            // External navigation (different page)
            navigate(href);
        }
    };

    useEffect(() => {
        // If there's a hash in the URL, scroll to that section on page load
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    useEffect(() => {
        // Initialize and refresh notifications when the user is authenticated and he change page
        if (isAuthenticated) {
            loadNotifications();
        }
    }, [isAuthenticated, location]);

    return (
        <>
        <ModalProvider />
        <div>
            {isAuthenticated ? (
                <div
                    //When the user is authenticated, the member header will be displayed
                    className="flex mx-1 py-1 border-b-[1px] border-[#D9D9D9] items-center justify-between"
                >
                    <div className="p-0 m-0 flex">
                        <div className="md:hidden px-2">
                            <Sheet
                                open={isHamburgerMenuOpen}
                                onOpenChange={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}
                            >
                                <SheetTrigger>
                                    <button className="p-2 mr-2">
                                        <div className="w-6 h-1 bg-[#000021] dark:bg-neutral_white-200 mb-1"></div>
                                        <div className="w-6 h-1 bg-[#000021] dark:bg-neutral_white-200 mb-1"></div>
                                        <div className="w-6 h-1 bg-[#000021] dark:bg-neutral_white-200"></div>
                                    </button>
                                </SheetTrigger>
                                <SheetContent side="left" className="xs:w-2/3 s:w-1/2 sm:w-1/2 h-screen p-0 m-0 overflow-y-auto scrollbar-none hover:scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800">
                                    <SheetHeader>
                                        <SheetTitle>
                                            <div className="flex justify-center my-2 items-center gap-x-4">
                                                <img src={logo} alt="Zangou" className="h-10 w-10" />
                                                <NavLink to='/' className="text-lg text-[#000021] dark:text-slate-50 flex-col">Zangou</NavLink>
                                            </div>
                                        </SheetTitle>
                                    </SheetHeader>
                                    <div className="flex flex-col">
                                        <SidebarGeneral />
                                    </div>
                                </SheetContent>
                            </Sheet>
                        </div>
                        <div className="flex gap-x-2 items-center md:w-1/6 mr-4">
                            <img src={logo} alt="Zangou" className="cursor-pointer md:ml-3 w-10 min-w-5 h-auto object-contain" onClick={() =>navigate('/')} />
                            <NavLink to='/' className="hidden md:block text-lg md:text-xl font-verdana text-[#000021] dark:text-slate-50 flex-col">Zangou</NavLink>
                        </div>
                    </div>
                    <div className="hidden md:flex flex-grow justify-center items-center md:gap-x-4 lg:gap-x-8 xl:gap-x-12 md:text-base lg:text-lg xl:text-xl">
                        <NavLink to="/Career" className={`font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-career_primary dark:hover:text-dark_career ${isActive('/Career') ? "font-bold !text-dark_career dark:!text-dark_career ml-10" : ""}`}>Career</NavLink>
                        <NavLink to="/Techwatch" className={`font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-techwatch_primary dark:hover:text-dark_techwatch ${isActive('/Techwatch') ? "font-bold !text-techwatch_primary dark:!text-dark_techwatch" : ""}`}>Techwatch</NavLink>
                        <NavLink to="/Contacts" className={`font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-contacts_primary dark:hover:text-dark_contacts ${isActive('/Contacts') ? "font-bold !text-contacts_primary dark:!text-dark_contacts" : ""}`}>Contacts</NavLink>
                        {/*<NavLink to="/Visibility" className={`px-4 py-2 font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-visibility_primary dark:hover:text-dark_visibility ${isActive('/Visibility') ? "font-bold !text-visibility_primary dark:!text-dark_visibility" : ""}`}>Visibility</NavLink>*/}
                        <NavLink to="/Community" className={`font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-community_primary dark:hover:text-dark_community ${isActive('/Community') ? "font-bold !text-community_primary dark:!text-dark_community" : ""}`}>Community</NavLink>
                        <NavLink to="/Tasks" className={`font-verdana lg:text-lg text-[#000021] dark:text-neutral_white-200 hover:text-tasks_primary dark:hover:text-dark_tasks ${isActive('/Tasks') ? "font-bold !text-tasks_primary dark:!text-dark_tasks" : ""}`}>Tasks</NavLink>
                    </div>
                    <div className="flex items-center md:ml-2 gap-x-2">
                        {isAuthenticated ? (
                            <>
                                {/* Help Modal Menu*/}
                                <ActionTooltip label={t("header.mode.help")} side="right">
                                    <NavigationMenu>
                                        <NavigationMenuList>
                                            <NavigationMenuItem>
                                                <NavigationMenuTrigger
                                                    className="cursor-pointer"
                                                    onClick={() => onOpen(location.pathname.slice(1) as ModalType)}
                                                >
                                                    <Button className="w-10 h-10 bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon">
                                                        <div className="flex text-[#000021] dark:text-neutral_white-200">
                                                            <CircleHelp className="h-[1.2rem] w-[1.2rem]" />
                                                        </div>
                                                    </Button>
                                                </NavigationMenuTrigger>
                                                <NavigationMenuContent className="hidden md:block">
                                                    <ul className="items-start justify-start">
                                                        {helpList.map((route: ModalProps, i) => (
                                                            <li
                                                                className={cn("text-[17px] cursor-pointer w-full justify-start items-center",
                                                                    //"block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                                                                    buttonVariants({
                                                                        variant: "ghost",
                                                                    }))}
                                                                onClick={() =>  onOpen(route.label as ModalType)}
                                                                key={i}
                                                            >
                                                                {route.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </NavigationMenuContent>
                                            </NavigationMenuItem>
                                        </NavigationMenuList>
                                    </NavigationMenu>
                                </ActionTooltip>
                                <Logout />
                                <ActionTooltip label={t("header.mode.hover")}>
                                    <div className="hidden lg:flex text-[#000021] dark:text-neutral_white-200">
                                        <ModeToggle />
                                    </div>
                                </ActionTooltip>
                                <Notifications />
                                <ActionTooltip label={t("header.profile.hover")}>
                                    <Button onClick={() => navigate("/Profile")} className="w-10 h-10 bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon">
                                        <User className="h-[1.2rem] w-[1.2rem]" />
                                    </Button>
                                </ActionTooltip>
                            </>
                        ) : (
                            <>
                                <NavLink to="/SignIn" className={`px-2 py-1 font-verdana justify-center text-center xs: text-xs md:text-sm text-[#2196F3] dark:text-neutral_white-200 ${isActive('/SignIn') ? "font-bold text-[#2196F3]" : ""}`}>Sign In</NavLink>
                                <NavLink to="/SignUp" className={`px-2 py-1 font-verdana justify-center text-center xs: text-xs md:text-sm text-neutral_white-200 bg-[#2196F3] border border-[#2196F3] dark:text-white ${isActive('/SignUp') ? "font-bold text-[#FFF8E7]" : ""}`}>Sign Up</NavLink>
                                <ActionTooltip label="Language">
                                    <div className="hidden lg:flex bg-transparent text-[#000021] dark:text-neutral_white-200">
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button className="bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon">
                                                    <Globe />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent align="center">
                                                <DropdownMenuItem onClick={() => i18n.changeLanguage('fr')}>
                                                    French
                                                </DropdownMenuItem>
                                                <DropdownMenuItem onClick={() => i18n.changeLanguage('en')}>
                                                    English
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </ActionTooltip>
                                <ActionTooltip label={t("header.mode.hover")}>
                                    <div className="hidden lg:flex text-[#000021] dark:text-neutral_white-200">
                                        <ModeToggle />
                                    </div>
                                </ActionTooltip>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <header
                    //When the user is not authenticated, the landing header will be displayed
                    className="sticky border-b-[1px] top-0 z-40 w-full bg-white dark:border-b-slate-700 dark:bg-background"
                >
                    <NavigationMenu className="mx-auto">
                        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
                            <NavigationMenuItem className="font-bold flex">
                                <a
                                    //rel="noreferrer noopener"
                                    href="/"
                                    className="ml-2 font-bold text-xl flex items-center gap-2"
                                >
                                    <img src={logo} alt="Zangou" className="h-10 w-10" />
                                    <span>Zangou</span>
                                </a>
                            </NavigationMenuItem>

                            {/* mobile */}
                            <span className="flex md:hidden">

                                <ActionTooltip label="Language">
                                    <div className="flex md:hidden bg-transparent text-[#000021] dark:text-neutral_white-200">
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button className="bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon">
                                                    <Globe className="h-[1.2rem] w-[1.2rem]" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent align="center">
                                                <DropdownMenuItem onClick={() => i18n.changeLanguage('fr')}>
                                                    French
                                                </DropdownMenuItem>
                                                <DropdownMenuItem onClick={() => i18n.changeLanguage('en')}>
                                                    English
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </ActionTooltip>

                                <ModeToggle />

                                <Sheet
                                open={isHamburgerMenuOpen}
                                onOpenChange={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}
                            >
                                <SheetTrigger className="px-2">
                                    <Menu
                                        className="flex md:hidden h-5 w-5"
                                        onClick={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}
                                    >
                                        <span className="sr-only">Menu Icon</span>
                                    </Menu>
                                </SheetTrigger>

                                <SheetContent side={"left"}>
                                    <SheetHeader>
                                        <SheetTitle className="font-bold text-xl items-center justify-center">
                                            <a
                                                //rel="noreferrer noopener"
                                                href="/"
                                                className="ml-2 font-bold text-xl flex items-center justify-center gap-2"
                                                onClick={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}
                                            >
                                                <img src={logo} alt="Zangou" className="h-10 w-10" />
                                                <span>Zangou</span>
                                            </a>
                                        </SheetTitle>
                                    </SheetHeader>
                                    <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                                        {routeList.map((route: RouteProps, i) => (
                                            <div 
                                                key={i}
                                                onClick={() => {
                                                    handleNavigation(route.href);
                                                    toggleHamburgerMenuSheet(isHamburgerMenuOpen);
                                                }}
                                                className={`text-[17px] ${buttonVariants({
                                                    variant: "ghost",
                                                    })}`}
                                            >
                                                {route.label}
                                            </div>
                                            ))}
                                        <div className="flex flex-row gap-x-4 pt-2">
                                            <NavLink to="/SignUp">
                                                <Button variant="landing_full" className="h-10 w-full px-4 py-2" onClick={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}>Sign Up</Button>
                                            </NavLink>     
                                            <NavLink to="/SignIn">
                                                <Button variant="landing_outline" className="h-10 w-full px-4 py-2" onClick={() => toggleHamburgerMenuSheet(isHamburgerMenuOpen)}>Sign In</Button>
                                            </NavLink>
                                        </div>
                                    </nav>
                                </SheetContent>
                            </Sheet>
                        </span>
                        {/* desktop */}
                        <div className="hidden md:flex md:flex-row">
                            <NavigationMenu>
                                <NavigationMenuList>
                                    <NavigationMenuItem>
                                    <NavigationMenuTrigger 
                                        className="cursor-pointer"
                                        onClick={() => navigate(routeList[0].href)}
                                    >
                                        {routeList[0].label}
                                    </NavigationMenuTrigger>
                                    <NavigationMenuContent>
                                        <ul className="items-start justify-start">
                                            {featuresList.map((route: RouteProps, i) => (
                                                <li
                                                    className={cn("text-[17px] cursor-pointer w-full justify-start items-center", 
                                                        //"block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                                                        buttonVariants({
                                                            variant: "ghost",
                                                            }))}
                                                    onClick={() => navigate(route.href)}
                                                    key={i}
                                                >
                                                    {route.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </NavigationMenuContent>
                                    </NavigationMenuItem>
                                </NavigationMenuList>
                            </NavigationMenu>
                            <nav className="hidden md:flex gap-2">
                                {routeList.slice(1).map((route: RouteProps, i) => (
                                    <div 
                                        key={i}
                                        onClick={() => handleNavigation(route.href)}
                                        className={`text-[17px] cursor-pointer ${buttonVariants({
                                            variant: "ghost",
                                            })}`}
                                    >
                                        {route.label}
                                    </div>
                                ))}
                                
                            </nav>
                        </div>
                        <div className="hidden md:flex md:flex-row gap-x-4 items-center">
                            <NavLink 
                                to="/SignUp" 
                                //className={`px-2 py-1 font-verdana justify-center text-center xs: text-xs md:text-sm text-neutral_white-200 bg-[#2196F3] border border-[#2196F3] dark:text-white ${isActive('/SignUp') ? "font-bold text-[#FFF8E7]" : ""}`}
                                //className={`border ${buttonVariants({ variant: "secondary" })}`}
                            >
                                <Button variant="landing_full">Sign Up</Button>
                            </NavLink>
                            <NavLink 
                                to="/SignIn" 
                                //className={`px-2 py-1 font-verdana justify-center text-center xs: text-xs md:text-sm text-[#2196F3] dark:text-neutral_white-200 ${isActive('/SignIn') ? "font-bold text-[#2196F3]" : ""}`}
                                //className="w-full md:w-1/3"
                                //className={`border ${buttonVariants({ variant: "secondary" })}`}
                            >
                                <Button variant="landing_outline">Sign In</Button>
                            </NavLink>
                            
                            <ActionTooltip label="Language">
                                <div className="hidden md:flex bg-transparent text-[#000021] dark:text-neutral_white-200">
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button className="bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon">
                                                <Globe className="h-[1.2rem] w-[1.2rem]" />
                                            </Button>
                                            </DropdownMenuTrigger>
                                        <DropdownMenuContent align="center">
                                            <DropdownMenuItem onClick={() => i18n.changeLanguage('fr')}>
                                                French
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => i18n.changeLanguage('en')}>
                                                English
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </ActionTooltip>
                            <div className="hidden md:flex gap-2">
                                <ModeToggle />
                            </div>
                        </div>
                        </NavigationMenuList>
                    </NavigationMenu>
                    </header>
            )}
        </div></>
    );
};

export default Header;

/*
<a
    rel="noreferrer noopener"
    href={route.href}
    key={i}
    className={`text-[17px] ${buttonVariants({
    variant: "ghost",
    })}`}
>
    {route.label}
</a>
*/