import { useEffect, useState } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { fetchTopics } from "../../../api/techwatch/api-getFeeds";
import { Topic } from "../../../interfaces/techwatch/int-techwatch";
import { useSelectedCategory, useTechwatchStoreActions } from "../../../hooks/techwatch-store";
import { Layers3 } from "lucide-react";

const CategoryComponent = () => {
    
    const selectedCategory = useSelectedCategory();

    const { setSelectedCategory } = useTechwatchStoreActions();

    const [categories, setCategories] = useState<Topic[]>([]);

    useEffect(() => {
        const getTopicsData = async () => {
            try {
                const topicsData = await fetchTopics();
                setCategories(topicsData);
            } catch (error) {
                //console.log(error);
            }
        };
        getTopicsData();
    }, []);

    return ( 
        <div className="w-full z-[40] inline-flex items-center justify-end px-4 mt-2 mb-0 gap-x-4 text-[#133867] dark:text-[#D1E7FF]">
            <div className="flex items-center">
                <div className="ml-auto items-center">
                    <Select
                        value={selectedCategory}
                        onValueChange={(value) => {setSelectedCategory(value as string)}}
                    >
                        <SelectTrigger className="text-md font-medium text-[#133867] dark:text-[#D1E7FF] bg-[#DDEBFE] dark:bg-[#102642] gap-x-4 m-0">
                            <Layers3 />
                            <SelectValue placeholder="Select a category" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                            <SelectLabel>Category</SelectLabel>
                            {categories && categories.map((category) => {
                                return (
                                    <SelectItem key={category.id} value={category.id}>
                                        {category.topics_category}
                                    </SelectItem>
                                );
                            })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
     );
}
 
export default CategoryComponent;