//import { useAccessToken } from '../hooks/auth-store';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from "sonner";
import NavigationService from '../lib/navigateUtility';
import { getAuthStoreActions } from '../hooks/auth-store';

//import { AxiosInstance } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8', // Specifies the content type of the request body
    'Accept': 'application/json', // Specifies the media type the client can handle
    // 'Authorization': 'Bearer YOUR_TOKEN_HERE', // Uncomment and replace YOUR_TOKEN_HERE with your actual token for secured endpoints
    // 'X-CSRF-Token': 'YOUR_CSRF_TOKEN_HERE', // Uncomment and replace YOUR_CSRF_TOKEN_HERE if your backend requires CSRF protection
  },
  // Additional configuration like timeout, withCredentials, etc.
  withCredentials: true, // Indicates whether or not cross-site Access-Control requests should be made using credentials
});

/*
// Interceptors can be used for dynamically setting or updating headers before each request
instance.interceptors.request.use(config => {
  // Example: Updating the Authorization header with a token from localStorage/sessionStorage
  //const token = localStorage.getItem('token');
  const accessToken = useAccessToken();
  //console.log('hitting the interceptor');
  //console.log('accessToken', accessToken);
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
//console.log('Updated config:', config); // Check the modified config
  return config;
}, error => {
  return Promise.reject(error);
});
*/

// Response Interceptor
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    //console.log("response", response);
    return response;
  },
  async (error) => {
    //console.log("error", error);
    if (error.response.status === 401) {
      //console.log("401 error");
      try {
        //const newAccessToken = await refreshToken();
        await refreshToken();
        // Retry the original request with the new token
        const originalRequest = error.config;
        //console.log("originalRequest", originalRequest);
        // originalRequest.headers.Authorization = `Bearer ${newAccessToken.token}`;
        return await axios(originalRequest);
      } catch (error_1) {
        // Handle refresh token error or redirect to login
        const navigate = NavigationService.getNavigate();
        const { setIsAuthenticated } = getAuthStoreActions();
        setIsAuthenticated(false); // Reset the authentication state
        navigate('/SignIn'); // Redirect to sign-in page
        toast.error("Your session is expired. Please login again.");
        throw error_1;
      }
    }
    return Promise.reject(error);
  }
);

async function refreshToken(): Promise<any> {

  try {
    //console.log("refreshToken");
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/refresh`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8', // Specifies the content type of the request body
          'Accept': 'application/json', // Specifies the media type the client can handle
          // 'Authorization': 'Bearer YOUR_TOKEN_HERE', // Uncomment and replace YOUR_TOKEN_HERE with your actual token for secured endpoints
          // 'X-CSRF-Token': 'YOUR_CSRF_TOKEN_HERE', // Uncomment and replace YOUR_CSRF_TOKEN_HERE if your backend requires CSRF protection
        },
      }
    );
    //console.log("response from refreshapi ", response);
    return response.data; // Assuming response contains the new access token
  } catch (error) {
    //console.error("Error refreshing token:", error);

    throw error; // Propagate error to caller
  }
}

export default instance;