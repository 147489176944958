import { z } from "zod";

// Define the status schema
const StatusSchema = z.object({
    id: z.string(),
    name: z.string(),
  });


// Define the Contact schema
const ContactSchema = z.object({
    id: z.string(),
    firstName: z.optional(z.string()),
    lastName: z.optional(z.string()),
    job_title: z.optional(z.string()),
    email: z.optional(z.string().email()),
    phone_number: z.optional(z.string()),
  });

// Define the Company schema
const CompanySchema = z.object({
    id: z.optional(z.string()),
    name: z.optional(z.string()),
    role: z.optional(z.string().nullable()),
    contacts: z.optional(z.array(ContactSchema)),
  });

// Define the Note schema
const NoteSchema = z.object({
    id: z.optional(z.string()),
    text: z.optional(z.string().nullable()),
  });

// Define the Action schema (reuse your existing schema)
const UpdateAction = z.object({
    id: z.optional(z.string()),
    board: z.string(),
    title: z.optional(z.string()),
    status: z.optional(z.string()),
    description: z.optional(z.string().nullable()),
    deadline_date: z.optional(z.union([z.string(), z.date()]).nullable()),
    //reminder_date: z.optional(z.string().nullable()),
  });

export const UpdateOpportunity = z.object({
  id: z.string(),
  title: z.optional(z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  })),
  description: z.optional(z.string().nullable()),
  //status: z.optional(StatusSchema),
  status_id: z.optional(z.string().nullable()),
  priority: z.optional(z.string().nullable()),
  value: z.optional(z.number().nullable()), // Ensure it's a number or null
  rate: z.optional(z.number().nullable()), // Ensure it's a number or null
  start_date: z.optional(z.union([z.string(), z.date()]).nullable()), // Support both string and Date types
  end_date: z.optional(z.union([z.string(), z.date()]).nullable()),
  exp_close_date: z.optional(z.union([z.string(), z.date()]).nullable()),
  companies: z.optional(CompanySchema),
  last_note: z.optional(NoteSchema),
  action_to_update: z.optional(UpdateAction), // Actions is required but can be empty
});
