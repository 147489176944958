import React from 'react';
import { NavLink } from 'react-router-dom';
import img_contacts from '../../../img/landing/contacts.svg';
import img_timeline from '../../../img/landing/contacts/Timeline-bro.svg';
import img_engagement from '../../../img/landing/contacts/Contact us-bro.svg';
import img_opportunitiies from '../../../img/landing/contacts/Efficiency-bro.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';



const LandingContacts = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#1E1F24] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h1 className="text-4xl font-bold text-contacts_primary dark:text-dark_contacts">Contacts</h1>
                        <p className="mt-4 text-lg lg:text-xl text-[#107362] dark:text-[#78CDB9]">
                            {t("landing.landingContacts.tagline")}
                        </p>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_contacts} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title1")}</h2>
                                <p className="mt-2 text-[#1A3C34] dark:text-[#BCECDF]">
                                    {t("landing.landingContacts.desc1")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#C9E9E0] text-[#55AA98] dark:bg-[#193831] dark:text-[#78CDB9] font-bold px-4 py-2 rounded shadow hover:bg-[#B6E0D5] dark:hover:bg-[#22453D]">
                                        {t("landing.landingContacts.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#ECF3F1] dark:bg-[#111917] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_timeline} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title2")}</h2>
                                <p className="mt-2 text-[#1A3C34] dark:text-[#BCECDF]">
                                    {t("landing.landingContacts.desc2")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#C9E9E0] text-[#55AA98] dark:bg-[#193831] dark:text-[#78CDB9] font-bold px-4 py-2 rounded shadow hover:bg-[#B6E0D5] dark:hover:bg-[#22453D]">
                                        {t("landing.landingContacts.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_engagement} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title3")}</h2>
                                <p className="mt-2 text-[#1A3C34] dark:text-[#BCECDF]">
                                    {t("landing.landingContacts.desc3")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#C9E9E0] text-[#55AA98] dark:bg-[#193831] dark:text-[#78CDB9] font-bold px-4 py-2 rounded shadow hover:bg-[#B6E0D5] dark:hover:bg-[#22453D]">
                                        {t("landing.landingContacts.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#ECF3F1] dark:bg-[#111917] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_opportunitiies} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#107362] dark:text-[#55AA98]">{t("landing.landingContacts.title4")}</h2>
                                <p className="mt-2 text-[#1A3C34] dark:text-[#BCECDF]">
                                    {t("landing.landingContacts.desc4")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#C9E9E0] text-[#55AA98] dark:bg-[#193831] dark:text-[#78CDB9] font-bold px-4 py-2 rounded shadow hover:bg-[#B6E0D5] dark:hover:bg-[#22453D]">
                                        {t("landing.landingContacts.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <NavLink to="/signup" className="mt-4 bg-[#C9E9E0] text-[#55AA98] dark:bg-[#193831] dark:text-[#78CDB9] font-bold px-4 py-2 rounded shadow hover:bg-[#B6E0D5] dark:hover:bg-[#22453D]">
                            {t("landing.landingContacts.cta2")}
                        </NavLink>
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
}

export default LandingContacts;
