import React from "react";

const SkeletonRow: React.FC<{ columnsCount: number }> = ({ columnsCount }) => (
  <div className="animate-pulse flex space-x-4">
    {Array.from({ length: columnsCount }, (_, index) => (
      <div key={index} className="flex-1 py-4 space-y-4">
        <div className="h-4 bg-gray-300 rounded"></div>
      </div>
    ))}
  </div>
);

export default SkeletonRow;
