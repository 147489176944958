import { CalendarCheck, CalendarRange, DatabaseZap, NotebookText, Trash, Trash2 } from "lucide-react";
import { toast } from "sonner";

import { OppyTitleUnit } from "./OppyTitleUnit";
import { OppyDescriptionUnit } from "./OppyDescriptionUnit";
import { OppyPriorityUnit } from "./OppyPriorityUnit";
import { OppyStatusUnit } from "./OppyStatusUnit";
import { OppyRateUnit } from "./OppyRateUnit";
import { OppyValueUnit } from "./OppyValueUnit";
import { OppyPeriodStartDateUnit } from "./OppyPeriodStartDateUnit";
import { OppyPeriodEndDateUnit } from "./OppyPeriodEndDate";
import { OppyExpCloseDateUnit } from "./OppyExpCloseDateUnit";
import { OppyNoteUnit } from "./OppyNotesUnit";
import OppyActionsUnit from "./OppyActionsUnit";
import { OppyCompanyUnit } from "./OppyCompanyUnit";

import { Card, CardHeader, CardContent, CardTitle } from "../../../../../components/ui/card";

import { Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchDeleteOpportunity } from "../../../../../api/contacts/opportunities/delete-opportunity";
import { useTranslation } from "react-i18next";
import { cn } from "../../../../../lib/utils";

interface OppyCardProps {
    data: Opportunity;
    setNewOppy?: React.Dispatch<React.SetStateAction<Boolean>>;
}

const OppyCard = ({
    data,
    setNewOppy,
}: OppyCardProps) => {

    const { t } = useTranslation();

    // Save changes
    const { deleteOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateOpportunityDeleteContact } = useAction(fetchDeleteOpportunity, {
        onSuccess: (data: { id: string }) => {
            data.id && deleteOpportunity(data.id);
            toast.success(`Opportunity deleted`);
        },
        onError: (error) => {
            toast.error("An error occurred");
        }
    });

    const handleOpportunityDeletion = (opportunityId: string) => {
        if (opportunityId !== "") {
            executeUpdateOpportunityDeleteContact({
                id: opportunityId,
            });
        } else {
            setNewOppy?.(false);
        }
    };

    

    return (
        <div 
            className="w-full h-full"
        >
            <Card 
                key={data.id}
                //className="flex flex-col my-2 p-1 bg-white dark:bg-[#0D1723] shadow-md rounded-none max-w-full h-full" //rounded-lg
                className="flex flex-col my-2 p-1 bg-white dark:bg-[#0D1723] shadow-md rounded-none max-w-full w-full h-full"
            >
                <CardHeader
                    className="text-contacts_primary w-full text-lg font-bold py-1 px-2"
                >
                    <CardTitle
                        //className="flex flex-col md:flex-row w-full items-center justify-between text-lg md:text-xl font-bold  m-0 p-0 gap-x-2 text-[#133867] dark:text-[#D1E7FF]"
                        className="flex flex-row w-full items-center justify-between text-lg md:text-xl font-bold m-0 p-0 gap-x-2 text-[#133867] dark:text-[#D1E7FF]"
                    >
                        <div className="flex-grow min-w-0">
                            <OppyTitleUnit data={data} setNewOppy={setNewOppy} />
                        </div>
                        
                        <div 
                            className="flex flex-row gap-x-2 items-center flex-shrink-0"
                            //flex flex-row gap-x-2 items-center flex-shrink-0
                            //flex flex-row gap-x-2 items-center
                        > 
                            <OppyStatusUnit data={data} />
                            <OppyPriorityUnit data={data} />
                            <button
                                onClick={() => handleOpportunityDeletion(data.id ?? "")}
                                className="text-contacts_primary text-xs inline"
                            >
                                <Trash2
                                    className="h-4 w-4"
                                />
                            </button>
                        </div>
                    </CardTitle>
                    
                </CardHeader>
                <CardContent
                    className={cn("flex flex-col md:flex-row items-start px-1 py-1 w-full h-full gap-x-2 gap-y-2",
                        !data.title ? "italic text-gray-400" : ""
                    )}
                    //className="flex flex-row items-start px-1 py-1 w-full h-full gap-x-2"
                >
                    {/* Company contacts */}
                    <div 
                        id="company-contacts" 
                        className="flex flex-col p-1 text-sm md:border-r-2 w-full md:w-1/5 h-full gap-y-2"
                        //className="flex flex-col p-1 text-sm border-r-2 w-1/5 h-full gap-y-2"
                    >
                        <OppyCompanyUnit data={data} />
                    </div>

                    {/* Description - notes */}
                    <div 
                        id="description-note" 
                        className="flex flex-col text-sm p-1 md:border-r-2 w-full md:w-2/5 h-full gap-y-3"
                        //className="flex flex-col text-sm p-1 border-r-2 w-2/5 h-full gap-y-3"
                    >
                        <div className="flex flex-col">
                            <span className="flex flex-row gap-x-1 items-center pb-2 font-medium w-full h-full">
                                <NotebookText className="h-4 w-4" />{t("contacts.opportunities.description.title")}
                            </span>
                            <OppyDescriptionUnit data={data} />
                        </div>
                        <div className="flex flex-col w-full h-full">
                            <OppyNoteUnit data={data} />
                        </div>
                    </div>

                    {/* Key points */}
                    <div 
                        id="indicators" 
                        className="flex flex-col text-sm p-1 md:border-r-2 w-full md:w-1/5 h-full gap-y-2"
                        //className="flex flex-col text-sm p-1 border-r-2 w-1/5 h-full gap-y-1"
                    >
                        <span className="flex flex-row gap-x-1 items-center pb-2 font-medium">
                                <DatabaseZap className="h-4 w-4" />{t("contacts.opportunities.keyPoints.keyPoints")}
                            </span>
                        <div className="flex flex-row gap-x-1 items-center pl-2">
                            <OppyRateUnit data={data} />
                        </div>
                        <div className="flex flex-row gap-x-1 items-center pl-2">
                            <OppyValueUnit data={data} />
                        </div>
                        <div className="flex flex-col pl-2">
                            <div className="flex items-center gap-x-1">
                                <CalendarRange className="h-4 w-4" />
                                <span>{t("contacts.opportunities.keyPoints.period.period")}</span>
                            </div>
                            <div className="flex flex-col ml-2">  {/* Indent the dates under "Period" */}
                                <OppyPeriodStartDateUnit data={data} />
                                <OppyPeriodEndDateUnit data={data} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-x-1 items-start pl-2">
                            <div className="flex items-center gap-x-1">
                                <CalendarCheck className="h-4 w-4" />
                                <span>{t("contacts.opportunities.keyPoints.expClosing.expClosing")}</span>
                            </div>
                            <div className="flex flex-col ml-2">  {/* Indent the dates under "Period" */}
                                <span><OppyExpCloseDateUnit data={data} /></span>
                            </div>
                        </div>
                    </div>
                    
                    {/* Actions */}
                    <div 
                        id="actions" 
                        //className="flex flex-col text-sm p-1 w-full md:w-1/5 h-full gap-y-1"
                        className="flex flex-col text-sm p-1 w-1/5 h-full gap-y-1"
                    >
                        <OppyActionsUnit data={data} />
                    </div>
                </CardContent>
            </Card>
        </div>
     );
}
 
export default OppyCard;


/*
<div className="flex flex-row items-center gap-x-1 pb-2 font-medium">
    <DirectionsRunIcon className="w-4 h-4" />
    <span>Next actions</span>
</div>

{data?.actions?.map((action: any) => (
    action.status === "DONE" ? (
        <div className="flex flex-row items-start gap-x-2">
            <div className="pt-0.5">
                <SquareCheck className="h-4 w-4" />
            </div>
            <div className="line-through">
                {action.title}
            </div>
        </div>
    ) : (
        <div className="flex flex-row items-start gap-x-2">
            <div className="pt-0.5">
                <Square className="h-4 w-4" />
            </div>
            <div>
                {action.title}
            </div>
        </div>
    )
))} 
*/

/* companies
<div className="flex flex-row gap-x-1 items-center font-medium">
    <Landmark className="h-4 w-4" /> Client / Prospect
</div>
{data.companies?.map((company: any) => (
    <div className="flex flex-col justify-start pl-2">
        <div className="flex flex-row justify-start items-center gap-x-1 pl-2">
            {(company.role === "intermediary") ? (
                <span className="">
                    <ActionTooltip label="Intermediary">
                        <ChevronsLeftRightEllipsis className="h-4 w-4" />
                    </ActionTooltip>
                </span>
            ) : (
                <span>
                    <ActionTooltip label="Final Client">
                        <Shield className="h-4 w-4" />
                    </ActionTooltip>
                </span>
            )}
            <div className="pl-1 font-semibold">
                {company.name}
            </div>
        </div>
        <div className="pl-6">
            {company.contacts.map((contact: any) => (
                <div className="flex flex-col pl-2">
                    <div className="text-sm">
                        {contact.firstName} {contact.lastName}
                    </div>
                    <div className="text-xs italic">
                        {contact.job_title}
                    </div>
                </div>
            ))}
        </div>
    </div>
))}
*/

const sampleOppyData: any = {
    title: "Data Engineer Python",
    description: "As a member of the IoT laboratory, you will analyse the data collected from the hundred of sensors of our product",
    status:"In progress",
    priority:"high",
    value:150000,
    rate:680,
    start_date:"10/08/2024",
    end_date:"10/08/2025",
    exp_close_date:"10/09/2024",
    companies : [
        {
            id: "xxx",
            name: "Accenture",
            role: "intermediary",
            contacts: [
                {
                    id:"zzzz",
                    firstName: "Sébastien",
                    lastName: "Lemaquis",
                    job_title: "Data analysis partner"
                }
            ]
        },
        {
            id: "xxy",
            name: "AXA",
            role: "final-client",
            contacts: [
                {
                    id:"zzzz",
                    firstName: "John",
                    lastName: "Doe",
                    job_title: "Head of datascience",
                    email: "john.doe@axa.com"
                }
            ]
        }
    ],
    last_note: {
        id:"yyy",
        text:"This is our last note, we are still waiting from the client budget confirmation"
    },
    last_actions: [
        {
            id: "aaaa",
            title: "Analyse opportunity",
            status: "DONE"
        },
        {
            id: "bbbb",
            title: "Call John for context and information",
            status: "IN_PROGRESS"
        },
        {
            id: "cccc",
            title: "Send proposal",
            status: "TO_DO"
        }
    ]
}