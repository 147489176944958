import { useState, useEffect } from "react";
import { DeleteGroupModal } from "./delete-group-modal";
import { DeleteInterestModal } from "./delete-interest-modal";


export const ModalProvider = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <DeleteGroupModal />
            <DeleteInterestModal />
        </>
    )
}