import { z } from "zod";

export const UpdatePlanElement = z.object({
  id: z.string(),
  plan_id: z.string(),
  parent_elt: z.string().optional(),
  title: z.string().min(3, "Title is too short").optional(),
  description: z.string().min(3, "Description is too short").optional(),
  previous_elt: z.optional(z.string()),
  next_elt: z.optional(z.string()),
  category: z.optional(z.enum(["MISSION", "GOCHAT", "GOLEARN", "TECHWATCH", "VISIBILITY", "PERSONAL", "MAIN", "NONE"])),
  type: z.optional(z.enum(["STEP", "PROJECT", "TASK"])),
  status: z.optional(z.enum(["TO_DO", "IN_PROGRESS", "DONE"])),
  year: z.optional(z.number()),
  deadline_date: z.optional(z.date()),
  start_date: z.optional(z.date()),
  end_date: z.optional(z.date()),
  deleted: z.optional(z.boolean()),
  created_at: z.optional(z.date()),
  updated_at: z.optional(z.date()),
});