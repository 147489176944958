import { FormInput } from "../../../components/form/form-input";
import { Button } from "../../../components/ui/button";
import { Board } from "../../../interfaces/tasks/int-board";
import { updateBoard } from "../../../api/tasks/update-board";
import { useAction } from "../../../hooks/use-action";

import { ElementRef, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useSelectedBoardElement, useSelectedBoard, useTaskStoreActions } from "../../../hooks/tasks-store";
import { t } from "i18next";

import { FieldErrors } from "../../../lib/create-safe-actions";

interface BoardTitleFormProps {
    data: Board;
    isEditingOption?: boolean;
    setIsEditingOption: (value: boolean) => void;
}

interface UpdateBoardFormData {
    title: string;
    id: string;
  }

export const BoardTitleForm = ({
    data,
    isEditingOption,
    setIsEditingOption,
}: BoardTitleFormProps) => {

    const { getBoardbyId, updateOneBoard } = useTaskStoreActions();
    const selectedBoard = useSelectedBoard() || "";
    const selectedBoardElement = getBoardbyId(selectedBoard); //useSelectedBoardElement();

    // Local state for fieldErrors
    const [localFieldErrors, setLocalFieldErrors] = useState<FieldErrors<UpdateBoardFormData> | undefined>();

    // Local state for title input
    const [title, setTitle] = useState(data.title);

    const { execute, fieldErrors } = useAction(updateBoard, {
        onSuccess: (responseData) => {
            //console.log("saved success : ",responseData);
            toast.success(`Board "${responseData.title}" updated!`);
            //setTitle(responseData.title);
            updateOneBoard({
                ...data,
                title: responseData.title,
            });
            //setLocalFieldErrors(undefined); // Reset fieldErrors on success
            disableEditing();
        },
        onError: (error) => {
            toast.error(error || "An error occurred");
            //setLocalFieldErrors(error.fieldErrors);
        },
    });

    const formRef = useRef<ElementRef<"form">>(null);
    const inputRef = useRef<ElementRef<"input">>(null);

    //const [title, setTitle] = useState(data.title);
    const [isEditing, setIsEditing] = useState(false);

    const enableEditing = () => {
        //TODO : Focus inputs
        setIsEditing(true);
        setTimeout(() => {
            inputRef.current?.focus();
            inputRef.current?.select();
        })
    };

    const disableEditing = () => {
        setIsEditingOption(false);
        setIsEditing(false);
    };

    useEffect(() => {
        if (isEditingOption) {
            enableEditing();
        }

    }, [isEditingOption]);

    //const onSubmit = (formData: FormData) => {
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        //console.log('hitting on submit : ', title);
        if (selectedBoardElement?.title !== title) {

            if (title === "Overview") { 
                toast.error("Title cannot be 'Overview'");
                setLocalFieldErrors(undefined); // Reset fieldErrors
                disableEditing();
            } else {
                execute({
                    title,
                    id: data.id || "", // change with a condition to check if data.id is undefined
                });
            }
        } else {
            disableEditing(); // If no change, simply disable editing
            //setLocalFieldErrors(undefined); // Reset fieldErrors
        }
    };

    useEffect(() => {
        if (localFieldErrors?.title) {
            toast.error(localFieldErrors.title);
        }
    }, [localFieldErrors?.title]);

    const onBlur = () => {
        formRef.current?.requestSubmit();
    }

    /*
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        if (localFieldErrors) {
            setLocalFieldErrors(undefined); // Reset fieldErrors when input changes
        }
    };
    */

    if (isEditing) {
        return (
            <form 
                onSubmit={onSubmit} 
                ref={formRef} 
                className="flex flex-grow items-center gap-x-2 py-0 my-0"
            >
                <FormInput 
                    ref={inputRef}
                    id="title"
                    onBlur={onBlur}
                    //value={title}
                    defaultValue={selectedBoardElement?.title}
                    errors={fieldErrors}
                    //onChange={onChange}
                    className="text-lg font-bold px-[7px] my-0 py-0 h-7 bg-transparent focus-visible:outline-none focus-visible:ring-transparent text-neutral_white-300 dark:text-[#FFDECD] border-none"
                />
            </form>
        )
    }

    return (
        (selectedBoardElement?.title === "Overview") ? (
            <div
                className="flex flex-grow text-neutral_white-200 dark:text-neutral_white-200 items-center font-bold text-lg h-auto w-auto p-1 px-2"
            >
                {selectedBoardElement?.title}
            </div>
        ) : (
            <Button
                onClick={enableEditing}
                className="flex flex-grow text-neutral_white-200 dark:text-neutral_white-200 items-center font-bold text-lg h-auto w-auto p-1 px-2"
                variant="transparent"
            >
                {selectedBoardElement?.title}
            </Button>
        )
    );
};