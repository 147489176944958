import { Button } from "../../../../components/ui/button";
import { Skeleton } from "../../../../components/ui/skeleton";

import { Copy, LogOut, Trash } from "lucide-react";

import { useAction } from "../../../../hooks/use-action";

import { toast } from "sonner";

import { PlanElement } from "../../../../api/career/interface-career";
import { createPlanElement } from "../../../../api/career/create-plan-element";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { useModal } from "../modals/use-modal-store";
import { deletePlanElement } from "../../../../api/career/delete-plan-element";
import { useTranslation } from "react-i18next";

interface ActionsProps {
    data:  PlanElement;
  };

export const Actions = ({
    data
}: ActionsProps) => {
    const { t } = useTranslation();

    const id = useModal((state) => state.data.planElementId);
    const isOpen = useModal((state) => state.isOpen);
    const onClose = useModal((state) => state.onClose);

    const { getPlanElement, updateMultiplePlanElementHook, deletePlanElementHook } = useCareerHookActions();
    const selectedElement: PlanElement | undefined = data.id ? getPlanElement(data.id) : undefined;
    
    const { 
        execute: executeCopyPlanElement,
        isLoading: isLoadingCopy,
    } = useAction(createPlanElement, {
        onSuccess: (data) => {
            //console.log("hitting copy card API call", data);
            updateMultiplePlanElementHook(data);
            toast.success(`Card "${data[0].title}" copied`);
            onClose();
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const { 
        execute: executeDeletePlanElement, 
        isLoading: isLoadingDelete,
    } = useAction(deletePlanElement, {
        onSuccess: (data) => {
            deletePlanElementHook(data);
            toast.success(`Card "${data[0].title}" deleted`);
            onClose();
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const onCopy = () => {
        if (!selectedElement) return;

        const { created_at, updated_at, ...selectedElementToCopy } = selectedElement;

        //console.log("selectedElementToCopy", selectedElementToCopy);

        //console.log(selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined);

        executeCopyPlanElement({
            ...selectedElementToCopy,
            id: selectedElement?.id || data.id,
            title: "Copy of " + selectedElement?.title || "Copy of " + data.title,
            plan_id: selectedElement?.plan_id || data.plan_id,
            start_date: selectedElement?.start_date ? new Date(selectedElement?.start_date) : undefined,
            end_date:  selectedElement?.end_date ? new Date(selectedElement?.end_date) : undefined,
            deadline_date:  selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined,
            parent_elt: selectedElement?.parent_elt || data.parent_elt || undefined,
            previous_elt: selectedElement?.previous_elt || undefined,
            next_elt: selectedElement?.next_elt || undefined,
            //type: selectedElement?.type || data.type,
        });
    };

    const onDelete = () => {

        executeDeletePlanElement({
            id: data.id,
        });
    };

    return (
        <div className="space-y-2 mt-2">
            <p className="text-xs font-semibold text-neutral-700 dark:text-neutral-300">
                {t("tasks.card.edit.actions.title")}
            </p>
            <div className="flex md:flex-col items-start justify-start gap-1 w-full lg:w-2/3">
                <Button
                    //onClick={onCopy}
                    disabled={isLoadingCopy}
                    variant="career"
                    className="w-full justify-start bg-career_primary"
                    size="sm"
                >
                    <LogOut className="h-3 w-3" />
                    Move
                </Button>
                <Button
                    onClick={onCopy}
                    disabled={isLoadingCopy}
                        variant="career"
                    className="w-full justify-start"
                    size="sm"
                >
                    <Copy className="h-3 w-3" />
                    Copy
                </Button>
                <Button
                    onClick={onDelete}
                    disabled={isLoadingDelete}
                    variant="career"
                    className="w-full justify-start"
                    size="sm"
                >
                    <Trash className="h-3 w-3" />
                    Delete
                </Button>
            </div>
            
        </div>
    );
};



Actions.Skeleton = function ActionsSkeleton() {
    return (
      <div className="space-y-2 mt-2">
        <Skeleton className="w-20 h-4 bg-neutral-200" />
        <Skeleton className="w-full h-8 bg-neutral-200" />
        <Skeleton className="w-full h-8 bg-neutral-200" />
      </div>
    );
  };
