import * as React from "react"
import { useTranslation } from "react-i18next";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

import { Table } from "@tanstack/react-table";

//import { Button } from "@/registry/new-york/ui/button";
import { Button } from "../../../../components/ui/button";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select"

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../../components/ui/pagination"

interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  //handle situation where > 10 pages
  const currentPage = table.getState().pagination.pageIndex + 1;
  const { t } = useTranslation();
  
  const pages = [];
  if (currentPage < 4 ) {
    const endPage = Math.min(5, table.getPageCount()-1);
    for (let i = 2; i <= endPage; i++) {
      pages.push(i);
    }
  } else {
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(currentPage + 2, table.getPageCount()-1);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  }
  //console.log("pages: ", pages);
  //console.log("currentPage: ", currentPage);

  return (
    <div className="flex items-center justify-between p-4">
      <div className="hidden md:flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "} {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="hidden md:block text-sm font-medium">
            {t("tasks.table.footer.pages")}
          </p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        <div className="flex items-center space-x-2">
        <Pagination>
          <PaginationContent>
            {currentPage > 1 && (
              <PaginationItem>
                <PaginationPrevious 
                  onClick={() => table.previousPage()}
                  className="text-tasks_primary cursor-pointer"
                />
              </PaginationItem>
            )}
            
            <PaginationItem>
              <PaginationLink 
                onClick={() => table.setPageIndex(0)}
                className={currentPage === 1 ? "bg-tasks_primary text-white" : "text-tasks_primary"}
              >
                1
              </PaginationLink>
            </PaginationItem>
          
              
            {pages[0] > 2 && (
              <PaginationItem>
                <span className="mx-1 text-tasks_primary">...</span>
              </PaginationItem>
              )}

            {pages.map((i) =>
              <PaginationItem key={i}>
                <PaginationLink
                  onClick={() => table.setPageIndex(i-1)}
                  className={currentPage === i ? "bg-tasks_primary text-white" : "text-tasks_primary"}
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
            )}

            {pages[pages.length - 1] < table.getPageCount() - 1 && (
              <PaginationItem>
                <span className="mx-1 text-tasks_primary">...</span>
              </PaginationItem>
            )}
              
            
            {table.getPageCount() !== 1 && (
            <PaginationItem>
              <PaginationLink 
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                className={currentPage === table.getPageCount() ? "bg-tasks_primary text-white" : "text-tasks_primary"}
              >
                {table.getPageCount()}
              </PaginationLink>
            </PaginationItem>
            )}
            
            {currentPage < table.getPageCount() && (
              <PaginationItem>
                <PaginationNext 
                  onClick={() => table.nextPage()}
                  className="text-tasks_primary cursor-pointer"
                />
              </PaginationItem>
            )}
            
          </PaginationContent>
        </Pagination>
        </div>

        {/*
          <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
                </div>
          */}
      </div>
    </div>
  )
}

/*
24/08 : Pagination Ellipsis retiré car cause une seconde scrollbar -> remplacé par "..."
<PaginationEllipsis />
*/