import { useEffect, useState } from "react";
import { DetailedContact } from "../../../../../interfaces/contacts/int-contacts";
import { fetchDetailedContactData, fetchUpdateContactDetailsData } from "../../../../../api/contacts/api-getContactData";
import { getContactsHookActions, useNewContact, useSelectedContact } from "../../../../../hooks/contact-hook";
import { Pencil, Save } from "lucide-react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../../../../components/ui/select"
import { useToast } from "../../../../../components/ui/use-toast";
import { Button } from "../../../../../components/ui/button";
import RelatedContacts from "./RelatedContacts";

const ContactInfoTable = () => {
        //initialize toast
        const { toast } = useToast();
        
        //Retrieve selected contact from the store
        const selectedContact = useSelectedContact();
        // retrieve the step of the new contact
        const { setNewContact } = getContactsHookActions(); // to be deleted if not used
        const newContact = useNewContact();

        //Detailed contacts : to be moved to contact info details page
        const [detailedContactData, setDetailedContactData] = useState<DetailedContact>({
            address: '',
            con_links: [],
            })
        
        // State to manage edit mode
        const [editMode, setEditMode] = useState(newContact === "Informations" ? true : false);
        const [editableContact, setEditableContact] = useState<DetailedContact>({} as DetailedContact);

        /*const [editableContact, setEditableContact] = useState<DetailedContact>({
            address: '',
            con_links: [],
            });
        */
        useEffect(() => {
            
            const getDetailedContactData = async () => {
                try {
                    const response = await fetchDetailedContactData(selectedContact as string); // Ensure selectedContact is always a string
                    setDetailedContactData({
                        address: response.address || '',
                        con_links: response.con_links || [],
                    });
                    setEditableContact({
                        address: response.address || '',
                        con_links: response.con_links || [],
                    });
                } catch (err) {
                    //console.log("error: ", err);
                }
            };
            if (newContact === "Informations") {
                setEditableContact({
                    address: '',
                    con_links: [
                        { link: '', label: '' },
                        { link: '', label: '' },
                        { link: '', label: '' },
                    ],
                });
                setEditMode(true);
            } else if (selectedContact) {
                getDetailedContactData();
            }
            
        }, []);
        
        // Function to handle the input change
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.name.startsWith('con_links')) {
                const match = e.target.name.match(/^con_links\[(\d+)\]\.(label|link)$/);
                if (match) {
                    const index = parseInt(match[1]);
                    const field = match[2];
        
                    // Check if editableContact.con_links is defined
                    if (editableContact.con_links) {
                        const newConLinks = [...editableContact.con_links];
                        const updatedLink = { ...newConLinks[index], [field]: e.target.value };
                        newConLinks[index] = updatedLink;
        
                        setEditableContact({ ...editableContact, con_links: newConLinks });
                    }
                }
            } else {
                setEditableContact({ ...editableContact, [e.target.name]: e.target.value });
            }
        };
        
        // Function to handle saving the edited contact
    const handleSave = async (type: string) => {
        // Here you would send the updated contact information to your server
        //console.log('Saving contact:', editableContact);
        if (selectedContact) {
            try {
                const response = await fetchUpdateContactDetailsData(selectedContact, editableContact);
                setDetailedContactData(response);
                //console.log("test response update contact : " + response)
                setEditMode(false);
                if (type === "next") {
                    setNewContact(undefined);
                } else if (type === "previous") {
                    setNewContact("Interests");
                }

                toast({
                    description: `Contact updated`,
                  });
            } catch (err) {
                //console.log("Can not update contact : " + err)
                toast({
                    description: `Failed to update contact`,
                  });
            }
        } 
    };

    const inputStyle = "text-base bg-transparent border-b-2 border-gray-300 p-1 m-1 rounded focus:border-blue-500 outline-none";

    return (
        
        <div>
            <RelatedContacts />

            {editMode ? (
                <div className="p-2 mb-2">
                    <div className="mb-2">
                        <h2 className="text-lg mb-2">Address</h2>
                            <input
                                className={`${inputStyle}`}
                                value={editableContact.address || ''}
                                name="address"
                                placeholder="Address"
                                onChange={handleInputChange}
                            />
                    </div>
                    <div>
                        <h2 className="text-lg mb-2">Social networks</h2>
                            {Array.isArray(editableContact?.con_links) && editableContact.con_links.map((link, index) => (
                                <div key={index} className="mb-1">
                                    <input
                                        className={`${inputStyle}`}
                                        value={link.label || ''}
                                        name={`con_links[${index}].label`}
                                        placeholder="Social network"
                                        onChange={handleInputChange} 
                                    />
                                    <input
                                        className={`${inputStyle}`}
                                        value={link.link || ''}
                                        name={`con_links[${index}].link`} 
                                        placeholder="Link"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            ))}
                            <div className="flex flex-row justify-end gap-x-2">
                                {newContact && (
                                    <Button
                                        onClick={() => handleSave("previous")}
                                        className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base"
                                    >
                                        Previous
                                    </Button>
                                )}
                                
                                <Button 
                                    onClick={() => handleSave("next")}
                                    className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base"
                                >
                                    Save contact
                                </Button>
                            </div>
                            
                        </div>
                </div>
            ) : (
                <div className="p-2 mb-2 w-full">
                    <div className="flex justify-between" >
                        <div className="mb-2">
                            <h2 className="text-lg mb-2">Address</h2>
                            <p>Address: {detailedContactData?.address}</p>
                        </div>
                        <div className="">
                            <button className="bg-contacts_primary justify-between ml-4 hover:bg-contacts_secondary text-white font-bold py-1 px-2 rounded" onClick={() => setEditMode(true)}>
                                <Pencil size={16}/>
                            </button>
                        </div>
                    </div>
                    <div className="mt-4"> 
                        <h2 className="text-lg mb-2">Social networks</h2>
                        {Array.isArray(detailedContactData?.con_links) ? 
                            detailedContactData?.con_links.map((link, index) => (
                                <div key={index} className="mb-1 flex items-center gap-4">
                                    {/* To be updated to include URL domain name display instead of the full link*/}
                                    {link.label && <div className="flex">{link.label} : </div>}
                                    {link.link && <div className="flex"><a href={link.link} target="_blank" rel="noopener noreferrer">{link.link}</a></div>}
                                </div>
                            ))
                            : (
                                <div className="flex items-center justify-center">
                                    <p>No known social networks</p>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </div>
     );
};
 
export default ContactInfoTable;