import { useSelectedServer } from "../../hooks/community-hook";
import { WebSocketProvider } from "../../hooks/socket-context";
//import ComMessages from "./_components/root/com-messages";
import { ModalProvider } from "./_components/modals/modal-provider";
import ComChannels from "./_components/root/com-channels";
import ComServer from "./_components/root/com-servers";


const Commmunity = () => {
    const selectedServer = useSelectedServer();
    //const selectedMessages = useSelectedMessages();

    return (
        <WebSocketProvider>
            <ModalProvider />
            <div className="flex flex-grow overflow-hidden">
                {selectedServer ? (
                     <ComChannels />
                )/*: (selectedMessages ? (
                        <ComMessages />
                    )*/
                :(
                    <ComServer />
                )}
            </div>
        </WebSocketProvider>
    );
}
 
export default Commmunity;