import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../../lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        default_simple: "text-white bg-neutral_blue",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        primary: "bg-sky-700 text-primary-foreground hover:bg-sky-700/90",
        transparent: "bg-transparent text-white hover:bg-white/20",
        gray: "bg-neutral-200 text-secondary-foreground hover:bg-neutral-300",
        tasks: "bg-tasks_primary hover:bg-tasks_primary/90 text-neutral_white-200",
        landing_full: "border border-neutral_blue text-white bg-neutral_blue hover:bg-white hover:text-neutral_blue",
        landing_outline: "border border-neutral_blue text-neutral_blue bg-white hover:bg-neutral_blue hover:text-white",
        //career: "flex-grow flex justify-center items-center text-xs bg-career_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4 sm:w-1/2",
        career: "text-xs bg-career_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4",// sm:w-1/2"
        task: "text-xs bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] gap-2 py-2 px-4",// sm:w-1/2"
        contacts: "bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2",
        contacts_primary: "bg-[#58AD9A] dark:bg-[#193831] text-[#DBEFE9] hover:bg-[#DBEFE9] hover:text-contacts_primary dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] font-bold py-1 px-2"
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
