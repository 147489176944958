import { InputType, ReturnType } from "./types";
import { createSafeAction } from "../../../../lib/create-safe-actions";
import { DeleteOpportunityTask } from "./schema";

import axiosInstance from "../../../api";

import { Opportunity } from "../../../../interfaces/contacts/int-opportunity";

import { ActionState } from "../../../../lib/create-safe-actions";

const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting create card API call");
    try {
        //console.log('data create card API call :', data);
        const response = await axiosInstance.post<Opportunity>(`/contact-opportunity/deleteOpportunityTask`, data);
        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const fetchDeleteOpportunityTask = createSafeAction(DeleteOpportunityTask, handler);