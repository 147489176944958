import { 
    AccordionContent, 
    AccordionItem, 
    AccordionTrigger 
} from "../../../../components/ui/accordion";


import { cn } from "../../../../lib/utils";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { useEffect, useState } from "react";
import { ServerData, findServers } from "../../../../api/community/api-display-server";
import { useCommunityActions, useModifyChannel, useSelectedChannel, useSelectedCurrentMember, useSelectedServer } from "../../../../hooks/community-hook";
import { ChannelData, fetchChannels, fetchGeneralChannel } from "../../../../api/community/api-get-channels";
import { useModal } from "../modals/use-modal-store";
import { ActionTooltip } from "../../../../components/action-tooltip";
import { ArrowBigLeft, Plus } from "lucide-react";
import { ChannelSettings } from "./channel-settings";
import React from "react";
import { useGeneralHookActions } from "../../../../hooks/general-hook";

interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string; //Organization;
    onExpand: (id: string) => void;
}

export const NavItemCommunity = ({
    isExpanded,
    isActive,
    membership,
    onExpand,
}: NavItemProps) => {

    //to get the servers
    const [servers, setServers] = useState<ServerData[]>([]);

    //when click on a server
    const { setSelectedServer } = useCommunityActions();
    const handleServerSelection = (id:string, name:string) => {
        setSelectedServer({id:id, name:name});
    }

    useEffect(() => {
        const getServers = async () => {
            try {
                const response = await findServers();
                setServers(response)
            } catch (error) {
                //console.log('erreur de get contact : ', error);
            }
        };
        getServers();
    }, [])

    const selectedServer = useSelectedServer();

    //to get the channels

    // Récupérer le role et l'id member du user de même que l'id et le nom de la channel
    const { setSelectedChannel } = useCommunityActions();;
    const selectedChannel = useSelectedChannel();
    const [channels, setChannels] = useState<ChannelData[]>([]);
    
    // Handle the channels refresh after creating a new channel -> TO DO : Refactor so we have servers and channels for a server in the store
    const modifyChannel: boolean = useModifyChannel();

    // Close hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();

    const handleChannelSelection = (id:string, name:string) => {
        setSelectedChannel({id:id, name:name});
        closeHamburgerMenuSheet();
    }

    useEffect(() => {

        const loadChannels = async () => {
            
            if(selectedServer && modifyChannel === false) {
                try {
                    const response = await fetchChannels(selectedServer?.id || "");
                    setChannels(response);

                } catch (err) {
                    //console.log("error: ", err);
                }
            }
        };
        // When a server is selected
        loadChannels();

    }, [selectedServer, modifyChannel])

    useEffect(() => {
        const loadGeneral = async () => {
            if(selectedServer && channels.length > 0) {
                //const channel = await fetchGeneralChannel(selectedServer?.id );
                const generalChannel = channels.find(channel => channel.name === "general");
                //setSelectedChannel({id: channel.id, name: channel.name});
                if (generalChannel) {
                    setSelectedChannel({id: generalChannel?.id, name: generalChannel?.name});
                } else {
                    setSelectedChannel({id: channels[0].id, name: channels[0].name});
                }
            }
        };
        if (!selectedChannel || channels.find((channel) => channel.id === selectedChannel.id) === undefined) {
            loadGeneral();
        }
    }, [channels])

    //To go back to servers
    const handleBackClick = () => {
        setSelectedServer(undefined);
        // setSelectedMessages(false);
    }

    //to modify the channel selected
    const { onOpen } = useModal();
    

    return (
        <AccordionItem
            value={membership} //organization.id
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand(membership)} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-gray-700 dark:text-gray-200 hover:font-bold rounded-md cursor-pointerransition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        {!selectedServer ? membership : selectedServer.name}
                    </span>
                </div>
            </AccordionTrigger>
            <AccordionContent className="pt-1 text-neutral-700">
                {!selectedServer ?
                    (servers.map(server => (
                        <Button
                            key={server.id}
                            //size="sm"
                            onClick={() => handleServerSelection(server.id, server.name)}
                            className={cn(
                                "w-full font-normal text-xs justify-start pl-4"
                                )}
                            variant="ghost"
                        >
                            <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-community_primary dark:hover:text-[#F8D3ED]">{server.name} </span>
                        </Button>
                    ))) : (
                        <>
                            <div className="flex pt-1 pb-3 justify-start gap-x-2 px-4">
                                <ActionTooltip
                                    side="left"
                                    align="center"
                                    label="Choose a server"
                                >
                                    <button
                                        onClick={handleBackClick}
                                        className="group flex items-center"
                                    >
                                        <div className="flex mx-3 h-[24px] w-[24px] rounded-[18px] group-hover:rounded-16[px] transition-all overflow-hidden items-center justify-center bg-background dark:bg-neutral-700 group-hover:bg-community_secondary">
                                            <ArrowBigLeft 
                                                className="h-6 w-6 group-hover:text-white transition text-community_primary dark:text-dark_community"
                                            />
                                        </div>
                                    </button>
                                </ActionTooltip>
                                <ActionTooltip
                                    side="right"
                                    align="center"
                                    label="Add a channel"
                                >
                                    <button
                                        onClick={() => onOpen("createChannel")}
                                        className="group flex items-center"
                                    >
                                        <div className="flex mx-3 h-[24px] w-[24px] rounded-[18px] group-hover:rounded-16[px] transition-all overflow-hidden items-center justify-center bg-background dark:bg-neutral-700 group-hover:bg-community_secondary">
                                            <Plus 
                                                className="h-6 w-6 group-hover:text-white transition text-community_primary dark:text-dark_community"

                                            />
                                        </div>
                                    </button>
                                </ActionTooltip>
                            </div>
                            {channels.map(channel => (
                                <div key={channel.id} className="w-full flex justify-between items-center">
                                    <Button
                                        key={channel.id}
                                        size="sm"
                                        onClick={() => handleChannelSelection(channel.id, channel.name)}
                                        className={cn(
                                            "w-full font-normal text-xs justify-start hover:bg-[#EBC7E0] dark:hover:bg-[#1D101A] pl-4 mb-1",
                                            selectedChannel && selectedChannel.id === channel.id && "font-bold bg-[#F4E2EE] dark:bg-[#542149]"
                                            )}
                                        id={channel.id}
                                        name={channel.name}
                                        variant="ghost"
                                    >
                                        <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-[#A12E8B] dark:hover:text-[#F8D3ED]">
                                            #{channel.name.length > 9 ? `${channel.name.substring(0, 9)}...` : channel.name}
                                        </span>
                                    </Button>
                                    {selectedChannel && channel.id === selectedChannel.id && (
                                        <ChannelSettings
                                            id={channel.id}
                                            name={channel.name}
                                        />
                                    )}
                                </div>
                                ))}
                        </>
                    )
                }
            </AccordionContent>
        </AccordionItem>
    );
};
 
NavItemCommunity.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}