import { Statistics } from "./Statistics";
import pilot from "../../../img/landing/main_landing/about_try1.svg" //"../../../img/main_landing/about_try2.svg";
import { useTranslation } from "react-i18next";

export const About = () => {

    const { t } = useTranslation();

  return (
    <section
      id="about"
      className="container pb-48 sm:py-32"
    >
      <div className="bg-muted/50 border rounded-lg pt-6 pb-6">
        <div className="px-6 flex flex-col-reverse md:flex-row gap-8 md:gap-12">
          <img
            src={pilot}
            alt=""
            className="w-[300px] object-contain rounded-lg"
          />
          <div className="bg-green-0 flex flex-col justify-between">
            <div className="pb-6">
              <h2 className="text-3xl md:text-4xl font-bold">
                <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
                  {t("landing.about.title1")} {" "}
                </span>
                {t("landing.about.title2")}
              </h2>
              <p className="text-lg text-muted-foreground mt-4">
                {t("landing.about.description")}
              </p>
            </div>

            <Statistics />
          </div>
        </div>
      </div>
    </section>
  );
};
