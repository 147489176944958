import { useState, useEffect } from "react";
import { Button } from "../../../components/ui/button";
import { ArrowUpToLine } from "lucide-react";

export const ScrollToTop = ({ scrollContainerRef }: any) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
  
    const handleScroll = () => {
      if (scrollContainer.scrollTop > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };
  
    scrollContainer.addEventListener("scroll", handleScroll);
  
    // Cleanup the event listener on component unmount
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef]);

  /*
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  */

  const goToTop = () => {
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  /*
  const goToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
  };
  */

  return (
    <>
      {showTopBtn && (
        <Button
          onClick={goToTop}
          className="fixed bg-neutral_blue bottom-14 right-6 opacity-90 shadow-md"
          size="icon"
        >
          <ArrowUpToLine className="h-4 w-4" />
        </Button>
      )}
    </>
  );
};
