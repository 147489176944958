import { AccordionContent, AccordionItem, AccordionTrigger } from "../../../../components/ui/accordion";

import {
    Plus,
} from "lucide-react"

import { cn } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { FormPopover } from "../../../../components/form/form-popover";

import { useBoards, useSelectedBoard, useTaskStoreActions } from "../../../../hooks/tasks-store";
import { useGeneralHookActions } from "../../../../hooks/general-hook";


interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string; //Organization;
    onExpand: (id: string) => void;
}

export const NavItemTasks = ({
    isExpanded,
    isActive,
    membership,
    onExpand,
}: NavItemProps) => {
    const { t } = useTranslation();

    const boards = useBoards();
    const selectedBoard = useSelectedBoard();

    // Close the hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();
  
   const { setSelectedBoard } = useTaskStoreActions();

    const handleClick = (selection: string) => {
        //goToHomePage(href);
        //console.log("data on click : ",selection);
        setSelectedBoard(selection);
        closeHamburgerMenuSheet();
    };

    return (
        <AccordionItem
            value={membership} //organization.id
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand(membership)} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-gray-700 dark:text-gray-200 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        {membership}
                    </span>
                </div>
            </AccordionTrigger>
            
            <AccordionContent className="pt-1 text-neutral-700">
                {boards && boards.map((board) => (
                    <Button
                        key={`${board.id}-${board.title}`}//{board.id}
                        size="sm"
                        //onClick={() => onClick(board.id as string)}
                        onClick={() => handleClick(board.id)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4",
                            selectedBoard === board.id && "bg-[#FAE3D8] dark:bg-[#542810]"
                            )}
                        variant="ghost"
                    >
                        <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-tasks_primary dark:hover:text-[#FFA06A]">{board.title}</span>
                    </Button>
                ))}

                {membership === 'Tasks' && (
                    <div className="font-medium text-lg mt-1 flex items-center justify-start z-[9999]">
                        <FormPopover 
                            align="start" 
                            side="bottom" 
                            sideOffset={18} 
                        >
                            <Button 
                                size="sm"
                                variant="ghost"
                                className="hidden md:flex w-[90%] font-normal text-xs pl-4 justify-startp text-neutral_white-200 bg-tasks_primary hover:text-[#DD691D] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA]"
                                //  className="rounded-sm hidden md:block text-sm text-neutral_white-200 bg-tasks_primary hover:text-[#DD691D] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA]"
                            >
                                {t("tasks.board.create.button")}
                            </Button>
                        </FormPopover>
                        <FormPopover>
                            <Button size="sm" className="rounded-sm block md:hidden ml-4 text-neutral_white-200 bg-tasks_primary hover:text-[#DD691D] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA]">
                                <Plus className="h-4 w-4"/>
                            </Button>
                        </FormPopover>
                    </div>
                )}
            </AccordionContent>

        </AccordionItem>
    );
};

NavItemTasks.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}