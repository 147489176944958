import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { Company, Opportunity, Status } from '../interfaces/contacts/int-opportunity';

interface OpportunityStore {

    opportunityTable: Opportunity[] | undefined;
    oppyStatuses: Status[] | undefined;
    companies: Company[] | undefined;

    actions: {
        initOpportunityTable: (initialOpportunities: Opportunity[]) => void;
        createUpdateOpportunity: (opportunity: Opportunity) => void;
        deleteOpportunity: (opportunityId: string) => void;
        initOppyStatuses: (statuses: Status[]) => void;
        createUpdateOppyStatuses: (statuses: Status) => void;
        updateOppyStatusOrder: (statuses: Status[]) => void;
        deleteOppyStatus: (statusId: string) => void;
        initCompanies: (companies: Company[]) => void;
        createUpdateCompanies: (companies: Company) => void;
        clearContactOppyStore: () => void;
	}
}
  

const opportunityStore = createStore<OpportunityStore>()(
    devtools(
        (set, get) => ({
            opportunityTable: undefined,
            oppyStatuses: undefined,
            companies: undefined,
            
            actions: {

                initOpportunityTable: (initialOpportunities: Opportunity[]) => {
                    set({
                        opportunityTable: initialOpportunities,
                    })
                },
                createUpdateOpportunity: (opportunity: Opportunity) => {
                    const currentOppys = get().opportunityTable || [];
                    const existingOpportunityIndex = currentOppys.findIndex(c => c.id === opportunity.id);

                    let newOpportunityTable;

                    if (existingOpportunityIndex >= 0) {
                        //opportunity exist 
                        newOpportunityTable = currentOppys.map((o, index) => index === existingOpportunityIndex ? opportunity : o);
                    } else {
                        newOpportunityTable = [opportunity, ...currentOppys]
                    }

                    set({
                        opportunityTable: newOpportunityTable,
                    });
                },
                deleteOpportunity: (opportunityId: string) => {
                    const currentOppys = get().opportunityTable || [];
                    const existingOpportunityIndex = currentOppys.findIndex(c => c.id === opportunityId);

                    if (existingOpportunityIndex >= 0) {
                        const newOpportunityTable = currentOppys.filter((o, index) => index !== existingOpportunityIndex);
                        set({
                            opportunityTable: newOpportunityTable,
                        });
                    }
                },
                initOppyStatuses: (statuses: Status[]) => {
                    const sortedStatuses = [...statuses].sort(
                        (a, b) => (a.order ?? 0) - (b.order ?? 0)
                      );
                    set({
                        oppyStatuses: sortedStatuses,
                    })
                },
                createUpdateOppyStatuses: (status: Status) => {
                    const currentOppyStatuses = get().oppyStatuses || [];
                    const existingOpportunityStatusIndex = currentOppyStatuses.findIndex(c => c.id === status.id);

                    let newOpportunityStatuses;

                    if (existingOpportunityStatusIndex >= 0) {
                        //opportunity exist 
                        newOpportunityStatuses = currentOppyStatuses.map((o, index) => index === existingOpportunityStatusIndex ? status : o);
                    } else {
                        newOpportunityStatuses = [status, ...currentOppyStatuses]
                    }

                    const sortedStatuses = [...newOpportunityStatuses].sort(
                        (a, b) => (a.order ?? 0) - (b.order ?? 0)
                      );

                    set({
                        oppyStatuses: sortedStatuses,
                    });
                },
                updateOppyStatusOrder: (statuses: Status[]) => {
                    const sortedStatuses = [...statuses].sort(
                        (a, b) => (a.order ?? 0) - (b.order ?? 0)
                      );
                    set({
                        oppyStatuses: sortedStatuses,
                    });
                },
                deleteOppyStatus: (statusId: string) => {
                    const currentOppyStatuses = get().oppyStatuses || [];
                    const existingOpportunityStatusIndex = currentOppyStatuses.findIndex(c => c.id === statusId);

                    if (existingOpportunityStatusIndex >= 0) {
                        const newOpportunityStatuses = currentOppyStatuses.filter((o, index) => index !== existingOpportunityStatusIndex);
                        set({
                            oppyStatuses: newOpportunityStatuses,
                        });
                    }
                },
                initCompanies: (companies: Company[]) => {
                    set({
                        companies: companies,
                    })
                },
                createUpdateCompanies: (company: Company) => {
                    const currentCompanies = get().companies || [];
                    const existingCompanyIndex = currentCompanies.findIndex(c => c.id === company.id);

                    let newCompanies;

                    if (existingCompanyIndex >= 0) {
                        //opportunity exist 
                        newCompanies = currentCompanies.map((o, index) => index === existingCompanyIndex ? company : o);
                    } else {
                        newCompanies = [company, ...currentCompanies]
                    }

                    set({
                        companies: newCompanies,
                    });
                },
                clearContactOppyStore: () => {
                    set({
                        opportunityTable: undefined,
                        oppyStatuses: undefined,
                        companies: undefined,
                    });
                },
            }
        }),
        {
            name: 'auth-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof opportunityStore, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof opportunityStore>) => state.actions;
const opportunityTableSelector = (state: ExtractState<typeof opportunityStore>) => state.opportunityTable;
const oppyStatusesSelector = (state: ExtractState<typeof opportunityStore>) => state.oppyStatuses; 
const companiesSelector = (state: ExtractState<typeof opportunityStore>) => state.companies;


// getters
export const getContactStoreActions = () => actionsSelector(opportunityStore.getState());
export const getOpportunityTable = () => opportunityTableSelector(opportunityStore.getState());
export const getOppyStatuses = () => oppyStatusesSelector(opportunityStore.getState());
export const getCompanies = () => companiesSelector(opportunityStore.getState());

function useOpportunityStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(opportunityStore, selector, equalityFn);
}

// Hooks
export const useOpportunityStoreActions = () => useOpportunityStore(actionsSelector);
export const useOpportunityTable = () => useOpportunityStore(opportunityTableSelector);
export const useOppyStatuses = () => useOpportunityStore(getOppyStatuses);
export const useCompanies = () => useOpportunityStore(getCompanies);