import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flag, Edit, ShieldAlert, ShieldCheck, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useCommunityActions, useSelectedChannel, useSelectedCurrentMember } from "../../../../hooks/community-hook";

import { UserAvatar } from "../../_components/user-avatar";
import { ActionTooltip } from "../../../../components/action-tooltip";
import { cn } from "../../../../lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { useWebSocket } from "../../../../hooks/socket-context";
import { useTranslation } from 'react-i18next';
import { useModal } from "../modals/use-modal-store";
import { fetchReportMessage } from "../../../../api/community/api-messages";
import { toast } from "sonner";


interface MessageDataProps {
    id: string;
    ref: { [key: string]: any };
    content: string;
    timestamp: string;
    isUpdated: boolean;
    deleted : string;
    memberId : string;
    userName : string;
    avatar?: string;
    memberRole: "GUEST" | "MODERATOR" | "ADMIN";
}

const roleIconMap = {
  "GUEST": null,
  "MODERATOR": <ShieldCheck className="h-4 w-4 ml-2 text-community_complementary" />,
  "ADMIN": <ShieldAlert className="h-4 w-4 ml-2 text-community_primary" />,
}

const formSchema = z.object({
  content: z.string().min(1),
});

const ChatItem = ({
    id,
    ref,
    content,
    timestamp,
    isUpdated,
    deleted,
    memberId,
    userName,
    avatar,
    memberRole,
    }: MessageDataProps) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { socket } = useWebSocket();
    const selectedChannel = useSelectedChannel();
    const { onOpen } = useModal();

    useEffect(() => {
        const handleKeyDown = (event: any) => {
        if (event.key === "Escape" || event.keyCode === 27) {
            setIsEditing(false);
            setIsDeleting(false);
        }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keyDown", handleKeyDown);
    }, []);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
        content: content
        }
    });

    const isLoading = form.formState.isSubmitting;

    interface MessageUpdate {
        content: string;
      }
  

    const onSubmitMessage = async (data: MessageUpdate) => {
        try {
            //console.log("onSubmit, data.content", data.content)
            if (socket) {
                socket.emit('updateMessage', {
                    content: data.content,
                    channelId: selectedChannel?.id,
                    messageId: id,
                    tempMessageId: Date.now().toString(),
                }
            )};
          form.reset();
          setIsEditing(false);
        } catch (error) {
          //console.log(error);
        }
      }


    useEffect(() => {
        form.reset({
        content: content,
        })
    }, [content]);


    //Récupération des infos du current member 
    const currentMember = useSelectedCurrentMember();


    const isAdmin = currentMember?.role === "ADMIN";
    const isModerator = currentMember?.role === "MODERATOR"
    const isOwner = currentMember?.id === memberId;
    const canDeleteMessage = (deleted==="FALSE") && (isAdmin || isModerator || isOwner);
    const canEditMessage = (deleted==="FALSE") && isOwner;

    const { setSelectedMessages, setSelectedMember } = useCommunityActions();

    const onMemberClick = () => {
        // to do : utiliser les hooks pour rediriger dans community lorsque l'on clique sur une icône
        setSelectedMessages(true); 
        setSelectedMember(id);
    }

    const handleReportMessage = (isFlagged: boolean) => {
        
        const flagMessage = async (id: string) => {
            try {
                const response = await fetchReportMessage(id);
                if (response) {
                    toast.success("Thanks for helping us moderating the community !");
                }
                //console.log("flagMessage response", response);
            } catch (error) {
                //console.log("flagMessage error", error);
                toast.error("An error occured while reporting the message");
            }
        };

        flagMessage(id);

    }

    return (
        <div className="relative group flex items-center hover:bg-black/5 p-4 transition w-full">
            <div className="group flex gap-x-2 items-start w-full">
                <div onClick={onMemberClick} className="cursor-pointer hover:drop-shadow-md transition">
                    <UserAvatar src={`${process.env.REACT_APP_BACKEND}/user/${avatar}`} />
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex items-center gap-x-2">
                        <div className="flex items-center">
                            <p onClick={onMemberClick} className="font-semibold text-sm hover:underline cursor-pointer">
                                {userName}
                            </p>
                            <ActionTooltip label={memberRole}>
                                {roleIconMap[memberRole]}
                            </ActionTooltip>
                        </div>
                        <span className="text-xs text-zinc-500 dark:text-zinc-400">
                        {timestamp}
                        </span>
                    </div>
                    {!isEditing && (
                        <p className={cn(
                            "text-sm text-zinc-600 dark:text-zinc-300",
                            deleted!=='FALSE' && "italic text-zinc-500 dark:text-zinc-400 text-xs mt-1"
                            )}>
                            {deleted === 'FALSE' ? (
                                <>
                                    {content}
                                    {isUpdated && (
                                        <span className="text-[10px] mx-2 text-zinc-500 dark:text-zinc-400">
                                        {t(`community.messages.editMessage.modified`)}
                                        </span>
                                    )}
                                </>
                            ) : deleted === 'MODERATOR' ? (
                                t(`community.messages.deleteMessage.deleteByModerator`)
                            ):
                            t(`community.messages.deleteMessage.deleteByUser`)
                            }
                        </p>
                    )}
                    {isEditing && (
                        <Form {...form}>
                            <form 
                            className="flex items-center w-full gap-x-2 pt-2"
                            onSubmit={form.handleSubmit(onSubmitMessage)}>
                                <FormField
                                control={form.control}
                                name="content"
                                render={({ field }) => (
                                    <FormItem className="flex-1">
                                    <FormControl>
                                        <div className="relative w-full">
                                        <Input
                                            disabled={isLoading}
                                            className="bg-[#F4E2EE] dark:bg-[#1D101A] border-0 focus-visible:ring-0 text-black dark:text-white focus-visible:ring-offset-0 placeholder-gray-500 dark:placeholder-gray-300"
                                            placeholder="Edited message"
                                            {...field}
                                        />
                                        </div>
                                    </FormControl>
                                    </FormItem>
                                )}
                                />
                                <Button
                                    disabled={isLoading}
                                    size="sm"
                                    className="bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                    {t(`community.messages.editMessage.save`)}
                                </Button>
                            </form>
                            <span className="text-[10px] mt-1 text-zinc-400">
                                {t(`community.messages.editMessage.description`)}
                            </span>
                        </Form>
                    )}
                </div>
            </div>
            
            <div className="hidden group-hover:flex items-center gap-x-2 absolute p-1 -top-2 right-5 bg-white dark:bg-zinc-800 border rounded-sm">
                {!canDeleteMessage ? (
                    <ActionTooltip label={t(`community.messages.reportMessage.hover`)}>
                        <Flag
                        onClick={() => handleReportMessage(true)}
                        className="cursor-pointer ml-auto w-4 h-4 text-zinc-500 hover:text-zinc-600 dark:hover:text-zinc-300 transition"
                        />
                    </ActionTooltip>
                ) : (
                    <div className="flex flex-row gap-x-1">
                        {canEditMessage && (
                        <ActionTooltip label={t(`community.messages.editMessage.hover`)}>
                            <Edit
                            onClick={() => setIsEditing(true)}
                            className="cursor-pointer ml-auto w-4 h-4 text-zinc-500 hover:text-zinc-600 dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                        )}
                        <ActionTooltip label={t(`community.messages.deleteMessage.hover`)}>
                            <Trash
                                onClick={() => onOpen("deleteMessage", {
                                    message: {
                                        id: id, 
                                        channel_id: selectedChannel?.id}, 

                                })}
                                className="cursor-pointer ml-auto w-4 h-4 text-zinc-500 hover:text-zinc-600 dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                    </div>
                )}
            </div>
        </div>
        );
    }

    export default ChatItem;