import { useNavigate } from 'react-router-dom';

import { AccordionContent, AccordionItem, AccordionTrigger } from "../../../../components/ui/accordion";

import { cn } from "../../../../lib/utils";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { useContactHookActions, useSelectedTabContact } from '../../../../hooks/contact-hook';
import { useTranslation } from "react-i18next";
import { useGeneralHookActions } from '../../../../hooks/general-hook';


interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string;
    userMemberships: string[];
    onExpand: (id: string) => void;
}

export const NavItemContacts = ({
    isExpanded,
    isActive,
    membership,
    userMemberships,
    onExpand,
}: NavItemProps) => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const { setSelectedTabContact } = useContactHookActions();
    
    // Close the hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();
    
    const handleSelectedTab = (tab: string) => {
        //console.log("changing Selected tab: ", tab);
        setSelectedTabContact(tab);
        closeHamburgerMenuSheet();
    };

    const selectedTab = useSelectedTabContact();

    //const availableTabs = ['All_Contacts', 'Next_Best_Actions', 'Opportunities', 'Profile'];
    const availableTabs = [
        { key: 'All_Contacts', label: t("sidebar.contacts.contacts") },
        { key: 'Next_Best_Actions', label: t("sidebar.contacts.nba") },
        { key: 'Opportunities', label: t("sidebar.contacts.opportunities") },
        { key: 'Profile_contacts', label: t("sidebar.contacts.profile") }
    ];

    return (
        <AccordionItem
            value={membership} //organization.id
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand(membership)} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-gray-700 dark:text-gray-200 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        {membership}
                    </span>
                </div>
            </AccordionTrigger>
            <AccordionContent className="pt-1 text-neutral-700">
                {availableTabs.map((tab) => (
                    <Button
                        key={tab.key}
                        size="sm"
                        onClick={() => handleSelectedTab(tab.key)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4",
                            selectedTab === tab.key && "bg-[#DBEFE9] dark:bg-[#172A26]"
                            )}
                        variant="ghost"
                    >
                         <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-contacts_primary dark:hover:text-[#78CDB9]">{tab.label} </span>
                    </Button>
                ))}
            </AccordionContent>
        </AccordionItem>
    );
};

NavItemContacts.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}