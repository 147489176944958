import { useCareerStoreAll } from "../../../../hooks/career-store";
import { CreationPlan, ExistingPlan, Plan, PlanElement, PlanId } from "../../../../api/career/interface-career";
import { create } from "zustand";

export type ModalType = "editPlan" | "deletePlan" | "editElement";

interface ModalData {
    plan?: Plan;
    existingPlan?: CreationPlan;
    planElement?: PlanElement;
    planId?: PlanId;
    planElementId?: string;
}


interface ModalStore {
    type: ModalType | null;
    data: ModalData;
    isOpen: boolean;
    onOpen: (type: ModalType, data?: ModalData) => void;
    setExistingPlan: (updatedPlan: CreationPlan) => void;
    onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
    type: null,
    data: {},
    isOpen: false,
    onOpen: (type, data={}) => {
        //console.log('Opening modal with type:', type);
        set({ isOpen: true, type, data})},
    setExistingPlan: (updatedPlan: CreationPlan) => set(state => ({
        ...state,
        data: {
            ...state.data,
            existingPlan: updatedPlan
        }
    })),
    onClose: () => set({ type: null, isOpen: false })
}));