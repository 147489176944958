import { z } from "zod";

export const CreateCard = z.object({
  //id: z.string().optional(),
  title: z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  }),
  parent_elt: z.string(),
  list_id: z.string(),
  order_list: z.number(),
  type: z.string(),
  status: z.optional(z.string()),
});