import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

// additionals imports for fuzzy matching on all columns
import {
    Column,
    //Table,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    sortingFns,
    FilterFn,
    SortingFn,
    FilterFns,
  } from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import { fuzzySort } from "./tasks-table-columns";
//end of additionals imports for fuzzy matching on all columns

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
  } from "../../../../components/ui/dropdown-menu";

import { cn } from "../../../../lib/utils";

import { DataTablePagination } from "./tasks-table-pagination";
import { getContactsHookActions, useIsLeftPanelVisible } from "../../../../hooks/contact-hook";
import { Switch } from "../../../../components/ui/switch";
import { ActionTooltip } from "../../../../components/action-tooltip";
import { useCardModal } from "../../../../hooks/tasks-use-card-modal";

// search for the contact card component
import { status_dict } from "../../../../interfaces/tasks/int-card";
import { duration_dict } from "../../../../interfaces/tasks/int-card";
import { useTaskStoreActions } from "../../../../hooks/tasks-store";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

// Type pour la visibilité des colonnes
type ColumnVisibility = {
  [key: string]: boolean; // Vous pouvez restreindre davantage les clés si nécessaire
};

//handle Fuzzy matching on all columns feature
declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
        standard: FilterFn<unknown> 
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('') //Global filter fuzzy matching
  const [isFuzzySearchEnabled, setIsFuzzySearchEnabled] = React.useState(true); //Global filter fuzzy matching
  //const isMediumOrLarger = useMediaQuery({ query: '(min-width: 768px)' });
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    created_at: false,
    updated_at: false,
  })

  const [rowSelection, setRowSelection] = React.useState({})
  const { t } = useTranslation();

  const { getBoardbyId } = useTaskStoreActions();

  const getCustomRowValue = (row: any, columnId: string) => {

    let rowValue = (row.getValue(columnId) as string)?.trim().toLowerCase() || '';
  
    if (columnId === "parent_elt") {
      //return row.original.boardTitle?.trim().toLowerCase() || '';
      const boardId = row.getValue(columnId) as string;
      return getBoardbyId(boardId as string)?.title?.trim().toLowerCase() || '';
    }
  
    if (columnId === "status") {
      const statusKey = row.getValue(columnId) as keyof typeof status_dict;
      return status_dict[statusKey]?.trim().toLowerCase() || '';
    }
  
    if (columnId === "duration") {
      const durationKey = row.getValue(columnId) as keyof typeof duration_dict;
      return duration_dict[durationKey]?.trim().toLowerCase() || '';
    }

    // For date columns, format the Date object to a string
    if (columnId === "due_date" || columnId === "created_at" || columnId === "updated_at") {
      const dateValue = row.getValue(columnId) as string;
      const date = new Date(dateValue);
      return date.toLocaleDateString();  
    }
  
    return rowValue;
  
  };
  
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
      //clean the value and the row value
      let searchValue = value?.trim().toLowerCase() || '';
      //let rowValue = (row.getValue(columnId) as string)?.trim().toLowerCase() || '';
  
      // Special case for `parent_elt` column to use boardTitle
      let rowValue = getCustomRowValue(row, columnId);
  
      // Rank the item
      const itemRank = rankItem(rowValue, searchValue);

      // Store the itemRank info
      addMeta({
        itemRank,
      })
  
      // Logging for debugging purposes
      //console.log(`Fuzzy filter - Row value: "${rowValue}", Search value: "${searchValue}"`);
    
      // Return if the item should be filtered in/out
      return itemRank.passed
    }
  
    const standardFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
      //console.log("hitting standardFilter");
      let searchValue = value?.trim().toLowerCase() || '';
      // Special case for `parent_elt` column to use boardTitle
      let rowValue = getCustomRowValue(row, columnId);
      
      return typeof rowValue === 'string' && rowValue.includes(searchValue);
    };

  const table = useReactTable({
    data,
    columns,
    filterFns: {     //global fuzzy match
        fuzzy: fuzzyFilter,
        standard: standardFilter,
      },
    globalFilterFn: isFuzzySearchEnabled ? fuzzyFilter : standardFilter, //global fuzzy match

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),    
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter, //global fuzzy match
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    
    state: {
      sorting,
      columnFilters,
      globalFilter, //global fuzzy match
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,  // Set the default page index (first page)
        pageSize: 20,  // Set the default page size
      },
    },
  })

  //handle left panel visibility
  const { setIsLeftPanelVisible, setSelectedContact } = getContactsHookActions();
  const isLeftPanelVisible = useIsLeftPanelVisible();
  // handle modal
  const cardModal = useCardModal();

  const applyGlobalFilter = async () => {
    // workaround : we need to force the table to reapply the global filter
    // the global filter value doesn't change so we need to force the table to reapply the filter
    // Temporarily store the current global filter value
    const currentFilter = globalFilter;
    setGlobalFilter(" ");
    setTimeout(() => {
      setGlobalFilter(currentFilter);
    }, 0); // The timeout of 0 ms is often enough to delay the execution until after the current call stack
  };

  //handle the rerendering of the table if parameters change 
  React.useEffect(() => {
    //setGlobalFilter(filter => ({ ...filter, fuzzy: isFuzzySearchEnabled }));
    applyGlobalFilter();
  }, [isFuzzySearchEnabled]);

  React.useEffect(() => {
    // Reset the pagination when filters or global filters change
    table.setPageIndex(0);  // Always reset to the first page when filtering
  }, [globalFilter, columnFilters]);

  return (
    <div className="h-full w-full z-0">
        <div className="flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center py-1 sm:py-4">
          <div className="flex flex-row justify-start gap-4 sm:mb-0">
                <div className="flex-grow max-w-md">
                    <DebouncedInput
                      value={globalFilter ?? ''}
                      onChange={value => {
                          //console.log('Global filter set to:', value);
                          setGlobalFilter(String(value));
                      }}
                      className="flex h-10 w-full bg-[#F3F7F6] dark:bg-[#020504] border border-input px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-tasks_primary dark:focus:border-tasks_primary focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                      placeholder={t("contacts.contactsTable.top.placeholder")}
                    />
                </div>
                <ActionTooltip side="bottom" align="center" label={isFuzzySearchEnabled ? (t("contacts.contactsTable.top.switchtoExactMatch")) : (t("contacts.contactsTable.top.switchtoFlexibleMatch"))}>
                    <div 
                        className="flex items-center" //shrink-0
                    >
                        <Switch 
                          id="isFuzzySearchEnabledSwitch" 
                          checked={isFuzzySearchEnabled}
                          onCheckedChange={e => setIsFuzzySearchEnabled(e)}
                          className="scale-75 data-[state=checked]:bg-tasks_primary data-[state=unchecked]:bg-[#F7F5F4] dark:data-[state=checked]:bg-tasks_primary dark:data-[state=unchecked]:bg-[#451F08]"
                        />
                        <label 
                          htmlFor="isFuzzySearchEnabledSwitch"
                          className="text-sm font-medium text-muted-foreground ml-2"
                        >
                            {t("contacts.contactsTable.top.flexibleMatch")}
                        </label>
                    </div>
                </ActionTooltip>
            </div>
            <div 
              className="flex flex-col sm:flex-row items-start sm:items-center gap-2" //shrink-0
            >
              <Button
                  onClick={() => cardModal.onOpen()}
                  className="hidden md:flex bg-[#F8EEEA] dark:bg-[#311C11] text-tasks_primary hover:bg-tasks_primary hover:text-[#F8EEEA] dark:hover:bg-tasks_primary dark:hover:text-[#FFDECD] font-bold py-1 px-2 rounded"
              >
                  {t("tasks.list.settings.add")}
              </Button>
            </div>
        </div>
        <div className="flex md:flex-col border-none">
            <Table className="z-0">
                <TableHeader className="z-0">
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow
                      key={headerGroup.id}
                      className="px-2 py-1 space-y-1 bg-[#FAE3D8] hover:bg-[#FAE3D8] dark:bg-[#311C11]"
                      >
                    {headerGroup.headers.map((header) => {
                        return (
                        <TableHead 
                            key={header.id}
                            // Adding px-0 to remove padding and justify-items-start align the text to the left for all columns except the select column
                            //className={cn("text-xs md:text-sm lg:text-base text-[#1A3C34] dark:text-[#BCECDF]", 
                            //    header.id === "select" ? " justify-center" : "justify-items-start px-0"
                            //    )}
                            className={`${
                              header.id === 'select' ? 'justify-center px-2' : 'justify-items-start px-0'
                            } text-xs md:text-sm lg:text-base text-[#1A3C34] dark:text-[#FFDECD]`}
                        >
                            {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                                )}
                        </TableHead>
                        )
                    })}
                    </TableRow>
                ))}
                </TableHeader>
                <TableBody>
                {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row, index) => (
                    <TableRow
                        key={row.id}
                        className={cn(
                          "text-sm px-1 py-1 m-0 space-y-0",
                          `${index % 2 === 0 ? "dark:hover:bg-[#451F08] hover:bg-[#FAE3D8]" : "bg-[#F8EEEA] hover:bg-[#FAE3D8] dark:bg-[#1B130F] dark:hover:bg-[#451F08]"}`,
                      )}
                        //className={`${index % 2 === 0 ? "dark:hover:bg-[#172A26] hover:bg-[#DBEFE9]" : "bg-[#ECF3F1] hover:bg-[#C9E9E0] dark:bg-[#111917] dark:hover:bg-[#193831]"}`} // Alterne entre bg-gray-50 et bg-white
                        data-state={row.getIsSelected() && "selected"}
                        onClick={() => {
                            //handleOpenSheetFunction(false, (row.original as { id: string }).id);
                            row.original.id && cardModal.onOpen((row.original.id));

                        }} // Add this line
                    >
                        {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} className='px-2 py-1 text-xs md:text-sm lg:text-base'>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                        ))}
                    </TableRow>
                    ))
                ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                          No results.
                      </TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
            <DataTablePagination table={table} />
        </div>
        {/* Small screen => display a simple list TODO: to be implemented 
          <div className="flex flex-col h-full w-full md:hidden">
            {table.getFilteredRowModel().rows?.length ? (
              table.getFilteredRowModel().rows.map((row, index) => (
                <div
                  key={row.original.id}
                  className={`border text-xs mt-2 ${
                    index % 2 === 0 ? "bg-[#EEF1F0] dark:bg-[#172A26]" : "bg-[#E6EDEB] dark:bg-[#111917]"
                  }`}
                >
                  test small screen
                </div>
            ))) : (
              <div>
                No results.
              </div>
            )}
          </div>
        */}
    </div>
  );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)
  
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)
  
      return () => clearTimeout(timeout)
    }, [value, debounce, onChange])
    
    //console.log('Debouncer set to:', value);

    return (
      <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
  }

  /*
  // Contact card component for small screens
  <ContactCard contactXsId={(row.original as { id: string }).id} handleOpenSheetFunction={handleOpenSheetFunction} />

  // titre contact (90) : 
        <div className="flex justify-between">
            <h2 id="contacts-section" className="flex text-base sm:text-2xl font-bold">
              {t("contacts.contactsTable.top.title")} ({table.getFilteredRowModel().rows.length})
            </h2>
        </div>


  // Dropdown menu to show/hide columns -> Hidden for now (03/09/2024) since it doesn't fi with UX/UI policy, TO DO: to be put somewhere else in the future

  <DropdownMenu>
    <DropdownMenuTrigger asChild>
        <Button 
        variant="outline"
        className="hidden md:flex ml-auto bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
        >
        {t("contacts.contactsTable.top.columns")}
        </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
        {table
        .getAllColumns()
        .filter(
            (column) => column.getCanHide()
        )
        .map((column) => {
            return (
            <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) =>
                column.toggleVisibility(!!value)
                }
            >
                {column.id}
            </DropdownMenuCheckboxItem>
            )
        })}
    </DropdownMenuContent>
</DropdownMenu>
  */