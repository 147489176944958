import { useNavigate } from "react-router-dom";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

enum PopularPlanType {
  NO = 0,
  YES = 1,
}

interface PricingProps {
  title: string;
  popular: PopularPlanType;
  frequency?: string;
  price?: string;
  description?: string;
  buttonText: string;
  benefitList?: string[];
}

export const Pricing = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const pricingList: PricingProps[] = [
        
        {
          title: t("landing.pricing.offer_title1"),
          popular: 1,
          price: t("landing.pricing.offer_price1"),
          description:
            t("landing.pricing.offer_description"),
          buttonText: t("landing.pricing.offer_button"),
          frequency: t("landing.pricing.offer_frequency1"),
          benefitList: [
            t("landing.pricing.check1"),
            t("landing.pricing.check2"),
            t("landing.pricing.check3"),
            t("landing.pricing.check4"),
            t("landing.pricing.check5"),
            t("landing.pricing.check6"),
          ],
        },
        {
          title: t("landing.pricing.offer_title2"),
          popular: 0,
          price: t("landing.pricing.offer_price2"),
          description:
            t("landing.pricing.offer_description"),
          buttonText: t("landing.pricing.offer_button"),
          frequency: t("landing.pricing.offer_frequency2"),
          benefitList: [
            t("landing.pricing.check1"),
            t("landing.pricing.check2"),
            t("landing.pricing.check3"),
            t("landing.pricing.check4"),
            t("landing.pricing.check5"),
            t("landing.pricing.check6"),
          ],
        },
        {
          title:  t("landing.pricing.offer_title3"),
          popular: 0,
          price: t("landing.pricing.price_entreprise"),
          description:
          t("landing.pricing.offer_enterprise_description"),
          buttonText: t("landing.pricing.offer_enterprise"),
          benefitList: [
            t("landing.pricing.check_E_1"),
            t("landing.pricing.check_E_2"),
            t("landing.pricing.check_E_3"),
            t("landing.pricing.check_E_4"),
            t("landing.pricing.check_E_5"),
            t("landing.pricing.check_E_6"),
          ],
        },
        
      ];

  // Fonction pour copier l'email pour l'offre entreprise
  const handleEnterpriseClick = () => {
    navigator.clipboard.writeText("contact@zangou.ai");
    toast.success("Email copied to clipboard!");
  };


  return (
    <section
      id="pricing"
      className="container pt-6 pb-24 sm:py-32"
    >
      <h2 className="text-3xl md:text-4xl font-bold text-center">
      {t("landing.pricing.title_part1")}
        <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
          {" "}
          {t("landing.pricing.title_part2")}
          {" "}
        </span>
        {t("landing.pricing.title_part3")}
      </h2>
      <h3 className="text-xl text-center text-muted-foreground pt-4 pb-8">
        {t("landing.pricing.description")}
      </h3>
      <div 
        className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-full justify-center"
        //className="flex w-full gap-8 items-center justify-center"
      >
        {pricingList.map((pricing: PricingProps) => (
          <Card
          key={pricing.title}
          className={`w-full max-w-md flex flex-col justify-between ${
            pricing.popular === PopularPlanType.YES
              ? "drop-shadow-xl shadow-black/10 dark:shadow-white/10"
              : ""
          } ${
            pricing.title === t("landing.pricing.offer_title3")
              ? "bg-blue-100 dark:bg-gray-800"
              : ""
          }`}
          >
            <CardHeader>
              <CardTitle className="flex item-center justify-between">
                {pricing.title}
                {pricing.popular === PopularPlanType.YES ? (
                  <Badge
                    variant="secondary"
                    className="text-sm text-primary"
                  >
                    Most popular
                  </Badge>
                ) : null}
              </CardTitle>
              <div>
                <span className="text-3xl font-bold">{pricing.price}</span>
                <span className="text-muted-foreground">{pricing.frequency}</span>
              </div>

              <CardDescription>{pricing.description}</CardDescription>
            </CardHeader>

            <CardContent>
              {pricing.title === t("landing.pricing.offer_title3") ? (
                  <Button onClick={handleEnterpriseClick} className="w-full" variant="landing_full">
                    {pricing.buttonText}
                  </Button>
                ) : (
                  <Button onClick={() => navigate("/signup")} className="w-full" variant="landing_full">
                    {pricing.buttonText}
                  </Button>
                )}
            </CardContent>
            <hr className="w-4/5 m-auto mb-4" />
            <CardFooter className="flex align-top">
              <div className="space-y-4">
                {pricing.benefitList && pricing.benefitList.map((benefit: string) => (
                  <span
                    key={benefit}
                    className="flex"
                  >
                    <Check className="text-green-500" />{" "}
                    <h3 className="ml-2">{benefit}</h3>
                  </span>
                ))}
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
