import { toast } from "sonner";
import { Card, Energy, energy } from "../../../../interfaces/tasks/int-card";

import { useAction } from "../../../../hooks/use-action";
import { useLists, useSelectedBoard, useTaskStoreActions } from "../../../../hooks/tasks-store";

import { updateCard } from "../../../../api/tasks/update-card";


import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../components/ui/select";

import { useTranslation } from "react-i18next";
import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { useEffect, useRef, useState } from "react";

interface TitleUnitProps {
    data: Card; //CardWithList;
}

const TitleUnit = ({ data }: TitleUnitProps) => {

    const { t } = useTranslation();

    const { createOrUpdateCard, updateTasks } = useTaskStoreActions();

    const { execute } = useAction(updateCard, {
        onSuccess: (data) => {
            //createOrUpdateCard(data); => TO DO : update cards in the list when refactor lists
            updateTasks([data]);
            toast.success(`Card "${data.title}" updated`);
            //setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const changeTitle = (title: string | undefined, card: Card) => {
        if (title && title !== card.title) {
            execute({
                title: title,
                id: data.id,
                parent_elt: data.parent_elt as string,
            });
        }
    }

    const [title, setTitle] = useState(data.title);
    const [isEditing, setIsEditing] = useState(false);

    const handleEditing = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setIsEditing(true);
    }

    const handleClickInInput = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation(); // Prevent click within input from bubbling up
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.stopPropagation(); // Prevents the blur event from triggering the parent event
        changeTitle(title, data);
        setIsEditing(false);
    };

    //handle escape key
    const inputRef = useRef<HTMLInputElement>(null);

    // Listen for "Escape" key to cancel editing
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            setIsEditing(false);
        } else if (e.key === "Enter") {
            if (inputRef.current) {
                inputRef.current.blur(); // Trigger onBlur to save the title
            }
        }
    };

    // Add keydown event listener when editing starts
    useEffect(() => {
        if (isEditing) {
            document.addEventListener("keydown", handleKeyDown);
            // Focus the input field automatically when editing starts
            if (inputRef.current) {
                inputRef.current.focus();
            }
        } else {
            document.removeEventListener("keydown", handleKeyDown);
        }

        // Cleanup event listener on unmount or when editing ends
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isEditing]);

    return ( 
        <div>
            {isEditing ? (
                <span className="flex text-sm text-muted-foreground m-0 p-0">
                    <form>
                        <input 
                            ref={inputRef}
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onClick={handleClickInInput} // Prevent click within input from bubbling up
                            onBlur={handleBlur}  // Handle blur event to save title
                            autoFocus
                        />
                    </form>
                </span>
            ) : (
                <span 
                    onClick={(e) => handleEditing(e)}
                    className="text-sm text-muted-foreground items-center m-0 px-2 py-1 hover:cursor-pointer"
                >
                    {data.title ? data.title.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Add a title"}
                </span>
            )}
        </div>
     );
}
 
export default TitleUnit;