import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { useEffect, useState } from "react";
import { useModal } from "./use-modal-store";
import { CreationPlan, ExistingPlan, Question } from "../../../../api/career/interface-career";
import { fetchEditPlan } from "../../../../api/career/api-create-plan";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ActionTooltip } from "../../../../components/action-tooltip";
import { Trash } from "lucide-react";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { FormTextarea } from "../../../../components/form/form-textarea";
import { useTranslation } from "react-i18next";

const questionSchema = z.object({
    id: z.string().optional(),
    title: z.string(),
  });
  
const formSchema = z.object({
    id: z.string(),
    path: z.enum(["CURRENT","ALTERNATIVE","DREAM","OTHER"]),
    title: z.string().min(1, {
        message: "Plan title is required."
    }),
    questions: z.array(questionSchema),
    resources: z.number().min(1),
    entertainment: z.number().min(1),
    confidence: z.number().min(1),
    coherence: z.number().min(1),
    context: z.string().min(1, {
        message: "Plan context is required."
    }),
    description: z.string().optional(),
});

type FormSchemaType = z.infer<typeof formSchema>;

export const EditPlanModal = () => {
    const { t } = useTranslation();

    const { isOpen, onClose, type, data } = useModal();
    const isModalOpen = isOpen && type === "editPlan";
    const path_name = ["Current Path", "Alternative Path", "Dream Path", "Other Path"];

    const [questions, setQuestions] = useState<Question[]>([]);

    //const { planId, existingPlan } = data;
    const { existingPlan } = data;

    const handleRemoveQuestion = (index: number) => {
        setQuestions(prev => prev.filter((_, i) => i !== index));
    };

    const form = useForm<FormSchemaType>({
        resolver: zodResolver(formSchema),
    });

    const isLoading = form.formState.isSubmitting;

    const [editPlan, setEditPlan] = useState<CreationPlan>();

    //retrieving the update plan function to handle the form submission conclusion
    const { updatePlan } = useCareerHookActions();
    const onSubmit = async (data: CreationPlan) => {
        if (data.id) {
            const payload = {
                id: data.id,
                path: data.path,
                title: data.title,
                description: data.description,
                context: data.context,
                questions: questions.filter(question => question.title.trim() !== ""), //modifiedQuestions,
                resources: data.resources,
                entertainment: data.entertainment,
                confidence: data.confidence,
                coherence: data.coherence,
            };
            try {
                //console.log("Plan", existingPlan);
                const response = await fetchEditPlan(data.id, payload);
                //console.log("Plan updated successfully", response);
                setEditPlan(response); //why updating the edit plan where closing the modal ?
                // mettre à jour le store
                updatePlan(response);
                onClose();
            } catch (err) {
                //console.log("error: ", err);
            }
        }
    };

    //console.log("Modal isOpen status:", isOpen);
    const handleClose = () => { //adding a popup to confirm the closing of the modal if changes have been made
        //console.log("Closing modal");
        form.reset();
        onClose();
      }

    useEffect(() => {
        //console.log("EditPlanModal props:", { isOpen, type, data });
        if(existingPlan) {
            const initialValues = {
                id: existingPlan.id,
                path: existingPlan.path,
                title: existingPlan.title,
                description: existingPlan.description || '',
                context: existingPlan.context,
                resources: existingPlan.resources,
                entertainment: existingPlan.entertainment,
                confidence: existingPlan.confidence,
                coherence: existingPlan.coherence,
                questions: existingPlan.questions || [],
            };
            form.reset(initialValues); // Utilisez reset pour initialiser tous les champs, y compris les questions

            const initialQuestions = existingPlan.questions && existingPlan.questions.length > 0
                    ? existingPlan.questions.map(q => ({
                        title: q.title,
                        id: q.id
                    }))
                    : [];

            setQuestions([...initialQuestions, { title: '' }]); //check necessity of the last empty question
        }
        
    }, [existingPlan]);

    return (
        <div className="flex-col bg-[#F8F5F5]  dark:bg-[#1A1213] shadow-md rounded-lg">
        <Dialog key={existingPlan?.id} open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="border-transparent text-center text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
                <DialogHeader className="pt-8 px-6">
                <DialogTitle className="text-2xl font-semibold text-center">
                    {t("career.modals.edit.top")}
                </DialogTitle>
                </DialogHeader>
                <ScrollArea className="flex-grow overflow-auto max-h-[30rem]">
                    <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8">
                        <div className= "space-y-8 px-6">
                            
                            <FormField
                                control={form.control}
                                name="title"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel
                                        className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.title")}
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                            disabled={isLoading}
                                            className="shadow border border-transparent rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                            placeholder= "your plan in 6 words or so"
                                            {...field}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="description"
                                render={({ field }) => (
                                    <FormItem className="mb-4 flex flex-col">
                                        <FormLabel
                                            className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.description")}
                                        </FormLabel>
                                        <FormControl>
                                            <textarea
                                                {...field}
                                                className="mt-2 p-4 block w-full h-36 shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                                placeholder="Example: Advance from a software developer to an AI specialist by acquiring necessary skills through courses and real-world projects, aiming to lead AI initiatives in a tech firm."
                                                disabled={isLoading}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="context"
                                render={({ field }) => (
                                    <FormItem className="mb-4 flex flex-col">
                                        <FormLabel
                                        className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.context")}
                                        </FormLabel>
                                        <FormControl>
                                            <textarea
                                                {...field}
                                                disabled={isLoading}
                                                className="mt-2 p-4 block w-full h-36 shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                                placeholder="relevant experience"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormItem>
                                <FormLabel
                                className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                    {t("career.modals.edit.questions")}
                                </FormLabel>
                                {questions.map((question, index) => (
                                    <div key={index} className="relative my-2">
                                        <Input
                                        value={question.title}
                                        className="shadow border border-transparent rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                        placeholder="Question"
                                        onChange={e => {
                                            const updatedQuestions = [...questions];
                                            updatedQuestions[index] = { ...updatedQuestions[index], title: e.target.value };
                                        
                                            // Si c'est le dernier champ et qu'il n'est pas vide, ajoutez un nouveau champ vide
                                            if (index === questions.length - 1 && e.target.value.trim() !== '') {
                                                updatedQuestions.push({ title: '' });
                                            }
                                        
                                            setQuestions(updatedQuestions);
                                        }}
                                        />
                                        {index !== 0 && (
                                        <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                                            <ActionTooltip label="Remove">
                                            <Trash
                                                onClick={() => handleRemoveQuestion(index)}
                                                className="cursor-pointer w-4 h-4 text-[#AE3F51] dark:text-[#FF95A0] dark:hover:text-zinc-300 transition"
                                                />
                                            </ActionTooltip>
                                        </div>
                                        )}
                                    </div>
                                ))}


                            </FormItem>
                            <div className="flex space-x-4">
                                <FormField
                                    control={form.control}
                                    name="coherence"
                                    render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.coherence")}
                                        </FormLabel>
                                        <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            min="1"
                                            max="10"
                                            onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            form.setValue('coherence', value);
                                            }}
                                            className="shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                        />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="entertainment"
                                    render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.entertainment")}
                                        </FormLabel>
                                        <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            min="1"
                                            max="10"
                                            onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            form.setValue('entertainment', value);
                                            }}
                                            className="shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                        />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    )}
                                />
                                </div>
                                <div className="flex space-x-4 mt-4">
                                <FormField
                                    control={form.control}
                                    name="resources"
                                    render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.resources")}
                                        </FormLabel>
                                        <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            min="1"
                                            max="10"
                                            onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            form.setValue('resources', value);
                                            }}
                                            className="shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                        />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="confidence"
                                    render={({ field }) => (
                                    <FormItem className="flex-1">
                                        <FormLabel className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                                            {t("career.modals.edit.confidence")}
                                        </FormLabel>
                                        <FormControl>
                                        <Input
                                            {...field}
                                            type="number"
                                            min="1"
                                            max="10"
                                            onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            form.setValue('confidence', value);
                                            }}
                                            className="shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                        />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <DialogFooter className="px-6 py-4">
                        <Button 
                            disabled={isLoading}
                            type="submit"
                            className="w-full bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"                            
                            >
                            {t("career.modals.edit.save")}
                        </Button>
                        </DialogFooter>
                    </form>
                    </Form>
                </ScrollArea>
            </DialogContent>
        </Dialog>
        </div>
     );
}

/*
// Rollback : we don't want to let the user modify the path type of the plan for now
// We will keep the path type as it is
// When we will refactor the feature we will do a more complicated implementation that will check the uniqueness of the path type

<FormField
    control={form.control}
    name="path"
    render={({ field }) => (
        <FormItem>
            <FormLabel
            className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">
                {t("career.modals.edit.path")}
                {
                // is it the path type ?
                
                }
            </FormLabel>
            <FormControl>
            <select
                {...form.register("path")} // Utilisez form.register pour lier le select à React Hook Form
                disabled={form.formState.isSubmitting}
                className="shadow border border-transparent rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                >
                {path_name.map((pathOption, index) => (
                    <option value={["CURRENT", "ALTERNATIVE", "DREAM", "OTHER"][index]} key={index}>
                    {pathOption}
                    </option>
                ))}
                </select>
            </FormControl>
            <FormMessage />
        </FormItem>
    )}
/>
*/