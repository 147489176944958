//import { db } from "@/lib/db";
//import { auth } from "@clerk/nextjs";
import { Board } from "../../../interfaces/tasks/int-board";
//import { redirect } from "next/navigation";
import { BoardTitleForm } from "./board-title-form";
import { BoardOptions } from "./board-options";

import { useState } from "react";
import BoardDisplayMode from "./board-display-mode";

interface BoardNavbarProps {
    data: Board;
}

export const BoardNavbar = ({
    data
}: BoardNavbarProps) => {

    const [ isEditingOption, setIsEditingOption ] = useState(false);

    return (
        <div className="w-full h-14 z-[40] inline-flex justify-between items-center px-6 gap-x-4 text-[#DD691D] bg-[#E7A481] dark:bg-[#63361E]">
            <div className="flex items-center">
                <BoardTitleForm data = {data} isEditingOption={isEditingOption} setIsEditingOption={setIsEditingOption} />
                {(data?.title !== "Overview") && (
                    <div className="ml-auto items-center">
                        <BoardOptions id={data.id || ''} name={data.title || ''} setIsEditingOption={setIsEditingOption} />
                    </div>
                )}
            </div>
            <div>
                <BoardDisplayMode data={data} />
            </div>
        </div>
    );
};

/*
<div className="flex items-center gap-x-4">
                <div>
                    <Select
                        value={selectedBoard}
                        onValueChange={(value) => {setSelectedBoard(value as string)}}
                    >
                        <SelectTrigger className="text-black gap-x-4">
                            <SelectValue placeholder="Select a project" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                            <SelectLabel>Board</SelectLabel>
                            {boards && boards.map((board) => {
                                return (
                                    <SelectItem key={board.id} value={board.id}>
                                        {board.title}
                                    </SelectItem>
                                );
                            })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <FormPopover align="start" side="bottom" sideOffset={18} >
                        <Button variant="primary" className="rounded-sm hidden md:block h-auto py-1.5 px-2">
                            Create
                        </Button>
                    </FormPopover>
                    <FormPopover>
                        <Button size="sm" className="rounded-sm block md:hidden">
                            <Plus className="h-4 w-4"/>
                        </Button>
                    </FormPopover>
                </div>
            </div> 
*/