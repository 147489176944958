import { Hash, Menu} from "lucide-react";
import { SocketIndicator } from "../socket-indicator";

interface ChatHeaderProps {
    serverId: string | undefined;
    serverName: string | undefined;
    name: string | undefined;
    type : string;
    imageUrl?: string | undefined;
  }

  const ChatHeader = ({
    serverId,
    serverName,
    name,
    type,
    imageUrl
  }: ChatHeaderProps) => {
    return (
        <div className="text-md font-semibold px-3 flex items-center h-12 border-neutral-200 dark:border-neutral-800 border-b-2">
            {type === "channel" && (
            <Hash className="w-5 h-5 text-zinc-500 dark:text-zinc-400 mr-1"
            />
            )}
            {type === "conversation" && (
                <Hash className="w-5 h-5 text-zinc-500 dark:text-zinc-400 mr-1"
                />
            )}
            <p className="font-semibold text-md text-black dark:text-white">
                {name} <span className=" font-normal italic">in server</span> <span className="font-semibold text-community_primary"> {serverName} </span>
            </p>
            <div className="ml-auto flex items-center">
                <SocketIndicator />
            </div>
        </div>
    );
}
 
export default ChatHeader;