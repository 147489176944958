import { Plus, X } from "lucide-react";

import { 
    forwardRef, 
    useRef,
    ElementRef,
    KeyboardEventHandler
} from "react";

import { FormSubmit } from "../../../components/form/form-submit";
import { FormTextarea } from "../../../components/form/form-textarea";
import { Button } from "../../../components/ui/button";

import { useAction } from "../../../hooks/use-action";
import { fetchCreateCard } from "../../../api/tasks/create-card";

import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { toast } from "sonner";
import { getSelectedBoardElement, useSelectedBoard, useTaskStoreActions } from "../../../hooks/tasks-store";
import { useTranslation } from "react-i18next";
import { Status, status_dict } from "../../../interfaces/tasks/int-card";



interface CardFormProps {
    listId: string;
    enableEditing: () => void;
    disableEditing: () => void;
    isEditing: boolean;
}

export const CardForm = forwardRef<HTMLTextAreaElement, CardFormProps>(({
    listId,
    enableEditing,
    disableEditing,
    isEditing,
}, ref) => {
    const { t } = useTranslation();
    const selectedBoard = useSelectedBoard();
    const formRef = useRef<ElementRef<"form">>(null);

    const selectedBoardElement = getSelectedBoardElement();

    const { createOrUpdateCard, getListById } = useTaskStoreActions();
    
    const { execute, fieldErrors } = useAction(fetchCreateCard, {
        onSuccess: (data) => {
            //console.log('data - new card :', data);
            if (selectedBoardElement?.title === "Overview") {
                const statusToListId: { [key in Status]: string } = {
                    "TO_DO": "1",
                    "IN_PROGRESS": "2",
                    "DONE": "3",
                  };
                const listId = statusToListId[data.status as Status];
                createOrUpdateCard({...data, list_id: listId});
            } else {
                createOrUpdateCard(data);
            }
          toast.success(`Card "${data.title}" created`);
          //toast.success(`Card created`);
          formRef.current?.reset();
        },
        onError: (error) => {
          toast.error(error);
        },
      });
    
    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          disableEditing();
        }
      };
    
    useOnClickOutside(formRef, disableEditing);
    useEventListener("keydown", onKeyDown); 

    const onTextareakeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          formRef.current?.requestSubmit();
        }
      };
    
    const { getListSize } = useTaskStoreActions();
    
    const onSubmit = (event: React.FormEvent) => { //(formData: FormData) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(formRef.current as HTMLFormElement);
        const title = formData.get("title") as string;
        const list_id = formData.get("listId") as string;
        const parent_elt = selectedBoard as string;
        const order_list = getListSize(listId);
        const type = "TASK";
        //console.log('title:', title, 'listId:', listId, 'boardId:', parent_elt);
        
        const originList = getListById(listId);
            const status = Object.keys(status_dict)
                .find(key => status_dict[key as keyof typeof status_dict] === originList?.title) as keyof typeof status_dict | undefined || "TO_DO";  // Apply fallback here

        if (selectedBoardElement?.title === "Overview") {
            execute({
                title,
                list_id: 'default-list-id', 
                parent_elt, 
                order_list: 100000, 
                type,
                status,
                //id: "",
                //created_at: undefined,
                //updated_at: undefined,
            });
        } else {
            execute({
                title,
                list_id, 
                parent_elt, 
                order_list, 
                type,
                status,
                //id: "",
                //created_at: undefined,
                //updated_at: undefined,
            });
        }
    };

    if(isEditing) {
        return (
            <form
                ref={formRef}
                onSubmit={onSubmit}
                className="m-1 py-0.5 px-1 space-y-4"
            >
                <FormTextarea
                    id="title"
                    className="focus-visible:border-[#E97631] dark:focus-visible:border-[#E97631]"
                    onKeyDown={onTextareakeyDown}
                    ref={ref}
                    placeholder={t("tasks.card.placeholder")}
                    errors={fieldErrors}
                />
                <input
                    hidden
                    id="listId"
                    name="listId"
                    defaultValue={listId}
                />
                <div className="flex items-center gap-x-1">
                    <FormSubmit variant="tasks">
                        {t("tasks.card.button2")}
                    </FormSubmit>
                    <Button 
                        onClick={disableEditing}
                        size="sm"
                        //variant="ghost"
                        className="bg-transparent text-[#E97631] hover:text-[#BA4F00] hover:bg-[#FAE3D8] dark:text-[#E97631] dark:hover:text-[#BA4F00] dark:hover:bg-[#FAE3D8]"
                    >
                        <X className="h-5 w-5" />
                    </Button>
                </div>
            </form>
        )
    }
    return (
        <div className="pt-2 px-2">
            <Button
                onClick={enableEditing}
                className="h-auto px-2 py-1.5 w-full justify-start text-muted-foreground text-sm text-[#FFC1A1] hover:text-[#DD691D] dark:text-[#63361E] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA]"
                size="sm"
                variant="ghost"
            >
                <Plus className="h-4 w-4 mr-2" />
                {t("tasks.card.button")}
            </Button>
        </div>
    );
});

CardForm.displayName = "CardForm";