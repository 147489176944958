import { useEffect, useState } from "react";

import { fetchContacts } from "../../../../api/contacts/api-getContactsList";
import { ContactTableElement } from "../../../../hooks/contact-store";
import { useColumns } from "./contacts-table-columns";
import { DataTable } from "./contacts-table-data";
import SkeletonRow from "./_components/SkeletonRow";
import { getContactsHookActions, useIsLeftPanelVisible, useNewContact } from "../../../../hooks/contact-hook";
import { getContactStoreActions, useContactTable } from "../../../../hooks/contact-store";

import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "../../../../components/ui/sheet";

import ContactDetails from "./contacts-details";
import BottomNavContacts from "../BottomNavContacts";
import { ModalProvider } from "../modals/modal-provider";
import { useSelectedTabContact } from "../../../../hooks/contact-hook";
import OpportunitiesComponent from "../opportunities";

import ProfileContacts from "../../../../pages/Profile/_components/profile-pages/profile-contacts";

const ContactsTable: React.FC = () => {
    
    const selectedTabContact = useSelectedTabContact();

    //const [contactsData, setContactsData] = useState<ContactTableElement[]>([]);
    const { initContactTable } = getContactStoreActions();

    useEffect(() => {
        const getContacts = async () => {
            try {
                const response = await fetchContacts();
                initContactTable(response);

            } catch (err) {
                //console.log("error: ", err);
            }
        };
        getContacts();
    }, []);

    const contactsData = useContactTable();
    
    const { setIsLeftPanelVisible, setSelectedContact, setNewContact, setInitialSelectedContact } = getContactsHookActions();
    const isLeftPanelVisible = useIsLeftPanelVisible();
    const newContact = useNewContact();
    
    const handleOpenSheet = (newContactTrigger?: boolean, contactId?: string) => {
        if (newContactTrigger) {
            setNewContact("contactCard");
            setSelectedContact(undefined);
            setIsLeftPanelVisible(true);
        } else if (contactId) {
            setSelectedContact(contactId);
            setNewContact(undefined);
            setIsLeftPanelVisible(true);
        } else {
            setNewContact(undefined);
            setSelectedContact(undefined);
            setInitialSelectedContact(undefined);
            setIsLeftPanelVisible(false);
        }
      };
    
    //console.log('Contacts Page table index');
    //console.log('newContact :', newContact);
    //console.log('isLeftPanelVisible :', isLeftPanelVisible);
    //console.log('contactsData :', contactsData?.length);

    // get columns for the table
    const columns = useColumns();
    
    return ( 
        <div>
            <ModalProvider />

            {(selectedTabContact === "All_Contacts") ? (
                <div className="container mx-auto py-1 dark:bg-[#1E1F24] min-h-screen">
                    {!contactsData ? (
                        <div className="mt-8">
                            <SkeletonRow columnsCount={columns.length} />
                            <SkeletonRow columnsCount={columns.length} />
                            <SkeletonRow columnsCount={columns.length} />
                        </div>
                    ) : (
                        <DataTable columns={columns} data={contactsData} handleOpenSheetFunction={handleOpenSheet} />
                    )}
                    {/* left panel component */}
                    <Sheet
                        open={isLeftPanelVisible}
                        onOpenChange={() => handleOpenSheet()}
                    >
                        <SheetContent className="w-full sm:max-w-none sm:w-1/2 p-0">
                            <ContactDetails />
                        </SheetContent>
                    </Sheet>
                </div>
            ) : (selectedTabContact === "Opportunities") ? (
                <OpportunitiesComponent />
            ) : (selectedTabContact === "Next_Best_Actions_toDo") ? (
                <div>
                    <h1>Best Actions</h1>
                </div>
            ) : (selectedTabContact === "Profile_contacts") ? (
                <ProfileContacts />
            ) : (
                <div className="flex flex-col w-full h-full">
                    <div className="container mx-auto py-1 dark:bg-[#1E1F24]">
                        {!contactsData ? (
                            <div className="mt-8 min-h-screen">
                                <SkeletonRow columnsCount={columns.length} />
                                <SkeletonRow columnsCount={columns.length} />
                                <SkeletonRow columnsCount={columns.length} />
                            </div>
                        ) : (
                            <DataTable columns={columns} data={contactsData} handleOpenSheetFunction={handleOpenSheet} />
                        )}

                        

                        {/* left panel component */}
                        <Sheet
                            open={isLeftPanelVisible}
                            onOpenChange={() => handleOpenSheet()}
                        >
                            <SheetContent className="w-full sm:max-w-none sm:w-1/2 p-0">
                                <ContactDetails />
                            </SheetContent>
                        </Sheet>

                        
                    </div>
                    <div className="flex flex-col w-full h-full mt-4">
                        <OpportunitiesComponent />
                    </div>
                </div>
            )}
            {/* Bottom Navigation Bar */}
            <div className="w-full fixed bottom-0 left-0 right-0 z-50">
                <BottomNavContacts handleOpenSheetFunction={handleOpenSheet} />
            </div>
        </div>
     );
}
 
export default ContactsTable;