import { MoreHorizontal, Copy, Trash2, Mails, SquareUserRound, Pencil, CircleFadingPlus, Forward, Plus } from "lucide-react";
//import { useToast } from "../../../components/ui/use-toast";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"

import { CustomElementNodeType } from "./CustomElementNode";
import { useModal } from "../modals/use-modal-store";

import { toast } from "sonner";

import { useAction } from "../../../../hooks/use-action";
import { PlanElement } from "../../../../api/career/interface-career";
import { createPlanElement } from "../../../../api/career/create-plan-element";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { deletePlanElement } from "../../../../api/career/delete-plan-element";

// Define the props interface
interface NodeActionMenuProps {
    props: CustomElementNodeType;
}
 
//props: CustomElementNodeType
//const NodeActionMenu = ({ contact }: ContactTableActionCellProps) => {
const NodeActionMenu = ({ props }: NodeActionMenuProps) => {

  const { getPlanElement, updateMultiplePlanElementHook, deletePlanElementHook } = useCareerHookActions();
  const selectedElement: PlanElement | undefined = props.id ? getPlanElement(props.id) : undefined;
  
  const { 
      execute: executeCopyPlanElement,
      isLoading: isLoadingCopy,
  } = useAction(createPlanElement, {
      onSuccess: (data) => {
          //console.log("hitting copy card API call", data);
          updateMultiplePlanElementHook(data);
          toast.success(`Card "${data[0].title}" copied`);
      },
      onError: (error) => {
          toast.error(error);
      },
  });

  const { 
      execute: executeDeletePlanElement, 
      isLoading: isLoadingDelete,
  } = useAction(deletePlanElement, {
      onSuccess: (data) => {
          deletePlanElementHook(data);
          toast.success(`Card "${data[0].title}" deleted`);
      },
      onError: (error) => {
          toast.error(error);
      },
  });

    const onAddCard = () => {
      if (!selectedElement) return;

      const { created_at, updated_at, ...selectedElementToCopy } = selectedElement;

      //console.log("selectedElementToCopy", selectedElementToCopy);

      //console.log(selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined);

      executeCopyPlanElement({
          ...selectedElementToCopy,
          id: selectedElement?.id,
          title: "New card",
          plan_id: selectedElement?.plan_id,
          start_date: undefined, //selectedElement?.start_date ? new Date(selectedElement?.start_date) : undefined,
          end_date: undefined, // selectedElement?.end_date ? new Date(selectedElement?.end_date) : undefined,
          deadline_date: undefined, // selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined,
          parent_elt: selectedElement?.parent_elt || undefined,
          previous_elt: selectedElement?.previous_elt || undefined,
          next_elt: selectedElement?.next_elt || undefined,
          description: undefined,
      });
  };

  const onCopy = () => {
      //console.log("onCopy");
      //console.log("selectedElement", selectedElement);

      if (!selectedElement) return;

      const { created_at, updated_at, ...selectedElementToCopy } = selectedElement;

      //console.log("selectedElementToCopy", selectedElementToCopy);

      //console.log(selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined);
      try {
        executeCopyPlanElement({
          ...selectedElementToCopy,
          id: selectedElement?.id,
          title: "Copy of " + selectedElement?.title,
          plan_id: selectedElement?.plan_id,
          start_date: undefined, //selectedElement?.start_date ? new Date(selectedElement?.start_date) : undefined,
          end_date: undefined, // selectedElement?.end_date ? new Date(selectedElement?.end_date) : undefined,
          deadline_date: undefined, // selectedElement?.deadline_date ? new Date(selectedElement?.deadline_date) : undefined,
          parent_elt: selectedElement?.parent_elt || undefined,
          previous_elt: selectedElement?.previous_elt || undefined,
          next_elt: selectedElement?.next_elt || undefined,
          description: selectedElement?.title ? "Copy of " + selectedElement?.title : undefined,
        });

      } catch (error) {
          console.error("error copying : ", error);
      }
      
  };

  const onDelete = () => {

      executeDeletePlanElement({
          id: selectedElement?.id || props.id,
      });
  };

    // Use the useModal hook to open the modal
    const { onOpen } = useModal();

    //Function copyToClipBoard to store the email or phone number in clipboard
    /*
    async function copyTextToClipboard(type: string, text?: string) {
      if (!text) { 
          toast({
              description: `Can not copy to clipboard, ${type} is missing in the contact details`,
          });
      }
      else {
          if ('clipboard' in navigator) {
          try {
              await navigator.clipboard.writeText(text);
              toast({
                  description: `${type} copied to clipboard`,
              });
          } catch (err) {
              console.error('Failed to copy: ', err);
          }
          } else {
          // Fallback for older browsers
              try {
                  const textarea = document.createElement('textarea');
                  textarea.value = text;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textarea);
                  toast({
                      description: `${type} copied to clipboard`,
                  });
              } catch (err) {
                  console.error('Failed to copy: ', err);
                  toast({
                      description: `Fail to copy ${type} in the clipboard`,
                  });
              }
          }
      }
    };
*/

     return ( 
        <div onClick={(event) => event.stopPropagation()}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-4 w-4 p-0">
                    <span className="sr-only">Open node menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem
                    onClick={() => onOpen(
                      "editElement", 
                      {
                          planElementId: props.id,
                      }
                  )}
                  >
                    <Pencil size={16} />
                    <p className="ml-2">Edit step</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    //onClick={() => copyTextToClipboard("email", contact.email)}
                    onClick={onAddCard}
                  >
                    <Plus size={16} />
                    <p className="ml-2">Add a step</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    //onClick={() => copyTextToClipboard("email", contact.email)}
                    onClick={onCopy}
                  >
                    <Copy size={16} />
                    <p className="ml-2">Duplicate step</p>
                  </DropdownMenuItem>
                  
                  <DropdownMenuSeparator />
                  
                  <DropdownMenuItem
                    onClick={onDelete}
                  >
                    <Trash2 size={16} />
                    <p className="ml-2">Delete node</p>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
     );
}
 
export default NodeActionMenu;

/* Actions for the dropdown menu that will be develop later
<DropdownMenuItem
  //onClick={() => copyTextToClipboard("phone", contact.phone_number)}
>
  <CircleFadingPlus size={16} />
  <p className="ml-2">Create a sub-flow</p>
</DropdownMenuItem>

<DropdownMenuItem
  //onClick={() => handleOpenSheet(contact.id)}
>
  <Forward size={16} />
  <p className="ml-2">Send to tasks</p>
</DropdownMenuItem>
*/