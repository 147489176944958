import { toast } from "sonner";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";


import { ListWithCards } from "../../../interfaces/tasks/int-types";
import { useAction } from "../../../hooks/use-action";
import { updateListOrder } from "../../../api/tasks/update-list-order";
import { updateCardOrder } from "../../../api/tasks/update-card-order";

import { ListForm } from "./list-form";
import { ListItem } from "./list-item";
import { useLists, useSelectedBoardElement, useTaskStoreActions } from "../../../hooks/tasks-store";

interface ListContainerProps {
    data: ListWithCards[];
    boardId: string;
};

function reorder<T>(list: T[], startIndex: number, endIndex: number) { // function reorder for a generic T
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export const ListContainer = ({
    data,
    boardId,
}: ListContainerProps) => {

    const lists = useLists();
    
    const [orderedData, setOrderedData] = useState(data);

    const { updateStoredLists } = useTaskStoreActions();

    const { execute: executeUpdateListOrder } = useAction(updateListOrder, {
        onSuccess: (data) => {
            //console.log("data return of the API : ", data);
            updateStoredLists(data);
            toast.success("List reordered");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const { execute: executeUpdateCardOrder } = useAction(updateCardOrder, {
        onSuccess: (data) => {
            //console.log("data return of the API : ", data);
            toast.success("Card reordered");    
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    useEffect(() => {
        if (lists) {
            setOrderedData(lists);
        }
        
    }, [lists])

    const onDragEnd = (result: any) => {
        const { destination, source, type } = result; //extract everything we need
        //console.log("hitting ondragend function result", result);
        if(!destination) {
            return;
        }

        //if dropped in the same position
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
            ) {
                return;
            }

        // if user move a list
        if (type === "list") {
            //console.log("reordering list");
            const items = reorder(
                orderedData, 
                source.index, 
                destination.index
            ).map((item, index) => ({
                ...item, 
                order: index,
                created_at: item.created_at ? new Date(item.created_at) : undefined,
                updated_at: item.updated_at ? new Date(item.updated_at) : undefined,
            }));

            setOrderedData(items);
            executeUpdateListOrder({ items, boardId });
        }

        //if the user move a card
        if (type === "card") {
            let newOrderedData = [...orderedData];

            // source and destination list
            const sourceList = newOrderedData.find(list => list.id === source.droppableId);
            const destList = newOrderedData.find(list => list.id === destination.droppableId);

            if (!sourceList || !destList) {
                return;
            }

            // check if card exist on the source list
            if (!sourceList.cards) {
                sourceList.cards = [];
            }
            
            // check if card exist on the dest list
            if (!destList.cards) {
                destList.cards = [];
            }

            // Moving the card in the same list
            if (source.droppableId === destination.droppableId) {
                const reorderedCards = reorder(
                    sourceList.cards,
                    source.index,
                    destination.index,
                );

                reorderedCards.forEach((card, idx) => {
                    card.order_list = idx ?? 0;
                    card.id = card.id ? card.id : "na";
                    card.created_at = card.created_at ? new Date(card.created_at) : undefined;
                    card.updated_at = card.updated_at ? new Date(card.updated_at) : undefined;
                });

                sourceList.cards = reorderedCards;

                setOrderedData(newOrderedData);
                
                //trigger server action
                executeUpdateCardOrder({
                    boardId: boardId,
                    items: reorderedCards,
                })

                // user move the card to another list
            } else {
                //remove card to the source list
                const [movedCard] = sourceList.cards.splice(source.index, 1)

                //assign the new listId to the moved card
                movedCard.list_id = destination.droppableId;

                //update the card status to the new list if Zangou kanban template
                if (destList.title === "To Do") {
                    movedCard.status = "TO_DO";
                } else if (destList.title === "In Progress") {
                    movedCard.status = "IN_PROGRESS";
                } else if (destList.title === "Done") {
                    movedCard.status = "DONE";
                }

                //add card to the destination list
                destList.cards.splice(destination.index, 0, movedCard);

                sourceList.cards.forEach((card, idx) => {
                    card.order_list = idx;
                });

                //update the order in each card in destination list
                destList.cards.forEach((card, idx) => {
                    card.order_list = idx;
                    card.created_at = card.created_at ? new Date(card.created_at) : undefined;
                    card.updated_at = card.updated_at ? new Date(card.updated_at) : undefined;
                });

                setOrderedData(newOrderedData);
                
                //trigger the server action
                executeUpdateCardOrder({
                    boardId: boardId,
                    items: destList.cards
                })
            }
        }

    }

    // get board to deactivate list creation for overview board
    const selectedBoardElement = useSelectedBoardElement();

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="lists" type="list" direction="horizontal">
                {(provided) => (
                    <ol 
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="flex flex-grow text-[#BA4F00] dark:text-[#FFDECD] gap-x-3 mb-2 w-full h-full"
                    >
                        {orderedData.map((list, index) => {
                            return (
                                <ListItem 
                                    key={list.id}
                                    index={index}
                                    data={list}
                                />
                            )
                        })}
                        {provided.placeholder}

                        {selectedBoardElement?.title !== "Overview" && (
                            <ListForm />
                        )}

                        <div className="flex-shrink-0 w-1" />

                    </ol>
                )}
            </Droppable>
        </DragDropContext>
    );
};