import { ListWithCards } from "../../../interfaces/tasks/int-types";
import { ListHeader } from "./list-header";
import { ElementRef, useRef, useState } from "react";
import { CardForm } from "./card-form";
import { cn } from "../../../lib/utils";
import { CardItem } from "./card-item";
import { useTranslation } from "react-i18next";

import { Draggable, Droppable } from "@hello-pangea/dnd";

interface ListItemProps {
    data: ListWithCards;
    index: number;
}

export const ListItem = ({
    data,
    index,
}: ListItemProps) => {
    const { t } = useTranslation();
    const textareaRef = useRef<ElementRef<"textarea">>(null);
    const [isEditing, setIsEditing] = useState(false);

    const disableEditing = () => {
        setIsEditing(false);
      };
    
      const enableEditing = () => {
        setIsEditing(true);
        setTimeout(() => {
          textareaRef.current?.focus();
        });
      };

    return (
        <Draggable draggableId={data.id} index={index}>
            {(provided) => (
                <li
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className="shrink-0 h-full w-[272px] select-none"
                >
                    <div 
                        {...provided.dragHandleProps}
                        className="w-full bg-[#F7F5F4] dark:bg-[#1B130F] shadow-md pb-2"
                    >
                        <ListHeader 
                            onAddCard={enableEditing} 
                            data={data} 
                        />
                    <Droppable droppableId={data.id} type="card">
                        {(provided)=>(
                            <ol
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={cn(
                                    "mx-1 px-1 py-0.5 flex flex-col gap-y-2",
                                    data.cards?.length > 0 ? "mt-2" : "mt-0",
                                )}
                            >
                                {data.cards?.map((card, index) => card.id && (
                                    <CardItem 
                                        index={index}
                                        key={card.id && card.id}
                                        data={card}
                                    />
                                ))}
                                {provided.placeholder}
                            </ol>
                        )}
                    </Droppable>
                    <CardForm 
                        listId={data.id}
                        ref={textareaRef}
                        isEditing={isEditing}
                        enableEditing={enableEditing}
                        disableEditing={disableEditing}
                    />
                    </div>
                </li>
            )}
        </Draggable>
    );
};