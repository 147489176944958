import { CardModal } from "../card-modal";
import { useEffect, useState } from "react";
import { DeleteBoardModal } from "../delete-plan-modal";
import { useDeletePlanModal } from "../../../../hooks/tasks-use-card-modal";

export const ModalProvider = () => {
    // protection against hydration errors
    const deleteModal = useDeletePlanModal();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <CardModal />
            {deleteModal.isOpen && deleteModal.id && deleteModal.name &&(
                <DeleteBoardModal id={deleteModal.id} name={deleteModal.name} />
            )}
        </>
    );
};