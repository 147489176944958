import { z } from "zod";

const passwordValidation = z.string()
  .min(6, "Password must be at least 6 characters long")
  .regex(/[a-z]/, "Password must contain at least one lowercase letter")
  .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
  .regex(/[0-9]/, "Password must contain at least one number")
  .regex(/[^a-zA-Z0-9]/, "Password must contain at least one special character");


export const userResetPWDSchema = z.object({
    token: z.string(),
    password: passwordValidation,
    confirm_password: passwordValidation,
}).refine(
    (values) => {
      return values.password === values.confirm_password;
    },
    {
      message: "Passwords must match!",
      path: ["confirmPassword"],
    }
  );

  export type UserSchema = z.infer<typeof userResetPWDSchema>;