import React from 'react';
import img_career from '../../../../img/landing/career.svg';
import img_techwatch from '../../../../img/landing/techwatch.svg';
import img_contacts from '../../../../img/landing/contacts.svg';
import img_visibility from '../../../../img/landing/visibility.svg';
import img_tasks from '../../../../img/landing/tasks.svg';
import img_community from '../../../../img/landing/community.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

interface FeatureProps {
    title: string;
    description: string;
    image: string;
    reverse: boolean;
    color: string;
    dark_color: string;
    bg_color: string;
    bg_dark_color: string;
}

const Feature: React.FC<FeatureProps> = ({ title, description, image, reverse, color, dark_color, bg_color, bg_dark_color}) => {
    return (
        <div className={`flex flex-col h-full md:px-35 md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center gap-8 p-4 bg-[${bg_color}] dark:bg-[${bg_dark_color}]`}>
            <div className="flex justify-center md:flex-1">
                <img src={image} alt={title} className="w-full h-auto mx-auto" />
            </div>
            <div className="flex-1 flex flex-col justify-center items-start text-left space-y-2">
                <h2 className={`text-lg font-semibold ${color} dark:text-[${dark_color}]`}>{title}</h2>
                <p className="text-sm dark:text-slate-300">{description}</p>
            </div>
        </div>
    );
};

const HelpStartModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Start";

    const features: FeatureProps[] = [
        { title: t("landing.career.title"), description: t("landing.career.description"), image: img_career, reverse: false, color: 'text-career_primary', dark_color:'#FF95A0', bg_color:'#F9EFF0', bg_dark_color:'#1A1213'},
        { title: t("landing.techwatch.title"), description: t("landing.techwatch.description"), image: img_techwatch, reverse: true, color: 'text-techwatch_primary', dark_color:'#87BAFF', bg_color:'#EBF1FA', bg_dark_color:'#0D1723'},
        { title: t("landing.contacts.title"), description: t("landing.contacts.description"), image: img_contacts, reverse: false, color: 'text-contacts_primary', dark_color:'#61B6A3', bg_color:'#ECF3F1', bg_dark_color:'#111917'},
        { title: t("landing.community.title"), description: t("landing.community.description"), image: img_community, reverse: true, color: 'text-community_primary', dark_color:'#F98FDF', bg_color:'#F4E2EE', bg_dark_color:'#1D101A'},
        { title: t("landing.tasks.title"), description: t("landing.tasks.description"), image: img_tasks, reverse: false, color: 'text-tasks_primary', dark_color:'#FFA06A', bg_color:'#F8EEEA', bg_dark_color:'#1B130F'},
    ];

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-warning_blue bg-[#F8F5F5] dark:bg-[#212121] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-3xl text-center font-bold">
            {t("landing.welcome.title")}
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.welcome.description")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
        <div className="flex flex-col h-full w-full overflow-auto flex-grow text-[#000021] bg-[#F8F5F5] dark:bg-[#212121] antialiased">
            <div className='flex flex-col '>
                {/* Features Section */}
                {features.map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </div>
        </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
        
    );
}

export default HelpStartModal;
