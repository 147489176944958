import { cn } from '../../../../lib/utils';
import { useCareerStoreAll } from '../../../../hooks/career-store';
import React, { memo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { Node, NodeProps } from 'reactflow';

import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import icon_year_1 from "../../../../img/career_icons/year_1.png"
import icon_year_2 from "../../../../img/career_icons/year_2.png"
import icon_year_3 from "../../../../img/career_icons/year_3.png"
import icon_year_4 from "../../../../img/career_icons/year_4.png"
import icon_year_5 from "../../../../img/career_icons/year_5.png"

const handleStyle = { left: 10 };

type CustomStepNodeData = {
    year: number;
    type: string;
    title: string;
    description: string;
  };
   
export type CustomStepNodeType = NodeProps<CustomStepNodeData>;

function CustomStepNode(props: CustomStepNodeType) { //NodeProps<customStepNodeData>

  const { selected, sourcePosition, targetPosition } = props;

  const { getNodeById } = useCareerStoreAll();
  const node = getNodeById(props.id);

  const icons : Record<number, typeof icon_year_1>= {
    1: icon_year_1,
    2: icon_year_2,
    3: icon_year_3,
    4: icon_year_4,
    5: icon_year_5,
  };

  /*
  
  if (parseInt(props.id) === 1) {
    //console.log("nodes custom step node : ", node);
  }
  */

  
  return (
    <div className={cn("flex flex-col justify-center items-center text-career_primary h-20 border-b-2 border-career_primary", 
    `w-[440px]`,
    //`w-[${node?.width}px]`,
    )}>
        <div className="flex items-center justify-center w-10 h-10 mb-2 bg-white border-4 border-career_primary rounded-full m-0 p-0">
          <span className="text-2xl font-semibold m-0 p-0">{props.data.year}</span>
        </div>
        <div className='flex text-base mb-4'>{props.data.title}</div>
    </div>
  );
}

export default memo(CustomStepNode);

/*
        <Handle type="target" position={targetPosition ? targetPosition : Position.Left} className="bg-transparent border-transparent" />
        <Handle type="source" position={sourcePosition ? sourcePosition : Position.Right} className="bg-transparent" />

        <div className='flex text-lg'>
          <Avatar className="rounded-full w-12 h-12 object-cover">
            <AvatarImage src={icons[props.data.year]} />
            <AvatarFallback>{`Year ${props.data.year}`}</AvatarFallback>
          </Avatar>
        </div>

*/