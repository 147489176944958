//import { Channel, ChannelType, Server } from "@prisma/client";
import { Server, Channel, Member, Message } from "../../../../api/community/interface-community";
import { create } from "zustand";

export type ModalType = "createServer" | "invite" | "editServer" | "members" | "viewMembers" | "createChannel" | "leaveChannel" | "deleteServer" | "editChannel" | "deleteChannel" | "messageFile" | "deleteMessage";

interface ModalData {
    server?: Server;
    channel?: Channel;
    message?: Message;
    member?: Member;
    currentMemberId?: string;
}

interface ModalStore {
    type: ModalType | null;
    data: ModalData;
    isOpen: boolean;
    onOpen: (type: ModalType, data?: ModalData) => void;
    onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
    type: null,
    data: {},
    isOpen: false,
    onOpen: (type, data={}) => set({ isOpen: true, type, data}),
    onClose: () => set({ type: null, isOpen: false })
}));