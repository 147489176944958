import { toast } from "sonner";
import { List } from "../../../interfaces/tasks/int-list";
import { ElementRef, useRef } from "react";
import { MoreHorizontal, X } from "lucide-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose
} from "../../../components/ui/popover";
import { useAction } from "../../../hooks/use-action";
import { Button } from "../../../components/ui/button";
import { FormSubmit } from "../../../components/form/form-submit";
import { Separator } from "../../../components/ui/separator";
import { deleteList } from "../../../api/tasks/delete-list";
import { useTaskStoreActions } from "../../../hooks/tasks-store";
import { copyList } from "../../../api/tasks/copy-list";
import { useTranslation } from "react-i18next";
import { Board } from "../../../interfaces/tasks/int-board";

interface ListOptionsProps {
  data: List;
  onAddCard: () => void;
};

export const ListOptions = ({
  data,
  onAddCard,
}: ListOptionsProps) => {
  const { t } = useTranslation();
  const closeRef = useRef<ElementRef<"button">>(null);

  const { deleteListWithCards, getSelectedBoardElement, updateOneBoard } = useTaskStoreActions();

  const { execute: executeDelete } = useAction(deleteList, {
    onSuccess: (data) => {
      deleteListWithCards(data.id);

      // Retrieve the selected board element containing this list
      const selectedBoardElement = getSelectedBoardElement();

      if (selectedBoardElement && selectedBoardElement.id) {
        // Filter out the deleted list from the board's lists
        const updatedBoard: Board = {
          ...selectedBoardElement,
          lists: selectedBoardElement.lists?.filter(list => list.id !== data.id) || [],  // Remove the deleted list
        };
  
        // Use the updateOneBoard function to update the board in the store
        updateOneBoard(updatedBoard);
  
        toast.success(`List "${data.title}" deleted`);
      } else {
        toast.error("Board not found or is invalid");
      }
      
      closeRef.current?.click();
    },
    onError: (error) => {
      toast.error(error);
    }
  });

  const { updateStoredList } = useTaskStoreActions();
  const { execute: executeCopy } = useAction(copyList, {
    onSuccess: (data) => {
      updateStoredList(data);
      
      // Retrieve the selected board element containing this list
      const selectedBoardElement = getSelectedBoardElement();

      if (selectedBoardElement && selectedBoardElement.id) {
        // Add the copied list to the board's lists
        const updatedBoard: Board = {
          ...selectedBoardElement,
          lists: [...(selectedBoardElement.lists || []), data],  // Add the copied list
        };

        // Use the updateOneBoard function to update the board in the store
        updateOneBoard(updatedBoard);

        toast.success(`List "${data.title}" copied`);
      } else {
        toast.error("Board not found or is invalid");
      }

      closeRef.current?.click();
    },
    onError: (error) => {
      toast.error(error);
    }
  });


  const onDelete = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); // Prevent default form submission
      const formData = new FormData(event.currentTarget);
      const id = formData.get("id") as string;
      const board_id = formData.get("boardId") as string;

      executeDelete({ id, board_id });
  };
  
  const onCopy = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData(event.currentTarget);
    const id = formData.get("id") as string;
    const board_id = formData.get("boardId") as string;

    executeCopy({ id, board_id });
  };


  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="h-auto w-auto p-2" variant="ghost">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="px-0 pt-3 pb-3" side="bottom" align="start">
        <div className="text-sm font-medium text-center text-neutral-600 pb-4">
          {t("tasks.list.settings.title")}
        </div>
        <PopoverClose ref={closeRef} asChild>
          <Button className="h-auto w-auto p-2 absolute top-2 right-2 text-neutral-600" variant="ghost">
            <X className="h-4 w-4" />
          </Button>
        </PopoverClose>
        <Button
          onClick={onAddCard}
          className="rounded-none w-full h-auto p-2 px-5 justify-start font-normal text-sm"
          variant="ghost"
        >
          {t("tasks.list.settings.add")}
        </Button>
        <form 
            onSubmit={onCopy}
        >
          <input hidden name="id" id="id" value={data.id} />
          <input hidden name="boardId" id="boardId" value={data.board_id} />
          <FormSubmit
            variant="ghost"
            className="rounded-none w-full h-auto p-2 px-5 justify-start font-normal text-sm"
          >
            {t("tasks.list.settings.copy")}
          </FormSubmit>
        </form>
        <Separator />
        <form
          onSubmit={onDelete}
        >
          <input hidden name="id" id="id" value={data.id} />
          <input hidden name="boardId" id="boardId" value={data.board_id} />
          <FormSubmit
            variant="ghost"
            className="rounded-none w-full h-auto p-2 px-5 justify-start font-normal text-sm"
          >
            {t("tasks.list.settings.delete")}
          </FormSubmit>
        </form>
      </PopoverContent>
    </Popover>
  );
};