import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { GetMessage } from '../api/community/api-messages';

interface CommunityHook {
    comProfile: { username: string, avatar: string } | undefined;
	selectedServer: { id: string, name: string } | undefined;
	selectedChannel: { id: string, name: string } | undefined;
    selectedMessages: boolean | undefined;
    modifiedChannel: boolean;// | undefined;
    selectedMember : string | undefined;
    selectedCurrentMember: { id: string, role: string } | undefined;
    messages : GetMessage[] | undefined;
    page : number;
    hasNextPage: boolean;

    actions: {
        setComProfile: (comProfile: {username: string, avatar: string} | undefined) => void;
		setSelectedServer: (selectedServer: { id: string, name: string } | undefined) => void;
		setSelectedChannel: (selectedChannel: { id: string, name: string }) => void;
        setSelectedMessages: (selectedMessages: boolean | undefined) => void;
        setModifiedChannel: (createChannel: boolean) => void; // | undefined
        setSelectedMember: (selectedMember: string ) => void;
        setSelectedCurrentMember: (selectedCurrentMember: { id: string, role: string } | undefined) => void;

        initMessages: (messages: GetMessage[]) => void;
        updateManyMessages: (messages: GetMessage[]) => void;
        setHasNextPage: (hasNextPage: boolean) => void;
        getPage: () => number;
        setPage: (page: number) => void;
        init: () => void;
		clear: () => void;

        // socket usage
        createMessage: (message: GetMessage) => void;
        updateMessage: (message: GetMessage) => void;
        deleteMessage: (message: GetMessage) => void;

        // clear messages
        clearMessages: () => void;

        // clear all the Store
        clearCommunityHook: () => void;
	}
}

const communityHook = createStore<CommunityHook>()(
    devtools(
        (set, get) => ({
            comProfile: undefined,
            selectedServer: undefined,
            selectedChannel: undefined,
            selectedMessages: undefined,
            modifiedChannel: false,
            selectedMember : undefined,
            selectedCurrentMember : undefined,
            // Pour gérer le chargement séquencé des messages
            messages : undefined,
            page: 1,
            hasNextPage: true,

            actions: {
                setComProfile: (comProfile: { username: string, avatar: string } | undefined) => {
                    set({
                        comProfile,
                    });
                },           
                setSelectedServer: (selectedServer: { id: string, name: string } | undefined) => {
                    set({
                        selectedServer,
                    });
                },
                setSelectedChannel: (selectedChannel: { id: string, name: string } | undefined) => {
                    set({
                        selectedChannel,
                        messages: undefined,
                        page: 1, 
                    });
                },
                setSelectedMessages: (selectedMessages: boolean | undefined) => {
                    set({
                        selectedMessages,
                    });
                },
                setModifiedChannel: (modifiedChannel: boolean) => { // | undefined
                    set({
                        modifiedChannel,
                    });
                },
                setSelectedMember: (selectedMember: string  | undefined) => {
                    set({
                        selectedMember,
                    });
                },
                setSelectedCurrentMember: (selectedCurrentMember: { id: string, role: string }  | undefined) => {
                    set({
                        selectedCurrentMember,
                    });
                },
                // Pour gérer le chargement séquencé des messages
                initMessages: (messages: GetMessage[]) => {
                    set({
                        messages,
                    });
                },
                updateManyMessages: (fetchedMessages: GetMessage[]) => {
                    const { messages } = get();
                    const existingMessagesIds = new Set(messages?.map(feed => feed.id));
                    const newMessages = fetchedMessages.filter(message => !existingMessagesIds.has(message.id));
                    if (newMessages.length > 0) {
                        if (messages) {
                            set({
                                // messages: [...messages, ...newMessages],
                                messages: [...newMessages, ...messages],
                                hasNextPage: newMessages.length >= 20,
                            });
                            //console.log("new messages state:", [...newMessages, ...messages]);
                        } else {
                            set({
                                messages: newMessages,
                                hasNextPage: newMessages.length >= 20,
                            });
                            //console.log("first loading messages state:", newMessages);
                            //console.log("first load - est§ce que les messages ont chargé ? : ", messages);
                        }
                    } else {
                        set({
                            hasNextPage: false,
                        });
                    }
                },
                createMessage: (newMessage: GetMessage) => {
                    set(({ messages }) => ({
                        messages: messages ? [ ...messages, newMessage] : [newMessage],
                    }));
                },
                updateMessage: (updatedMessage: GetMessage) => {
                    set(({ messages }) => ({
                        messages: messages ? messages.map(msg => msg.id === updatedMessage.id ? updatedMessage : msg) : [],
                    }));
                },
                deleteMessage: (deletedMessage: GetMessage) => {
                    set(({ messages }) => ({
                        //messages: messages ? messages.filter(msg => msg.id !== deletedMessageId) : [],
                        messages: messages ? messages.map(msg => msg.id === deletedMessage.id ? deletedMessage : msg) : [],
                    }));
                },
                clearMessages: () => {
                    set({
                        messages: undefined,
                        page: 1,
                    });
                },
                getPage: () => {
                    return get().page;
                },
                setPage: (page: number) => {
                    //console.log("setting page: ", page);
                    set({
                        page,
                    });
                },
                setHasNextPage: (hasNextPage: boolean) => {
                    set({ hasNextPage });
                },
                init: () => {
                    set({
                        messages: undefined,
                        page: 1,
                    });
                },
                clear: () => {
                    set({
                        selectedChannel: undefined,
                        messages: undefined,
                        page: 1,
                    });
                },
                clearCommunityHook: () => {
                    set({
                        comProfile: undefined,
                        selectedServer: undefined,
                        selectedChannel: undefined,
                        selectedMessages: undefined,
                        modifiedChannel: false,
                        selectedMember: undefined,
                        selectedCurrentMember: undefined,
                        messages: undefined,
                        page: 1,
                        hasNextPage: true,
                    });
                },
            },
        }),
        {
            name: 'community-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
);

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof communityHook, U>>;

// Selectors
const comProfileSelector = (state: ExtractState<typeof communityHook>) => state.comProfile;
const selectedServerSelector = (state: ExtractState<typeof communityHook>) => state.selectedServer;
const selectedChannelSelector = (state: ExtractState<typeof communityHook>) => state.selectedChannel;
const selectedMessagesSelector = (state: ExtractState<typeof communityHook>) => state.selectedMessages;
const ModifyChannelSelector = (state: ExtractState<typeof communityHook>) => state.modifiedChannel;
const selectedMemberSelector = (state: ExtractState<typeof communityHook>) => state.selectedMember;
const selectedCurrentMemberSelector = (state: ExtractState<typeof communityHook>) => state.selectedCurrentMember;
const messagesFromChannelSelector = (state: ExtractState<typeof communityHook>) => state.messages;
const pageSelector = (state: ExtractState<typeof communityHook>) => state.page;
const hasNextPageSelector = (state: ExtractState<typeof communityHook>) => state.hasNextPage;
const actionsSelector = (state: ExtractState<typeof communityHook>) => state.actions;


// getters
export const getComProfile = () => comProfileSelector(communityHook.getState());
export const getSelectedServer = () => selectedServerSelector(communityHook.getState());
export const getSelectedChannel = () => selectedChannelSelector(communityHook.getState());
export const getSelectedMessages = () => selectedMessagesSelector(communityHook.getState());
export const getModifiedChannel = () => ModifyChannelSelector(communityHook.getState());
export const getMember = () => selectedMemberSelector(communityHook.getState());
export const getCurrentMember = () => selectedCurrentMemberSelector(communityHook.getState());
export const getMessages = () => messagesFromChannelSelector(communityHook.getState());
export const getInterests = () => pageSelector(communityHook.getState());
export const getNextpage = () => hasNextPageSelector(communityHook.getState());
export const getActions = () => actionsSelector(communityHook.getState());

function useCommunityStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(communityHook, selector, equalityFn);
}

// Hooks
export const useComProfile= () => useCommunityStore(comProfileSelector);
export const useSelectedServer = () => useCommunityStore(selectedServerSelector);
export const useSelectedChannel = () => useCommunityStore(selectedChannelSelector);
export const useSelectedMessages = () => useCommunityStore(selectedMessagesSelector);
export const useModifyChannel = () => useCommunityStore(ModifyChannelSelector);
export const useSelectedMember = () => useCommunityStore(selectedMemberSelector);
export const useSelectedCurrentMember = () => useCommunityStore(selectedCurrentMemberSelector);
export const useMessages = () => useCommunityStore(messagesFromChannelSelector);
export const usePage = () => useCommunityStore(pageSelector);
export const useHasNextPage = () => useCommunityStore(hasNextPageSelector);
export const useCommunityActions = () => useCommunityStore(actionsSelector);
