import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { fetchGetGlbProfile } from '../../api/globals/api-profile';
import { GlbProfile } from '../../interfaces/profile/profile';

// Version of the language files to avoid caching issues
const version = process.env.REACT_APP_TRANSLATION_VERSION || '1.0.0';

// Environment variable to enable/disable debug mode
const env = process.env.REACT_APP_NODE_ENV || 'production';
const debug = env !== 'production';

// Define the interface for your profile response if not already defined
interface ProfileResponse {
  language?: 'french' | 'english' | 'default';
}

// Utility function to map response language to i18next supported languages
const mapLanguage = (lang: string | undefined): string => {
  switch (lang) {
    case 'french': return 'fr';
    case 'english': return 'en';
    default: return 'en'; // default to English if undefined or 'default'
  }
};

const customLanguageDetector = {
  name: 'customLanguageDetector',
  lookup: async (): Promise<string> => {
    try {
      const response: GlbProfile | null = await fetchGetGlbProfile();
      if (response){
        return mapLanguage(response.language);
      }
      else
        return 'en';
    } catch (error) {
      //console.error('Error fetching language preference:', error);
      return 'en';  // fallback language
    }
  },
  cacheUserLanguage: (lng: string) => {
    //console.log("Language cached:", lng);
  }
};

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Enables backend plugin to load translations.
  .use(Backend)
  // Detects user language
  .use(LanguageDetector)
  // Initialize i18next
  .init({
    // Set default language
    fallbackLng: 'en',
    // Debug mode shows logs in console
    debug: debug,
    // Define supported languages
    supportedLngs: ['en', 'fr'],
    // Use local json files
    backend: {
      loadPath: `/locales/{{lng}}.json?v=${version}`,  // Path to the translation files
    },
    detection: {
      order: ['customLanguageDetector', 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag'],
      caches: ['localStorage', 'cookie'],  // Configure caching as needed
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    }
  });

export default i18n;
