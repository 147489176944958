import { toast } from "sonner";
import { Card, Energy, energy } from "../../../../interfaces/tasks/int-card";

import { useAction } from "../../../../hooks/use-action";
import { useLists, useSelectedBoard, useTaskStoreActions } from "../../../../hooks/tasks-store";

import { updateCard } from "../../../../api/tasks/update-card";


import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../components/ui/select";

import { useTranslation } from "react-i18next";
import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { useEffect, useRef, useState } from "react";

interface EnergyUnitProps {
    data: Card; //CardWithList;
}

const EnergyUnit = ({ data }: EnergyUnitProps) => {

    const { t } = useTranslation();

    const { createOrUpdateCard, updateTasks } = useTaskStoreActions();

    const { execute } = useAction(updateCard, {
        onSuccess: (data) => {
            //createOrUpdateCard(data); => TO DO : update cards in the list when refactor lists
            updateTasks([data]);
            toast.success(`Card "${data.title}" updated`);
            //setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const changeEnergy = (status: Energy | undefined, card: Card) => {
        if (status && energy.includes(status)) {
            const parent_elt = data.parent_elt as string;
            execute({
                energy: status,
                id: data.id,
                parent_elt: parent_elt,
            });
        }
    }

    const [isEditing, setIsEditing] = useState(false);

    const handleEditing = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setIsEditing(true);
    }

    //handle escape key
    const triggerRef = useRef<HTMLButtonElement>(null);

    // Listen for "Escape" key to cancel editing
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            setIsEditing(false);
        }
    };

    // Add keydown event listener when editing starts
    useEffect(() => {
        if (isEditing) {
            document.addEventListener("keydown", handleKeyDown);
            // Focus the SelectTrigger automatically when editing starts
            if (triggerRef.current) {
                triggerRef.current.focus();
            }
        } else {
            document.removeEventListener("keydown", handleKeyDown);
        }

        // Cleanup event listener on unmount or when editing ends
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isEditing]);

    return ( 
        <div>
            {isEditing ? (
                <span className="flex text-sm text-muted-foreground m-0 p-0">
                    <Select
                        value={data.energy ?? "Medium"}
                        onValueChange={(value: Energy) => {changeEnergy(value, data)}}
                        open={isEditing}
                    >
                        <SelectTrigger ref={triggerRef} className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                            <SelectValue placeholder={data.energy ? data.energy.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Medium"} />
                        </SelectTrigger>
                        <SelectContent>
                        <SelectGroup>
                            <SelectLabel>{t("tasks.card.edit.energy")}</SelectLabel>
                                {energy.map((value) => (
                                    <SelectItem 
                                        key={value} 
                                        value={value}
                                        onClick={(e) => e.stopPropagation()} // Prevent event bubbling to the parent
                                    >
                                        {value.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                    </SelectItem>
                                ))}
                        </SelectGroup>
                        </SelectContent>
                    </Select>
                </span>
            ) : (
                <span 
                    onClick={(e) => handleEditing(e)}
                    className="text-sm text-muted-foreground items-center m-0 px-2 py-1 hover:cursor-pointer"
                >
                    {data.energy ? data.energy.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Medium"}
                </span>
            )}
        </div>
     );
}
 
export default EnergyUnit;