import axiosInstance from "../api";
import { Board } from "../../interfaces/tasks/int-board";
import { List } from "../../interfaces/tasks/int-list";
import { Card } from "../../interfaces/tasks/int-card";
import { ListWithCards } from "../../interfaces/tasks/int-types";

export const fetchUserBoards = async (): Promise<Board[]> => {
  const response = await axiosInstance.get<Board[]>(`/boards/`);
  return response.data;
};

export const fetchBoardLists = async (boardId: string): Promise<List[]> => {
  const response = await axiosInstance.get<List[]>(`lists/${boardId}`);
  return response.data;
};

export const fetcListById = async (listId: string): Promise<List> => {
  const response = await axiosInstance.get<List>(`lists/listById/${listId}`);
  return response.data;
};

//listsWithCards
export const fetchBoardListsWithCards = async (boardId: string): Promise<ListWithCards[]> => {
  const response = await axiosInstance.get<ListWithCards[]>(`lists/listsWithCards/${boardId}`);
  return response.data;
};

//overview - all tasks
export const fetchBoardOverviewTasksAll = async (): Promise<Card[]> => {
  const response = await axiosInstance.get<Card[]>(`boards/overview`);
  return response.data;
};

//listsWithCards overview
export const fetchBoardOverviewListsWithCards = async (boardId: string): Promise<ListWithCards[]> => {
  const response = await axiosInstance.get<ListWithCards[]>(`lists/listsWithCards-overview/${boardId}`);
  return response.data;
};