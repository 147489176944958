import { Plus, X } from "lucide-react";

import { useState, useRef, ElementRef } from "react";

import { ListWrapper } from "./list-wrapper";
import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { FormInput } from "../../../components/form/form-input";
import { FormSubmit } from "../../../components/form/form-submit";
import { Button } from "../../../components/ui/button";
import { useTranslation } from "react-i18next";

import { useAction } from "../../../hooks/use-action";
import { createList } from "../../../api/tasks/create-list";
import { toast } from "sonner";
import { getSelectedBoardElement, useSelectedBoard, useTaskStoreActions } from "../../../hooks/tasks-store";
import { ListWithCards } from "../../../interfaces/tasks/int-types";
import { Board } from "../../../interfaces/tasks/int-board";


export const ListForm = () => {

    const { t } = useTranslation();

    const selectedBoard = useSelectedBoard();
    const { getNumberOfLists, updateStoredList } = useTaskStoreActions();
    const numberOfLists = getNumberOfLists();

    const formRef = useRef<ElementRef<"form">>(null);
    const inputRef = useRef<ElementRef<"input">>(null);
  
    const [isEditing, setIsEditing] = useState(false);

    const enableEditing = () => {
        setIsEditing(true);
        setTimeout(() => {
            inputRef.current?.focus();
        });
    };

    const disableEditing = () => {
        setIsEditing(false);
    };

    const { updateOneBoard } = useTaskStoreActions();

    const { execute, fieldErrors } = useAction(createList, {
        onSuccess: (data) => {
            updateStoredList(data as ListWithCards);
            // TO DO : update boards with new list for the board
            const selectedBoardElement = getSelectedBoardElement();
            // Update the board with the new list
            if (selectedBoardElement && selectedBoardElement.id) {
                // Ensure that selectedBoardElement has an id before proceeding
                const updatedBoard: Board = {
                    ...selectedBoardElement,
                    lists: [...(selectedBoardElement.lists || []), data],  // Add new list to the board's lists
                };
    
                // Use the updateOneBoard function to update the board in the store
                updateOneBoard(updatedBoard);
    
                toast.success(`List "${data.title}" created`);
            } else {
                toast.error('Selected board not found or is invalid');
            }
            disableEditing();
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            disableEditing();
        };
    };

    useEventListener("keydown", onKeyDown);
    useOnClickOutside(formRef, disableEditing);

    //const onSubmit = (formData: FormData) => {
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        //const board_id = formData.get("board_id") as string;
        const board_id = selectedBoard || "";
        const order = numberOfLists || 0;

        execute({
            title,
            board_id,
            order,
        });
    }

    if (isEditing) {
        return (
            <ListWrapper>
                <form
                    onSubmit={onSubmit}
                    ref={formRef}
                    className="w-full p-3 bg-[#F7F5F4] dark:bg-[#1B130F] space-y-4 shadow-md"
                >
                    <FormInput 
                        ref={inputRef}
                        errors={fieldErrors}
                        id="title"
                        className="text-sm px-2 py-1 h-7 font-medium border-transparent hover:border-input transition focus:border-[#DC6A21]"
                        placeholder={t("tasks.list.placeholder")}
                    />
                    <input 
                        hidden
                        value={selectedBoard}
                        name="board_id"
                    />
                    <div className="flex items-center gap-x-1">
                        <FormSubmit
                        variant="tasks"
                        >
                        {t("tasks.list.button2")}
                        </FormSubmit>
                        <Button 
                            onClick={disableEditing}
                            size="sm"
                            //variant="ghost"
                            className="bg-transparent text-[#E97631] hover:text-[#BA4F00] hover:bg-[#FAE3D8] dark:text-[#E97631] dark:hover:text-[#BA4F00] dark:hover:bg-[#FAE3D8]"
                        >
                        <X className="h-5 w-5" />
                        </Button>
                    </div>    
                </form>
            </ListWrapper>
        )
    }

    return (
        <ListWrapper>
            <form
                className="w-full p-3 bg-[#F7F5F4] dark:bg-[#1B130F] space-y-4 shadow-md"
            >
                <button
                    onClick={enableEditing}
                    className="w-full h-7 px-2 py-1 text-[#FFC1A1] hover:text-[#DD691D] dark:text-[#63361E] dark:hover:text-[#9D5B38] dark:hover:bg-[#63361E] hover:bg-[#F8EEEA] transition flex items-center font-medium text-sm"
                >
                    <Plus className="h-4 w-4 mr-2"/>
                    {t("tasks.list.button")}
                </button>
            </form>
        </ListWrapper>
    )
}