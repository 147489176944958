import {
  Card,
} from "../../../components/ui/card";
import { useTheme } from "../../../components/theme-provider/theme-provider";
import hero_contacts_light from "../../../img/landing/main_landing/hero_contacts_light.png";
import hero_contacts_dark from "../../../img/landing/main_landing/hero_contacts_dark.png";
import hero_community_light from "../../../img/landing/main_landing/hero_community_light.png";
import hero_community_dark from "../../../img/landing/main_landing/hero_community_dark.png";
import hero_tasks_light from "../../../img/landing/main_landing/hero_tasks_light.png";
import hero_tasks_dark from "../../../img/landing/main_landing/hero_tasks_dark.png";
import hero_career_light from "../../../img/landing/main_landing/hero_career_light.png";
import hero_career_dark from "../../../img/landing/main_landing/hero_career_dark.png";

export const HeroCards = () => {

  const { theme } = useTheme();
  const img_contact = theme === 'dark' ? hero_contacts_dark : hero_contacts_light;
  const img_community = theme === 'dark' ? hero_community_dark : hero_community_light;
  const img_tasks = theme === 'dark' ? hero_tasks_dark : hero_tasks_light;
  const img_career = theme === 'dark' ? hero_career_dark : hero_career_light;
  return (
    <div className="hidden lg:flex flex-row flex-wrap gap-8 relative w-[700px] h-[500px]">
      {/* Testimonial */}
      <Card className="absolute w-[340px] -top-[15px] drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <img src={img_community} alt="community"/>
      </Card>

      {/* Team */}
      <Card className="absolute right-[20px] top-4 w-80 flex flex-col justify-center items-center drop-shadow-xl shadow-black/10 dark:shadow-white/10">
          <img
            src={img_career}
            alt="career" />
      </Card>

      {/* Pricing */}
      <Card className="absolute top-[150px] left-[50px] w-72  drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <img src={img_contact} alt="contacts"/>
      </Card>

      {/* Service */}
      <Card className="absolute w-[350px] -right-[10px] bottom-[35px]  drop-shadow-xl shadow-black/10 dark:shadow-white/10">
        <img src={img_tasks} alt="tasks"/>
      </Card>
    </div>
  );
};
