import { create, useStore } from 'zustand';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  Node as ReactFlowNode, 
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { PlanElement } from '../api/career/interface-career';

//import initialNodes from './nodes';
//import initialEdges from './edges';

export type NodeData = {
  color: string;
};

export type RFState = {
  planTitle: string;
  nodes: ReactFlowNode[]; //Node<NodeData>[];
  edges: Edge[];
  nodeOption: string;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setPlanTitle: (title: string) => void;
  getNodeById(id: string): Node | undefined;
  setNodes: (nodes: Node[]) => void;
  setEdges: (edges: Edge[]) => void;
  updateNode: (planElement: PlanElement) => void;
  updateMultipleNodes: (planElements: PlanElement[]) => void;
  updateNodeColor: (nodeId: string, color: string) => void;
  updateNodeOption: (newNodeOption: string) => void;
  clearCareerStore: () => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const careerStore = create<RFState>((set, get) => ({
  planTitle: "",
  nodes: [],//initialNodes,
  edges: [], //initialEdges,
  nodeOption: "ClassicNode",
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
    //console.log('initial node state : ', get().nodes)
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },
  setPlanTitle: (planTitle: string) => {
    set({ planTitle });
  },
  getNodeById: (nodeId: string) => {
    const { nodes } = get(); // Destructure to get the nodes array from the current state
    return nodes.find(node => node.id === nodeId); // Return the node that matches the given ID, or undefined if not found
  },
  setNodes: (nodes: Node[]) => {
    set({ nodes });
  },
  setEdges: (edges: Edge[]) => {
    set({ edges });
  },
  updateNode: (planElement: PlanElement) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === planElement.id) {
          node.data = {
            ...node.data,
            title: planElement.title,
            description: planElement.description,
            category: planElement.category,
            year: planElement.year,
            type: planElement.type,
            label: planElement.title,
            previous_elt: planElement.previous_elt,
            next_elt: planElement.next_elt,
          };
        }
        return node;
      }),
    });
/*
    const { nodes } = get();
    const nodeToUpdate = nodes.findIndex((f) => f.id === planElement.id);
    if (nodeToUpdate !== -1) {
      nodes.map((node) => {});

      nodes[nodeToUpdate].data = {
        ...nodes[nodeToUpdate].data,
        title: planElement.title,
        description: planElement.description,
        category: planElement.category,
        year: planElement.year,
        type: planElement.type,
        label: planElement.title,
        previous_elt: planElement.previous_elt,
        next_elt: planElement.next_elt,
      };
    }
    set({ nodes });
*/
  },
  updateMultipleNodes: (planElements: PlanElement[]) => {

  },
  updateNodeColor: (nodeId: string, color: string) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          // it's important to create a new object here, to inform React Flow about the cahnges
          node.data = { ...node.data, color };
        }
        return node;
      }),
    });
  },
  updateNodeOption: (newNodeOption: string) => {
    set({
        nodeOption: newNodeOption,
    });
  },
  clearCareerStore: () => {
    set({
      planTitle: "",
      nodes: [],
      edges: [],
      nodeOption: "ClassicNode",
    });
  },
}));

export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;


type Params<U> = Parameters<typeof useStore<typeof careerStore, U>>;

// Selectors
const selectorAll = (state: ExtractState<typeof careerStore>) => ({
    planTitle: state.planTitle,
    nodes: state.nodes,
    edges: state.edges,
    setPlanTitle: state.setPlanTitle,
    getNodeById: state.getNodeById,
    setNodes: state.setNodes,
    setEdges: state.setEdges,
    updateNode: state.updateNode,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    updateNodeColor: state.updateNodeColor,
    clearCareerStore: state.clearCareerStore,
  });
const nodeOptionSelector = (state: ExtractState<typeof careerStore>) => state.nodeOption;
const nodeOptionActionSelector = (state: ExtractState<typeof careerStore>) => state.updateNodeOption; 

// getters
export const getNodeOptionAction = () => nodeOptionActionSelector(careerStore.getState());

function useCareerStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(careerStore, selector, equalityFn);
}

//export default useCareerStore;

// Hooks
export const useCareerStoreAll = (equalityFn?: typeof shallow) => useCareerStore(selectorAll, equalityFn);
export const useCareerStoreNodeOption = (equalityFn?: typeof shallow) => useCareerStore(nodeOptionSelector, equalityFn);
