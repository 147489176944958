import { CalendarCheck, CalendarDays, CalendarIcon, CalendarRange, ChevronsLeftRightEllipsis, DatabaseZap, DollarSign, Landmark, NotebookText, PiggyBank, Shield, Square, SquareCheck, StickyNote } from "lucide-react";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { Card, CardHeader, CardContent, CardTitle } from "../../../../../components/ui/card";
import { Badge } from "../../../../../components/ui/badge";
import { ActionTooltip } from "../../../../../components/action-tooltip";

interface OppyInterface {}

const sampleOppyData: any = {
    title: "Data Engineer Python",
    description: "As a member of the IoT laboratory, you will analyse the data collected from the hundred of sensors of our product",
    status:"In progress",
    priority:"high",
    value:150000,
    rate:680,
    start_date:"10/08/2024",
    end_date:"10/08/2025",
    exp_close_date:"10/09/2024",
    companies : [
        {
            id: "xxx",
            name: "Accenture",
            role: "intermediary",
            contacts: [
                {
                    id:"zzzz",
                    firstName: "Sébastien",
                    lastName: "Lemaquis",
                    job_title: "Data analysis partner"
                }
            ]
        },
        {
            id: "xxy",
            name: "AXA",
            role: "final-client",
            contacts: [
                {
                    id:"zzzz",
                    firstName: "John",
                    lastName: "Doe",
                    job_title: "Head of datascience",
                    email: "john.doe@axa.com"
                }
            ]
        }
    ],
    last_note: {
        id:"yyy",
        text:"This is our last note, we are still waiting from the client budget confirmation"
    },
    last_actions: [
        {
            id: "aaaa",
            title: "Analyse opportunity",
            status: "DONE"
        },
        {
            id: "bbbb",
            title: "Call John for context and information",
            status: "IN_PROGRESS"
        },
        {
            id: "cccc",
            title: "Send proposal",
            status: "TO_DO"
        }
    ]
}

const OppyCardSample = () => {
    return ( 
        <div className="italic">
            <Card 
                key="vvv"
                className="flex flex-col my-2 p-1 bg-white dark:bg-[#0D1723] shadow-md rounded-none max-w-full h-full" //rounded-lg
            >
                <CardHeader
                    className="text-techwatch_primary text-lg font-bold py-1 px-2"
                >
                    <CardTitle
                        className="flex flex-row items-center justify-between text-lg md:text-xl font-bold  m-0 p-0 gap-x-2 text-[#133867] dark:text-[#D1E7FF]"
                    >
                        <div>
                            {sampleOppyData.title} (sample)
                        </div>
                        <div className="flex flex-row gap-x-2 items-center">
                            <Badge
                                variant="default"
                                className="bg-contacts_primary md:text-sm"
                            >
                                {sampleOppyData.status} 
                            </Badge>
                            <Badge
                                variant="default"
                                className="bg-contacts_primary md:text-sm"
                            >
                                {sampleOppyData.priority}
                            </Badge>
                        </div>
                    </CardTitle>
                    
                </CardHeader>
                <CardContent
                    className="flex flex-row items-start px-1 py-1 w-full h-full gap-x-2"
                >
                    {/* Company contacts */}
                    <div id="company-contacts" className="flex flex-col p-1 text-sm border-r-2 w-1/5 h-full gap-y-2">
                        <div className="flex flex-row gap-x-1 items-center font-medium">
                            <Landmark className="h-4 w-4" /> Client / Prospect
                        </div>
                        {sampleOppyData.companies.map((company: any) => (
                            <div className="flex flex-col justify-start pl-2">
                                <div className="flex flex-row justify-start items-center gap-x-1 pl-2">
                                    {(company.role === "intermediary") ? (
                                        <span className="">
                                            <ActionTooltip label="Intermediary">
                                                <ChevronsLeftRightEllipsis className="h-4 w-4" />
                                            </ActionTooltip>
                                        </span>
                                    ) : (
                                        <span>
                                            <ActionTooltip label="Final Client">
                                                <Shield className="h-4 w-4" />
                                            </ActionTooltip>
                                        </span>
                                    )}
                                    <div className="pl-1 font-semibold">
                                        {company.name}
                                    </div>
                                </div>
                                <div className="pl-6">
                                    {company.contacts.map((contact: any) => (
                                        <div className="flex flex-col pl-2">
                                            <div className="text-sm">
                                                {contact.firstName} {contact.lastName}
                                            </div>
                                            <div className="text-xs italic">
                                                {contact.job_title}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Description - notes */}
                    <div id="description-note" className="flex flex-col text-sm p-1 border-r-2 w-2/5 h-full gap-y-3">
                        <div className="flex flex-col">
                            <span className="flex flex-row gap-x-1 items-center pb-2 font-medium">
                                <NotebookText className="h-4 w-4" />Description
                            </span>
                            {sampleOppyData.description}
                        </div>
                        <div className="flex flex-col">
                            <span className="flex flex-row gap-x-1 items-center pb-2 font-medium">
                                <StickyNote className="h-4 w-4" />Last note
                            </span>
                            {sampleOppyData.last_note.text}
                        </div>
                    </div>

                    {/* Key points */}
                    <div id="indicators" className="flex flex-col text-sm p-1 border-r-2 w-1/5 h-full gap-y-1">
                        <span className="flex flex-row gap-x-1 items-center pb-2 font-medium">
                                <DatabaseZap className="h-4 w-4" />Key points
                            </span>
                        <div className="flex flex-row gap-x-1 items-center pl-2">
                            <DollarSign className="h-4 w-4" /> TJM: {sampleOppyData.rate} 
                        </div>
                        <div className="flex flex-row gap-x-1 items-center pl-2">
                            <PiggyBank className="h-4 w-4" /> Value: {sampleOppyData.value} 
                        </div>
                        <div className="flex flex-col pl-2">
                            <div className="flex items-center gap-x-1">
                                <CalendarRange className="h-4 w-4" />
                                <span>Period:</span>
                            </div>
                            <div className="flex flex-col ml-7">  {/* Indent the dates under "Period" */}
                                <span>{sampleOppyData.start_date}</span>
                                <span>{sampleOppyData.end_date}</span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-x-1 items-start pl-2">
                            <div className="flex items-center gap-x-1">
                                <CalendarCheck className="h-4 w-4" />
                                <span>Exp. close date:</span>
                            </div>
                            <div className="flex flex-col ml-7">  {/* Indent the dates under "Period" */}
                                <span>{sampleOppyData.exp_close_date}</span>
                            </div>
                        </div>
                    </div>
                    
                    {/* Actions */}
                    <div id="actions" className="flex flex-col text-sm p-1 w-1/5 h-full gap-y-1">
                        <div className="flex flex-row items-center gap-x-1 pb-2 font-medium">
                            <DirectionsRunIcon className="w-4 h-4" />
                            <span>Next actions</span>
                        </div>
                        
                        {sampleOppyData.last_actions.map((action: any) => (
                            action.status === "DONE" ? (
                                <div className="flex flex-row items-start gap-x-2">
                                    <div className="pt-0.5">
                                        <SquareCheck className="h-4 w-4" />
                                    </div>
                                    <div className="line-through">
                                        {action.title}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row items-start gap-x-2">
                                    <div className="pt-0.5">
                                        <Square className="h-4 w-4" />
                                    </div>
                                    <div>
                                        {action.title}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </CardContent>
            </Card>
        </div>
     );
}
 
export default OppyCardSample;