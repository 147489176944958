import { InputType, ReturnType } from "./types";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { MoveCard } from "./schema";

import axiosInstance from "../../api";
import { Card } from "../../../interfaces/tasks/int-card";


const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting update card API call");
    try {
        const response = await axiosInstance.patch<Card>(`/cards/moveCard`, data);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An error occurred"
        };
    }
};

export const moveCard = createSafeAction(MoveCard, handler);