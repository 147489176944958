import { InputType, ReturnType } from "./types";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { CopyList } from "./schema";
import axiosInstance from "../../api";
import { ListWithCards } from "../../../interfaces/tasks/int-types";

const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting copy list API call");
    try {
        //console.log('data copy list API call :', data);
        const response = await axiosInstance.post<ListWithCards>(`/lists/copyList`, data);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const copyList = createSafeAction(CopyList, handler);