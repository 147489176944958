export interface Card {
    id: string;
    user_id?: string;
    title: string;
    description?: string | null;
    order: number;
    order_list: number;
    order_calendar?: number;
    order_kanban?: number;

    //listId: string;
    list_id: string;
    
    parent_elt?: string;
    type: string;

    duration?: Duration;
    energy?: string;
    status?: Status;
    due_date?: string;
    created_at?: Date | undefined;
    updated_at?: Date | undefined;

    boardTitle?: string; // added for search purposes in the task table
  };

export type Duration = "V_MIN" | "X_MIN" | "XV_MIN" | "XXX_MIN" | "XLV_MIN" | "I_H" | "II_H" | "III_H" | "IV_H" | "VI_H" | "VIII_H" | "I_DAY";

export type Energy = "LOW" | "MEDIUM" | "HIGH";
export const energy = ["LOW", "MEDIUM", "HIGH"];

export const duration_dict: { [key in Duration]: string } = {
  "V_MIN": "5 minutes",
  "X_MIN": "10 minutes",
  "XV_MIN": "15 minutes",
  "XXX_MIN": "30 minutes",
  "XLV_MIN": "45 minutes",
  "I_H": "1 hour",
  "II_H": "2 hours",
  "III_H": "3 hours",
  "IV_H": "4 hours",
  "VI_H": "6 hours",
  "VIII_H": "8 hours",
  "I_DAY": "1 day"
}

export type Status = "TO_DO" | "IN_PROGRESS" | "DONE";

export const status_dict: { [key in Status]: string } = {
  "TO_DO": "To Do",
  "IN_PROGRESS": "In Progress",
  "DONE": "Done"
}