import axiosInstance from "../api";
import { ExistingPlan, Path, Plan, PlanElement, Question } from "./interface-career";

export const fetchUserPlans = async (): Promise<ExistingPlan[]> => {
    const response = await axiosInstance.get<ExistingPlan[]>(`/car-plan/plansFromUser/`);
    return response.data;
  };

export const fetchPathPlan = async (path: Path): Promise<Plan> => {
    const response = await axiosInstance.post<Plan>(`/car-plan/getPlanByPath`, path);
    return response.data;
  };

export const fetchPlanQuestions = async (planId: string): Promise<Question[]> => {
    const response = await axiosInstance.get<Question[]>(`/car-plan/getQuestionsFromPlan/${planId}`);
    return response.data;
  };

export const fetchPlanElementsData = async (planId: string): Promise<PlanElement[]> => {
    const response = await axiosInstance.get<PlanElement[]>(`/car-plan/elementsFromPlan/${planId}`);
    return response.data;
  };