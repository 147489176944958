import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Bold, Italic, Plus, Underline, SendHorizontal } from "lucide-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group";
import { useSelectedChannel } from "../../../../hooks/community-hook";
import io from 'socket.io-client';
//import useWebSocketStore from "../../../../hooks/socket-store";
//import CookieService from "../../../../lib/services/cookie-service";
import { useWebSocket } from "../../../../hooks/socket-context";
import { EmojiPicker } from "../emoji-picker";
import { ElementRef, useRef } from "react";


interface ChatInputProps {
  name: string;
  type: "conversation" | "channel";
}

const formSchema = z.object({
  content: z.string().min(1),
});

const ChatInput = ({
  name,
  type,
}: ChatInputProps) => {
    const { socket } = useWebSocket();
    const selectedChannel = useSelectedChannel();
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        content: "",
      }
    });

    interface FormData {
      content: string;
    }
    const formRef = useRef<ElementRef<"form">>(null);

    const { handleSubmit, formState: { isSubmitting } } = form;

    const onSubmit = async (data: FormData) => {
      //console.log("Attempting to send new message", data);
      if(selectedChannel && socket) {
        socket.emit('newMessage', {
          content: data.content,
          channelId: selectedChannel.id,
          tempMessageId: Date.now().toString(),
        }, (response: any) => {
          //console.log("Server acknowledgment:", response);
        });
      } else {
        //console.log("Socket or selectedChannel is not available");
      }
      form.reset();
    };

    const handleSendMessageButton = () => {
      formRef.current?.requestSubmit();
    }

    return (
        <div className="items-center w-auto h-full">
          <Form {...form}>
            <form 
              onSubmit={handleSubmit(onSubmit)}
              ref={formRef}
            >
              <FormField
                control={form.control}
                name="content"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div 
                        className="flex flex-row relative items-center gap-x-2 p-4"
                      >
                        <Input
                          className="px-5 py-6 bg-zinc-200/90 dark:bg-zinc-700/75 border-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-zinc-600 dark:text-zinc-200"
                          placeholder={`Message ${type === "conversation" ? name : "#" + name}`}
                          {...field}
                          
                        />
                        
                        <span className="hidden md:block absolute inset-y-0 right-12 mr-6 top-7 rounded-full items-center cursor-pointer">
                          <EmojiPicker
                            onChange={(emoji: string) => field.onChange(`${field.value} ${emoji}`)}
                          />
                        </span>
                        
                        <span 
                          className="p-3 bg-community_primary rounded-full items-center cursor-pointer"
                          onClick={handleSendMessageButton}
                        >
                          <SendHorizontal className="text-white h-5 w-5"  />
                        </span>
                          {/*<ToggleGroup type="multiple" className="items-center">
                            <ToggleGroupItem value="bold" aria-label="Toggle bold" onMouseDown={toggleBoldMark}>
                              <Bold className="h-4 w-4" />
                            </ToggleGroupItem>
                            <ToggleGroupItem value="italic" aria-label="Toggle italic">
                              <Italic className="h-4 w-4" />
                            </ToggleGroupItem>
                            <ToggleGroupItem value="underline" aria-label="Toggle underline">
                              <Underline className="h-4 w-4" />
                            </ToggleGroupItem>
                            
                          </ToggleGroup>*/}
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
    )
  }
  
  export default ChatInput;