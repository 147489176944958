import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { useModal } from "./use-modal-store";

const LegalNoticeModal = () => {
    const { isOpen, onClose, type } = useModal();
    const isModalOpen = isOpen && type === "legalNoticeModal";

    const handleClose = () => {
        onClose();
    }

    return (
    <div className="flex-col bg-[#F8F5F5]  dark:bg-[#1A1213] shadow-md rounded-lg overflow-auto">
        <Dialog key="cgu" open={isModalOpen} onOpenChange={handleClose}> 
            <DialogContent className="border-transparent text-left text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
                <ScrollArea className="flex-grow max-h-[80vh] w-full overflow-auto rounded-md">
                    <div>
                        <div id="header" className="pr-2">
                            <h1 className="text-xl font-bold py-4">Mentions Légales</h1>
                            <p className="text-md">
                                Le site www.zangou.ai est édité par la société française Zangou SAS.
                            </p>
                            <p className="text-md">
                                Adresse : 67 rue des Poissonniers 75018 Paris 
                            </p>
                            <p className="text-md">
                                Email : contact@zangou.ai 
                            </p>
                            <p className="text-md">
                                Capital : 1000 euros.
                            </p>
                            <p className="text-md">
                                Numéro d'immatriculation : RCS Paris n° 930 170 469 
                            </p>
                            <p className="text-md">
                                Numéro de TVA : FR93930170469
                            </p>
                            <p className="text-md">
                                Directeur de publication : Thibaut Préval 
                            </p>
                            <p className="text-md">
                                Centre de données à : UE
                            </p>
                            <p className="text-md">
                                Hébergement web : Amazon Web Services 
                            </p>
                        </div>
                    </div>
                    <ScrollBar orientation="vertical" className=" bg-opacity-0" />
                </ScrollArea>
            </DialogContent>
        </Dialog>
    </div>
    );
}
 
export default LegalNoticeModal;