import { ElementRef, useEffect, useRef, useState } from "react";

import { useAction } from "../../../../hooks/use-action";
import { useLists, useSelectedBoard, useSelectedBoardElement, useTaskStoreActions } from "../../../../hooks/tasks-store";

import { updateCard } from "../../../../api/tasks/update-card";
import { fetchCreateCard } from "../../../../api/tasks/create-card";

import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { Card, Duration, Energy } from "../../../../interfaces/tasks/int-card";

import { Layout } from "lucide-react";
import { toast } from "sonner";

import { FormInput } from "../../../../components/form/form-input";
import { Skeleton } from "../../../../components/ui/skeleton";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../components/ui/select";
import { Textarea } from "../../../../components/ui/textarea";
import { format } from "date-fns"
import { Button } from "../../../../components/ui/button"
import { Calendar as CalendarIcon } from "lucide-react"
import { Calendar } from "../../../../components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover"
import { cn } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";
import { useCardModal } from "../../../../hooks/tasks-use-card-modal";




interface HeaderProps {
    data: CardWithList;
}

const duration_dict: { [key in Duration]: string } = {
    "V_MIN": "5 minutes",
    "X_MIN": "10 minutes",
    "XV_MIN": "15 minutes",
    "XXX_MIN": "30 minutes",
    "XLV_MIN": "45 minutes",
    "I_H": "1 hour",
    "II_H": "2 hours",
    "III_H": "3 hours",
    "IV_H": "4 hours",
    "VI_H": "6 hours",
    "VIII_H": "8 hours",
    "I_DAY": "1 day"
}

const energy = ["LOW", "MEDIUM", "HIGH"];

export const Header = ({
    data,
}: HeaderProps) => {

    const { t } = useTranslation();

    const { createOrUpdateCard, addTask, updateTasks } = useTaskStoreActions();

    const selectedBoard = useSelectedBoard();
    const selectedBoardElement = useSelectedBoardElement();

    const { setTaskCardModalId } = useCardModal();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { execute: executeUpdateCard } = useAction(updateCard, {
        onSuccess: (data: Card) => {
            if (selectedBoardElement?.display_mode === "TABLE") {
                updateTasks([data]);
            } else {
                createOrUpdateCard(data);
            }
            toast.success(`Card "${data.title}" updated`);
            setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const { execute: executeCreateCard } = useAction(fetchCreateCard, {
        onSuccess: (data: Card) => {
            if (selectedBoardElement?.display_mode === "TABLE") {
                addTask(data);
                setTaskCardModalId(data.id);
            } else {
                createOrUpdateCard(data);
            }
            
            toast.success(`Card "${data.title}" updated`);
            setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const [title, setTitle] = useState(''); //data?.title ||

    // Adding a state to manage the editing state of the title (to avoid the onBlur event to be triggered when clicking on the title)
    const [isEditing, setIsEditing] = useState(data?.id ? false : true);

    const onBlur = () => {
        if (title !== data.title) {
            inputRef.current?.form?.requestSubmit();
        } else {
            setIsEditing(false);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        const parent_elt = selectedBoard as string;

        if (title === data.title) {
            setIsEditing(false);
            return;
        }

        if (!data.id) {
            executeCreateCard({
                title,
                parent_elt: data.parent_elt || selectedBoardElement?.id || '',
                list_id: data.list_id || 'default-list-id',
                order_list: data.order_list || 100000,
                type: data.type || "TASK"
            })
        } else {
            executeUpdateCard({
                title,
                parent_elt: data.parent_elt || selectedBoardElement?.id || '',
                id: data.id
            });
        }
    }
    
    useEffect(() => {
        if (isEditing && inputRef.current) {
            const adjustment = 2;
            inputRef.current.style.height = 'auto'; // Reset height to shrink back if text is deleted
            const idealHeight = inputRef.current.scrollHeight + adjustment;
            inputRef.current.style.height = `${idealHeight}px`;
        }

      }, [isEditing, title]);


    const changeDuration = (status: Duration | undefined) => {
        if (status && Object.keys(duration_dict).includes(status)) {
            const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
            const readableDuration = duration_dict[status as keyof typeof duration_dict];
            executeUpdateCard({
                duration: status,
                id: data.id,
                parent_elt: parent_elt,
            });
            //console.log(`Duration set to: ${readableDuration}`);
        }
    }

    const changeEnergy = (status: Energy | undefined) => {
        if (status && energy.includes(status)) {
            const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
            executeUpdateCard({
                energy: status,
                id: data.id,
                parent_elt: parent_elt,
            });
        }
    }

    const [dueDate, setDueDate] = useState<Date | undefined>();

    const handleDeadlineChange = (date: Date) => {
        const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
        executeUpdateCard({
            due_date: date,
            parent_elt,
            id: data.id,
        });
        setDueDate(date);
    };

    // Initialize state variables
    useEffect(() => {
        setTitle(data?.title);
        setDueDate(data.due_date ? new Date(data.due_date) : undefined);
        if (!data.id) {
            setIsEditing(true);
            setTimeout(() => {
                inputRef.current?.focus();
            });
        }
    }, [data]);

    return (
        <div className="flex items-start gap-x-3 mb-6 w-full text-xs md:text-sm">
            <Layout className="h-5 w-5 mt-1 text-neutral-700 dark:text-neutral-300" />
            <div className="w-full">
                <form onSubmit={onSubmit}>
                    {isEditing ? (
                        <Textarea
                            placeholder={t("tasks.card.placeholder")}
                            ref={inputRef}
                            onBlur={onBlur}
                            id="title"
                            name="title"
                            //defaultValue={title}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            rows={1}
                            className="w-11/12 font-semibold items-center text-sm md:text-lg px-1 pt-1 text-[#BA4F00] bg-[#F1F1F3] dark:bg-transparent dark:text-[#FFDECD] border-transparent relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                            style={{ minHeight: `36px`, lineHeight: '1.5' }}
                        />
                    ) : (
                        <h2
                            className="w-11/12 font-semibold text-sm md:text-lg text-[#BA4F00] bg-[#F1F1F3] dark:bg-transparent dark:text-[#FFDECD] dark:hover:border-tasks_primary dark:hover:border-[1px] px-1 py-1 items-center cursor-pointer"
                            onClick={() => setIsEditing(true)}
                        >
                            {title}
                        </h2>
                    )}
                </form>
                <div className="flex flex-row items-center gap-x-2 mt-1.5">
                    {data?.list?.title && data.list.title ? (
                        <span className="flex text-sm text-muted-foreground m-0 p-0">
                            <p className="hidden md:block text-sm text-muted-foreground">
                                in list <span className="underline">{data.list.title}</span>
                            </p>
                        </span>
                    ) : (
                        <p>pas de liste</p>
                    )}
                    <span className="flex text-sm text-muted-foreground m-0 p-0">
                        <Select
                            onValueChange={(value: Duration) => {changeDuration(value)}}
                            disabled={!data.id}
                        >
                            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                                <SelectValue placeholder={data.duration ? duration_dict[data.duration] : "Ajouter une durée"} />
                            </SelectTrigger>
                            <SelectContent>
                            <SelectGroup>
                                <SelectLabel>{t("tasks.card.edit.duration")}</SelectLabel>
                                    {Object.entries(duration_dict).map(([key, value]) => (
                                        <SelectItem key={key} value={key}>
                                            {value}
                                        </SelectItem>
                                    ))}
                            </SelectGroup>
                            </SelectContent>
                        </Select>
                    </span>
                    <span className="flex text-sm text-muted-foreground m-0 p-0">
                        <Select
                            onValueChange={(value: Energy) => {changeEnergy(value)}}
                            disabled={!data.id}
                        >
                            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                                <SelectValue placeholder={data.energy ? data.energy.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Medium"} />
                            </SelectTrigger>
                            <SelectContent>
                            <SelectGroup>
                                <SelectLabel>{t("tasks.card.edit.energy")}</SelectLabel>
                                    {energy.map((value) => (
                                        <SelectItem key={value} value={value}>
                                            {value.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                        </SelectItem>
                                    ))}
                            </SelectGroup>
                            </SelectContent>
                        </Select>
                    </span>
                    <span 
                        // Deadline date picker
                        className="flex px-0"
                    >
                        <Popover>
                            <PopoverTrigger asChild disabled={!data.id} >
                                <Button
                                variant={"outline"}
                                className={cn(
                                    //"w-[280px] justify-start text-left font-normal",
                                    "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                                    !dueDate && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {dueDate ? format(dueDate, "PPP") : <span className="hidden md:block">
                                    {t("tasks.card.edit.deadline")}
                                    </span>}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                mode="single"
                                selected={dueDate ? dueDate : undefined}
                                onSelect={(value) => value && handleDeadlineChange(value)}
                                initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </span>
                </div>
            </div>
        </div>
    );
};

Header.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };