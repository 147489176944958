import { useNavigate } from 'react-router-dom';

import { AccordionContent, AccordionItem, AccordionTrigger } from "../../../../components/ui/accordion";

import {
    Activity,
    CreditCard,
    Layout,
    Settings,
    Plus,
} from "lucide-react"

import { cn } from "../../../../lib/utils";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { FormPopover } from "../../../../components/form/form-popover";

import { useProfileHookActions, useSelectedProfilePage } from '../../../../hooks/profile-hook';

import { userProfiles } from '../../../Profile';
import { useSelectedTabProfile } from '../../../../hooks/profile-hook'; 
import { useGeneralHookActions } from '../../../../hooks/general-hook';

interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string; //Organization;
    userMemberships: string[];
    onExpand: (id: string) => void;
}

export const NavItemProfile = ({
    isExpanded,
    isActive,
    //membership,
    userMemberships,
    onExpand,
}: NavItemProps) => {

    const { setSelectedProfilePage } = useProfileHookActions();

    const selectedTab = useSelectedProfilePage();

    // Close the hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();

    const handleClick = (profile: string) => {
        setSelectedProfilePage(profile)
        //console.log("Clicked on Profile");
        closeHamburgerMenuSheet();
    };

    return (
        <AccordionItem
            value="Profile" //organization.id - otherTools
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand("otherTools")} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-neutral-700 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        Profile
                    </span>
                </div>
            </AccordionTrigger>
            <AccordionContent className="pt-1 text-gray-700 dark:text-gray-200">
                {userProfiles.map((profile) => (
                    <Button
                        key={profile}
                        size="sm"
                        onClick={() => handleClick(`${profile}`)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4 hover:font-bold hover:text-sky-700 dark:hover:text-sky-500",
                            selectedTab === profile && "bg-sky-700/20"
                            )}
                        variant="ghost"
                    >
                        {profile}
                    </Button>
                ))}
            </AccordionContent>

        </AccordionItem>
    );
};

NavItemProfile.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}