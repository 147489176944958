import { 
    ColumnDef, 
    SortingFn, // additionals imports for fuzzy matching on all columns
    sortingFns // additionals imports for fuzzy matching on all columns
  } from "@tanstack/react-table"

import { compareItems } from "@tanstack/match-sorter-utils";

import { ArrowUpDown, Contact, MoreHorizontal, Ellipsis } from "lucide-react";

import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Badge } from "../../../../components/ui/badge";

import { Card } from "../../../../interfaces/tasks/int-card";
import { useTranslation } from "react-i18next";
import { useCardModal } from "../../../../hooks/tasks-use-card-modal";
//import ContactTableActionCell from "./_components/ContactTableActionCell";  

import { Duration, duration_dict } from "../../../../interfaces/tasks/int-card";
import { use } from "i18next";

import TitleUnit from "../_component_units/TitleUnit";
import DurationUnit from "../_component_units/DurationUnit";
import EnergyUnit from "../_component_units/EnergyUnit";
import StatusUnit from "../_component_units/StatusUnit";
import ParentEltUnit from "../_component_units/ParentElt";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";

import {
  Column,
  Table,
} from '@tanstack/react-table'

// Handle changes directly in the table

  export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0
  
    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
      dir = compareItems(
        rowA.columnFiltersMeta[columnId]?.itemRank!,
        rowB.columnFiltersMeta[columnId]?.itemRank!
      )
    }
  
    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
  }

  // Use a type to extend the existing ColumnDef type
  type CustomColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
    columnName?: string; // Add the custom columnName property
  };
  
  // Define your columns
  export const useColumns = (): CustomColumnDef<Card>[] => {
    
    const { t } = useTranslation();
    //const cardModal = useCardModal();

    return [
    /*
      {
          id: "select",
          header: ({ table }) => (
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
              className="justify-center"
            />
          ),
          cell: ({ row }) => {
            return (
              <div onClick={(event) => event.stopPropagation()}>
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => row.toggleSelected(!!value)}
                  aria-label="Select row"
                />
              </div>
            )
          },
          enableSorting: false,
          enableHiding: false,
      },
      */
      {
          accessorKey: "title",
          columnName: t("tasks.table.headers.title"),
          header: ({ column }) => {
              return (
                <Button
                  variant="ghost"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                  {t("tasks.table.headers.title")}
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              )
          },
          filterFn: 'fuzzy',
          sortingFn: fuzzySort,
          cell: ({ row }) => {
            return (
              <div className="text-sm text-muted-foreground items-center m-0 px-0 py-1 hover:cursor-pointer">
                <TitleUnit data={row.original} />
              </div>
            )
          }
      },
      {
          accessorKey: "parent_elt",
          columnName: t("tasks.table.headers.board"),
          header: ({ column }) => {
              return (
                <Button
                  variant="ghost"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                  {t("tasks.table.headers.board")}
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              )
          },
          filterFn: 'fuzzy',
          /* -> changed to custom filterFn to be able to search on boardTitle instead of parent_elt (id)
          filterFn: (row, columnId, value) => {
            const boardTitle = row.original.boardTitle?.toLowerCase() || "";
            return boardTitle.includes(value.toLowerCase());
          },
          */
          sortingFn: fuzzySort,
          cell: ({ row }) => {
            return (
              <div className="text-sm text-muted-foreground items-center m-0 px-0 py-1 hover:cursor-pointer">
                <ParentEltUnit 
                  data={row.original} 
                  setSearchBoardTitle={(title: string) => (row.original.boardTitle = title)} // Store boardTitle in row.original
                />
              </div>
            )
          }
      },
      {
          accessorKey: "duration",
          columnName: t("tasks.table.headers.duration"),
          header: ({ column }) => {
              return (
                <Button
                  variant="ghost"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                  {t("tasks.table.headers.duration")}
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              )
          },
          filterFn: 'fuzzy',
          sortingFn: fuzzySort,
          cell: ({ row }) => {
            return (
              <DurationUnit data={row.original} />
            )
          }
      },
      {
          accessorKey: "energy",
          columnName: t("tasks.table.headers.energy"),
          header: ({ column }) => {
              return (
                <Button
                  variant="ghost"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                  {t("tasks.table.headers.energy")}
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              )
          },
          filterFn: 'fuzzy',
          sortingFn: fuzzySort,
          cell: ({ row }) => {
            return (
              <EnergyUnit data={row.original} />
            )
          }
      },
      {
          accessorKey: "status",
          columnName: t("tasks.table.headers.status"),
          header: ({ column }) => {
              return (
                <Button
                  variant="ghost"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                  {t("tasks.table.headers.status")}
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              )
          },
          filterFn: 'fuzzy',
          sortingFn: fuzzySort,
          cell: ({ row }) => {
            return (
              <StatusUnit data={row.original} />
            )
          }
      },
      {
        accessorKey: "due_date",
        columnName: t("tasks.table.headers.deadline"),
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("tasks.table.headers.deadline")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        cell: ({ row }) => {
          return (
            <div className="text-sm text-muted-foreground items-center m-0 px-0 py-1 hover:cursor-pointer">
              {row.original.due_date && new Date(row.original.due_date).toLocaleDateString()}
            </div>
          )
        }
    },
    {
        accessorKey: "created_at",
        columnName: t("tasks.table.headers.creationDate"),
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("tasks.table.headers.creationDate")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        cell: ({ row }) => {
          return (
            <div className="text-sm text-muted-foreground items-center m-0 px-0 py-1 hover:cursor-pointer">
              {row.original.created_at && new Date(row.original.created_at).toLocaleDateString()}
            </div>
          )
        }
    },
    {
        accessorKey: "updated_at",
        columnName: t("tasks.table.headers.lastUpdate"),
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("tasks.table.headers.lastUpdate")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        cell: ({ row }) => {
          return (
            <div className="text-sm text-muted-foreground items-center m-0 px-0 py-1 hover:cursor-pointer">
              {row.original.updated_at && new Date(row.original.updated_at).toLocaleDateString()}
            </div>
          )
        }
    },
    {
        id: "actions",
        header: ({ column, table }) => {
          //const contact:ContactTableElement  = row.original
      
          return (
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                  <Button 
                    variant="outline"
                    className="bg-transparent hover:bg-[#F8EEEA] dark:bg-[#2D544B] dark:hover:bg-[#437A6E] border-none m-0 p-0 py-1 h-1/2 align-middle items-center"
                    //className="hidden md:flex items-center align-middle ml-auto dark:bg-[#172A26] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
                  >
                  {
                    //t("contacts.contactsTable.top.columns")
                  }
                    <Ellipsis className="h-4" />
                  </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                  {table
                  .getAllColumns()
                  .filter(
                      (column) => column.getCanHide()
                  )
                  .map((column) => {
                    // Casting column.columnDef to CustomColumnDef to access columnName
                    const customColumnDef = column.columnDef as CustomColumnDef<Card>;
                    return (
                      <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) =>
                            column.toggleVisibility(!!value)
                          }
                      >
                        {customColumnDef.columnName || column.id}
                      </DropdownMenuCheckboxItem>
                    )
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
          )
        },
        enableSorting: false,
        enableHiding: false,
    },
  ];
};