import { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { useModal } from "./use-modal-store";
import { useTranslation } from 'react-i18next';
import { toast } from "sonner";
import { useContactStoreActions } from "../../../../hooks/contact-store";
import { fetchUploadContacts } from "../../../../api/contacts/create-contact";
import * as XLSX from 'xlsx';
import { Contact } from "../../../../api/contacts/create-contact/schema";
import { ContactTableElement } from "../../../../api/contacts/interface-contact";

export const UploadContacts = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, type } = useModal();
  const isModalOpen = isOpen && type === "uploadContacts";

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Use ref for the input element
  const { updateContactTableForUpload } = useContactStoreActions(); // Function to refresh contacts



  const handleDownloadTemplate = () => {
    const fileUrl = `${process.env.REACT_APP_BACKEND}/contacts/Zangou_Contacts_template_fr.xlsx`; // URL vers le fichier sur le backend
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Zangou_Contacts_template_fr.xlsx'; // Nom du fichier lors du téléchargement
    link.click();
    toast.success(t("contacts.contactsTable.upload.downloadTemplateSuccess"));
  };

  // Vérification des colonnes du fichier avant de permettre le traitement
  const validateFileColumns = (sheet: XLSX.WorkSheet): boolean => {
    const requiredColumns = [
      'First Name', 'Last Name', 'Job Title', 'Company', 'Location',
      'Phone Number', 'Email', 'Groups', 'Interests', 'Address',
      'LinkedIn (link)', 'Meta (link)', 'Other Social Network (link)'
    ];
    const headers: string[]  = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] as string[]; // Get the first row as headers
    return requiredColumns.every((col) => headers.includes(col));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size <= 500 * 1024 && file.name.endsWith('.xlsx')) {
      setSelectedFile(file);
    } else {
      toast.error('Invalid file. Please select a .xlsx file under 500KB.');
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error('Please select a file');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Validation des colonnes
      if (!validateFileColumns(sheet)) {
        toast.error('Invalid file structure. Please use the correct template.');
        setSelectedFile(null); // Reset the selected file
        return;
      }

      const jsonData = XLSX.utils.sheet_to_json<Contact>(sheet);

      // Map Excel columns to createContactDto fields
      const mappedContacts = jsonData.map((row: any) => ({
        first_name: row['First Name'],
        last_name: row['Last Name'],
        job_title: row['Job Title'],
        company_name: row['Company'],
        location: row['Location'],
        phone_number: row['Phone Number'],
        email: row['Email'],
        groups: row['Groups'],
        interests: row['Interests'],
        address: row['Address'],
        linkedin: row['LinkedIn (link)'],
        meta: row['Meta (link)'],
        other_rs: row['Other Social Network (link)'],
      }));

      //console.log('Data being sent to backend:', mappedContacts);
      setIsLoading(true);

      // Function to send contacts
      const sendContacts = async (mappedContacts: any[]) => {
        try {
          const response = await fetchUploadContacts(mappedContacts);
          if (response && response.contacts && response.contacts.length > 0) {
            // Mettre à jour le store avec les nouveaux contacts
            // Remap the response to match ContactTableElement structure
            const remappedContacts: ContactTableElement[] = response.contacts;
            /*
            const remappedContacts: ContactTableElement[] = response.contacts.map((contact: ContactTableElement) => ({
              id: contact.id, // Ensure the contact ID is returned from the backend
              first_name: contact.first_name,
              last_name: contact.last_name,
              job_title: contact.job_title,
              company_name: contact.company_name,
              location: contact.location,
              email: contact.email,
              phone_number: contact.phone_number,
              con_contacts_groups: contact.groups?.split(",").map((groupName: string) => ({
                  con_groups: { id: "", name: groupName.trim() }, // Assuming no group ID from the Excel file, set it as empty
              })) || [],
              con_contacts_interests: contact.interests?.split(",").map((interestName: string) => ({
                  con_interests: { id: "", name: interestName.trim() }, // Assuming no interest ID, set it as empty
              })) || [],
              con_interactions: [], // Assuming no interactions in this context
          }));
          */

          updateContactTableForUpload(remappedContacts);  
          
        } else {
          toast.error('No contacts processed');
        }
        } catch (error) {
          toast.error('Failed to upload contacts');
        }
      }

      
      try {
        const batchSize = 100;
        if (mappedContacts.length > 100) {
          const loops = Math.ceil(mappedContacts.length / batchSize); 
          for (let i = 0; i < loops; i++) {
            const batch = mappedContacts.slice(i * batchSize, (i + 1) * batchSize);
            // Send batch to backend
            await sendContacts(batch);
          }
        } else {
          await sendContacts(mappedContacts);
          //updateContactTableForUpload(mappedContacts); 
        }
        toast.success(`${mappedContacts.length} contacts imported successfully`);
      } catch (error) {
        toast.error('Failed to upload contacts');
      } finally {
        setIsLoading(false);
        onClose(); // Fermer le modal
        setSelectedFile(null); // Reset file after process
      }
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  // This function will trigger the hidden file input when the user clicks the Upload button
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simulate a click on the hidden input element
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#1E1F24] p-0 overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-contacts_primary">
            {t("contacts.contactsTable.upload.title")}
          </DialogTitle>
          <DialogDescription className="text-center text-[#1A3C34] dark:text-[#BCECDF]">
            {t("contacts.contactsTable.upload.description")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="bg-[#F8F5F5] dark:bg-[#1E1F24] px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={handleDownloadTemplate}
              className="text-[#1A3C34] dark:text-[#BCECDF] hover:bg-[#61B6A3] dark:hover:bg-[#437A6E] px-4 py-2"
              variant="ghost"
            >
              {t("contacts.contactsTable.upload.downloadTemplate")}
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              className="hidden"
            />
            <Button
              disabled={isLoading}
              onClick={selectedFile ? handleFileUpload : triggerFileInput} // Change based on file existence
              className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] font-bold px-4 py-2 rounded shadow hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]"
            >
              {isLoading ? t("contacts.contactsTable.upload.loading") : selectedFile ? t("contacts.contactsTable.upload.processButton") : t("contacts.contactsTable.upload.uploadButton")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
