import axiosInstance from "../api";

export interface Activity{
    feature: 'career' | 'techwatch' | 'visibility' | 'tasks' | 'contacts' | 'global' | 'community';
    action: string;
}

export const fetchInterestVisibility = async (activity: Activity): Promise<string> => {
    const response = await axiosInstance.post<string>(`/glb-analytics/create`, activity);
  return response.data;
};
