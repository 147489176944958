import { PlanElement } from "../../../../api/career/interface-career";

export const groupByCategory = (elements: PlanElement[]): { [key: string]: PlanElement[] } => {
    return elements.reduce((groups, element) => {
        const category = element.category || 'UNKNOWN';
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(element);
        return groups;
    }, {} as { [key: string]: PlanElement[] });
};

const findFirstElement = (elements: PlanElement[]): PlanElement | undefined => {
    return elements.find(el => el.previous_elt === null);
};

const sortElements = (elements: PlanElement[]): PlanElement[] => {
    const sortedElements: PlanElement[] = [];
    let currentElement = findFirstElement(elements);
    
    if (currentElement) {
        for (let i = 0; i < elements.length; i++) {
            currentElement && sortedElements.push(currentElement);
            // eslint-disable-next-line no-loop-func
            currentElement = elements.find(el => el.id === currentElement?.next_elt) || undefined;
        }
    }
    /*
    while (currentElement) {
        sortedElements.push(currentElement);
        currentElement = elements.find(el => el.id === currentElement?.next_elt) || null;
    }
    */
    
    return sortedElements;
};

export const sortElementsByCategory = (elements: PlanElement[]): PlanElement[] => { // { [key: string]: PlanElement[] } 
    const groupedByCategory = groupByCategory(elements);

    /* -> first implementation returning a sorted array for each category { [key: string]: PlanElement[] }
    const sortedByCategory: { [key: string]: PlanElement[] } = {};

    Object.keys(groupedByCategory).forEach(category => {
        sortedByCategory[category] = sortElements(groupedByCategory[category]);
    });
    */

    // second implementation returning a sorted array of all elements : PlanElement[]
    let sortedAllElements: PlanElement[] = [];

    // Sort each category and concatenate the sorted elements into one array
    Object.keys(groupedByCategory).forEach(category => {
        const sortedElements = sortElements(groupedByCategory[category]);
        sortedAllElements = [...sortedAllElements, ...sortedElements];
    });

    return sortedAllElements;
};
