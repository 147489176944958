import { useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { getAuthStoreActions } from "../../../hooks/auth-store";
import { fetchLogin } from "../../../api/globals/api-login";
import { fetchGetGlbProfile } from "../../../api/globals/api-profile";
import { useTheme } from "../../../components/theme-provider/theme-provider";
import { toast } from "sonner";
import { useActions, useIsAuthenticated } from "../../../hooks/auth-store";
//import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; 
import { Eye, EyeOff } from 'lucide-react';

import { zodResolver } from '@hookform/resolvers/zod';

import { fetchSignIn } from "../../../api/globals/sign-in";
import { signInSchema, SignInSchema, emailSchema } from "../../../api/globals/sign-in/schema";
import { useForm } from "react-hook-form";

interface InputValues {
    email: string;
    password: string;
}

const EmailSignIn = () => {

    // Check if user is authenticated and redirection
    const isAuthenticated = useIsAuthenticated();

    let navigate = useNavigate();
    const { setIsAuthenticated, setSignUpStep } = getAuthStoreActions();
    const { theme, setTheme } = useTheme();

    //const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState<string | undefined>(undefined);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    
    const { register, handleSubmit, formState: { errors }, watch, setError, clearErrors } = useForm<SignInSchema>({
        resolver: zodResolver(signInSchema),
        defaultValues: {
          email: '',
          password: '',
        }
      });

    //const [error, setError] = useState(false);

    const onSubmit = (data: SignInSchema) => {
        
        setLoading(true);

        const getLogin = async () => {
            try {
                const response = await fetchSignIn(data);
                //console.log("response axios login: ", response);
                if (response) {
                    //console.log("response login data: ", response);
                    if (response?.data?.theme) {
                        const theme = response.data.theme;
                        if (theme === 'light' || theme === 'dark' || theme === 'system') {
                            await setIsAuthenticated(true);
                            setTheme(theme);
                            setLoading(false);
                            setLoginError(undefined);
                            navigate(`/Career`);
                        } else {
                            toast.error("Error logging in. Please try again.");
                            setLoginError("Error logging in. Please try again.");
                            setLoading(false);
                        }
                    } else {
                        if (response?.error === "Request failed with status code 403") {
                            toast.error("There is an issue with email and / or password. Please try again.");
                            setLoginError("There is an issue with email and / or password. Please try again.");
                            setLoading(false);
                        } else {
                            toast.error("Error logging in. Please try again.");
                            setLoginError("Error logging in. Please try again.");
                            setLoading(false);
                        }
                    }
                } else {
                    toast.error("Error logging in. Email and / or password don't match.");
                    setLoginError("Error logging in. Email and / or password don't match.");
                    setLoading(false);
                }
                
            } catch (err) {
                //console.log("error axios login catch: ", err);
                toast.error("Error logging in. Please try again.");
                setLoginError("Error logging in. Email and / or password don't match.");
                setLoading(false);
            }
        };
        getLogin();

    }

    if (isAuthenticated) {
        return <Navigate to="/Techwatch" />;
    }

    return (
        <div className="p-2">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-1 sm:mx-auto sm:w-full sm:max-w-md pb-3 h-full">   
                <div className="space-y-2 sm:mx-auto sm:w-full sm:max-w-md pb-3">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        {...register('email')}
                        placeholder="your email"
                        className="mt-1 block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 rounded sm:text-sm"
                        //onBlur={(e) => handleBlur('email', e.target.value)}
                    />
                    {errors.email && <p className="text-warning_red text-sm">{errors.email.message}</p>}
                </div>
                <div className="space-y-2 sm:mx-auto sm:w-full sm:max-w-md pb-3 relative">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Password</label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"} // Toggle between text and password
                            id="password"
                            {...register('password')}
                            placeholder="type your password"
                            className="mt-1 block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 rounded sm:text-sm pr-10" // Added padding to the right for the icon
                        />
                        <div 
                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" 
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                        </div>
                    </div>
                    {errors.password && <p className="text-warning_red text-sm">{errors.password.message}</p>}
                    <p className="text-sm text-[#107362] dark:text-[#BCECDF] p-0 hover:underline cursor-pointer" onClick={() => setSignUpStep('forgottenPassword')}><i>Forgot password?</i></p>
                </div>
                <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-[#C9E9E0] text-[#107362] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:bg-[#2D544B] dark:text-[#78CDB9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1A3C34]"
                    //onClick={HandleClick}
                    type="submit"
                >
                    Sign In
                </button>
                {loginError && <p className="mt-4 text-[#C23F38]">{loginError}</p>}
            </form>
        </div>
    )
};

export default EmailSignIn;

/*
<div className="space-y-2 sm:mx-auto sm:w-full sm:max-w-md pb-3">
    <label htmlFor="password" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Password</label>
    <div>
        <input
            type={showPassword ? "text" : "password"} // Toggle between text and password
            id="password"
            {...register('password')}
            placeholder="type your password"
            className="mt-1 block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 rounded sm:text-sm"
        />
        <div 
            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" 
            onClick={() => setShowPassword(!showPassword)}
        >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </div>
    </div>
    {errors.password && <p className="text-warning_red text-sm">{errors.password.message}</p>}
    <p className="text-sm text-[#107362] dark:text-[#BCECDF] p-0 hover:underline cursor-pointer" onClick={() => setSignUpStep('forgottenPassword')}><i>Forgot password ?</i></p>
</div>
*/