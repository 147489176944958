import { InputType, ReturnType } from "./types";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { DeleteBoard } from "./schema";
//import { redirect } from "next/navigation";

import axiosInstance from "../../api";
import { Board } from "../../../interfaces/tasks/int-board";

const handler = async (data: InputType): Promise<ReturnType> => {
    
    //console.log("hitting delete card API call");
    try {
        //console.log('data delete card API call :', data);
        const response = await axiosInstance.delete<Board>(`/boards/deleteBoard/${data.id}`);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};
export const deleteBoard = createSafeAction(DeleteBoard, handler);

