import { useState, useEffect } from "react";
import { DeleteMessageModal } from "./delete-message-modal";
import { InviteModal } from "./invite-modal";
import { EditChannelModal } from "./edit-channel-modal";
import { MembersModal } from "./members-modal";
import { LeaveChannelModal } from "./leave-channel";
import { DeleteChannelModal } from "./delete-channel-modal";
import { ViewMembersModal } from "./view-members-modal";
import { CreateChannelModal } from "./create-channel-modal";


export const ModalProvider = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    return (
        <>
            <DeleteMessageModal />
            <CreateChannelModal />
            <EditChannelModal />
            <MembersModal />
            <ViewMembersModal />
            <LeaveChannelModal />
            <InviteModal />
            <DeleteChannelModal />
        </>
    )
}