import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { buttonVariants } from "../../../components/ui/button";
import { HeroCards } from "./HeroCards";
import { useTranslation } from "react-i18next";

export const Hero = () => {
    
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  return (
    <section className="hero-container container grid lg:grid-cols-2 place-items-center gap-10 bg-transparent w-full">
      {/* Shadow effect */}
      <div className="shadow_landing z-0"></div>
      
      <div className="text-center lg:text-start space-y-6 py-20 md:py-32">
      <main className="text-5xl md:text-6xl font-bold">
        {i18n.language === 'fr' ? (
          <h1 className="inline">
            <span className="inline bg-gradient-to-r from-[#FF4D6D] to-[#9B0000] text-transparent bg-clip-text">
                {t("landing.hero.slogan.zangou")}
            </span>{" "}
            <br />
            {t("landing.hero.slogan.title")}{" "}
            <span className="inline bg-gradient-to-r from-[#00FFB2] via-[#007A63] to-[#003F33] text-transparent bg-clip-text">
                {t("landing.hero.slogan.title2")}
            </span>
          </h1>
        ) : (
          // English Version: "Your GenAI Career Companion"
          <h1 className="inline">
              <span className="inline bg-gradient-to-r from-[#FF4D6D] to-[#9B0000] text-transparent bg-clip-text">
                  {t("landing.hero.slogan.zangou")}
              </span>{" "}
              <br />
              {t("landing.hero.slogan.title").split(" ")[0]}{" "} {/* "Your" */}
              <span className="inline bg-gradient-to-r from-[#00FFB2] via-[#007A63] to-[#003F33] text-transparent bg-clip-text">
                  {t("landing.hero.slogan.title2")}
              </span>{" "}
              {t("landing.hero.slogan.title").split(" ").slice(1).join(" ")} {/* "Career Companion" */}
          </h1>
        )}
        </main>

        <p className="text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
          {t("landing.hero.slogan.description")}
        </p>

        <div className="space-y-4 md:space-y-0 md:space-x-4 z-40">
          <Button 
            className="w-full md:w-1/3 z-50"
            variant="landing_full"
            onClick={() => {
              navigate('/signup');
            }}
          >
            {t("landing.hero.cta1")}
          </Button>
          <Button 
            className="w-full md:w-1/3 z-50"
            variant="landing_outline"
            onClick={() => {
              navigate('/signin');
            }}
          >
            {t("landing.hero.cta2")}
          </Button>
        </div>
      </div>

      {/* Hero cards sections */}
      <div className="z-10">
        <HeroCards />
      </div>

      
    </section>
  );
};
