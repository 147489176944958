import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useModal } from "./use-modal-store";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useSelectedChannel } from "../../../../hooks/community-hook";
import { fetchLeaveChannel } from "../../../../api/community/api-members";
import { useTranslation } from 'react-i18next';

export const LeaveChannelModal = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, type, data } = useModal();

  const isModalOpen = isOpen && type === "leaveChannel";
  const { server } = data;

  const selectedChannel = useSelectedChannel();

  const [isLoading, setIsLoading] = useState(false);
  const [leftChannel, setLeaveChannel] = useState();

  const onClick = async () => {
    try {
      setIsLoading(true);
      if (selectedChannel) {
        //console.log("IF LEAVE CHANNEL MODAL");
        const response = await fetchLeaveChannel(selectedChannel.id);
        setLeaveChannel(response);
        onClose();
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] p-0 overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
            {t(`community.modals.leaveChannel.title`)}
          </DialogTitle>
          <DialogDescription className="text-center text-[#5D184F] dark:text-[#F8D3ED]">
            {t(`community.modals.leaveChannel.description`)} <span className="font-semibold text-community_primary">{selectedChannel?.name}</span>?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="bg-[#F8F5F5] dark:bg-[#212121] px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="mt-4 text-[#A5328E] dark:text-[#A6308F] hover:bg-[#F4E2EE] dark:hover:bg-[#34172D] hover:text-[#5D184F] dark:hover:text-[#F98FDF] px-4 py-2"
              variant="ghost"
            >
              {t(`community.modals.leaveChannel.cancel`)}
            </Button>
            <Button
              disabled={isLoading}
              onClick={onClick}
              className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]"
            >
              {t(`community.modals.leaveChannel.confirm`)}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}