import { useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { format } from "date-fns"

import { Opportunity, Priority, priorityValues } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Skeleton } from "../../../../../components/ui/skeleton";
import { Badge } from "../../../../../components/ui/badge";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover"
import { cn } from "../../../../../lib/utils";
import { CalendarIcon, DollarSign, PiggyBank } from "lucide-react";
import { Button } from "../../../../../components/ui/button";
import { Calendar } from "../../../../../components/ui/calendar";

interface OppyPeriodEndDateUnitProps {
    data: Opportunity;
}

export const OppyPeriodEndDateUnit = ({
    data,
}: OppyPeriodEndDateUnitProps) => {

    const [isEditing, setIsEditing] = useState(false);
    const [rawDate, setRawDate] = useState<Date | undefined>(data.end_date ? new Date(data.end_date) : undefined);

    const { t, i18n } = useTranslation();

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setRawDate(data.end_date ? new Date(data.end_date) : undefined);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
            setIsEditing(false);
        }
    });

    const handleRawDateChange = (date: Date) => {
        if (data.id) {
            executeUpdateCard({
                id: data.id,
                end_date: date,
            });
        }
    };

    return (
        <span 
            // Deadline date picker
            className="flex px-0"
        >
            <Popover>
                <PopoverTrigger asChild disabled={!data.id} >
                    <Button
                    variant={"outline"}
                    className={cn(
                        //"w-[280px] justify-start text-left font-normal",
                        "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                        !rawDate && "text-muted-foreground"
                    )}
                    >
                    {rawDate ? format(rawDate, "MMM do, yyyy") : <span className="hidden md:block">
                        {t("contacts.opportunities.keyPoints.period.endDate")}
                        </span>}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                    <Calendar
                    mode="single"
                    selected={rawDate ? rawDate : undefined}
                    onSelect={(value) => value && handleRawDateChange(value)}
                    initialFocus
                    />
                </PopoverContent>
            </Popover>
        </span>
    );
    
};

OppyPeriodEndDateUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };