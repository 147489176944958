import { InputType, ReturnType } from "./types";
//import { db } from "@/lib/db";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { DeleteList } from "./schema";

import axiosInstance from "../../api";

import { List } from "../../../interfaces/tasks/int-list";

const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting delete card API call");
    try {
        //console.log('data delete card API call :', data);
        const response = await axiosInstance.delete<List>(`/lists/deleteList/${data.id}`);

        // Wrap the response data in an ActionState object
        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};


export const deleteList = createSafeAction(DeleteList, handler);

/* 
const handler = async (data: InputType): Promise<ReturnType> => {

    const { userId, orgId } = auth();

    if (!userId || !orgId) {
        return {
            error: "Unauthorized",
        };
    }

    const { id, boardId } = data;

    let list;

    try {
        list = await db.list.delete({
            where: {
                id,
                boardId,
                board: {
                    orgId,
                },
            },
        });
    } catch (error) {
        return {
            error: "Failed to delete"
        }
    }

    revalidatePath(`/board/${boardId}`);
    return {data: list};
};
*/