import * as React from "react"
import debounce from 'lodash.debounce';

import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "../ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover"

import { Group, Interest } from "../../hooks/contact-store";
import { Badge } from "../ui/badge";

interface ComboboxContactsProps {
  //showInput?: boolean;
  nature: string;
  selectedContact: string | undefined;
  filteredValues: Group[] | Interest[];
  addGroup?: (group: Group, contactId: string) => void;
  addInterest?: (interest: Interest, contactId: string) => void;
  incomingPage?: string; // Not in use as of now, to be used in the contact table to display "+" button instead of "Add group"

}

const ComboboxContacts = ({ nature, selectedContact, filteredValues, addGroup, addInterest, incomingPage}: ComboboxContactsProps) => {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")
  let selectedElement: Group | Interest;

  const triggerRef = React.useRef<HTMLButtonElement>(null);  // Specify button element type
  const popoverRef = React.useRef<HTMLDivElement>(null);  // Specify div element type
  const [popoverStyle, setPopoverStyle] = React.useState<React.CSSProperties>({});



  const handleInputChange = debounce((newInputValue) => {
    setInputValue(newInputValue);
  }, 300);  // Debounce by 300 milliseconds to avoid making too many requests and crash react

  const handleSelection = (currentValue: string) => {
    setOpen(false)
    
    //console.log("currentValue: ", currentValue)
    
    if (currentValue === "newElement") {
      if (nature === "group") {
        selectedElement = {"name": inputValue.trim()}  as Group
      } else if (nature === "interest") {
        selectedElement = {"name": inputValue.trim()}  as Interest
      }
    } else {
      selectedElement = filteredValues.filter((element) => element.name.trim() === currentValue.trim())[0]
    }
     
    if (addGroup && selectedElement && selectedContact && nature === "group") {
      addGroup(selectedElement, selectedContact)
    } else if (addInterest && selectedElement && selectedContact && nature === "interest") {
      addInterest(selectedElement, selectedContact)
    }
  }

  React.useLayoutEffect(() => {
    if (open && triggerRef.current && popoverRef.current) {
      const buttonRect = triggerRef.current.getBoundingClientRect();
      const dropdownHeight = popoverRef.current.offsetHeight;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        // If not enough space below and enough space above, show above
        setPopoverStyle({
          bottom: window.innerHeight - buttonRect.top + 5, // slightly offset
          left: buttonRect.left,
          maxHeight: spaceAbove - 10, // slightly less than space above to fit
        });
      } else {
        // Enough space below or no space above, show below
        setPopoverStyle({
          top: buttonRect.bottom + 5, // slightly offset
          left: buttonRect.left,
          maxHeight: spaceBelow - 10, // slightly less than space below to fit
        });
      }
    }
  }, [open]);

  return (
    <div className="flex z-60">
      <Popover open={open} onOpenChange={setOpen} >
        <PopoverTrigger asChild ref={triggerRef}>
          {incomingPage && incomingPage === "Contacts" ? (
            <Badge variant="outline">+</Badge>
          ) : incomingPage && incomingPage === "newContact" ? (
            <button 
              className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] hover:bg-[#61B6A3] dark:hover:bg-[#437A6E] m-1 px-2 py-3 text-xs md:text-sm rounded-full" 
            >
                {nature && nature === "group" ? "Add new group" : "Add new interest"}
            </button>
          ): (
            <button 
              className="bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] hover:bg-[#61B6A3] dark:hover:bg-[#437A6E] m-1 px-2 py-1 text-xs md:text-sm rounded-full" 
            >
                {nature && nature === "group" ? "Add group" : "Add interest"}
            </button>
          )}
        </PopoverTrigger>
        <PopoverContent 
          ref={popoverRef}
          className="w-[200px] p-0 z-60"
          style={{ zIndex: 60, position: 'fixed' }}
        >
          <Command>
            <CommandInput 
              placeholder={incomingPage === "newContact" ? `Type new ${nature}...` : "Search value..." }
              onValueChange={(input) => handleInputChange(input)}  // Capture the input value
            />
            <CommandList className="max-h-[150px] overflow-x-hidden overflow-y-auto" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <CommandEmpty>
                <div
                  key='new'
                  onClick={() => handleSelection("newElement")}
                  className="z-60 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-sm px-2 py-1 text-sm cursor-pointer bg-accent-1 dark:bg-accent-8"  // Ensure it's clickable and has high z-index
                >
                  Create group for "{inputValue}"
                </div>
              </CommandEmpty>
              <CommandGroup>
                {filteredValues.map((elt) => (
                  <CommandItem
                    key={elt.id}
                    value={elt.name}
                    onSelect={(currentValue) => {
                      handleSelection(currentValue)
                    }}
                    className=" flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none z-1000 hover:bg-gray-100 dark:hover:bg-gray-800"  // Ensure it's clickable and has high z-index
                    style={{ pointerEvents: 'auto', zIndex: 1000 }}  // Debugging inline style
                  >
                    {elt.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
    
  );
};

export default ComboboxContacts;
