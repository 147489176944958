import { useDeletePlanModal } from "../../../hooks/tasks-use-card-modal";

import { toast } from "sonner";
import { MoreHorizontal, X } from "lucide-react";
import { useTranslation } from "react-i18next";

import { useAction } from "../../../hooks/use-action";
import { deleteBoard } from "../../../api/tasks/delete-board";
import { Button } from "../../../components/ui/button";
import { 
    Popover,
    PopoverClose,
    PopoverContent,
    PopoverTrigger,
 } from "../../../components/ui/popover";
import { useBoards, useTaskStoreActions } from "../../../hooks/tasks-store";
import { set } from "lodash";


interface BoardOptionsProps {
    id: string;
    name: string;
    setIsEditingOption: (value: boolean) => void;
}

export const BoardOptions = ({ id, name, setIsEditingOption }: BoardOptionsProps) => {
    const { t } = useTranslation();
    
    const boards = useBoards();
    const { deleteBoardFromStore, setSelectedBoard } = useTaskStoreActions();
    const deleteModal = useDeletePlanModal();

    const { execute, isLoading } = useAction(deleteBoard, {
        onSuccess: (data) => {
            deleteBoardFromStore(data.id);
            toast.success("Board deleted successfully");
            //move to another board
            if (boards && boards?.length > 0) {
                const availableBoards = boards.filter((board) => board.id !== data.id);
                setSelectedBoard(availableBoards[0].id);
            }
        },
        onError: (error) => {
            toast.error(error);
        }
    });
    

    const onDelete = () => {
        execute({ id });
        //console.log('delete board with id:', id);
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    className="h-auto w-auto align-middle p-2"
                    variant="transparent"
                >
                    <MoreHorizontal className="h-4 w-4"/>
                </Button>
            </PopoverTrigger>
            <PopoverContent 
                className="px-0 pt-3 pb-3"
                side="bottom"
                align="start"
            >
                <div className="text-sm font-medium text-center text-neutral-600 pb-4">
                    {t("tasks.board.actions")}
                </div>
                <PopoverClose asChild>
                    <Button 
                        className="h-auto w-auto p-2 absolute top-2 right-2 text-neutral-600"
                        variant="ghost"
                    >
                        <X className="h-4 w-4"/>
                    </Button>
                </PopoverClose>
                <Button
                    variant="ghost"
                    onClick={() => { setIsEditingOption(true) }}
                    //onClick={onDelete}
                    //disabled={isLoading}
                    className="rounded-none w-full h-auto p-2 px-5 justify-start font-normal text-sm"
                >
                    {t("tasks.board.edit")}
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => { id && name && deleteModal.onOpen(id, name)}}
                    //onClick={onDelete}
                    //disabled={isLoading}
                    className="rounded-none w-full h-auto p-2 px-5 justify-start font-normal text-sm"
                >
                    {t("tasks.board.delete")}
                </Button>
            </PopoverContent>
        </Popover>
    );
};