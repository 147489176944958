import { Button } from '../../../../components/ui/button';
import { getNodeOptionAction, useCareerStoreNodeOption } from '../../../../hooks/career-store';
import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Node, NodeProps } from 'reactflow';

import { Forward, CircleFadingPlus, Pencil, Save, MoreHorizontal } from 'lucide-react';

import NodeActionMenu from './NodeActionMenu';
import { cn } from '../../../../lib/utils';


// Status dict (from tasks)
import { Status, status_dict } from '../../../../interfaces/tasks/int-card';

type CustomElementNodeData = {
    id?: string;
    plan_id: string;
    parent_elt?: string;
    year: number;
    type: string;
    category: string;
    title: string;
    description?: string;
    label?: string;
    status?: Status;
    deadline_date?: Date;
    start_date?: Date;
    end_date?: Date;
    created_at?: Date;
    updated_at?: Date;
    previous_elt?: string;
    next_elt?: string;
  };

export type CustomElementNodeType = NodeProps<CustomElementNodeData>;

const truncateString = (str: string) => {
  const MAX_LENGTH = 70;

  if (!str) {
    return "";
  }

  if (str.length <= MAX_LENGTH) {
    return str;
  }

  const lastSpaceIndex = str.lastIndexOf(' ', MAX_LENGTH);

  if (lastSpaceIndex !== -1) {
    return str.substring(0, lastSpaceIndex) + ' [...]';
  }

  return str.substring(0, MAX_LENGTH) + ' [...]';
};

function CustomElementNode(props: CustomElementNodeType) { //NodeProps<CustomElementNodeData>
  //to do : reworw the tailwind classnames to adjust the size of the node and the text box
  const { selected, sourcePosition, targetPosition } = props;
  //const { updateNodeOption } = getNodeOptionAction();
  const nodeOption = useCareerStoreNodeOption();

  const [editMode, setEditMode] = useState(false);
  /*
  if(parseInt(props.id) < 6) {
    //console.log("nodes props : ", props);
  }
  */

  const ClassicNode = () => {

    //bg-[#F8F5F5] border-stone-400

    return (
      <div className={cn("w-80 px-4 py-2 bg-white dark:bg-[#1A1213] shadow-sm hover:shadow-md border-2 border-[#F697A1]",
        !selected ? "h-20" : "min-h-30", // "min-h-20",
        props.data.status === "DONE" ? "border-contacts_primary" : "border-[#F697A1]" // border-green-500 "border-stone-400"
      )}>
        <div className="flex">
          <div className="flex flex-col flex-grow">
            <div className="justify-center items-center text-sm font-bold">{selected ? props.data.title : truncateString(props.data.title)}</div> 
            {selected && (
              <div className="flex text-xs text-gray-500 ">{props.data.category && (props.data.category).toLowerCase()} - year {props.data.year} {props.data.status && " - " + status_dict[props.data.status]}</div>
            )}
          </div>
          <div className="flex justify-end align-middle">
              <NodeActionMenu props = { props } />
          </div>
        </div>

        <Handle 
          id="classic-node-handle-target"
          type="target" 
          position={targetPosition ? targetPosition : Position.Left} 
          className={cn("w-1 h-1 !bg-transparent !border-none",
            //!selected ? "hidden" : "",
            //props.data.status === "DONE" ? "!bg-contacts_primary" : "!bg-transparent !border-none" // !bg-career_primary 
          )} 
        />
        <Handle 
          id="classic-node-handle-source"
          type="source" 
          position={sourcePosition ? sourcePosition : Position.Right} 
          className={cn("w-1 h-1 !bg-transparent !border-none",
            //!selected ? "hidden" : "",
            //props.data.status === "DONE" ? "!bg-contacts_primary" : "!bg-transparent !border-none" // !bg-career_primary 
          )} 
        />
      </div>
    )
  }

  

  const BulletNode = () => {
    return (
      <div className="flex flex-col items-center">
        <div className="w-72 justify-center items-center text-center text-xs pb-1">{truncateString(props.data.title)}</div> 
        <div className="rounded-full w-6 h-6 flex justify-center items-center text-sm bg-sky-700 text-white">
          {props.data.year}
          <Handle type="target" position={targetPosition ? targetPosition : Position.Left} className="bg-transparent border-transparent" /> {/*className="bg-transparent" review tailwind classnames*/}
          <Handle type="source" position={sourcePosition ? sourcePosition : Position.Right} className="bg-transparent" />
        </div>
      </div>
      
    )
  }

  
const renderContent = () => {
    // If the nodeOption is 'BulletNode' and the node is selected, show the ClassicNode
    // Otherwise, for 'BulletNode', show the BulletNode (or the selected condition is not met)
    if (nodeOption === 'BulletNode') {
        //return selected ? <ClassicNode /> : <BulletNode />;
        return <BulletNode />;
    }
    // For 'ClassicNode', always show the ClassicNode
    else if (nodeOption === 'ClassicNode' || nodeOption === 'default') {
        //return selected ? <DetailedNode /> : <ClassicNode />;
        return <ClassicNode />;
    }
    // Fallback or default case to show BulletNode
    else {
        return <BulletNode />;
    }
};

  return (
    <div>
      {renderContent()}
    </div>
    
  );
}

export default memo(CustomElementNode);

/*
<div className="flex justify-between align-middle">
          <div className="flex text-xs text-gray-500 ">{(props.data.category).toLowerCase()} - year {props.data.year}</div>
          <div className='flex'>
            <NodeActionMenu props = { props } />
          </div>
        </div>
        
        
        <div className="w-72 justify-center items-center text-sm font-bold">{selected ? props.data.title : truncateString(props.data.title)}</div> 
        {selected && (
          <div>
            <div className="text-xs text-gray-500 ">{props.data.description}</div>
            <div className="flex justify-end gap-1 mt-2">
              <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
              <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
              <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Pencil size={12} color="#1C4171" /></Button>
          </div>
        </div>
        )}


const DetailedNode = () => {
    return (
      <div className="w-80 px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400"> 
        <div className="text-xs text-gray-500 ">{props.data.type} - year {props.data.year}</div>
        <div className="w-72 justify-center items-center text-sm font-bold">{props.data.title}</div> 
        <div className="text-xs text-gray-500 ">{props.data.description}</div>
        <div className="flex justify-end gap-1 mt-2">
          <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
          <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
          <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Pencil size={12} color="#1C4171" /></Button>
        </div>
        <Handle type="target" position={targetPosition ? targetPosition : Position.Left} className="w-16 h-32 !bg-teal-500" />
        <Handle type="source" position={sourcePosition ? sourcePosition : Position.Right} className="w-16 !bg-teal-500" />
      </div>
    )
  }

*/


/*

{ editMode ? (
          <div>
            <div>
              <div className="flex justify-between align-middle">
                <div className='flex'>azertyuio
                  <NodeActionMenu props = { props } />
                </div>
              </div>
              
              
              <div className="w-72 justify-center items-center text-sm font-bold">{selected ? props.data.title : truncateString(props.data.title)}</div> 
              {selected && (
                <div>
                  <div className="flex text-xs text-gray-500 ">{props.data.category && (props.data.category).toLowerCase()} - year {props.data.year}</div>
                  <div className="flex justify-end gap-1 mt-2">
                    <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
                    <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
                    <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(false)}><Save size={12} color="#1C4171" /></Button>
                </div>
              </div>
              )}
            </div>
          </div>
        ) : (
*/