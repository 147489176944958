import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAuthStoreActions } from "../../../hooks/auth-store";

import { fetchGetGlbProfile } from "../../../api/globals/api-profile";
import { useTheme } from "../../../components/theme-provider/theme-provider";
import { fetchForgottenPassword } from "../../../api/globals/reset-password/api-reset-password";
import { CircleCheck } from "lucide-react";

interface InputValues {
    email: string;
}

const ForgottenPassword = () => {
    // This component ask the user for his email to send him a link to reset his password
    let navigate = useNavigate();
    const { setIsAuthenticated } = getAuthStoreActions();
    const { theme, setTheme } = useTheme();

    const [passwordReseted, setPasswordReseted] = useState(false);

    const [inputValues, setInputValues] = useState<InputValues>({
        email: '',
    });
    
    const  checkValue = (value: string) => {
        if (value) {
            if (!value.includes('@')) {
                alert("Attention, il n'y a pas d'@, ceci n'est pas une adresse valide.");
            }
        }
    }

    const [error, setError] = useState(false);

    const HandleClick = () => {

        const getPasswordReset = async () => {
            try {
                const response = await fetchForgottenPassword(inputValues.email);
                if (response.message === 'Password reset email sent') {
                    //console.log("response axios forgotten password: ", response);
                    setPasswordReseted(true);
                }      
            } catch (err) {
                //console.logr);
                setError(true);
            }
        };
        getPasswordReset();

    }

    return ( 
        <div className="p-2">   
            {passwordReseted ? (
                <div>
                    <div className="flex flex-col items-center justify-center">
                        <CircleCheck size={64} />
                        <h3 className="text-xl font-bold text-[#107362] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:bg-[#2D544B] dark:text-[#78CDB9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1A3C34]">Reset link sent!</h3>
                        <p className="text-center text-md  text-[#107362] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:bg-[#2D544B] dark:text-[#78CDB9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1A3C34]">You can now check your emails to find the reset link (it is sometimes in the spams).</p>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="space-y-2 sm:mx-auto sm:w-full sm:max-w-md pb-3">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Email</label>
                        <input
                            type='email'
                            name="email"
                            className="mt-1 rounded block w-full px-3 py-2 border text-[#1A3C34] bg-[#ECF3F1] dark:text-[#BCECDF] dark:bg-[#111917] leading-tight focus:border-[#A0D3C6] dark:focus:border-[#2D544B] focus:outline-none focus:ring-0 sm:text-sm"
                            placeholder='type your email'
                            value={inputValues.email}
                            onChange={(e) => setInputValues({ ...inputValues, email: e.target.value })}
                            onBlur={(e) => checkValue(e.target.value)}
                        />
                    </div>
                    <button
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-[#C9E9E0] text-[#107362] hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:bg-[#2D544B] dark:text-[#78CDB9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1A3C34]"
                        onClick={HandleClick}
                    >
                        Reset password
                    </button>
                    {error && <p className="mt-4 text-[#C23F38]">Error reset password. Please try again.</p>}
                </div>
            )}            
        </div>
     );
}
 
export default ForgottenPassword;