//node modal

import { Dialog, DialogContent } from "../../../../components/ui/dialog";

import { useCareerNodeModal } from "../../../../hooks/career-use-node-modal";
import { useCareerStoreAll } from "../../../../hooks/career-store";
import { useCareerHookActions, usePlanElements } from "../../../../hooks/career-hook";
import { useModal } from "../modals/use-modal-store";

import { Header } from "./header";
import { Description } from "./description";
import { Actions } from "./actions";
import { PlanElement } from "../../../../api/career/interface-career";
import { cn } from "../../../../lib/utils";

export const NodeModal = () => {
    const id = useModal((state) => state.data.planElementId);
    const isOpen = useModal((state) => state.isOpen);
    const onClose = useModal((state) => state.onClose);
    const { type } = useModal();
    const isModalOpen = isOpen && type === "editElement";
    //const { getNodeById } = useCareerStoreAll();
    const { getPlanElement } = useCareerHookActions();

    //const selectedElement: PlanElement | undefined = id ? getPlanElement(id) : undefined;
    //const selectedElement = useCareerHookActions(state => state.getPlanElement(id));
    //const selectedPlanElements: PlanElement[] | undefined = usePlanElements();
    //const selectedElement: PlanElement | undefined = selectedPlanElements?.filter((element: PlanElement) => element.id === id)[0];
    //console.log('selectedPlanElements', selectedPlanElements)
    //console.log('selectedElement :', selectedElement);

    const selectedElement: PlanElement | undefined = id ? getPlanElement(id) : undefined;
    
    //console.log('selectedElement - index:', selectedElement);

    return ( 
        <Dialog
            open={isModalOpen}
            onOpenChange={onClose}
        >
            <DialogContent
                //bg-[#F8F5F5] dark:bg-[#1A1213]
                //className="bg-[#F8F5F5] dark:bg-background border-career_primary border-[1px] p-4 md:p-6 md:max-w-3xl md:w-full lg:max-w-4xl lg:w-full xl:max-w-5xl xl:w-full"
                className={cn("bg-[#F8F5F5] dark:bg-background border-career_primary border-[1px] p-4 md:p-6 md:max-w-3xl md:w-full lg:max-w-4xl lg:w-full xl:max-w-5xl xl:w-full",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            >

                {!selectedElement
                    ? <Header.Skeleton />
                    : <Header data={selectedElement} />
                }
                <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">
                    <div className="col-span-3">
                        <div className="w-full ">
                        {!selectedElement
                            ? <Description.Skeleton />
                            : <Description key={selectedElement.id} data={selectedElement} />
                        }
                        </div>
                    </div>
                    {!selectedElement
                        ? <Actions.Skeleton />
                        : <Actions data={selectedElement} />
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}