import axiosInstance from "../api";
import { CreationPlan, ExistingPlan, FirstPromptAPI, InputSecondPromptAPI, Plan, PlanElement, inputFirstPromptAPI } from "./interface-career";

export const fetchCreatePlan = async (inputPlanData: CreationPlan): Promise<CreationPlan> => {
    // Création du plan en base
    const response = await axiosInstance.post<CreationPlan>(`/car-plan/create`, inputPlanData);
    return response.data;
  };

export const fetchCreatePlanRequest = async (inputPlanData: CreationPlan): Promise<FirstPromptAPI> => {
  // Création du plan en base avec appel GPT
  //console.log("questions", inputPlanData.questions);
  const response = await axiosInstance.post<FirstPromptAPI>(`/openai/create-plan`, inputPlanData);
  return response.data;
};

export const fetchDetailedPlanRequest = async (planId: string): Promise<string> => {
  // Mise à jour du plan en base avec appel GPT
  const response = await axiosInstance.post<string>(`/openai/detailed-plan/${planId}`);
  return response.data;
};

export const fetchEditPlan = async (planId: string, inputPlanData: CreationPlan): Promise<CreationPlan> => {
  // Modification du plan en base
  //console.log("hitting fetchEditPlan")
  //console.log("inputPlanData", inputPlanData)
  const response = await axiosInstance.patch<CreationPlan>(`/car-plan/update/${planId}`, inputPlanData);
  return response.data;
};

export const fetchDeletePlan = async (planId: string): Promise<ExistingPlan> => {
  // Suppresion du plan en base
  const response = await axiosInstance.delete<ExistingPlan>(`/car-plan/delete/${planId}`);
  return response.data;
};

export const fetchCreatePlanSecondRequest = async (path: 'CURRENT' | 'ALTERNATIVE' | 'DREAM' | 'OTHER', modifySteps: FirstPromptAPI): Promise<string> => {
  // Modification du plan en base
  const inputPlanData = await axiosInstance.patch<ExistingPlan>(`/car-plan/updateStepsElements/${path}`, modifySteps);
  // Mise à jour du plan en base avec appel GPT
  const response = await axiosInstance.post<string>(`/openai/detailed-plan/${inputPlanData.data.id}`, inputPlanData);
  return response.data;
};

//First prompt refined
export const fetchCreatePlanPrompt = async (inputPlanData: inputFirstPromptAPI): Promise<FirstPromptAPI> => {
  // Création du plan en base avec appel GPT
  //console.log("questions", inputPlanData.questions);
  const response = await axiosInstance.post<FirstPromptAPI>(`/openai/main-steps`, inputPlanData);
  return response.data;
};

export const fetchCreatePlanPromptNew = async (inputPlanData: CreationPlan): Promise<PlanElement[]> => {
  // Création du plan en base avec appel GPT
  //console.log("questions", inputPlanData.questions);
  const response = await axiosInstance.post<PlanElement[]>(`/openai/main-steps`, inputPlanData);
  return response.data;
};

//with OKR method
export const fetchFinalizePlanSecondRequest = async (path: 'CURRENT' | 'ALTERNATIVE' | 'DREAM' | 'OTHER', modifySteps: InputSecondPromptAPI): Promise<CreationPlan> => {
  // Modification du plan en base
  const inputPlanData = await axiosInstance.patch<CreationPlan>(`/car-plan/updateSecondPrompt/${path}`, modifySteps);
  // Mise à jour du plan en base avec appel GPT
  //console.log("key result year 1", modifySteps.year_1.keyResults);
  const response = await axiosInstance.post<CreationPlan>(`/openai/follow-up-plan/${inputPlanData.data.id}`, modifySteps);
  return response.data;
};