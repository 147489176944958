import { z } from "zod";

export const UpdateCardOrder = z.object({
  items: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
      order_list: z.number(),
      list_id: z.string(),
      status: z.string().optional(),
      created_at: z.date().optional(),
      updated_at: z.date().optional(),
    })
  ),
  boardId: z.string(),
});