import React, { useEffect, useState, useRef } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Edge,
  Node as ReactFlowNode, 
  NodeTypes,
  useReactFlow,
} from 'reactflow';

import { shallow } from 'zustand/shallow';

import 'reactflow/dist/style.css';

import { getNodeOptionAction, useCareerStoreAll, useCareerStoreNodeOption } from '../../../hooks/career-store'

// custom nodes
import customStepNode, { CustomStepNodeType } from './_comp_createNodes/customStepNode';
import customElementNode, { CustomElementNodeType } from './_comp_createNodes/CustomElementNode';
import CustomCategoryNode, { CustomCategoryNodeType } from './_comp_createNodes/CustomCategoryNode_2';
// custom edges
import CustomEdgeCategories from './_comp_createNodes/CustomEdgeCategories_2';



// temporary imports to be moved to the back
//import { DetailedNode, createDetailedEdges, createDetailedNodes, createMainEdges, createMainNodes, transformPrompt1Json, transformPrompt2Json } from './functionBuildNodes';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { Button } from '../../../components/ui/button';
import positionNodes from './_comp_createNodes/positionNodes';

import { useCareerHookActions, usePlanElements, useSelectedPlan } from '../../../hooks/career-hook';
import { fetchPlanElementsData } from '../../../api/career/api-plan-elements';
import { createEdges, createNodes } from './_comp_createNodes/buildNodes';

// temporary data load : 
//const promptMainStepsDataRaw = require('../assets/prompt1.json');
//const promptDetailedStepsDataRaw = require('../assets/prompt2.json');

export type customNodes = CustomStepNodeType | CustomElementNodeType;

export const nodeTypes: NodeTypes = {
  customStepNode: customStepNode,
  customElementNode: customElementNode,
  customCategoryNode: CustomCategoryNode,
  };

export const edgeTypes = {
  customEdgeCategories: CustomEdgeCategories,
};

export const CareerPathFlow = () => {

  // for development purpose (compare the different layouting algorithms)
  const [layoutOptionChoice , setLayoutOptionChoice ] = useState<string>("default")
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const updateNodeOption = getNodeOptionAction();
  const nodeOption = useCareerStoreNodeOption();
  
  //Load career hook (plan ID to retrieve the data from the backend)
  const selectedPlan = useSelectedPlan();
  const { getPlanTitle } = useCareerHookActions();

  //Load the career store
  const { planTitle, nodes, edges, setPlanTitle, setNodes, setEdges, onNodesChange, onEdgesChange, onConnect } = useCareerStoreAll(shallow);
  
  //const { fitView } = useReactFlow();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useRef<any>(null);//useReactFlow(); // Get React Flow instance

  const findNodesPosition = async (initNodes?: ReactFlowNode[], initEdges?: Edge[]) => {

    const nodesToPosition = initNodes ? initNodes : nodes;
    const edgesToPosition = initEdges ? initEdges : edges;

    const results = await positionNodes(nodeOption, nodesToPosition, edgesToPosition);
    const { newNodes, newEdges } = results;

    setNodes(newNodes);
    setEdges(newEdges);

    setTimeout(() => {
        setIsInitialized(true);
      }, 0); // The timeout of 0 ms is often enough to delay the execution until after the current call stack
    
    return results;
  }

  // Initialize the nodes and edges
  // devMode to keep the first draft of the function
  const devMode = false;

  const { setPlanElements } = useCareerHookActions();

  const planElements = usePlanElements();

  useEffect(() => {
    if (selectedPlan) {
      setPlanTitle(getPlanTitle(selectedPlan));
      const getPlanElements = async () => {
        const retrievePlanElements = await fetchPlanElementsData(selectedPlan);
        //console.log("planElements :", retrievePlanElements);
        
        //save plan elements in the store to use data in other components than the flow
        setPlanElements(retrievePlanElements);

        //console.log("planElements :", retrievePlanElements);
      }
      getPlanElements();
    }

  }, [selectedPlan]);


  useEffect(() => {
    
    //console.log("planElements - start creating nodes : ", planElements);

    let initNodes: ReactFlowNode[] = [];
    let initEdges: Edge[] = [];

    const initilizeNodesAndEdges = async () => {
      if (planElements) {      
        initNodes = createNodes(planElements);
        //console.log("initNodes - start : ", initNodes);

        initEdges = createEdges(initNodes);
        //console.log("initEdges - start : ", initEdges);

        await findNodesPosition(initNodes, initEdges).catch(console.error);

        // Focus on the top left after nodes and edges are set
        // Focus on the top left after nodes and edges are set
        if (reactFlowInstance.current && reactFlowWrapper.current) {
          const xValues = nodes.map((node) => node.position.x);
          const yValues = nodes.map((node) => node.position.y);
          const minX = Math.min(...xValues);
          const minY = Math.min(...yValues);
          const maxX = Math.max(...xValues);
          const maxY = Math.max(...yValues);

          //reactFlowInstance.current.setCenter(minX, minY, { zoom: 2, duration: 800 });
          //essai 2
          /*reactFlowInstance.current.zoomTo(1); // Reset zoom to 1
          reactFlowInstance.current.fitBounds({
            x: minX,
            y: minY,
            width: 1, // Small width and height to focus on the exact point
            height: 1,
          });
          */
          // essai 3
          
          // Calculate the zoom level and transformation
          const zoom = 1; // Adjust the zoom level as needed
          const transformX = -minX * zoom;
          const transformY = -minY * zoom;

          reactFlowInstance.current.setTransform({ x: transformX, y: transformY, zoom });
          
         //essai 4
         /*reactFlowInstance.current.fitView({
            bounds: {
              x: minX,
              y: minY,
              width: maxX - minX,
              height: maxY - minY,
            },
            padding: 0.1,
            includeHiddenNodes: true,
          });
          */
         //essai 5
         /*
         // Calculate the view dimensions
         const viewWidth = maxX - minX;
         const viewHeight = maxY - minY;
         
         // Calculate the zoom level to fit the view within the screen dimensions
         const containerWidth = reactFlowWrapper.current.clientWidth;
         const containerHeight = reactFlowWrapper.current.clientHeight;
         const zoom = Math.min(containerWidth / viewWidth, containerHeight / viewHeight);

         reactFlowInstance.current.fitView({
           bounds: {
             x: minX,
             y: minY,
             //width: viewWidth,
             //height: viewHeight,
           },
           zoom,
           padding: 0.1,
           includeHiddenNodes: true,
         });
         */

        }
      }
    };
    
    initilizeNodesAndEdges();

  }, [planElements]);

/*
  useEffect(() => {
    if (isInitialized) {
        findNodesPosition().catch(console.error);
    }
  }, [nodeOption])
*/

  const containerStyle = {
    //paddingTop: '4rem', // Adjust this value based on your menu height
    height: 'calc(100vh - 8rem)', // Subtract the menu height from the viewport height
  };

  return (
      <div
          className="flex flex-col w-full overflow-hidden"
          style={containerStyle}
          id="careerPath page"
      >
        <div className='flex w-full text-center justify-center py-2 text-lg font-bold cursor-pointer text-career_primary dark:text-[#FF95A0]' id='career path title'>
          
           {planTitle}
          
        </div>
        <div 
          //style={{ height: '50vw', width: '100vw' }}
          //style={{ height: '80vh', width: '100%' }}
          className='flex-grow w-full overflow-hidden'
          id="career path flow div"
          //style={{ height: 600}}
          //style={{ height: '30vw' }}
          //add a tailwind className to adjust the height and width of the flow so it could fit the entire screen, it can not be h-96 w-96 because it is a flex child
        >
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitView
                onInit={(instance) => reactFlowInstance.current = instance} // Store the instance in the ref
                //style={{ position: 'relative', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '300%' }}
                //className="bg-teal-50"
                //className="overview w-full h-full"
                >
                <Background />
                <MiniMap style={{ width: 150, height: 100 }} className="hidden md:flex" />
                <Controls showInteractive={false} />
            </ReactFlow>
        </div>
      </div>          
  );
};

/* 
<div className="flex items-center space-x-2 gap-2">
            <Select
              value={layoutOptionChoice} onValueChange={(value) => setLayoutOptionChoice(value)}
            >
              <SelectTrigger className="h-8 text-black">
                <SelectValue placeholder={layoutOptionChoice} />
              </SelectTrigger>
              <SelectContent side="bottom">
                  <SelectItem key="dagre" value="dagre">
                    dagre
                  </SelectItem>
                  <SelectItem key="elkjs" value="elkjs">
                    elkjs
                  </SelectItem>
                  <SelectItem key="default" value="default">
                    default
                  </SelectItem>
              </SelectContent>
            </Select>
            
            <Select
                value={nodeOption} onValueChange={(value) => updateNodeOption(value)}
              >
                <SelectTrigger className="h-8">
                  <SelectValue placeholder={nodeOption} />
                </SelectTrigger>
                <SelectContent side="top">
                    <SelectItem key="BulletNode" value="BulletNode">
                      BulletNode
                    </SelectItem>
                    <SelectItem key="ClassicNode" value="ClassicNode">
                      ClassicNode
                    </SelectItem>
                    <SelectItem key="Detailed" value="Detailed">
                      Detailed
                    </SelectItem>
                    <SelectItem key="default" value="default">
                      default
                    </SelectItem>
                </SelectContent>
            </Select>
          </div>


  <div className='flex items-center space-x-2 gap-6 pr-8'>
            <Button className=" items-end" >TBD function 1</Button>
            <Button className=" items-end" >TBD function 2</Button>
          </div>
*/