import { useCardModal } from "../../../hooks/tasks-use-card-modal";
import { Draggable } from "@hello-pangea/dnd";

import { Card } from "../../../interfaces/tasks/int-card";
import { useTranslation } from "react-i18next";

interface CardItemProps {
    data: Card;
    index: number;
};

export const CardItem = ({
    data,
    index,
}: CardItemProps) => {

    const cardModal = useCardModal();
    const { t } = useTranslation();

    return data.id ? (
        <Draggable draggableId={data.id} index={index}>
            {(provided) => (
            <div 
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                role="button"
                onClick={() => { data.id && cardModal.onOpen(data.id)}}
                className="truncate border-2 border-transparent hover:border-[#9D5B38] py-2 px-3 text-sm bg-[#F7B594] dark:bg-[#542810] text-[#54301E] dark:text-[#FFDECD]"
            >
                {data.title}
            </div>
            )}
        </Draggable>
    ) : (
        <div>
            {t("tasks.card.notFound")}
        </div>
    );
};