import axiosInstance from "../api";

export interface CheckExistingEmailDto {
  email: string;
}

export interface CheckExistingUsernameDto {
  user_name: string;
}

export interface LoginDto {
  email: string;
  password: string;
}

export const fetchCheckExistingEmail = async (checkExistingEmailDto: CheckExistingEmailDto): Promise<{message: string}> => {
  const response = await axiosInstance.post('/auth/check-existing-email', {
    email: checkExistingEmailDto.email,
  });
  return response.data;
};

export const fetchCheckExistingUsername = async (checkExistingUsernameDto: CheckExistingUsernameDto): Promise<{message: string}> => {
  const response = await axiosInstance.post('/auth/check-existing-username', {
    user_name: checkExistingUsernameDto.user_name,
  });
  return response.data;
};

export const fetchValidateEmail = async (token: string): Promise<{message: string}> => {
  const response = await axiosInstance.post('/auth/verifyEmail', {
    token,
  });
  return response.data;
};