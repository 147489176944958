import { createStore, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { Board } from "../interfaces/tasks/int-board";
import { CardWithList, ListWithCards } from "../interfaces/tasks/int-types";
import { List } from "../interfaces/tasks/int-list";
import { Card, status_dict } from "../interfaces/tasks/int-card";
import { fetcListById } from "../api/tasks/api-get-elements";


interface TaskStore {

    boards: Board[] | undefined;
    selectedBoard: string | undefined;
    selectedBoardElement: Board | undefined;
    lists: ListWithCards[] | undefined;
    tasks: Card[] | undefined;

    actions: {
        initBoards: (boards: Board[], boardIdFromUrl?: string) => void;
        getBoardbyId: (BoardId: string) => Board;
        getBoards: () => Board[] | undefined;
        updateOneBoard: (board: Board) => void;
        deleteBoardFromStore: (boardId: string) => void;

        getSelectedBoard: () => string | undefined;
        setSelectedBoard: (selectedBoard: string) => void;

        getSelectedBoardElement: () => Board | undefined;
        setSelectedBoardElement: (selectedBoard: string) => void;

        getLists: () => ListWithCards[] | undefined;
        getListById: (listId: string) => List | undefined;
        getNumberOfLists: () => number;
        getListSize: (listId: string) => number;
        setLists: (lists: ListWithCards[] | undefined) => void;
        updateStoredList: (list: ListWithCards | List) => void;
        updateStoredLists: (list: ListWithCards[] | List[]) => void;
        deleteListWithCards: (listId: string) => void;
        createOrUpdateCard: (card: Card) => void;
        updateCards: (cards: Card[]) => void;
        deleteCardFromList: (cardId: string) => void;
        getCardById: (cardId: string) => CardWithList | undefined ;

        // manage cards overview
        getTasks: () => Card[] | undefined;
        getTasksById: (taskId: string) => Promise<CardWithList | undefined>;
        setTasks: (tasks: Card[] | undefined) => void;
        addTask: (task: Card) => void;
        updateTasks: (tasks: Card[]) => void;
        deleteTask: (taskId: string) => void;

        // Clear the store
		clear: () => void;
        clearTaskStore: () => void;
	}
}

const taskStore = createStore<TaskStore>()(
    devtools(
        (set, get) => ({
            boards: undefined,
            selectedBoard: undefined,
            lists: undefined,
            selectedBoardElement: undefined,
            tasks: undefined,
            
            actions: {
                initBoards: async (boards: Board[], boardIdFromUrl?: string) => {
                    // check if there is a board named Overview and put it first
                    const indexOverview = boards.findIndex((b) => b.title === "Overview");
                    if (indexOverview !== -1) {
                        const overviewBoard = boards[indexOverview];
                        boards.splice(indexOverview, 1);
                        boards.unshift(overviewBoard);
                    }
                    // check if the boards are the same as the ones in the store
                    const currentBoards = get().boards;
                    if (currentBoards && currentBoards.length === boards.length) {
                        const isSame = currentBoards.every((board, index) => board.id === boards[index].id);
                        if (isSame) {
                            return; // No need to update the store
                        }
                    }
                    // if not, update the store with the new boards
                    set({
                        boards,
                    });
                    if (boardIdFromUrl) {
                        
                        let newSelectedBoardElement = boards.filter((b) => b.id === boardIdFromUrl)[0];
                        if (!newSelectedBoardElement) {
                            newSelectedBoardElement = boards[0];
                        }
                        
                        
                        set({
                            selectedBoard: newSelectedBoardElement.id,
                            selectedBoardElement: newSelectedBoardElement,
                        });
                    } else {
                        // Set selectedBoard to the overview board or first board if it's not set
                        if (!get().selectedBoard) {
                            let newSelectedBoard = boards.filter((b) => b.title === "Overview")[0]?.id;
                            if (!newSelectedBoard) {
                                newSelectedBoard = boards[0]?.id;
                            }
                            set({
                                selectedBoard: newSelectedBoard,
                            });
                        }

                        // Set selectedBoardElement to the selected board if it's not set
                        if (!get().selectedBoardElement || get().selectedBoardElement?.id === get().selectedBoard) {
                            let newSelectedBoardElement = boards.filter((b) => b.title === "Overview")[0];
                            if (!newSelectedBoardElement) {
                                newSelectedBoardElement = boards[0];
                            }
                            set({
                                selectedBoardElement: newSelectedBoardElement,
                            });
                        }
                    }
                },
                getBoardbyId: (boardId: string) => {
                    const searchBoard = get().boards?.find(board => board.id === boardId);
                    //return { board: searchBoard || {} as Board };
                    return searchBoard || {} as Board;
                },
                getBoards: () => {
                    return get().boards;
                },
                updateOneBoard: (board: Board) => {
                    //const { boards } = get();
                    const currentBoards = get().boards;
                    if (!currentBoards) {
                        return;
                    }
                    const index = currentBoards?.findIndex((b) => b.id === board.id);
                    if (index !== -1) {
                        const updatedBoards = [...currentBoards];
                        updatedBoards[index] = board;
                        set({
                            boards: updatedBoards,
                        });
                    } else {
                        // If not found, add the new board
                        set({
                            boards: [...currentBoards, board],
                        });
                    }
                },
                deleteBoardFromStore: (boardId: string) => {
                    const boards = get().boards || [];
                    const updatedBoards = boards.filter((board) => board.id !== boardId);
                    set({ boards: updatedBoards });
                },
                getSelectedBoard: () => {
                    return get().selectedBoard;
                },
                getNumberOfLists: () => {
                    return get().lists?.length || 0;
                },
                getListSize: (listId: string) => {
                    const list = get().lists?.find((l) => l.id === listId);
                    return list?.cards?.length || 0;
                },
                getListById: (listId: string) => {
                    const list = get().lists?.find((l) => l.id === listId);
                    return list;
                },
                setSelectedBoard: (selectedBoard: string) => {
                    const board = get().actions.getBoardbyId(selectedBoard);
                    // TO DO : add a fall back to get a task if the board is not found
                    set({
                        selectedBoard,
                        selectedBoardElement: board,
                    });
                },
                getSelectedBoardElement: () => {
                    return get().selectedBoardElement;
                },
                setSelectedBoardElement: (board_id: string) => {
                    const board = get().actions.getBoardbyId(board_id);
                    set({
                        selectedBoardElement: board,
                    });
                },
                getLists: () => {
                    return get().lists;
                },
                setLists: (lists: ListWithCards[] | undefined) => {
                    set({
                        lists,
                    });
                },
                updateStoredList: (list: ListWithCards | List) => {
                    const lists = get().lists || [];
                    //console.log('list to update:', list);
                    const index = lists.findIndex((l) => l.id === list.id);
                    //console.log('index:', index);
                    if (index !== -1) {
                        // If found, update the list at the index with the new list details
                        const updatedLists = lists.map((item, idx) => idx === index ? { ...item, ...list } : item);
                        set({ lists: updatedLists });
                    } else {
                        // If not found, add the new list
                        //console.log('list added in the store :', list);
                        set({ lists: [...lists, list as ListWithCards] });
                    }
                },
                updateStoredLists: (lists: ListWithCards[] | List[]) =>{
                    //console.log('lists to update store :', lists);
                    const currentLists = get().lists || [];
                    const updatedLists = currentLists.map((list) => {
                        const updatedList = lists.find((l) => l.id === list.id);
                        return updatedList ? { ...list, ...updatedList } : list;
                    });
                    
                    // Sort the updatedLists array based on the order property
                    updatedLists.sort((a, b) => a.order - b.order);
                    
                    set({ lists: updatedLists });

                    //console.log("updated lists : ", updatedLists);
                },
                deleteListWithCards: (listId: string) => {
                    const lists = get().lists || [];
                    const updatedLists = lists.filter((list) => list.id !== listId);
                    set({ lists: updatedLists });
                },
                createOrUpdateCard: (card: Card) => {

                    const currentCard = get().lists?.map((list) => list.cards).flat().find((c) => c.id === card.id);
                    
                    const selectedBoardElement = get().selectedBoardElement;

                    // Check if the selected board exists
                    if (!selectedBoardElement) return;

                    set((state) => {
                        let lists = state.lists?.map((list) => {
                          if (list.id === card.list_id) {
                            const existingCardIndex = list.cards ? list.cards?.findIndex((c) => c.id === card.id) : -1;
                            if (existingCardIndex !== -1) {
                              // Update existing card
                              const updatedCards = [...list.cards];
                              updatedCards[existingCardIndex] = { ...updatedCards[existingCardIndex], ...card };
                              return { ...list, cards: updatedCards };
                            } else {
                              // Add new card
                              const updatedCards = list.cards ? [...list.cards, card] : [card];
                              return { ...list, cards: updatedCards };
                            }
                          }
                          return list;
                        });

                        // Update new cards not attached to a list : when list_id is null -> Cards should be attached to the list by status : list name = statusdict[status name]
                        // This function only applied to List display mode 
                        if (selectedBoardElement.title === "Overview") {
                            if (card.list_id === null) {
                                const updatedCards = lists?.map((list) => {
                                    if (list.title === status_dict[card.status as keyof typeof status_dict]) {
                                        const existingCardIndex = list.cards ? list.cards.findIndex((c) => c.id === card.id) : -1;

                                        if (existingCardIndex !== -1) {
                                            // Update existing card in the list
                                            const updatedCards = [...list.cards];
                                            updatedCards[existingCardIndex] = { ...updatedCards[existingCardIndex], ...card };
                                            return { ...list, cards: updatedCards };
                                        } else {
                                            // Add new card if it doesn't exist in the list
                                            const updatedCards = list.cards ? [...list.cards, card] : [card];
                                            return { ...list, cards: updatedCards };
                                        }
                                    }
                                    return list;
                                });
                                lists = updatedCards;
                            }
                        }

                        // Case 1: If the board title is not "Overview" remove card if the board or list has changed
                        if (selectedBoardElement.title !== "Overview") {
                            if (selectedBoardElement.id !== card.parent_elt) {
                                // remove the card from lists
                                lists = lists?.map((list) => {
                                    const updatedCards = list.cards.filter((c) => c.id !== card.id);
                                    return { ...list, cards: updatedCards };
                                });
                            }

                            if (currentCard && currentCard.list_id !== card.list_id) {
                                //find the card from the original list and remove it
                                const originalListCleaned = lists?.map((list) => {
                                    if (list.id === currentCard.list_id) {
                                        const updatedCards = list.cards.filter((c) => c.id !== card.id);
                                        return { ...list, cards: updatedCards };
                                    }
                                    return list;
                                });
                                lists = originalListCleaned;
                            }
                        } 
                        return { ...state, lists };
                      });
                    
                },
                updateCards: (cards: Card[]) => { // to be reviewed
                    const lists = get().lists || [];
                    const updatedLists = lists.map((list) => {
                        const updatedCards = list.cards.map((c) => {
                            const updatedCard = cards.find((card) => card.id === c.id);
                            return updatedCard ? { ...c, ...updatedCard } : c;
                        });
                        updatedCards.sort((a, b) => a.order_list - b.order_list);
                        return { ...list, cards: updatedCards };
                    });
                    set({ lists: updatedLists });
                },
                deleteCardFromList: (cardId: string) => {
                    const lists = get().lists || [];
                    const updatedLists = lists.map((list) => {
                        const updatedCards = list.cards.filter((c) => c.id !== cardId);
                        return { ...list, cards: updatedCards };
                    });
                    set({ lists: updatedLists });
                },
                getCardById: (cardId: string): CardWithList | undefined => {
                    const lists = get().lists || [];
                    for (const list of lists) {
                        const card = list.cards?.find((c) => c.id === cardId);
                        if (card) {
                            const listForCard: List = {
                                id: list.id,
                                title: list.title,
                                description: list.description,
                                order: list.order,
                                board_id: list.board_id,
                                created_at: list.created_at,
                                updated_at: list.updated_at
                            };

                            // Return the card along with its corresponding list
                            // without including the list's cards
                            const cardWithList: CardWithList = {
                                ...card,
                                list: listForCard
                            };

                            return cardWithList;
                        }
                    }
                    return {} as CardWithList;
                },
                getTasks: () => {
                    return get().tasks;
                },
                getTasksById: async (taskId: string): Promise<CardWithList | undefined> => {
                    const tasks = get().tasks || [];
                    const task = tasks.find((task) => task.id === taskId);
                    
                    if (task) {
                        const boards = get().boards || [];
                        let list: List | undefined;
                        list = boards.filter((board) => board.id === task?.parent_elt)[0].lists?.find((list) => list.id === task?.list_id)

                        if (!list) {
                            await fetcListById(task.list_id)
                        }
                        if (list) {
                            const listForCard: List = {
                                id: list.id,
                                title: list.title,
                                description: list.description,
                                order: list.order,
                                board_id: list.board_id,
                                created_at: list.created_at,
                                updated_at: list.updated_at
                            };

                            // Return the card along with its corresponding list
                            const cardWithList: CardWithList = {
                                ...task,
                                list: listForCard
                            };

                            return cardWithList;
                        } else {
                            const cardWithList: CardWithList = {
                                ...task,
                                list: { title: "no list"} as List
                            };
                            return cardWithList
                        }
                    }
                    return undefined;
                },
                setTasks: (tasks: Card[]| undefined) => {
                    set({
                        tasks,
                    });
                },
                addTask: (task: Card) => {
                    const tasks = get().tasks || [];
                    set({
                        tasks: [...tasks, task],
                    });
                },
                updateTasks: (tasks: Card[]) => {
                    // update the tasks in the store with the new tasks (check existence of the task by id)
                    const currentTasks = get().tasks || [];
                    const selectedBoardElement = get().selectedBoardElement;

                    // Check if the selected board exists
                    if (!selectedBoardElement) return;

                    // Case 1: If the board title is "Overview"
                    if (selectedBoardElement.title === "Overview") {
                        // Update tasks as usual, without removing any based on board
                        const updatedTasks = currentTasks.map(task => {
                            const updatedTask = tasks.find(t => t.id === task.id);
                            return updatedTask ? { ...task, ...updatedTask } : task;
                        });

                        // Add new tasks from the passed array that aren't in currentTasks
                        const newTasks = tasks.filter(task => !currentTasks.some(t => t.id === task.id));
                        set({ tasks: [...updatedTasks, ...newTasks] });
                    } 
                    // Case 2: If the board title is NOT "Overview"
                    else {
                        // Update tasks for the current board and remove tasks that don't belong to this board
                        const updatedTasks = currentTasks
                            .map(task => {
                                const updatedTask = tasks.find(t => t.id === task.id);
                                return updatedTask ? { ...task, ...updatedTask } : task;
                            });

                        const filteredTasks = updatedTasks.filter(task => task.parent_elt === selectedBoardElement.id) // Keep only tasks belonging to the current board
                        /*
                        // Add new tasks from the passed array that belong to the current board
                        const newTasks = tasks
                            .filter(task => task.parent_elt === selectedBoardElement.id)
                            .filter(task => !currentTasks.some(t => t.id === task.id));
                        set({ tasks: [...updatedTasks, ...newTasks] });
                        */
                        set({ tasks: [...filteredTasks] });
                    }

                    /*
                    const updatedTasks = currentTasks.map((task) => {
                        const updatedTask = tasks.find((t) => t.id === task.id);
                        return updatedTask ? { ...task, ...updatedTask } : task;
                    });
                    //set({ tasks: updatedTasks });
                    set({ tasks: [...updatedTasks] });
                    */
                },
                deleteTask: (taskId: string) => {
                    const tasks = get().tasks || [];
                    const updatedTasks = tasks.filter((task) => task.id !== taskId);
                    set({ tasks: updatedTasks });
                },
                clear: () => {
                    set({
                        boards: undefined,
                        selectedBoard: undefined,
                    });
                },
                clearTaskStore: () => {
                    set({
                        boards: undefined,
                        selectedBoard: undefined,
                        lists: undefined,
                        selectedBoardElement: undefined,
                        tasks: undefined,
                    });
                },
            }
        }),
        {
            name: 'tasks-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production', // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
)

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof taskStore, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof taskStore>) => state.actions;
const boardsSelector = (state: ExtractState<typeof taskStore>) => state.boards;
const selectedBoardSelector = (state: ExtractState<typeof taskStore>) => state.selectedBoard;
const listsSelector = (state: ExtractState<typeof taskStore>) => state.lists;
const selectedBoardElementSelector = (state: ExtractState<typeof taskStore>) => state.selectedBoardElement;
const tasksSelector = (state: ExtractState<typeof taskStore>) => state.tasks;

// getters
export const getContactStoreActions = () => actionsSelector(taskStore.getState());
export const getBoards = () => boardsSelector(taskStore.getState());
export const getSelectedBoard= () => selectedBoardSelector(taskStore.getState());
export const getLists = () => listsSelector(taskStore.getState());
export const getSelectedBoardElement = () => selectedBoardElementSelector(taskStore.getState());
export const getTasks = () => tasksSelector(taskStore.getState());

function useTaskStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(taskStore, selector, equalityFn);
}

// Hooks
export const useTaskStoreActions = () => useTaskStore(actionsSelector);
export const useBoards = () => useTaskStore(boardsSelector);
export const useSelectedBoard = () => useTaskStore(selectedBoardSelector);
export const useLists = () => useTaskStore(listsSelector);
export const useSelectedBoardElement = () => useTaskStore(selectedBoardElementSelector);
export const useTasks = () => useTaskStore(tasksSelector);
