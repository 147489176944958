import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';

import { useSelectedContact } from "../../../../../hooks/contact-hook";
import { Typography } from '@mui/material';
import { Tv2, Phone, Send, Mail, Coffee, Utensils, BriefcaseBusiness, GlassWater, Aperture, Plus, Pickaxe, LayoutDashboard, Pencil, Edit, Trash, CalendarDays, Map } from "lucide-react";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import { useEffect, useState } from 'react';
import { ConEvent, ConAction } from '../../../../../interfaces/contacts/int-contacts';
import { fetchEventsFromContact } from '../../../../../api/contacts/api-contact-event';
import { Button } from '../../../../../components/ui/button';
import React from 'react';
import ContactTimelineActions from './ContactTimelineActionsOptions';
import { ActionTooltip } from '../../../../../components/action-tooltip';

import { enventTypeComponents } from './ContactTimeline';

interface ContactTimelineElementProps {
    key: string;
    contactEvent: ConEvent;
    index: number;
    handleEventDeletion: (eventId: string) => void;
    setIsEditing: (eventId: string) => void;
}

const ContactTimelineElement = ({key, contactEvent, index, setIsEditing, handleEventDeletion}: ContactTimelineElementProps) => {
    //console.log('contactEvent', contactEvent);
    return ( 
        <div className={`group flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} items-stretch align-top`}>
            <div 
                id="part-1" 
                className={`flex-1 gap-2 px-2 py-2.5 text-sm ${index % 2 === 0 ? 'justify-end' : 'justify-start'}`}
            >
                <div className="flex flex-col">
                    {contactEvent.date &&
                        <div className={`inline-flex gap-2 my-2 items-center ${index % 2 === 0 ? 'justify-end text-right' : 'justify-start text-left'}`}>
                                <CalendarDays className="w-4 h-4" />
                                {contactEvent.date && (new Date(contactEvent.date).toLocaleDateString())}
                        </div>
                    }
                    {contactEvent.location &&
                        <div className={`inline-flex gap-2 items-center ${index % 2 === 0 ? 'justify-end text-right' : 'justify-start text-left'}`}>
                            <Map className="w-4 h-4" /> {contactEvent.location && contactEvent.location}
                        </div>
                    }
                </div>
                
            </div>
            <div id="part-2" className="flex flex-col w-12 items-center justify-center p-0 m-0 relative">
                <div className="w-10 h-10 bg-contacts_primary rounded-full flex items-center justify-center">
                    {contactEvent && (() => {
                        const ContactTypeEventIcon = contactEvent.type ? enventTypeComponents[contactEvent.type] : enventTypeComponents['OTHER'];
                        return <ContactTypeEventIcon className="flex w-5 h-5" />
                    })()}
                </div>
                <div className="w-0.5 bg-gray-400 flex flex-grow"></div>
                <div className={`hidden group-hover:flex items-center gap-x-2 p-1 bg-white dark:bg-zinc-800 border rounded-sm ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
                        <ActionTooltip label="Edit">
                            <Edit
                                onClick={() => setIsEditing(contactEvent.id ?? '')}
                                className="cursor-pointer w-4 h-4 text-zinc-500 hover:text-zinc-600 dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                        <ActionTooltip label="Delete">
                            <Trash
                                onClick={() => handleEventDeletion(contactEvent.id ?? '')}
                                className="cursor-pointer ml-auto w-4 h-4 text-zinc-500 hover:text-zinc-600 dark:hover:text-zinc-300 transition"
                            />
                        </ActionTooltip>
                    </div>
                <div className="w-0.5 bg-gray-400 flex flex-grow"></div>
            </div>
            <div 
                id="part-3" 
                className={`flex-1 px-2 py-2 ${index % 2 === 0 ? 'text-left' : 'text-right'}`}
            >
                <div className="text-base">
                    {contactEvent.title}  
                </div>
                <div className="text-sm my-2">
                    {contactEvent.description}
                </div>
                {(contactEvent.actions && contactEvent.actions.length > 0) &&
                <div className="py-2 my-2">
                    <div className="text-base inline-flex gap-2 items-center"><DirectionsRunIcon className="w-4 h-4" /> Actions</div>
                    <div className="w-full">
                        <ul className="p-0 m-0 flex flex-col">
                            {contactEvent.actions && contactEvent.actions.map((action: ConAction) => (
                                <li 
                                    key={action.id} 
                                    className={`flex m-0 p-0 text-sm items-center ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
                                >
                                    <div className="m-0 p-0 flex">{action.title}</div>
                                    <div className="m-0 p-0 w-6 h-6 items-center flex"><ContactTimelineActions action={action} /></div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                }
            </div>
        </div>
     );
}
 
export default ContactTimelineElement;