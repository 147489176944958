import { Board } from "../../../interfaces/tasks/int-board";
import { useState } from "react";

import { updateBoardData } from "../../../api/tasks/update-board-data";
import { useAction } from "../../../hooks/use-action";

import { SquareKanban, SquareMenu } from 'lucide-react';
import { Separator } from "../../../components/ui/separator";
import { ActionTooltip } from "../../../components/action-tooltip";
import { useTaskStoreActions } from "../../../hooks/tasks-store";
import { display } from "@mui/system";

interface BoardNavbarProps {
    data: Board;
}

const BoardDisplayMode = ({
    data
}: BoardNavbarProps) => {
    const { setSelectedBoardElement, updateOneBoard } = useTaskStoreActions();
    //const selectedBoard = useSelectedBoard() || "";
    //const selectedBoardElement = getBoardbyId(selectedBoard); //useSelectedBoardElement();

    const [ boardDisplayMode, setBoardDisplayMode ] = useState<string>(data.display_mode)
    
    const { execute, fieldErrors } = useAction(updateBoardData, {
        onSuccess: (responseData) => {
            updateOneBoard({
                ...data,
                display_mode: responseData.display_mode,
            });
            setSelectedBoardElement(responseData.id)
            setBoardDisplayMode(responseData.display_mode)

        },
        onError: (error) => {
            //setLocalFieldErrors(error.fieldErrors);
        },
    });

    //const onSubmit = (formData: FormData) => {
        const changeDisplayMode = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            event.stopPropagation(); // Prevent default form submission
            execute({
                display_mode: data.display_mode === "TABLE" ? "LIST" : "TABLE",
                id: data.id || "", // change with a condition to check if data.id is undefined
            });
        };

    

    return ( 
        <div className="flex flex-col">
            {data.display_mode === "TABLE" ? (
                <div className="flex flex-row">
                    <ActionTooltip label="Table">
                        <SquareMenu 
                            className="h-6 w-6" 
                        /> 
                    </ActionTooltip>
                    <Separator className="border-white" orientation="vertical" />
                    <ActionTooltip label="display as a Kanban">
                        <SquareKanban
                            className="h-6 w-6 cursor-pointer"
                            color="white"
                            onClick={(e) => changeDisplayMode(e)}
                        />
                    </ActionTooltip>
                </div>
            ) : (
                <div className="flex flex-row">
                    <ActionTooltip label="display as a table">
                        <SquareMenu 
                            className="h-6 w-6 cursor-pointer" 
                            onClick={(e) => changeDisplayMode(e)}
                            color="white"
                        /> 
                    </ActionTooltip>
                    <Separator 
                        className="border-white" 
                        orientation="vertical"
                        color="white"
                    />
                    <ActionTooltip label="Kanban">
                        <SquareKanban 
                            className="h-6 w-6"
                        />
                    </ActionTooltip>
                </div>
            )}
            
        </div>
     );
}
 
export default BoardDisplayMode;