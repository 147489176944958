import { cn } from "../../../../lib/utils";
import { SidebarGeneral } from "../../../../components/Sidebar-general";
import MainServers from "../main/main-server";

const ComServer = () => {
    return (
        <div className="flex flex-grow h-full w-full">
            <div className="hidden md:flex flex-col w-2/12 h-full px-1 py-1 dark:bg-[#1E1F22]">
                <SidebarGeneral />
            </div>
            <div 
                //className="flex-grow flex flex-col p-2 w-10/12 overflow-auto"
                className={cn("flex-grow flex flex-col p-2 w-10/12",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            >
                <MainServers />
            </div>
        </div>
    );
}
 
export default ComServer;