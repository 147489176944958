import { createStore, useStore } from "zustand";
import { FeedExtended } from "../interfaces/techwatch/int-techwatch";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";

interface TechwatchStore {

    feeds: FeedExtended[] | undefined;
    selectedTab: string;
    page: number;
    selectedCategory: string;

    actions: {
        initFeeds: (feeds: FeedExtended[]) => void;
        getFeedById: (FeedId: string) => { feed: FeedExtended };
        updateManyFeeds: (feeds: FeedExtended[]) => void;
        updateOneFeed: (feed: FeedExtended) => void;

        getSelectedTab: () => string;
        setSelectedTab: (tab: string) => void;

        getPage: () => number;
        setPage: (page: number) => void;

        setSelectedCategory: (category: string) => void;

        resetFeeds: () => void;

		init: () => void;
		clear: () => void;
        clearTechwatchStore: () => void;
	}
}

const techwatchStore = createStore<TechwatchStore>()(
    devtools(
        (set, get) => ({
            feeds: undefined,
            selectedTab: 'Personalized',
            page: 1,
            selectedCategory: '',
            
            actions: {
                initFeeds: (feeds: FeedExtended[]) => {
                    set({
                        feeds,
                    });
                },
                getFeedById: (feedId: string) => {
                    const searchFeed = get().feeds?.find(feed => feed.id === feedId);
                    // Ensure we always return an object with a 'feed' property
                    return { feed: searchFeed || {} as FeedExtended };
                },
                updateManyFeeds: (fetchedFeeds: FeedExtended[]) => {
                    const { feeds } = get();
                    const existingFeedIds = new Set(feeds?.map(feed => feed.id));
                    const newFeeds = fetchedFeeds.filter(feed => !existingFeedIds.has(feed.id));
                    if (newFeeds.length > 0) {
                        if (feeds) {
                            set({
                                feeds: [...feeds, ...newFeeds],
                            });
                        } else {
                            set({
                                feeds: newFeeds,
                            });
                        }
                    }
                    //console.log("page", get().page);
                    //console.log("fetchedFeeds: ", fetchedFeeds?.length, fetchedFeeds);
                    //console.log("feeds: ", feeds?.length, feeds);
                    //console.log("new feeds: ", newFeeds.length, newFeeds);
                },
                updateOneFeed: (feed: FeedExtended) => {
                    const { feeds } = get();
                    if (!feeds) {
                        return; // No feeds to update
                    }
                    const index = feeds?.findIndex((f) => f.id === feed.id);
                    if (index !== -1) {
                        feeds[index] = feed;
                        set({
                            feeds,
                        });
                    }
                },
                getSelectedTab: () => {
                    return get().selectedTab;
                },
                setSelectedTab: (tab: string) => {
                    const currentTab = get().selectedTab;
                    const currentPage = get().page;
                    if (!(currentTab === tab && currentPage===1)) {
                        set({
                            selectedTab: tab,
                            feeds: [],
                            page: 1,
                        });        
                    }
                    
                },
                getPage: () => {
                    return get().page;
                },
                setPage: (page: number) => {
                    //console.log("setting page: ", page);
                    set({
                        page,
                    });
                },
                setSelectedCategory: (category: string) => {
                    set({
                        selectedCategory: category,
                        feeds: [],
                        page: 1,
                    });
                },
                resetFeeds: () => {
                    set({
                        feeds: [],
                        page: 1,
                    });
                },
                init: () => {
                    set({
                        selectedTab: 'Personalized',
                        feeds: [],
                        page: 1,
                    });
                },
                clear: () => {
                    set({
                        feeds: undefined,
                        selectedTab: undefined,
                        page: undefined,
                    });
                },
                clearTechwatchStore: () => {
                    set({
                        feeds: undefined,
                        selectedTab: 'Personalized',
                        page: 1,
                        selectedCategory: '',
                    });
                },
            }
        }),
        {
            name: 'techwatch-store',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
)

/**
 * Required for zustand stores, as the lib doesn't expose this type
 */
export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof techwatchStore, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof techwatchStore>) => state.actions;
const feedsSelector = (state: ExtractState<typeof techwatchStore>) => state.feeds;
const selectedTabSelector = (state: ExtractState<typeof techwatchStore>) => state.selectedTab;
const pageSelector = (state: ExtractState<typeof techwatchStore>) => state.page;
const selectedCategorySelector = (state: ExtractState<typeof techwatchStore>) => state.selectedCategory;

// getters
export const getContactStoreActions = () => actionsSelector(techwatchStore.getState());
export const getContactTable = () => feedsSelector(techwatchStore.getState());
export const getGroups = () => selectedTabSelector(techwatchStore.getState());
export const getInterests = () => pageSelector(techwatchStore.getState());
export const getSelectedCategory = () => selectedCategorySelector(techwatchStore.getState());

function useTechwatchStore<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(techwatchStore, selector, equalityFn);
}

// Hooks
export const useTechwatchStoreActions = () => useTechwatchStore(actionsSelector);
export const useFeeds = () => useTechwatchStore(feedsSelector);
export const useSelectedTab = () => useTechwatchStore(selectedTabSelector);
export const usePage = () => useTechwatchStore(pageSelector);
export const useSelectedCategory = () => useTechwatchStore(selectedCategorySelector);