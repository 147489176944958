import { 
  Check,
  Gavel,
  Loader2,
  MoreVertical, 
  Shield, 
  ShieldAlert, 
  ShieldCheck,
  ShieldQuestion
} from "lucide-react";
import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useModal } from "./use-modal-store";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { Avatar } from "../../../../components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuTrigger,
  DropdownMenuSubTrigger,
} from "../../../../components/ui/dropdown-menu";
import { Channel } from "../../../../api/community/interface-community";
import { useSelectedChannel } from "../../../../hooks/community-hook";
import { GetMembers, Member, UpdateMember, fetchDeleteMember, fetchGetMembersFromChannel, fetchUpdateRoleMember } from "../../../../api/community/api-members";
import { UserAvatar } from "../user-avatar";
import { useTranslation } from 'react-i18next';

import icon_fr from "../../../../img/channels/france.png"

const roleIconMap = {
  "GUEST": null,
  "MODERATOR": <ShieldCheck className="h-4 w-4 ml-2 text-community_complementary" />,
  "ADMIN": <ShieldAlert className="h-4 w-4 text-community_primary" />
}

export const MembersModal = () => {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose, type, data } = useModal();
  const selectedChannel = useSelectedChannel();

  const isModalOpen = isOpen && type === "members";
  const { channel } = data as { channel : Channel };
  const [loadingId, setLoadingId] = useState("");

  const [membersFromChannel, setGetMembers] = useState<GetMembers[]>([]);

  const [updatedMember, setUpdateMember] = useState<UpdateMember>();

  const [removedMember, setRemoveMember] = useState<Member>();

  useEffect(() => {
    const fetchMembers = async () => {
      if (selectedChannel?.id) {
        try {
          const response = await fetchGetMembersFromChannel(selectedChannel.id);
          setGetMembers(response);
        } catch (error) {
          //console.log("error", error);
        }
      }
    };
  
    fetchMembers();
  }, [selectedChannel?.id, updatedMember, removedMember]);
  

  const onKick = async (memberName: string, memberId: string) => {
    try {

      if (selectedChannel?.id){
        const response = await fetchDeleteMember(selectedChannel.id, {name: memberName})
        setLoadingId(memberId);
        setRemoveMember(response);
        const url = " ";
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoadingId("");
    }
  }


  const onRoleChange = async (memberName: string, memberId: string, role: "ADMIN" | "MODERATOR" | "GUEST") => {
    try {
      setLoadingId(memberId);
      if (selectedChannel?.id) {
        try {
          const response = await fetchUpdateRoleMember(selectedChannel.id, {name: memberName, role: role});
          setUpdateMember(response);
        } catch (error) {
          //console.log("error", error);
        }
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoadingId("");
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
            {t(`community.modals.manageMembers.title`)}
          </DialogTitle>
          <DialogDescription 
            className="text-center text-[#5D184F] dark:text-[#F8D3ED]"
          >
            {membersFromChannel?.length} {t(`community.modals.manageMembers.members`)}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
            {membersFromChannel?.map((member) => (
            <div key={member.id} className="flex items-center gap-x-2 mb-6">
                <UserAvatar src={`${process.env.REACT_APP_BACKEND}/user/${member.image_url}`}/>
                <div className="flex flex-col gap-y-1">
                <div className="text-sm font-semibold flex items-center gap-x-1">
                    {member.user_name}
                    {roleIconMap[member.role]}
                </div>
                </div>
                <div className="ml-auto">
                    <DropdownMenu>
                    <DropdownMenuTrigger>
                        <MoreVertical className="h-4 w-4 text-zinc-500" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side="left">
                        <DropdownMenuSub>
                        <DropdownMenuSubTrigger
                            className="flex items-center"
                        >
                            <ShieldQuestion
                            className="w-4 h-4 mr-2"
                            />
                            <span>Role</span>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                            <DropdownMenuItem
                                onClick={() => onRoleChange(member.user_name, member.id, "GUEST")}
                            >
                                <Shield className="h-4 w-4 mr-2" />
                                {t(`community.modals.manageMembers.role.guest`)}
                                {member.role === "GUEST" && (
                                <Check
                                    className="h-4 w-4 ml-auto"
                                />
                                )}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={() => onRoleChange(member.user_name, member.id, "MODERATOR")}
                            >
                                <ShieldCheck className="h-4 w-4 mr-2" />
                                {t(`community.modals.manageMembers.role.moderator`)}
                                {member.role === "MODERATOR" && (
                                <Check
                                    className="h-4 w-4 ml-auto"
                                />
                                )}
                            </DropdownMenuItem>
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                        </DropdownMenuSub>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                        onClick={() => onKick(member.user_name, member.id)}
                        >
                        <Gavel className="h-4 w-4 mr-2" />
                        {t(`community.modals.manageMembers.kick`)}
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                {/*loadingId === member.id && (
                <Loader2
                    className="animate-spin text-zinc-500 ml-auto w-4 h-4"
                />
                )*/}
            </div>
            ))}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}