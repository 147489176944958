import axiosInstance from "../../api";
export interface NewsletterDto {
    mail: string;
  }

export const fetchAddingMail = async (email: NewsletterDto): Promise<{message: string}> => {
  const response = await axiosInstance.post('/glb-newsletter/create', {
    mail: email.mail,
  });
  return response.data;
};