import React from 'react';
import { NavLink } from 'react-router-dom';
import img_career from '../../../../img/landing/career.svg';
import img_2 from '../../../../img/career_plans/Business mission-bro.svg';
import img_3 from '../../../../img/career_plans/Moving forward-bro.svg';
import img_4 from '../../../../img/career_plans/Design thinking-bro.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

const HelpCareerModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Career";

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-3xl text-center font-bold">
            Career
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.landingCareer.tagline")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
            <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-2">
                    <div className="container mx-auto px-4">
                        <div className="mb-16">
                            <div className="flex flex-col md:flex-row items-center gap-2">
                                <img src={img_career} alt="Career Plan" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title1")}</h2>
                                    <p className="mt-2 text-sm text-[#6E0021] dark:text-[#FBD5D7]">
                                        {t("landing.landingCareer.desc1")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16 bg-[#F9EFF0] dark:bg-[#1A1213] p-2 rounded-lg">
                            <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                                <img src={img_2} alt="Experience" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title2")}</h2>
                                    <p className="mt-2 text-sm text-[#6E0021] dark:text-[#FBD5D7]">
                                        {t("landing.landingCareer.desc2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16">
                            <div className="flex flex-col md:flex-row items-center gap-2">
                                <img src={img_3} alt="Tasks" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title3")}</h2>
                                    <p className="mt-2 text-sm text-[#6E0021] dark:text-[#FBD5D7]">
                                        {t("landing.landingCareer.desc3")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16 bg-[#F9EFF0] dark:bg-[#1A1213] p-2 rounded-lg">
                            <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                                <img src={img_4} alt="Customization" className="w-full md:w-1/2 h-auto rounded-lg" />
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title4")}</h2>
                                    <p className="mt-2 text-sm text-[#6E0021] dark:text-[#FBD5D7]">
                                        {t("landing.landingCareer.desc4")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
        
    );
}

export default HelpCareerModal;
