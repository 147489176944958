import { Card, Duration, duration_dict } from "../../../../interfaces/tasks/int-card";
import { use } from "i18next";

import { toast } from "sonner";

import { useAction } from "../../../../hooks/use-action";
import { useLists, useSelectedBoard, useTaskStoreActions } from "../../../../hooks/tasks-store";

import { updateCard } from "../../../../api/tasks/update-card";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "../../../../components/ui/select";

import { useTranslation } from "react-i18next";
import { CardWithList } from "../../../../interfaces/tasks/int-types";
import { useEffect, useState } from "react";

interface ParentEltUnitProps {
    data: Card; //CardWithList;
    setSearchBoardTitle: (title: string) => void;
}

const ParentEltUnit = ({data, setSearchBoardTitle}: ParentEltUnitProps) => {

    const { t } = useTranslation();

    const { getTasksById, getBoardbyId, setSelectedBoard } = useTaskStoreActions();

    const [ boardTitle, setBoardTitle ] = useState<string>("");

    const getBoardTitle = async (id: string): Promise<string> => {
        try {
            // Try to get the board title from the store (assuming this is synchronous)
            const board = getBoardbyId(id);
            return board.title;
        } catch (error) {
            // If it fails, try to fetch the task by its ID
            try {
                const boardFromCard = await getTasksById(id);
                return boardFromCard ? boardFromCard.title : "";
            } catch (error) {
                console.error("Failed to retrieve board or task title:", error);
                return ""; // Return an empty string if both methods fail
            }
        }
    };
    
    const boardTitleFromCard = getBoardTitle(data.parent_elt as string);

    useEffect(() => {
        const fetchTitle = async () => {
            const title = await getBoardTitle(data.parent_elt as string);
            setBoardTitle(title);
            setSearchBoardTitle(title);
        };
        
        fetchTitle();
    }, [data.parent_elt]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedBoard(data.parent_elt as string);
    };

    return ( 
        <div
            onClick={handleClick}
        >
            {boardTitle ? boardTitle : "No parent element"}
        </div>
     );
}
 
export default ParentEltUnit;