import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { useModal } from "./use-modal-store";
import { useTranslation } from 'react-i18next';
import { useWebSocket } from "../../../../hooks/socket-context";

export const DeleteMessageModal = () => {
  const { t } = useTranslation();

  const { isOpen, onClose, type, data } = useModal();
  const { socket } = useWebSocket();

  const isModalOpen = isOpen && type === "deleteMessage";

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    //console.log("DELETE MODAL");
    try {
      setIsLoading(true);
      if (socket) {
        socket.emit('deleteMessage', {
            messageId: data.message?.id,
            channelId: data.message?.channel_id,
            tempMessageId: Date.now().toString(),
        }
    )};
      onClose();
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] p-0 overflow-hidden">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
            {t(`community.modals.deleteMessage.title`)}
          </DialogTitle>
          <DialogDescription className="text-center text-[#5D184F] dark:text-[#F8D3ED]">
            {t(`community.modals.deleteMessage.description`)}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="bg-[#F8F5F5] dark:bg-[#212121] px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="mt-4 text-[#A5328E] dark:text-[#A6308F] hover:bg-[#F4E2EE] dark:hover:bg-[#34172D] hover:text-[#5D184F] dark:hover:text-[#F98FDF] px-4 py-2"
              variant="ghost"
            >
            {t(`community.modals.deleteMessage.cancel`)}
            </Button>
            <Button
              disabled={isLoading}
              onClick={onClick}
              className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]"
            >
            {t(`community.modals.deleteMessage.confirm`)}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}