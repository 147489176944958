import axiosInstance from "../../api";

export const fetchForgottenPassword = async (email: string): Promise<{message: string}> => {
    const response = await axiosInstance.post('/auth/forgotten-password', {
      email,
    });
    return response.data;
  };

export const fetchRPCheckToken = async (token: string): Promise<{message: string}> => {
  const response = await axiosInstance.post('/auth/reset-password-check', {
    token,
  });
  //console.log('response: ', response);
  //console.log('response.data: ', response.data);
  return response.data;
};

