import { useNavigate } from 'react-router-dom';

import { AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";

import {
    Activity,
    CreditCard,
    Layout,
    Settings,
    Plus,
} from "lucide-react"

import { cn } from "../../lib/utils";

import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { FormPopover } from "../../components/form/form-popover";

import { useTaskStoreActions } from "../../hooks/tasks-store";

interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    //membership: string; //Organization;
    userMemberships: string[];
    onExpand: (id: string) => void;
    location?: string;
}

export const NavItemOtherTools = ({
    isExpanded,
    isActive,
    //membership,
    userMemberships,
    onExpand,
    location,
}: NavItemProps) => {
    let navigate = useNavigate();

    return (
        <AccordionItem
            value="otherTools" //organization.id
            className="border-none p-0 m-0"
        >
            <AccordionTrigger
                onClick={() => onExpand("otherTools")} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-neutral-700 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2  p-0 m-0">
                    <span className="font-medium text-gray-700 dark:text-gray-200 text-sm">
                        Other Solutions
                    </span>
                </div>
            </AccordionTrigger>
            
            <AccordionContent className="pt-1 text-gray-700 dark:text-gray-200 hover:font-bold p-0 m-0">
                {userMemberships.map((membership) => (
                    <Button
                        key={membership}
                        size="sm"
                        onClick={() => navigate(`/${membership}`)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4 hover:font-bold",
                            ("/" + membership === location) && "bg-[#DDEBFE] dark:bg-[#11325B]"
                            )}
                        variant="ghost"
                    >
                        {membership}
                    </Button>
                ))}
            </AccordionContent>

        </AccordionItem>
    );
};

NavItemOtherTools.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}