import { useEffect, useState } from "react";
import { Button } from "../../../../../components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group"
import { SelectedCategoryState } from "../profile-techwatch";
import { ArrowLeft, Save } from "lucide-react";

import { Tag, Topics_Tags } from "../../../../../interfaces/techwatch/int-techwatch";
import { addUniqueTag, deleteUniqueTag, fetchAllTags, fetchTCCategorySelectedTags, fetchaddMultipleTags, fetchAllSelectedTags } from "../../../../../api/techwatch/api-tec-profile";

import { toast } from "sonner";
import { useTranslation } from "react-i18next";

interface CategoryTagsProps {
    selectedCategory: SelectedCategoryState;
    setSelectedCategory: (category: SelectedCategoryState) => void;
    filter: string;
}

const CategoryTags = ({
    selectedCategory,
    setSelectedCategory,
    filter,
}: CategoryTagsProps) => {
    const { t } = useTranslation();
    const [tags, setTags] = useState<Topics_Tags[]>([]);
    const [selectedTagsUser, setSelectedTagsUser] = useState<string[]>([]);

    useEffect(() => {
        const loadTags = async () => {
            try {
                const allTags = await fetchAllTags();
                setTags(allTags);
            } catch (error) {
                //console.error("Error fetching all tags:", error);
                toast.error("Error fetching tags.");
            }
        };
        loadTags();
    }, [filter]); // Rerun when filter changes

    useEffect(() => {
        const loadSelectedTags = async () => {
            if (selectedCategory) {
                const selectedTags = await fetchTCCategorySelectedTags(selectedCategory.id);
                setSelectedTagsUser(selectedTags);
            } else {
                // When no category is specifically selected, load all selected tags
                const selectedTags = await fetchAllSelectedTags(); // This should return all tags selected by the user across all categories
                setSelectedTagsUser(selectedTags);
            }
        };
        loadSelectedTags();
    }, [selectedCategory, filter]);

    const handleTagChange = async (newSelectedTags: string[]) => {
        const tagsToAdd = newSelectedTags.filter(tag => !selectedTagsUser.includes(tag));
        const tagsToRemove = selectedTagsUser.filter(tag => !newSelectedTags.includes(tag));

        tagsToAdd.forEach(async tagId => {
            try {
                await addUniqueTag(tagId);
                toast.success('Tag correctly added');
            } catch (error) {
                //console.error("Failed to add tag:", error);
                toast.error("Failed to add tag.");
            }
        });

        tagsToRemove.forEach(async tagId => {
            try {
                await deleteUniqueTag(tagId);
                toast.success('Tag correctly removed' );
            } catch (error) {
                //console.error("Failed to remove tag:", error);
            }
        });

        setSelectedTagsUser(newSelectedTags);
    };

    const getFilteredTags = () => {
        let uniqueTagsMap = new Map();
        tags.forEach(tag => {
            // When no category is specifically selected, use tag name for uniqueness to avoid duplicates across categories
            // When a category is selected, use tag id for uniqueness to ensure all tags for that category are shown
            const uniqueKey = selectedCategory ? `${tag.tag_id}-${tag.topic_id}` : tag.tag_name.toLowerCase();
            if (!uniqueTagsMap.has(uniqueKey)) {
                uniqueTagsMap.set(uniqueKey, tag);
            }
        });
        return Array.from(uniqueTagsMap.values());
    };

    const filteredTags = getFilteredTags().filter(tag =>
        tag.tag_name.toLowerCase().includes(filter.toLowerCase()) &&
        (!selectedCategory || tag.topic_id === selectedCategory.id)
    );

    return ( 
    <div>
        <div id="name-of-category">
            {selectedCategory ? (
                <p className="text-sm block text-[#2764B2] dark:text-[#87BAFF] font-bold mb-2 text-muted-foreground">
                    {t("profile.techwatch.descriptionTopicsTags")} {selectedCategory.topics_category}
                </p>
            ) : (
                <p className="text-sm block text-[#2764B2] dark:text-[#87BAFF] font-bold mb-2 text-muted-foreground">
                    {t("profile.techwatch.descriptionTags")}
                </p>
            )}
        </div>
        <div id='list-of-tags'>
            <ToggleGroup value={selectedTagsUser} onValueChange={handleTagChange} type="multiple">
                <div className="flex flex-wrap pt-2">
                    {filteredTags.map((tag) => (
                        <ToggleGroupItem 
                            key={`${tag.tag_id}-${tag.topic_id}`} 
                            value={tag.tag_id}
                            className="flex items-center m-1 px-2 py-1 group hover:bg-[#BBD9FF] hover:text-neutral_grey-500 data-[state=on]:bg-techwatch_primary data-[state=on]:text-neutral_white-200 dark:data-[state=on]:bg-[#3C72B9] bg-[#EBF1FA] dark:bg-[#5E5A5D]"
                        >
                            {tag.tag_name}
                        </ToggleGroupItem>
                    ))}
                </div>
            </ToggleGroup>
            {selectedCategory && (
            <Button 
                className="mt-4 bg-[#EBF1FA] text-[#133867] hover:bg-[#BBD9FF] hover:text-[#2764B2] dark:bg-[#102642] dark:text-[#87BAFF] dark:hover:bg-[#11325B] dark:hover:text-[#D1E7FF] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                onClick={() => setSelectedCategory(undefined)}>
                <ArrowLeft />
                Go back
            </Button>)}
        </div>
    </div> 
);
}
 
export default CategoryTags;