import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "../../../../components/ui/dialog"
import * as z from "zod";
import { useModal } from "./use-modal-store";
import { Label } from "../../../../components/ui/label";
import { Input } from "../../../../components/ui/input";
import { Check, SendHorizonal } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelectedChannel } from "../../../../hooks/community-hook";
import { fetchInviteMember } from "../../../../api/community/api-members";
import { Form, FormField, FormItem } from "../../../../components/ui/form";
import { Member } from "../../../../api/community/api-members";
import { useTranslation } from 'react-i18next';
import {toast} from 'sonner';
import { AxiosError } from "axios";


const formSchema = z.object({
    name: z.string().min(1, {
      message: "Username name is required."
    }),
  });
  
export const InviteModal = () => {
    const { isOpen, onOpen, onClose, type, data } = useModal();
    // const origin = useOrigin();
    const { t } = useTranslation();

    const isModalOpen = isOpen && type === "invite";
    const { channel } = data;

    const [clicked, setClick] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const selectedChannel = useSelectedChannel();

    const [inviteUser, setInviteUser] = useState<Member>();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
          name: '',
        }
      });


    const onSubmit = async (data: {name: string}) => {
        if(selectedChannel){
            try {
                //console.log("channel", selectedChannel.id);
                //console.log("data", data);
                //console.log("Sending invite request...");
                const response = await fetchInviteMember(data, selectedChannel.id);
                //console.log('response Invite Modal', response);
                setInviteUser(response);
                setClick(true);
                toast(`Invitation sent to ${response}`);
                setTimeout(() => {
                    setClick(false);
                    form.reset()
                    onClose();
                }, 1000);
            } catch (err: any) {
                //console.log("error: ", err);
                if (err && err.response) {
                    const code = err.response.status;
                    //console.log('code', code);
                    if (code === 409) {
                      toast(`The user seems already invited`);
                    } else if (code === 404) {
                      toast(`We can't find this user`);
                    } else {
                      toast('Something went wrong');
                    }
                  } else {
                    // Affiche un message si l'erreur n'est pas formatée comme attendu
                    //console.log("Unexpected error format: ", err);
                    toast('Something went wrong');
                  }
            }
        }
    }

    const handleClose = () => {
        form.reset();
        onClose();
      }

    return ( 
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
           <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] p-1 overflow-hidden items-center justify-center">
            <DialogHeader className="pt-8 px-6">
                <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
                    {t(`community.modals.invite.title`)}
                </DialogTitle>
            </DialogHeader>
            <div className="p-4 w-max-[3px]">
                <Label
                    className="uppercase text-xs font-bold text-[#A5328E] dark:text-[#F98FDF]"
                >
                    {t(`community.modals.invite.username`)}
                </Label>
                <div className="flex items-center mt-2 gap-x-2">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="flex">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <Input
                                        disabled={isLoading}
                                        className="bg-[#F4E2EE] dark:bg-[#1D101A] border-0 focus-visible:ring-0 text-black dark:text-white focus-visible:ring-offset-0 placeholder-gray-500 dark:placeholder-gray-300"
                                        {...field}
                                    />
                                </FormItem>
                            )}
                        />
                        <Button disabled={isLoading} type="submit" size="icon" className="bg-[#A5328E]">
                            {clicked
                            ? <Check className="w-4 h-4"/> 
                            : <SendHorizonal className="w-4 h-4" />
                            }
                        </Button>
                    </form>
                </Form>                
                </div>
            </div>
           </DialogContent>
        </Dialog>
     );
}