import React from 'react';
import { NavLink } from 'react-router-dom';
import img_techwatch from '../../../img/landing/techwatch.svg';
import img_feed from '../../../img/landing/techwatch/Feed-bro.svg';
import img_filter from '../../../img/landing/techwatch/Filter-bro.svg';
import img_summary from '../../../img/landing/techwatch/Search-bro.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';



const LandingTechWatch = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#1E1F22] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
            <div className="container mx-auto px-4">
                <div className="text-center mb-10">
                    <h1 className="text-4xl font-bold text-techwatch_primary dark:text-dark_techwatch">TechWatch</h1>
                    <p className="mt-4 text-lg lg:text-xl text-[#2764B2] dark:text-[#3C72B9]">
                        {t("landing.landingTechwatch.tagline")}
                    </p>
                </div>
                <div className="mb-16">
                    <div className="flex flex-col md:flex-row items-center gap-10">
                        <img src={img_techwatch} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                        <div className="flex-1">
                            <h2 className="text-2xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title1")}</h2>
                            <p className="mt-2 text-[#133867] dark:text-[#D1E7FF]">
                                {t("landing.landingTechwatch.desc1")}
                            </p>
                            <div className='py-3'>
                                <NavLink to="/signup" className="mt-4 bg-[#F3F6FA] text-[#2764B2] dark:bg-[#01040C] dark:text-[#D1E7FF] font-bold px-4 py-2 rounded shadow hover:bg-[#DDEBFE] dark:hover:bg-[#102642]">
                                    {t("landing.landingTechwatch.cta1")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-16 bg-[#EBF1FA] dark:bg-[#0D1723] p-10 rounded-lg">
                    <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                        <img src={img_feed} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                        <div className="flex-1">
                            <h2 className="text-2xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title2")}</h2>
                            <p className="mt-2 text-[#133867] dark:text-[#D1E7FF]">
                                {t("landing.landingTechwatch.desc2")}
                            </p>
                            <div className='py-3'>
                                <NavLink to="/signup" className="mt-4 bg-[#F3F6FA] text-[#2764B2] dark:bg-[#01040C] dark:text-[#D1E7FF] font-bold px-4 py-2 rounded shadow hover:bg-[#DDEBFE] dark:hover:bg-[#102642]">
                                    {t("landing.landingTechwatch.cta1")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-16">
                    <div className="flex flex-col md:flex-row items-center gap-10">
                        <img src={img_filter} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                        <div className="flex-1">
                            <h2 className="text-2xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title3")}</h2>
                            <p className="mt-2 text-[#133867] dark:text-[#D1E7FF]">
                                {t("landing.landingTechwatch.desc3")}
                            </p>
                            <div className='py-3'>
                                <NavLink to="/signup" className="mt-4 bg-[#F3F6FA] text-[#2764B2] dark:bg-[#01040C] dark:text-[#D1E7FF] font-bold px-4 py-2 rounded shadow hover:bg-[#DDEBFE] dark:hover:bg-[#102642]">
                                    {t("landing.landingTechwatch.cta1")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-20 bg-[#EBF1FA] dark:bg-[#0D1723] p-10 rounded-lg">
                    <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                        <img src={img_summary} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                        <div className="flex-1">
                            <h2 className="text-2xl font-semibold text-techwatch_primary dark:text-dark_techwatch">{t("landing.landingTechwatch.title4")}</h2>
                            <p className="mt-2 text-[#133867] dark:text-[#D1E7FF]">
                                {t("landing.landingTechwatch.desc4")}
                            </p>
                            <div className='py-3'>
                                <NavLink to="/signup" className="mt-4 bg-[#F3F6FA] text-[#2764B2] dark:bg-[#01040C] dark:text-[#D1E7FF] font-bold px-4 py-2 rounded shadow hover:bg-[#DDEBFE] dark:hover:bg-[#102642]">
                                    {t("landing.landingTechwatch.cta1")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <NavLink to="/signup" className="mt-4 bg-[#F3F6FA] text-[#2764B2] dark:bg-[#01040C] dark:text-[#D1E7FF] font-bold px-4 py-2 rounded shadow hover:bg-[#DDEBFE] dark:hover:bg-[#102642]">
                        {t("landing.landingTechwatch.cta2")}
                    </NavLink>
                </div>
            </div>
            </ScrollArea>
        </div>
    );
}

export default LandingTechWatch;
