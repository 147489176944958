import React, { useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.post('/api/auth/verify-email', null, { params: { token } });
        alert('Email verified successfully!');
      } catch (error) {
        console.error('Verification error', error);
        alert('Email verification failed');
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token]);

  return <div>Verifying your email...</div>;
};

export default VerifyEmail;
