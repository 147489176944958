import axiosInstance from "../api";

export interface CreateChannelData {
    id: string
    name: string;
}

export interface InputCreateChannelData {
    server_id: string
    name: string;
}

export interface InputUpdateChannelData {
    name: string;
}

export const createChannel = async (inputCreateChannelData: InputCreateChannelData): Promise<CreateChannelData> => {
    const response = await axiosInstance.post<CreateChannelData>(`/com-channel/create`, inputCreateChannelData);
    return response.data;
  };

export const updateChannel = async (channelId: string, inputUpdateChannelData: InputUpdateChannelData): Promise<InputUpdateChannelData> => {
    const response = await axiosInstance.patch<CreateChannelData>(`/com-channel/updateChannel/${channelId}`, inputUpdateChannelData);
    return response.data;
  };

export const deleteChannel = async (channelId: string): Promise<{ message: string }> => {
    const response = await axiosInstance.delete<{ message: string }>(`/com-channel/deleteChannel/${channelId}`);
    return response.data;
  };

  