import PlanComponent from "./planComponent";
import LandingOverview from "./landing/landing-overview";
import { usePlans } from "../../../../hooks/career-hook";

const PlanOverview = () => {

    let plans = usePlans();
    
    const pathsOrder = { CURRENT: 1, ALTERNATIVE: 2, DREAM: 3, OTHER: 4 };
    
    plans = plans?.map((plan) => {
        // Create a new object with the updated 'path' property
        return {
            ...plan,
            path: plan.path || "OTHER" // This will assign "OTHER" if 'path' is undefined or falsy
        };
    }).sort((a, b) => {
        // Now TypeScript knows `a.path` and `b.path` are keys in `pathsOrder`
        return pathsOrder[a.path] - pathsOrder[b.path];
    });

    return (
        <div className="flex-grow flex flex-col h-full p-4 space-y-4">
            {plans && plans.length > 0 ? (
                plans.map((plan) => (
                    plan.id &&
                        <PlanComponent
                            key= {plan.id}
                            id={plan.id}
                        />
                    ))
                ) : (
                <LandingOverview/>
            )}
        </div>
  );
};
 
export default PlanOverview;