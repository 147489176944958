import { FeedExtended, Topic } from "../../interfaces/techwatch/int-techwatch";
import axiosInstance from "../api";

//Get feeds logic
export const fetchGeneralFeeds = async (page: number, limit: number): Promise<FeedExtended[]> => {
    const response = await axiosInstance.get<FeedExtended[]>('/feeds', {
        params: {
            page,
            limit,
        },
    });
    return response.data;
};

export const fetchSavedFeeds = async (page: number, limit: number): Promise<FeedExtended[]> => {
    const response = await axiosInstance.get<FeedExtended[]>('/save-feeds', {
        params: {
            page,
            limit,
        },
    });
    return response.data;
};

export const fetchFeedsByCategory = async (page: number, limit: number, topic: string): Promise<FeedExtended[]> => {
    const response = await axiosInstance.get<FeedExtended[]>('/feeds/feedsByTopic', {
        params: {
            page,
            limit,
            topic,
        },
    });
    return response.data;
  };

export const fetchFeedsByUser = async (page: number, limit: number): Promise<FeedExtended[]> => {
    const response = await axiosInstance.get<FeedExtended[]>('/feeds/feedsByUser', {
        params: {
            page,
            limit,
        },
    });
    return response.data;
};

//Save feeds logic
export const fetchSaveFeed = async (feedId: string): Promise<{ saveFeed: string }> => {
    const response = await axiosInstance.post<{ saveFeed: string }>(`/save-feeds/saveFeed/${feedId}`);
    return response.data;
};

export const fetchUnsaveFeed = async (feedId: string): Promise<{ saveFeed: string }> => {
    const response = await axiosInstance.delete<{ saveFeed: string }>(`/save-feeds/unsaveFeed/${feedId}`);
    return response.data;
};

//Like feeds logic
export const fetchLikeFeed = async (feedId: string): Promise<{ likeFeed: string }> => {
    const response = await axiosInstance.post<{ likeFeed: string }>(`/like-feeds/likeFeed/${feedId}`);
    return response.data;
};

export const fetchUnlikeFeed = async (feedId: string): Promise<{ likeFeed: string }> => {
    const response = await axiosInstance.delete<{ likeFeed: string }>(`/like-feeds/unlikeFeed/${feedId}`);
    return response.data;
};

//DisLike feeds logic
export const fetchDislikeFeed = async (feedId: string): Promise<{ dislikeFeed: string }> => {
    const response = await axiosInstance.post<{ dislikeFeed: string }>(`/like-feeds/dislikeFeed/${feedId}`);
    return response.data;
};

export const fetchUndislikeFeed = async (feedId: string): Promise<{ dislikeFeed: string }> => {
    const response = await axiosInstance.delete<{ dislikeFeed: string }>(`/like-feeds/undislikeFeed/${feedId}`);
    return response.data;
};

export const fetchTopics = async (): Promise<Topic[]> => {
    const response = await axiosInstance.get<Topic[]>('/topics');
    return response.data;
};