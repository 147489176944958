import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { ModalProvider } from "../modals/modal-provider";
import { useModal } from "../modals/use-modal-store";
import { useActions, useIsAuthenticated } from "../../../hooks/auth-store";

import { CircleCheck, AlertCircle, Eye, EyeOff } from 'lucide-react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { userSchema, UserSchema, emailSchema, userNameSchema } from "../../../api/globals/sign-up/schema";
import { fetchCheckExistingEmail, fetchCheckExistingUsername } from "../../../api/globals/api-signup";

import { fetchCreateUser } from "../../../api/globals/sign-up";

interface MyData {
    // Définissez votre structure ici
  }

const SignUpForm = () => {

    const { onOpen } = useModal();
    
    const { register, handleSubmit, formState: { errors }, watch, setError, clearErrors } = useForm<UserSchema>({
        resolver: zodResolver(userSchema),
        defaultValues: {
          user_name: '',
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          confirm_password: '',
          accept_gtc_pp: false
        }
      });
    
    // handle email and usernam checks
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);
    const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

    // handle submit state variables
    const [data, setData] = useState<MyData>({} as MyData);
    const [isLoading, setLoading] = useState(false);
    const [error, setErrorState] = useState('');
    
    // handle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Check if user is authenticated and redirection
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();

    // hook pour passer à la page suivante
    const { setSignUpStep} = useActions();
    // Ajout d'un état pour contrôler si les GTC ont été acceptées
    //const [acceptGTC, setAcceptGTC] = useState(false);

    const accept_gtc_pp = watch('accept_gtc_pp', false);

    if (isAuthenticated) {
        return <Navigate to="/techwatch" />;
    }

    const handleBlur = async (field: string, value: string) => {
        let validationSchema;
        if (field === 'email') {
            validationSchema = emailSchema;
        } else if (field === 'user_name') {
            validationSchema = userNameSchema;
        } else {
            return;
        }

        const result = validationSchema.safeParse({ [field]: value });

        if (!result.success) {
            // Handle format validation error
            if (field === 'email') {
                setError('email', { type: 'manual', message: result.error.errors[0].message });
                setIsEmailValid(null);
            }
            if (field === 'user_name') {
                setError('user_name', { type: 'manual', message: result.error.errors[0].message });
                setIsUsernameValid(null);
            }
            return;
        }

        // Clear any previous validation errors
        clearErrors(field);

        if (field === 'email') {
            try {
                const response = await fetchCheckExistingEmail({ email: value });
                setIsEmailValid(response.message === "Email is available");
            } catch (err) {
                setIsEmailValid(false);
            }
        }
        if (field === 'user_name') {
            try {
                const response = await fetchCheckExistingUsername({ user_name: value });
                setIsUsernameValid(response.message === "Username is available");
            } catch (err) {
                setIsUsernameValid(false);
            }
        }
    };

    const onSubmit = async (data: UserSchema) => {

        setLoading(true);
        
        const signUpData = {
            ...data,
            display_name: data.user_name
        };

        //console.log('signUpData: ', signUpData);

        try {
            await fetchCheckExistingUsername({ user_name: data.user_name });
            await fetchCheckExistingEmail({ email: data.email });
            await fetchCreateUser(signUpData);
            setLoading(false);
            setSignUpStep('emailValidation');
        } catch (err:any) {
            //console.log('Error verifying email or signing up: ', err);
            setErrorState(err.message);
            setLoading(false);
        }
    };


    return (
        <div className="flex flex-grow h-full w-full bg-[#F8F5F5] dark:bg-[#1A1213] pb-24">
            <ModalProvider />
                {/* New Form Panel */}
                <form 
                    onSubmit={handleSubmit(onSubmit)} 
                    //className="space-y-1 sm:mx-auto sm:w-full sm:max-w-md pb-3 h-full"
                    className="space-y-1 sm:mx-auto sm:max-w-md pb-3 w-full h-full px-2"
                >
                    <div className="pb-3 relative">
                        <label htmlFor="userName" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">User Name</label>
                        <input
                            type="text"
                            id="userName"
                            {...register('user_name')}
                            placeholder="your display Name"
                            className="mt-1 rounded block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 sm:text-sm"
                            onBlur={(e) => handleBlur('user_name', e.target.value)}
                        />
                        {isUsernameValid && <CircleCheck className="w-5 h-5 text-green-500 absolute top-8 right-3" />}
                        {isUsernameValid === false && (
                            <div className="absolute top-8 right-3 flex items-center space-x-1">
                                <AlertCircle className="w-5 h-5 text-red-500" />
                                <p className="text-red-500 text-sm">Username is not available</p>
                            </div>
                        )}
                        {errors.user_name && <p className="text-warning_red text-sm">{errors.user_name.message}</p>}
                    </div>
                    {/*<div className="pb-3">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            {...register('first_name')}
                            placeholder="your first name"
                            className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm"
                        />
                        {errors.first_name && <p className="text-warning_red text-sm">{errors.first_name.message}</p>}
                    </div>
                    <div className="pb-3">
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            {...register('last_name')}
                            placeholder="your last name"
                            className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm"
                        />
                        {errors.last_name && <p className="text-warning_red text-sm">{errors.last_name.message}</p>}
                    </div>*/}
                    <div className="pb-3 relative">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            {...register('email')}
                            placeholder="your email"
                            className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm"
                            onBlur={(e) => handleBlur('email', e.target.value)}
                        />
                        {isEmailValid && <CircleCheck className="w-5 h-5 text-green-500 absolute top-8 right-3" />}
                        {isEmailValid === false && (
                            <div className="absolute top-8 right-3 flex items-center space-x-1">
                                <AlertCircle className="w-5 h-5 text-red-500" />
                                <p className="text-red-500 text-sm">Email is not available</p>
                            </div>
                        )}
                        {errors.email && <p className="text-warning_red text-sm">{errors.email.message}</p>}
                    </div>

                    <div className="pb-4 relative">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                {...register('password')}
                                placeholder="type your password"
                                className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm pr-10"
                            />
                            <div 
                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" 
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </div>
                        </div>
                        {errors.password && <p className="text-warning_red text-sm">{errors.password.message}</p>}
                    </div>

                    <div className="pb-4 relative">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Confirm Password</label>
                        <div className="relative">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                {...register('confirm_password')}
                                placeholder="confirm your password"
                                className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm pr-10"
                            />
                            <div 
                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" 
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </div>
                        </div>
                        {errors.confirm_password && <p className="text-warning_red text-sm">{errors.confirm_password.message}</p>}
                    </div>
                    
                    <div className="flex items-center pb-4">
                        <input
                            id="acceptGTC"
                            type="checkbox"
                            {...register('accept_gtc_pp')}
                            className="h-4 w-4 text-[#AE3F51] focus:ring-[#F697A1] border-gray-300 rounded mt-1"
                        />
                        <label htmlFor="acceptGTC" className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
                            I agree to the <span className="text-[#AE3F51] hover:underline cursor-pointer" onClick={() => onOpen("cguModal")}>Terms and Conditions</span> and <span className="text-[#AE3F51] hover:underline cursor-pointer" onClick={() => onOpen("privacyPolicyModal")}>Privacy Policy</span>
                        </label>
                        {errors.accept_gtc_pp && <p className="text-warning_red text-sm">{errors.accept_gtc_pp.message}</p>}
                    </div>
                    <div className=" mb-4 ">
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803C45]"
                            //disabled={!acceptGTC}  // Disable button if GTC is not checked
                        >
                        Sign Up
                        </button>
                    </div>
                </form>
        </div>
    );
}
 
export default SignUpForm;

/*
<div className="pb-4">
    <label htmlFor="password" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Password</label>
    <input
        type="password"
        id="password"
        {...register('password')}
        placeholder="type your password"
        className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm"
    />
    {errors.password && <p className="text-warning_red text-sm">{errors.password.message}</p>}
</div>
<div className="pb-4">
    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600 dark:text-[#B3B3B3] italic mt-2">Confirm Password</label>
    <input
        type="password"
        id="confirmPassword"
        {...register('confirm_password')}
        placeholder="confirm your password"
        className="mt-1 block w-full px-3 py-2 border text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0 rounded sm:text-sm"
    />
    {errors.confirm_password && <p className="text-warning_red text-sm">{errors.confirm_password.message}</p>}
</div>
*/