import { MoreHorizontal, Copy, Trash2, Mails, SquareUserRound } from "lucide-react";
import { useToast } from "../../../../../components/ui/use-toast";
import { Button } from "../../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu"

import { ContactTableElement, getContactStoreActions } from "../../../../../hooks/contact-store";
import { getContactsHookActions } from "../../../../../hooks/contact-hook";
import { fetchDeleteContact } from "../../../../../api/contacts/api-getContactData";

import { ConEvent, ConAction } from '../../../../../interfaces/contacts/int-contacts';

import { toast } from "sonner";

// Define the props interface
interface ContactTimelineActionsOptions {
    action: ConAction;
}

 //handle left panel visibility
 const { setIsLeftPanelVisible, setSelectedContact } = getContactsHookActions();
 
 const handleOpenSheet = (contactId: string) => {
       //console.log('contactId', contactId);
       setSelectedContact(contactId);
       setIsLeftPanelVisible(true);
   };


const contactTimelineActionsOptions = ({ action }: ContactTimelineActionsOptions) => {
    
    

     return ( 
        <div onClick={(event) => event.stopPropagation()}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0 m-0">
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>


                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    //onClick={() => handleOpenSheet(action.id)}
                  >
                    <SquareUserRound size={16} />
                    <p className="ml-2">View contact details</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    //onClick={() => handleContactDeletion(action.id)}
                  >
                    <Trash2 size={16} />
                    <p className="ml-2">Delete contact</p>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
     );
}

export default contactTimelineActionsOptions;