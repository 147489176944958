import { Button } from '../../../../components/ui/button';
import { getNodeOptionAction, useCareerStoreNodeOption } from '../../../../hooks/career-store';
import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Node, NodeProps } from 'reactflow';

import { Forward, CircleFadingPlus, Pencil, Save, MoreHorizontal } from 'lucide-react';

import NodeActionMenu from './NodeActionMenu';
import { cn } from '../../../../lib/utils';

type CustomCategoryNodeData = {
    year: number;
    type: string;
    category: string;
    title: string;
    description: string;
    label: string;
    previous_elt?: string;
    next_elt?: string;
  };

export type CustomCategoryNodeType = NodeProps<CustomCategoryNodeData>;

const truncateString = (str: string) => {
  const MAX_LENGTH = 70;

  if (!str) {
    return "";
  }

  if (str.length <= MAX_LENGTH) {
    return str;
  }

  const lastSpaceIndex = str.lastIndexOf(' ', MAX_LENGTH);

  if (lastSpaceIndex !== -1) {
    return str.substring(0, lastSpaceIndex) + ' [...]';
  }

  return str.substring(0, MAX_LENGTH) + ' [...]';
};

function CustomCategoryNode(props: CustomCategoryNodeType) { //NodeProps<CustomElementNodeData>
  //to do : reworw the tailwind classnames to adjust the size of the node and the text box
  const { selected, sourcePosition, targetPosition } = props;
  //const { updateNodeOption } = getNodeOptionAction();
  const nodeOption = useCareerStoreNodeOption();

  const [editMode, setEditMode] = useState(false);
  /*
  if(parseInt(props.id) < 6) {
    //console.log("nodes props : ", props);
  }
  */

  const CategoryNode = () => {

    return (
        <div className={cn("w-80 px-4 py-2 text-center flex items-center justify-center text-sm md:text-2xl bg-[#F697A1] dark:bg-[#1A1213] shadow-sm hover:shadow-md border-2 border-[#F697A1]",
            !selected ? "h-20" : "")}>
              
            <i>{props.data.title && (props.data.title)}</i>
          
        </div>
    )
  }
  
    const renderContent = () => {
        return (
            <CategoryNode />
        );
    };

  return (
    <div>
      {renderContent()}
    </div>
    
  );
}

export default memo(CustomCategoryNode);

/*
<div className="flex justify-between align-middle">
          <div className="flex text-xs text-gray-500 ">{(props.data.category).toLowerCase()} - year {props.data.year}</div>
          <div className='flex'>
            <NodeActionMenu props = { props } />
          </div>
        </div>
        
        
        <div className="w-72 justify-center items-center text-sm font-bold">{selected ? props.data.title : truncateString(props.data.title)}</div> 
        {selected && (
          <div>
            <div className="text-xs text-gray-500 ">{props.data.description}</div>
            <div className="flex justify-end gap-1 mt-2">
              <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
              <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
              <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Pencil size={12} color="#1C4171" /></Button>
          </div>
        </div>
        )}


const DetailedNode = () => {
    return (
      <div className="w-80 px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400"> 
        <div className="text-xs text-gray-500 ">{props.data.type} - year {props.data.year}</div>
        <div className="w-72 justify-center items-center text-sm font-bold">{props.data.title}</div> 
        <div className="text-xs text-gray-500 ">{props.data.description}</div>
        <div className="flex justify-end gap-1 mt-2">
          <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
          <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
          <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Pencil size={12} color="#1C4171" /></Button>
        </div>
        <Handle type="target" position={targetPosition ? targetPosition : Position.Left} className="w-16 h-32 !bg-teal-500" />
        <Handle type="source" position={sourcePosition ? sourcePosition : Position.Right} className="w-16 !bg-teal-500" />
      </div>
    )
  }

*/


/*

{ editMode ? (
          <div>
            <div>
              <div className="flex justify-between align-middle">
                <div className='flex'>azertyuio
                  <NodeActionMenu props = { props } />
                </div>
              </div>
              
              
              <div className="w-72 justify-center items-center text-sm font-bold">{selected ? props.data.title : truncateString(props.data.title)}</div> 
              {selected && (
                <div>
                  <div className="flex text-xs text-gray-500 ">{props.data.category && (props.data.category).toLowerCase()} - year {props.data.year}</div>
                  <div className="flex justify-end gap-1 mt-2">
                    <Button title="add sub-steps" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><CircleFadingPlus size={12} color="#1C4171"/></Button>
                    <Button title="send to tasks" value="" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(true)}><Forward size={12} color="#1C4171"/></Button>
                    <Button title="edit" value="edit" className="h-5 p-1 text-sm bg-transparent hover:bg-slate-100" onClick={() => setEditMode(false)}><Save size={12} color="#1C4171" /></Button>
                </div>
              </div>
              )}
            </div>
          </div>
        ) : (
*/