import { Link } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { fetchDislikeFeed, fetchLikeFeed, fetchSaveFeed, fetchUndislikeFeed, fetchUnlikeFeed, fetchUnsaveFeed } from "../../../api/techwatch/api-getFeeds";
import { FeedExtended } from "../../../interfaces/techwatch/int-techwatch";
import { Button } from "../../../components/ui/button";
import { Bookmark, BookmarkCheck, ThumbsUp, Share2, ExternalLink, ArrowDown, ArrowUp} from "lucide-react";
import { useToast } from "../../../components/ui/use-toast";
import { useFeeds, useTechwatchStoreActions } from "../../../hooks/techwatch-store";
import { Skeleton } from "../../../components/ui/skeleton";
import SocialShareButton from "./SocialShareButtons";
import { ActionTooltip } from "../../../components/action-tooltip";
import { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { Badge } from "../../../components/ui/badge";

interface FeedCardProps {
    //key: string;
    feed: FeedExtended;
    selectedFeed: string;
    setSelectedFeed: React.Dispatch<React.SetStateAction<string>>;
    feedRefs: { [key: string]: any };
}

const FeedCard = ({feed, selectedFeed, setSelectedFeed, feedRefs }: FeedCardProps) => {
    const { toast } = useToast();
    const { updateOneFeed } = useTechwatchStoreActions();
    const feeds = useFeeds();
    const [showAllTags, setShowAllTags] = useState(false);
    const toggleTags = () => setShowAllTags(!showAllTags);
    const [showShareOptions, setShowShareOptions] = useState(false);
    const toggleShareOptions = () => setShowShareOptions(!showShareOptions);
    const [isTLDRExpanded, setTLDRExpanded] = useState(false);
    const handleToggleTLDR = () => setTLDRExpanded(!isTLDRExpanded);

    //console.log("FEED", feed);

    const truncateString = (inputText: string) => {
        const MAX_LENGTH = 200;
        if (inputText.length <= MAX_LENGTH) return { text: inputText, isTruncated: false };
        const lastSpaceIndex = inputText.lastIndexOf(' ', MAX_LENGTH);
        if (lastSpaceIndex !== -1) return { text: inputText.substring(0, lastSpaceIndex), isTruncated: true };
        return { text: inputText.substring(0, MAX_LENGTH), isTruncated: true };
    };

    const handleSelectedFeed = (event: React.MouseEvent, feedId: string) => {
        event.stopPropagation();
        setSelectedFeed(prevFeed => {
            const isCollapsing = prevFeed === feedId;
            if (isCollapsing) {
                setTimeout(() => {
                    feedRefs[feedId]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 100);
            }
            return prevFeed === feedId ? '' : feedId;
        });
    };

    const handleToggleSaveFeed = async (feedId: string) => {
        try {
            const currentFeed = feeds?.find(feed => feed.id === feedId);
            if (!currentFeed) throw new Error("Feed not found");
            let response;
            if (currentFeed.isSaved) {
                response = await fetchUnsaveFeed(feedId);
            } else {
                response = await fetchSaveFeed(feedId);
            }
            toast({ description: `${response.saveFeed}` });
            currentFeed.isSaved = !currentFeed.isSaved;
            updateOneFeed(currentFeed);
        } catch (err) {
            //console.error("error: ", err);
            toast({ description: 'Oups, impossible to update the article status.' });
        }
    };

    const handleToggleLikeFeed = async (feedId: string) => {
        try {
            const currentFeed = feeds?.find(feed => feed.id === feedId);
            if (!currentFeed) throw new Error("Feed not found");
            let response;
            if (currentFeed.isLiked) {
                response = await fetchUnlikeFeed(feedId);
            } else {
                response = await fetchLikeFeed(feedId);
                if (currentFeed.isDisLiked) {
                    currentFeed.isDisLiked = false;
                }
            }
            toast({ description: `${response.likeFeed}` });
            currentFeed.isLiked = !currentFeed.isLiked;
            updateOneFeed(currentFeed);
        } catch (err) {
            //console.error("error: ", err);
            toast({ description: 'Oups, impossible to update the article status.' });
        }
    };

    const handleToggleDisLikeFeed = async (feedId: string) => {
        try {
            const currentFeed = feeds?.find(feed => feed.id === feedId);
            if (!currentFeed) throw new Error("Feed not found");
            let response;
            if (currentFeed.isDisLiked) {
                response = await fetchUndislikeFeed(feedId);
            } else {
                response = await fetchDislikeFeed(feedId);
                if (currentFeed.isLiked) {
                    currentFeed.isLiked = false;
                }
            }
            toast({ description: `${response.dislikeFeed}` });
            currentFeed.isDisLiked = !currentFeed.isDisLiked;
            updateOneFeed(currentFeed);
        } catch (err) {
            //console.error("error: ", err);
            toast({ description: 'Oups, impossible to update the article status.' });
        }
    };

    const { text: truncatedText, isTruncated } = truncateString(feed.summary_clean);

    // Handling image
    //const [src, setSrc] = useState(feed.image_url ? feed.image_url : feed.source_image_url);
    const [src, setSrc] = useState<string>(feed.image_url ? feed.image_url : '') ;
    const [imgError, setImgError] = useState(false);

    const checkImage = (url: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(url);
            img.onerror = () => reject(url);
            img.src = url;
        });
    };

    useEffect(() => {
        checkImage(src).catch(() => {
            setImgError(true);
            setSrc(feed.source_image_url ? feed.source_image_url : 'https://www.zangou.ai/static/media/logo.7b3c62b7dcf7f9618d37.png'); // Fallback to a default image or a placeholder
        });
    }, [src]);

    // Open the feed in a new tab logic
    const openInNewBackgroundTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener noreferrer');
        if (newWindow) {
            setTimeout(() => {
            //newWindow.blur(); // Removes focus from the new tab
            newWindow.opener = null; // Detach from the parent window to improve security
            window.focus(); // Attempt to refocus the current window
            }, 0); // Increased timeout for better reliability
        }
    };

    return (
        <Card 
            key={feed.id} 
            className="flex flex-col mx-4 my-2 p-1 bg-white dark:bg-[#0D1723] shadow-md rounded-lg max-w-full" 
            ref={(el: HTMLDivElement | null) => {
                feedRefs[feed.id] = el;
                return void 0;
            }}
        >
            <CardHeader className="text-lg font-bold py-1 px-3">
                <div className="flex justify-between items-start m-0 p-0">
                    <CardTitle className="text-lg md:text-xl font-bold text-[#133867] dark:text-[#D1E7FF] m-0 pt-1 pb-0 px-1">{feed.title}</CardTitle>
                    <ActionTooltip side="right" align="center" label="See full article">
                        <a 
                            href={feed.link} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="px-3 pt-2 m-0"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default anchor behavior
                                openInNewBackgroundTab(feed.link);
                            }}
                            
                        >
                            <ExternalLink className="w-5 h-5 hover:text-techwatch_primary" />
                        </a>
                    </ActionTooltip>
                </div>
                <CardDescription className="text-sm text-[#878286] dark:text-[#6D6D6D] font-semibold m-0 px-1 py-0">
                    {feed.author && feed.author + " - " } 
                    <a href={feed.tec_sources?.source_site_url} target="_blank" rel="noreferrer">{feed.tec_sources?.source_title}</a> 
                    {feed.published_date && " - " + new Date(feed.published_date).toLocaleDateString()}
                </CardDescription>
            </CardHeader>
            <CardContent className="flex-grow items-start px-1 py-1">
                <div className="flex flex-col md:flex-row items-center md:items-center">
                    <div className="flex-shrink-0 w-1/2 md:w-1/4 px-4 py-2 md:py-0 items-center overflow-hidden rounded-lg">
                        <img
                            src={src}
                            onError={() => setSrc('https://www.zangou.ai/static/media/logo.7b3c62b7dcf7f9618d37.png')}
                            alt={feed.title}
                            className="w-full md:h-24 object-contain" // Change from `object-cover` to `object-contain`
                        />
                    </div>
                
                    {(feed.tldr && feed.tldr !== 'noTldr') ? (
                        <div>
                            <p className="text-md font-normal text-[#133867] dark:text-[#D1E7FF] hidden md:block">{feed.tldr}</p>
                            {isTLDRExpanded ? (
                                <div className="md:hidden">
                                    <p className="text-sm font-normal text-[#133867] dark:text-[#D1E7FF]">
                                        {feed.tldr} <span className="cursor-pointer mt-4 px-2 italic text-[#2764B2] dark:text-[#87BAFF] hover:text-techwatch_secondary dark:hover:text-[#2A5287]" onClick={handleToggleTLDR}>See less</span>
                                    </p>
                                </div>
                            ) : (
                                feed.tldr.length > 200 ? (
                                    <div className="md:hidden">
                                        <p className="text-sm font-medium text-[#133867] dark:text-[#D1E7FF]">
                                            {`${feed.tldr.substring(0, 200)}...`} <span className="cursor-pointer mt-4 px-2 italic text-[#2764B2] dark:text-[#87BAFF] hover:text-techwatch_secondary dark:hover:text-[#2A5287]" onClick={handleToggleTLDR}>See more</span>
                                        </p>
                                    </div>
                                ) : (
                                    <p className="text-sm font-normal text-[#133867] dark:text-[#D1E7FF] md:hidden">{feed.tldr}</p>
                                )
                            )}
                        </div>
                    ) : (
                        selectedFeed === feed.id ? (
                            <div>
                                <p className="text-md font-normal text-[#133867] dark:text-[#D1E7FF]">
                                    {feed.summary_clean}
                                    <span className="cursor-pointer mt-4 px-2 italic text-[#2764B2] dark:text-[#87BAFF] hover:text-techwatch_secondary dark:hover:text-[#2A5287]" onClick={(event) => handleSelectedFeed(event, feed.id)}>See less</span>
                                </p>
                            </div>
                        ) : (
                            <div>
                                <p className="text-md font-normal text-[#133867] dark:text-[#D1E7FF]">
                                    {truncatedText}
                                    {isTruncated && (
                                        <span className="cursor-pointer mt-4 px-2 italic text-[#2764B2] dark:text-[#87BAFF] hover:text-techwatch_secondary dark:hover:text-[#2A5287]" onClick={() => setSelectedFeed(feed.id)}>See more</span>
                                    )}
                                </p>
                            </div>
                        )
                    )}
                </div>
            </CardContent>
            <CardFooter className="flex justify-between items-center w-full py-1 px-1">
                <div className="flex flex-wrap gap-2">
                    {feed.tec_feeds_tags && (
                        <>
                            {showAllTags ? (
                                feed.tec_feeds_tags.slice(0,5).map((tag, index) => (
                                        <Badge key={index} className="text-xs font-normal bg-[#62A0F2] dark:bg-[#254E82] text-neutral_white-200">{tag}</Badge>
                                ))
                            ) : (
                                <>
                                    {feed.tec_feeds_tags.slice(0, 1).map((tag, index) => (
                                        <Badge key={index} className="text-xs font-normal bg-[#62A0F2] dark:bg-[#254E82] text-neutral_white-200">{tag}</Badge>
                                    ))}

                                    <Badge className="md:hidden text-xs font-normal bg-[#62A0F2] dark:bg-[#254E82] text-neutral_white-200 cursor-pointer" onClick={toggleTags}>+</Badge>

                                    {feed.tec_feeds_tags.slice(1, 3).map((tag, index) => (
                                        <Badge key={index} className="hidden md:block text-xs font-normal bg-[#62A0F2] dark:bg-[#254E82] text-neutral_white-200">{tag}</Badge>
                                    ))}
                                    {feed.tec_feeds_tags.length > 3 && (
                                        <Badge className="hidden md:block text-xs font-normal bg-[#62A0F2] dark:bg-[#254E82] text-neutral_white-200 cursor-pointer" onClick={toggleTags}>+</Badge>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="flex">
                    <Popover>
                            <ActionTooltip side="top" align="center" label="Share">
                                <PopoverTrigger asChild>
                                    <Button className="bg-transparent text-[#133867] dark:text-[#D1E7FF] hover:bg-transparent rounded-md">
                                        <Share2 size={20} />
                                    </Button>
                                </PopoverTrigger>
                            </ActionTooltip>
                            <PopoverContent className="bg-white dark:bg-[#0D1723] shadow-lg p-2 rounded-lg" >
                                <SocialShareButton feed={feed} />
                            </PopoverContent>
                    </Popover>
                    <Button className="mx-1 px-3 py-0 bg-transparent text-techwatch_primary hover:bg-transparent rounded-md" onClick={() => handleToggleSaveFeed(feed.id)}>
                        {feed.isSaved ? (
                            <ActionTooltip side="top" align="center" label="Bookmark">
                                <BookmarkCheck size={20} className="border-techwatch_primary text-[#133867] dark:text-[#D1E7FF]"/>
                            </ActionTooltip>
                        ) : (
                            <ActionTooltip side="top" align="center" label="Bookmark">
                                <Bookmark size={20} className="border-techwatch_primary text-[#133867] dark:text-[#D1E7FF]"/>
                            </ActionTooltip>
                        )}
                    </Button>
                    <Button className="flex mx-1 px-3 py-0 bg-transparent text-techwatch_primary hover:bg-transparent" onClick={() => handleToggleDisLikeFeed(feed.id)}>
                        {feed.isDisLiked ? (
                            <ActionTooltip side="bottom" align="center" label="Cancel">
                                <ArrowDown size={20} className="text-warning_red"/>
                            </ActionTooltip>
                        ) : (
                            <ActionTooltip side="bottom" align="center" label="Dislike">
                                <ArrowDown size={20} className="text-[#133867] dark:text-[#D1E7FF]"/>
                            </ActionTooltip>
                        )}
                    </Button>
                    <Button className="flex mx-1 px-3 py-0 bg-transparent text-techwatch_primary hover:bg-transparent" onClick={() => handleToggleLikeFeed(feed.id)}>
                        {feed.isLiked ? (
                            <ActionTooltip side="right" align="center" label="Unlike">
                                <ArrowUp size={20} className="text-warning_green"/>
                            </ActionTooltip>
                        ) : (
                            <ActionTooltip side="right" align="center" label="Like">
                                <ArrowUp size={20} className="text-[#133867] dark:text-[#D1E7FF]"/>
                            </ActionTooltip>
                        )}
                    </Button>
                </div>
            </CardFooter>
        </Card>
    );
}

export default FeedCard;
// className="flex flex-col mx-4 p-1 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mt-1 mb-3 max-w-full" 
export const FeedCardSkeleton = () => {
    return (
        <Card 
            //className="w-full max-w-full mx-4 p-1 animate-pulse"
            className="flex flex-col mx-4 p-1 gap-4 bg-white dark:bg-[#0D1723] shadow-md rounded-lg mt-1 mb-3 max-w-full animate-pulse" 
        >
            <CardHeader>
                <CardTitle>
                    <Skeleton className="h-6 w-3/4 mb-2" />
                </CardTitle>
                <CardDescription>
                    <Skeleton className="h-4 w-1/2 mb-1" />
                </CardDescription>
            </CardHeader>
            <CardContent>
                <Skeleton className="h-4 w-full mb-2" />
                <Skeleton className="h-4 w-full mb-2" />
                <Skeleton className="h-4 w-5/6" />
            </CardContent>
            <CardFooter className="flex justify-between">
                <Skeleton className="h-4 w-1/4" />
                <div className="flex">
                    <Skeleton className="h-8 w-8 mr-2" />
                    <Skeleton className="h-8 w-8 mr-2" />
                    <Skeleton className="h-8 w-8 mr-2" />
                    <Skeleton className="h-8 w-8" />
                </div>
            </CardFooter>
        </Card>
    );
}
