import { create } from "zustand";

type CardModalStore = {
    id?: string;
    isOpen: boolean;
    onOpen: (id?: string) => void;
    setTaskCardModalId: (id: string) => void;
    onClose: () => void;
};

type PlanModalStore = {
    id?: string;
    name?: string;
    isOpen: boolean;
    onOpen: (id: string, name: string) => void;
    onClose: () => void;
};

export const useCardModal = create<CardModalStore>((set) => ({
    id: undefined,
    isOpen: false,
    onOpen: (id?: string) => {
        if (id) {
            set({ isOpen: true, id })
        } else {
            set({ isOpen: true })
        }
    },
    setTaskCardModalId: (id: string) => {
        set({
            id,
        })
    },
    onClose: () => set({ isOpen: false, id: undefined }),
}));

export const useDeletePlanModal = create<PlanModalStore>((set) => ({
    id: undefined,
    name: undefined,
    isOpen: false,
    onOpen: (id: string, name: string) => set({ isOpen: true, id, name }),
    onClose: () => set({ isOpen: false, id: undefined , name: undefined}),
}));