import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

import image_career from "../../../img/landing/main_landing/career.svg";
import image_techwatch from "../../../img/landing/main_landing/techwatch.svg";
import image_contacts from "../../../img/landing/main_landing/contacts.svg";
import image_community from "../../../img/landing/main_landing/community.svg";
import image_tasks from "../../../img/landing/main_landing/tasks.svg";
import image_visibility from "../../../img/landing/main_landing/visibility.svg";
import image_jobs from "../../../img/landing/main_landing/jobs.svg";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface FeatureProps {
  title: string;
  description: string;
  image: string;
  link?: string;
  soon?: boolean;
}


export const Features = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const features: FeatureProps[] = [
        {
          title: t("landing.features.career_title"),
          description: t("landing.features.career_description"),
          image: image_career,
          link:'/productCareer',
          soon: false,
        },
        {
            title: t("landing.features.contacts_title"),
            description: t("landing.features.contacts_description"),
            image: image_contacts,
            link:'/productContacts',
            soon: false,
        },
        {
          title: t("landing.features.techwatch_title"),
          description: t("landing.features.techwatch_description"),
          image: image_techwatch,
          link:'/productTechwatch',
          soon: false,
        },
        {
          title: t("landing.features.community_title"),
          description: t("landing.features.community_description"),
          image: image_community,
          link:'/productCommunity',
          soon: false,
        },
        {
            title: t("landing.features.tasks_title"),
            description: t("landing.features.tasks_description"),
            image: image_tasks,
            link:'/productTasks',
            soon: false,
        },
        {
            title: t("landing.features.visibility_title"),
            description: t("landing.features.visibility_description"),
            image: image_visibility,
            link:'/productVisibility',
            soon: true,
        },
        {
            title: t("landing.features.jobs_title"),
            description: t("landing.features.jobs_description"),
            image: image_jobs,
            soon: true,
        },
    ];
      
    const featureList: string[] = [
        "AI-Driven", "Customizable", "Productivity", "Career Planning", "Networking", "Insightful", "Privacy Focused", "Opportunity Matching"
    ];

  return (
    <section
      id="features"
      className="container pt-6 pb-24 sm:pt-6 md:pb-32 space-y-8"
    >
      <h2 className="text-3xl lg:text-4xl font-bold md:text-center">
        {t("landing.features.title_1")}
        {" "}
        <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
            {t("landing.features.title_2")}
        </span>
      </h2>

      <div className="flex flex-wrap md:justify-center gap-4">
        {featureList.map((feature: string) => (
          <div key={feature}>
            <Badge
              variant="secondary"
              className="text-sm"
            >
              {feature}
            </Badge>
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map(({ title, description, image, link, soon }: FeatureProps) => (
          <Card key={title}>
            <CardHeader>
              <CardTitle className="items-center gap-x-2">
                <span>{title}</span>
                {soon && soon === true && (
                    <Badge
                        variant="secondary"
                        className="text-sm text-primary"
                    >
                        {t("landing.features.soon")}
                    </Badge>
                )}
              </CardTitle>
            </CardHeader>

            <CardContent>
                {description}
                <br/>
                {link && (
                    <div className="font-semibold italic text-neutral_blue hover:cursor-pointer" onClick={() => {navigate(link)}}>
                        {t("landing.features.seeMore")}
                    </div>
                )}
            </CardContent>

            <CardFooter>
              <img
                src={image}
                alt="About feature"
                className="w-[200px] lg:w-[300px] mx-auto"
              />
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
