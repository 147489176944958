import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { cn } from "../../../../../lib/utils";

import { Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";
import { useBoards } from "../../../../../hooks/tasks-store";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"
import { fetchDeleteOpportunityTask } from "../../../../../api/contacts/opportunities/delete-opportunity-action";

import { CalendarCheck, CalendarDays, CalendarIcon, CalendarRange, ChevronsLeftRightEllipsis, DatabaseZap, DollarSign, Landmark, NotebookText, Pencil, PiggyBank, Plus, Shield, Square, SquareCheck, StickyNote, Trash, Trash2 } from "lucide-react";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import OppyNewActionsNewUnit from "./OppyActionsNewUnit";
import { Button } from "../../../../../components/ui/button";


interface OppyActionsUnitProps {
    data: Opportunity;

}

const OppyActionsUnit = ({
    data,
}: OppyActionsUnitProps) => {

    const { t } = useTranslation();
    
    const [isEditing, setIsEditing] = useState(false);
    const [isNewAction, setIsNewAction] = useState<boolean>(false);
    const [isEditingAction, setIsEditingAction] = useState<string | boolean>(false);

    // Save changes
    const { createUpdateOpportunity } = useOpportunityStoreActions();
    
    const { execute: executeUpdateOpportunity } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            //setDescription(data.description);
            //setIsEditing(false);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity");
        }
    });

    const handleCheckAction = (actionId: string, actionBoardId: string, check: boolean) => {
        executeUpdateOpportunity({
            id: data.id ?? "",
            action_to_update: {
                id: actionId,
                board: actionBoardId,
                status: check ? "DONE" : "TO_DO",
            }
        });
    };

    // Expand title logic
    const [expandedActionId, setExpandedActionId] = useState<string | null>(null);
    const toggleExpand = (actionId: string) => {
        setExpandedActionId(expandedActionId === actionId ? null : actionId);
    };

    // Edit action logic
    const toogleEditActions = (actionId: string) => {
        setIsEditingAction(actionId);
    };

    // Delete action logic
    const toggleDeleteAction = ( actionId: string) => {
        if (data.id && actionId) {
            executeDeleteOpportunityTask({
                opportunity_id: data.id,
                task_id: actionId,
            });
        }
    };

    const { execute: executeDeleteOpportunityTask } = useAction(fetchDeleteOpportunityTask, {
        onSuccess: (data: Opportunity) => {
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    return ( 
        <div id="actions" className="flex flex-col items-start text-sm p-1 w-full h-full gap-y-1">
            <div className="flex flex-row justify-start gap-x-1 w-full">
                <div className="flex flex-row items-center gap-x-1 pb-2 font-medium whitespace-nowrap">
                    <DirectionsRunIcon className="w-4 h-4" />
                    <span>{t("contacts.opportunities.nextTasks.nextTasks")}</span>
                </div>
                <Button 
                    variant="contacts"
                    className="flex h-5 w-5 m-0 p-0 text-sm"
                    onClick={() => {
                        setIsEditingAction(true);
                    }}
                >
                    <Plus className="h-4 w-4" />
                </Button>
            </div>
            

            {isEditingAction ? (
                <OppyNewActionsNewUnit 
                    data={data} 
                    //isNewAction={isNewAction}
                    //setIsNewAction={setIsNewAction}
                    isEditingAction={isEditingAction}
                    setIsEditingAction={setIsEditingAction}
                />
            ) : (
                // We display the actions
                // to do : button to edit with the id of the action
                data.actions ? data.actions.map((action: any) => (
                        <div className="flex flex-row items-start text-sm gap-x-2 w-full">
                            <div 
                                className="pt-0.5 cursor-pointer"
                                onClick={() => handleCheckAction(action.id, action.board, action.status !== "DONE")}
                            >
                                {action.status === "DONE" ? <SquareCheck className="h-4 w-4" /> : <Square className="h-4 w-4" />}
                            </div>
                            <div className="flex-grow"> 
                                <span 
                                    className={cn("flex overflow-hidden",
                                        expandedActionId === action.id ? "" : "line-clamp-2",
                                        action.status === "DONE" && "line-through",
                                    )}
                                >
                                    {action.title}
                                </span>
                                {action.title.length > 80 && expandedActionId !== action.id && (
                                    <button
                                        onClick={() => toggleExpand(action.id)}
                                        className="text-contacts_primary text-xs ml-1 inline"
                                    >
                                        ... See more
                                    </button>
                                )}
                                {expandedActionId === action.id && (
                                    <button
                                        onClick={() => toggleExpand(action.id)}
                                        className="text-contacts_primary text-xs ml-1 inline"
                                    >
                                        See less
                                    </button>
                                )}
                            </div>
                            <div className="flex flew-row gap-x-1">
                                <button
                                    // Edit task
                                    onClick={() => toogleEditActions(action.id)}
                                    className="text-contacts_primary text-xs inline"
                                >
                                    <Pencil className="h-4 w-4" />
                                </button>
                                <button
                                    // Delete task
                                    onClick={() => toggleDeleteAction(action.id)}
                                    className="text-contacts_primary text-xs inline"
                                >
                                    <Trash2 className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                )) : (
                    //if there are no action in the 
                    <div className="italic">
                        {t("contacts.opportunities.nextTasks.plus")}
                    </div>
                )
            )}
            
            
        </div>
     );
}
 
export default OppyActionsUnit;