import { Card, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { TimelineIcon, TldrIcon, CareerTasksIcon } from "./Icons";
import img_timeline_light from "../../../img/landing/main_landing/focus_timeline_light.png";
import img_timeline_dark from "../../../img/landing/main_landing/focus_timeline_dark.png";
import img_career_light from "../../../img/landing/main_landing/hero_career_light.png";
import img_career_dark from "../../../img/landing/main_landing/hero_career_dark.png";
import img_feed_light from "../../../img/landing/main_landing/focus_feed_light.png";
import img_feed_dark from "../../../img/landing/main_landing/focus_feed_dark.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTheme } from "../../../components/theme-provider/theme-provider";

interface ServiceProps {
  title: string;
  description: string;
  icon: JSX.Element;
  img: string;
}

export const Services = () => {

    const { theme } = useTheme();
    const { t } = useTranslation();
    // État pour gérer l'image sélectionnée
    const [serviceList, setServiceList] = useState<ServiceProps[]>([]);
    const [selectedImage, setSelectedImage] = useState(img_timeline_light);

    useEffect(() => {
      const updatedServiceList: ServiceProps[] = [
        {
          title: t("landing.focus.feature1_title"),
          description: t("landing.focus.feature1_description"),
          icon: <TimelineIcon />,
          img: theme === 'dark' ? img_timeline_dark : img_timeline_light,
        },
        {
          title: t("landing.focus.feature2_title"),
          description: t("landing.focus.feature2_description"),
          icon: <TldrIcon />,
          img: theme === 'dark' ? img_feed_dark : img_feed_light,
          //img: img_tldr, // L'image reste la même quel que soit le thème
        },
        {
          title: t("landing.focus.feature3_title"),
          description: t("landing.focus.feature3_description"),
          icon: <CareerTasksIcon />,
          img: theme === 'dark' ? img_career_dark : img_career_light,
          //img: img_career, // L'image reste la même quel que soit le thème
        },
      ];
  
      setServiceList(updatedServiceList);
  
      // Si l'image sélectionnée change avec le thème, on la met à jour
      setSelectedImage(theme === 'dark' ? img_timeline_dark : img_timeline_light);
    }, [theme]); // Dépendances : changement de thème et de la traduction

    

  return (
    <section className="container py-24 sm:py-32">
      <div className="grid lg:grid-cols-[1fr,1fr] gap-8 place-items-center">
        <div>
          <h2 className="text-3xl md:text-4xl font-bold">
            <span className="bg-gradient-to-b from-neutral_blue to-[#187DB7] text-transparent bg-clip-text">
                {t("landing.focus.title")}{" "}
            </span>
            {t("landing.focus.title_2")}
          </h2>

          <p className="text-muted-foreground text-xl mt-4 mb-8 ">
            {t("landing.focus.description")}
          </p>

          <div className="flex flex-col gap-8">
            {serviceList.map(({ icon, title, description, img }: ServiceProps) => (
              <Card 
                key={title}
                onClick={() => setSelectedImage(img)}
                className="hover:cursor-pointer"
              >
                <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
                  <div className="mt-1 p-1 rounded-2xl">
                    {icon}
                  </div>
                  <div>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription className="text-md mt-2">
                      {description}
                    </CardDescription>
                  </div>
                </CardHeader>
              </Card>
            ))}
          </div>
        </div>

        <img
          src={selectedImage}
          className="w-[300px] md:w-[500px] lg:w-[600px] object-contain"
          alt="About services"
        />
      </div>
    </section>
  );
};
