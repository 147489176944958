import { useEffect, useState } from "react";

import { 
  ColumnDef, 
  SortingFn, // additionals imports for fuzzy matching on all columns
  sortingFns // additionals imports for fuzzy matching on all columns
} from "@tanstack/react-table"
import { compareItems } from "@tanstack/match-sorter-utils";

import { ArrowUpDown, Contact, MoreHorizontal } from "lucide-react";
 
import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Badge } from "../../../../components/ui/badge";

import { ContactGroup, ContactInterest, ContactTableElement, Group } from "../../../../hooks/contact-store";
import ContactTableActionCell from "./_components/ContactTableActionCell";
import { useTranslation } from "react-i18next";


export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

  //export const columns: ColumnDef<ContactTableElement>[] = [
  export const useColumns = (): ColumnDef<ContactTableElement>[] => {
    
    const { t } = useTranslation();

    return [
   /*{
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
            className="justify-center"
          />
        ),
        cell: ({ row }) => {
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
              />
            </div>
          )
        },
        enableSorting: false,
        enableHiding: false,
    }*/
    {
        accessorKey: "first_name",
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("contacts.contactsTable.header.firstName")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
    },
    {
        accessorKey: "last_name",
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("contacts.contactsTable.header.lastNme")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
    },
    {
        accessorKey: "job_title",
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("contacts.contactsTable.header.job")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
    },
    {
        accessorKey: "company_name",
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("contacts.contactsTable.header.company")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        cell: ({ row }) => {
          return (
            <span>{row.original.company?.name}</span>
          )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
    },
    {
        accessorKey: "location",
        header: ({ column }) => {
            return (
              <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
              >
                {t("contacts.contactsTable.header.location")}
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
    },
    {
      accessorKey: "con_contacts_groups",
      id: "con_contacts_groups",
      accessorFn: (row) => {
        // Convert group names into a string array to be used by the filter and sort functions
        return row.con_contacts_groups.map((group: ContactGroup) => group.con_groups.name).join(' ');
      },
      header: ({ column }) => {
        return (
          <div className="h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors">
            {t("contacts.contactsTable.header.groups")}
          </div>
        )
      },
      cell: ({ row }) => {
        const groupsLength = row.original.con_contacts_groups.length;
  
        if (groupsLength > 3) {
          return (
            <div>
              {row.original.con_contacts_groups.slice(0,3).map((groups: ContactGroup) => (
                <Badge key={row.id + groups.con_groups.id} variant="outline">{groups.con_groups.name}</Badge>
              ))}
              <Badge key={row.id + "group_+"} variant="outline">+{groupsLength - 3}</Badge>
            </div>
          )
        } else {
          return (
            row.original.con_contacts_groups.map((groups: ContactGroup) => (
              <Badge key={row.id + groups.con_groups.id} variant="outline">{groups.con_groups.name}</Badge>
            ))
          )
        }
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
    },
    {
        accessorKey: "interests",
        id: "interests",
        accessorFn: (row) => {
          // Convert group names into a string array to be used by the filter and sort functions
          return row.con_contacts_interests.map((interest: ContactInterest) => interest.con_interests.name).join(' ');
        },
        header: ({ column }) => {
          return (
            <div className="h-10 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors">
              {t("contacts.contactsTable.header.interests")}
            </div>
          )
        },
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        cell: ({ row }) => {
          const interestsLength = row.original.con_contacts_interests.length;
    
          if (interestsLength > 3) {
            return (
              <div>
                {row.original.con_contacts_interests.slice(0,3).map((interests: ContactInterest) => (
                  <Badge key={row.id + interests.con_interests.id} variant="outline">{interests.con_interests.name}</Badge>
                ))}
                <Badge key={row.id + "interests_+"} variant="outline">+{interestsLength - 3}</Badge>
              </div>
            )
          } else {
            return (
              row.original.con_contacts_interests.map((interests: ContactInterest) => (
                <Badge key={row.id + interests.con_interests.id} variant="outline">{interests.con_interests.name}</Badge>
              ))
            )
          }
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
          const contact:ContactTableElement  = row.original
     
          return (
            <ContactTableActionCell contact={contact} />
          )
        },
        enableSorting: false,
        enableHiding: false,
    },
  ];
};