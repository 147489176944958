import ReactFlow, {
    Node as ReactFlowNode,
    Edge,
  } from 'reactflow';

import { MarkerType } from "reactflow";

import { NodeCategory, NodeType, Plan, PlanElement, nodeCategory_dict } from "../../../../api/career/interface-career";
import { sortElementsByCategory } from './sortNodes';

const categoryPlanElements: PlanElement[] = [
    {
        id: "1",
        plan_id: "",
        parent_elt: "",
        title: "Missions & Projects",
        description: "MISSION",
        //previous_elt: "",
        //next_elt: "",
        category: "MISSION",
        type: "CATEGORY",
        status: "DONE",
        year: 0,
        key_results: [],
        deadline_date: new Date(),
        start_date: new Date(),
        end_date: new Date(),
        deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
    },
    {
        id: "2",
        plan_id: "",
        parent_elt: "",
        title: "Network",
        description: "GOCHAT",
        //previous_elt: "",
        //next_elt: "",
        category: "GOCHAT",
        type: "CATEGORY",
        status: "DONE",
        year: 0,
        key_results: [],
        deadline_date: new Date(),
        start_date: new Date(),
        end_date: new Date(),
        deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
    },
    {
        id: "3",
        plan_id: "",
        parent_elt: "",
        title: "Training & certifications",
        description: "GOLEARN",
        //previous_elt: "",
        //next_elt: "",
        category: "GOLEARN",
        type: "CATEGORY",
        status: "DONE",
        year: 0,
        key_results: [],
        deadline_date: new Date(),
        start_date: new Date(),
        end_date: new Date(),
        deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
    },
    {
        id: "4",
        plan_id: "",
        parent_elt: "",
        title: "Techwatch",
        description: "TECHWATCH",
        //previous_elt: "",
        //next_elt: "",
        category: "TECHWATCH",
        type: "CATEGORY",
        status: "DONE",
        year: 0,
        key_results: [],
        deadline_date: new Date(),
        start_date: new Date(),
        end_date: new Date(),
        deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
    },
    {
        id: "5",
        plan_id: "",
        parent_elt: "",
        title: "Visibility",
        description: "VISIBILITY",
        //previous_elt: "",
        //next_elt: "",
        category: "VISIBILITY",
        type: "CATEGORY",
        status: "DONE",
        year: 0,
        key_results: [],
        deadline_date: new Date(),
        start_date: new Date(),
        end_date: new Date(),
        deleted: false,
        created_at: new Date(),
        updated_at: new Date(),
    }
];


export function createNodes(planElements: PlanElement[]): ReactFlowNode[] {
    //console.log('planElements - hitting build nodes : ', planElements);
    const createdNodes: ReactFlowNode[] = [];
    //console.log('planElements - hitting build nodes : ', planElements);

    //planElements = planElements

    // sort the elements b categories and next_elt to next_elt to build the nodes in the good order
    const sortedPlanElements: PlanElement[] = sortElementsByCategory(planElements).concat(categoryPlanElements);

    //console.log('planElements - hitting build nodes : ', planElements);

    sortedPlanElements.forEach((element, index) => {
    //for (const [index, element] of transformedJson.entries()) {
        createdNodes.push({
            id: element.id,
            //type: 'group',
            type: element.type === "STEP" ? 'customStepNode' : element.type === "CATEGORY" ? 'customCategoryNode' : 'customElementNode',
            data: {
                id: element.id,
                year: element.year,
                type: element.type as NodeType,
                category: element.category as NodeCategory,
                title: element.title,
                description: element.description,
                label: element.title,
                status: element.status,
                plan_id: element.plan_id,
                parent_elt: element.parent_elt,
                deadline_date: element.deadline_date,
                start_date: element.start_date,
                end_date: element.end_date,
                created_at: element.created_at,
                updated_at: element.updated_at,
                previous_elt: element.previous_elt,
                next_elt: element.next_elt,
            },
            position: { x: 0, y: 0 },
            draggable: false,
        });
    });

    //console.log('Created nodes all : ', createdNodes);

    return createdNodes;
}


export function createEdges(createdNodes: ReactFlowNode[]): Edge[] {

    // List of node ids to check existence before creating an edge
    const nodeMap = new Map(createdNodes.map(node => [node.id, node]));
    //console.log('nodeMap : ', nodeMap);

    let createdEdges: Edge[] = [];
    
    //console.log('createdNode T2 : ', nodeMap);

    createdEdges = createdNodes
        .map((element, index, array) => {
            const { data } = element;
            const { id, type, next_elt } = data;
            
            if (next_elt == null || !nodeMap.has(next_elt)) {
                // Skip edge creation if next_elt is null or target node doesn't exist
                return undefined;
            }
          
            if (type === "PROJECT") {

                const targetNode = nodeMap.get(next_elt);
                const targetData = targetNode?.data;

                // Initialize label as undefined
                let label: string | undefined;

                // Check if the years are different
                if (data.year !== targetData.year && (data.category || targetData.category)) {
                    // Years are different, set label to the category
                    const categoryKey: NodeCategory = data.category || targetData.category || 'NONE';
                    label = nodeCategory_dict[categoryKey];
                }

                return {
                    id: `e-${element.id}-${element.data.next_elt}`,
                    source: element.id,
                    target: next_elt,
                    sourceHandle: 'classic-node-handle-source',
                    targetHandle: 'classic-node-handle-target',
                    style: {
                        strokeWidth: 2,
                        stroke: '#78716C',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: '#78716C',
                    },
                    // Conditionally add label if it's defined
                    ...(label && {
                        //label,
                        /*
                        labelStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            fill: '#FFFFFF',
                            whiteSpace: 'pre-wrap', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            //textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            //maxWidth: '20px' // Adjust the width as needed
                        },
                        labelBgPadding: [20, 10],
                        labelBgStyle: {
                            fill: '#E4707F',
                            borderRadius: 0,
                        },
                        */
                        data: {
                            startLabel: 'Start',
                            endLabel: label,
                        },
                        type: 'customEdgeCategories',
                    }),
                }
            } 
        }).filter(edge => edge !== undefined) as Edge[];

        //console.log('createdEdges : ', createdEdges);

        //console.log('createdEdge : ', createdEdges.filter((edge) => (edge.source === "ade3d268-aa5a-424f-befb-87c647caec17" || edge.target === "ade3d268-aa5a-424f-befb-87c647caec17")));


    return createdEdges;
}