    import { createStore, useStore } from "zustand";
    import { devtools } from "zustand/middleware";
    import { useStoreWithEqualityFn } from "zustand/traditional";
    import { CreationPlan, ExistingPlan, FirstPromptAPI, Plan, PlanElement } from "../api/career/interface-career";

    interface CareerHook {

        plans: CreationPlan[] | undefined;
        plan: Plan | undefined;
        creationPlan: CreationPlan | undefined;
        creatingPlan: boolean;
        planElements: PlanElement[] | undefined;
        selectedPlan: string | undefined;
        selectedPath: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER" | undefined;
        stepCreation: number | undefined;
        
        actions: {
            initPlans: (plans: CreationPlan[]) => void;
            initPathPlan: (plan: Plan) => void;
            updatePlan: (plan: CreationPlan) => void;
            setCreationPlan : (creationPlan: CreationPlan | undefined) => void;
            updateCreationPlan : (creationPlan: CreationPlan) => void;
            //deleteCreationPlan
            setPlanElements: (planElements: PlanElement[] | undefined) => void;
            deletePlan: (planId: string) => void;
            getPlanById: (planId: string) => CreationPlan | undefined;
            getPlanByPath: (path: string) => CreationPlan | undefined;
            getPlanTitle: (planId: string) => string;
            getPlanPath: (planId: string) => string;
            getSelectedPlan: () => string | undefined;
            setSelectedPlan: (planId: string | undefined) => void;
            setSelectedPath: (selectedPath:  "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER" | undefined) => void;
            setCreatingPlan: (creatingPlan: boolean) => void;
            setStepCreation: (stepCreation: number | undefined) => void;
            getPlanElementsMainSteps: () => PlanElement[] | undefined;
            getPlanElement: (planElementId: string) => PlanElement | undefined;
            updatePlanElementHook: (planElement: PlanElement) => void;
            updateMultiplePlanElementHook: (planElements: PlanElement[]) => void;
            deletePlanElementHook: (planElements: PlanElement[]) => void;
            clearCareerHook: () => void;
        }
    }

    const careerHook = createStore<CareerHook>()(
        devtools(
            (set, get) => ({
                plans: undefined,
                plan: undefined,
                creationPlan: undefined,
                mainSteps: undefined, // TD DO : find out why we need this
                planElements: undefined,
                selectedPlan: undefined,
                selectedPath: undefined,
                creatingPlan: false,
                stepCreation: undefined,
                
                actions: {
                    initPlans: (plans: CreationPlan[]) => {
                        set({
                            plans
                        });
                    },
                    initPathPlan: (plan: Plan) => {
                        set({
                            plan
                        });
                    },
                    getPlanById: (planId: string) => {
                        const plans = get().plans;
                        return plans?.find(plan => plan.id === planId);
                    },
                    getPlanByPath: (path: string)=> {
                         const plans=get().plans;
                         return plans?.find(plan=>plan.path===path);
                    },
                    setCreationPlan: (creationPlan: CreationPlan | undefined) => {
                        set({
                            creationPlan,
                        });
                    },
                    updateCreationPlan: (creationPlanUpdated: CreationPlan) => {
                        const lastCreationPlan = get().creationPlan;
                        set({
                            creationPlan: { ...lastCreationPlan, ...creationPlanUpdated}
                        });
                    },
                    setCreatingPlan: (creatingPlan: boolean) => {
                        set({ creatingPlan });
                    },
                    setStepCreation: (stepCreation: number | undefined) => {
                        set({ stepCreation });
                    },
                    updatePlan: (plan: CreationPlan) => {
                        // to be implemented
                        
                        const plansToUpdate = get().plans || [];
                        if (!plansToUpdate) {
                            return; // no plans to update
                        }
                        const index = plansToUpdate?.findIndex((f) => f.id === plan.id);
                        if (index !== -1) {
                            plansToUpdate[index] = plan;
                            set({
                                plans: plansToUpdate,
                            });
                        }
                    },
                    setPlanElements: (planElements: PlanElement[] | undefined) => {
                        set({
                            planElements
                        });
                    },
                    deletePlan: (planId: string) => {
                        const planToDelete = get().plans || [];
                        const updatedPlans = planToDelete.filter((plan) => plan.id !== planId);
                        set({ plans: updatedPlans});
                    },
                    getPlanTitle: (planId: string) => {
                        const searchPlan = get().plans?.find(plan => plan.id === planId);
                        return searchPlan?.title || '';
                    },
                    getPlanPath: (planId: string) => {
                        const searchPlan = get().plans?.find(plan => plan.id === planId);
                        return searchPlan?.path || '';
                    },
                    getSelectedPlan: () => {
                        return get().selectedPlan;
                    },
                    setSelectedPlan: (planId: string|undefined)=>{
                        if(!planId){
                            set({
                                selectedPlan: undefined,
                            })
                        } else {
                            set({
                              selectedPlan: planId,
                           });
                        }
                    },
                    setSelectedPath: (selectedPath :  "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER" | undefined) => {
                        set({
                            selectedPath,
                        });
                    },
                    getPlanElementsMainSteps: () => {
                        const planElements = get().planElements;
                        return planElements?.filter((element) => element.category === "MAIN")
                    },
                    getPlanElement: (planElementId: string) => {
                        const plan_Elements = get().planElements;
                        return plan_Elements?.find(planElement => planElement.id === planElementId);
                    },
                    updatePlanElementHook: (planElement: PlanElement) => {
                        //console.log('hitting planElement - hook : ', planElement);
                        const planElements = get().planElements;
                        if (!planElements) {
                            return;
                        }
                        const planElementToUpdate = planElements?.findIndex((f) => f.id === planElement.id);
                        if (planElementToUpdate !== -1) {
                            const newPlanElements = [...planElements];
                            newPlanElements[planElementToUpdate] = planElement;
                            set({ planElements: newPlanElements });
                        } else {
                            const newPlanElements = [...planElements, planElement];
                            set({ planElements: newPlanElements });
                        }
                        //console.log('planElement - hook : ', planElements);
                    },
                    updateMultiplePlanElementHook: (planElements: PlanElement[]) => {
                        //console.log('hitting planElement multiple updates - hook : ', planElements);
                        const existingplanElements = get().planElements || [];
                        const updatedPlanElements = [...existingplanElements];
                        planElements.forEach((planElement) => {
                            const planElementToUpdate = updatedPlanElements.findIndex((f) => f.id === planElement.id);
                            if (planElementToUpdate !== -1) {
                                updatedPlanElements[planElementToUpdate] = planElement;
                                //console.log('planElement - hook : updated element : ', updatedPlanElements);
                            } else {
                                updatedPlanElements.push(planElement);
                            }
                        });
                        set({ planElements: updatedPlanElements });
                    },
                    deletePlanElementHook: (planElements: PlanElement[]) => {
                        const existingPlanElements = get().planElements || [];
                        const elementToDelete = planElements[0];
                        const updatedPlanElements = existingPlanElements.filter((existingPlanElement) => existingPlanElement.id !== elementToDelete.id);

                        //replicating the same logic for multiple elements instead of calling it to avoid updating the state multiple times and causing multiple re-renders
                        planElements.slice(1).forEach((planElement) => {
                            const planElementToUpdate = updatedPlanElements.findIndex((f) => f.id === planElement.id);
                            if (planElementToUpdate !== -1) {
                                updatedPlanElements[planElementToUpdate] = planElement;
                                //console.log('planElement - hook : updated element : ', updatedPlanElements);
                            } else {
                                updatedPlanElements.push(planElement);
                            }
                        });
                        set({ planElements: updatedPlanElements});
                    },
                    clearCareerHook: () => {
                        set({
                            plans: undefined,
                            plan: undefined,
                            creationPlan: undefined,
                            //mainSteps: undefined, // TD DO : find out why we need this
                            planElements: undefined,
                            selectedPlan: undefined,
                            selectedPath: undefined,
                            creatingPlan: false,
                            stepCreation: undefined,
                        });
                    },
                },
            }),
            {
                name: 'career-hook',
                enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
            }
        )
    )

    /**
     * Required for zustand stores, as the lib doesn't expose this type
     */
    export type ExtractState<S> = S extends {
        getState: () => infer T;
    }
    ? T
    : never;

    type Params<U> = Parameters<typeof useStore<typeof careerHook, U>>;

    // Selectors
    const actionsSelector = (state: ExtractState<typeof careerHook>) => state.actions;
    const plansSelector = (state: ExtractState<typeof careerHook>) => state.plans;
    const planPathSelector = (state: ExtractState<typeof careerHook>) => state.plan;
    const selectedPlanSelector = (state: ExtractState<typeof careerHook>) => state.selectedPlan;
    const selectedPathSelector= (state: ExtractState<typeof careerHook>) => state.selectedPath;
    const creationPlanSelector = (state: ExtractState<typeof careerHook>) => state.creationPlan;
    const creatingPlanSelector = (state: ExtractState<typeof careerHook>) => state.creatingPlan;
    const stepCreationSelector = (state: ExtractState<typeof careerHook>) => state.stepCreation;
    const planElementsSelector = (state: ExtractState<typeof careerHook>) => state.planElements;

    // getters
    export const getContactStoreActions = () => actionsSelector(careerHook.getState()); // TD DO : change to career
    export const getPlans = () => plansSelector(careerHook.getState());
    export const getPlan = () => planPathSelector(careerHook.getState());
    export const getSelectedPlan = () => selectedPlanSelector(careerHook.getState());
    export const getSelectedPath = () => selectedPathSelector(careerHook.getState());
    export const getCreationPlan = () => creationPlanSelector(careerHook.getState());
    export const getCreatingPlan = () => creatingPlanSelector(careerHook.getState());
    export const getStepCreation = () => stepCreationSelector(careerHook.getState());
    export const getPlanElements = () => planElementsSelector(careerHook.getState());

    function useCareerHook<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
        return useStoreWithEqualityFn(careerHook, selector, equalityFn);
    }

    // Hooks
    export const useCareerHookActions = () => useCareerHook(actionsSelector);
    export const usePlans = () => useCareerHook(plansSelector);
    export const usePlanPath = () => useCareerHook(planPathSelector);
    export const useSelectedPlan = () => useCareerHook(selectedPlanSelector);
    export const useSelectedPath = () => useCareerHook(selectedPathSelector);
    export const useCreationPlan = () => useCareerHook(creationPlanSelector);
    export const useCreatingPlan = () => useCareerHook(creatingPlanSelector);
    export const useStepCreation = () => useCareerHook(stepCreationSelector);
    export const usePlanElements = () => useCareerHook(planElementsSelector);
