import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import GoogleSignIn from "./_components/googleSignIn";
import EmailSignIn from "./_components/emailSignIn";
import Logout from "./_components/logout";

import SeparatorWithText from "../../components/SeparatorWithText";

//import { useStore } from "../../hooks/auth-store";
import { useActions, useIsAuthenticated, useSignUpStep } from "../../hooks/auth-store";

import signInImg from '../../img/Sign-in.svg'
import { use } from "i18next";
import ForgottenPassword from "./_components/forgottenPassword";
import Error404 from "../../Error";

//import * as CheckboxPrimitive from '@radix-ui/react-checkbox';


const SignIn = () => {

    const isAuthenticated = useIsAuthenticated();

    const signUpStep = useSignUpStep();
    const { setSignUpStep } = useActions();

    useEffect(() => {

        setSignUpStep('signinForm');
        return () => {
            setSignUpStep('');
        }
    }, []);

    //console.log('signUpStep : ', signUpStep);

    let navigate = useNavigate();

    if (isAuthenticated) {
        return <Navigate to="/techwatch" />;
    }


    return ( 
        <div className="flex flex-col md:flex-row min-h-screen flex-grow bg-[#F8F5F5] dark:bg-[#1E1F24]">
            {/* Top bar for small screens */}
            <div className="md:hidden bg-gradient-to-r from-[#F8F5F5] to-[#55AA98] dark:from-[#1E1F24] dark:to-[#78CDB9] p-4 text-white flex justify-between items-center">
                <h3 className="text-md font-bold text-neutral_grey-500">Join Us Today</h3>
                <button onClick={() => navigate(`/signup`)} className="bg-neutral_white-200 text-contacts_primary dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded">
                    Sign Up
                </button>
            </div>
            {/* Image Panel */}
            <div className="hidden md:flex w-full md:w-1/4 items-center justify-center bg-[#F8F5F5] dark:bg-[#1E1F24]">
                <img src={signInImg} alt='SignIn' className="w-full h-auto object-cover"/>
            </div>

            <div className="w-full md:w-1/2 md:mt-32 flex-col items-center justify-center p-6 bg-[#F8F5F5] dark:bg-[#1E1F24]">
                <h1 className="text-center text-2xl font-extrabold text-contacts_primary dark:text-[#78CDB9]">Welcome back !</h1>
                <h2 className="text-center text-xl font-semibold justify-between items-center mb-4 text-[#107362] dark:text-[#BCECDF]">Sign into your account</h2>
        
                {signUpStep === 'signinForm' ? (
                    <div>    
                        <EmailSignIn />
                        
                        {/*
                            <SeparatorWithText inputText="or" />
                            <GoogleSignIn />
                        */}
                    </div>

                ) : signUpStep === 'forgottenPassword' ? (
                    <ForgottenPassword />
                ) : (
                    <Error404 />
                )}
            
            </div>


            {/* Right Panel for Background and Information */}
            <div className="hidden md:flex w-full md:w-1/4 items-center justify-center bg-gradient-to-r from-[#F8F5F5] to-[#55AA98] dark:from-[#1E1F24] dark:to-[#78CDB9] p-10 text-white">
                <div className="max-w-md mx-auto">
                    <h2 className="text-xl font-bold mb-3">Join Us Today</h2>
                    <p className="mb-5 text-md">Create an account to get started with our services.</p>
                    <button onClick={() => navigate(`/signup`)} className="mt-4 bg-neutral_white-200 text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#172A26] dark:hover:text-[#BCECDF] font-bold py-2 px-4 rounded-lg shadow-lg">
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
}
 
export default SignIn;