import { X } from "lucide-react";

import { useEffect, useRef, useState } from "react";

import { Interest, getContactStoreActions, useInterests } from "../../../../../hooks/contact-store";
import { getContactsHookActions, useNewContact, useSelectedContact } from "../../../../../hooks/contact-hook";

import { fetchUserInterests, fetchAddInterestContact, fetchDeleteInterestContact, fetchaddMultipleInterestsContact } from "../../../../../api/contacts/api-contact-interest";
import { useToast } from "../../../../../components/ui/use-toast";
import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group"
import { Button } from "../../../../../components/ui/button";
import ComboboxContacts from "../../../../../components/Portal/ComboboxContacts";

const ContactInterests = () => {
    // initialize toast
    const { toast } = useToast();

//load data from the store
    //Retrieve selected contact from the store
    const selectedContact = useSelectedContact();
    //console.log("selected contact: ", selectedContact);

    // retrieve the step of the new contact if one
    const { setNewContact } = getContactsHookActions(); // to be deleted if not used
    const newContact = useNewContact();

    //load user interests
    const userInterests = useInterests();
    //load necessary functions for the groups from the store
    const { initInterests, updateInterests } = getContactStoreActions();
    //console.log("userGroups: ", userInterests);
    //load user groups from the database if they are not in the store
    if (!userInterests) {
        const getUserInterestsFromDB = async () => {
            try {
                const response = await fetchUserInterests();
                initInterests(response);
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        getUserInterestsFromDB();
    }
    
    //load contact groups
    const [contactInterests, setContactInterests] = useState<Interest[]>([]);
    //load necessary functions for the contact groups from the store
    const { getContactInterestsById, updateContactInterests } = getContactStoreActions();

    // handle toggle for adding groups to a new contact
    // Initialize state
    const [selectedInterestsnewContact, setSelectedInterestsnewContact] = useState<string[]>([]);
    
    useEffect(() => {
        if (selectedContact) {
            const interests = getContactInterestsById(selectedContact);
            setContactInterests(interests ?? []);

            // Set the interest IDs to the selectedInterestsnewContact state for toogling interests in UI
            const interestIds = interests?.map(interest => interest.id) ?? [];
            setSelectedInterestsnewContact(interestIds);
        }
        // Suppressing the ESLint warning about missing dependency since filterSuggestedGroups doesn’t rely on any state or props that change over time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContact]);
    

    // load state variables for the UX
    //const [showInput, setShowInput] = useState(false);
    //const [inputValue, setInputValue] = useState(''); //TODO : add type
    //const [suggestedInterests, setSuggestedInterests] = useState<Interest[]>([]);
    const [filteredInterests, setFilteredInterests] = useState<Interest[]>([]);
    const containerRef = useRef<HTMLDivElement>(null); //TODO : remember what it does


// suggest groups logic
    // function to filter suggested groups
    const filterSuggestedGroups = () => {
        if (!userInterests || !contactInterests) {
            return [];
        }
    
        // Filter out groups that are already in contactGroups
        return userInterests.filter(userInterest => 
            !contactInterests.some(contactInterest => 
                contactInterest.id === userInterest.id
            )
        );
    };

    //update suggested groups list
    useEffect(() => {
        const newSuggestedInterests = filterSuggestedGroups();
        //setSuggestedInterests(newSuggestedInterests);
        setFilteredInterests(newSuggestedInterests);
        // Suppressing the ESLint warning about missing dependency since filterSuggestedGroups doesn’t rely on any state or props that change over time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInterests, contactInterests]);

    //Group - input auto-complete
    /*
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if(e.target.value) {
            setFilteredInterests(
                suggestedInterests?.filter(interest => interest.name.toLowerCase().includes(e.target.value.toLowerCase())
                ) ?? []
            );
        } else {
            setFilteredInterests([]);
        }
    };
    */

    // Interest - Checking for outside click
    /*
    useEffect(() => {
        // Checking for outside click
        function handleOutsideClick(event: MouseEvent) {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowInput(false);
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    */

//Groups adding / deleting logic
    
    //handlers
    // function addGroup()
    function addInterest(interest: Interest, contactId: string) {
        //add group to the contact
        const addInterestContactToDB = async () => {
            try {
                const response = await fetchAddInterestContact(contactId, interest);
                //console.log("response: ", response);
                updateInterests(response.newInterest);
                updateContactInterests(contactId, response.newInterest, 'add');
                setContactInterests(getContactInterestsById(contactId) || []);
                if (newContact) {
                    setSelectedInterestsnewContact([...selectedInterestsnewContact, response.newInterest.id]);
                }
                //setShowInput(false);
                //setInputValue("");
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        addInterestContactToDB();


        return true;
    }
    // function removeGroup()
    function removeInterest(interest: Interest, contactId: string) {
        //remove group from the contact
        const deleteInterestContactToDB = async () => {
            try {
                const response = await fetchDeleteInterestContact(contactId, interest.id);
                //console.log("response: ", response);
                updateInterests(interest);
                updateContactInterests(contactId, interest, 'remove');
                setContactInterests(getContactInterestsById(contactId) || []);

            } catch (err) {
                //console.log("error: ", err);
            }
        };
        deleteInterestContactToDB();
    }

    // function addInterests() - for contact creation
    function addMultipleInterests(interests: string[], contactId: string, type: string) {
        //add group to the contact
        const addMultipleInterestsContactToDB = async () => {
            try {
                const response = await fetchaddMultipleInterestsContact(contactId, interests);
                //console.log("response: ", response);

                if (response.newContactInterests) {
                    response.newContactInterests.map(interest => {
                        updateContactInterests(contactId, interest, 'add');
                        setContactInterests(getContactInterestsById(contactId) || []);
                        if (type === "next") {
                            setNewContact("Informations");
                        } else if (type === "previous") {
                            setNewContact("Groups");
                        };
                        toast({
                            description: `Interests added to the contact`,
                          });
                    });
                }
            } catch (err) {
                //console.log("error: ", err);
            }
        };

        if (interests.length === 0) {
            if (type === "next") {
                setNewContact("Informations");
            } else if (type === "previous") {
                setNewContact("Groups");
            };
            return true;
        } else {
            addMultipleInterestsContactToDB();
            return true;
        }
    }

    //console.log("contactinterest:")
    
    return ( 
        <div className="px-4 xs:text-xs md:text-md">
                <div className="py-2">
                    {newContact ? (
                            <div>
                                <p>Adding interests for a new contact </p>
                                
                                <ToggleGroup value={selectedInterestsnewContact} onValueChange={((value) => setSelectedInterestsnewContact(value))} type="multiple">
                                        <div className="flex flex-wrap pt-2">
                                            {userInterests && userInterests.map((interest: Interest, index: number) => (
                                                <ToggleGroupItem 
                                                    key={interest.id} 
                                                    value={interest.id}
                                                    className="flex items-center m-1 px-2 py-1 rounded-full group hover:bg-contacts_secondary hover:text-white data-[state=on]:bg-contacts_primary bg-contacts_secondary bg-opacity-50"
                                                >
                                                    {interest.name}
                                                </ToggleGroupItem>
                                            ))
                                            }
                                        </div>
                                    </ToggleGroup>

                                    <ComboboxContacts 
                                        //showInput={showInput} 
                                        nature="interest" 
                                        selectedContact={selectedContact} 
                                        filteredValues={userInterests || []} 
                                        addInterest={addInterest}
                                        incomingPage="newContact"
                                    />

                                    <div className="flex justify-end gap-2 mt-2">
                                        <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base" onClick={() => addMultipleInterests(selectedInterestsnewContact, selectedContact || "", "previous")}>Previous</Button>
                                        <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base" onClick={() => addMultipleInterests(selectedInterestsnewContact, selectedContact || "", "next")}>Next</Button>
                                    </div>
                            </div>

                    ) : (
                        <div>
                            <div className="flex flex-wrap"> 
                                {contactInterests && contactInterests.map((interest: Interest, index: number) => (
                                    <div key={interest.id} className="flex items-center m-1 px-2 py-1 bg-[#AFD8CD] text-[#1A3C34] dark:bg-[#193831] dark:text-[#BCECDF] rounded-full group hover:bg-[#61B6A3] dark:hover:bg-[#437A6E]">
                                        <p className="flex align-middle text-xs md:text-sm">{interest.name}</p>
                                        <span 
                                            className="text-white cursor-pointer align-middle px-1" 
                                            onClick={() => removeInterest(interest, selectedContact || "")}
                                        >
                                            <X size={16} />
                                        </span>
                                    </div>
                                ))
                                }
                            </div>
                            
                            <ComboboxContacts 
                                //showInput={showInput} 
                                nature="interest" 
                                selectedContact={selectedContact} 
                                filteredValues={filteredInterests} 
                                addInterest={addInterest} />  
                        </div>
                    )}
                </div>
        </div>
     );
};
 
export default ContactInterests;