import axiosInstance from "../api";
import { ContactTableElement } from "../../hooks/contact-store";
import { DetailedContact } from "../../interfaces/contacts/int-contacts";

export const fetchDetailedContactData = async (contactId: string): Promise<DetailedContact> => {
      const response = await axiosInstance.get<DetailedContact>(`/contacts/detailsContactData/${contactId}`);
      return response.data;
    };

export const fetchAddContact = async (contactData: Partial<ContactTableElement>): Promise<{ newContact: Partial<ContactTableElement>}> => {
  //console.log('contactData', contactData);
  const response = await axiosInstance.post<{ newContact: Partial<ContactTableElement>}>('/contacts/addContact', {
    ...contactData,
  });
  return response.data;
};

export const fetchUpdateContactData = async (contactId: string, contactData: Partial<ContactTableElement>): Promise<Partial<ContactTableElement>> => {
  //console.log('contactData', contactData);
  const response = await axiosInstance.patch<Partial<ContactTableElement>>('/contacts/updateContact', {
    ...contactData,
  });
  return response.data;
};

export const fetchUpdateContactDetailsData = async (contactId: string, contactData: DetailedContact): Promise<DetailedContact> => {
  //console.log('contactData', contactData);
  const response = await axiosInstance.patch<DetailedContact>(`/contacts/updateContactDetails/${contactId}`, {
    ...contactData,
  });
  return response.data;
};


export const fetchDeleteContact = async (contactId: string): Promise<ContactTableElement> => {
    const response = await axiosInstance.delete<ContactTableElement>(`/contacts/deleteContact/${contactId}`);
    return response.data;
    };
