import img_current_path from '../../../../../img/career_plans/Moving forward-bro.svg';
import img_alternative_path from '../../../../../img/career_plans/Business mission-bro.svg';
import img_dream_path from '../../../../../img/career_plans/Restorer-bro.svg';
import img_other_path from '../../../../../img/career_plans/Design thinking-bro.svg';
import React from 'react';
import { Button } from '../../../../../components/ui/button';
import { useCareerHookActions } from '../../../../../hooks/career-hook';
import { Path } from "../../../../../api/career/interface-career";
import { Telescope, ScissorsLineDashed, Pencil, ArrowUpWideNarrow, Blocks } from 'lucide-react';
import { useTranslation } from "react-i18next";

const LandingOverview = () => {
  const { t } = useTranslation();
  const { setSelectedPath } = useCareerHookActions();

  interface FeatureProps {
    path: string,
    title: string;
    description: string;
    image: string;
    cta: string;
    reverse: boolean;
    color: string;
    dark_color: string;
  }

  interface FeatureItemProps {
    Icon: React.ElementType;
    title: string;
    description: string;
  }

  const features: FeatureProps[] = [
    {
      path: 'CURRENT',
      title: t("career.overviewComponents.current.title"),
      description: t("career.overviewComponents.current.description"),
      cta: t("career.overviewComponents.current.cta"),
      image: img_current_path,
      reverse: false,
      color: 'text-career_primary',
      dark_color: 'text-career_primary',
    },
    {
      path: 'ALTERNATIVE',
      title: t("career.overviewComponents.alternative.title"),
      description: t("career.overviewComponents.alternative.description"),
      cta: t("career.overviewComponents.alternative.cta"),
      image: img_alternative_path,
      reverse: true,
      color: 'text-[#6E0021]',
      dark_color: 'text-[#FBD5D7]',
    },
    {
      path: 'DREAM',
      title: t("career.overviewComponents.dream.title"),
      description: t("career.overviewComponents.dream.description"),
      cta: t("career.overviewComponents.dream.cta"),
      image: img_dream_path,
      reverse: false,
      color: 'text-[#D76474]',
      dark_color: 'text-[#D76474]',
    },
    {
      path: 'OTHER',
      title: t("career.overviewComponents.other.title"),
      description: t("career.overviewComponents.other.description"),
      cta: t("career.overviewComponents.other.cta"),
      image: img_other_path,
      reverse: true,
      color: 'text-[#AE3F51]',
      dark_color: 'text-[#FF95A0]',
    },
  ];

  const handleClick = (key: string) => {
    setSelectedPath(key as Path);
  };

  const Feature: React.FC<FeatureProps> = ({ path, title, description, image, cta, reverse, color, dark_color }) => {
    return (
      <div className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center gap-4 md:gap-8 p-4 md:p-8`}>
        <div className="flex justify-center md:flex-1">
          <img src={image} alt={title} className="w-full md:max-w-md h-auto mx-auto" />
        </div>
        <div className="flex-1 flex flex-col justify-center items-start text-left space-y-2 md:space-y-4">
          <h2 className={`text-lg md:text-xl font-semibold ${color} dark:${dark_color}`}>{title}</h2>
          <p className="text-sm md:text-base text-justify text-[#AE3F51] dark:text-[#FBD5D7]">{description}</p>
          <div className="flex">
            <Button 
              className={`bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4`} 
              onClick={() => handleClick(path)}
            >
              {cta}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const FeatureItem: React.FC<FeatureItemProps> = ({ Icon, title, description }) => (
    <div className="flex items-start space-x-2 md:space-x-4">
      <Icon className="hidden sm:block text-career_primary dark:text-[#FF95A0] w-8 h-8 md:w-12 md:h-12" />
      <div className="flex flex-col space-y-1">
        <p className="font-semibold text-career_primary dark:text-[#FF95A0] text-sm md:text-lg">{title}</p>
        <p className="bg-[#F9EFF0] dark:bg-sky-500/10 text-[#AE3F51] dark:text-[#FBD5D7] p-2 md:p-4 rounded text-xs md:text-base">{description}</p>
      </div>
    </div>
  );

  return (
    <div className="flex flex-grow flex-col h-full text-[#000021] antialiased">
      {/* Hero Section */}
      <div className="text-center p-4 md:p-6">
        <h1 className="text-lg md:text-xl font-bold dark:text-[#FF95A0] text-career_primary">{t("career.overviewLanding.top.title")}</h1>
        <p className="text-sm md:text-base mt-2 text-[#AE3F51] dark:text-[#FBD5D7]">
          {t("career.overviewLanding.top.description")}
        </p>
      </div>
      {/* Features Section */}
      <div className="p-4 md:p-8 space-y-4 md:space-y-6">
        <FeatureItem
          Icon={Telescope}
          title={t("career.overviewLanding.block1.title")}
          description={t("career.overviewLanding.block1.description")}
        />
        <FeatureItem
          Icon={ScissorsLineDashed}
          title={t("career.overviewLanding.block2.title")}
          description={t("career.overviewLanding.block2.description")}
        />
        <FeatureItem
          Icon={Pencil}
          title={t("career.overviewLanding.block3.title")}
          description={t("career.overviewLanding.block3.description")}
        />
        <FeatureItem
          Icon={ArrowUpWideNarrow}
          title={t("career.overviewLanding.block4.title")}
          description={t("career.overviewLanding.block4.description")}
        />
        <FeatureItem
          Icon={Blocks}
          title={t("career.overviewLanding.block5.title")}
          description={t("career.overviewLanding.block5.description")}
        />
      </div>
      {/* Dynamic Features Section */}
      <div className="p-4 md:p-8">
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default LandingOverview;
