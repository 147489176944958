import { z } from "zod";

export const CreateOpportunityStatus = z.object({
  //id: z.optional(z.string().uuid()),
  name: z.optional(z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  })),
  order: z.optional(z.number().nullable()),
});

