import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { useEffect, useState } from "react";
import { useCommunityActions, useSelectedServer } from "../../../../hooks/community-hook";
import { CreateChannelData, createChannel } from "../../../../api/community/api-channel";
import { useModal } from "./use-modal-store";
import { useTranslation } from 'react-i18next';

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Channel name is required."
  }).refine(
    name => name !== "general",
    {
      message: "Channel name cannot be 'general'"
    }
  )
});

export const CreateChannelModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type, data } = useModal();
    const isModalOpen = isOpen && type === "createChannel";

    const { setModifiedChannel } = useCommunityActions();
    const [newChannel, setNewChannel] = useState<CreateChannelData>();
    const selectedServer = useSelectedServer();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
          name: ''
        }
    });

    const isLoading = form.formState.isSubmitting;

    const handleCreateChannelModal = () => {
        setModifiedChannel(false);
        //setIsOpen(false);
    };

    interface FormData {
        name: string;
    }

    const onSubmit = async (data: FormData) => {
        if(selectedServer) {
            const payload = {
                name: data.name,
                server_id: selectedServer.id,
            };
            try {
                const response = await createChannel(payload);
                setNewChannel(response);
                handleCreateChannelModal();
                onClose();
                form.reset();
            } catch (err) {
                //console.log("error: ", err);
            }
        }
    };

    const handleClose = () => {
        form.reset();
        onClose();
      }

    useEffect(() => {
        isModalOpen && setModifiedChannel(true);

        return () => {
            setModifiedChannel(false);
        };
    }, [isModalOpen]);

      return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-[#F8F5F5] dark:bg-[#212121] text-black dark:text-white p-0 overflow-hidden">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-2xl text-center font-bold text-[#A5328E] dark:text-[#A6308F]">
                        {t(`community.modals.createChannel.title`)}
                    </DialogTitle>
                    <DialogDescription className="text-center text-[#5D184F] dark:text-[#F8D3ED]">
                        {t(`community.modals.createChannel.description`)}
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                        <div className="space-y-8 px-6">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel
                                            className="uppercase text-xs font-bold text-[#A5328E] dark:text-[#F98FDF]">
                                            {t(`community.modals.createChannel.field`)}
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                disabled={isLoading}
                                                className="bg-[#F4E2EE] dark:bg-[#1D101A] border-0 focus-visible:ring-0 text-black dark:text-white focus-visible:ring-offset-0 placeholder-gray-500 dark:placeholder-gray-300"
                                                placeholder="Enter channel name"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <DialogFooter className="px-6 py-4">
                            <div className="flex w-full justify-center">
                                <Button disabled={isLoading} type="submit"
                                    className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#F8D3ED] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                    {t(`community.modals.createChannel.confirm`)}
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
    
}