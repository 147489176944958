import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import io, { Socket } from 'socket.io-client';

interface WebSocketContextType {
  socket: Socket | null;
  joinRoom: (roomId: string) => void;
  leaveRoom: (roomId: string) => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);


  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {

    //console.log(`Attempting to connect to WebSocket at ${process.env.REACT_APP_BACKEND_ROOT}/messages`);

    //const launchSocket = async () => {

    const newSocket = io(`${process.env.REACT_APP_BACKEND_MESSAGES}`, {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      path: '/socket.io',
    });
    newSocket.on('connect', () => {
      //console.log(`WebSocket connected: ${newSocket.id}`);
    });
  
    newSocket.on('connect_error', (error) => {
      //console.error(`Connection error: ${error.message}`);
    });
  
    newSocket.on('disconnect', (reason) => {
      //console.log(`WebSocket disconnected: ${reason}`);
    });
    setSocket(newSocket);

    // Assign the socket to the ref
    socketRef.current = newSocket;

    //console.log("SOCKET ETAT:");
    //console.log(newSocket);
    
    return () => {
      newSocket.disconnect();
    };
    
  }, []);

  const joinRoom = (roomId: string) => {
    socket?.emit('joinRoom', { roomId });
  };

  const leaveRoom = (roomId: string) => {
    socket?.emit('leaveRoom', { roomId });
  };

  return (
    <WebSocketContext.Provider value={{ socket, joinRoom, leaveRoom }}>
      {children}
    </WebSocketContext.Provider>
  );
};

/*
  const connectSocket = () => {
    const newSocket = io(`${process.env.REACT_APP_BACKEND_ROOT}`, {
      path: '/socket.io',
      transports: ['websocket'],
      auth: {
        token: accessToken,
      },
    });
    setSocket(newSocket);
  };

  const disconnectSocket = () => {
    socket?.disconnect();
    setSocket(null);
  };

  useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  // Assurez-vous de retourner les enfants enveloppés par le fournisseur de contexte
  return (
    <WebSocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
*/

/*
import React, { createContext, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';

interface WebSocketContextType {
  socket: Socket | null;
  connectSocket: () => void;
  disconnectSocket: () => void;
}

const defaultContextValue: WebSocketContextType = {
  socket: null, // Since there's no socket initially, set this to null.
  connectSocket: () => {
    // Default function logic (possibly empty, if you expect this to only be used when the context is provided)
  },
  disconnectSocket: () => {
    // Default function logic
  },
};

// Création du contexte avec un type défini

export const WebSocketContext = createContext<WebSocketContextType>(defaultContextValue);

interface WebSocketProviderProps {
    children: React.ReactNode;
}

// Fournisseur du contexte WebSocket
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  //export const WebSocketProvider = ({ children }: WebSocketProviderProps): React.ReactElement => {
  
  const [socket, setSocket] = useState<Socket | null>(null);

  const connectSocket = () => {
    const newSocket = io(`${process.env.REACT_APP_BACKEND_ROOT}`, {
      path: '/socket.io',
      transports: ['websocket'],
    });
    setSocket(newSocket);
  };

  const disconnectSocket = () => {
    socket?.disconnect();
    setSocket(null);
  };

  const contextValue: WebSocketContextType = {
    socket,
    connectSocket,
    disconnectSocket,
  }

  return (
      <WebSocketContext.Provider value={contextValue}>
        {children}
      </WebSocketContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
*/