import { useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity, Priority, priorityValues } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Skeleton } from "../../../../../components/ui/skeleton";
import { Badge } from "../../../../../components/ui/badge";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover"
import { cn } from "../../../../../lib/utils";

interface OppyPriorityUnitProps {
    data: Opportunity;
}

export const OppyPriorityUnit = ({
    data,
}: OppyPriorityUnitProps) => {

    const [open, setOpen] = useState(false);

    const [priority, setPriority] = useState(data.priority);

    const { t } = useTranslation();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setPriority(data.priority);
            setOpen(false);
        },
        onError: (error) => {
            toast.error(error);
            setOpen(false);
        }
    });

    const handleChange = (value: Priority) => {
        
        if (data.id) {
            if (value === priority) {
                setOpen(false);
                return;
            }
            executeUpdateCard({
                id: data.id,
                priority: value,
            });
        }
    };
    
    return (

        <Popover
            open={open} 
            onOpenChange={setOpen} 
        >
            <PopoverTrigger asChild disabled={!data.title}>
                <button>
                <Badge className={cn("bg-contacts_primary text-xs md:text-sm cursor-pointer",
                        !data.title && "bg-contacts_primary/10 text-gray-500 border-none italic font-medium cursor-not-allowed"
                    )}>
                        {priority ? priority?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Add a priority"}
                    </Badge>
                </button>
            </PopoverTrigger>
            <PopoverContent className="p-2">
                <div className="flex flex-col text-xs md:text-sm">
                    {priorityValues.map((priorityValue) => (
                        <div
                            key={priorityValue}
                            onClick={() => handleChange(priorityValue as Priority)}
                            className={cn(
                                "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                priority === priorityValue && "font-bold"
                            )}
                        >
                            {priorityValue.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                        </div>
                    ))}
                </div>
            </PopoverContent>
        </Popover>
        
    );
};

OppyPriorityUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };