import React from 'react';
import { NavLink } from 'react-router-dom';
import img_community from '../../../img/landing/community.svg';
import img_missions from '../../../img/landing/landing_servers/Mission Impossible-bro.svg';
import img_coworking from '../../../img/landing/landing_servers/Coworking-bro.svg';
import img_kickstart from '../../../img/landing/landing_servers/kickstart.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';



const LandingCommunity = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h1 className="text-4xl font-bold text-community_primary dark:text-dark_community">Community</h1>
                        <p className="mt-4 text-lg lg:text-xl text-[#A12E8B] dark:text-[#F8D3ED]">
                            {t("landing.landingCommunity.tagline")}
                        </p>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_community} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title1")}</h2>
                                <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc1")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#A6308F] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                        {t("landing.landingCommunity.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F4E2EE] dark:bg-[#1D101A] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_missions} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title2")}</h2>
                                <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc2")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#A6308F] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                        {t("landing.landingCommunity.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_coworking} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title3")}</h2>
                                <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc3")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#A6308F] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                        {t("landing.landingCommunity.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F4E2EE] dark:bg-[#1D101A] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_kickstart} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title4")}</h2>
                                <p className="mt-2 text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc4")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#A6308F] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                                        {t("landing.landingCommunity.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <NavLink to="/signup" className="mt-4 bg-[#F1D4E8] text-[#A5328E] dark:bg-[#47193D] dark:text-[#A6308F] font-bold px-4 py-2 rounded shadow hover:bg-[#EBC7E0] dark:hover:bg-[#542149]">
                            {t("landing.landingCommunity.cta2")}
                        </NavLink>
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
}

export default LandingCommunity;
