import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useModal } from "./use-modal-store";
import { Button } from "../../../../components/ui/button";
import { fetchDeletePlan } from "../../../../api/career/api-create-plan";
import { Plan } from "../../../../api/career/interface-career";
import { useCareerHookActions } from "../../../../hooks/career-hook";
import { useTranslation } from "react-i18next";

export const DeletePlanModal = () => {
  const { t } = useTranslation();

  const { isOpen, onClose, type, data } = useModal();

  const isModalOpen = isOpen && type === "deletePlan";
  const { planId, existingPlan } = data;

  const [isLoading, setIsLoading] = useState(false);

  const [deletedPlan, setDeletePlan] = useState<Plan>();

  const { deletePlan } = useCareerHookActions();

  const onClick = async () => {
    try {
      //console.log("DELETE PLAN MODAL");
      setIsLoading(true);
      if (planId) {
        //console.log("IF DELETE PLAN MODAL");
        const response = await fetchDeletePlan(planId.id);
        deletePlan(planId.id);
        onClose();
      }
    } catch (error) {
      //console.log("onClick: ", planId);
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-career_primary dark:text-[#FBD5D7] bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold">
            {t("career.modals.delete.top")}
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium text-center">
            {t("career.modals.delete.description")}<br />
            <span className="text-career_primary font-semibold">{existingPlan?.path}</span> {t("career.modals.delete.warning")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t("career.modals.delete.cancel")}
            </Button>
            <Button
              disabled={isLoading}
              className="bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={onClick}
            >
            {t("career.modals.delete.confirm")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}