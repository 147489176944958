import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';

import { getContactsHookActions, useNewContact, useSelectedContact } from "../../../../../hooks/contact-hook";
import { Typography } from '@mui/material';
import { Tv2, Phone, Send, Mail, Coffee, Utensils, BriefcaseBusiness, GlassWater, Aperture, Plus, Pickaxe, LayoutDashboard, Pencil, Edit, Trash } from "lucide-react";
//import { visio, call, mail, café, dej, travail, Tv2, phone, send, coffee, utensils, BriefcaseBusiness } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ConEvent, ConAction } from '../../../../../interfaces/contacts/int-contacts';
import { fetchEventsFromContact } from '../../../../../api/contacts/api-contact-event';
import { Button } from '../../../../../components/ui/button';
import React from 'react';
import ContactTimelineActions from './ContactTimelineActionsOptions';
import { ActionTooltip } from '../../../../../components/action-tooltip';
import ContactTimelineElement from './ContactTimelineElement';
import ContactTimelineForm from './ContactTimelineForm';
import { toast } from "sonner";
import { useAction } from '../../../../../hooks/use-action';
import { fetchDeleteEvent } from '../../../../../api/contacts/delete-event';

// Define a mapping of membership to component
export const enventTypeComponents: Record<string, React.ElementType> = {
    MESSAGE: Mail,
    CALL: Phone, // Assuming you have different components for different memberships
    VISIO: Tv2,
    COFFEE: Coffee,
    LUNCH: Utensils,
    DINER: Utensils,
    EVENT: GlassWater,
    MEETING: BriefcaseBusiness,
    WORKSHOP: LayoutDashboard,
    OTHER: Aperture,
  };

const ContactTimeline = () => {
    //console.log('ContactTimeline');
    const selectedContact = useSelectedContact();
    // retrieve the step of the new contact if one
    //console.log('selectedContact :', selectedContact);
    const { setNewContact } = getContactsHookActions();
    const newContact = useNewContact();

    const [contactEvents, setContactEvents] = useState<ConEvent[] | undefined>(undefined);
    const [isNewEvent, setIsNewEvent] = useState<Boolean>(false);
    const [isEditing, setIsEditing] = useState<string | undefined>(undefined);

    const disableEditing = () => {
        setIsEditing(undefined);
    };

    const disableNewEvent = () => {
        setIsNewEvent(false);
    };

    // Function to sort the events array
    function sortEvents(events: ConEvent[]): ConEvent[] {
        return events.sort((a, b) => {
        if (a.date && b.date) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        } else if (a.date && !b.date) {
            return -1;
        } else if (!a.date && b.date) {
            return 1;
        } else {
            return 0;
        }
        });
    }

    const updateContactEvents = (event: ConEvent) => {
        if (contactEvents) {
            const updatedEvents = [...contactEvents];
            // find if the event already exists and update it
            const eventIndex = updatedEvents.findIndex((e) => e.id === event.id);
            if (eventIndex !== -1) {
                updatedEvents[eventIndex] = event;
            } else {
                // add the new event
                updatedEvents.push(event);
            }
            setContactEvents(sortEvents(updatedEvents));
        }
    };

    const deleteContactEvent = (eventId: string) => {
        if (contactEvents) {
            const updatedEvents = contactEvents.filter((e) => e.id !== eventId);
            setContactEvents(sortEvents(updatedEvents));
        }
    };

    // Initialize an empty event object
    const emptyEvent: ConEvent = {
        id: '', // Assuming id is generated when the event is saved
        type: '', // Default to an empty string or a sensible default
        title: '',
        description: '',
        date: new Date(), // Use new Date() for a default value or null if your logic handles it
        location: '',
        actions: [{'title': ''}], // Start with an empty array for actions
    };

    useEffect(() => {
        const fetchEvents = async () => {
            if (selectedContact) {
                const response = await fetchEventsFromContact(selectedContact)
                setContactEvents(sortEvents(response));
                //console.log('contact events :', response);
            }
        }
        fetchEvents();
        
        return () => {
            // Cleanup logic here, which runs on component unmount or dependency change
            //console.log('Cleaning up or resetting state');
            setContactEvents(undefined);
        };
    }, [selectedContact]);

    const { execute: executeDeleteEvent, fieldErrors: deleteEventFieldErrors } = useAction(fetchDeleteEvent, {
        onSuccess: (data) => {
            //console.log('data - deleted event :', data);
            data.id && deleteContactEvent(data.id);
            disableEditing();            
            toast.success(`Event deleted`);
        },
        onError: (error) => {
          toast.error(error);
        },
      });

    const handleEventDeletion = async (event: string) => {
        //console.log('event id : ', event);
        executeDeleteEvent({
            id: event, 
            contact_id: selectedContact || ""});
    };

    const handleNextStep = (step: string) => {
        if (isEditing!==undefined || isNewEvent) {
            toast.error("Please save or cancel the current event before proceeding");
        } else if (step === "previous") {
            setNewContact("contactCard")
        } else if (step === "next") {
            setNewContact("Groups")
        }
    };

    return (
        <div className="flex flex-col">
            {contactEvents && contactEvents?.map((contactEvent, index) => (
                (contactEvent.id && isEditing === contactEvent.id) ? 
                    <ContactTimelineForm key={contactEvent.id} contactEvent={contactEvent} index={index} disableEditing={disableEditing} handleEventDeletion={handleEventDeletion} updateContactEvents={updateContactEvents} disableNewEvent={disableNewEvent} />
                :
                contactEvent.id && <ContactTimelineElement key={contactEvent.id} contactEvent={contactEvent} index={index} setIsEditing={setIsEditing} handleEventDeletion={handleEventDeletion} />
            ))}
            {isNewEvent ? 
                <ContactTimelineForm key={emptyEvent.id || '1'} contactEvent={emptyEvent} index={0} disableEditing={disableEditing} handleEventDeletion={handleEventDeletion} updateContactEvents={updateContactEvents} disableNewEvent={disableNewEvent} />
            :
                <div className="w-full flex items-center justify-center">
                    <Button
                        onClick={() => setIsNewEvent(true)}
                        className="w-10 h-10 bg-contacts_primary rounded-full flex items-center justify-center p-0 m-0"
                    >
                        <Plus />
                    </Button>
                </div>
            }
            
            {newContact && (
                <div className="flex justify-end gap-2">
                    <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base" onClick={() => handleNextStep("next")}>Next</Button>
                </div>
            )}
        </div>
        );
    }
     
    export default ContactTimeline;            

    /*
    6To do : <Button className="bg-contacts_primary hover:bg-contacts_secondary text-white font-bold text-base" onClick={() => handleNextStep("previous")} >Previous</Button>
    for timeline to card
    */ 