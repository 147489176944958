import { createStore, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { GlbProfile } from "../interfaces/profile/profile";

interface GeneralProfileHook {
    glbProfile: GlbProfile | undefined;
    
    actions: {
        setGlbProfile: (glbProfile: GlbProfile) => void;
        clearGeneralProfileHook: () => void;
    }
}

const generalProfileHook = createStore<GeneralProfileHook>()(
    devtools(
        (set, get) => ({
            glbProfile: undefined,
            
            actions: {
                setGlbProfile: (glbProfile: GlbProfile) => {
                    set({
                        glbProfile,
                    });
                },
                clearGeneralProfileHook: () => {
                    set({
                        glbProfile: undefined,
                    });
                }
            },
        }),
        {
            name: 'general-profile-hook',
            enabled: process.env.REACT_APP_NODE_ENV !== 'production',//!import.meta.env.PROD, // Enable the devtools in non-production environments properly with the import.meta.env.PROD variable
        }
    )
)

export type ExtractState<S> = S extends {
    getState: () => infer T;
}
? T
: never;

type Params<U> = Parameters<typeof useStore<typeof generalProfileHook, U>>;

// Selectors
const actionsSelector = (state: ExtractState<typeof generalProfileHook>) => state.actions;
const glbProfileSelector = (state: ExtractState<typeof generalProfileHook>) => state.glbProfile;

// getters
export const getActions = () => actionsSelector(generalProfileHook.getState());
export const getGlbProfile = () => glbProfileSelector(generalProfileHook.getState());


function useGlbProfileHook<U>(selector: Params<U>[1], equalityFn?: Params<U>[2]) {
    return useStoreWithEqualityFn(generalProfileHook, selector, equalityFn);
}

// Hooks
export const useProfileActions = () => useGlbProfileHook(actionsSelector);
export const useGlbProfile= () => useGlbProfileHook(glbProfileSelector);