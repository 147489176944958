import { useEffect, useState } from "react";
import { cn } from "../../../../../lib/utils";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { useCompanies, useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { ContactGroup, ContactInterest, ContactTableElement, getContactStoreActions, Interaction, useContactTable } from "../../../../../hooks/contact-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"
import { fetchCompanies } from "../../../../../api/contacts/opportunities/api-get-opportunities";
import { fetchAddContact } from "../../../../../api/contacts/api-getContactData";
import { fetchDeleteOpportunityCompanyContact } from "../../../../../api/contacts/opportunities/delete-opportunity-company-contact";

import { Company, Opportunity, companyRole, companyRoleValues } from "../../../../../interfaces/contacts/int-opportunity";

import { Button } from "../../../../../components/ui/button";
import { Badge } from "../../../../../components/ui/badge";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../../components/ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../../../../../components/ui/command";
import { Input } from "../../../../../components/ui/input";

import { Trash } from "lucide-react";
import debounce from 'lodash.debounce';

interface OppyCompanyNewUnitProps {
    data: Opportunity;
    //isNewAction: string;
    //setIsNewAction: React.Dispatch<React.SetStateAction<boolean>>;
    isEditingCompany: string | boolean;
    setIsEditingCompany: React.Dispatch<React.SetStateAction<string | boolean>>;
}

const OppyCompanyEditUnit = ({
    data,
    isEditingCompany,
    setIsEditingCompany,
}: OppyCompanyNewUnitProps) => {

    const { t } = useTranslation();

    const newCompanyObject: Company = {
        id: undefined,
        name: "",
        role: undefined,
        contacts: [],
    }

    const foundAction = data.companies?.find((company: Company) => company.id === isEditingCompany);
    const [editingCompany, setEditingCompany] = useState<Company>(foundAction ?? newCompanyObject);
    const [openRolePopover, setopenRolePopover] = useState(false);

    const handleRoleChange = (role: companyRole) => {
        setEditingCompany((prevCompany) => ({
            ...prevCompany,
            role,
          }));
          setopenRolePopover(false);
    }

    // Companies
    const [openCompanyPopover, setOpenCompanyPopover] = useState(false);
    
    // search input company
    // debouncer for the search input
    const [inputCompanyValue, setInputCompanyValue] = useState("")
    const handleInputChange = debounce((newInputValue) => {
        setInputCompanyValue(newInputValue);
      }, 300);

    // Load companies
    const companies = useCompanies();
    const { initCompanies } = useOpportunityStoreActions();
    useEffect(() => {
        const getCompaniesList = async () => {
            try {
                const response = await fetchCompanies();
                if (response) {
                    initCompanies(response);
                }
            } catch (error) {
            }
        };
        if (!companies || companies.length === 0) {
            getCompaniesList();
        }
    }, []);

    const handleCompanyChange = (company_id: string) => {
        if (company_id === "newElement") {
            setEditingCompany((prevCompany) => ({
                ...prevCompany,
                name: inputCompanyValue,
            }));
        } else {
            setEditingCompany((prevCompany) => ({
                ...prevCompany,
                id: company_id,
                name: companies?.find((company) => company.id === company_id)?.name ?? inputCompanyValue,
            }));
        }
        setOpenCompanyPopover(false);
    };

    // Contacts
    const [isAddingContact, setIsAddingContact] = useState(false);
    const [openContactPopover, setOpenContactPopover] = useState(false);

    const userContacts = useContactTable();

    const [inputContactValue, setInputContactValue] = useState("")
    const handleContactInputChange = debounce((newInputValue) => {
        setInputContactValue(newInputValue);
      }, 300);

    const [inputContactFirstName, setInputContactFirstName] = useState("");
    const [inputContactLastName, setInputContactLastName] = useState("");
    const [inputContactJobTitle, setInputContactJobTitle] = useState("");

    const handleContactCreation = async (contact_id: string) => {
        const editableContact = {
            first_name: inputContactFirstName,
            last_name: inputContactLastName,
            job_title: inputContactJobTitle,
        };
        const { updateContactTable } = getContactStoreActions();
        try {
            const response = await fetchAddContact(editableContact);
            if (response) {
                const updatedContact: ContactTableElement = { 
                    id: response.newContact.id as string,
                    first_name: response.newContact.first_name as string,
                    last_name: response.newContact.last_name as string,
                    job_title: response.newContact.job_title ?? '',  // Providing a default value if undefined
                    company_name: response.newContact.company_name ?? '',  // Providing a default value if undefined
                    location: response.newContact.location ?? '',  // Providing a default value if undefined
                    email: response.newContact.email ?? '',  // Providing a default value if undefined
                    phone_number: response.newContact.phone_number ?? '',  // Providing a default value if undefined
                    con_contacts_groups: [] as ContactGroup[],
                    con_contacts_interests: [] as ContactInterest[],
                    con_interactions: [] as Interaction[],
                 };
                 // Add the new contact to the store
                 updateContactTable(updatedContact);
                 const newContactOppyFormat = {
                    id: response.newContact.id ?? "",
                    firstName: response.newContact.first_name,
                    lastName: response.newContact.last_name,
                    job_title: response.newContact.job_title,
                }
                // Add the new contact to the company
                setEditingCompany((prevCompany) => ({
                    ...prevCompany,
                    contacts: [...prevCompany.contacts, newContactOppyFormat],
                }));
            }
        } catch (error) {
            //console.log("contact creation error : ", error);
        }
    };

    const handleContactChange = (contact_id: string, addContact: boolean) => {
        
        const currentContacts = editingCompany.contacts.map((contact) => contact.id);

        if (!currentContacts.includes(contact_id)) {
            if (addContact === true) {
                const newContact = userContacts?.find((contact) => contact.id === contact_id);
                const newContactOppyFormat = {
                    id: contact_id,
                    firstName: newContact?.first_name,
                    lastName: newContact?.last_name,
                    job_title: newContact?.job_title,
                }
                if (newContactOppyFormat) {

                    setEditingCompany((prevCompany) => ({
                        ...prevCompany,
                        contacts: [...prevCompany.contacts, newContactOppyFormat],
                    }));
                }
            } else if (addContact === false) {
                setEditingCompany((prevCompany) => ({
                    ...prevCompany,
                    contacts: prevCompany.contacts.filter((contact) => contact.id !== contact_id),
                }));
            }
        }
        setOpenContactPopover(false);
    };
    // Save changes
    const { createUpdateOpportunity } = useOpportunityStoreActions();
    
    const { execute: executeUpdateOpportunity } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            //setDescription(data.description);
            setIsEditingCompany(false);
        },
        onError: (error) => {
            toast.error(error);
        }
    });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        if (data.id) {
            if (editingCompany.id) {
                // if the action exist; we update it
                executeUpdateOpportunity({
                    id: data.id,
                    companies: {
                        id: editingCompany.id,
                        name: editingCompany.name,
                        role: editingCompany.role ?? "CLIENT" as companyRole,
                        contacts: editingCompany.contacts,
                    }
                });
            } else {
                // if the action doesn't exist; we create it
                executeUpdateOpportunity({
                    id: data.id,
                    companies: {
                        name: editingCompany.name ?? "",
                        role: editingCompany.role ?? "CLIENT" as companyRole,
                        contacts: editingCompany.contacts,
                    }
                });
            }
        } 
    }

    const { execute: executeUpdateOpportunityDeleteContact } = useAction(fetchDeleteOpportunityCompanyContact, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            //setDescription(data.description);
            setIsEditingCompany(false);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity");
        }
    });

    const handleContactDeletion = (contact_id: string) => {
        if (data.id && editingCompany.id) {
            executeUpdateOpportunityDeleteContact({
                opportunity_id: data.id,
                company_id: editingCompany.id,
                contact_id: contact_id,
            });
        }
    };

    const disableEditing = () => {
        //setIsNewAction(false);
        setIsEditingCompany(false);
    }

    return ( 
        <div className="flex flex-col justify-start gap-y-2">
            <form onSubmit={onSubmit} className="flex flex-col w-full justify-start gap-y-1">
                <div
                    // company name
                    className="flex flex-row w-full justify-start items-center gap-x-1"
                >
                    <label htmlFor="rate" className="flex w-full items-center gap-x-1 m-0 p-0 text-sm">
                        {t("contacts.opportunities.client.company.company")}
                    </label>
                    <Popover open={openCompanyPopover} onOpenChange={setOpenCompanyPopover} >
                        <PopoverTrigger
                            asChild
                            disabled={editingCompany.name ? true : false}
                        >
                            <button>
                                <Badge 
                                    variant="contacts"
                                    className={cn("text-xs md:text-sm cursor-pointer whitespace-nowrap",
                                        editingCompany.name ? "bg-transparent border-none italic font-medium cursor-not-allowed" : 
                                        ""
                                    )}
                                >
                                    {editingCompany.name ? editingCompany.name : t("contacts.opportunities.client.company.placeholder")}
                                </Badge>
                            </button>
                        </PopoverTrigger>
                        <PopoverContent 
                            className="p-2"
                        >
                            <Command>
                                <CommandInput
                                    //placeholder={incomingPage === "newContact" ? `Type new ${nature}...` : "Search value..." }
                                    onValueChange={(input) => handleInputChange(input)}  // Capture the input value
                                    />
                                <CommandList className="max-h-[150px] overflow-x-hidden overflow-y-auto" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <CommandEmpty>
                                        <div
                                            key='new'
                                            onClick={() => handleCompanyChange("newElement")}
                                            className="z-60 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-sm px-2 py-1 text-sm cursor-pointer bg-accent-1 dark:bg-accent-8"  // Ensure it's clickable and has high z-index
                                        >
                                            {t("contacts.opportunities.client.company.createplaceholder")} "{inputCompanyValue}"
                                        </div>
                                    </CommandEmpty>
                                    <CommandGroup>
                                        {companies?.map((elt) => (
                                        <CommandItem
                                            key={elt.id}
                                            value={elt.id}
                                            onSelect={(currentValue) => {
                                                handleCompanyChange(currentValue)
                                            }}
                                            className=" flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none z-1000 hover:bg-gray-100 dark:hover:bg-gray-800"  // Ensure it's clickable and has high z-index
                                            style={{ pointerEvents: 'auto', zIndex: 1000 }}  // Debugging inline style
                                        >
                                            {elt.name}
                                        </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
                <div
                    // company role
                    className="flex flex-row w-full justify-start"
                >
                    <label htmlFor="rate" className="flex w-full items-center gap-x-1 m-0 p-0 text-sm">
                        {t("contacts.opportunities.client.role.role")}
                    </label>
                    <Popover
                        open={openRolePopover} 
                        onOpenChange={setopenRolePopover}
                    >
                        <PopoverTrigger asChild>
                            <button>
                                <Badge 
                                    variant="contacts"
                                    className={cn("text-xs md:text-sm cursor-pointer whitespace-nowrap",
                                        editingCompany.role ? "bg-transparent border-none italic font-medium" : 
                                        ""
                                    )}
                                >
                                {companyRoleValues.find((role) => role === editingCompany.role) ? 
                                    companyRoleValues.find((role) => role === editingCompany.role)?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) :
                                    t("contacts.opportunities.client.role.rolePlaceholder")
                                }
                                </Badge>
                            </button>
                        </PopoverTrigger>
                        <PopoverContent className="p-2">
                            <div className="flex flex-col text-xs md:text-sm max-h-48 overflow-y-auto scrollbar-thin scrollbar-track-transparent">
                                {companyRoleValues && companyRoleValues.map((role) => (
                                    <div
                                        key={role}
                                        onClick={() => handleRoleChange(role as companyRole)}
                                        className={cn(
                                            "cursor-pointer p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800",
                                            role === editingCompany.role && "font-bold"
                                        )}
                                    >
                                        {role.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                    </div>
                                ))}
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <div
                    // company contacts
                    className="flex flex-row w-full justify-start items-center gap-x-1"
                >
                    <label htmlFor="contacts" className="flex w-full items-center gap-x-1 m-0 p-0 text-sm">{t("contacts.opportunities.client.contacts.Contacts")}</label>
                    <Popover
                        open={openContactPopover} 
                        onOpenChange={setOpenContactPopover}
                    >
                        <PopoverTrigger asChild>
                            <button>
                                <Badge 
                                    variant="contacts"
                                    className={cn("text-xs md:text-sm cursor-pointer whitespace-nowrap",
                                        editingCompany.role ? "bg-transparent border-none italic font-medium" : 
                                        ""
                                    )}
                                >
                                    {t("contacts.opportunities.client.contacts.placeholder")}
                                </Badge>
                            </button>
                        </PopoverTrigger>
                        <PopoverContent className="p-2">
                            <Command>
                                <CommandInput
                                    onValueChange={(input) => handleContactInputChange(input)}  // Capture the input value
                                />
                                <CommandList className="max-h-[150px] overflow-x-hidden overflow-y-auto" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <CommandEmpty className="p-0 m-0">
                                        <div
                                            key='new'
                                            className="flex flex-col w-full justify-center gap-y-2 z-60 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-sm px-2 py-1 text-sm cursor-pointer bg-accent-1 dark:bg-accent-8"  // Ensure it's clickable and has high z-index
                                        >
                                            <div className="flex pt-1 italic text-muted-foreground">{t("contacts.opportunities.client.contacts.createplaceholder")} "{inputContactValue}"</div>
                                            <Input
                                                id="contact_firstName"
                                                name="contact_firstName"
                                                type="text"
                                                placeholder={t("contacts.opportunities.client.contacts.firstName")}
                                                value={inputContactFirstName}
                                                onChange={(e) => setInputContactFirstName(e.target.value)} 
                                            />
                                            <Input
                                                id="contact_lastName"
                                                name="contact_lastName"
                                                type="text"
                                                placeholder={t("contacts.opportunities.client.contacts.lastName")}
                                                value={inputContactLastName}
                                                onChange={(e) => setInputContactLastName(e.target.value)}
                                            />
                                            <Input
                                                id="contact_jobTitle"
                                                name="contact_jobTitle"
                                                type="text"
                                                placeholder={t("contacts.opportunities.client.contacts.jobTitle")}
                                                value={inputContactJobTitle}
                                                onChange={(e) => setInputContactJobTitle(e.target.value)}
                                            />
                                            <Button
                                                type="button"
                                                variant="contacts"
                                                onClick={() => handleContactCreation("newElement")}
                                            >
                                                {t("contacts.opportunities.client.contacts.createplaceholder")}
                                            </Button>
                                        </div>
                                    </CommandEmpty>
                                    <CommandGroup>
                                        {userContacts?.map((elt) => (
                                        <CommandItem
                                            key={elt.id}
                                            value={elt.first_name + " " + elt.last_name}
                                            onSelect={(currentValue) => {
                                                handleContactChange(elt.id, true)
                                            }}
                                            className=" flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none z-1000 hover:bg-gray-100 dark:hover:bg-gray-800"  // Ensure it's clickable and has high z-index
                                            style={{ pointerEvents: 'auto', zIndex: 1000 }}  // Debugging inline style
                                        >
                                            {elt.first_name} {elt.last_name}
                                        </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                            <div className="flex flex-col text-xs md:text-sm max-h-48 overflow-y-auto scrollbar-thin scrollbar-track-transparent">
                                
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <div className="flex flex-col w-full justify-start items-center gap-x-1 gap-y-1">
                    {editingCompany.contacts?.map((contact) => (
                        <div className="flex flex-col w-full justify-start pl-2">
                            <div className="text-sm">
                                {contact.firstName} {contact.lastName} 
                                <button
                                    onClick={() => handleContactDeletion(contact.id)}
                                    className="text-contacts_primary text-xs inline"
                                >
                                    <Trash
                                        className="h-4 w-4"
                                    />
                                </button>
                            </div>
                            <div className="text-xs italic">
                                {contact.job_title}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex items-center gap-x-2 mt-2">
                    <Button
                        type="submit"
                        size="sm"
                        variant="contacts_primary"
                        className="text-xs md:text-sm"
                    >
                        {t("tasks.card.edit.description.save")}
                    </Button>
                    <Button
                        type="button"
                        onClick={disableEditing}
                        size="sm"
                        variant="contacts"
                        className="text-xs md:text-sm"
                    >
                        {t("tasks.card.edit.description.cancel")}
                    </Button>
                </div>
            </form>
        </div>
     );
}
 
export default OppyCompanyEditUnit;