import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import img_visibility from '../../../img/landing/visibility/Social update-bro.svg';
import img_automate from '../../../img/landing/visibility/Mobile Marketing-bro.svg';
import img_analytics from '../../../img/landing/visibility.svg';
import img_booster from '../../../img/landing/visibility/Social Growth-bro.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '../../../hooks/auth-store';
import { Button } from '../../../components/ui/button';
import { fetchInterestVisibility } from '../../../api/analytics/api-analytics';



const LandingVisibility = () => {
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const [templatesInterest, setTemplatesInterest] = useState(false);
    const [publishInterest, setPublishInterest] = useState(false);
    const [analyticsInterest, setAnalyticsInterest] = useState(false);
    const [boosterInterest, setBoosterInterest] = useState(false);


    const handleClick = async (subFeature: string) => {
        // Update state and call API to register interest
        await fetchInterestVisibility({ feature: 'visibility', action: subFeature });
        switch(subFeature) {
            case 'templatesInterest':
                setTemplatesInterest(true);
                break;
            case 'publishInterest':
                setPublishInterest(true);
                break;
            case 'analyticsInterest':
                setAnalyticsInterest(true);
                break;
            case 'boosterInterest':
                setBoosterInterest(true);
                break;
            default:
                // Handle other cases or errors
                break;
        }
    };

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h1 className="text-4xl font-bold text-visibility_primary dark:text-dark_visibility">Visibility</h1>
                        <p className="mt-4 text-lg lg:text-xl text-[#9F6000] dark:text-[#FCE0C6]">
                            {t("landing.landingVisibility.tagline")}
                        </p>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_visibility} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-visibility_primary dark:text-dark_visibility">{t("landing.landingVisibility.title1")}</h2>
                                <p className="mt-2 text-[#9F6000] dark:text-[#FCE0C6]">
                                    {t("landing.landingVisibility.desc1")}
                                </p>
                                <div className='py-3'>
                                    {isAuthenticated ? (
                                        <Button  onClick={() => handleClick("templatesInterest")} className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                            {templatesInterest ?  t("landing.landingVisibility.ctaActivated") :  t("landing.landingVisibility.ctaConnected")}
                                        </Button>
                                    ) : (
                                        <NavLink to="/signup" className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                            {t("landing.landingVisibility.cta1")}
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#FFE4C9] dark:bg-[#2D1E10] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_automate} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-visibility_primary dark:text-dark_visibility">{t("landing.landingVisibility.title2")}</h2>
                                <p className="mt-2 text-[#9F6000] dark:text-[#FCE0C6]">
                                    {t("landing.landingVisibility.desc2")}
                                </p>
                                <div className='py-3'>
                                    {isAuthenticated ? (
                                        <Button  onClick={() => handleClick("publishInterest")} className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                            {publishInterest ?  t("landing.landingVisibility.ctaActivated") :  t("landing.landingVisibility.ctaConnected")}
                                        </Button>
                                    ) : (
                                    <NavLink to="/signup" className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                        {t("landing.landingVisibility.cta1")}
                                    </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_analytics} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-visibility_primary dark:text-dark_visibility">{t("landing.landingVisibility.title3")}</h2>
                                <p className="mt-2 text-[#9F6000] dark:text-[#FCE0C6]">
                                    {t("landing.landingVisibility.desc3")}
                                </p>
                                <div className='py-3'>
                                    {isAuthenticated ? (
                                        <Button  onClick={() => handleClick("analyticsInterest")} className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                            {analyticsInterest ?  t("landing.landingVisibility.ctaActivated") :  t("landing.landingVisibility.ctaConnected")}
                                        </Button>
                                    ) : (
                                    <NavLink to="/signup" className="mt-4 bg-[#FAE3D8] text-[#E58800] dark:bg-[#311C11] dark:text-[#FFDECD] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                        {t("landing.landingVisibility.cta1")}
                                    </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#FFE4C9] dark:bg-[#2D1E10] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_booster} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-visibility_primary dark:text-dark_visibility">{t("landing.landingVisibility.title4")}</h2>
                                <p className="mt-2 text-[#9F6000] dark:text-[#FCE0C6]">
                                    {t("landing.landingVisibility.desc4")}
                                </p>
                                <div className='py-3'>
                                    {isAuthenticated ? (
                                        <Button  onClick={() => handleClick("boosterInterest")} className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                            {boosterInterest ?  t("landing.landingVisibility.ctaActivated") :  t("landing.landingVisibility.ctaConnected")}
                                        </Button>
                                    ) : (
                                    <NavLink to="/signup" className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                                        {t("landing.landingVisibility.cta1")}
                                    </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <NavLink to="/signup" className="mt-4 bg-[#FFE5C8] text-[#E58800] dark:bg-[#2D1E10] dark:text-[#F6AB5F] font-bold px-4 py-2 rounded shadow hover:bg-[#FFC88C] dark:hover:bg-[#4C2D0B]">
                            {t("landing.landingVisibility.cta2")}
                        </NavLink>
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
}

export default LandingVisibility;
