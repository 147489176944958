import { useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Skeleton } from "../../../../../components/ui/skeleton";

import { DollarSign } from "lucide-react";

interface OppyRateUnitProps {
    data: Opportunity;
}

export const OppyRateUnit = ({
    data,
}: OppyRateUnitProps) => {

    const [isEditing, setIsEditing] = useState(false);
    const [rate, setRate] = useState(data.rate ?? 0);
    const increment: number = 50;

    const { t, i18n } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setRate(Number(data.rate) ?? 0);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error(error);
            setIsEditing(false);
        }
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRate(Number(e.target.value));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowUp") {
            setRate((prevValue) => prevValue + increment);
            e.preventDefault(); // Prevent default increment behavior
        }
        if (e.key === "ArrowDown") {
            setRate((prevValue) => Math.max(0, prevValue - increment)); // Prevent going below 0
            e.preventDefault(); // Prevent default decrement behavior
        }
    };

    const onBlur = () => {
        inputRef.current?.form?.requestSubmit();
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const newRate = Number(formData.get("rate"));
        if (newRate === data.rate) {
            setIsEditing(false);
            return;
        }

        if (data.id) {
            executeUpdateCard({
                id: data.id,
                rate: newRate,
            });
        }
    }
    
    if (isEditing) {

        return (
            <div>
                <form onSubmit={onSubmit} className="flex flex-row items-center justify-start gap-x-1 w-full m-0 p-0 text-sm">
                    <label htmlFor="rate" className="flex w-1/3 items-center gap-x-1 m-0 p-0 text-sm"><DollarSign className="h-4 w-4" />
                        {t("contacts.opportunities.keyPoints.rate")}
                    </label>
                    <input
                        type="number"
                        id="rate"
                        name="rate"
                        ref={inputRef}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={onBlur} // Calls onSubmit directly
                        value={rate}
                        className="flex w-2/3 m-0 p-1 text-center text-sm border-t border-b border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Enter contract value"
                        min="0"
                        step="any"
                        disabled={!data.id}
                    />
                </form>
            </div>
        );
    } else {
        return (
            <div
                className="flex flex-row gap-x-1 items-center w-full cursor-pointer"
                onClick={() => setIsEditing(true)}
            >
                <DollarSign className="h-4 w-4" /> {t("contacts.opportunities.keyPoints.rate")} {i18n.language === 'fr' ? (new Intl.NumberFormat("fr-FR").format(rate)) : (new Intl.NumberFormat("en-US").format(rate))}
            </div>
        );
    }
    
};

OppyRateUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };