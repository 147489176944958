export interface Plan {
    id?: string;
    glb_user_id?: string;
    title: string;
    description?: string;
    resources: number;
    entertainment: number;
    confidence: number;
    coherence: number;
    path: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER";
    context: string;
    questions?: string[];
    start_date ?: Date;
    end_date ?: Date;
    deleted ?: boolean;
    created_at?: Date;
    updated_at?: Date;
}

export interface ExistingPlan {
  id: string;
  glb_user_id?: string;
  title: string;
  description?: string;
  resources: number;
  entertainment: number;
  confidence: number;
  coherence: number;
  path: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER";
  context: string;
  questions?: Question[];
  start_date ?: Date;
  end_date ?: Date;
  deleted ?: boolean;
  created_at?: Date;
  updated_at?: Date;
}


export interface PlanElement {
    id: string;
    plan_id: string;
    parent_elt?: string;
    title: string;
    description?: string;
    previous_elt?: string;
    next_elt?: string;
    category: NodeCategory;
    type: NodeType,
    status: NodeStatus;
    year: number;
    key_results?: KeyResults[];
    deadline_date: Date;
    start_date: Date;
    end_date: Date;
    deleted : boolean;
    created_at: Date;
    updated_at: Date;
}

export interface KeyResults {
  id?: string;
  title: string;
}

export type NodeCategory = 'MAIN' | 'MISSION' | 'GOCHAT' | 'GOLEARN' | 'TECHWATCH' | 'VISIBILITY' | 'NONE';

//export const duration_dict: { [key in Duration]: string } = {
export const nodeCategory_dict: { [key in NodeCategory]: string } = {
  'MAIN': 'Main',
  'MISSION': 'Job roles & Projects',
  'GOCHAT': 'Networking',
  'GOLEARN': 'Training & certifications',
  'TECHWATCH': 'Techwatch',
  'VISIBILITY': 'Visibility',
  'NONE': 'None'
}

export type NodeStatus = 'TO_DO' | 'IN_PROGRESS' | 'DONE';

export type NodeType = 'STEP' | 'PROJECT' | 'TASK' | 'CATEGORY';

export interface Question {
  id?: string;
  title: string;
}

export interface PlanId {
  id: string;
}

export type Path = "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER";

export interface MessagePrompt {
    role: 'system' | 'user' | 'assistant';
    content: string;
  }

export interface BetterCallGPTDto {
    plan: Plan;
    messages: MessagePrompt[];
    promptChoice: string | undefined;
}

export interface inputFirstPromptAPI {
  title: string;
  description ?: string;
  resources?: number;
  entertainment?: number;
  confidence: number;
  coherence: number;
  skills ?: string;
  tools ?: string;
  soft_skills ?: string;
  context: string;
  path: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER";
  questions ?: Array<string>;
  start_date ?: Date;
  end_date ?: Date;
}

export interface CreationPlan {
  id?: string;
  title?: string;
  proposed_title?: string;
  description ?: string;
  resources?: number;
  entertainment?: number;
  confidence?: number;
  coherence?: number;
  skills?: string;
  tools?: string;
  soft_skills?: string;
  context?: string;
  path?: "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER";
  questions?: Question[];
  start_date?: Date;
  end_date?: Date;
  creating_plan?: Boolean;
  current_step?: number;
}

export interface FirstPromptAPI {
    global_title: string;
    year_1_title: string;
    year_1_description: string;
    year_2_title: string;
    year_2_description: string;
    year_3_title: string;
    year_3_description: string;
    year_4_title: string;
    year_4_description: string;
    year_5_title: string;
    year_5_description: string;
}

export interface InputSecondPromptAPI {
  global_title: string;
  year_1:{
    objective: string;
    description?: string;
    keyResults?: string[];
  };
  year_2:{
    objective: string;
    description?: string;
    keyResults?: string[];
  };
  year_3:{
    objective: string;
    description?: string;
    keyResults?: string[];
  };
  year_4:{
    objective: string;
    description?: string;
    keyResults?: string[];
  };
  year_5:{
    objective: string;
    description?: string;
    keyResults?: string[];
  };
}