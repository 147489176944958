import { useProfileHookActions, useSelectedProfilePage } from "../../hooks/profile-hook";
import { SidebarGeneral } from "../../components/Sidebar-general";
import ProfileCareer from "./_components/profile-pages/profile-career";
import ProfileCommunity from "./_components/profile-pages/profile-community";
import ProfileContacts from "./_components/profile-pages/profile-contacts";
import ProfileGeneral from "./_components/profile-pages/profile-general";
import ProfileTasks from "./_components/profile-pages/profile-tasks";
import ProfileTechwatch from "./_components/profile-pages/profile-techwatch";
import ProfileVisibility from "./_components/profile-pages/profile-visibility";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalProvider } from "./_components/modals/modal-provider";
import { cn } from "../../lib/utils";

//export const userProfiles = ['General', 'Career', 'Techwatch', 'Contacts', 'Visibility', 'Commmunity', 'Tasks'];
export const userProfiles = ['General', 'Techwatch', 'Commmunity', 'Contacts'];

const Profile = () => {
    
    // Define a mapping of membership to component
    const userProfilesComponents: Record<string, React.ElementType> = {
        General: ProfileGeneral,
        Tasks: ProfileTasks,
        Techwatch: ProfileTechwatch,
        Contacts: ProfileContacts,
        Career: ProfileCareer,
        Commmunity: ProfileCommunity,
        Visibility: ProfileVisibility,
        // Add other memberships and their corresponding components
        //DefaultNavItem: ProfileGeneral, // To be replaced with a neutral component 
    };
    //const selectedProfilePage = useSelectedProfilePage();
    //const setSelectedProfilePage = useProfileHookActions();
    const { setSelectedProfilePage } = useProfileHookActions();
    const selectedProfilePage = useSelectedProfilePage();
    //console.log('setSelectedProfilePage : ', selectedProfilePage)

    // Default profile page to be redirected to if none is selected
    const defaultProfilePage = "/Profile/General";
    const navigate = useNavigate();
    const location = useLocation();


    // Ensure there is always a selected profile page
    useEffect(() => {
        if (!selectedProfilePage) {
            setSelectedProfilePage('General');
        }
    }, [selectedProfilePage, setSelectedProfilePage]);

    return (
        <>
        <ModalProvider />
        <div className="flex flex-grow h-full w-full">
            <div className="hidden md:flex flex-col w-2/12 px-1 py-1 h-full">
                {/* Left Sidebar Menu */}
                <SidebarGeneral />
            </div>
            {/* Main Content Area */}
            <div 
                //className="flex-1 overflow-auto w-10/12 pt-2"
                className={cn("flex-1 w-10/12 pt-2",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            >
                {userProfiles
                    .filter(profile => profile === selectedProfilePage)
                    .map(profile => {
                        // Dynamically select the component based on the membership
                        //const SpecificComponent = userProfilesComponents[profile] || userProfilesComponents['DefaultNavItem'];
                        const SpecificComponent = userProfilesComponents[profile] || userProfilesComponents['General']; // Default to General if not found
                        return (
                            <SpecificComponent />
                        );
                    })}
            </div>
        </div>
        </>
     );
}

export default Profile;