import axiosInstance from "../api";

export interface MessageData {
    id: string;
    content: string;
    created_at: string;
    updated_at: string;
    deleted : string;
    member_id : string;
    channel_id : string;

}

export interface GetMessage {
    id: string;
    content: string;
    created_at: Date;
    updated_at: Date;
    deleted: string;
    member_id: string;
    channel_id: string;
    user_name: string;
    avatar?: string;
    member_role : "GUEST" | "MODERATOR" | "ADMIN";
  }
  

export const fetchMessages = async (channelId: string): Promise<GetMessage[]> => {

    const response = await axiosInstance.get<GetMessage[]>(`/com-message/all/${channelId}`);

    return response.data;
};

export const fetchCreateMessage = async (channelId: string, newMessage: {content: string}): Promise<MessageData> => {

    const response = await axiosInstance.post<MessageData>(`/com-message/create/${channelId}`, newMessage);

    return response.data;
};

export const fetchLimitedMessages = async (channelId: string, page: number, limit: number): Promise<GetMessage[]> => {
    const response = await axiosInstance.get<GetMessage[]>(`/com-message/last/${channelId}`, {
        params: {
            page,
            limit,
        },
    });
    return response.data;
  };

  export const fetchReportMessage = async (messageId: string): Promise<MessageData> => {

    const response = await axiosInstance.get<MessageData>(`/com-message/reportMessage/${messageId}`);

    return response.data;
};