import axiosInstance from "../api";

export interface GetMember {
    memberId: string;
    memberRole: string;
}

export interface GetMembers {
    id: string;
    channel_id: string;
    role: "GUEST" | "MODERATOR" | "ADMIN";
    status: string;
    user_name: string;
    image_url?: string;
  }

export interface UpdateMember {
    name: string;
    role: string;
}

export interface Member {
    name: string;
}
  
export const fetchGetCurrentMember = async (channelId: string): Promise<GetMember> => {

    const response = await axiosInstance.get<GetMember>(`/com-member/currentMember/${channelId}`);

    return response.data;
};

export const fetchGetMembersFromChannel = async (channelId: string): Promise<GetMembers[]> => {

    const response = await axiosInstance.get<GetMembers[]>(`/com-member/all/${channelId}`);

    return response.data;
};

export const fetchUpdateRoleMember = async (channelId: string, updateMember: UpdateMember): Promise<UpdateMember> => {

    const response = await axiosInstance.patch<UpdateMember>(`/com-member/updateRole/${channelId}`, updateMember);

    return response.data;
};

export const fetchDeleteMember = async (channelId: string, deleteMember: {name:string}): Promise<Member> => {

    const response = await axiosInstance.delete<Member>(`/com-member/removeMember/${channelId}`, {
        data: deleteMember,
    });

    return response.data;
};

export const fetchInviteMember = async (addMember: {name:string}, channelId: string,): Promise<Member> => {

    const response = await axiosInstance.post<Member>(`/com-member/invite/${channelId}`, addMember);

    return response.data;
};

export const fetchLeaveChannel = async (channelId: string)=> {

    const response = await axiosInstance.delete(`/com-member/leaveChannel/${channelId}`);

    return response.data;
};