import FeedsTimeline from "./_components/FeedsTimeline";
import { useEffect, useRef, useState } from "react";
import { useSelectedTab, useTechwatchStoreActions } from "../../hooks/techwatch-store";
import { SidebarGeneral } from "../../components/Sidebar-general";
import BottomNavTechwatch from "./_components/BottomNavTechwatch";
import ProfileTechwatch from "../Profile/_components/profile-pages/profile-techwatch";
import { fetchAllSelectedTags } from "../../api/techwatch/api-tec-profile";
import { FeedCardSkeleton } from "./_components/feedCard";
import Footer from "../../components/Footer";
import { cn } from "../../lib/utils";


const TechWatch = () => {

    const { setSelectedTab } = useTechwatchStoreActions();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const selectedTab = useSelectedTab();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //console.log('use effect index - tab', selectedTab);
        const fetchTags = async () => {
            setIsLoading(true);
            try {
                const tags = await fetchAllSelectedTags();
                setSelectedTags(tags);
                setIsLoading(false);
                if (tags.length === 0) {
                    //console.log('selected tags - ', tags.length);
                    setSelectedTab('Profile');
                }
            } catch (error) {
                //console.error("Failed to fetch tags:", error);
                setIsLoading(false);
            }
        };
        

        // redirect to profile page if no tags selected
        if (selectedTab === "Personalized") {
            fetchTags();
        } 

    }, [selectedTab]);

    /*
    if (isLoading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }
    */

    return ( 
        <div className="flex flex-grow h-full w-full">
            <div className="hidden md:flex flex-col w-2/12 px-1 py-1 h-full dark:bg-[#1E1F22]">
                <SidebarGeneral />
            </div>
            {/* Main Content Area */}
            <div 
                //className="flex-1 flex flex-col overflow-auto w-10/12"
                className={cn("flex-1 flex flex-col w-10/12",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            >
                {/* Scrolling timeline container */}
                {selectedTab === "Profile" ? (
                    <ProfileTechwatch />
                ) : (
                    isLoading ? (
                        <div>
                            <FeedCardSkeleton />
                        </div>
                    ) : (
                        <div 
                            //className="overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800"
                            ref={scrollContainerRef} 
                            style={{ 
                                //paddingBottom: '50px' 
                            }}
                        >
                            <FeedsTimeline scrollContainerRef={scrollContainerRef}/> 
                        </div>
                    )
                )}
                {/* Fixed Bottom Navigation Bar */}
                <div className="w-full fixed bottom-0 left-0 right-0 z-50">
                    <BottomNavTechwatch />
                </div>
                
            </div>
        </div>
     );
}

export default TechWatch;