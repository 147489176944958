import * as React from "react";
import { useTranslation } from "react-i18next";

import { Copy, Search } from 'lucide-react';

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

// additionals imports for fuzzy matching on all columns
import {
    Column,
    //Table,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    sortingFns,
    FilterFn,
    SortingFn,
    FilterFns,
  } from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import { fuzzySort } from "./contacts-table-columns";
//end of additionals imports for fuzzy matching on all columns

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

import { Button } from "../../../../components/ui/button";

import { cn } from "../../../../lib/utils";

import { DataTablePagination } from "./contacts-table-pagination";
import { getContactsHookActions, useIsLeftPanelVisible } from "../../../../hooks/contact-hook";
import { Switch } from "../../../../components/ui/switch";
import ContactCard from "./_components/ContactCard";
import { ActionTooltip } from "../../../../components/action-tooltip";
import { useModal } from "../modals/use-modal-store";
import { ContactGroup, ContactInterest } from "../../../../api/contacts/interface-contact";
import { ContactTableElement } from "../../../../hooks/contact-store";
import { Badge } from "../../../../components/ui/badge";

const sampleData: ContactTableElement[] = [
  {
    id: "1",
    first_name: "John",
    last_name: "Doe",
    job_title: "CEO",
    company_name: "Doe Inc.",
    location: "New York",
    email: "john.doe@doe.com",
    phone_number: "+1 314 159 265",
    con_contacts_groups: [{con_groups: {id: '1', name:'Partners'}}],
    con_contacts_interests: [{con_interests: {id: '1', name:'Design & UX'}}],
    con_interactions: [],
  },
  {
    id: "2",
    first_name: "Jane",
    last_name: "Doe",
    job_title: "CTO",
    company_name: "Doe Inc.",
    location: "New York",
    email: "jane.doe@doe.com",
    phone_number: "+1 662 607 015",
    con_contacts_groups: [{con_groups: {id: '1', name:'Former Clients'}}],
    con_contacts_interests: [{con_interests: {id: '1', name:'Digital Marketing'}}],
    con_interactions: [],
  },
  {
    id: "3",
    first_name: "Annie",
    last_name: "Hall",
    job_title: "COO",
    company_name: "Smith Inc.",
    location: "London",
    email: "annie.hall@smith.com",
    phone_number: "+44 602 214 076",
    con_contacts_groups: [{con_groups: {id: '1', name:'Partners'}}],
    con_contacts_interests: [{con_interests: {id: '1', name:'Entrepreneurship'}}],
    con_interactions: [],
  },
];

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  handleOpenSheetFunction: (newContact?: boolean, contactId?: string) => void;
}

// Type pour la visibilité des colonnes
type ColumnVisibility = {
  [key: string]: boolean; // Vous pouvez restreindre davantage les clés si nécessaire
};

//handle Fuzzy matching on all columns feature
declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
        standard: FilterFn<unknown> 
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const getCustomRowValue = (row: any, columnId: string) => {

  let rowValue = '';
  if (columnId === "con_contacts_groups") {
    // Extract group names and concatenate them into a single string
    const groups = row.original.con_contacts_groups.map((group: ContactGroup) => group.con_groups.name).join(' ');
    rowValue = groups.trim().toLowerCase();
  } else if (columnId === "interests") {
    // Extract interest names and concatenate them into a single string
    const interests = row.original.con_contacts_interests.map((interest: ContactInterest) => interest.con_interests.name).join(' ');
    rowValue = interests.trim().toLowerCase();
  } else {
    rowValue = (row.getValue(columnId) as string)?.trim().toLowerCase() || '';
  }

  return rowValue;

};


const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    //clean the value and the row value
    //let rowValue = (row.getValue(columnId) as string).trim().toLowerCase();
    let searchValue = value.trim().toLowerCase();

    // Special case for ⁠ parent_elt ⁠ column to use boardTitle
    let rowValue = getCustomRowValue(row, columnId);


    // Rank the item
    const itemRank = rankItem(rowValue, searchValue);
 
    // Store the itemRank info
    addMeta({
      itemRank,
    })
  
    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const standardFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    //let rowValue = (row.getValue(columnId) as string).trim().toLowerCase();
    let searchValue = value.trim().toLowerCase();
    // Special case for ⁠ parent_elt ⁠ column to use boardTitle
    let rowValue = getCustomRowValue(row, columnId);
    
    return typeof rowValue === 'string' && rowValue.includes(searchValue);
    //return rowValue.includes(searchValue);
  };

export function DataTable<TData, TValue>({
  columns,
  data,
  handleOpenSheetFunction,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('') //Global filter fuzzy matching
  const [isFuzzySearchEnabled, setIsFuzzySearchEnabled] = React.useState(true); //Global filter fuzzy matching
  //const isMediumOrLarger = useMediaQuery({ query: '(min-width: 768px)' });
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
 /*
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(() => {
 
    const initialVisibility: VisibilityState = {};
    columns.forEach(column => {
      initialVisibility[column.id!] = column.id === 'groups' || column.id === 'interests' ? isMediumOrLarger : true;
    });
    return initialVisibility;
  });
  */
/*
 // Configuration de React Table
const tableInstance = useReactTable({
  data,
  columns,
  state: {
    sorting,
    columnVisibility,
  },
  onSortingChange: setSorting,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getCoreRowModel(),
  filterFns: {
    text: textFilter
  }
});
*/

/*
React.useEffect(() => {
  // Mettre à jour la visibilité des colonnes en fonction de la taille de l'écran
  setColumnVisibility(current => ({
    ...current,
    groups: isMediumOrLarger,
    interests: isMediumOrLarger,
  }));
}, [isMediumOrLarger]);
  //ESSAI sur les colonnes filtrables et selectionnées en fonction de la taille de l'écran. Version précédente ci dessous
  //const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
*/

  const [rowSelection, setRowSelection] = React.useState({})
  const { t } = useTranslation();

  const { onOpen } = useModal();

  const openUploadContactsModal = () => {
    onOpen("uploadContacts"); // Ouvre le modal d'upload de contacts
  };

  // To be deleted 18 10 2024
  //const contactsData: ContactTableElement[] = data.length > 0 ? data : sampleData;

  const table = useReactTable({
    data,
    columns,
    filterFns: {     //global fuzzy match
        fuzzy: fuzzyFilter,
        standard: standardFilter,
      },
    globalFilterFn: isFuzzySearchEnabled ? fuzzyFilter : standardFilter, //global fuzzy match
    
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter, //global fuzzy match
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    
    state: {
      sorting,
      columnFilters,
      globalFilter, //global fuzzy match
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: 0,  // Set the default page index (first page)
        pageSize: 20,  // Set the default page size
      },
    },
  })

  //handle left panel visibility
  const { setIsLeftPanelVisible, setSelectedContact } = getContactsHookActions();
  const isLeftPanelVisible = useIsLeftPanelVisible();

  const applyGlobalFilter = async () => {
    // workaround : we need to force the table to reapply the global filter
    // the global filter value doesn't change so we need to force the table to reapply the filter
    // Temporarily store the current global filter value
    const currentFilter = globalFilter;
    setGlobalFilter(" ");
    setTimeout(() => {
      setGlobalFilter(currentFilter);
    }, 0); // The timeout of 0 ms is often enough to delay the execution until after the current call stack
  };

  //handle the rerendering of the table if parameters change 
  React.useEffect(() => {
    //setGlobalFilter(filter => ({ ...filter, fuzzy: isFuzzySearchEnabled }));
    applyGlobalFilter();
  }, [isFuzzySearchEnabled]);

  return (
    <div className="h-full w-full">
        <div className="flex justify-start gap-x-2 items-end">
            <h2 id="contacts-section" className="flex text-base sm:text-2xl font-bold m-0 p-0">
              {t("contacts.contactsTable.top.title")}
            </h2>
            <Badge variant="default" className="bg-contacts_primary text-white text-xs sm:text-sm px-3 py-0 mb-1">
              {table.getFilteredRowModel().rows.length}
            </Badge>
        </div>
        <div className="flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center py-1 sm:py-4">
          <div className="flex flex-row justify-start gap-4 sm:mb-0">
              <div className="flex-grow max-w-md">
                  <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={value => {
                      //console.log('Global filter set to:', value);
                      setGlobalFilter(String(value));
                  }}
                  className="flex h-10 w-full bg-[#F3F7F6] dark:bg-[#020504] border border-input px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-[#58AD9A] dark:focus:border-[#437A6E] focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                  placeholder={(data && data.length > 0) ? t("contacts.contactsTable.top.placeholder") : t("contacts.contactsTable.top.placeholderEmptyTable")}
                  disabled={data && data.length === 0}
                  />
              </div>
              <ActionTooltip side="bottom" align="center" label={isFuzzySearchEnabled ? (t("contacts.contactsTable.top.switchtoExactMatch")) : (t("contacts.contactsTable.top.switchtoFlexibleMatch"))}>
                <div 
                    className="flex items-center" //shrink-0
                >
                  <Switch 
                  id="isFuzzySearchEnabledSwitch" 
                  checked={isFuzzySearchEnabled}
                  onCheckedChange={e => setIsFuzzySearchEnabled(e)}
                  className="scale-75 data-[state=checked]:bg-[#61B6A3] data-[state=unchecked]:bg-[#DBEFE9] dark:data-[state=checked]:bg-[#437A6E] dark:data-[state=unchecked]:bg-[#172A26]"
                  disabled={data && data.length === 0}
                  />
              
                  <label 
                  htmlFor="isFuzzySearchEnabledSwitch"
                  className="text-sm font-medium text-muted-foreground ml-2"
                  >
                      {t("contacts.contactsTable.top.flexibleMatch")}
                  </label>
                </div>
              </ActionTooltip>
            </div>
            <div 
              className="flex flex-col sm:flex-row items-start sm:items-center gap-2" //shrink-0
            >
              <Button
                onClick={() => openUploadContactsModal()}
                className="hidden md:flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
              >
                Upload Contacts
              </Button>
              <Button
                  onClick={() => handleOpenSheetFunction(true)}
                  className="hidden md:flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
              >
                  {t("contacts.contactsTable.top.add")}
              </Button>
            </div>
        </div>
        <div className="hidden md:flex md:flex-col border-none">
            <Table>
                <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow
                      key={headerGroup.id}
                      className="bg-[#A0D3C6] hover:bg-[#C9E9E0] dark:bg-[#2D544B] dark:hover:bg-[#437A6E]"
                      >
                    {headerGroup.headers.map((header) => {
                        return (
                        <TableHead 
                            key={header.id}
                            // Adding px-0 to remove padding and justify-items-start align the text to the left for all columns except the select column
                            //className={cn("text-xs md:text-sm lg:text-base text-[#1A3C34] dark:text-[#BCECDF]", 
                            //    header.id === "select" ? " justify-center" : "justify-items-start px-0"
                            //    )}
                            className={`${
                              header.id === 'select' ? 'justify-center' : 'justify-items-start px-0'
                            } text-xs md:text-sm lg:text-base text-[#1A3C34] dark:text-[#BCECDF]`}>
                            {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                                )}
                        </TableHead>
                        )
                    })}
                    </TableRow>
                ))}
                </TableHeader>
                <TableBody>
                {table.getRowModel().rows?.length > 0 ? (
                    table.getRowModel().rows.map((row, index) => (
                    <TableRow
                        key={index} //{row.id}
                        className={`${index % 2 === 0 ? "dark:hover:bg-[#172A26] hover:bg-[#DBEFE9]" : "bg-[#ECF3F1] hover:bg-[#C9E9E0] dark:bg-[#111917] dark:hover:bg-[#193831]"}`} // Alterne entre bg-gray-50 et bg-white
                        data-state={row.getIsSelected() && "selected"}
                        onClick={() => {
                            //console.log((row.original as { id: string }).id);
                            handleOpenSheetFunction(false, (row.original as { id: string }).id);
                        }}
                    >
                        {row.getVisibleCells().map((cell, indexCell) => (
                        <TableCell 
                          key={indexCell} //{cell.id} 
                          className='px-2 py-1 text-sm' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                        ))}
                    </TableRow>
                    ))
                ) : ( // No results handling depending on the presence of data
                    (data && data.length > 0) ? (
                      <TableRow>
                        <TableCell colSpan={columns.length} className="h-24 text-center">
                          <div className="flex flex-col gap-y-2 items-center justify-center">
                            <span className="flex flex-row items-center justify-center gap-x-2">
                              <Search className="h-6 w-6" />
                              {t("contacts.contactsTable.message.noResults")}
                            </span>
                            <Button
                                onClick={() => handleOpenSheetFunction(true)}
                                className="hidden md:flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
                            >
                                {t("contacts.contactsTable.top.add")}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      sampleData.map((row, index) => (
                        <TableRow
                            key={index} //{row.id}
                            className={`${index % 2 === 0 ? "dark:hover:bg-[#172A26] hover:bg-[#DBEFE9]" : "bg-[#ECF3F1] hover:bg-[#C9E9E0] dark:bg-[#111917] dark:hover:bg-[#193831]"}`} // Alterne entre bg-gray-50 et bg-white
                            //data-state={ && "selected"}
                            onClick={() => {
                                //console.log((row.original as { id: string }).id);
                                handleOpenSheetFunction(true);
                            }}
                        >
                            <TableCell 
                              key={index + "_1"}  
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {t("contacts.contactsTable.message.example")}{row.first_name}
                            </TableCell>
                            <TableCell 
                              key={index + "_2"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.last_name}
                            </TableCell>
                            <TableCell 
                              key={index + "_3"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.job_title}
                            </TableCell>
                            <TableCell 
                              key={index + "_4"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.company_name}
                            </TableCell>
                            <TableCell 
                              key={index + "_5"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.location}
                            </TableCell>
                            <TableCell 
                              key={index + "_6"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.con_contacts_groups.map((group: ContactGroup) => (
                                  <Badge key={index + group.con_groups.id} variant="outline">{group.con_groups.name}</Badge>
                                ))}
                            </TableCell>
                            <TableCell 
                              key={index + "_7"}
                              className='px-2 py-1 text-sm italic' // pl-4 pr-4 text-xs md:text-sm lg:text-base
                            >
                                {row.con_contacts_interests.map((interest: ContactInterest) => (
                                  <Badge key={index + interest.con_interests.id} variant="outline">{interest.con_interests.name}</Badge>
                                ))}
                            </TableCell>
                        </TableRow>
                        )
                    ) 
                ))}
                </TableBody>
            </Table>
            <DataTablePagination table={table} />
        </div>
        <div className="flex flex-col h-full w-full md:hidden">
          <Button
            onClick={() => handleOpenSheetFunction(true)}
            className="md:hidden mt-1 flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
          >
              {t("contacts.contactsTable.top.add")}
          </Button>
          {table.getFilteredRowModel().rows?.length ? (
            table.getFilteredRowModel().rows.map((row, index) => (
              <div
                key={row.original.id}
                className={`border text-xs mt-2 ${
                  index % 2 === 0 ? "bg-[#EEF1F0] dark:bg-[#172A26]" : "bg-[#E6EDEB] dark:bg-[#111917]"
                }`}
              >
                <ContactCard contactXsId={(row.original as { id: string }).id} handleOpenSheetFunction={handleOpenSheetFunction} />
              </div>
          ))) : ( // No results handling for mobile depending on the presence of data
            data && data.length > 0 ? (
              <div className="flex flex-col gap-y-4 items-center justify-center">
                <span className="flex flex-row items-center justify-center gap-x-2 mt-4">
                  <Search className="h-6 w-6" />
                  {t("contacts.contactsTable.message.noResults")}
                </span>
                <Button
                    onClick={() => handleOpenSheetFunction(true)}
                    className="md:hidden flex bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
                >
                    {t("contacts.contactsTable.top.add")}
                </Button>
              </div>
            ) : (
              <div>
                {sampleData.map((row, index) => (
                  <div
                    key={index}
                    className={`flex border items-center text-xs mt-2 italic p-2 space-x-4 ${
                      index % 2 === 0 ? "bg-[#EEF1F0] dark:bg-[#172A26]" : "bg-[#E6EDEB] dark:bg-[#111917]"
                    }`}
                    onClick={() => handleOpenSheetFunction(true)}
                  >
                    <div className="bg-[#D4E8E3] dark:bg-[#22453D] w-24 h-24 rounded-full flex items-center justify-center">
                      <p className="text-contacts_primary dark:text-[#78CDB9] text-4xl font-bold flex justify-center items-center ">
                          {row.first_name?.charAt(0)}
                          {row.last_name?.charAt(0)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                    <div className="flex justify-start items-center"> 
                      <h1 className="font-bold text-base text-contacts_primary">
                        {t("contacts.contactsTable.message.example")}{row.first_name} {row.last_name}
                      </h1>
                    </div>
                    <div className="flex flex-col w-full h-full text-sm">
                      <div>{row.job_title} {row.company_name && `@${row.company_name}`}</div>
                      <div>{row.location}</div>
                      <div className="flex flex-col md:flex-row gap-x-1">
                          <div className="mr-2 flex flex-row gap-x-1 cursor-pointer items-center">
                              <span className="text-sm md:text-base">{row.email}</span>
                              {row.email && (<Copy className="h-3 w-3 md:h-3 md:w-3" />)}
                          </div>
                          <div className="hidden md:flex">- </div>
                          <div className="md:ml-2 flex flex-row gap-x-1 cursor-pointer items-center">
                              <span className="text-sm md:text-base">{row.phone_number}</span>
                              {row.phone_number && (<Copy className="h-3 w-3 md:h-3 md:w-3" />)}    
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            )
          )}
        </div>
    </div>
  );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)
  
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)
  
      return () => clearTimeout(timeout)
    }, [value, debounce, onChange])
    
    //console.log('Debouncer set to:', value);

    return (
      <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
  }

  /*

  // Dropdown menu to show/hide columns -> Hidden for now (03/09/2024) since it doesn't fi with UX/UI policy, TO DO: to be put somewhere else in the future

  <DropdownMenu>
    <DropdownMenuTrigger asChild>
        <Button 
        variant="outline"
        className="hidden md:flex ml-auto bg-[#DBEFE9] dark:bg-[#172A26] text-contacts_primary hover:bg-[#58AD9A] hover:text-[#DBEFE9] dark:hover:bg-[#193831] dark:hover:text-[#BCECDF] font-bold py-1 px-2 rounded"
        >
        {t("contacts.contactsTable.top.columns")}
        </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
        {table
        .getAllColumns()
        .filter(
            (column) => column.getCanHide()
        )
        .map((column) => {
            return (
            <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) =>
                column.toggleVisibility(!!value)
                }
            >
                {column.id}
            </DropdownMenuCheckboxItem>
            )
        })}
    </DropdownMenuContent>
</DropdownMenu>
  */