import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchCreateOpportunity } from "../../../../../api/contacts/opportunities/create-opportunity"
import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Skeleton } from "../../../../../components/ui/skeleton";
import { Textarea } from "../../../../../components/ui/textarea";
import { cn } from "../../../../../lib/utils";


interface OppyTitleUnitProps {
    data: Opportunity;
    setNewOppy?: React.Dispatch<React.SetStateAction<Boolean>>;
}

const priority = ["LOW", "MEDIUM", "HIGH"];

export const OppyTitleUnit = ({
    data,
    setNewOppy,
}: OppyTitleUnitProps) => {

    const { t } = useTranslation();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            setTitle(data.title);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity");
        }
    });

    const { execute: executeCreateCard } = useAction(fetchCreateOpportunity, {
        onSuccess: (data: Opportunity) => {
            
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" created`);
            setTitle(data.title);
            setIsEditing(false);
            setNewOppy && setNewOppy(false);
        },
        onError: (error) => {
            toast.error("An error occurred while creating the opportunity");
        }
    });

    const [title, setTitle] = useState(''); //data?.title ||

    // Adding a state to manage the editing state of the title (to avoid the onBlur event to be triggered when clicking on the title)
    const [isEditing, setIsEditing] = useState(data?.id ? false : true);

    const onBlur = () => {
        if (title !== data.title) {
            inputRef.current?.form?.requestSubmit();
        } else {
            setIsEditing(false);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const title = formData.get("title") as string;
        
        if (title === data.title) {
            setIsEditing(false);
            return;
        }

        if (!data.id) {
            executeCreateCard({
                title,
            })
        } else {
            executeUpdateCard({
                id: data.id,
                title,
            });
        }
    }
    
    useEffect(() => {
        if (isEditing && inputRef.current) {
            const adjustment = 2;
            inputRef.current.style.height = 'auto'; // Reset height to shrink back if text is deleted
            const idealHeight = inputRef.current.scrollHeight + adjustment;
            inputRef.current.style.height = `${idealHeight}px`;
        }

      }, [isEditing, title]);

    
    

    // Initialize state variables
    useEffect(() => {
        setTitle(data?.title);
        //setDueDate(data.due_date ? new Date(data.due_date) : undefined);
        if (!data.id) {
            setIsEditing(true);
            setTimeout(() => {
                inputRef.current?.focus();
            });
        }
    }, [data]);

    return (
        <div className="flex items-start gap-x-3 w-full text-xs md:text-sm">
            <form onSubmit={onSubmit} className="w-full">
                {isEditing ? (
                    <Textarea
                        placeholder={t("contacts.opportunities.headerOppy.titlePlaceholder")}
                        ref={inputRef}
                        onBlur={onBlur}
                        id="title"
                        name="title"
                        //defaultValue={title}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        rows={1}
                        className={cn("w-full font-semibold items-center text-sm text-contacts_primary md:text-lg px-1 pt-1 bg-transparent dark:bg-transparent dark:text-[#FFDECD] border-none relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-contacts_primary hover:border-contacts_primary",
                            !title && "border-1px min-h-4 min-w-12"
                        )}
                        style={{ minHeight: `36px`, lineHeight: '1.5' }}
                    />
                ) : (
                    <div
                        className={cn("w-full font-semibold text-base md:text-lg text-contacts_primary dark:bg-transparent dark:text-[#FFDECD] dark:hover:border-contacts_primary dark:hover:border-[1px] px-1 py-1 items-center cursor-pointer",
                            !title && "border-1px bg-contacts_primary/10 h-9 w-full items-center rounded-sm"
                        )}
                        onClick={() => {
                            setIsEditing(true);
                            setTimeout(() => {
                                inputRef.current?.focus();
                            });
                        }}
                    >
                        {title ? title : t("contacts.opportunities.headerOppy.titlePlaceholder")}
                    </div>
                )}
            </form>
        </div>
    );
};

OppyTitleUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };



/*
<Layout className="h-5 w-5 mt-1 text-neutral-700 dark:text-neutral-300" />

    const changeDuration = (status: Duration | undefined) => {
        if (status && Object.keys(duration_dict).includes(status)) {
            const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
            const readableDuration = duration_dict[status as keyof typeof duration_dict];
            executeUpdateCard({
                duration: status,
                id: data.id,
                parent_elt: parent_elt,
            });
            //console.log(`Duration set to: ${readableDuration}`);
        }
    }

    const changeEnergy = (status: Energy | undefined) => {
        if (status && energy.includes(status)) {
            const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
            executeUpdateCard({
                energy: status,
                id: data.id,
                parent_elt: parent_elt,
            });
        }
    }

    const [dueDate, setDueDate] = useState<Date | undefined>();

    const handleDeadlineChange = (date: Date) => {
        const parent_elt = data.parent_elt || selectedBoardElement?.id || '';
        executeUpdateCard({
            due_date: date,
            parent_elt,
            id: data.id,
        });
        setDueDate(date);
    };
*/

/*
<div className="flex flex-row items-center gap-x-2 mt-1.5">
    {data?.list?.title && data.list.title ? (
        <span className="flex text-sm text-muted-foreground m-0 p-0">
            <p className="hidden md:block text-sm text-muted-foreground">
                in list <span className="underline">{data.list.title}</span>
            </p>
        </span>
    ) : (
        <p>pas de liste</p>
    )}
    <span className="flex text-sm text-muted-foreground m-0 p-0">
        <Select
            onValueChange={(value: Duration) => {changeDuration(value)}}
            disabled={!data.id}
        >
            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                <SelectValue placeholder={data.duration ? duration_dict[data.duration] : "Ajouter une durée"} />
            </SelectTrigger>
            <SelectContent>
            <SelectGroup>
                <SelectLabel>{t("tasks.card.edit.duration")}</SelectLabel>
                    {Object.entries(duration_dict).map(([key, value]) => (
                        <SelectItem key={key} value={key}>
                            {value}
                        </SelectItem>
                    ))}
            </SelectGroup>
            </SelectContent>
        </Select>
    </span>
    <span className="flex text-sm text-muted-foreground m-0 p-0">
        <Select
            onValueChange={(value: Energy) => {changeEnergy(value)}}
            disabled={!data.id}
        >
            <SelectTrigger className="w-auto h-auto text-sm border-none p-1 gap-x-1 m-0">
                <SelectValue placeholder={data.energy ? data.energy.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()) : "Medium"} />
            </SelectTrigger>
            <SelectContent>
            <SelectGroup>
                <SelectLabel>{t("tasks.card.edit.energy")}</SelectLabel>
                    {energy.map((value) => (
                        <SelectItem key={value} value={value}>
                            {value.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                        </SelectItem>
                    ))}
            </SelectGroup>
            </SelectContent>
        </Select>
    </span>
    <span 
        // Deadline date picker
        className="flex px-0"
    >
        <Popover>
            <PopoverTrigger asChild disabled={!data.id} >
                <Button
                variant={"outline"}
                className={cn(
                    //"w-[280px] justify-start text-left font-normal",
                    "justify-start text-left font-normal text-sm py-0 px-2 h-8 border-none text-muted-foreground",
                    !dueDate && "text-muted-foreground"
                )}
                >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {dueDate ? format(dueDate, "PPP") : <span className="hidden md:block">
                    {t("tasks.card.edit.deadline")}
                    </span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                mode="single"
                selected={dueDate ? dueDate : undefined}
                onSelect={(value) => value && handleDeadlineChange(value)}
                initialFocus
                />
            </PopoverContent>
        </Popover>
    </span>
</div>
*/