import { Button } from '../../../components/ui/button';
import { fetchLogout } from '../../../api/globals/api-logout';
import { getAuthStoreActions } from '../../../hooks/auth-store';
import { LogOut } from "lucide-react";
// Functions to clear all the hooks and stores
import { getContactsHookActions } from '../../../hooks/contact-hook';
import { getContactStoreActions } from '../../../hooks/contact-store';
import { useCareerHookActions } from '../../../hooks/career-hook';
import { useCareerStoreAll } from '../../../hooks/career-store';
import { shallow } from 'zustand/shallow';
import { useCommunityActions } from '../../../hooks/community-hook';
import { useProfileActions } from '../../../hooks/general-profile';
import { useProfileHookActions } from '../../../hooks/profile-hook';
import { useTaskStoreActions } from '../../../hooks/tasks-store';
import { useTechwatchStoreActions } from '../../../hooks/techwatch-store';
import { ActionTooltip } from '../../../components/action-tooltip';
import { useTranslation } from 'react-i18next';
import { getGeneralHookStoreActions } from '../../../hooks/general-hook';
import { useOpportunityStoreActions } from '../../../hooks/contact-oppy-store';

const Logout = () => {
    // Function to simulate logout
    const { setIsAuthenticated } = getAuthStoreActions();

    // Functions to clear all the hooks and stores
    const { clearAuthStore } = getAuthStoreActions();
    const { clearCareerHook } = useCareerHookActions();
    const { clearCareerStore } = useCareerStoreAll(shallow);
    const { clearCommunityHook } = useCommunityActions();    
    const { clearContactHook } = getContactsHookActions();
    const { clearContactStore } = getContactStoreActions();
    const { clearContactOppyStore } = useOpportunityStoreActions();
    // General hook skipped since we can't log out without closing it
    const { clearGeneralProfileHook } = useProfileActions();
    const { clearProfileHook } = useProfileHookActions();
    // TD DO : check WS hook managed -> I think socket is closed when getting off the page
    const { clearTaskStore } = useTaskStoreActions();
    const { clearTechwatchStore } = useTechwatchStoreActions();
    const { clearGeneralHook } = getGeneralHookStoreActions();
    const { t } = useTranslation();



    const handleLogout = () => {
        // Call the back to remove the tokens
        //console.log('logOut is called');
        const getLogin = async () => {
            try {
                //const response = 
                await fetchLogout();
                //console.log("response axios login: ", response);
                //setIsAuthenticated(false);
                clearAuthStore();
                clearCareerHook();
                clearCareerStore();
                clearCommunityHook();
                clearContactHook();
                clearContactStore();
                clearContactOppyStore();
                clearGeneralProfileHook();
                clearProfileHook();
                clearTaskStore();
                clearTechwatchStore();
                clearGeneralHook();
            } catch (err) {
                //console.log("error: ", err);
            }
        };
        getLogin();
        // Clear the tokens from the store and cookies
        //const { clearTokens } = getAuthStoreActions();
        //clearTokens(); // This clears both the Zustand store and the cookies
        
    };

    return (
        <ActionTooltip label={t("header.logOut")}>
            <Button
            onClick={handleLogout}
            className="w-10 h-10 bg-transparent border-0 text-[#000021] dark:text-neutral_white-200" variant="outline" size="icon"
            >
                <LogOut
                className="h-[1.2rem] w-[1.2rem]"
                //className="px-4 py-2 lg:text-lg text-[#000021] dark:text-slate-50"
                />
            </Button>
        </ActionTooltip>
        
     );
}

export default Logout;