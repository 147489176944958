import { Progress } from "../../../../components/ui/progress";
import { CreationPlan, Plan, inputFirstPromptAPI } from "../../../../api/career/interface-career";
import { useCareerHookActions, useCreationPlan } from "../../../../hooks/career-hook";
import { useState } from "react";
import { title } from "process";
import { fetchCreatePlan, fetchEditPlan } from "../../../../api/career/api-create-plan";
import { FormTextarea } from "../../../../components/form/form-textarea";
import { FormInput } from "../../../../components/form/form-input";
import { useTranslation } from 'react-i18next';


const CreatePlanTitle : React.FC = () => {
    const { t } = useTranslation();
    const creationPlan = useCreationPlan();
    const { setCreationPlan } = useCareerHookActions();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const title = formData.get("title") as string;
        const description = formData.get("description") as string;
        const path = creationPlan?.path;
        const current_step = 2;
        const creating_plan = creationPlan?.creating_plan;

        const creationPlanUpdate = {
            ...creationPlan,
            title,
            description,
            path,
            current_step,
            creating_plan,
        }
        //console.log(creationPlanUpdate);
        let response;
        if (!creationPlan?.id) { 
            response = await fetchCreatePlan(creationPlanUpdate)
        } else {
            response = await fetchEditPlan(creationPlan.id, creationPlanUpdate)
        }
        if (response) {
            //console.log('response ApI', response)
            setCreationPlan(response);
            setTimeout(() => {
                //console.log("creation plan updated", creationPlan);
              }, 1000);
        } else {
            //console.log('no response from the API');
        }
    };
    

  

    return (
        <div className="max-w-xl mx-auto p-6 bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold text-center text-career_primary dark:text-[#FBD5D7] mb-6">{t("career.createPlan.step1.top")}</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="title" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step1.title.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step1.title.description")}
                    </p>
                    <FormInput
                        type="text"
                        id="title"
                        //name="title"
                        className="shadow border rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step1.title.placeholder")}
                        defaultValue={creationPlan?.title}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="description" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step1.description.title")}</label>
                    <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                        {t("career.createPlan.step1.description.description")}
                    </p>
                    <FormTextarea
                        id="description"
                        //name="description"
                        className="mt-2 p-4 block w-full h-48 shadow border rounded py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                        placeholder={t("career.createPlan.step1.description.placeholder")}
                        defaultValue={creationPlan?.description}
                        //onChange={handleInputChange}
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {t("career.createPlan.step1.buttonNext")}
                </button>
            </form>
        </div>
    );
};
 
export default CreatePlanTitle;