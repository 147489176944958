import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type ThemeContextType = {
  theme: string;
  setTheme: (theme: string) => void;
};

// Spécifiez une valeur initiale qui correspond à la structure attendue
const ThemeContext = createContext<ThemeContextType>({
  theme: 'dark', // Valeur initiale par défaut
  setTheme: theme => {} // Fonction vide par défaut
});

type ThemeProviderProps = {
  children: ReactNode;
  attribute?: string;
  defaultTheme?: string;
  enableSystem?: boolean;
  storageKey?: string;
  className?: string;
};

export const ThemeProvider = ({ 
    children,
    attribute = 'class',
    defaultTheme = 'light',
    enableSystem = false,
    storageKey = 'theme',
    className = "",
}: ThemeProviderProps) => {

  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    // Détecter le thème système si `enableSystem` est true
        if (enableSystem && window.matchMedia) {
            const systemTheme = window.matchMedia('(prefers-color-scheme: light)').matches ? 'dark' : 'light';
            setTheme(systemTheme);
        } else {
            const storedTheme = window.localStorage.getItem(storageKey) || defaultTheme;
            setTheme(storedTheme);
        }
    }, [enableSystem, defaultTheme, storageKey]);

  useEffect(() => {
        window.localStorage.setItem(storageKey, theme);
        if (attribute === 'class') {
        document.body.className = theme;
        } else {
        document.body.setAttribute(attribute, theme);
        }
    }, [theme, attribute, storageKey]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
