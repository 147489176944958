import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
//import { useModal } from "./use-modal-store";
import { Button } from "../../../components/ui/button";
//import { fetchDeletePlan } from "../../../api/career/api-create-plan";
//import { Plan } from "../../../../api/career/interface-career";
//import { useCareerHookActions } from "../../../../hooks/career-hook";
import { useTranslation } from "react-i18next";
import { useDeletePlanModal } from "../../../hooks/tasks-use-card-modal";
import { useAction } from "../../../hooks/use-action";
import { deleteBoard } from "../../../api/tasks/delete-board";
import { toast } from "sonner";
import { useBoards, useTaskStoreActions } from "../../../hooks/tasks-store";

interface BoardOptionsProps {
    id: string;
    name: string;
}

export const DeleteBoardModal = ({ id, name }: BoardOptionsProps) => {
  const { t } = useTranslation();

  //const id = useDeletePlanModal((state) => state.id);
  const isOpen = useDeletePlanModal((state) => state.isOpen);
  const onClose = useDeletePlanModal((state) => state.onClose);

  const boards = useBoards();
  const { deleteBoardFromStore, setSelectedBoard } = useTaskStoreActions();
  const { execute, isLoading } = useAction(deleteBoard, {
    onSuccess: (data) => {
        deleteBoardFromStore(data.id);
        toast.success("Board deleted successfully");
        //move to another board
        if (boards && boards?.length > 0) {
            const availableBoards = boards.filter((board) => board.id !== data.id);
            setSelectedBoard(availableBoards[0].id);
        }
    },
    onError: (error) => {
        toast.error(error);
    }
    });

  const onDelete = () => {
    execute({ id });
    //console.log('delete board with id:', id);
    onClose();
    };

  return (
    <Dialog 
        open={isOpen}
        onOpenChange={onClose}
    >
      <DialogContent className="border-transparent text-center text-tasks_primary dark:text-[#FFDECD] bg-[#F7F5F4] dark:bg-[#1B130F] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-6">
          <DialogTitle className="text-2xl text-center font-bold">
            {t("tasks.board.modals.delete.top")}
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium text-center">
            {t("tasks.board.modals.delete.description")}<br />
            <span className="text-tasks_primary font-semibold">{name}</span> {t("tasks.board.modals.delete.warning")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <Button
              disabled={isLoading}
              onClick={onClose}
              className="bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t("tasks.board.modals.delete.cancel")}
            </Button>
            <Button
              disabled={isLoading}
              className="bg-tasks_primary text-neutral_white-200 hover:bg-[#FFC1A1] hover:text-[#BA4F00] dark:hover:bg-[#542810] dark:hover:text-[#FFA06A]  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={onDelete}
            >
            {t("tasks.board.modals.delete.confirm")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}