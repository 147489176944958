import { 
    AccordionContent, 
    AccordionItem, 
    AccordionTrigger 
} from "../../../../components/ui/accordion";
import { useTranslation } from "react-i18next";


import { cn } from "../../../../lib/utils";

import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";

import { useCareerHookActions, useSelectedPath } from "../../../../hooks/career-hook";
import { useNavigate } from "react-router-dom";
import { useGeneralHookActions } from "../../../../hooks/general-hook";


interface NavItemProps {
    isExpanded: boolean;
    isActive: boolean;
    membership: string; //Organization;
    onExpand: (id: string) => void;
}

export const NavItemCareer = ({
    isExpanded,
    isActive,
    membership,
    onExpand,
}: NavItemProps) => {
    const { t } = useTranslation();

    let navigate = useNavigate();
    const { setSelectedPath } = useCareerHookActions();
    const { setSelectedPlan } = useCareerHookActions();

    // close hamburger menu
    const { closeHamburgerMenuSheet } = useGeneralHookActions();
    
    type PathValue = "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER" | undefined;

    interface PathOption {
    label: string;
    value: PathValue;
    }

    const paths: PathOption[] = [
        { label: t("sidebar.career.overview"), value: undefined },
        { label: t("sidebar.career.current"), value: "CURRENT" },
        { label: t("sidebar.career.alternative"), value: "ALTERNATIVE" },
        { label:  t("sidebar.career.dream"), value: "DREAM" },
        { label: t("sidebar.career.other"), value: "OTHER" },
      ];

    const handlePathSelection = (path: PathValue) => {
        setSelectedPlan(undefined);
        setSelectedPath(path);
        closeHamburgerMenuSheet();
    }

    const selectedTab = useSelectedPath();

    const isSelected = (tab: string) => {
        return tab === selectedTab;
    };


    return (
        <AccordionItem
            value={membership} //organization.id
            className="border-none"
        >
            <AccordionTrigger
                onClick={() => onExpand(membership)} //organization.id
                className={cn(
                    "flex items-center gap-x-2 p-1.5 text-gray-700 dark:text-gray-200 rounded-md cursor-pointer hover:bg-neutral-500/10 transition text-start no-underline hover:no-underline",
                    isActive && !isExpanded && "bg-sky-500/10 text-sky-700"
                    )}
            >
                <div className="flex items-center gap-x-2">
                    <span className="font-medium text-sm">
                        {membership}
                    </span>
                </div>
            </AccordionTrigger>
            
            <AccordionContent className="pt-1 text-neutral-700">
                {paths.map(({ label, value }) => (
                    <Button
                        key={label}
                        size="sm"
                        onClick={() => handlePathSelection(value)}
                        className={cn(
                            "w-full font-normal text-xs justify-start pl-4",
                            selectedTab === value && "bg-[#FAE1E3] dark:bg-[#35161A]"
                            )}
                        variant="ghost"
                    >
                        <span className="text-gray-700 dark:text-gray-200 hover:font-bold hover:text-career_primary dark:hover:text-[#FBD5D7]">{label}</span>
                    </Button>
                ))}
            </AccordionContent>
        </AccordionItem>
    );
};

NavItemCareer.Skeleton = function SkeletonNavItem() {
    return (
        <div className="flex items-center gap-x-2">
            <div className="w-10 h-10 relative shrink-0">
                <Skeleton className="h-full w-full absolute" />
            </div>
            <Skeleton className="h-10 w-full" />
        </div>
    )
}