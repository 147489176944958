import { z } from "zod";

export const UpdateOpportunityStatus = z.object({
  id: z.string().uuid(),
  name: z.optional(z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  })),
  order: z.optional(z.number().nullable()),
});

