import { z } from "zod";

export const UpdateBoard = z.object({
  title: z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  })
  .refine((val) => val !== "Overview", {
    message: "Title cannot be 'Overview'",
  }),
  id: z.string(),
});