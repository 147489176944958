// Desc: Contacts page
// Path: go_beyond/frontend/src/pages/Contacts/index.tsx
// To do:
// [ ] Build the contacts page
// [ ] Add the contacts table
// [ ] Add the best actions table
// [ ] Add the opportunities table
// [ ] Add the profile section
// [ ] Add the left sidebar menu

//import page components
import ConSideBar from "./_components/sidebar";
import ContactsTable from "./_components/contacts-table";
import { SidebarGeneral } from "../../components/Sidebar-general";
import { cn } from "../../lib/utils";
import Footer from "../../components/Footer";
import OpportunitiesComponent from "./_components/opportunities";

const Contacts = () => {
    //console.log('Contacts Page');
    

    return ( 
        <div className="flex flex-grow h-full w-full">
            {/* Left Sidebar Menu */}
            <div className="hidden md:flex flex-col w-2/12 h-full px-1 py-1 dark:bg-[#1E1F22]">
                <SidebarGeneral /> 
            </div>
            {/* Main Content Area */}
            <div 
                //className="flex-grow flex flex-col overflow-auto h-full w-10/12 pt-2"
                className={cn("flex-grow flex flex-col h-full w-10/12 pt-2",
                    "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
                )}
            >
                <ContactsTable />

                {/* Footer */}
                <Footer />
            </div>
        </div>
     );
}

export default Contacts;

/*
<BestActionsTable />

<OpportunitiesTable />
*/