import React from 'react';
import { NavLink } from 'react-router-dom';
import img_career from '../../../img/landing/career.svg';
import img_2 from '../../../img/career_plans/Business mission-bro.svg';
import img_3 from '../../../img/career_plans/Moving forward-bro.svg';
import img_4 from '../../../img/career_plans/Design thinking-bro.svg';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';



const LandingCareer = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-4">
            <ScrollArea className="flex-grow  w-full overflow-auto rounded-md">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h1 className="text-4xl font-bold text-career_primary dark:text-dark_career">Career</h1>
                        <p className="mt-4 text-lg lg:text-xl text-[#AE3F51] dark:text-[#FBD5D7]">
                            {t("landing.landingCareer.tagline")}
                        </p>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_career} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title1")}</h2>
                                <p className="mt-2 text-[#6E0021] dark:text-[#FBD5D7]">
                                    {t("landing.landingCareer.desc1")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 font-bold px-4 py-2 rounded shadow bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:ring-[#803C45]">
                                        {t("landing.landingCareer.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F9EFF0] dark:bg-[#1A1213] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_2} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title2")}</h2>
                                <p className="mt-2 text-[#6E0021] dark:text-[#FBD5D7]">
                                    {t("landing.landingCareer.desc2")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 font-bold px-4 py-2 rounded shadow bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:ring-[#803C45]">
                                        {t("landing.landingCareer.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-10">
                            <img src={img_3} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title3")}</h2>
                                <p className="mt-2 text-[#6E0021] dark:text-[#FBD5D7]">
                                    {t("landing.landingCareer.desc3")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 font-bold px-4 py-2 rounded shadow bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:ring-[#803C45]">
                                        {t("landing.landingCareer.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F9EFF0] dark:bg-[#1A1213] p-10 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-10">
                            <img src={img_4} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-2xl font-semibold text-[#AE3F51] dark:text-[#FF95A0]">{t("landing.landingCareer.title4")}</h2>
                                <p className="mt-2 text-[#6E0021] dark:text-[#FBD5D7]">
                                    {t("landing.landingCareer.desc4")}
                                </p>
                                <div className='py-3'>
                                    <NavLink to="/signup" className="mt-4 font-bold px-4 py-2 rounded shadow bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:ring-[#803C45]">
                                        {t("landing.landingCareer.cta1")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <NavLink to="/signup" className="mt-4 bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] focus:ring-[#803C45] font-bold px-4 py-2 rounded shadow">
                            {t("landing.landingCareer.cta2")}
                        </NavLink>
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
}

export default LandingCareer;
