import { InputType, ReturnType } from "./types";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { CopyCard } from "./schema";
import { Card } from "../../../interfaces/tasks/int-card";
import axiosInstance from "../../api";

const handler = async (data: InputType): Promise<ReturnType> => {

    //console.log("hitting copy card API call");
    try {
        //console.log('data copy card API call :', data);
        const response = await axiosInstance.post<Card>(`/cards/copyCard`, data);

        return {
            data: response.data // This is the Card object
        };
    } catch (error) {
        //console.error('API call failed:', error);
        
        // Return an ActionState object with an error message.
        // If you have specific field errors, you can populate them as well.
        return {
            error: error instanceof Error ? error.message : "An unknown error occurred"
        };
    }
};

export const copyCard = createSafeAction(CopyCard, handler);