import { z } from "zod";

export const UpdateBoardData = z.object({
  title: z.optional(z.string({
    required_error: "Title is required",
    invalid_type_error: "Title is required",
  }).min(3, {
    message: "Title is too short",
  })
  .refine((val) => val !== "Overview", {
    message: "Title cannot be 'Overview'",
  })),
  id: z.string(),
  display_mode: z.enum(['TABLE', 'LIST', 'KANBAN', 'CALENDAR']).optional()
});