import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

import { Note, Opportunity } from "../../../../../interfaces/contacts/int-opportunity";

import { useOpportunityStoreActions } from "../../../../../hooks/contact-oppy-store";
import { useAction } from "../../../../../hooks/use-action";

import { fetchUpdateOpportunity } from "../../../../../api/contacts/opportunities/update-opportunity"

import { Plus, StickyNote } from "lucide-react";
import { Skeleton } from "../../../../../components/ui/skeleton";

import { Textarea } from "../../../../../components/ui/textarea";
import { Button } from "../../../../../components/ui/button";

interface OppyNoteUnitProps {
    data: Opportunity;
}

export const OppyNoteUnit = ({
    data,
}: OppyNoteUnitProps) => {

    const { t } = useTranslation();

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { createUpdateOpportunity } = useOpportunityStoreActions();

    const { execute: executeUpdateCard } = useAction(fetchUpdateOpportunity, {
        onSuccess: (data: Opportunity) => {
            createUpdateOpportunity(data);
            toast.success(`Opportunity "${data.title}" updated`);
            data.last_note && setNote(data.last_note);
            setIsEditing(false);
        },
        onError: (error) => {
            toast.error("An error occurred while updating the opportunity");
        }
    });

    const newNote: Note = {
        id: undefined,
        text: "",
    }
    const [note, setNote] = useState<Note>(data.last_note ?? newNote);

    // Adding a state to manage the editing state of the title (to avoid the onBlur event to be triggered when clicking on the title)
    const [isEditing, setIsEditing] = useState(false);

    const onBlur = () => {
        if (note.text !== data.last_note?.text) {
            inputRef.current?.form?.requestSubmit();
        } else {
            setIsEditing(false);
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        //const description = formData.get("description") as string;
        if (note.text === data.last_note?.text) {
            setIsEditing(false);
            return;
        }
        if (data.id) {
            executeUpdateCard({
                id: data.id,
                last_note: note,
            });
        } 
    }
    
    
    useEffect(() => {
        if (isEditing && inputRef.current) {
            const adjustment = 2;
            inputRef.current.style.height = 'auto'; // Reset height to shrink back if text is deleted
            const idealHeight = inputRef.current.scrollHeight + adjustment;
            inputRef.current.style.height = `${idealHeight}px`;
        }

      }, [isEditing, note]);
    
    return (
        <div className="flex flex-col items-start w-full text-xs md:text-sm">
            <div className="flex flex-row gap-x-2 items-center pb-2 font-medium justify-start w-full">
                <div className="flex items-center gap-x-1">
                    <StickyNote className="h-4 w-4" />
                    <span>
                        {t("contacts.opportunities.notes.title")}
                    </span>
                </div>
                <Button 
                    variant="contacts"
                    className="flex h-5 w-5 m-0 p-0 text-sm"
                    onClick={() => {
                        setNote(newNote);
                        setIsEditing(true);
                    }}
                >
                    <Plus className="h-4 w-4" />
                </Button>
            </div>
            <form onSubmit={onSubmit} className="flex w-full">
                {isEditing ? (
                    <Textarea
                        placeholder={t("contacts.opportunities.notes.placeholder")}
                        ref={inputRef}
                        onBlur={onBlur}
                        id="description"
                        name="description"
                        //defaultValue={title}
                        value={note.text ?? ""}
                        onChange={(e) => setNote({id: note.id, text: e.target.value})}
                        rows={1}
                        className="w-full items-center text-sm px-1 pt-1 bg-transparent dark:bg-transparent dark:text-[#FFDECD] border-transparent relative -left-1.5 overflow-y-auto max-h-48 focus-visible:bg-white focus-visible:border-input hover:border-input"
                        style={{ minHeight: `36px`, lineHeight: '1.5' }}
                        disabled={!data.id}
                    />
                ) : (
                    <div
                        //className="w-full font-semibold text-sm md:text-lg dark:bg-transparent dark:text-[#FFDECD] dark:hover:border-tasks_primary dark:hover:border-[1px] px-1 py-1 items-center cursor-pointer"
                        className="cursor-pointer"
                        style={{ whiteSpace: 'pre-wrap' }} // Ensures line breaks are preserved
                        onClick={() => setIsEditing(true)}
                    >
                        {note.text && note.text}
                    </div>
                )}
            </form>
        </div>
    );
};

OppyNoteUnit.Skeleton = function HeaderSkeleton() {
    return (
      <div className="flex items-start gap-x-3 mb-6">
        <Skeleton className="h-6 w-6 mt-1 bg-neutral-200" />
        <div>
          <Skeleton className="w-24 h-6 mb-1 bg-neutral-200" />
          <Skeleton className="w-12 h-4 bg-neutral-200" />
        </div>
      </div>
    );
  };
