import { useEffect, useState } from "react";
import signUpImg from '../../img/Sign-up.svg'
import { Navigate, useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components/ui/scroll-area";
import { ModalProvider } from "./modals/modal-provider";
import { Checkbox } from "../../components/ui/checkbox"
import { useModal } from "./modals/use-modal-store";
import { useActions, useIsAuthenticated, useSignUpStep } from "../../hooks/auth-store";

import { CircleCheck } from 'lucide-react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { userSchema, UserSchema, emailSchema, userNameSchema } from "../../api/globals/sign-up/schema";
import { fetchCheckExistingEmail, fetchCheckExistingUsername } from "../../api/globals/api-signup";

import { fetchCreateUser } from "../../api/globals/sign-up";

import SignUpForm from "./_components/SignUpForm";
import { sign } from "crypto";
import EmailValidation from "./_components/EmailValidation";
import Error404 from "../../Error";
import { use } from "i18next";

interface MyData {
    // Définissez votre structure ici
  }

  const SignUp = () => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
  
    const signUpStep = useSignUpStep();
    const { setSignUpStep } = useActions();
  
    useEffect(() => {
        setSignUpStep('signUpForm');
        return () => {
            setSignUpStep('');
        };
    }, []);
  
    useEffect(() => {
        // if signUpStep is emailValidationDone, navigate to signin after 1 second
        if (signUpStep === 'emailValidationDone') {
            setTimeout(() => {
                navigate('/signin');
            }, 1000);
        }
    }, [signUpStep]);
    
    if (isAuthenticated) {
        return <Navigate to="/techwatch" />;
    }
  
    return (
        <div className="flex flex-col md:flex-row h-full w-full overflow-hidden bg-[#F8F5F5] dark:bg-[#1A1213]">
            {/* Top bar for small screens */}
            <div className="md:hidden bg-gradient-to-r from-[#D76474] to-[#F8F5F5] dark:from-[#A84F5B] dark:to-[#1A1213] p-4 text-white flex justify-between items-center">
                <h3 className="text-md font-bold text-neutral_white-200">Already have an account?</h3>
                <button onClick={() => navigate(`/signin`)} className="bg-neutral_white-200 text-career_primary hover:bg-[#AE3F51] hover:text-[#FFBFC4] font-bold py-1 px-2 rounded">
                    Sign In
                </button>
            </div>
            {/* Left Panel */}
            <div className="hidden md:flex w-1/4 bg-gradient-to-r from-[#D76474] to-[#F8F5F5] dark:from-[#A84F5B] dark:to-[#1A1213] p-10 text-white items-center justify-center">
                <div className="max-w-md mx-auto">
                    <h2 className="text-xl font-bold mb-3">Already have an account?</h2>
                    <p className="mb-5 text-md">Sign in to continue to your account.</p>
                    <button onClick={() => navigate(`/signin`)} className="mt-4 bg-neutral_white-200 text-career_primary hover:bg-[#AE3F51] hover:text-[#FFBFC4] font-bold py-2 px-4 rounded-lg shadow-lg">
                        Sign In
                    </button>
                </div>
            </div>
  
            {/* Form and Image Panel with Scroll Area */}
            <div className="flex-grow flex flex-col overflow-auto py-12">
                <div className="flex flex-col md:flex-row bg-[#F8F5F5] dark:bg-[#1A1213] w-full h-full">
                    {/* New Form Panel */}
                    <div className="md:w-1/2 flex-grow flex flex-col items-center justify-center bg-[#F8F5F5] dark:bg-[#1A1213] pb-42">
                        <h2 className="text-center text-2xl font-extrabold text-career_primary dark:text-[#FBD5D7]">Unleash your potential!</h2>
                        <h3 className="mt-2 text-center text-xl text-[#AE3F51] dark:text-[#FF95A0]">Create your account</h3>
  
                        {/* Form */}
                        {signUpStep === 'signUpForm' ? (
                            <SignUpForm />
                        ) : signUpStep === 'emailValidation' ? (
                            <EmailValidation />
                        ) : signUpStep === 'emailValidationDone' ? (
                            <div className="flex flex-col items-center justify-center">
                                <CircleCheck size={64} />
                                <h3 className="text-xl font-bold text-career_primary dark:text-[#FF95A0]">Email validated!</h3>
                                <p className="text-center text-md text-[#AE3F51] dark:text-[#FF95A0]">You can now sign in with your credentials.</p>
                            </div>
                        ) : (
                            <Error404 />
                        )}
                    </div>
  
                    {/* Image Panel */}
                    <div className="hidden md:flex w-1/4 items-center justify-center bg-[#F8F5F5] dark:bg-[#1A1213]">
                        <img src={signUpImg} alt="SignUp" className="w-full h-auto object-cover" />
                    </div>
                </div>
            </div>
        </div>
    );
  };
  
  export default SignUp;