import { useWebSocket } from "../../../hooks/socket-context"; 
import { Badge } from "../../../components/ui/badge";

export const SocketIndicator = () => {
  const { socket } = useWebSocket();

  if (!socket) {
    return (
      <Badge 
        variant="outline" 
        className="bg-yellow-600 text-white border-none"
      >
        Fallback: Polling every 1s
      </Badge>
    )
  }

  return (
    <Badge 
      variant="outline" 
      className="bg-emerald-600 text-white border-none"
    >
      Live: Real-time updates
    </Badge>
  )
}