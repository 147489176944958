//import { auth } from "@clerk/nextjs";
import { InputType, ReturnType } from "./types";
//import { db } from "@/lib/db";
//import { revalidatePath } from "next/cache";
import { createSafeAction } from "../../../lib/create-safe-actions";
import { UpdateBoardData } from "./schema";
import axiosInstance from "../../api";
import { Board } from "../../../interfaces/tasks/int-board";


const handler = async (data: InputType): Promise<ReturnType> => {
    const response = await axiosInstance.patch<Board>(`/boards/updateBoard`, {
        display_mode: data.display_mode,
        id: data.id
    
    });
    //console.log('response update board API call :', response);
    //console.log('response.data update board API call :', response.data);

    return { data : response.data };
};

export const updateBoardData = createSafeAction(UpdateBoardData, handler);