import React from 'react';
import img_community from '../../../../img/landing/community.svg';
import img_missions from '../../../../img/landing/landing_servers/Mission Impossible-bro.svg';
import img_coworking from '../../../../img/landing/landing_servers/Coworking-bro.svg';
import img_kickstart from '../../../../img/landing/landing_servers/kickstart.svg';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { useModal } from './use-modal-store';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../../../components/ui/dialog';

const HelpCommunityModal = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, type} = useModal();
    const isModalOpen = isOpen && type === "Community";

    return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="border-transparent text-center text-community_primary dark:text-dark_community bg-[#F8F5F5] dark:bg-[#212121] shadow-md rounded-lg mb-6">
        <DialogHeader className="pt-8 px-4">
          <DialogTitle className="text-3xl text-center font-bold">
            Community
          </DialogTitle>
          <DialogDescription className="text-gray-600 dark:text-[#B3B3B3] italic mt-2 font-medium text-center">
            {t("landing.landingCommunity.tagline")}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[420px] pr-6">
            <div className="bg-[#F8F5F5] dark:bg-[#212121] text-gray-800 min-h-screen flex flex-col flex-grow  w-full items-center justify-center p-2">
                <div className="container mx-auto px-4">
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_community} alt="Personalized News Feed" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title1")}</h2>
                                <p className="mt-2 text-sm text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc1")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F4E2EE] dark:bg-[#1D101A] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_missions} alt="Trending Topics" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title2")}</h2>
                                <p className="mt-2 text-sm text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc2")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16">
                        <div className="flex flex-col md:flex-row items-center gap-2">
                            <img src={img_coworking} alt="Custom Topic Subscription" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title3")}</h2>
                                <p className="mt-2 text-sm text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc3")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-16 bg-[#F4E2EE] dark:bg-[#1D101A] p-2 rounded-lg">
                        <div className="flex flex-col md:flex-row-reverse items-center gap-2">
                            <img src={img_kickstart} alt="Insightful Summaries" className="w-full md:w-1/2 h-auto rounded-lg" />
                            <div className="flex-1">
                                <h2 className="text-xl font-semibold text-community_primary dark:text-dark_community">{t("landing.landingCommunity.title4")}</h2>
                                <p className="mt-2 text-sm text-[#A12E8B] dark:text-[#F8D3ED]">
                                    {t("landing.landingCommunity.desc4")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
        
    );
}

export default HelpCommunityModal;
