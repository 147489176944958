import { useEffect, useState } from "react";
//for Add Plan Modal
import { ModalProvider } from "./_components/modals/modal-provider";

import { useCareerHookActions, useSelectedPath, useSelectedPlan } from "../../hooks/career-hook"; 

import { fetchDetailedPlanRequest } from "../../api/career/api-create-plan";
import PlanPage from "./_components/main/plan";
import PlanOverview from "./_components/main/overview";

import { fetchPlanQuestions, fetchUserPlans } from "../../api/career/api-plan-elements";
import { SidebarGeneral } from "../../components/Sidebar-general";
import { cn } from "../../lib/utils";

const CareerPath = () => {

  const selectedPath = useSelectedPath();
  
  const [updatedPlan, setupdatedPlan] = useState<boolean>(false);

  const selectedPlan = useSelectedPlan();
  const handleUpdatePlan = async () => {
    if (selectedPlan){
      const updatePlan = await fetchDetailedPlanRequest(selectedPlan);
      if(updatePlan) setupdatedPlan(true);
    }
  }

  const { initPlans } = useCareerHookActions();
    
  useEffect(() => {
      const getPlans = async() => {
          const response = await fetchUserPlans();
          const plansWithId = response.filter(plan => plan.id !== undefined);
          const plansWithQuestions = await Promise.all(plansWithId.map(async (plan) => {
              const questions = await fetchPlanQuestions(plan.id);
              return { ...plan, questions };
          }));
          initPlans(plansWithQuestions);
      }
      getPlans();
  }, [selectedPath]);


  return (
    
    <div className="flex flex-grow overflow-hidden h-full w-full">
      <ModalProvider />
      <div className="hidden md:flex flex-col w-2/12 h-full px-1 py-1 dark:bg-[#1E1F22]">
        <SidebarGeneral />
      </div>
      <div id="career-body" 
        //className="flex-grow flex flex-col p-2 w-10/12 overflow-auto"
        className={cn("flex-grow flex flex-col p-2 w-10/12",
          "overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800",
      )}
      >
      {selectedPath ? (
        <PlanPage/>
        ): (
        <PlanOverview />
        )
      }
      </div> 
    </div>
  )
}


export default CareerPath;
