import { useEffect, useState } from 'react';
import { fetchFinalizePlanSecondRequest, fetchEditPlan } from '../../../../api/career/api-create-plan';
import { Trash } from 'lucide-react';
import { ActionTooltip } from '../../../../components/action-tooltip';
import { Input } from '../../../../components/ui/input';
import { Slider } from "../../../../components/ui/slider"
import { useCareerHookActions, useCreationPlan, useSelectedPath } from '../../../../hooks/career-hook';
import { InputSecondPromptAPI, PlanElement } from '../../../../api/career/interface-career';
import { useTranslation } from 'react-i18next';

interface CreatePlanQuestionsProps {
    planElements: PlanElement[];
    detailedPlanCallIsLoading: boolean;
    onNext: () => void;
}

const CreatePlanQuestions: React.FC<CreatePlanQuestionsProps> = ({ planElements, onNext, detailedPlanCallIsLoading }) => {
    const { t } = useTranslation();
    const creationPlan = useCreationPlan();
    const selectedPath = useSelectedPath();
    const { setCreationPlan, updatePlan, setSelectedPlan } = useCareerHookActions();

    const [sliderValues, setSliderValues] = useState({
        resources: creationPlan?.resources || 5,
        entertainment: creationPlan?.entertainment || 5,
        coherence: creationPlan?.coherence || 5,
        confidence: creationPlan?.confidence || 5,
    });

    // Gestion des questions comme un tableau de chaînes
    const [questions, setQuestions] = useState((creationPlan?.questions) && (creationPlan.questions?.length > 0) ? creationPlan.questions : [{ title: "" }]);
    const [isLoading, setIsLoading] = useState(false);

    /*
    useEffect(() => {
        const inputData: InputSecondPromptAPI = {
            global_title: creationPlan?.proposed_title ? creationPlan?.proposed_title : creationPlan?.title || "",
            year_1: { objective: '', description: '', keyResults: [] },
            year_2: { objective: '', description: '', keyResults: [] },
            year_3: { objective: '', description: '', keyResults: [] },
            year_4: { objective: '', description: '', keyResults: [] },
            year_5: { objective: '', description: '', keyResults: [] },
        };

        // Populate each year's data from planMainSteps
        planElements.forEach(step => {
            if (step.year >= 1 && step.year <= 5) {
                const yearKey = `year_${step.year}` as keyof Omit<InputSecondPromptAPI, 'global_title'>;
                inputData[yearKey].objective = step.title;
                inputData[yearKey].description = step.description || "";
                inputData[yearKey].keyResults = step.key_results?.map(kr => kr.title) || [];
            }
        });

        const fetchPlanDetails = async () => {
            try {
                setIsLoading(true);
                const responseElements = await fetchFinalizePlanSecondRequest(selectedPath as "CURRENT" | "ALTERNATIVE" | "DREAM" | "OTHER", inputData);
                //console.log('responseElements:', responseElements);
                if (responseElements) {
                    updatePlan(responseElements);
                }
            } catch (error) {
                console.error('An error occurred while fetching plan details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPlanDetails();
    }, [planElements, creationPlan, selectedPath, updatePlan]);
    */
    
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true);
    
        e.preventDefault();
        //const newStep = creationPlan?.current_step && (step === 'previous' ? creationPlan?.current_step - 1 : creationPlan?.current_step + 1);
        const newStep = creationPlan?.current_step && creationPlan?.current_step + 1;

        const creationPlanUpdate = {
            ...creationPlan,
            questions: questions.filter(question => question.title.trim() !== ''),
            resources: sliderValues.resources,
            coherence: sliderValues.coherence,
            confidence: sliderValues.confidence,
            entertainment: sliderValues.entertainment,
            current_step: newStep,
        };
    
        try {
            const response = creationPlan?.id && await fetchEditPlan(creationPlan?.id, creationPlanUpdate);
            if (response) {
                setCreationPlan(response);
                setSelectedPlan(creationPlan?.id);
                /*if (step === 'next') {
                    onNext();
                }*/
                onNext();
            }
        } catch (error) {
            //console.error("Error while updating plan:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleEvaluationChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setSliderValues(prevValues => ({ ...prevValues, [name]: parseInt(e.target.value) }));
    };
    
    // Suppression des questions
    const handleRemoveQuestion = (index: number) => setQuestions(questions.filter((_, i) => i !== index));

    const evaluationFields = ['resources', 'entertainment', 'confidence', 'coherence'] as const;

    const evaluationDetails = {
        resources: t("career.createPlan.step4.estimations.resources.description"),//  "Do you have the objectives resources-time, money, skill, contacts- you need to pull off your plan?",
        coherence: t("career.createPlan.step4.estimations.coherence.description"),// "Does this path align with you and your experience?",
        entertainment: t("career.createPlan.step4.estimations.entertainment.description"),// "What is the level of passion and enjoyment you anticipate from this career?",
        confidence: t("career.createPlan.step4.estimations.confidence.description"),// "How confident are you in your ability to achieve this plan?",
    };

    const handleQuestionChange = (index: number, value: string) => {
        const updatedQuestions = questions.map((question, i) => {
            if (i === index) {
                return { ...question, title: value };
            }
            return question;
        });
    
        setQuestions(updatedQuestions);
        // Automatically add a new question field if the last one is being edited
        if (index === questions.length - 1 && value.trim() !== '') {
            setQuestions([...updatedQuestions, { title: '' }]);
        }
    }

    return (
        <div className="max-w-xl md:max-w-2xl lg:max-w-3xl mx-auto p-6 bg-[#F8F5F5] dark:bg-[#1A1213] shadow-md rounded-lg">
            <h2 className="text-2xl font-semibold text-center text-career_primary dark:text-[#EDEDED] mb-6">{t("career.createPlan.step4.top")}</h2>
            <form id="creationPlanForm">
                <div className="mb-4">
                    <label htmlFor="questions" className="block text-[#AE3F51] dark:text-[#FBD5D7] font-bold mb-2">{t("career.createPlan.step4.questions.title")}</label>
                        <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-2 font-medium">
                            {t("career.createPlan.step4.questions.description")}
                        </p>
                        <p className="text-gray-600 dark:text-[#B3B3B3] text-xs italic font-medium">
                            {t("career.createPlan.step4.questions.example")}
                        </p>
                        {questions.map((question, index) => (
                            <div key={index} className="relative my-2">
                                <Input
                                type="text"
                                placeholder={`Question ${index + 1}`}
                                value={question.title}
                                className="shadow border rounded w-full py-2 px-3 text-[#221F21] bg-[#F9EFF0] dark:text-[#FBD5D7] dark:bg-[#303030] leading-tight focus:border-[#F697A1] dark:focus:border-[#803C45] focus:outline-none focus:ring-0"
                                onChange={e => handleQuestionChange(index, e.target.value)}
                                />
                                {index !== 0 && (
                                    <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                                        <ActionTooltip label="Remove">
                                        <Trash
                                            onClick={() => handleRemoveQuestion(index)}
                                            className="cursor-pointer w-4 h-4 text-[#AE3F51] dark:text-[#FF95A0] dark:hover:text-zinc-300 transition"
                                        />
                                        </ActionTooltip>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
                <div className="mb-6">
                    <label htmlFor="evaluation" className="block text-[#AE3F51] dark:text-[#FF95A0] font-bold mb-2">{t("career.createPlan.step4.estimations.title")}</label>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {evaluationFields.map(field => (
                            <div key={field} className="flex flex-col mb-4 items-center">
                                <label htmlFor={field} className="font-semibold text-center text-career_primary dark:text-[##EDEDED] capitalize">{t(`career.createPlan.step4.estimations.${field}.title`)}</label>
                                <p className="mb-4 text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-1 font-medium">{evaluationDetails[field]}</p>
                                <div className="w-full flex justify-between items-center">
                                    <div className="slider-wrapper">
                                        <span className='text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-1 font-medium'>1</span>
                                        <input
                                            type="range"
                                            min="1"
                                            max="10"
                                            value={sliderValues[field]}
                                            onChange={(e) => handleEvaluationChange(e, field)}
                                            className="flex-grow mx-2 appearance-none bg-gray-200 dark:bg-gray-600 rounded-full h-2 outline-none"
                                            style={{ '--value': `${(sliderValues[field] - 1) * 100 / 9}%` } as React.CSSProperties}
                                        />
                                        <span className='text-gray-600 dark:text-[#B3B3B3] text-xs italic mt-1 font-medium'>10</span>
                                    </div>
                                </div>
                                <div className="text-center text-xs py-1">
                                    <span className='text-[#AE3F51] dark:text-[#FF95A0] font-bold'>{sliderValues[field]}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center">
                    <button
                        className="bg-[#FFCFD3] text-[#6E0021] hover:bg-[#AE3F51] hover:text-[#FFBFC4] dark:bg-[#481920] dark:text-[#FBD5D7] dark:hover:bg-[#35161A] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={(e) => handleSubmit(e)} disabled={detailedPlanCallIsLoading}
                    >
                        {detailedPlanCallIsLoading ? 'Loading...' : t('career.createPlan.step4.buttonNext')}
                    </button>
                </div>
            </form>
        </div>
    );
};

 
export default CreatePlanQuestions;